import { Icon } from "@/components/Icon";
import type { IconProps } from "@/types";

export type SelectDefaultIconProps = IconProps;

export function SelectDefaultIcon({
  className,
  ...props
}: SelectDefaultIconProps) {
  return <Icon {...props} className={`${className ?? ""} SelectDefaultIcon`} />;
}

SelectDefaultIcon.displayName = "Select.DefaultIcon";
