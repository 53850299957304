import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Stack, type StackProps } from "../Stack";

type AccordionTargetSlotProps<RC extends ReactElement | undefined = undefined> =
  StackProps<RC> & {};

function AccordionTargetSlot<RC extends ReactElement | undefined = undefined>({
  children,
  direction = "column",
  justifyContent = "center",
  gap = "0px",
  rc = <span />,
  ...props
}: AccordionTargetSlotProps<RC>) {
  return (
    <Stack
      {...props}
      direction={direction}
      gap={gap}
      rc={rc}
      justifyContent={justifyContent}
    >
      {children}
    </Stack>
  );
}

export function AccordionTargetLeftSlot<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  alignItems = "flex-start",
  className,
  ...props
}: AccordionTargetSlotProps<RC>) {
  return (
    <AccordionTargetSlot
      {...props}
      alignItems={alignItems}
      sx={merge({ flex: 1, textAlign: "left", minh: "base.spacing.x8" }, sx)}
      className={`${className ?? ""} AccordionTargetLeftSlot`}
    />
  );
}

export function AccordionTargetRightSlot<
  RC extends ReactElement | undefined = undefined,
>({
  alignItems = "flex-end",
  className,
  sx = {},
  ...props
}: AccordionTargetSlotProps<RC>) {
  return (
    <AccordionTargetSlot
      {...props}
      alignItems={alignItems}
      sx={merge({ textAlign: "right" }, sx)}
      className={`${className ?? ""} AccordionTargetRightSlot`}
    />
  );
}

AccordionTargetLeftSlot.displayName = "Accordion.TargetLeftSlot";
AccordionTargetRightSlot.displayName = "Accordion.TargetRightSlot";
