import { useEffect, useState } from "react";

export const useTimeCountdown = (
  targetDate?: Date,
): { formatted: string; msLeft: number | undefined } => {
  const [timeLeft, setTimeLeft] = useState<string>("");
  const [msLeft, setMsLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!targetDate) {
      setTimeLeft("");
      setMsLeft(undefined);
      return;
    }

    const calculateTimeLeft = () => {
      const now = new Date();
      const diffTime = targetDate.getTime() - now.getTime();

      if (diffTime <= 0) {
        setTimeLeft("");
        setMsLeft(0);
        return;
      }

      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.floor(
        (diffTime % (1000 * 60 * 60)) / (1000 * 60),
      );
      const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      const formattedHours = diffHours.toString().padStart(2, "0");
      const formattedMinutes = diffMinutes.toString().padStart(2, "0");
      const formattedSeconds = diffSeconds.toString().padStart(2, "0");

      setTimeLeft(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
      setMsLeft(diffTime);
    };

    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return { formatted: timeLeft, msLeft };
};
