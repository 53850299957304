import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import type { BodyProps, InputTextAlign } from "@/types";

type InputListCaptionProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC> & { textAlign?: InputTextAlign };

export function InputListCaption<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  className,
  sx = {},
  textAlign,
  testId = "InputListCaption",
  ...props
}: InputListCaptionProps<RC>) {
  return (
    <Body
      {...props}
      size={size}
      testId={testId}
      className={`${className ?? ""} InputListCaption`}
      sx={merge(sx, {
        c: "base.color.text.body.secondary",
        mt: "base.spacing.x2",
        py: "base.spacing.x2",
        textAlign: textAlign ?? "left",
      })}
    />
  );
}

InputListCaption.displayName = "InputList.Caption";
