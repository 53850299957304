import type {
  BiomeTheme,
  MakeResponsive,
  MenuItemShimmerType,
  MenuItemSize,
} from "@/types";
import { lineClamp } from "@/utils";
import { getResponsiveSx } from "@/utils/styleHelpers";
import type { Properties } from "csstype";

export const baseContainerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingX: "base.spacing.x3",
  width: "100%",
  brad: "base.borderRadius.x4",
  transitionProperty: "border, background, box-shadow, padding, min-height",
  border: "solid transparent",
  borderWidth: "base.border.size.200",

  ".innerContainer": {
    gap: "base.spacing.x4",
  },

  "&.MenuItem--emphasized": {
    bgc: "base.color.translucent.emphasis.100",
  },

  "&.MenuItem--hasWholeOnClick": {
    cursor: "pointer",
  },

  "&.MenuItem--hasWholeOnClick.MenuItem--hover": {
    bgc: "base.color.translucent.emphasis.200",
    boxShadow: "base.shadow.200",
  },

  "&.MenuItem--selected": {
    bgc: "transparent",
    borderColor: "base.color.translucent.standard.1000",

    "&.MenuItem--emphasized": {
      bgc: "base.color.translucent.emphasis.100",
    },
  },

  "&.MenuItem--selected.MenuItem--hasWholeOnClick.MenuItem--hover": {
    boxShadow: "base.shadow.200",
  },

  "&.MenuItem--hasWholeOnClick:active": {
    bgc: "base.color.translucent.emphasis.100",
    boxShadow: "base.shadow.100",
  },

  ".MenuItemLabel, .MenuItemCaption": {
    "& > .FramedImage, & > .Icon, & > .Logo": {
      minw: "unset",
    },
    "& > .FramedImage, & > .Icon, & > .Logo, & > .Badge": {
      d: "inline-flex",
      verticalAlign: "top",
    },
  },

  "&.MenuItem--medium, &.MenuItem--small": {
    ".MenuItemLabel, .MenuItemCaption": {
      "& > .FramedImage, & > .Icon, & > .Logo": {
        w: "1.1em",
      },
      "& > .FramedImage, & > .Icon, & > .Logo, & > .Badge": {
        mt: "0.15em",
      },
    },
  },

  "&.MenuItem--xSmall": {
    ".MenuItemLabel, .MenuItemCaption": {
      "& > .FramedImage, & > .Icon, & > .Logo": {
        w: "1.1em",
        mt: "0.05em",
      },
      "& > .Badge": {
        mt: "0em",
      },
    },
  },
};

export const innerTextContainerSxProps = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  flexGrow: 1,
};

export const renderSizeBasedStyles = ({
  size,
  theme,
}: {
  size: MenuItemSize;
  theme: BiomeTheme;
}) => {
  switch (size) {
    case "xSmall":
      return {
        paddingY: `calc(${theme.base.spacing.x2} - ${theme.base.border.size[200]})`,
        minHeight: theme.base.spacing.x13,

        "& .MenuItem": {
          paddingTop: theme.base.spacing.x2,
        },
      };

    case "small":
      return {
        paddingY: `calc(${theme.base.spacing.x3} - ${theme.base.border.size[200]})`,
        minHeight: theme.base.spacing.x18,

        "& .MenuItem": {
          paddingTop: theme.base.spacing.x3,
        },
      };

    case "medium":
    default:
      return {
        paddingY: `calc(${theme.base.spacing.x6} - ${theme.base.border.size[200]})`,
        minHeight: theme.base.spacing.x24,

        "& .MenuItem": {
          paddingTop: theme.base.spacing.x6,
        },
      };
  }
};

export const renderResponsiveStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) => getResponsiveSx({ size, theme, renderSxAtSize: renderSizeBasedStyles });

export const baseFramedIconStyles = {
  flexShrink: 0,
};

export const baseLeftImageStyles = {
  flexShrink: 0,
  alignSelf: "center",
};

export const baseShimmerOuterSx = {
  ...baseContainerStyles,
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "base.spacing.x4",
};

export function renderShimmerOuterStyles({
  size,
  theme,
  shimmerType,
}: {
  size: MenuItemSize;
  theme: BiomeTheme;
  shimmerType: MenuItemShimmerType;
}) {
  const baseMenuItemSx = renderSizeBasedStyles({ size, theme });
  return {
    ...baseMenuItemSx,
  };
}

export const renderResponsiveOuterStyles = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<MenuItemSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: renderSizeBasedStyles,
  });

export const labelAndCaptionBaseSx = {
  ...lineClamp(2),
  textAlign: "left",
};
export const captionSx = {
  ...labelAndCaptionBaseSx,
  c: "base.color.text.body.secondary",
};
export const labelSx = {
  ...labelAndCaptionBaseSx,
  c: "base.color.text.body.primary",
};

export const renderSizeBasedDividerSx = ({
  size,
  theme,
}: {
  size: MenuItemSize;
  theme: BiomeTheme;
}) => {
  switch (size) {
    case "xSmall":
      return {
        marginTop: `calc(${theme.base.spacing.x2} - ${theme.base.border.size[200]})`,
        marginBottom: `calc(${theme.base.spacing.x2} - ${theme.base.border.size[200]})`,
      };

    case "small":
      return {
        marginTop: `calc(${theme.base.spacing.x3} - ${theme.base.border.size[200]})`,
        marginBottom: `calc(${theme.base.spacing.x3} - ${theme.base.border.size[200]})`,
      };

    case "medium":
    default:
      return {
        marginTop: `calc(${theme.base.spacing.x6} - ${theme.base.border.size[200]})`,
        marginBottom: `calc(${theme.base.spacing.x6} - ${theme.base.border.size[200]})`,
      };
  }
};

export const renderResponsiveDividerSx = ({
  size,
  theme,
}: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({ size, theme, renderSxAtSize: renderSizeBasedDividerSx });

export function getShimmerTopContainerStyles({
  size,
  theme,
}: {
  size: MenuItemSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "xSmall":
      return {
        minHeight: theme.base.spacing.x9,
      };

    default:
      return {
        minHeight: theme.base.spacing.x12,
      };
  }
}

export const getResponsiveShimmerTopContainerStyles = (props: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getShimmerTopContainerStyles,
  });

export function getBottomSlotDividerStyles({
  size,
  theme,
}: {
  size: MenuItemSize;
  theme: BiomeTheme;
}) {
  const { paddingY, ...other } = renderSizeBasedStyles({ size, theme });
  return {
    marginY: paddingY,
  } as Properties;
}

export const getResponsiveBottomSlotDividerStyles = (props: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getBottomSlotDividerStyles,
  });
