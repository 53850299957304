import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedImage } from "@/components/FramedThings";
import { DEFAULT_CARD_HERO_SIZE } from "@/constants";
import type { FramedImageProps, MakeResponsive } from "@/types";

import {
  type CardHeroSize,
  useGetFramedContentSizeFromHeroCardSize,
} from "./shared";
import { baseFramedSx } from "./styles";

export type CardHeroFramedImageProps<
  Use extends ReactElement | undefined = undefined,
> = FramedImageProps<Use> & {
  cardHeroSize?: MakeResponsive<CardHeroSize>;
};

export function CardHeroFramedImage<
  Use extends ReactElement | undefined = undefined,
>({
  sx = {},
  className,
  cardHeroSize = DEFAULT_CARD_HERO_SIZE,
  size,
  use,
  ...props
}: CardHeroFramedImageProps<Use>) {
  const mergedSx = useMemo(() => merge(baseFramedSx, sx), [sx]);
  const defaultSize = useGetFramedContentSizeFromHeroCardSize(cardHeroSize);
  return (
    <FramedImage
      {...props}
      use={use}
      sx={mergedSx}
      size={size ?? defaultSize}
      className={`${className ?? ""} CardHeroFramedImage`}
    />
  );
}

CardHeroFramedImage.displayName = "CardHero.FramedImage";
