import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Icon } from "@/components/Icon";
import { SvgIcon } from "@/components/SvgIcon";
import { type DuoConProps, isDualVariantIcon } from "@/types";
import { getBgFillColoring, getFillColoring } from "./styles";

export function DuoCon<RC extends ReactElement | undefined = undefined>({
  icon,
  variant = "regular",
  iconVariant = "regular",
  colorVariant = "guidance",
  outerShape = "circle",
  testId,
  className,
  sx = {},
  motionProfile,
  ...props
}: DuoConProps<RC>) {
  const bgFill = useMemo(
    () => getBgFillColoring(colorVariant, variant),
    [colorVariant, variant],
  );
  const fill = useMemo(
    () => getFillColoring(colorVariant, variant),
    [colorVariant, variant],
  );
  const outerMergedSx = useMemo(
    () =>
      merge(
        {
          w: "base.icon.size.600",
          d: "flex",
          position: "relative",

          "&::before": {
            content: '""',
            w: "100%",
            pb: "100%",
          },
        },
        sx,
      ),
    [sx],
  );
  const outerShapeSx = useMemo(
    () => ({
      position: "absolute",
      top: "0",
      left: "0",
      w: "100%",
      h: "100%",
      maxw: "100%",
      maxh: "100%",
      fill: bgFill,
    }),
    [bgFill],
  );
  const innerIconSx = useMemo(() => {
    return {
      ...(outerShape === "triangle"
        ? {
            bottom: "10%",
            translate: "-50% 0%",
          }
        : {
            top: "50%",
            translate: "-50% -50%",
          }),
      w: "58.33%",
      position: "absolute",
      left: "50%",
      fill,
    };
  }, [fill, outerShape]);
  const outerShapeSvg = useMemo(() => {
    switch (outerShape) {
      case "hexagon":
        return <path d="M11 0L21.3923 6V18L11 24L0.607666 18V6L11 0Z" />;
      case "triangle":
        return <path d="M0 21L12 0L24 21H0Z" />;
      case "circle":
      default:
        return <circle cx="12" cy="12" r="11" />;
    }
  }, [outerShape]);
  const outerShapeSvgViewBox = useMemo(() => {
    switch (outerShape) {
      case "triangle":
        return "0 0 24 21";
      case "hexagon":
        return "0 0 22 24";
      case "circle":
      default:
        return "0 0 24 24";
    }
  }, [outerShape]);
  const iconProps = isDualVariantIcon(icon)
    ? { icon, variant: iconVariant }
    : { icon };
  return (
    <Box
      {...props}
      sx={outerMergedSx}
      testId={testId}
      className={`${
        className ?? ""
      } DuoCon DuoCon--${outerShape} DuoCon--${variant}`}
    >
      <SvgIcon
        sx={outerShapeSx}
        data-outer-shape={outerShape}
        testId={`${testId}__outerShape`}
        viewBox={outerShapeSvgViewBox}
      >
        {outerShapeSvg}
      </SvgIcon>
      <Icon
        {...iconProps}
        sx={innerIconSx}
        testId={`${testId}__icon`}
        motionProfile={motionProfile}
      />
    </Box>
  );
}

DuoCon.displayName = "DuoCon";
