import type { ReactElement } from "react";

import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import type { BoxWithRCAndDomProps } from "@/types";
import { cloneElementWithCssProp } from "@/utils/componentHelpers";

export function Box<RC extends ReactElement | undefined = undefined>({
  testId,
  sx = {},
  children,
  rc = <div />,
  domRef,
  className,
  ...domProps
}: BoxWithRCAndDomProps<RC>) {
  const css = useConvertSxToEmotionStyles(sx);
  const mergedClassNames = `${className ?? ""} ${rc?.props.className ?? ""} Box`;
  return cloneElementWithCssProp(rc, {
    ...domProps,
    ...(testId ? { "data-testid": testId } : {}),
    ...(domRef ? { ref: domRef } : {}),
    className: mergedClassNames,
    css,
    children,
  });
}

Box.displayName = "Box";
