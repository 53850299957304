import type {
  InputTextAlign,
  InputValidationStatus,
  MakeResponsive,
  StandardComponentWithProps,
  TextInputSize,
} from "@/types";
import { isChildSubcomponent } from "@/utils";
import {
  type ComponentProps,
  type ReactElement,
  type ReactNode,
  isValidElement,
} from "react";
import { Autocomplete } from "../Autocomplete";
import { Select } from "../MenuItemRelatedComponents";
import type { StackProps } from "../Stack";
import { TextArea } from "../TextArea";
import { TextInput } from "../TextInput";

type BaseInputGroupProps = {
  id?: string;
  size?: MakeResponsive<TextInputSize>;
  textAlign?: InputTextAlign;
  validationStatus?: InputValidationStatus;
};

export type InputGroupProps<RC extends ReactElement | undefined> = Omit<
  StackProps<RC>,
  "direction"
> &
  BaseInputGroupProps;

export type InputGroupRowBaseProps = StandardComponentWithProps<
  HTMLDivElement,
  BaseInputGroupProps & { children?: ReactNode }
>;

export const SUPPORTED_INPUT_COMPONENTS = [
  TextArea,
  TextInput,
  Select,
  Autocomplete,
] as const;

export const SUPPORTED_INPUT_COMPONENTS_MAP = {
  TextArea,
  TextInput,
  Select,
  Autocomplete,
} as const;

export function isSupportedInputComponent(
  child: ReactNode,
): child is ReactElement<
  ComponentProps<(typeof SUPPORTED_INPUT_COMPONENTS)[number]>
> {
  return SUPPORTED_INPUT_COMPONENTS.some(
    (inputEl) => isValidElement(child) && child.type === inputEl,
  );
}

export function getSizePropForInput(
  child: ReactNode,
  size: MakeResponsive<TextInputSize>,
) {
  switch (true) {
    case isChildSubcomponent(child, TextArea):
    case isChildSubcomponent(child, TextInput):
    case isChildSubcomponent(child, Autocomplete): {
      let actualSize = size;
      if (isValidElement(child) && child.props.sizeVariant)
        actualSize = child.props.sizeVariant;
      return { sizeVariant: actualSize };
    }

    case isChildSubcomponent(child, Select):
    default: {
      let actualSize = size;
      if (childHasSizeProp(child)) actualSize = child.props.size;
      return { size: actualSize };
    }
  }
}

type ChildWithSizeProp = ReactElement<{ size: MakeResponsive<TextInputSize> }>;
export function childHasSizeProp(child: ReactNode): child is ChildWithSizeProp {
  const biomeTypedChild = child as ChildWithSizeProp;
  return biomeTypedChild?.props?.size !== undefined;
}
