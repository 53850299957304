import { type ReactElement, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Video } from "@/components/Video";
import type {
  AspectRatioBaseProps,
  BoxWithRCAndDomProps,
  VideoProps,
} from "@/types";

import { dimVariantOverlaySx, mediaAssetBaseSx } from "./styles";
import type {
  HeroBackgroundMediaFrost,
  HeroBackgroundMediaVariant,
} from "./types";

export type HeroBackgroundVideoProps<
  RC extends ReactElement | undefined = undefined,
> = Omit<VideoProps, "domRef"> &
  Omit<AspectRatioBaseProps, "aspectRatio"> &
  BoxWithRCAndDomProps<RC> & {
    frost?: HeroBackgroundMediaFrost;
    variant?: HeroBackgroundMediaVariant;
    videoRef?: Ref<HTMLVideoElement>;
  };

export function HeroBackgroundVideo({
  sx = {},
  rc,
  className,
  objectFit = "cover",
  objectPosition = "center center",
  testId = "HeroBackgroundVideo",
  frost = "300",
  variant = "bright",
  videoUrl,
  mimeType,
  domRef,
  videoRef,
  // @NOTE: <video> DOM attr props:
  autoPlay = true,
  muted = true,
  loop = true,
  controls,
  playsInline,
  poster,
  preload,
  mediaGroup,
  disablePictureInPicture,
  crossOrigin,
  ...props
}: HeroBackgroundVideoProps) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          pos: "relative",
          h: "100%",
          ...(variant === "dim" ? dimVariantOverlaySx : {}),
        },
        sx,
      ),
    [sx, variant],
  );
  const videoSx = useMemo(
    () =>
      merge(mediaAssetBaseSx, {
        objectFit,
        objectPosition,
        ...(frost !== "none" ? { filter: `base.frost.${frost}` } : {}),
      }),
    [objectFit, objectPosition, frost],
  );
  return (
    <Box
      {...props}
      rc={rc}
      domRef={domRef}
      sx={mergedSx}
      testId={testId}
      className={`${className ?? ""} HeroBackgroundVideo`}
    >
      <Video
        domRef={videoRef}
        playsInline={playsInline}
        videoUrl={videoUrl}
        mimeType={mimeType}
        sx={videoSx}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        testId={`${testId}__video`}
        controls={controls}
        poster={poster}
        preload={preload}
        mediaGroup={mediaGroup}
        disablePictureInPicture={disablePictureInPicture}
        crossOrigin={crossOrigin}
      />
    </Box>
  );
}

HeroBackgroundVideo.displayName = "HeroBackground.Video";
