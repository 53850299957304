import { merge } from "ts-deepmerge";

import type { BodyProps } from "@/types";
import { Body } from "../Text";

export type SpineMenuItemLabelProps = Omit<BodyProps, "size">;

export function SpineMenuItemLabel({
  children,
  sx = {},
  ...props
}: SpineMenuItemLabelProps) {
  return (
    <Body
      {...props}
      size="small"
      sx={merge({ c: "inherit", textAlign: "center" }, sx)}
    >
      {children}
    </Body>
  );
}

SpineMenuItemLabel.displayName = "SpineMenuItemLabel";
