import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import {
  SwapStack,
  type SwapStackProps,
} from "@/components/FramedThings/SwapStack";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { MenuItemProps } from "@/types";

import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseLeftImageStyles } from "./styles";

export type MenuItemSwapStackProps<
  RC extends ReactElement | undefined = undefined,
> = Omit<SwapStackProps<RC>, "size"> & Pick<MenuItemProps, "size">;

export function MenuItemSwapStack<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  rc = <span />,
  size = DEFAULT_MENU_ITEM_SIZE,
  ...props
}: MenuItemSwapStackProps<RC>) {
  const mergedSx = useMemo(() => merge(baseLeftImageStyles, sx), [sx]);
  const swapStackSize = useGetBiomeImageSizeFromMenuItemSize(size);
  return <SwapStack {...props} rc={rc} sx={mergedSx} size={swapStackSize} />;
}

MenuItemSwapStack.displayName = "MenuItem.SwapStack";
MenuItemSwapStack.PrimaryImage = SwapStack.PrimaryImage;
MenuItemSwapStack.SecondaryImage = SwapStack.SecondaryImage;
