import { cloneElement, useCallback, useEffect, useId, useMemo } from "react";

import { DEFAULT_DRAWER_POSITION, DEFAULT_DRAWER_SIZE } from "@/constants";
import {
  useControlledOverlay,
  useForwardLocalDomRef,
  useGetMotionProfile,
  useGetSubcomponentChild,
} from "@/hooks";
import {
  CLOSE_DRAWER,
  OPEN_DRAWER,
  UPDATE_DRAWER_PROPS,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { DrawerComponentProps } from "@/types";

import { SmartClone } from "../SmartClone";
import { DrawerContent } from "./DrawerContent";
import { DrawerTarget } from "./DrawerTarget";

export function Drawer({
  visible,
  id: idProp,
  children,
  containerSx,
  bgOverlaySx,
  size = DEFAULT_DRAWER_SIZE,
  domRef = { current: null },
  showHeaderBar = true,
  onCloseDrawer,
  showBgOverlay = true,
  headerBarTitle,
  outsideClicksClose = true,
  escapeKeyClose = true,
  drawerPosition = DEFAULT_DRAWER_POSITION,
  drawerCloseIcon = "Close",
  drawerCloseIconVariant = "bold",
  testId = "Drawer",
  motionProfile,
}: DrawerComponentProps) {
  const motionProfileToUse = useGetMotionProfile(motionProfile);

  // @NOTE: Move domRef to the Target element, by doing:
  // const localDomRef = useForwardLocalDomRef(target?.props?.domRef || { current: null });
  const target = useGetSubcomponentChild(children, DrawerTarget);
  const localDomRef = useForwardLocalDomRef(domRef);
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const content = useGetSubcomponentChild(children, DrawerContent);

  const { state: drawerList, dispatchAction } = useOverlaysStore(
    (state) => state.drawerList,
  );

  const isControlled = useMemo(() => typeof visible !== "undefined", [visible]);
  const drawerProps = useMemo(
    () => ({
      id,
      content: content ? cloneElement(content, { id }) : null,
      containerSx,
      bgOverlaySx,
      size,
      targetRef: localDomRef,
      headerBarTitle,
      showHeaderBar,
      showBgOverlay,
      isControlled,
      onCloseDrawer,
      outsideClicksClose,
      escapeKeyClose,
      drawerPosition,
      drawerCloseIcon,
      drawerCloseIconVariant,
      testId,
      motionProfile: motionProfileToUse,
    }),
    [
      content,
      drawerPosition,
      headerBarTitle,
      id,
      containerSx,
      bgOverlaySx,
      isControlled,
      localDomRef,
      onCloseDrawer,
      outsideClicksClose,
      escapeKeyClose,
      showBgOverlay,
      showHeaderBar,
      drawerCloseIcon,
      drawerCloseIconVariant,
      size,
      testId,
      motionProfileToUse,
    ],
  );

  // @NOTE: this useEffect allows Drawer props to stay up to date,
  // as the application re-renders and things potentially change
  useEffect(() => {
    dispatchAction({
      type: UPDATE_DRAWER_PROPS,
      payload: drawerProps,
    });
  }, [dispatchAction, drawerProps]);

  const closeDrawer = useCallback(() => {
    dispatchAction({
      type: CLOSE_DRAWER,
      payload: { id },
    });
    onCloseDrawer?.();
  }, [dispatchAction, onCloseDrawer, id]);

  const showDrawer = useCallback(() => {
    dispatchAction({
      type: OPEN_DRAWER,
      payload: drawerProps,
    });
  }, [dispatchAction, drawerProps]);

  // @NOTE: Allow Drawer to be controlled:
  useControlledOverlay({
    id,
    visible,
    showFunction: showDrawer,
    hideFunction: closeDrawer,
    overlayList: drawerList,
  });

  const onClickProps = useMemo(() => {
    return !isControlled
      ? {
          onClick: () => {
            if (drawerList.find((drawer) => drawer.id === id)) {
              closeDrawer();
            } else {
              showDrawer();
            }
          },
        }
      : {};
  }, [drawerList, isControlled, id, closeDrawer, showDrawer]);

  return target ? (
    <SmartClone {...onClickProps} id={id} domRef={localDomRef}>
      {target}
    </SmartClone>
  ) : null;
}

Drawer.displayName = "Drawer";
Drawer.Content = DrawerContent;
Drawer.Target = DrawerTarget;
