import {
  type AllDualVariantIconKeys,
  type AllIconKeys,
  type AllSingleVariantIconKeys,
  Body,
  Box,
  Icon,
  dualVariantIcons,
  singleVariantIcons,
} from "@biom3/react";
import type { ImageField } from "@prismicio/client";

function isDualVariantIcon(icon: AllIconKeys): icon is AllDualVariantIconKeys {
  return dualVariantIcons.includes(icon as AllDualVariantIconKeys);
}

function isSingleVariantIcon(
  icon: AllIconKeys,
): icon is AllSingleVariantIconKeys {
  return singleVariantIcons.includes(icon as AllSingleVariantIconKeys);
}
export const regularVariant = (
  icon: AllIconKeys,
): { icon: AllDualVariantIconKeys } | { icon: AllSingleVariantIconKeys } => {
  const val = isDualVariantIcon(icon)
    ? { icon }
    : isSingleVariantIcon(icon)
      ? { icon }
      : null;
  if (!val) throw new Error(`Invalid icon ${icon}`);
  return val;
};
export type RulesDialogInfoBoxProps = {
  testId: string;
  icons?: AllIconKeys[];
  images?: ImageField[];
  caption: string;
  title: string;
  iconsVariant?: "regular" | "bold";
};
export function RulesDialogInfoBox({
  testId,
  icons,
  iconsVariant = "regular",
  images,
  caption,
  title,
}: RulesDialogInfoBoxProps) {
  return (
    <Box
      testId={testId}
      sx={{
        display: "flex",
        flexBasis: "calc(33.33% - 4px)", // gap of 4px base.spacing.x1
        flexGrow: 1, // allows it to grow even if less than 3 columns to take up space
      }}
    >
      <Box
        testId={`${testId}__RulesDialogInfoBox`}
        sx={{
          backgroundColor: "base.color.translucent.emphasis.100",
          borderRadius: "base.borderRadius.x8",
          textAlign: "center",
          padding: "base.spacing.x3",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box testId={`${testId}__icons`}>
          {icons?.map((icon, i) => {
            return (
              // @ts-expect-error All icons support bold variants
              <Icon
                {...regularVariant(icon)}
                variant={iconsVariant}
                sx={{ width: "base.icon.size.300" }}
                key={icon}
                testId={`${testId}__icons-${i}`}
              />
            );
          })}
          {images?.map((image) => {
            return (
              <Box
                key={image.url}
                sx={{ width: "base.icon.size.300" }}
                rc={<img src={image.url || ""} alt={image.alt || ""} />}
              />
            );
          })}
        </Box>
        <Body
          size="xxSmall"
          sx={{ color: "base.color.text.body.secondary" }}
          testId={`${testId}__caption`}
        >
          {caption}
        </Body>
        <Body size="xSmall" testId={`${testId}__title`}>
          {title}
        </Body>
      </Box>
    </Box>
  );
}
