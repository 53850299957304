import type { BiomeTheme, ImageSizeVariant, MakeResponsive } from "@/types";
import { getImageSizeMapping, getResponsiveSx } from "@/utils";

export function getSizeBasedStyles({
  size,
  theme,
}: { size: ImageSizeVariant; theme: BiomeTheme }) {
  const sizeMapping = getImageSizeMapping("FramedContainer", theme);
  return {
    width: sizeMapping[size],
    height: sizeMapping[size],
  };
}

export const getResponsiveSizeBasedStyles = ({
  size,
  theme,
}: { size: MakeResponsive<ImageSizeVariant>; theme: BiomeTheme }) =>
  getResponsiveSx({ size, theme, renderSxAtSize: getSizeBasedStyles });

export const innerMediaElementSx = {
  w: "70%",
  aspectRatio: 1,
};
