import type {
  LooseAutocomplete,
  SpacingTokenPaths,
} from "@biom3/design-tokens";
import { ClassNames } from "@emotion/react";
import type {
  EmblaCarouselType,
  EmblaOptionsType,
  EmblaPluginType,
} from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
  Children,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {
  useGetMotionProfile,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  DeeplyNestedSx,
  MakeValidSxValue,
  MotionProfile,
  StandardComponentWithProps,
} from "@/types";
import { isChildSubcomponent } from "@/utils";
import {
  CarouselSimpleNextButtCon,
  CarouselSimplePreviousButtCon,
} from "./CarouselSimpleControlButtCons";
import { CarouselSimplePagination } from "./CarouselSimplePagination";
import {
  CarouselSimpleSlide,
  type CarouselSimpleSlideProps,
} from "./CarouselSimpleSlide";
import {
  baseRootSx,
  containerSx,
  controlBarBaseSx,
  viewportSx,
} from "./styles";
import { useEmblaDotButton } from "./useEmblaDotButton";

type CarouselSimpleOptions = Pick<
  EmblaOptionsType,
  // @TODO: add support for breakpoints and active props
  "loop" | "skipSnaps" | "slidesToScroll" | "dragFree" | "dragThreshold"
>;

export type CarouselSimpleProps<
  RC extends ReactElement | undefined = undefined,
> = StandardComponentWithProps<
  HTMLDivElement,
  CarouselSimpleOptions &
    BoxWithRCAndDomProps<RC> & {
      motionProfile?: MotionProfile;
      plugins?: EmblaPluginType[];
      slideWidth?: MakeValidSxValue<
        LooseAutocomplete<NonNullable<SpacingTokenPaths>>
      >;
      slideHeight?: MakeValidSxValue<
        LooseAutocomplete<NonNullable<SpacingTokenPaths>>
      >;
      slideSpacing?: MakeValidSxValue<
        LooseAutocomplete<NonNullable<SpacingTokenPaths>>
      >;
      getApi?: (api: EmblaCarouselType) => void;
      onNextSlide?: () => void;
      onPreviousSlide?: () => void;
      onSlideChange?: (index: number) => void;
      controlBarSx?: DeeplyNestedSx;
      highlightActiveSlide?: boolean;
    }
>;

export function CarouselSimple<RC extends ReactElement | undefined>({
  // @NOTE: Embla props --------------------------------
  loop = false,
  skipSnaps = true,
  slidesToScroll = 1,
  dragThreshold = 10,
  dragFree = false,
  plugins = [],
  // @NOTE: Biome props --------------------------------
  highlightActiveSlide = true,
  onNextSlide,
  onPreviousSlide,
  onSlideChange,
  testId = "CarouselSimple",
  getApi,
  children,
  className,
  motionProfile,
  slideWidth = "80%",
  slideHeight,
  slideSpacing = "base.spacing.x4",
  sx = {},
  controlBarSx = {},
  ...props
}: CarouselSimpleProps<RC>) {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const duration = useMemo(
    () =>
      motionProfileToUse === "fast"
        ? 15
        : motionProfileToUse === "gentle"
          ? 30
          : 0,
    [motionProfileToUse],
  );

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop,
      skipSnaps,
      slidesToScroll,
      dragFree,
      duration,
      dragThreshold,
    },
    plugins,
  );
  const { subcomponents: slides, otherChildren } =
    useSplitApartChildrenAndSubComponents(children, CarouselSimpleSlide);

  // @NOTE: Allow parent component to access Embla API:
  useEffect(() => {
    if (emblaApi) getApi?.(emblaApi);
  }, [emblaApi, getApi]);
  // @NOTE: ensure embla carousel re-inits when children change:
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (emblaApi) {
      getApi?.(emblaApi);
      handleSelect();
      emblaApi.on("select", handleSelect);

      return () => {
        emblaApi.off("select", handleSelect);
      };
    }

    return undefined;
  }, [slides.length, emblaApi, slidesToScroll]);
  const canScrollPrev = emblaApi?.canScrollPrev() || false;
  const canScrollNext = emblaApi?.canScrollNext() || false;
  const handleSelect = useCallback(() => {
    if (!emblaApi) return;
    const slide = emblaApi.selectedScrollSnap();
    onSlideChange?.(slide);
  }, [emblaApi, onSlideChange]);
  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
    canScrollPrev && onPreviousSlide?.();
  }, [emblaApi, canScrollPrev, onPreviousSlide]);
  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
    canScrollNext && onNextSlide?.();
  }, [emblaApi, canScrollNext, onNextSlide]);
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useEmblaDotButton(emblaApi);
  const mergedRootSx = useMemo(
    () =>
      merge(
        {
          "--slideSpacing": slideSpacing,
          "--slideHeight": slideHeight,
          "--slideWidth": slideWidth,
        },
        baseRootSx,
        sx,
      ),
    [sx, slideSpacing, slideHeight, slideWidth],
  );
  // @NOTE: only init the control bar subcomponents once the carousel has initialised
  // and there is valid data to display
  const shouldRenderControlBar = Boolean(otherChildren && scrollSnaps.length);
  const mergedControlBarSx = useMemo(
    () => merge(controlBarBaseSx, controlBarSx),
    [controlBarSx],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <Box
          {...props}
          className={cx(className, "CarouselSimple", {
            "CarouselSimple--loop": loop,
          })}
          testId={testId}
          sx={mergedRootSx}
        >
          <Box
            domRef={(el) => {
              viewportRef.current = el;
              emblaRef(el);
            }}
            sx={viewportSx}
            className="CarouselSimple__viewport"
            testId={`${testId}__viewport`}
          >
            <Stack
              direction="row"
              alignItems="stretch"
              className="CarouselSimple__container"
              testId={`${testId}__container`}
              gap="0px"
              sx={containerSx}
            >
              {Children.map(slides, (child, index) => {
                return (
                  <SmartClone
                    active={highlightActiveSlide && selectedIndex === index}
                    slideWidth={
                      (child.props as CarouselSimpleSlideProps)?.slideWidth ??
                      slideWidth
                    }
                    motionProfile={motionProfileToUse}
                    highlightActiveSlide={highlightActiveSlide}
                    className="embla__slide"
                  >
                    {child}
                  </SmartClone>
                );
              })}
            </Stack>
          </Box>

          {shouldRenderControlBar && (
            <Stack
              sx={mergedControlBarSx}
              testId={`${testId}__controlBar`}
              className="CarouselSimple__controlBar"
            >
              {Children.map(otherChildren, (child) => {
                if (isChildSubcomponent(child, CarouselSimplePagination)) {
                  return (
                    <SmartClone
                      totalPages={scrollSnaps.length}
                      currentPage={selectedIndex + 1}
                      onPageChange={(newIndex: number) =>
                        onDotButtonClick(newIndex - 1)
                      }
                    >
                      {child}
                    </SmartClone>
                  );
                }

                if (isChildSubcomponent(child, CarouselSimpleNextButtCon)) {
                  return <SmartClone onClick={scrollNext}>{child}</SmartClone>;
                }

                if (isChildSubcomponent(child, CarouselSimplePreviousButtCon)) {
                  return <SmartClone onClick={scrollPrev}>{child}</SmartClone>;
                }

                return child;
              })}
            </Stack>
          )}
        </Box>
      )}
    </ClassNames>
  );
}

CarouselSimple.displayName = "CarouselSimple";

CarouselSimple.Slide = CarouselSimpleSlide;
CarouselSimple.Pagination = CarouselSimplePagination;
CarouselSimple.NextButtCon = CarouselSimpleNextButtCon;
CarouselSimple.PreviousButtCon = CarouselSimplePreviousButtCon;
