import { EnvironmentNames } from "@/types";
import * as x from "@imtbl/x-client";

export const getCoreSdkConfig = (environment: EnvironmentNames) => {
  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return x.imxClientConfig({ environment: x.Environment.PRODUCTION });
    }
    case EnvironmentNames.SANDBOX: {
      return x.imxClientConfig({ environment: x.Environment.SANDBOX });
    }
    case EnvironmentNames.DEV: {
      return {
        baseConfig: new x.ImmutableConfiguration({
          environment: x.Environment.SANDBOX,
        }),
        overrides: {
          immutableXConfig: x.createConfig({
            basePath: "https://api.dev.x.immutable.com",
            chainID: 5,
            coreContractAddress: "0xd05323731807A35599BF9798a1DE15e89d6D6eF1",
            registrationContractAddress:
              "0x7EB840223a3b1E0e8D54bF8A6cd83df5AFfC88B2",
          }),
        },
      };
    }
    default: {
      throw new Error(`Invalid environment: "${environment}"`);
    }
  }
};
