import { type ReactElement, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { useForwardLocalDomRef } from "@/hooks/useForwardLocalDomRef";
import type {
  InputTextAlign,
  InputValidationStatus,
  StandardInputComponentWithProps,
} from "@/types";
import { cloneElementWithCssProp } from "@/utils";
import { inputResetStyles } from "../../utils/styleHelpers";
import { Box } from "../Box";
import { Icon } from "../Icon";
import {
  containerSx,
  handleSx,
  inputCss,
  tickSx,
  toggleContainerSx,
} from "./styles";

export type ToggleProps = StandardInputComponentWithProps<
  "input",
  {
    validationStatus?: InputValidationStatus;
    textAlign?: InputTextAlign;
    inputRef?: Ref<HTMLInputElement>;
  }
>;

export function Toggle<RC extends ReactElement | undefined = undefined>({
  sx = {},
  testId = "Toggle",
  name,
  id = name,
  children,
  domRef = { current: null },
  className,
  onChange,
  checked,
  value,
  disabled,
  textAlign,
  validationStatus,
  inputRef,
  rc,
  ...inputHtmlAttrs
}: RC extends undefined ? ToggleProps : ToggleProps & { rc: RC }) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const resetStyles = inputResetStyles(disabled);

  const mergedInputCss = useMemo(
    () => merge(resetStyles, inputCss),
    [resetStyles],
  );

  const memoizedInputProps = useMemo(
    () => ({
      type: "checkbox",
      "data-testid": `${testId}__input`,
      id,
      name,
      value,
      checked,
      onChange,
      disabled,
      ref: inputRef,
      css: mergedInputCss,
      className: "actualInput",
    }),
    [
      id,
      name,
      testId,
      value,
      checked,
      onChange,
      disabled,
      inputRef,
      mergedInputCss,
    ],
  );

  const input = cloneElementWithCssProp(<input />, {
    ...inputHtmlAttrs,
    ...memoizedInputProps,
  });

  return (
    <Box
      rc={rc}
      domRef={localDomRef}
      testId={testId}
      className={`${className ?? ""} Toggle`}
      sx={merge(containerSx, sx)}
    >
      {input}

      <Box
        className="toggleContainer"
        sx={toggleContainerSx}
        testId={`${testId}__toggleContainer`}
      >
        <Box
          className="toggleHandle"
          rc={<span />}
          sx={handleSx}
          testId={`${testId}__toggleContainer__toggleHandle`}
        >
          <Icon
            icon="Tick"
            variant={disabled ? "regular" : "bold"}
            testId={`${testId}__checkIcon`}
            sx={tickSx}
            className="toggleHandle__tickIcon"
          />
        </Box>
      </Box>
    </Box>
  );
}

Toggle.displayName = "Toggle";
