import { ClassNames } from "@emotion/react";
import { AnimatePresence } from "motion/react";
import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { ClearFieldButton } from "@/components/Clickable";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {
  DEFAULT_TEXT_INPUT_ALIGN,
  DEFAULT_TEXT_INPUT_SIZE,
  TEXT_INPUT_SIZES,
} from "@/constants";
import {
  useConvertSxToEmotionStyles,
  useForwardLocalDomRef,
  useGetMotionProfile,
  useGetSubcomponentChild,
  useResizeObserver,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import type { TextInputProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { cloneElementWithCssProp, getMotionProfileSx } from "@/utils";
import { TextInputAdornment } from "./TextInputAdornment";
import { TextInputButton } from "./TextInputButton";
import { TextInputFramedImage } from "./TextInputFramedImage";
import { TextInputIcon } from "./TextInputIcon";
import { TextInputStatefulButtCon } from "./TextInputStatefulButtCon";
import {
  baseInputSx,
  clearValueButtonSx,
  inputContainerBaseSx,
  leftContainerSx,
  renderContainerStyles,
  renderInputSx,
  renderResponsiveContainerStyles,
  renderResponsiveInputSx,
  rightButtonsContainerSx,
  sideContainerSx,
} from "./styles";

// @NOTE: when touching the styles inside this component, plz be aware that
// other components compose themselves using them, eg:
// <Select />
// <TextArea />

export function TextInput<RC extends ReactElement | undefined = undefined>({
  domRef,
  inputRef = { current: null },
  rc,
  placeholder,
  sx = {},
  testId = "TextInput",
  name,
  id = name,
  children,
  className,
  validationStatus,
  onChange,
  onClearValue,
  value,
  // @NOTE: normally, the ClearValueButton would be a subcomponent, and engineers
  // can choose whether they want it or not. This would mean however, that by default,
  // we have no ClearValueButton. This is not ideal - as design wants inputs to
  // feature this by default. Thus, for now, it is a simple flag instead, and is not
  // a subcomponent.
  hideClearValueButton,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  // @NOTE: this would normally be undefined, but then we get react errors
  // around a component switching between controlled and uncontrolled.
  defaultValue = "",
  // @NOTE: normally this prop would simply be called "size", but
  // native text input tags already have a property called "size",
  // (The size attribute defines the width of the <input> and the
  // height of the <select> element).
  // Thus we will need to call this property something else,
  // eg "sizeVariant"
  sizeVariant = DEFAULT_TEXT_INPUT_SIZE,
  disabled,
  motionProfile,
  ...inputDomAttributes
}: RC extends undefined ? TextInputProps : TextInputProps & { rc: RC }) {
  const localInputRef = useForwardLocalDomRef(inputRef);
  const [uncontrolledValue, setUncontrolledValue] = useState<
    ComponentPropsWithoutRef<"input">["value"]
  >(
    typeof value === "number"
      ? value.toString()
      : typeof value === "string"
        ? value
        : defaultValue,
  );

  const button = useGetSubcomponentChild(children, TextInputButton);
  const buttCon = useGetSubcomponentChild(children, TextInputStatefulButtCon);
  const icon = useGetSubcomponentChild(children, TextInputIcon);
  const framedImage = useGetSubcomponentChild(children, TextInputFramedImage);
  const adornment = useGetSubcomponentChild(children, TextInputAdornment);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    TextInputButton,
    TextInputStatefulButtCon,
    TextInputIcon,
    TextInputFramedImage,
    TextInputAdornment,
  ]);

  const rightHandButtonsContainerRef = useRef<HTMLDivElement>(null);
  const leftHandButtonsContainerRef = useRef<HTMLDivElement>(null);
  const { width: rightHandButtonsContainerWidth } = useResizeObserver(
    rightHandButtonsContainerRef,
    30,
  );
  const { width: leftHandButtonsContainerWidth } = useResizeObserver(
    leftHandButtonsContainerRef,
    30,
  );

  const onClearInputText = useCallback(() => {
    // @NOTE: if its a CONTROLLED input, simply call the
    // onClearValue prop, and then early exit
    if (value !== undefined) {
      return onClearValue?.();
    }

    // @NOTE: input is UNCONTROLLED, so we need to manually
    // update it's value:
    if (localInputRef.current) {
      localInputRef.current.value = "";
      localInputRef.current.focus();
    }
    onChange?.({
      type: "change",
      target: localInputRef?.current,
    } as ChangeEvent<HTMLInputElement>);
    setUncontrolledValue("");
    onClearValue?.();
    return false;
  }, [localInputRef, onChange, value, onClearValue]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (value === undefined) setUncontrolledValue(event.target.value);
      onChange?.(event);
    },
    [onChange, value],
  );

  const theme = useTheme();
  const defaultSize = getStartingSize(
    sizeVariant,
    DEFAULT_TEXT_INPUT_SIZE,
    TEXT_INPUT_SIZES,
  );
  const allContainerStyles = useMemo(
    () =>
      merge(
        inputContainerBaseSx,
        renderContainerStyles({ size: defaultSize, theme }),
        renderResponsiveContainerStyles({
          size: sizeVariant,
          theme,
        }),
        sx,
      ),
    [defaultSize, sizeVariant, sx, theme],
  );
  const valueToUse = value ?? uncontrolledValue;
  const allInputSx = useMemo(
    () =>
      merge(
        baseInputSx,
        renderInputSx({
          theme,
          validationStatus,
          leftHandButtonsWidth: leftHandButtonsContainerWidth,
          rightHandButtonsWidth: rightHandButtonsContainerWidth,
          textAlign,
          hasValue: Boolean(valueToUse),
        }),
        renderResponsiveInputSx({
          theme,
          validationStatus,
          size: sizeVariant,
          leftHandButtonsWidth: leftHandButtonsContainerWidth,
          rightHandButtonsWidth: rightHandButtonsContainerWidth,
          textAlign,
          hasValue: Boolean(valueToUse),
        }),
      ),
    [
      leftHandButtonsContainerWidth,
      rightHandButtonsContainerWidth,
      sizeVariant,
      textAlign,
      theme,
      validationStatus,
      valueToUse,
    ],
  );

  const motionProfileToUse = useGetMotionProfile(motionProfile);

  const convertedInputStyles = useConvertSxToEmotionStyles(
    merge(allInputSx, getMotionProfileSx(motionProfileToUse, theme)),
  );

  const hasLeftHandItems =
    framedImage || icon || (textAlign === "left" && Boolean(adornment));

  const memoizedInputProps = useMemo(
    () => ({
      disabled,
      placeholder,
      id,
      name,
      "data-testid": `${testId}__input`,
      ref: localInputRef,
      css: convertedInputStyles,
      onChange: handleInputChange,
      value: valueToUse,
      className: "TextInput__input",
    }),
    [
      convertedInputStyles,
      disabled,
      handleInputChange,
      id,
      name,
      localInputRef,
      placeholder,
      testId,
      valueToUse,
    ],
  );

  // Create the input element with both memoized props and the latest inputDomAttributes
  const input = cloneElementWithCssProp(<input />, {
    ...inputDomAttributes,
    ...memoizedInputProps,
  });

  return (
    <ClassNames>
      {({ cx }) => (
        <Stack
          rc={rc}
          domRef={domRef}
          className={cx(`${className ?? ""} TextInput`, {
            "TextInput--disabled": disabled,
          })}
          testId={testId}
          sx={allContainerStyles}
          data-validation-status={validationStatus}
        >
          {input}

          {hasLeftHandItems && (
            <Stack
              direction="row"
              alignItems="center"
              sx={merge(leftContainerSx, sideContainerSx)}
              domRef={leftHandButtonsContainerRef}
              className="leftContainer"
            >
              {framedImage || icon || null}
              {textAlign === "left" && adornment ? (
                <SmartClone
                  testId={adornment?.props?.testId || `${testId}__adornment`}
                >
                  {adornment}
                </SmartClone>
              ) : null}
            </Stack>
          )}

          <Stack
            alignItems="center"
            direction="row"
            sx={merge(rightButtonsContainerSx, sideContainerSx)}
            testId={`${testId}__rightButtonsContainer`}
            domRef={rightHandButtonsContainerRef}
            className="rightButtonsContainer"
          >
            {textAlign === "right" && Boolean(adornment) ? (
              <SmartClone
                testId={adornment?.props?.testId || `${testId}__adornment`}
              >
                {adornment}
              </SmartClone>
            ) : null}

            <AnimatePresence>
              {valueToUse && !hideClearValueButton && (
                <ClearFieldButton
                  testId={`${testId}__rightButtonsContainer__clearValueButton`}
                  sx={clearValueButtonSx}
                  onClick={onClearInputText}
                  disabled={disabled}
                />
              )}
            </AnimatePresence>

            {button && (
              <SmartClone
                disabled={disabled}
                currentInputValue={valueToUse}
                testId={
                  button.props.testId ??
                  `${testId}__rightButtonsContainer__rightButton`
                }
              >
                {button}
              </SmartClone>
            )}
            {buttCon && (
              <SmartClone
                disabled={disabled}
                currentInputValue={valueToUse}
                testId={
                  buttCon.props.testId ??
                  `${testId}__rightButtonsContainer__rightButtCon`
                }
              >
                {buttCon}
              </SmartClone>
            )}
          </Stack>

          {otherChildren}
        </Stack>
      )}
    </ClassNames>
  );
}

TextInput.displayName = "TextInput";
TextInput.Button = TextInputButton;
TextInput.Icon = TextInputIcon;
TextInput.FramedImage = TextInputFramedImage;
TextInput.StatefulButtCon = TextInputStatefulButtCon;
TextInput.Adornment = TextInputAdornment;
