export async function controlCarouselVideosPlayback(
  index: number,
  videoDomRefs: Record<string, HTMLVideoElement | null>,
) {
  // @NOTE: pause all videos
  for (const key in videoDomRefs) {
    try {
      videoDomRefs[key]?.pause();
    } catch (err) {
      // @NOTE: dont think we need to care about errors which
      // could happen here, just swallow and move on
    }
  }
  // @NOTE: play just the currently active video
  try {
    await videoDomRefs[`slide-${index}-video`]?.play();
  } catch (err) {
    // @NOTE: dont think we need to care about errors which
    // could happen here, just swallow and move on
  }
}

function extractMuxId(url: string) {
  const muxIdRegex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)\.m3u8/;
  const match = url.match(muxIdRegex);
  if (match?.[1]) {
    return match[1];
  }
  return null;
}

export function getMuxThumbnailImageUrl(videoUrl: string) {
  const muxId = extractMuxId(videoUrl);
  return muxId
    ? `https://image.mux.com/${muxId}/thumbnail.webp?width=720&time=2`
    : undefined;
}
