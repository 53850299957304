import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Button } from "@/components/Clickable";
import type { ButtonProps } from "@/types";

type MenuItemButtonProps<RC extends ReactElement | undefined = undefined> =
  ButtonProps<RC>;

export function MenuItemButton<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  size = "small",
  variant = "tertiary",
  ...props
}: MenuItemButtonProps<RC>) {
  const { onClick } = "onClick" in props ? props : { onClick: null };
  const mergedSx = useMemo(() => merge({ flexShrink: 0 }, sx), [sx]);
  return (
    <Button
      {...props}
      sx={mergedSx}
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (typeof onClick === "function") {
          onClick(ev);
        }
      }}
      size={size}
      variant={variant}
    />
  );
}

MenuItemButton.displayName = "MenuItem.Button";
MenuItemButton.Icon = Button.Icon;
MenuItemButton.Logo = Button.Logo;
MenuItemButton.FramedImage = Button.FramedImage;
