import type { ReactElement } from "react";

import { Heading } from "@/components/Text";
import type { HeadingProps } from "@/types";

export function ConfirmationDialogContentHeading<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: HeadingProps<RC>) {
  return <Heading {...props} rc={rc} />;
}
