import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { BodyProps } from "@/types";
import { Body } from "../Text/Body";
import { adornmentSx } from "./styles";

export type TextInputAdornmentProps<
  RC extends ReactElement | undefined = undefined,
> = BodyProps<RC> & {};

export function TextInputAdornment<
  RC extends ReactElement | undefined = undefined,
>({ children, sx = {}, ...props }: TextInputAdornmentProps<RC>) {
  const allStyles = merge(adornmentSx, sx);

  return (
    <Body {...props} sx={allStyles}>
      {children}
    </Body>
  );
}

TextInputAdornment.displayName = "TextInput.Adornment";
