import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Chip, type ChipProps } from "@/components/Chip";
import { useTheme } from "@/hooks";
import { chipBaseSx } from "./styles";

export type CardHeroChipProps<RC extends ReactElement | undefined> =
  ChipProps<RC>;

export function CardHeroChip<RC extends ReactElement | undefined = undefined>({
  size = "medium",
  rc = <span />,
  className,
  testId = "CardHeroChip",
  sx = {},
  ...props
}: CardHeroChipProps<RC>) {
  const {
    base: { colorMode },
  } = useTheme();
  const mergedSx = useMemo(() => merge(chipBaseSx, sx), [sx]);
  return (
    <Chip
      {...props}
      className={`${className ?? ""} CardHeroChip`}
      size={size}
      rc={rc}
      testId={testId}
      sx={mergedSx}
      variant={colorMode === "onLight" ? "inverse" : "regular"}
    />
  );
}

CardHeroChip.displayName = "CardHero.Chip";
CardHeroChip.Icon = Chip.Icon;
CardHeroChip.FramedImage = Chip.FramedImage;
CardHeroChip.Avatar = Chip.Avatar;
