import { Modal } from "@/components/Modal";
import { ModalTarget } from "@/components/Modal/ModalTarget";
import type { ModalComponentProps } from "@/types";

import { LoadingOverlayContent } from "./LoadingOverlayContent";

export type LoadingOverlayProps = ModalComponentProps & {};

export function LoadingOverlay({
  bgOverlaySx = {},
  testId = "LoadingOverlay",
  ...props
}: LoadingOverlayProps) {
  return (
    <Modal
      {...props}
      testId={testId}
      ContentSubcomponent={LoadingOverlayContent}
      bgOverlaySx={bgOverlaySx}
    />
  );
}

LoadingOverlay.displayName = "LoadingOverlay";
LoadingOverlay.Target = ModalTarget;
LoadingOverlay.Content = LoadingOverlayContent;
