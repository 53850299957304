import type { AllLogoKeys } from "@/components/Logo/shared";
import { getCacheItem, setCacheItem } from "./cacheHelpers";

export async function fetchLogoContent(
  logo: AllLogoKeys,
): Promise<string[] | null> {
  const localStorageKey = `logo-${logo}`;
  const cachedIcon = await getCacheItem(localStorageKey);

  if (cachedIcon) {
    return cachedIcon as string[];
  }

  try {
    const resp = await fetch(
      `https://biome.immutable.com/hosted-assets/logos/${logo}.json`,
    );
    const data = await resp.json();
    setCacheItem(localStorageKey, data);
    return data;
  } catch (err) {
    console.error("🧬 BIOME (logo) Error 🚨:", err);
    return null;
  }
}
