import { useTheme } from "@emotion/react";
import type { Properties } from "csstype";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Divider } from "@/components/Divider";
import { ShimmerBox, ShimmerCircle } from "@/components/Shimmer";
import { Stack } from "@/components/Stack";
import { Body } from "@/components/Text";
import {
  DEFAULT_MENU_ITEM_SHIMMER_TYPE,
  DEFAULT_MENU_ITEM_SIZE,
  MENU_ITEM_SIZES,
} from "@/constants";
import type { ImageSizeVariant, MenuItemShimmerProps } from "@/types";
import { getImageSizeMapping } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  isImageSizeAnArray,
  useGetBiomeImageSizeFromMenuItemSize,
  useGetCaptionTextSizeFromMenuItemSize,
  useGetLabelTextSizeFromMenuItemSize,
} from "./shared";
import {
  baseContainerStyles,
  baseShimmerOuterSx,
  getBottomSlotDividerStyles,
  getResponsiveBottomSlotDividerStyles,
  getResponsiveShimmerTopContainerStyles,
  getShimmerTopContainerStyles,
  renderResponsiveOuterStyles,
  renderShimmerOuterStyles,
} from "./styles";

const SPAN_RC = <span />;

export function MenuItemShimmer<
  RC extends ReactElement | undefined = undefined,
>({
  size = DEFAULT_MENU_ITEM_SIZE,
  emphasized,
  sx = {},
  testId = "MenuItemShimmer",
  shimmerType = DEFAULT_MENU_ITEM_SHIMMER_TYPE,
  className,
  ...props
}: MenuItemShimmerProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );

  const mergedContainerSx = useMemo(
    () =>
      merge(
        baseShimmerOuterSx,
        renderShimmerOuterStyles({ size: startingSize, theme, shimmerType }),
        renderResponsiveOuterStyles({ size, theme }),
        {
          bgc: emphasized
            ? baseContainerStyles["&.MenuItem--emphasized"].bgc
            : "transparent",
        },
        sx,
      ),
    [sx, size, theme, startingSize, emphasized, shimmerType],
  );
  const labelTextSize = useGetLabelTextSizeFromMenuItemSize(size);
  const captionTextSize = useGetCaptionTextSizeFromMenuItemSize(size);
  const leftFramedComponetSize = useGetBiomeImageSizeFromMenuItemSize(size);
  const leftAreaSizeMapping = getImageSizeMapping("FramedContainer", theme);
  const leftAreaWidth = useMemo(() => {
    let width: Properties["width"] | (Properties["width"] | null)[];
    if (isImageSizeAnArray(leftFramedComponetSize)) {
      width = leftFramedComponetSize.map((s) =>
        s !== null ? leftAreaSizeMapping[s] : null,
      );
    }
    width = leftAreaSizeMapping[leftFramedComponetSize as ImageSizeVariant];
    return width;
  }, [leftFramedComponetSize, leftAreaSizeMapping]);
  const showBottomSlotShimmer = shimmerType === "withBottomSlot";
  const bottomSlotDividerSx = useMemo(() => {
    return merge(
      getBottomSlotDividerStyles({ theme, size: startingSize }),
      getResponsiveBottomSlotDividerStyles({ size, theme }),
    );
  }, [startingSize, size, theme]);
  const topContainerSx = useMemo(
    () =>
      merge(
        getShimmerTopContainerStyles({ theme, size: startingSize }),
        getResponsiveShimmerTopContainerStyles({ size, theme }),
      ),
    [size, startingSize, theme],
  );

  return (
    <Stack
      {...props}
      testId={testId}
      sx={mergedContainerSx}
      direction="row"
      className={`${className ?? ""} MenuItemShimmer MenuItemShimmer--${shimmerType}`}
    >
      <ShimmerCircle
        className="MenuItemShimmer__leftArea"
        rc={SPAN_RC}
        radius={leftAreaWidth}
        sx={{
          alignSelf: "flex-start",
        }}
      />
      <Stack gap="0px" sx={{ flex: 1 }} rc={SPAN_RC}>
        <Stack
          justifyContent="center"
          sx={topContainerSx}
          gap="0px"
          rc={SPAN_RC}
        >
          <Body
            shimmer={1}
            size={labelTextSize}
            shimmerSx={{ w: "base.spacing.x14" }}
          />
          <Body
            shimmer={1}
            size={captionTextSize}
            shimmerSx={{ w: "base.spacing.x24" }}
          />
        </Stack>

        {showBottomSlotShimmer && (
          <Stack gap="0px" rc={SPAN_RC}>
            <Divider sx={bottomSlotDividerSx} size="xSmall" />
            <Stack
              sx={{ minh: "base.spacing.x5" }}
              justifyContent="center"
              rc={SPAN_RC}
            >
              <ShimmerBox sx={{ w: "base.spacing.x8", h: "base.spacing.x2" }} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

MenuItemShimmer.displayName = "MenuItemShimmer";
