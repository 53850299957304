import { type ReactElement, type ReactNode, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";

import type { SubcomponentElement, UnknownReactProps } from "@/types/core";
import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

type SubcomponentWithExclude = {
  subcomponent: SubcomponentElement;
  excludeParent?: SubcomponentElement | Array<SubcomponentElement>;
};

function checkForSubcomponentWithExclude(
  child: ReactNode,
  subcomponentObj: SubcomponentWithExclude | SubcomponentElement | undefined,
): child is ReactElement<UnknownReactProps> {
  if (!subcomponentObj) {
    return false;
  }

  if ("subcomponent" in subcomponentObj) {
    return isChildSubcomponent(
      child,
      subcomponentObj.subcomponent,
      subcomponentObj.excludeParent,
    );
  }

  return isChildSubcomponent(child, subcomponentObj);
}

type ElementOrUndefined = ReactElement<UnknownReactProps> | undefined;

export function useGetManySingleSubcomponentChildren(
  children: ReactNode,
  subcomponentList: Array<
    SubcomponentWithExclude | SubcomponentElement | undefined
  >,
) {
  const { subcomponents, otherChildren } = useMemo(() => {
    let sub1Child: ElementOrUndefined = undefined;
    let sub2Child: ElementOrUndefined = undefined;
    let sub3Child: ElementOrUndefined = undefined;
    let sub4Child: ElementOrUndefined = undefined;
    let sub5Child: ElementOrUndefined = undefined;
    let sub6Child: ElementOrUndefined = undefined;
    let sub7Child: ElementOrUndefined = undefined;
    let sub8Child: ElementOrUndefined = undefined;
    let sub9Child: ElementOrUndefined = undefined;
    let sub10Child: ElementOrUndefined = undefined;
    let sub11Child: ElementOrUndefined = undefined;
    let sub12Child: ElementOrUndefined = undefined;
    let sub13Child: ElementOrUndefined = undefined;
    let sub14Child: ElementOrUndefined = undefined;
    let sub15Child: ElementOrUndefined = undefined;
    let sub16Child: ElementOrUndefined = undefined;
    let sub17Child: ElementOrUndefined = undefined;
    let sub18Child: ElementOrUndefined = undefined;
    let sub19Child: ElementOrUndefined = undefined;
    let sub20Child: ElementOrUndefined = undefined;
    const otherChildren: Array<ReactNode> = [];

    flattenChildren(children).forEach((child) => {
      switch (true) {
        case checkForSubcomponentWithExclude(child, subcomponentList?.[0]):
          sub1Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[1]):
          sub2Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[2]):
          sub3Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[3]):
          sub4Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[4]):
          sub5Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[5]):
          sub6Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[6]):
          sub7Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[7]):
          sub8Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[8]):
          sub9Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[9]):
          sub10Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[10]):
          sub11Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[11]):
          sub12Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[12]):
          sub13Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[13]):
          sub14Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[14]):
          sub15Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[15]):
          sub16Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[16]):
          sub17Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[17]):
          sub18Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[18]):
          sub19Child = child;
          break;

        case checkForSubcomponentWithExclude(child, subcomponentList?.[19]):
          sub20Child = child;
          break;

        // If the child is not any of the above subcomponents,
        // add it to the otherChildren array
        default:
          otherChildren.push(child);
          break;
      }
    });

    return {
      otherChildren,
      subcomponents: [
        sub1Child,
        sub2Child,
        sub3Child,
        sub4Child,
        sub5Child,
        sub6Child,
        sub7Child,
        sub8Child,
        sub9Child,
        sub10Child,
        sub11Child,
        sub12Child,
        sub13Child,
        sub14Child,
        sub15Child,
        sub16Child,
        sub17Child,
        sub18Child,
        sub19Child,
        sub20Child,
      ] as Array<ElementOrUndefined>,
    };
  }, [children, subcomponentList]);

  return {
    subcomponents,
    otherChildren,
  };
}
