import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { BodyProps } from "@/types";
import { Body } from "../Text/Body/Body";

export type CardDescriptionProps<
  RC extends ReactElement | undefined = undefined,
> = BodyProps<RC>;

export function CardDescription<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  weight = "regular",
  sx = {},
  rc = <span />,
  className,
  ...props
}: CardDescriptionProps<RC>) {
  return (
    <Body
      {...props}
      className={`${className ?? ""} CardDescription`}
      sx={merge(
        {
          color: "base.color.text.body.secondary",
          textAlign: "left",
        },
        sx,
      )}
      size={size}
      weight={weight}
      rc={rc}
    />
  );
}

CardDescription.displayName = "Card.Description";
