import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from "@/constants";
import { useGetCurrentSizeClass, useGetMotionProfile, useTheme } from "@/hooks";
import type { ButtonProps } from "@/types";
import { convertVariantToValidCssClass, getMotionProfileSx } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";
import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

import { BaseClickable } from "../BaseClickable/BaseClickable";
import { ButtonFramedImage } from "./ButtonFramedImage";
import { ButtonIcon } from "./ButtonIcon";
import { ButtonLogo } from "./ButtonLogo";
import {
  baseButtonInnerSpanStyles,
  getDefaultButtonStyles,
  getResponsiveButtonStyles,
} from "./styles";

const INTERNAL_CONTAINER_RC = <span />;

export function Button<RC extends ReactElement | undefined = undefined>({
  children,
  sx = {},
  variant = "primary",
  size = DEFAULT_BUTTON_SIZE,
  testId,
  className,
  motionProfile,
  ...props
}: ButtonProps<RC>) {
  const themeProps = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_BUTTON_SIZE,
    BUTTON_SIZES,
  );
  const allContainerSx = merge(
    getDefaultButtonStyles({ size: startingSize, variant, themeProps }),
    getResponsiveButtonStyles({
      size,
      variant,
      themeProps,
    }),
    getMotionProfileSx(motionProfileToUse, themeProps),
    {
      "&::before": {
        ...getMotionProfileSx(motionProfileToUse, themeProps),
      },
    },
    themeProps.components?.Button?.sxOverride ?? {},
    sx,
  );

  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );

  return (
    <BaseClickable
      {...props}
      sx={allContainerSx}
      testId={testId}
      className={`${
        className ?? ""
      } Button Button--${convertVariantToValidCssClass(
        variant,
      )} Button--${currentSizeClass}`}
    >
      <Stack
        gap="0"
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={baseButtonInnerSpanStyles}
        rc={INTERNAL_CONTAINER_RC}
        className="Button__innerSpan"
      >
        {Children.map(flattenedChildren, (child) => {
          if (
            isChildSubcomponent(child, ButtonIcon) ||
            isChildSubcomponent(child, ButtonLogo) ||
            isChildSubcomponent(child, ButtonFramedImage)
          ) {
            // @NOTE: get sub-component children, with some special extra props
            return (
              <SmartClone
                size={size}
                variant={variant}
                className={`${child.props.className ?? ""} Button__iconOrLogo`}
                testId={
                  child.props.testId ? child.props.testId : `${testId}__icon`
                }
              >
                {child}
              </SmartClone>
            );
          }

          return (
            <Box
              rc={INTERNAL_CONTAINER_RC}
              className="Button__innerSpan__innerContent"
            >
              {child}
            </Box>
          );
        })}
      </Stack>
    </BaseClickable>
  );
}

Button.displayName = "Button";
Button.Icon = ButtonIcon;
Button.Logo = ButtonLogo;
Button.FramedImage = ButtonFramedImage;
