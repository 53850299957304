import { Badge, type BadgeProps } from "@/components/Badge";
import type { ReactElement } from "react";

export function SpineMenuItemBadge<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: BadgeProps<RC>) {
  return <Badge {...props} />;
}

SpineMenuItemBadge.displayName = "SpineMenu.Item.Badge";
