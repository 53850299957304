import {
  type ComponentProps,
  type ReactElement,
  type ReactNode,
  isValidElement,
} from "react";

import type { SubcomponentElement } from "@/types/core";

export function checkForElementChildType(
  child: ReactNode,
): child is ReactElement & { type: { displayName: string } } {
  return (
    isValidElement(child) &&
    !childIsStringOrNumber(child) &&
    typeof child?.type !== "boolean"
  );
}

function childMatchesExcludes(
  child: ReactElement,
  excludeParent: SubcomponentElement | Array<SubcomponentElement>,
) {
  if (Array.isArray(excludeParent)) {
    return excludeParent.some((excluded) => child.type === excluded);
  }
  return child.type === excludeParent;
}

type ChildWithDisplayName = {
  type: { displayName: string };
};
type PropsWithChildren = { children?: ReactNode };

export function isChildSubcomponent<
  Sub extends SubcomponentElement | Array<SubcomponentElement>,
>(
  child: ReactNode,
  subcomponent: Sub,
  excludeParent?: SubcomponentElement | Array<SubcomponentElement>,
): child is Sub extends Array<SubcomponentElement>
  ? ReactElement<ComponentProps<Sub[number]>> & ChildWithDisplayName
  : Sub extends SubcomponentElement
    ? ReactElement<ComponentProps<Sub>> & ChildWithDisplayName
    : unknown {
  if (checkForElementChildType(child)) {
    if (isValidElement(child)) {
      const elementType = child.type as { displayName?: string };
      if (Array.isArray(subcomponent)) {
        return subcomponent.some((sub) => elementType === sub);
      }
      if (elementType === subcomponent) {
        return true;
      }

      // Recursively check if the child props.children contains a supported subcomponent
      if (
        typeof child.type === "function" &&
        (child.props as PropsWithChildren)?.children
      ) {
        // Dont go deeper if the child is a parent which should be excluded
        if (!(excludeParent && childMatchesExcludes(child, excludeParent))) {
          return isChildSubcomponent(
            (child.props as PropsWithChildren)?.children,
            subcomponent,
          );
        }
      }
    }
  }

  // Recursively check if the child is an array which contains a supported subcomponent
  // This could be the case for Overlay components, eg: <Modal><Modal.Target /><Modal.Content /></Modal>
  if (Array.isArray(child)) {
    return child.some((c) => {
      return isChildSubcomponent(c, subcomponent);
    });
  }

  return false;
}

export function getSubcomponentChild(
  children: ReactNode | ReactNode[],
  subcomponent: SubcomponentElement,
) {
  if (Array.isArray(children)) {
    return children.find((child) => isChildSubcomponent(child, subcomponent));
  }
  return isChildSubcomponent(children, subcomponent) ? children : null;
}

export function childIsStringOrNumber(
  child?: ReactNode,
): child is string | number {
  return typeof child === "string" || typeof child === "number";
}
