import type { BiomeTheme } from "@/types";

export const containerSx = {
  w: "base.spacing.x5",
  h: "base.spacing.x5",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
  pos: "relative",
};

export const inputCssStyles = (theme: BiomeTheme) => ({
  "&:disabled + .radioContainer": {
    boxShadow: "none",
  },

  "&:checked + .radioContainer": {
    borderWidth: theme.base.border.size[200],
    borderColor: theme.base.color.translucent.standard[1000],
  },

  "&:checked + .radioContainer > .checkCircle": {
    opacity: "1",
  },

  "&:hover:not(:disabled) + .radioContainer": {
    borderColor: theme.base.color.translucent.standard[800],
    boxShadow: theme.base.shadow[300],
    backgroundColor: theme.base.color.translucent.emphasis[200],
  },

  "&:checked:hover:not(:disabled) + .radioContainer": {
    borderColor: theme.base.color.text.body.secondary,
    borderWidth: theme.base.border.size[100],
  },

  "&:checked:not(:disabled):hover + .radioContainer > .checkCircle": {
    scale: "0.6",
  },
});

export const radioContainerSx = {
  borderStyle: "solid",
  borderWidth: "base.border.size.100",
  borderColor: "base.color.translucent.standard.500",
  borderRadius: "base.borderRadius.x6",
  bg: "base.color.translucent.emphasis.100",
  w: "base.spacing.x5",
  h: "base.spacing.x5",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
  transitionProperty: "border, background-color, box-shadow",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",
};

export const checkSx = {
  pos: "relative",
  pointerEvents: "none",
  w: "10px",
  h: "10px",
  borderRadius: "50%",
  backgroundColor: "base.color.translucent.standard.1000",
  opacity: "0",
  transitionProperty: "opacity, scale",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",
};
