import { type ReactNode, useContext } from "react";
import { merge } from "ts-deepmerge";

import { useTheme } from "@/hooks";
import { ClientOnlyPortal } from "@/overlays/ClientOnlyPortal";
import { TooltipOverlay } from "@/overlays/TooltipOverlay";

import { Box } from "@/components";
import { BiomePortalIdContext } from "../BiomePortalIdProvider/BiomePortalIdProvider";
import { positionedOverlayContainerCss } from "../shared";
import { BiomeTooltipProvider } from "./tooltipContext";

const SELECTOR = "tooltip-container";
export function MountedTooltipOverlayAndProvider({
  children,
}: {
  children: ReactNode;
}) {
  const portalId = useContext(BiomePortalIdContext);
  const {
    base: { zLevel },
  } = useTheme();
  return (
    <BiomeTooltipProvider>
      {children}

      {/* TOOLTIP */}
      <ClientOnlyPortal selector={`#${SELECTOR}${portalId}`}>
        <TooltipOverlay />
      </ClientOnlyPortal>
      <Box
        id={`${SELECTOR}${portalId}`}
        sx={merge(positionedOverlayContainerCss, {
          zIndex: zLevel.tooltip,
        })}
      />
    </BiomeTooltipProvider>
  );
}
