import type { ReactElement } from "react";

import type { BoxWithRCAndDomProps } from "@/types";
import { Box } from "../Box";

export type PopoverContentProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & {
  id?: string;
};

export function PopoverContent<
  RC extends ReactElement | undefined = undefined,
>({ children, domRef, ...props }: PopoverContentProps<RC>) {
  return (
    <Box {...props} domRef={domRef}>
      {children}
    </Box>
  );
}

PopoverContent.displayName = "Popover.Content";
