import type { BodyWeight } from "@biom3/design-tokens";
import type { ReactElement } from "react";

import type { LoopingTextProps } from "@/types";

import { LoopingText } from "../Text/LoopingText/LoopingText";

export function LoadingOverlayContentLoopingText<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>(props: LoopingTextProps<RC, Use>) {
  const { weight, ...propsMinusWeight } =
    "weight" in props ? props : { ...props, weight: "bold" };
  const typedProps = propsMinusWeight as LoopingTextProps;
  return <LoopingText {...typedProps} weight={weight as BodyWeight} />;
}

LoadingOverlayContentLoopingText.displayName =
  "LoadingOverlay.Content.LoopingText";
