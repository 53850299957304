import {
  AspectRatioImage,
  Body,
  ButtCon,
  Button,
  CountdownTimer,
  Heading,
  Stack,
} from "@biom3/react";
import type { Content } from "@prismicio/client";
import type { WishlistProps } from "@ui-kit";
import { WishlistButton } from "@ui-kit/CTAButtons/WishlistButton";
import { trackGameDetailsEvent } from "@ui-kit/utils";
import NextLink from "next/link";

type PromotionTileProps = {
  countdown?: string | null;
  imageUrl?: string | null;
  title?: string | null;
  caption?: string | null;
  description?: string | null;
  primaryCta?: { url: string; text?: string; target?: string };
  secondaryCta?: { url: string; text?: string; target?: string };
  shareCta?: { url: string; target?: string };
  wishlistProps: WishlistProps;
  game?: Content.GameDocument;
};

export function PromotionTile({
  countdown,
  imageUrl,
  title,
  caption,
  description,
  primaryCta,
  secondaryCta,
  shareCta,
  wishlistProps,
  game,
}: PromotionTileProps) {
  const promotedGameUid = game?.uid;
  const templateTier = game?.data
    ?.template_tier as Content.GameDocumentData["template_tier"];

  const onShareClick = () => {
    trackGameDetailsEvent({
      game,
      identifier: "PromotionTile",
      control: "PromotionShareClicked",
      controlType: "Button",
      extras: {
        shareUrl: shareCta?.url,
        shareTarget: shareCta?.target,
        ...(promotedGameUid ? { promotedGameUid } : {}),
      },
    });
  };

  const onSecondaryCtaClick = () => {
    trackGameDetailsEvent({
      game,
      identifier: "PromotionTile",
      control: "PromotionSecondaryClicked",
      controlType: "Button",
      extras: {
        secondaryUrl: secondaryCta?.url,
        secondaryTarget: secondaryCta?.target,
        secondaryText: secondaryCta?.text,
        ...(promotedGameUid ? { promotedGameUid } : {}),
      },
    });
  };

  const onPrimaryCtaClick = () => {
    trackGameDetailsEvent({
      game,
      identifier: "PromotionTile",
      control: "PromotionPimaryClicked",
      controlType: "Button",
      extras: {
        primaryUrl: primaryCta?.url,
        primaryTarget: primaryCta?.target,
        primaryText: primaryCta?.text,
        ...(promotedGameUid ? { promotedGameUid } : {}),
      },
    });
  };

  const withSingleCta =
    (primaryCta?.text && !secondaryCta?.text) ||
    (!primaryCta?.text && secondaryCta?.text);

  const isLargePaddingDisplayMode = !imageUrl && !countdown;
  const shouldUseInternalWishlistButton =
    primaryCta?.url === "internal:wishlist";

  return (
    <Stack
      direction={{
        default: "column",
        large: "row",
      }}
      alignItems="stretch"
      sx={{
        p: isLargePaddingDisplayMode ? "base.spacing.x8" : "base.spacing.x4",
        bg: "base.color.translucent.emphasis.100",
        backdropFilter: "base.frost.400",
        brad: "base.borderRadius.x10",
      }}
      gap="base.spacing.x8"
    >
      {imageUrl || countdown ? (
        <Stack sx={{ pos: "relative" }}>
          {imageUrl && (
            <AspectRatioImage
              imageUrl={imageUrl}
              aspectRatio="4/3"
              responsiveSizes={[
                256, 370, 512, 640, 720, 860, 1024, 1280, 1440, 1600, 1920,
                2048, 2160,
              ]}
              relativeImageSizeInLayout="334px"
              sx={{ brad: "base.borderRadius.x6", w: "100%", h: "100%" }}
            />
          )}
          {countdown && (
            <CountdownTimer
              futureTarget={new Date(countdown)}
              variant="inverse"
              sx={{
                pos: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            />
          )}
        </Stack>
      ) : null}
      <Stack sx={{ flex: 1 }} gap="base.spacing.x6">
        <Stack
          sx={{
            minh: isLargePaddingDisplayMode ? "unset" : "base.spacing.x32",
          }}
          gap="0px"
        >
          <Heading
            // biome-ignore lint/a11y/useHeadingContent: <explanation>
            rc={<h4 />}
            size={templateTier === "premium variant C" ? "small" : "medium"}
            weight="bold"
            sx={{ pr: isLargePaddingDisplayMode ? "unset" : "base.spacing.x8" }}
          >
            {title}
          </Heading>
          {caption && (
            <Body sx={{ c: "base.color.text.body.secondary" }}>{caption}</Body>
          )}
          {description && (
            <Body sx={{ mt: "base.spacing.x3" }}>{description}</Body>
          )}
        </Stack>

        <Stack
          gap="base.spacing.x4"
          justifyContent="space-between"
          direction={{ default: "column", large: "row" }}
          sx={{
            mt: "auto",
            ...(withSingleCta ? { justifyContent: "flex-end" } : {}),
          }}
        >
          {secondaryCta?.text && (
            <Button
              variant="tertiary"
              sx={{
                flexGrow: 1,
                // @NOTE: the below is needed to combat a bad safari render issue
                // where if you render backdropFilter onntop of another
                // backdropFilter, the top-most backdropFilter renders as all white
                backdropFilter: "unset",
              }}
              rc={
                <NextLink
                  onClick={onSecondaryCtaClick}
                  target={secondaryCta.target}
                  href={secondaryCta.url}
                  referrerPolicy="no-referrer"
                />
              }
            >
              {secondaryCta.text}
            </Button>
          )}
          {primaryCta?.text && shouldUseInternalWishlistButton ? (
            <WishlistButton
              analyticsExtras={{ promotedGameUid }}
              trackGameDetailsEventIdentifier="PromotionTile"
              wishlistProps={wishlistProps}
              sx={{ flexGrow: 1 }}
              showButtonIcon={false}
            />
          ) : (
            <Button
              variant="secondary"
              sx={{
                ...(withSingleCta
                  ? {
                      flex: { default: 1, xxLarge: 0.5 },
                    }
                  : { flex: { default: "1 1 auto", large: "1 1 0%" } }),
              }}
              rc={
                <NextLink
                  onClick={onPrimaryCtaClick}
                  target={primaryCta?.target}
                  href={{ pathname: primaryCta?.url }}
                  referrerPolicy="no-referrer"
                />
              }
            >
              {primaryCta?.text}
            </Button>
          )}
        </Stack>

        {shareCta && (
          <ButtCon
            sx={{
              pos: "absolute",
              top: "base.spacing.x4",
              right: "base.spacing.x4",
            }}
            icon="SocialShare"
            variant="tertiary"
            size="small"
            rc={
              <NextLink
                href={{ pathname: shareCta.url }}
                onClick={onShareClick}
                target={shareCta.target}
                referrerPolicy="no-referrer"
              />
            }
          />
        )}
      </Stack>
    </Stack>
  );
}
