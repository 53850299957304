import type { DistributiveOmit } from "@/types";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TOOLBAR_SIZE, TOOLBAR_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type { ButtConProps, MakeResponsive, ToolbarSize } from "@/types";
import { getStartingSize } from "../../utils/styleHelpers";
import { ButtCon } from "../Clickable";
import { getLeftButtConStyles, getResponsiveLeftButtConStyles } from "./styles";

export type ToolbarLeftButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "size"> & {
  size?: MakeResponsive<ToolbarSize>;
};

export function ToolbarLeftButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  size = DEFAULT_TOOLBAR_SIZE,
  variant = "tertiary",
  sx = {},
  ...props
}: ToolbarLeftButtConProps<RC>) {
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_TOOLBAR_SIZE,
    TOOLBAR_SIZES,
  );
  const buttConStyles = merge(
    {
      flexShrink: 0,
    },
    getLeftButtConStyles({ themeProps, size: startingSize }),
    getResponsiveLeftButtConStyles({
      themeProps,
      size,
    }),
    sx,
  );
  return (
    <ButtCon {...props} variant={variant} size={size} sx={buttConStyles} />
  );
}

ToolbarLeftButtCon.displayName = "Toolbar.LeftButtCon";
ToolbarLeftButtCon.SvgIcon = ButtCon.SvgIcon;
