import {
  type Content,
  type ContentRelationshipField,
  type FilledContentRelationshipField,
  type FilledLinkToWebField,
  LinkType,
  type TimestampField,
} from "@prismicio/client";

export const isWebLink = (
  field: ContentRelationshipField | undefined,
): field is FilledLinkToWebField => {
  return field?.link_type === LinkType.Web;
};

export const isLinkedDocument = <DocType = string, DocDataType = unknown>(
  field: ContentRelationshipField | undefined,
): field is FilledContentRelationshipField<DocType, string, DocDataType> => {
  return field?.link_type === LinkType.Document;
};

export const getWebLink = (
  field: ContentRelationshipField | undefined,
): FilledLinkToWebField => {
  if (isWebLink(field)) return field;

  return {
    link_type: LinkType.Web,
    text: field?.text || "",
    url: "#",
  };
};

export const getLinkedDocument = <DocType = string, DocDataType = unknown>(
  field: ContentRelationshipField | undefined,
): FilledContentRelationshipField<DocType, string, DocDataType> | undefined => {
  if (isLinkedDocument<DocType, DocDataType>(field)) return field;

  return undefined;
};

export const getGamePageLink = (game: Content.GameDocument) => {
  // game has not been assigned a template yet, so link to external website
  if (game.data.template_tier === "none") {
    return getWebLink(game.data.website)?.url || "#";
  }

  // game has been assigned a template, link to internal game details page
  return `/games/${game?.uid}`;
};

export function getPrismicDate(dateField: TimestampField): Date | undefined {
  if (!dateField) {
    return undefined;
  }

  const date = new Date(dateField);

  if (Number.isNaN(date.getTime())) {
    return undefined;
  }

  return date;
}

export const getLinkedGenre = (field: ContentRelationshipField | undefined) => {
  const genre = getLinkedDocument<
    Content.GameGenreDocument,
    Content.GameGenreDocumentData
  >(field);
  return genre?.data?.name?.toLowerCase() || "";
};

export const getLinkedPlatform = (
  field: ContentRelationshipField | undefined,
) => {
  const platform = getLinkedDocument<
    Content.GamePlatformDocument,
    Content.GamePlatformDocumentData
  >(field);
  return platform?.data?.name?.toLowerCase() || "";
};

export const getGameMetadata = (game: Content.GameDocument) => {
  return {
    image: game.data.logo_square.url || "",
    link: getGamePageLink(game),
    name: game.data.name || "",
    description: game.data.description || "",
    genres: game.data.genres
      .map((field) => getLinkedGenre(field.genre))
      .filter(Boolean),
    platforms: game.data.platforms
      .map((field) => getLinkedPlatform(field.platform))
      .filter(Boolean),
  };
};
