import type { BiomeTheme, DeeplyNestedSx, MakeResponsive } from "@/types";
import { getResponsiveSx } from "../../utils/styleHelpers";
import { getBodyTextStyles } from "../../utils/textStyleHelpers";
import type { StepperSize } from "./shared";

export const baseStepperSx = {
  width: "100%",

  "&.Stepper--horizontal": {
    gap: "base.spacing.x12",
    flexDirection: "row",
  },

  "&.Stepper--vertical": {
    flexDirection: "column",

    "&.Stepper--small": {
      gap: "base.spacing.x8",
    },
    "&.Stepper--medium": {
      gap: "base.spacing.x10",
    },
  },
};

export const baseStepSx: DeeplyNestedSx = {
  d: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  c: "base.color.text.body.primary",
  bg: "base.color.translucent.emphasis.300",
  flex: 1,
  brad: "base.borderRadius.x25",
  whiteSpace: "nowrap",
  textAlign: "left",
  pos: "relative",

  "&::after": {
    content: '""',
    pos: "absolute",
  },

  "&[disabled]": {
    cursor: "default",
  },

  "&:hover:not(.StepperStep--active):not([disabled])": {
    bg: "base.color.translucent.emphasis.400",
    boxShadow: ({ base }) =>
      `inset 0 0 0 1px ${base.color.translucent.standard[1000]}`,

    ".StepperStep__badge": {
      bg: "base.color.translucent.emphasis.300",
    },
  },

  "&.StepperStep--complete": {
    bg: "base.color.translucent.emphasis.300",
    boxShadow: ({ base }) =>
      `inset 0 0 0 1px ${base.color.translucent.standard[500]}`,
  },

  "&.StepperStep--active": {
    bg: "base.color.translucent.inverse.400",
    boxShadow: ({ base }) =>
      `inset 0 0 0 2px ${base.color.translucent.standard[1000]}`,
  },

  "&:not(.StepperStep--lastStep)::after": {},

  "&.StepperStep--horizontal::after": {
    width: "base.spacing.x8",
    height: "base.border.size.100",
    top: "50%",
    left: ({ base }) => `calc(100% + ${base.spacing.x2})`,
  },

  "&.StepperStep--horizontal": {
    ":not(.StepperStep--lastStep)": {
      "&::after": {
        bg: "base.color.translucent.standard.500",
      },
    },
    "&.StepperStep--complete": {
      "&::after": {
        bg: "base.color.translucent.standard.1000",
      },
    },
  },

  "&.StepperStep--vertical": {
    "&::after": {
      width: "base.border.size.100",
      top: ({ base }) => `calc(100% + ${base.spacing.x2})`,
    },
    "&:not(.StepperStep--lastStep)": {
      "&::after": {
        bg: "base.color.translucent.standard.500",
      },
    },
    "&.StepperStep--complete": {
      "&::after": {
        bg: "base.color.translucent.standard.1000",
      },
    },
    "&.StepperStep--small::after": {
      h: "base.spacing.x4",
      left: "base.spacing.x4",
    },
    "&.StepperStep--medium::after": {
      h: "base.spacing.x6",
      left: "base.spacing.x6",
    },
  },
};

export function getStepSx({
  size,
  theme,
}: {
  size: StepperSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        ...getBodyTextStyles({
          size: "small",
          weight: "bold",
          themeProps: theme,
        }),
        padding: theme.base.spacing.x1,
        paddingRight: theme.base.spacing.x3,
        paddingLeft: theme.base.spacing.x9,
        minHeight: theme.base.spacing.x8,
      };

    case "medium":
    default:
      return {
        ...getBodyTextStyles({
          size: "medium",
          weight: "bold",
          themeProps: theme,
        }),
        padding: theme.base.spacing.x2,
        paddingRight: theme.base.spacing.x7,
        paddingLeft: theme.base.spacing.x14,
        minHeight: theme.base.spacing.x12,
      };
  }
}

export const getResponsiveStepSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<StepperSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getStepSx,
  });

export function getStepBadgeSx({
  size,
  theme: { base },
}: {
  size: StepperSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        width: base.icon.size[300],
        height: base.icon.size[300],
        left: base.spacing.x1,
      };

    case "medium":
    default:
      return {
        width: base.icon.size[400],
        height: base.icon.size[400],
        left: base.spacing.x2,
      };
  }
}

export const getResponsiveStepBadgeSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<StepperSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getStepBadgeSx,
  });

export const baseStepBadgeSx = {
  p: "base.spacing.x1",
  brad: "50%",
  bg: "base.color.translucent.emphasis.200",
  c: "inherit",
  lineHeight: "unset",
  pos: "absolute",
  top: "50%",
  translate: "0 -50%",
};

export function getStepBadgeStateSx({
  stepIndex,
  activeStepIndex,
}: {
  stepIndex?: number;
  activeStepIndex?: number;
}) {
  if (stepIndex === undefined || activeStepIndex === undefined) return {};
  if (stepIndex === activeStepIndex) {
    return {
      bg: "base.color.translucent.standard.1000",
      c: "base.color.text.body.inverse.primary",
    };
  }
  return {};
}
