import {
  type AllSingleVariantIconKeys,
  Body,
  Heading,
  Icon,
  Stack,
} from "@biom3/react";
import { getLinkedDocument, getWebLink } from "@cms/utils";
import type { Content } from "@prismicio/client";
import { Breadcrumb } from "@ui-kit/GameHeader/Breadcrumb";
import {
  useIsLessThanLargeScreen,
  useIsLessThanMediumScreen,
  useIsLessThanXLargeScreen,
} from "@ui-kit/hooks/screenSizeHooks";
import { trackGameDetailsEvent } from "@ui-kit/utils";
import NextLink from "next/link";

type GameHeaderProps = {
  game: Content.GameDocument;
  title?: string | null;
};

const PageTitle = ({ title }: { title: string }) => {
  const isSmallScreenMode = useIsLessThanMediumScreen();
  const isMediumScreenMode = useIsLessThanLargeScreen();
  const isLargeScreenMode = useIsLessThanXLargeScreen();

  const showTitle =
    !isSmallScreenMode && !isMediumScreenMode && !isLargeScreenMode;

  return (
    <>
      {showTitle && (
        <Heading size={"xLarge"} weight="bold">
          {title}
        </Heading>
      )}
    </>
  );
};

export const GamePlatforms = ({
  game,
}: {
  game: Content.GameDocument;
}) => {
  const platforms = game.data.platforms;
  const mapIcons = (network: string): AllSingleVariantIconKeys | undefined => {
    switch (network) {
      case "App Store":
        return "Apple";
      case "Play Store":
        return "GooglePlayStore";
      case "Windows":
        return "Windows";
    }

    return undefined;
  };

  const onPlatformLinkClick = (link: string, platform?: string) => {
    trackGameDetailsEvent({
      identifier: "GamePlatforms",
      control: "PlatformHeaderClicked",
      controlType: "Link",
      extras: {
        link,
        platform,
      },
    });
  };

  return (
    <Stack direction={"row"} gap={"base.spacing.x6"}>
      {platforms.map(({ link: linkItem, platform: item }) => {
        const platform = getLinkedDocument<
          Content.GamePlatformDocument,
          Content.GamePlatformDocumentData
        >(item);
        const icon = mapIcons(platform?.data?.name || "");

        if (!platform?.data?.name || !icon) return null;

        const link = getWebLink(linkItem);

        return (
          <Stack direction="row" gap="base.spacing.x2" key={icon}>
            <Icon sx={{ w: "base.icon.size.250" }} icon={icon} />
            <Body
              weight={"bold"}
              size="small"
              sx={{
                color: "base.color.fixed.white.1000",
                textDecoration: "none",
              }}
              rc={
                link?.url ? (
                  <NextLink
                    href={link.url}
                    referrerPolicy="no-referrer"
                    target="_blank"
                    onClick={() => onPlatformLinkClick(link.url, platform.uid)}
                  />
                ) : undefined
              }
            >
              {platform.data.name}
            </Body>
          </Stack>
        );
      })}
    </Stack>
  );
};

export const GameHeader = ({ title, game }: GameHeaderProps) => {
  const landingPageUrl = "/games";
  const gameName = game.data.name || "";
  const gameTitle = title || gameName;

  const handleBackButtonClick = (controlType: string) => {
    trackGameDetailsEvent({
      identifier: "GameHeader",
      control: "BackToGamesListClicked",
      controlType,
    });
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      label: "Games",
      url: landingPageUrl,
      icon: "ESports",
      onClick: () => handleBackButtonClick("Link"),
    },
    { label: gameName },
  ];

  return (
    <Stack direction="column" justifyContent="left" gap="base.spacing.x3">
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PageTitle title={gameTitle} />
    </Stack>
  );
};
