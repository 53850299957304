import type { HeadingSize } from "@biom3/design-tokens";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Heading } from "@/components/Text";
import { DEFAULT_CARD_HERO_SIZE } from "@/constants";
import type { DomPropsWithDomRef, HeadingProps, MakeResponsive } from "@/types";

import type { CardHeroSize } from "./shared";
import { baseTitleSx } from "./styles";

type BaseCardTitleProps = HeadingProps & {
  cardHeroSize?: MakeResponsive<CardHeroSize>;
};

export type CardTitleProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"span"> & BaseCardTitleProps
    : BaseCardTitleProps & { rc: RC };

const CARDHERO_HEADING_SIZE_MAPPING: Record<CardHeroSize, HeadingSize> = {
  small: "medium",
  medium: "large",
  large: "large",
};

export function CardHeroTitle<RC extends ReactElement | undefined = undefined>({
  children,
  sx = {},
  rc = <span />,
  cardHeroSize = DEFAULT_CARD_HERO_SIZE,
  size,
  weight = "bold",
  className,
  ...props
}: CardTitleProps<RC>) {
  const mergedSx = useMemo(() => merge(baseTitleSx, sx), [sx]);
  const sizeToUse = useMemo(() => {
    if (size) return size;
    if (Array.isArray(cardHeroSize)) {
      return cardHeroSize.map((s) => {
        const typedSize = s as CardHeroSize;
        return typedSize ? CARDHERO_HEADING_SIZE_MAPPING[typedSize] : null;
      }) as MakeResponsive<HeadingSize>;
    }
    const pickedSize =
      CARDHERO_HEADING_SIZE_MAPPING[cardHeroSize as CardHeroSize];
    return pickedSize;
  }, [size, cardHeroSize]);
  return (
    <Heading
      {...props}
      size={sizeToUse}
      weight={weight}
      sx={mergedSx}
      rc={rc}
      className={`${className ?? ""} CardHeroTitle`}
    >
      {children}
    </Heading>
  );
}

CardHeroTitle.displayName = "CardHero.Title";
