import { Buffer } from "buffer";

// @NOTE: when updating this, be sure to also update the lambda:
// https://github.com/immutable/platform-infrastructure/blob/main/terraform/cloudfront/immutable-image-resizer-cache/lambda-src/src-ts/constants.ts#L3-L6
export const TOLERATED_IMAGE_SIZES = [
  24, 32, 48, 64, 72, 128, 256, 370, 450, 512, 640, 720, 860, 1024, 1280, 1440,
  1600, 1920, 2048, 2160, 2304, 2560, 2732, 2880, 3072, 3200, 3456, 3840, 4096,
] as const;

export function base64UrlEncodeImageUrl(url: string) {
  return Buffer.from(url)
    .toString("base64")
    .replace(/\+/g, "-") // Convert '+' to '-'
    .replace(/\//g, "_"); // Convert '/' to '_'
}

export function base64UrlDecodeImageUrl(base64Url: string) {
  const cleaned = base64Url
    .replace(/-/g, "+") // Convert '-' to '+'
    .replace(/_/g, "/"); // Convert '_' to '/'

  return Buffer.from(cleaned, "base64").toString();
}
