import type { ReactElement } from "react";

import type { IconProps } from "@/types";
import { Icon } from "../Icon";

export function SpineMenuItemIcon<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: IconProps<RC>) {
  return <Icon {...props} rc={rc} />;
}

SpineMenuItemIcon.displayName = "SpineMenu.Item.Icon";
