import type { Colors } from "@biom3/design-tokens";
import type { ReactElement, ReactNode } from "react";

import {
  type allIcons,
  dualVariantIcons,
  singleVariantIcons,
} from "@/components/Icon/shared";
import type { AllLogoKeys } from "@/components/Logo/shared";
import type { DUO_CON_OUTER_SHAPES } from "@/constants";

import type {
  BoxWithRCAndDomProps,
  DomPropsWithDomRef,
  StandardComponentWithProps,
} from "./core";
import type { FramedComponentBaseProps } from "./core";
import type { MotionProfile } from "./motion";

export type AllIconKeys = (typeof allIcons)[number];
export type AllDualVariantIconKeys = (typeof dualVariantIcons)[number];
export type AllSingleVariantIconKeys = (typeof singleVariantIcons)[number];

export type IconVariant = "bold" | "regular";
export type BaseIconDomProps = StandardComponentWithProps<
  SVGSVGElement,
  {
    children?: ReactNode;
    icon: AllIconKeys;
  }
>;

export type LogoBaseProps = Omit<BaseIconDomProps, "icon"> & {
  logo: AllLogoKeys;
};

export type LogoProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"svg"> & LogoBaseProps
    : LogoBaseProps & { rc: RC };

export type NestedLogoComponentProps = Omit<LogoProps, "logo">;

export function isLogo(
  onClick: unknown,
  rc: ReactElement | undefined,
  props: unknown,
): props is Partial<LogoProps> {
  return !rc && !onClick;
}

export type DuoConColorVariant = keyof Colors["status"];
export type DuoConOuterShape = (typeof DUO_CON_OUTER_SHAPES)[number];
export type DuoConProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> &
    IconSubcomponentDiscrimintatedUnion & {
      outerShape?: DuoConOuterShape;
      variant?: IconVariant;
      colorVariant?: DuoConColorVariant;
      motionProfile?: MotionProfile;
    };

type GetIconConditionalDomTypes<
  RC extends ReactElement | undefined = undefined,
> = RC extends undefined
  ? BaseIconDomProps & DomPropsWithDomRef<"svg">
  : BaseIconDomProps & { rc: RC };

export type IconDiscrimintatedUnion =
  | { icon: AllDualVariantIconKeys; variant?: IconVariant }
  | { icon: AllSingleVariantIconKeys; variant?: never };

export type IconSubcomponentDiscrimintatedUnion =
  | { icon: AllDualVariantIconKeys; iconVariant?: IconVariant }
  | { icon: AllSingleVariantIconKeys; iconVariant?: never };

export type OptionalIconSubcomponentDiscrimintatedUnion =
  | { icon?: AllDualVariantIconKeys; iconVariant?: IconVariant }
  | { icon?: AllSingleVariantIconKeys; iconVariant?: never };

export type IconProps<RC extends ReactElement | undefined = undefined> =
  GetIconConditionalDomTypes<RC> &
    IconDiscrimintatedUnion & { motionProfile?: MotionProfile };

export type IconSubcomponentProps<
  OtherProps extends {},
  RC extends ReactElement | undefined = undefined,
> = GetIconConditionalDomTypes<RC> &
  IconSubcomponentDiscrimintatedUnion &
  OtherProps;

export function isDualVariantIcon(
  icon: unknown,
): icon is AllDualVariantIconKeys {
  return dualVariantIcons.includes(icon as AllDualVariantIconKeys);
}

export function isSingleVariantIcon(
  icon: unknown,
): icon is AllSingleVariantIconKeys {
  return singleVariantIcons.includes(icon as AllSingleVariantIconKeys);
}

export type FramedIconProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & IconDiscrimintatedUnion & FramedComponentBaseProps;

export type FixedSizeFramedIconProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> &
  IconDiscrimintatedUnion &
  Omit<FramedComponentBaseProps, "size">;

export type FramedLogoProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> &
    Omit<LogoBaseProps, "domRef"> &
    FramedComponentBaseProps;
