import type { OptionKey, SelectOptionProps } from "@/types";
import { getSubcomponentChild, warnUser } from "@/utils";
import {
  type ComponentType,
  type JSXElementConstructor,
  type ReactElement,
  type ReactNode,
  useMemo,
} from "react";

export function pickAnOption(selectedOption?: OptionKey, options?: ReactNode) {
  return typeof selectedOption !== "undefined" && Array.isArray(options)
    ? (options.find(
        (option) => option.props.optionKey === selectedOption,
      ) as ReactElement<
        SelectOptionProps,
        string | JSXElementConstructor<SelectOptionProps>
      >)
    : null;
}

export const noOptionFoundErrorText = (selectedOption: OptionKey | undefined) =>
  `No <*.Option> subcomponent found for the selectedOption: "${selectedOption}"`;

export function useGetControlledContent({
  options,
  selectedOption,
  subcomponent,
}: {
  options?: ReactElement<SelectOptionProps>[];
  selectedOption?: OptionKey | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  subcomponent: ComponentType<any>;
}) {
  const content = useMemo(() => {
    if (selectedOption === null) return null;
    const pickedOption = pickAnOption(selectedOption, options);
    if (pickedOption?.props.children) {
      return getSubcomponentChild(pickedOption.props.children, subcomponent);
    }
    if (selectedOption !== undefined) {
      warnUser(noOptionFoundErrorText(selectedOption));
    }
    return null;
  }, [options, selectedOption, subcomponent]);

  return content;
}
