import { type Gradient, smartPickTokenValue } from "@biom3/design-tokens";
import type { CSSProperties } from "react";
import { merge } from "ts-deepmerge";

import { SHORTHAND_CSS_RULE_MAPPING } from "@/constants";
import type {
  BiomeTheme,
  MakeObjectResponsive,
  Measurement,
  MeasurementOrResponsiveMeasurement,
  ModifiedCssTypeProperties,
  SxProps,
  ValidSxValues,
} from "@/types";

export function hasKey<K extends string>(
  key: K,
  object: unknown,
): object is { [_ in K]: keyof SxProps } {
  // @TODO: would be awesome to try and get this working
  // (because it actually narrows the type), but it's not a priority atm
  // ): object is { [_ in K]: {} } {
  return typeof object === "object" && object !== null && key in object;
}

// @TODO: this is 99% the correct type, but still includes the shorthand rules.
// ideally, the type should only be longform rules.
export function convertShorthandRule(rule: keyof SxProps) {
  let longHandRule = rule;
  if (hasKey(rule, SHORTHAND_CSS_RULE_MAPPING)) {
    longHandRule = SHORTHAND_CSS_RULE_MAPPING[rule];
  }
  return longHandRule;
}

export function applyXandYStyleAmount(
  rule: keyof SxProps,
  value: string,
): ModifiedCssTypeProperties {
  const rulePrefix = rule.substring(0, rule.length - 1);
  return rule.match(/[margin|padding]Y/)
    ? { [`${rulePrefix}Top`]: value, [`${rulePrefix}Bottom`]: value }
    : { [`${rulePrefix}Left`]: value, [`${rulePrefix}Right`]: value };
}

export function applyStyleAmount(
  rule: keyof SxProps,
  value: ValidSxValues | null,
  themeProps: BiomeTheme,
): ModifiedCssTypeProperties {
  if (value === undefined || value === null) return {};
  const valueFromToken =
    smartPickTokenValue<string | Gradient>(themeProps, `${value}`) ?? value;

  const isGradient = typeof valueFromToken === "object";
  if (isGradient && rule.match(/background$|backgroundImage$|^color/)) {
    const gradient = valueFromToken;
    const styleObject = {
      backgroundImage: gradient.spectrum,
      backgroundBlendMode: gradient.blendMode,
    };
    return rule.match(/color/)
      ? {
          ...styleObject,
          backgroundClip: "text",
          textFillColor: "transparent",
        }
      : styleObject;
  }

  if (
    rule.match(/paddingX$|paddingY|marginY|marginX$/) &&
    typeof valueFromToken === "string"
  ) {
    return applyXandYStyleAmount(rule, valueFromToken);
  }

  return { [rule]: valueFromToken };
}

type MeasurementOrNull = Measurement | null;
type NullOrMeasurementArray = MeasurementOrNull[];

export function renderResponsiveStyles(
  rule: keyof SxProps,
  values: NullOrMeasurementArray,
  themeProps: BiomeTheme,
) {
  const [defaultValue, ...responsiveValues] = values;
  const responsiveStyles = responsiveValues.map(
    (responsiveValue: MeasurementOrNull, index: number) => {
      const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
      const cssValue =
        typeof responsiveValue === "function"
          ? responsiveValue(themeProps)
          : responsiveValue;
      return responsiveValue
        ? {
            [mediaStyleRule]: applyStyleAmount(rule, cssValue, themeProps),
          }
        : {};
    },
  );
  const styles: CSSProperties = {
    ...(defaultValue
      ? applyStyleAmount(
          rule,
          typeof defaultValue === "function"
            ? defaultValue(themeProps)
            : defaultValue,
          themeProps,
        )
      : {}),
    ...Object.assign({}, ...responsiveStyles),
  };
  return styles;
}

export function renderResponsiveStylesFromObject(
  rule: keyof SxProps,
  values: MakeObjectResponsive<MeasurementOrNull>,
  themeProps: BiomeTheme,
) {
  const { default: defaultProp, ...responsiveProps } = values;
  const orderedArray = [defaultProp];
  let key: keyof typeof themeProps.base.breakpoint;
  for (key in themeProps.base.breakpoint) {
    const responsiveStyle = responsiveProps[key];
    if (responsiveStyle) {
      orderedArray.push(responsiveStyle);
    } else {
      orderedArray.push(null);
    }
  }
  return renderResponsiveStyles(rule, orderedArray, themeProps);
}

// @TODO: the types here are not great. might need some work. :(
export function renderDescendantStylesFromObject(
  descendantSelector: string,
  descendantValue: MakeObjectResponsive<MeasurementOrNull>,
  themeProps: BiomeTheme,
) {
  const descendantStyles = {
    [descendantSelector]: {} as Record<string, unknown>,
  };
  let key: keyof typeof descendantValue;
  for (key in descendantValue) {
    const rule = convertShorthandRule(key as keyof SxProps);
    const value = descendantValue[
      key
    ] as MeasurementOrResponsiveMeasurement | null;

    // Ensure we always have an object to merge with
    const baseStyles = descendantStyles[descendantSelector] || {};
    const newStyles = chooseWhichStylesToRender(rule, value, themeProps);

    descendantStyles[descendantSelector] = merge(baseStyles, newStyles);
  }
  return descendantStyles;
}

export function chooseWhichStylesToRender(
  rule: keyof SxProps,
  value: MeasurementOrResponsiveMeasurement | null,
  themeProps: BiomeTheme,
) {
  if (value === null) return {};

  // @NOTE: We must ignore any of the @emotion/react keyframe based
  // animation style objects, as these do NOT need to go through sx processing
  if (typeof value === "function" && typeof rule === "function") {
    return {};
  }

  const cssValue = typeof value === "function" ? value(themeProps) : value;

  // @TODO: find a way to not have to cast cssValue here (hint: its due to Array.isArray())
  return Array.isArray(cssValue)
    ? renderResponsiveStyles(rule, cssValue as Measurement[], themeProps)
    : typeof cssValue === "object" && cssValue.default
      ? renderResponsiveStylesFromObject(rule, cssValue, themeProps)
      : typeof cssValue === "object"
        ? renderDescendantStylesFromObject(rule, cssValue, themeProps)
        : applyStyleAmount(rule, cssValue as ValidSxValues, themeProps);
}

type IncomingStyles = Record<string, unknown>;
function sortStylesForMediaQueries(styles: IncomingStyles) {
  const keys = Object.keys(styles);
  const mediaKeys = keys.filter((key) => key.startsWith("@media"));
  const nonMediaKeys = keys.filter((key) => !key.startsWith("@media"));
  const sortedMediaKeys = mediaKeys.sort((a, b) => {
    const widthA = Number.parseInt(a.match(/\d+/)?.[0] ?? "", 10);
    const widthB = Number.parseInt(b.match(/\d+/)?.[0] ?? "", 10);
    return widthA - widthB;
  });
  const sortedObject: IncomingStyles = {};
  nonMediaKeys.forEach((key) => {
    sortedObject[key] = styles[key];
  });
  sortedMediaKeys.forEach((key) => {
    sortedObject[key] = styles[key];
  });
  return sortedObject as CSSProperties;
}

export function convertSxToEmotionStyles(sx: SxProps, themeProps: BiomeTheme) {
  const stylesArray: CSSProperties[] = [];

  // @NOTE: important that we use Object.getOwnPropertyNames instead of say,
  // Object.keys, as the latter will return keys which are ordered differently from
  // their insertion order (which is quite important given that rule order matters in CSS).
  // more about this here: https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order/38218582#38218582
  Object.getOwnPropertyNames(sx).forEach((rule) => {
    const typedRule = rule as keyof SxProps;
    const value = sx[typedRule];
    const longHandRule = convertShorthandRule(typedRule);
    if (value || typeof value === "number") {
      stylesArray.push(
        chooseWhichStylesToRender(longHandRule, value, themeProps),
      );
    }
  });

  // @NOTE: we need to ensure that all @media rules are last, AND sorted
  // by their min-width values, as this is the only way to ensure that the
  // styles are applied in the correct order.
  return sortStylesForMediaQueries(merge({}, ...stylesArray));
}
