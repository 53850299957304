import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";

import { SvgIcon } from "../SvgIcon";

export function PassportVerticalLockup({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent("PassportVerticalLockup");
  return (
    <SvgIcon
      {...props}
      testId={testId}
      viewBox="0 0 123 112"
      sx={merge(
        {
          fill: "base.color.brand.1",
          w: "123px",
        },
        sx,
      )}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}

PassportVerticalLockup.displayName = "PassportVerticalLockup";
