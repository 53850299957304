import { type MutableRefObject, type Ref, useEffect, useRef } from "react";

/**
 * This is a TypeScript function that creates a local DOM reference and forwards it to parent
 * components.
 * @param domRef - A reference to an HTML or SVG element that is passed in as a parameter to the custom
 * hook. This reference is used to forward the local DOM reference to parent components.
 * @returns The `useForwardLocalDomRef` function returns a `Ref` object that refers to a local DOM
 * element. This local DOM element is stored in the `localDomRef` variable, which is created using the
 * `useRef` hook. The function also updates the `domRef` object passed as an argument to refer to the
 * same local DOM element, so that it can be accessed by parent
 */
export function useForwardLocalDomRef<TElement extends HTMLElement | null>(
  domRef: Ref<TElement>,
) {
  const localDomRef = useRef<TElement>(null);

  // @NOTE: store the current domRef AND forward it on to
  // parent components
  useEffect(() => {
    if (domRef && typeof domRef === "object") {
      // @TODO:
      // We have to mutate the domRef type here, simply to allow it to be re-assigned
      // to the localRef.current value. This is a bit of a hack - would be nice to find
      // another way...
      (domRef as MutableRefObject<unknown | null>).current =
        localDomRef.current;
    } else if (typeof domRef === "function") {
      domRef(localDomRef.current);
    }
  }, [domRef]);

  return localDomRef;
}
