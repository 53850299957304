import { ClassNames } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { DEFAULT_IMAGE_SIZE_VARIANT, IMAGE_SIZE_VARIANTS } from "@/constants";
import { useGetMotionProfile, useTheme } from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  DeeplyNestedSx,
  FramedComponentBaseProps,
} from "@/types";
import {
  centerFlexChildren,
  getMotionProfileSx,
  getStartingSize,
  hFlex,
} from "@/utils";

import { getResponsiveSizeBasedStyles, getSizeBasedStyles } from "./styles";

export type FramedContainerProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & FramedComponentBaseProps;

export function FramedContainer<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  className,
  testId = "FramedContainer",
  circularFrame = false,
  emphasized = false,
  padded = false,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  motionProfile,
  sx = {},
  ...props
}: FramedContainerProps<RC>) {
  const theme = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...hFlex,
          ...centerFlexChildren,
          ...(emphasized
            ? {
                boxShadow: ({ base }) =>
                  `0 0 0 ${base.border.size[200]} ${base.color.translucent.standard[500]}`,
              }
            : {}),
          brad: circularFrame ? "50%" : "base.borderRadius.x2",
          bg: "base.color.translucent.inverse.600",
          transitionProperty: "width",
        } as DeeplyNestedSx,
        getMotionProfileSx(motionProfileToUse, theme),
        getSizeBasedStyles({ size: startingSize, theme }),
        getResponsiveSizeBasedStyles({ size, theme }),
        sx,
      ),
    [
      sx,
      emphasized,
      circularFrame,
      size,
      startingSize,
      theme,
      motionProfileToUse,
    ],
  );

  return (
    <ClassNames>
      {({ cx }) => (
        <Box
          {...props}
          sx={mergedSx}
          testId={testId}
          className={cx(className, "FramedContainer", {
            "FramedContainer--padded": padded,
            "FramedContainer--emphasized": emphasized,
            "FramedContainer--circularFrame": circularFrame,
          })}
        >
          {children}
        </Box>
      )}
    </ClassNames>
  );
}

FramedContainer.displayName = "FramedContainer";
