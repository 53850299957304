import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from "@/constants";
import { useTheme } from "@/hooks";
import type { BoxWithRCAndDomProps, ButtonSize, MakeResponsive } from "@/types";
import { isChildSubcomponent } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";

import { HorizontalMenuButtCon } from "./HorizontalMenuButtCon";
import { HorizontalMenuButton } from "./HorizontalMenuButton";
import {
  getContainerStyles,
  horizontalMenuSxProps,
  renderResponsiveContainerStyles,
} from "./styles";

export type HorizontalMenuProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & {
  /**
   * Controls the size of the HoriztonalMenu
   */
  size?: MakeResponsive<ButtonSize>;
};

export function HorizontalMenu<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  sx = {},
  size = "medium",
  className,
  ...props
}: HorizontalMenuProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);
  const containerSx = merge(
    horizontalMenuSxProps,
    getContainerStyles({ size: startingSize, theme }),
    renderResponsiveContainerStyles({
      size,
      theme,
    }),
    sx,
  );
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );

  return (
    <Box
      {...props}
      sx={containerSx}
      className={`${className ?? ""} HorizontalMenu`}
    >
      {Children.map(flattenedChildren, (child) => {
        if (
          isChildSubcomponent(child, HorizontalMenuButtCon) ||
          isChildSubcomponent(child, HorizontalMenuButton)
        ) {
          return (
            <SmartClone size={child.props.size ? child.props.size : size}>
              {child}
            </SmartClone>
          );
        }
        return child;
      })}
    </Box>
  );
}

HorizontalMenu.displayName = "HorizontalMenu";
HorizontalMenu.Button = HorizontalMenuButton;
HorizontalMenu.ButtCon = HorizontalMenuButtCon;
