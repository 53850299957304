import { hFlex } from "@/utils";

export const containerSx = {
  ...hFlex,
  justifyContent: "center",
  pos: "relative",
  overflow: "hidden",
  w: "100%",
  color: "base.color.text.heading.primary",
  // @NOTE: this ensures that the bottom of descending glyphs
  // (eg. 'g', 'y') are not cut off
  pb: "2.5%",
};

export const baseDummyDisplaySx = {
  pos: "absolute",
  top: "0",
  left: "0",
  c: "red",
  pointerEvents: "none",
  whiteSpace: "nowrap",
  flexGrow: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const baseInnerTextSx = {
  lineHeight: "1",
  whiteSpace: "nowrap",
  c: "inherit",
};
