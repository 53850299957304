import { DEFAULT_TIMELINE_VARIANT, TIMELINE_VARIANTS } from "@/constants";
import { useGetCurrentSizeClass } from "@/hooks";
import { isChildSubcomponent, renderNullAndWarnUser } from "@/utils";
import { ClassNames } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DuoCon } from "../DuoCon";
import { Icon } from "../Icon";
import { SmartClone } from "../SmartClone";
import { Stack } from "../Stack";
import { Body } from "../Text";
import { TimelineStepDot } from "./TimelineStepDot";
import { type TimelineStepProps, useGetBasicLayoutMode } from "./shared";
import {
  baseTimelineStepSx,
  captionSx,
  completeDuoConSx,
  customDotDuoConLoadingSx,
  customDotDuoConSx,
  customDotSx,
  labelSx,
} from "./styles";

export function TimelineStep<RC extends ReactElement | undefined = undefined>({
  className,
  variant = DEFAULT_TIMELINE_VARIANT,
  allStepsComplete = false,
  stepIndex,
  activeStepIndex,
  totalSteps,
  label,
  testId = "TimelineStep",
  caption = "",
  customDot,
  showCurrentStepSpinner = false,
  sx = {},
  ...stackProps
}: TimelineStepProps<RC>) {
  const variantClass = useGetCurrentSizeClass(
    variant,
    DEFAULT_TIMELINE_VARIANT,
    TIMELINE_VARIANTS,
  );
  const mergedSx = useMemo(() => merge(baseTimelineStepSx, sx), [sx]);
  const captionSize = useMemo(() => {
    switch (true) {
      case variantClass.includes("split"):
        return "medium";

      default:
        return "small";
    }
  }, [variantClass]);
  const basicLayoutMode = useGetBasicLayoutMode(variantClass);

  const allStepIndexPropsValid =
    typeof stepIndex === "number" &&
    typeof activeStepIndex === "number" &&
    typeof totalSteps === "number";

  const isCompleteStep =
    allStepsComplete || (allStepIndexPropsValid && stepIndex < activeStepIndex);
  const isActiveStep =
    !allStepsComplete &&
    allStepIndexPropsValid &&
    stepIndex === activeStepIndex;
  const isFutureStep =
    !allStepsComplete && allStepIndexPropsValid && stepIndex > activeStepIndex;
  const isLastStep = allStepIndexPropsValid && stepIndex === totalSteps;
  const isFirstStep = allStepIndexPropsValid && stepIndex === 1;
  const displayDot = useMemo(() => {
    if (customDot) {
      if (isChildSubcomponent(customDot, DuoCon)) {
        switch (true) {
          case isCompleteStep:
            return (
              <TimelineStepDot sx={customDotSx}>
                <SmartClone
                  variant={customDot.props.variant ?? "bold"}
                  colorVariant={customDot.props.colorVariant ?? "success"}
                  sx={customDotDuoConSx}
                >
                  {customDot}
                </SmartClone>
              </TimelineStepDot>
            );

          case isActiveStep:
            return (
              <TimelineStepDot sx={customDotSx}>
                <SmartClone
                  variant={customDot.props.variant ?? "bold"}
                  colorVariant={customDot.props.colorVariant ?? "guidance"}
                  sx={customDotDuoConSx}
                >
                  {customDot}
                </SmartClone>
                {showCurrentStepSpinner && (
                  <Icon icon="Loading" sx={customDotDuoConLoadingSx} />
                )}
              </TimelineStepDot>
            );

          case isFutureStep:
          default:
            return (
              <TimelineStepDot>
                <SmartClone
                  colorVariant={customDot.props.colorVariant ?? "guidance"}
                  sx={customDotDuoConSx}
                >
                  {customDot}
                </SmartClone>
              </TimelineStepDot>
            );
        }
      }
      return (
        <SmartClone sx={customDotSx} className="TimelineStep__dot">
          {customDot}
        </SmartClone>
      );
    }

    switch (true) {
      case isCompleteStep:
        return (
          <TimelineStepDot>
            <DuoCon
              icon="Tick"
              colorVariant="success"
              variant="bold"
              iconVariant="bold"
              sx={completeDuoConSx}
            />
          </TimelineStepDot>
        );

      case isActiveStep && showCurrentStepSpinner:
        return <TimelineStepDot isLoadingDot />;

      case isActiveStep:
      case isFutureStep:
      default:
        return <TimelineStepDot />;
    }
  }, [
    customDot,
    isCompleteStep,
    isActiveStep,
    isFutureStep,
    showCurrentStepSpinner,
  ]);

  // @NOTE: early exit, render nothing, when these values are not valid:
  if (!allStepIndexPropsValid) {
    if (typeof stepIndex !== "number")
      return renderNullAndWarnUser(
        "Timeline",
        `Timeline recieved an invalid stepIndex property (${stepIndex})`,
      );
    if (typeof activeStepIndex !== "number")
      return renderNullAndWarnUser(
        "Timeline",
        `Timeline recieved an invalid activeStepIndex property (${activeStepIndex})`,
      );
    if (typeof totalSteps !== "number")
      return renderNullAndWarnUser(
        "Timeline",
        `Timeline recieved an invalid totalSteps property (${totalSteps})`,
      );
  }

  return (
    <ClassNames>
      {({ cx }) => (
        <Stack
          {...stackProps}
          testId={testId}
          sx={mergedSx}
          className={cx(
            className,
            "TimelineStep",
            `TimelineStep--${basicLayoutMode}`,
            `TimelineStep--${variantClass}`,
            {
              "TimelineStep--hasCustomDot": Boolean(customDot),
              "TimelineStep--active": isActiveStep,
              "TimelineStep--complete": isCompleteStep,
              "TimelineStep--future": isFutureStep,
              "TimelineStep--firstStep": isFirstStep,
              "TimelineStep--lastStep": isLastStep,
            },
          )}
        >
          <SmartClone testId={`${testId}__dot`}>{displayDot}</SmartClone>

          <Body
            sx={labelSx}
            className="TimelineStep__label"
            size="medium"
            weight="bold"
            testId={`${testId}__label`}
          >
            {label}
          </Body>

          <Body
            sx={captionSx}
            testId={`${testId}__caption`}
            className={cx("TimelineStep__caption", {
              "TimelineStep__caption--empty": caption === "",
            })}
            size={captionSize}
          >
            {caption}
          </Body>
        </Stack>
      )}
    </ClassNames>
  );
}

TimelineStep.displayName = "Timeline.Step";
