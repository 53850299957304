// @NOTE: Rive must be imported liked this because it is a CJS package, and we are using ESM
// thus, when we goto use @biom3/react in another repo, we get build errors
import * as Rive from "@rive-app/react-canvas-lite";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import {
  DEFAULT_LOADING_OVERLAY_SIZE,
  LOADING_OVERLAY_SIZES,
} from "@/constants";
import {
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import type { LoadingOverlayContentProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";
import { vFlex } from "@/utils/sxChunks";

import { ModalContent } from "../Modal/ModalContent";
import { SmartClone } from "../SmartClone";
import { LoadingOverlayContentLoopingText } from "./LoadingOverlayContentLoopingText";
import { LoadingOverlayContentRiveBox } from "./LoadingOverlayContentRiveBox";
import { getContainerSx, getResponsiveContainerSx } from "./style";

export function LoadingOverlayContent<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  sx = {},
  testId = "LoadingOverlayContent",
  size = DEFAULT_LOADING_OVERLAY_SIZE,
  ...props
}: LoadingOverlayContentProps<RC>) {
  const theme = useTheme();
  const { RiveComponent: DefaultRive } = Rive.useRive({
    src: "https://biome.immutable.com/hosted-assets/rive/immutable_loader.riv",
    autoplay: true,
    layout: new Rive.Layout({ fit: Rive.Fit.Contain }),
  });
  const riveBox = useGetSubcomponentChild(
    children,
    LoadingOverlayContentRiveBox,
  );
  const loopingText = useGetSubcomponentChild(
    children,
    LoadingOverlayContentLoopingText,
  );
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    LoadingOverlayContentRiveBox,
    LoadingOverlayContentLoopingText,
  ]);
  const startingSize = getStartingSize(
    size,
    DEFAULT_LOADING_OVERLAY_SIZE,
    LOADING_OVERLAY_SIZES,
  );
  const containerSx = merge(
    {
      ...vFlex,
      alignItems: "center",
      bg: "base.color.neutral.500",
      boxShadow: "base.shadow.500",
      brad: "base.borderRadius.x6",
      gap: "base.spacing.x4",
      px: "base.spacing.x4",
      overflow: "unset",
    },
    getContainerSx({
      size: startingSize,
      theme,
    }),
    getResponsiveContainerSx({ size, theme }),
    sx,
  );

  return (
    <ModalContent {...props} sx={containerSx} testId={testId}>
      {riveBox ? (
        <SmartClone testId={riveBox.props.testId ?? `${testId}__riveBox`}>
          {riveBox}
        </SmartClone>
      ) : (
        <LoadingOverlayContentRiveBox
          rc={<DefaultRive />}
          testId={`${testId}__riveBox`}
        />
      )}

      {loopingText && (
        <SmartClone testId={`${testId}__loopingText`}>{loopingText}</SmartClone>
      )}

      {otherChildren}
    </ModalContent>
  );
}

LoadingOverlayContent.displayName = "LoadingOverlay.Content";
LoadingOverlayContent.RiveBox = LoadingOverlayContentRiveBox;
LoadingOverlayContent.LoopingText = LoadingOverlayContentLoopingText;
