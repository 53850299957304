import {
  type ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { merge } from "ts-deepmerge";

import { SmartClone } from "@/components/SmartClone";
import { Heading } from "@/components/Text";
import {
  useForwardLocalDomRef,
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import { useWindowSizeStore } from "@/providers";
import type { HeadingProps } from "@/types";

import { BulletListTitleDuoCon } from "./BulletListTitleDuoCon";
import { BulletListContext } from "./shared";
import { bulletListTitleSx } from "./styles";

type BulletListTitleProps<RC extends ReactElement | undefined = undefined> =
  HeadingProps<RC> & {};

export function BulletListTitle<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  size = "xSmall",
  weight = "bold",
  testId = "BulletListTitle",
  rc = <h4 />,
  sx = {},
  className,
  ...props
}: BulletListTitleProps<RC>) {
  const { setDimensions } = useContext(BulletListContext);
  const { state: windowWidth } = useWindowSizeStore((store) => store.width);
  const innerRef = useRef<HTMLSpanElement>(null);
  const duoCon = useGetSubcomponentChild(children, BulletListTitleDuoCon);
  const duoConRef = useForwardLocalDomRef(
    duoCon?.props.domRef ?? { current: null },
  );
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    BulletListTitleDuoCon,
  ]);
  const mergedSx = useMemo(() => merge(bulletListTitleSx, sx), [sx]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const leftInset = innerRef.current?.offsetLeft ?? 0;
    const titleDuoConWidth = duoConRef.current?.offsetWidth ?? 0;
    const titleGap = leftInset - titleDuoConWidth;
    setDimensions({ titleDuoConWidth, titleGap });
  }, [size, children, windowWidth]);
  return (
    <Heading
      {...props}
      size={size}
      weight={weight}
      rc={rc}
      sx={mergedSx}
      testId={testId}
      className={`${className ?? ""} BulletListTitle`}
      motionProfile="none"
    >
      {duoCon && <SmartClone domRef={duoConRef}>{duoCon}</SmartClone>}
      <span ref={innerRef}>{otherChildren}</span>
    </Heading>
  );
}

BulletListTitle.displayName = "BulletList.Title";
BulletListTitle.DuoCon = BulletListTitleDuoCon;
