import {
  type MouseEvent,
  type ReactElement,
  useCallback,
  useMemo,
} from "react";

import { Button } from "@/components/Clickable";
import { DEFAULT_CARD_HERO_SIZE } from "@/constants";
import type { ButtonProps, ButtonSize, MakeResponsive } from "@/types";

import { merge } from "ts-deepmerge";
import {
  type CardHeroButtonVariant,
  type CardHeroSize,
  useGetCardHeroButtonVariant,
} from "./shared";
import { buttonAndButtConBaseSx } from "./styles";

export type CardHeroButtonProps<
  RC extends ReactElement | undefined = undefined,
> = Omit<ButtonProps<RC>, "variant"> & {
  cardHeroSize?: MakeResponsive<CardHeroSize>;
  variant?: CardHeroButtonVariant;
};

const CARDHERO_BUTTON_SIZE_MAPPING: Record<CardHeroSize, ButtonSize> = {
  small: "medium",
  medium: "large",
  large: "large",
};

export function CardHeroButton<
  RC extends ReactElement | undefined = undefined,
>({
  variant = "primary",
  className,
  size,
  cardHeroSize = DEFAULT_CARD_HERO_SIZE,
  sx = {},
  ...props
}: CardHeroButtonProps<RC>) {
  const { onClick, ...otherProps } =
    "onClick" in props ? props : { ...props, onClick: null };
  const handleOnClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onClick === "function") {
        onClick(ev);
      }
    },
    [onClick],
  );
  const variantToUse = useGetCardHeroButtonVariant(variant);
  const sizeToUse = useMemo(() => {
    // @NOTE: allow the component's normal size prop to be over-written
    // from the defaults selected by cardHeroSize:
    if (size) return size;

    if (Array.isArray(cardHeroSize)) {
      return cardHeroSize.map((s) => {
        const typedSize = s as CardHeroSize;
        return typedSize ? CARDHERO_BUTTON_SIZE_MAPPING[typedSize] : null;
      }) as MakeResponsive<ButtonSize>;
    }
    const pickedSize =
      CARDHERO_BUTTON_SIZE_MAPPING[cardHeroSize as CardHeroSize];
    return pickedSize;
  }, [size, cardHeroSize]);
  const mergedSx = useMemo(() => merge(buttonAndButtConBaseSx, sx), [sx]);

  return (
    <Button
      {...otherProps}
      {...(onClick ? { onClick: handleOnClick } : {})}
      variant={variantToUse}
      size={sizeToUse}
      className={`${className ?? ""} CardHeroButton`}
      sx={mergedSx}
    />
  );
}

CardHeroButton.displayName = "CardHero.Button";
CardHeroButton.Icon = Button.Icon;
CardHeroButton.Logo = Button.Logo;
