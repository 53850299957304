import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { FramedIcon } from "@/components/FramedThings";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { FixedSizeFramedIconProps, MenuItemProps } from "@/types";

import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseFramedIconStyles } from "./styles";

export type MenuItemFramedIconProps<
  RC extends ReactElement | undefined = undefined,
> = FixedSizeFramedIconProps<RC> & Pick<MenuItemProps, "size">;

export function MenuItemFramedIcon<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  size = DEFAULT_MENU_ITEM_SIZE,
  rc = <span />,
  ...props
}: MenuItemFramedIconProps<RC>) {
  const allSx = merge(baseFramedIconStyles, sx);
  const sizeToUse = useGetBiomeImageSizeFromMenuItemSize(size);
  return <FramedIcon {...props} rc={rc} sx={allSx} size={sizeToUse} />;
}

MenuItemFramedIcon.displayName = "MenuItem.FramedIcon";
