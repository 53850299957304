import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { TextInputIconProps } from "@/types";
import { Icon } from "../Icon";
import { inputIconBaseSx } from "./styles";

export function TextInputIcon<RC extends ReactElement | undefined>({
  sx = {},
  ...props
}: TextInputIconProps<RC>) {
  const allStyles = merge(inputIconBaseSx, sx);
  return <Icon {...props} sx={allStyles} />;
}

TextInputIcon.displayName = "TextInput.Icon";
