import { INPUT_MIN_WIDTH } from "@/constants";
import type { BiomeTheme } from "@/types";

const ZERO_BORDER_RADIUS = "0px";
const NORMAL_BORDER_RADIUS = "base.borderRadius.x4";

const negativeMargin = ({ base }: BiomeTheme) =>
  `calc(${base.border.size[100]} * -1)`;

export const baseContainerSx = {
  alignItems: "stretch",
  pos: "relative",
  gap: "0px",
  minw: INPUT_MIN_WIDTH,

  "& > .InputGroup__child": {
    brad: ZERO_BORDER_RADIUS,

    // @NOTE: ensure that input components below, dont have min-width's
    // which can break the layouts
    minw: "unset",

    "&.Autocomplete .TextInput": {
      minw: "unset",
    },

    "& .TextInput__input": {
      brad: ZERO_BORDER_RADIUS,
    },

    "&:not(:last-child)": {
      // @NOTE: this is to ensure that the border between each input is only 1px
      mb: negativeMargin,
    },

    "&:first-child": {
      bradtl: NORMAL_BORDER_RADIUS,
      bradtr: NORMAL_BORDER_RADIUS,

      "&.TextInput > .TextInput__input, &.Autocomplete > .TextInput > .TextInput__input":
        {
          bradtl: NORMAL_BORDER_RADIUS,
          bradtr: NORMAL_BORDER_RADIUS,
        },
    },

    "&:last-child": {
      bradbl: NORMAL_BORDER_RADIUS,
      bradbr: NORMAL_BORDER_RADIUS,

      "&.TextInput > .TextInput__input, &.Autocomplete > .TextInput > .TextInput__input":
        {
          bradbl: NORMAL_BORDER_RADIUS,
          bradbr: NORMAL_BORDER_RADIUS,
        },
    },

    // @NOTE: style when a row is the last child:
    "&.InputGroupRow:last-child": {
      "& > *:first-child": {
        bradbl: NORMAL_BORDER_RADIUS,
        "& .TextInput__input": {
          bradbl: NORMAL_BORDER_RADIUS,
        },
      },
      "& > *:last-child": {
        bradbr: NORMAL_BORDER_RADIUS,
        "& .TextInput__input": {
          bradbr: NORMAL_BORDER_RADIUS,
        },
      },
    },

    // @NOTE: style when a row is the first child:
    "&.InputGroupRow:first-child": {
      "& > *:first-child": {
        bradtl: NORMAL_BORDER_RADIUS,
        "& .TextInput__input": {
          bradtl: NORMAL_BORDER_RADIUS,
        },
      },
      "& > *:last-child": {
        bradtr: NORMAL_BORDER_RADIUS,
        "& .TextInput__input": {
          bradtr: NORMAL_BORDER_RADIUS,
        },
      },
    },

    // @NOTE: style when a row is the ONLY child:
    "&.InputGroupRow:only-child": {
      "& > *": {
        brad: ZERO_BORDER_RADIUS,
        "& .TextInput__input": {
          brad: ZERO_BORDER_RADIUS,
        },
      },
    },
  },
};

export const baseRowSx = {
  alignItems: "stretch",
  position: "relative",
  width: "100%",
  gap: "0px",

  "& .TextInput": {
    minw: "unset",
  },

  "&:not(:last-child):not(:only-child)": {
    // @NOTE: this is to ensure that the border between each input is only 1px
    mb: negativeMargin,
  },

  "& .InputGroupRow__child": {
    flex: 1,
    brad: ZERO_BORDER_RADIUS,
    minw: "unset",

    "& .TextInput__input": {
      brad: ZERO_BORDER_RADIUS,
    },

    "&:not(:only-child)": {
      "&:not(:last-child)": {
        // @NOTE: this is to ensure that the border between each input is only 1px
        mr: negativeMargin,
      },

      "&:last-child": {
        flex: 1.01,
      },
    },
  },
};
