import type { DomReferenceRect } from "../types";

export function getSafeRightHorizontalPosition(
  target?: DomReferenceRect,
  content?: DomReferenceRect,
  leftIsUnsafe = false,
): number {
  if (target && content) {
    return target.left + target.width - content.width < 0 && !leftIsUnsafe
      ? getSafeLeftHorizontalPosition(target, content, true)
      : target.left + target.width - content.width;
  }
  return 0;
}

export function getSafeLeftHorizontalPosition(
  target?: DomReferenceRect,
  content?: DomReferenceRect,
  rightIsUnsafe = false,
): number {
  if (target && content) {
    return target.left + content.width > document.body.clientWidth &&
      !rightIsUnsafe
      ? getSafeRightHorizontalPosition(target, content, true)
      : target.left;
  }
  return 0;
}

export function getSafeCenterHorizontalPosition(
  target?: DomReferenceRect,
  content?: DomReferenceRect,
): number {
  if (target && content) {
    const proposedPosition = target.left + target.width / 2 - content.width / 2;
    const isLeftEdgeOutOfBounds = proposedPosition < 0;
    const isRightEdgeOutOfBounds =
      proposedPosition + content.width >= document.body.clientWidth;

    return isLeftEdgeOutOfBounds
      ? getSafeLeftHorizontalPosition(target, content, true)
      : isRightEdgeOutOfBounds
        ? getSafeRightHorizontalPosition(target, content, true)
        : proposedPosition;
  }
  return 0;
}

// @TODO: Elegantly handle the potential situation when there is NO
// safe position above OR below the target. :thinking_face:
export function getSafeTopVerticalPosition(
  target?: DomReferenceRect,
  content?: DomReferenceRect,
  verticalGap = 0,
  bottomIsUnsafe = false,
): number {
  if (target && content) {
    return target.top - content.height - verticalGap < 0 && !bottomIsUnsafe
      ? getSafeBottomVerticalPosition(target, content, verticalGap, true)
      : target.top - content.height - verticalGap;
  }
  return 0;
}

export function getSafeBottomVerticalPosition(
  target?: DomReferenceRect,
  content?: DomReferenceRect,
  verticalGap = 0,
  topIsUnsafe = false,
): number {
  if (target && content) {
    // @NOTE
    return target.bottom + content.height + verticalGap > window.innerHeight &&
      !topIsUnsafe
      ? getSafeTopVerticalPosition(target, content, verticalGap)
      : target.bottom + verticalGap;
  }
  return 0;
}

export function getParallaxCoords(
  layerWidth: number,
  layerHeight: number,
  containerWidth: number,
  containerHeight: number,
  layerX: number,
  layerY: number,
) {
  const excessW = layerWidth - containerWidth;
  const excessH = layerHeight - containerHeight;
  const percentageW = layerX / containerWidth;
  const percentageH = layerY / containerHeight;
  return {
    x: percentageW * excessW,
    y: percentageH * excessH,
  };
}
