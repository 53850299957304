import {
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Stack, type StackProps } from "@/components/Stack";
import { useBrowserEffect, useGetCurrentSizeClass, useInterval } from "@/hooks";
import type { MakeResponsive } from "@/types";
import { warnUser } from "@/utils";

import { CountdownTile } from "./CountdownTile";
import {
  COUNTDOWN_TIMER_SIZES,
  type CountdownTimerSize,
  type CountdownTimerVariant,
  DEFAULT_COUNTDOWN_TIMER_SIZE,
  DEFAULT_COUNTDOWN_TIMER_VARIANT,
} from "./shared";
import { countDownContainerBaseSx } from "./styles";

export type CountdownTimerProps<
  RC extends ReactElement | undefined = undefined,
> = StackProps<RC> & {
  futureTarget: Date;
  size?: MakeResponsive<CountdownTimerSize>;
  variant?: CountdownTimerVariant;
  hideDays?: boolean;
  hideSeconds?: boolean;
  hideHours?: boolean;
  hideMinutes?: boolean;
  onCountdownEnd?: () => void;
  displayUpdateInterval?: number;
  getSafeTimeoutDelay?: (safeDelay: number) => void;
};

function getTimeLeft(futureTarget: Date) {
  const diffTime = Math.abs(
    new Date().valueOf() - new Date(futureTarget).valueOf(),
  );
  const days = diffTime / (24 * 60 * 60 * 1000);
  const hours = (days % 1) * 24;
  const minutes = (hours % 1) * 60;
  const seconds = (minutes % 1) * 60;
  return { days, hours, minutes, seconds };
}

const DEFAULT_TIME = { days: 0, hours: 0, minutes: 0, seconds: 0 };
// https://mrcoles.com/maximum-delay-settimeout/
export const MAX_TIMEOUT_INTEGER = 2147483647;

export function CountdownTimer<
  RC extends ReactElement | undefined = undefined,
>({
  futureTarget,
  hideDays = false,
  hideSeconds = false,
  hideHours = false,
  hideMinutes = false,
  size = DEFAULT_COUNTDOWN_TIMER_SIZE,
  variant = DEFAULT_COUNTDOWN_TIMER_VARIANT,
  className,
  testId,
  onCountdownEnd,
  direction = "row",
  justifyContent = "space-between",
  displayUpdateInterval = 1000,
  getSafeTimeoutDelay,
  sx = {},
  ...props
}: CountdownTimerProps<RC>) {
  const [timeLeft, setTimeLeft] = useState(DEFAULT_TIME);
  const safeTimeoutDelay = useMemo(() => {
    const now = new Date().valueOf();
    const future = futureTarget.valueOf();
    const diff = future - now;
    const safeDelay = Math.min(diff, MAX_TIMEOUT_INTEGER);
    getSafeTimeoutDelay?.(safeDelay);
    return safeDelay;
  }, [futureTarget, getSafeTimeoutDelay]);
  useBrowserEffect(() => {
    setTimeLeft(getTimeLeft(futureTarget));

    // @NOTE: setup a single timeout to fire the callback when the countdown ends:
    const timoutRef = window.setTimeout(() => {
      if (onCountdownEnd) {
        onCountdownEnd();
      }
    }, safeTimeoutDelay);

    return () => {
      clearInterval(displayPollIntervalRef.current);
      clearTimeout(timoutRef);
    };
  }, []);

  const cancelIntervalAndResetTime = useCallback(() => {
    setTimeLeft(DEFAULT_TIME);
    clearInterval(displayPollIntervalRef.current);
  }, []);

  // @NOTE: create a slow interval to update the component's time display:
  const displayPollIntervalRef = useInterval(() => {
    const newTimeLeft = getTimeLeft(futureTarget);
    const now = new Date();
    if (now >= futureTarget) {
      cancelIntervalAndResetTime();
    }
    return setTimeLeft(newTimeLeft);
  }, displayUpdateInterval);

  useEffect(() => {
    const now = new Date();
    if (now >= futureTarget) {
      warnUser(
        `CountdownTimer has recieved ${futureTarget.toLocaleString()} as a futureTarget. This is invalid, as it is in the past.
  Timer interval has been disabled`,
      );
      clearInterval(displayPollIntervalRef.current);
      setTimeLeft(DEFAULT_TIME);
    }
  }, [futureTarget, displayPollIntervalRef]);

  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_COUNTDOWN_TIMER_SIZE,
    COUNTDOWN_TIMER_SIZES,
  );
  const allSx = useMemo(() => merge(countDownContainerBaseSx, sx), [sx]);

  return (
    <Stack
      {...props}
      data-safe-timeout={safeTimeoutDelay}
      direction={direction}
      justifyContent={justifyContent}
      testId={testId}
      sx={allSx}
      className={`${
        className ?? ""
      } CountdownTimer CountdownTimer--${variant} CountdownTimer--${sizeClass}`}
    >
      {!hideDays && (
        <CountdownTile
          label="days"
          digit={Math.floor(timeLeft.days)}
          size={size}
          variant={variant}
        />
      )}
      {!hideHours && (
        <CountdownTile
          label="hours"
          digit={Math.floor(timeLeft.hours)}
          size={size}
          variant={variant}
        />
      )}
      {!hideMinutes && (
        <CountdownTile
          label="minutes"
          digit={Math.floor(timeLeft.minutes)}
          size={size}
          variant={variant}
        />
      )}
      {!hideSeconds && (
        <CountdownTile
          label="seconds"
          digit={Math.floor(timeLeft.seconds)}
          size={size}
          variant={variant}
        />
      )}
    </Stack>
  );
}

CountdownTimer.displayName = "CountdownTimer";
