import type { BiomeTheme, DeeplyNestedSx, MakeResponsive } from "@/types";
import { getResponsiveSx, hFlex } from "@/utils";
import type { Properties } from "csstype";
import type { ChipSize } from "./shared";

export const baseChipStyles: DeeplyNestedSx = {
  ...hFlex,
  alignItems: "center",
  gap: "base.spacing.x2",
  px: "base.spacing.x3",
  bg: "base.color.translucent.emphasis.100",
  brad: "base.borderRadius.x4",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionProperty: "background, border-color",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",

  "&.Chip--inverseVariant": {
    bg: "base.color.translucent.inverse.200",

    "& .Chip__label": {
      c: "base.color.text.body.inverse.primary",
    },
    "& .ChipIcon": {
      fill: "base.color.text.body.inverse.primary",
    },
    "& .ClearFieldButton": {
      fill: "base.color.translucent.inverse.600",

      "&:hover": {
        fill: "base.color.text.body.inverse.primary",
      },
    },
  },

  "&.Chip--mediumSize": {
    py: "base.spacing.x1",
    minh: "base.icon.size.400",
  },

  "&.Chip--largeSize": {
    py: "base.spacing.x2",
    minh: "base.icon.size.500",
  },

  "&.Chip--hasWholeOnClick": {
    cursor: "pointer",
    border: "solid",
    borderWidth: "base.border.size.100",
    borderColor: "base.color.translucent.standard.500",

    "&:hover": {
      bg: "base.color.translucent.emphasis.200",
      borderColor: "base.color.translucent.standard.800",
    },

    "&:active": {
      bg: "base.color.translucent.emphasis.300",
      borderColor: "base.color.translucent.standard.800",
    },
  },
};

export const labelSx = {
  flex: 1,
};

export const baseChipLeftImageContentSx = {
  flexShrink: 0,
};

function getChipIconSx({
  size,
  theme: { base },
}: {
  size: ChipSize;
  theme: BiomeTheme;
}): Properties {
  return size === "large"
    ? {
        width: base.icon.size[200],
      }
    : {
        width: base.icon.size[100],
      };
}

export const getResponsiveChipIconSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ChipSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getChipIconSx,
  });
