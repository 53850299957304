import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { ButtCon } from "@/components/Clickable";
import type {
  AllDualVariantIconKeys,
  DeeplyNestedSx,
  OptionalIconButtConProps,
} from "@/types";

type ConfirmationDialogContentCloseButtConProps<
  RC extends ReactElement | undefined,
> = OptionalIconButtConProps<RC>;

const closeButtonBaseSx: DeeplyNestedSx = {
  pos: "absolute",
  top: "base.spacing.x4",
  right: "base.spacing.x4",
  backdropFilter: "base.frost.100",
};

export function ConfirmationDialogContentCloseButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  className,
  size = "medium",
  variant = "tertiary",
  ...props
}: ConfirmationDialogContentCloseButtConProps<RC>) {
  const { icon = "Close", ...propsMinusIcon } =
    "icon" in props ? props : { icon: undefined, ...props };
  const { children, ...propsMinusIconAndChildren } =
    "children" in propsMinusIcon
      ? propsMinusIcon
      : { children: null, ...propsMinusIcon };
  const { iconVariant = "bold", ...propsMinusIconAndChildrenAndIconVariant } =
    "iconVariant" in propsMinusIconAndChildren
      ? propsMinusIconAndChildren
      : { iconVariant: undefined, ...propsMinusIconAndChildren };

  const mergedSx = useMemo(() => merge(closeButtonBaseSx, sx), [sx]);
  return (
    <ButtCon
      {...propsMinusIconAndChildrenAndIconVariant}
      icon={icon as AllDualVariantIconKeys}
      iconVariant={iconVariant}
      size={size}
      variant={variant}
      sx={mergedSx}
      className={`${className ?? ""} ConfirmationDialogContentCloseButtCon`}
    />
  );
}

ConfirmationDialogContentCloseButtCon.displayName =
  "ConfirmationDialog.Content.CloseButtCon";
ConfirmationDialogContentCloseButtCon.SvgIcon = ButtCon.SvgIcon;
