import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { HeroZoomText, type HeroZoomTextProps } from "../HeroZoomText";

export type HeroBackgroundZoomTextProps<
  RC extends ReactElement | undefined = undefined,
> = HeroZoomTextProps<RC> & {};

export function HeroBackgroundZoomText<
  RC extends ReactElement | undefined = undefined,
>({
  rc,
  sx = {},
  testId = "HeroBackgroundZoomText",
  ...props
}: HeroBackgroundZoomTextProps<RC>) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          c: "base.color.fixed.white.1000",
          flexShrink: 0,
          pos: "absolute",
          top: "0",
          left: "0",
          w: "100%",
        },
        sx,
      ),
    [sx],
  );
  return <HeroZoomText {...props} rc={rc} sx={mergedSx} testId={testId} />;
}

HeroBackgroundZoomText.displayName = "HeroBgBlock.ZoomText";
