import packageData from "../package.json";
import { base, fontFaceStylesImport, onDarkBase } from "./tokens/base";

export * from "./types";
export { leafIsObject } from "./utils/leafUtils";
export {
  pickTokenValue,
  smartPickTokenValue,
} from "./utils/processTokenUtils";
export { base, fontFaceStylesImport, onDarkBase, base as onLightBase };
export const designTokens = {
  base,
};

export const { version } = packageData;

console.log(`
  @biom3/design-tokens
  
  version: ${version}

`);
