import type { Colors, Gradients, Shadows } from "../../types";

export const onDarkColors: Colors = {
  brand: {
    1: "#F3F3F3",
    2: "#131313",
    3: "#E0E0E0",
    4: "#B6B6B6",
    5: "#36E0F8",
    6: "#1F8290",
  },
  neutral: {
    1000: "#0D0D0D",
    900: "#131313",
    800: "#1C1C1C",
    700: "#202020",
    600: "#252525",
    500: "#292929",
  },
  accent: {
    1: "#F191FA",
    2: "#36E0F8",
    3: "#6FD6F2",
    4: "#61ADEB",
    5: "#99A8EA",
    6: "#E4F4F7",
    7: "#F8D1DE",
    8: "#F2B7DA",
  },
  translucent: {
    emphasis: {
      500: "#F7F7F729", // rgba(247, 247, 247, 0.16)
      400: "#F7F7F71F", // rgba(247, 247, 247, 0.12)
      300: "#F7F7F71A", // rgba(247, 247, 247, 0.10)
      200: "#F7F7F714", // rgba(247, 247, 247, 0.08)
      100: "#F7F7F70F", // rgba(247, 247, 247, 0.06)
    },
    standard: {
      1000: "#F7F7F7", // rgba(247, 247, 247, 1)
      900: "#F7F7F7E0", // rgba(247, 247, 247, 0.88)
      800: "#F7F7F7CC", // rgba(247, 247, 247, 0.80)
      700: "#F7F7F7A3", // rgba(247, 247, 247, 0.64)
      600: "#F7F7F77A", // rgba(247, 247, 247, 0.48)
      500: "#F7F7F73D", // rgba(247, 247, 247, 0.24)
      400: "#F7F7F729", // rgba(247, 247, 247, 0.16)
      300: "#F7F7F71F", // rgba(247, 247, 247, 0.12)
      200: "#F7F7F714", // rgba(247, 247, 247, 0.08)
      150: "#F7F7F70F", // rgba(247, 247, 247, 0.06)
      100: "#F7F7F70A", // rgba(247, 247, 247, 0.04)
      50: "#F7F7F705", // rgba(247, 247, 247, 0.03)
    },
    inverse: {
      1000: "#0D0D0D", // rgba(13, 13, 13, 1)
      900: "#0D0D0DE0", // rgba(13, 13, 13, 0.88)
      800: "#0D0D0DCC", // rgba(13, 13, 13, 0.80)
      700: "#0D0D0DA3", // rgba(13, 13, 13, 0.64)
      600: "#0D0D0D7A", // rgba(13, 13, 13, 0.48)
      500: "#0D0D0D3D", // rgba(13, 13, 13, 0.24)
      400: "#0D0D0D29", // rgba(13, 13, 13, 0.16)
      300: "#0D0D0D1F", // rgba(13, 13, 13, 0.12)
      200: "#0D0D0D14", // rgba(13, 13, 13, 0.08)
      150: "#0D0D0D0F", // rgba(13, 13, 13, 0.06)
      100: "#0D0D0D0A", // rgba(13, 13, 13, 0.04)
      50: "#0D0D0D05", // rgba(13, 13, 13, 0.03)
    },
  },
  status: {
    guidance: {
      bright: "#36E0F8",
      dim: "#94E9F5 ",
    },
    success: {
      bright: "#ABF790",
      dim: "#D5FAC7",
    },
    attention: {
      bright: "#FAFD7E",
      dim: "#F6F8B9",
    },
    fatal: {
      bright: "#FF637F",
      dim: "#F9ABB9",
    },
  },
  fixed: {
    black: {
      1000: "#131313",
      100: "#13131314",
    },
    white: {
      1000: "#F3F3F3",
      100: "#F3F3F314",
    },
  },
  text: {
    highlight: "#36E0F833",
    heading: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      emphasis: "base.gradient.1",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "#6E6E6E",
      },
    },
    body: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "#6E6E6E",
      },
    },
    caption: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "#6E6E6E",
      },
    },
    link: {
      primary: "base.color.accent.1",
      secondary: "base.color.brand.1",
      inverse: {
        primary: "#b026bd",
        secondary: "base.color.brand.2",
      },
    },
    status: {
      success: {
        primary: "base.color.status.success.bright",
        secondary: "base.color.status.success.dim",
      },
      guidance: {
        primary: "base.color.status.guidance.bright",
        secondary: "base.color.status.guidance.dim",
      },
      attention: {
        primary: "base.color.status.attention.bright",
        secondary: "base.color.status.attention.dim",
      },
      fatal: {
        primary: "base.color.status.fatal.bright",
        secondary: "base.color.status.fatal.dim",
      },
    },
  },
  overlay: "base.color.translucent.standard.500",
} as const;

export const onDarkShadows: Shadows = {
  100: "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 1px 4px 1px rgba(0, 0, 0, 0.32)",
  200: "0px 2px 6px 2px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.32)",
  300: "0px 4px 8px 4px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.32)",
  400: "0px 6px 10px 4px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.32)",
  500: "0px 8px 12px 6px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.32)",
} as const;

export const onDarkGradients: Gradients = {
  1: {
    spectrum: "linear-gradient(105.76deg, #F191FA -46.96%, #83E3F0 99.99%)",
    blendMode: "normal",
    svgDefs: `
    <linearGradient id="gradient-1-singleLayer" x1="0" y1="0" x2="24" y2="0.214702"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#C9A2F6" />
      <stop offset="1" stop-color="#83E3F0" />
    </linearGradient>`,
    svgDefIds: "gradient-1-singleLayer",
  },
  2: {
    spectrum:
      "radial-gradient(96.87% 65.57% at 54.34% 93.79%, #FCF5EE 0%, rgba(236, 190, 225, 0) 71.51%), radial-gradient(73.48% 242.97% at 26.54% 26.42%, #A3EEF8 0%, #A4DCF5 17.71%, #A6AEEC 38.02%, #ECBEE1 100%)",
    blendMode: "normal, normal",
    svgDefs: `
    <radialGradient id="gradient-2-bottomLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(4.7777 4.75595) rotate(44.9817) scale(18.6995 39.6574)">
      <stop stop-color="#A3EEF8" />
      <stop offset="0.177083" stop-color="#A4DCF5" />
      <stop offset="0.380208" stop-color="#A6AEEC" />
      <stop offset="1" stop-color="#ECBEE1" />
    </radialGradient>
    <radialGradient id="gradient-2-topLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(9.7806 16.8827) rotate(84.265) scale(9.68552 18.5286)">
      <stop stop-color="#FCF5EE" />
      <stop offset="0.715135" stop-color="#ECBEE1" stop-opacity="0" />
    </radialGradient>`,
    svgDefIds: "gradient-2-bottomLayer, gradient-2-topLayer",
  },
  3: {
    spectrum:
      "radial-gradient(100% 100% at 24.5% 100%, rgba(106, 160, 242, 0.9) 0%, rgba(198, 229, 247, 0) 100%), linear-gradient(81.66deg, rgba(124, 246, 251, 0.48) 9.38%, rgba(254, 253, 248, 0.7) 98.47%), radial-gradient(4009.6% 10453.55% at 0% 0%, #7EF8FE 0%, #7ABFEC 30.21%, #AEC3ED 45.54%, #DEF3F6 61.46%, #FAF9F3 100%)",
    blendMode: "normal, normal, normal",
    svgDefs: `
    <radialGradient id="gradient-3-bottomLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="rotate(60.6728) scale(36.75 1550.85)">
      <stop stop-color="#7EF8FE" />
      <stop offset="0.302083" stop-color="#7ABFEC" />
      <stop offset="0.455396" stop-color="#AEC3ED" />
      <stop offset="0.614583" stop-color="#DEF3F6" />
      <stop offset="1" stop-color="#FAF9F3" />
    </radialGradient>
    <linearGradient id="gradient-3-middleLayer" x1="-1.26123e-07" y1="4.8" x2="24" y2="2.16"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#7CF6FB" stop-opacity="0.48" />
      <stop offset="1" stop-color="#FEFDF8" stop-opacity="0.7" />
    </linearGradient>
    <radialGradient id="gradient-3-topLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(4.41 18) rotate(-52.9472) scale(22.5541)">
      <stop stop-color="#6AA0F2" />
      <stop offset="1" stop-color="#C6E5F7" stop-opacity="0" />
    </radialGradient>`,
    svgDefIds: `gradient-3-bottomLayer,
      gradient-3-middleLayer,
      gradient-3-topLayer`,
  },
} as const;
