import { type ReactNode, createContext, useContext, useState } from "react";

type ProfileDrawerContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  openOverlay?: "status" | "connections";
  setOpenOverlay: (overlay?: "status" | "connections") => void;
};

const ProfileDrawerContext = createContext<
  ProfileDrawerContextType | undefined
>(undefined);

export function ProfileDrawerProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openOverlay, setOpenOverlay] = useState<
    "status" | "connections" | undefined
  >(undefined);

  return (
    <ProfileDrawerContext.Provider
      value={{
        isOpen,
        setIsOpen,
        openOverlay,
        setOpenOverlay,
      }}
    >
      {children}
    </ProfileDrawerContext.Provider>
  );
}

export function useProfileDrawer() {
  const context = useContext(ProfileDrawerContext);
  if (context === undefined) {
    throw new Error(
      "useProfileDrawer must be used within a ProfileDrawerProvider",
    );
  }
  return context;
}
