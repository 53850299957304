import { useTheme } from "@emotion/react";
import type { ReactElement, ReactNode } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TOOLBAR_SIZE, TOOLBAR_SIZES } from "@/constants";
import type {
  DomPropsWithDomRef,
  MakeResponsive,
  StandardComponentWithProps,
  ToolbarSize,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";
import { lineClamp } from "@/utils/sxChunks";

import { Box } from "../Box";
import { getResponsiveTitleStyles, getTitleStyles } from "./styles";

export type ToolbarTitleProps = StandardComponentWithProps<
  HTMLHeadingElement,
  {
    size?: MakeResponsive<ToolbarSize>;
    children?: ReactNode;
  }
>;

export function ToolbarTitle<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_TOOLBAR_SIZE,
  sx = {},
  rc = <h3 />,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"h3"> & ToolbarTitleProps
  : ToolbarTitleProps & { rc: RC }) {
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_TOOLBAR_SIZE,
    TOOLBAR_SIZES,
  );
  const titleStyles = merge(
    lineClamp(1),
    { c: "base.color.text.body.primary" },
    getTitleStyles({ themeProps, size: startingSize }),
    getResponsiveTitleStyles({
      themeProps,
      size,
    }),
    sx,
  );
  return <Box {...props} sx={titleStyles} rc={rc} />;
}

ToolbarTitle.displayName = "Toolbar.Title";
