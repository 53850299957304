import type { Properties } from "csstype";

import { INPUT_MIN_WIDTH } from "@/constants";
import type {
  BiomeTheme,
  InputTextAlign,
  InputValidationStatus,
  MakeResponsive,
  TextAreaSize,
} from "@/types";
import { type RenderSxProps, getResponsiveSx } from "../../utils/styleHelpers";

// @TODO: see if we can use more of the TextInput styles ...

export const containerBaseSx = {
  position: "relative",
  minWidth: INPUT_MIN_WIDTH,
  flexShrink: 0,
  borderRadius: "base.borderRadius.x4",
  transitionProperty: "box-shadow, background",
};

export const baseTextAreaSx = {
  appearance: "none",
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
  border: "none",
  borderRadius: "base.borderRadius.x4",
  fontFamily: "base.font.family.body.primary",
  fontSize: "base.text.body.medium.regular.fontSize",
  lineHeight: "base.text.body.medium.regular.lineHeight",
  fontWeight: "base.text.body.medium.regular.fontWeight",
  background: "transparent",
  padding: "0",
  color: "base.color.text.body.primary",

  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    // @NOTE: this hides the up and down arrows on number inputs
    display: "none",
  },

  "&::placeholder": {
    color: "base.color.text.body.secondary",
  },

  "&:not(:disabled):focus": {
    outline: "none",
  },
};

export function getTextAreaSx({
  textAlign,
}: {
  size: TextAreaSize;
  theme: BiomeTheme;
  textAlign: InputTextAlign;
}) {
  return {
    textAlign,
  };
}

export const getResponsiveTextAreaSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<TextAreaSize>;
  textAlign: InputTextAlign;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getTextAreaSx as (
      props: RenderSxProps<TextAreaSize>,
    ) => Properties,
  });

export const clearValueButtonSx = {
  position: "absolute",
  right: "base.spacing.x3",
};

export function getContainerSx({
  validationStatus,
  theme,
  hasValue,
  size,
}: {
  validationStatus: InputValidationStatus | undefined;
  theme: BiomeTheme;
  size: TextAreaSize;
  hasValue: boolean;
}) {
  const gutter = theme.base.spacing.x3;
  const paddingY =
    size === "large" ? theme.base.spacing.x5 : theme.base.spacing.x3;
  const paddingLeft = gutter;
  const paddingRight = `calc(${gutter} + (${gutter} * 2))`;
  const hoverShadow = theme.base.shadow[300];
  const emptyShadow = "0 0 0 rgba(0,0,0,0)";

  return {
    paddingY,
    paddingLeft,
    paddingRight,

    background: hasValue
      ? theme.base.color.translucent.emphasis[200]
      : theme.base.color.translucent.emphasis[100],

    boxShadow:
      validationStatus === "error"
        ? `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.status.fatal.bright}`
        : hasValue
          ? `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[800]}`
          : `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[500]}`,

    "&:not(.disabled):hover": {
      background: hasValue
        ? theme.base.color.translucent.emphasis[300]
        : theme.base.color.translucent.emphasis[200],
      boxShadow:
        validationStatus === "error"
          ? `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.status.fatal.bright}`
          : `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[800]}`,
    },

    "&:not(.disabled).focused": {
      outline: "none",
      background: theme.base.color.translucent.emphasis[300],
      boxShadow:
        validationStatus === "error"
          ? `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[200]} ${theme.base.color.status.fatal.bright}`
          : `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[200]} ${theme.base.color.brand[1]}`,
    },
  };
}

export const getResponsiveContainerSx = (props: {
  size: MakeResponsive<TextAreaSize>;
  validationStatus: InputValidationStatus | undefined;
  theme: BiomeTheme;
  hasValue: boolean;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerSx as unknown as (
      props: RenderSxProps<TextAreaSize>,
    ) => Properties,
  });

export function getClearValueButtonSx({
  size,
  theme,
}: {
  size: TextAreaSize;
  theme: BiomeTheme;
}) {
  return {
    top: size === "large" ? theme.base.spacing.x5 : theme.base.spacing.x3,
  };
}

export const getResponsiveClearValueButtonSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<TextAreaSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getClearValueButtonSx,
  });
