import type { Property } from "csstype";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { CloudImage } from "@/components/CloudImage";
import { DEFAULT_LOADING_IMG_ATTR } from "@/constants";
import type { AspectRatioImageProps } from "@/types";
import { isImageResizerProps } from "@/utils";

function convertOldFormatAspectRatio(aspectRatio: Property.AspectRatio) {
  return typeof aspectRatio === "string"
    ? aspectRatio.replace(":", "/")
    : aspectRatio;
}

export function AspectRatioImage<
  Use extends ReactElement | undefined = undefined,
>({
  loading = DEFAULT_LOADING_IMG_ATTR,
  sx = {},
  aspectRatio = "1/1",
  objectFit = "cover",
  objectPosition = "50% 50%",
  testId = "aspectRatioImage",
  className,
  domRef,
  alt,
  use,
  ...props
}: AspectRatioImageProps<Use>) {
  const safeAspectRatio = useMemo(() => {
    if (Array.isArray(aspectRatio)) {
      return aspectRatio.map((aspect) => {
        return convertOldFormatAspectRatio(aspect as Property.AspectRatio);
      });
    }

    return convertOldFormatAspectRatio(aspectRatio as Property.AspectRatio);
  }, [aspectRatio]);

  const mergedSx = useMemo(
    () =>
      merge(
        {
          aspectRatio: safeAspectRatio,
          objectFit,
          objectPosition,
        },
        sx,
      ),
    [sx, safeAspectRatio, objectFit, objectPosition],
  );

  const typedProps = isImageResizerProps(use, props)
    ? props
    : {
        use,
        ...props,
      };
  return (
    <CloudImage
      {...typedProps}
      domRef={domRef}
      alt={alt}
      loading={loading}
      className={`${className ?? ""} AspectRatioImage`}
      testId={testId}
      sx={mergedSx}
    />
  );
}

AspectRatioImage.displayName = "AspectRatioImage";
