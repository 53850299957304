import { createFastContext } from "@/utils/createFastContext";

export type WindowSizeStore = { width: number | null; height: number | null };

export const initialState: WindowSizeStore = { width: null, height: null };

export const {
  Provider: BiomeWindowSizeProvider,
  useStore: useWindowSizeStore,
} = createFastContext(initialState);

// @NOTE: The below shows up in react dev tools - which aids identifying
// the provider in the tree
BiomeWindowSizeProvider.displayName = "BiomeWindowSizeProvider";
