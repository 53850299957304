export const CLOSE_DRAWER = "closeDrawer";
export const OPEN_DRAWER = "openDrawer";
export const UPDATE_DRAWER_PROPS = "updateDrawerProps";

export const CLOSE_MODAL = "closeModal";
export const OPEN_MODAL = "openModal";
export const UPDATE_MODAL_PROPS = "updateModalProps";

export const CLOSE_POPOVER = "closePopover";
export const OPEN_POPOVER = "openPopover";
export const UPDATE_POPOVER_PROPS = "updatePopoverProps";

export const CLOSE_TOAST = "closeToast";
export const OPEN_TOAST = "openToast";
export const UPDATE_TOAST_PROPS = "updateToastProps";
