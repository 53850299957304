import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps } from "@/types";

export type ConfirmationDialogContentAnimationBoxProps<
  RC extends ReactElement | undefined,
> = BoxWithRCAndDomProps<RC> & {};

export function ConfirmationDialogContentRiveBox<
  RC extends ReactElement | undefined,
>({
  sx = {},
  rc,
  className,
  ...props
}: ConfirmationDialogContentAnimationBoxProps<RC>) {
  return (
    <Box
      {...props}
      sx={merge({ w: "100%", height: "250px", flexShrink: 0 }, sx)}
      className={`${className ?? ""} ConfirmationDialogContentRiveBox`}
    >
      {rc}
    </Box>
  );
}

ConfirmationDialogContentRiveBox.displayName =
  "ConfirmationDialog.Content.RiveBox";
