export function slugify(input: string): string {
  // @NOTE: insert some protection against maliciously long input strings:
  // resolves: https://github.com/immutable/biom3/security/code-scanning/7
  if (input.length > 500) {
    throw new Error(
      "slugify() Error: Input too long, please use a string less than 500 characters",
    );
  }
  return input
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // remove non-word characters
    .replace(/[\s_-]+/g, "-") // replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, ""); // remove leading and trailing hyphens
}

export function convertVariantToValidCssClass(input: string): string {
  return input.replace(/\//g, "_");
}

export function camelCaseToSpaces(s: string): string {
  return s.replace(/([a-z])([A-Z])/g, "$1 $2");
}
