import { merge } from "ts-deepmerge";

import { OverflowPopoverMenu } from "@/components/MenuItemRelatedComponents";
import { DEFAULT_BUTTON_SIZE } from "@/constants";
import type { OverflowPopoverMenuProps } from "@/types";

export type AppHeaderBarOverflowPopoverMenuProps = OverflowPopoverMenuProps;

export function AppHeaderBarOverflowPopoverMenu({
  children,
  sx = {},
  size,
  className,
  ...props
}: AppHeaderBarOverflowPopoverMenuProps) {
  return (
    <OverflowPopoverMenu
      {...props}
      size={DEFAULT_BUTTON_SIZE}
      sx={merge({ flexShrink: 0 }, sx)}
      className={`${className ?? ""} AppHeaderBarOverflowPopoverMenu`}
    >
      {children}
    </OverflowPopoverMenu>
  );
}

AppHeaderBarOverflowPopoverMenu.displayName =
  "AppHeaderBar.OverflowPopoverMenu";
