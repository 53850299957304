import { createContext } from "react";

export function recursivelyAddTooltipAttrsToChildNodes(
  domNode: HTMLElement | null,
  tooltipId: string,
  isTrigger?: boolean,
) {
  if (domNode) {
    domNode.setAttribute("data-tooltip-id", tooltipId);
    if (isTrigger) domNode.setAttribute("data-tooltip-target", "true");
    for (const child of Array.from(domNode.children)) {
      if (child.nodeType !== Node.TEXT_NODE) {
        child.setAttribute("data-tooltip-id", tooltipId);
        if (isTrigger) child.setAttribute("data-tooltip-target", "true");
        if (child.children) {
          recursivelyAddTooltipAttrsToChildNodes(
            child as HTMLElement,
            tooltipId,
          );
        }
      }
    }
  }
}

export type TooltipComponentContextType = {
  triggerDomRecrawl: () => void;
};

export const TooltipComponentContext =
  createContext<TooltipComponentContextType>({
    triggerDomRecrawl() {},
  });
