import type { BodySize, CaptionSize, HeadingSize } from "@biom3/design-tokens";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { ShimmerBox } from "@/components/Shimmer";
import { Stack } from "@/components/Stack";
import {
  DEFAULT_BODY_PROPS,
  DEFAULT_CAPTION_PROPS,
  DEFAULT_HEADING_PROPS,
} from "@/constants";
import { useTheme } from "@/hooks/useTheme";
import type { BoxWithRCAndDomProps, MakeResponsive } from "@/types";
import { vFlex } from "@/utils/sxChunks";
import {
  getBodyTextStyles,
  getCaptionTextStyles,
  getHeadingTextStyles,
} from "@/utils/textStyleHelpers";
import type { Properties } from "csstype";

type MultiTextDescriminatedUnion =
  | {
      textKind: "Caption";
      size?: MakeResponsive<CaptionSize>;
    }
  | {
      textKind: "Heading";
      size?: MakeResponsive<HeadingSize>;
    }
  | {
      textKind: "Body";
      size?: MakeResponsive<BodySize>;
    };

export type MultiTextShimmerProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> &
  MultiTextDescriminatedUnion & {
    shimmerLines?: number;
  };

export function MultiTextShimmer<
  RC extends ReactElement | undefined = undefined,
>({
  size,
  shimmerLines = 1,
  sx = {},
  testId,
  className,
  textKind,
  ...props
}: MultiTextShimmerProps<RC>) {
  const themeProps = useTheme();
  const mergedSx = useMemo(() => {
    let textComponentStyles: Properties;
    switch (textKind) {
      case "Caption":
        textComponentStyles = textComponentStyles = getCaptionTextStyles({
          themeProps,
          size: size ?? DEFAULT_CAPTION_PROPS.size,
          weight: DEFAULT_CAPTION_PROPS.weight,
        });
        break;
      case "Heading":
        textComponentStyles = getHeadingTextStyles({
          themeProps,
          size: size ?? DEFAULT_HEADING_PROPS.size,
          weight: DEFAULT_HEADING_PROPS.weight,
        });
        break;
      case "Body":
      default:
        textComponentStyles = getBodyTextStyles({
          themeProps,
          size: size ?? DEFAULT_BODY_PROPS.size,
          weight: DEFAULT_BODY_PROPS.weight,
          mono: false,
        });
        break;
    }

    return merge(
      {
        ...vFlex,
        display: "inline-flex",
        verticalAlign: "baseline",
      },
      textComponentStyles,
      sx,
    );
  }, [size, sx, textKind, themeProps]);

  return (
    <Box
      {...props}
      sx={mergedSx}
      testId={testId}
      className={`${className ?? ""} MultiTextShimmer`}
    >
      {Array.from({ length: shimmerLines }, (item, index) => (
        <Stack
          testId={`${testId}__line--${index}`}
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          className="MultiTextShimmer__line"
          direction="row"
          alignItems="center"
          sx={{
            pos: "relative",
            fontSize: "inherit",
            lineHeight: "inherit",
            c: "transparent",
          }}
        >
          Shimmer
          {/* ^ this text ensures the correct size of the shimmer box */}
          <ShimmerBox
            sx={{
              pos: "absolute",
              top: "50%",
              left: "0px",
              h: "66%",
              transform: "translateY(-50%)",
              ...(shimmerLines > 1 && index === shimmerLines - 1
                ? { w: "75%" }
                : {}),
            }}
          />
        </Stack>
      ))}
    </Box>
  );
}

MultiTextShimmer.displayName = "MultiTextShimmer";
