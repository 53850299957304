import type { AllLogoKeys } from "@/components/Logo/shared";
import { useEffect, useRef, useState } from "react";

import { fetchLogoContent } from "../utils/svgHelpers";
import { usePrevious } from "./usePrevious";
import { useUpdateEffect } from "./useUpdateEffect";

export function useFetchLogoContent(logo: AllLogoKeys) {
  const isFetching = useRef(false);
  const [svgContent, setSvgContent] = useState<string[] | null>(null);
  const previousLogo = usePrevious(logo);
  useEffect(() => {
    async function fetchContent() {
      isFetching.current = true;
      const content = await fetchLogoContent(logo);
      if (content) setSvgContent(content);
      isFetching.current = false;
    }
    // @NOTE: ONLY fetch the icon content if it hasn't already been fetched
    if (!svgContent && !isFetching.current) fetchContent();
  }, [logo, svgContent]);

  useUpdateEffect(() => {
    if (previousLogo !== logo) setSvgContent(null);
  }, [logo, previousLogo]);

  return svgContent;
}
