import type { ReactElement } from "react";

import type { LogoProps } from "@/types";

import { allLogos } from "./shared";

export function Logo<RC extends ReactElement | undefined = undefined>({
  logo,
  className,
  ...props
}: LogoProps<RC>) {
  const LogoComponent = allLogos[logo];
  return (
    <LogoComponent
      {...props}
      className={`${className} Logo Logo--${logo}`}
      data-logo={logo}
    />
  );
}

Logo.displayName = "Logo";
