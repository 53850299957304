import { Button } from "@/components/Clickable";
import type { TextInputButtonProps } from "@/types";

export function TextInputButton({
  children,
  currentInputValue,
  sx = {},
  ...props
}: TextInputButtonProps) {
  return (
    <Button {...props} variant="tertiary" size="small" sx={sx}>
      {children}
    </Button>
  );
}

TextInputButton.displayName = "TextInput.Button";
TextInputButton.Icon = Button.Icon;
TextInputButton.Logo = Button.Logo;
