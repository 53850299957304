import { AnimatePresence, motion } from "motion/react";
import { Fragment } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { useEventListener } from "@/hooks";
import { useTheme } from "@/hooks/useTheme";
import { CLOSE_MODAL } from "@/providers/BiomeOverlaysProvider/actions";
import { useOverlaysStore } from "@/providers/BiomeOverlaysProvider/overlaysContext";
import { userSelect } from "@/utils";
import { getModalAnimationProps, getModalContainerPositionSx } from "./helpers";

export type ModalOverlayProps = { hasExternalContainer?: boolean };

export function ModalOverlay({ hasExternalContainer }: ModalOverlayProps) {
  const { base } = useTheme();
  const { state: modalList, dispatchAction } = useOverlaysStore(
    (state) => state.modalList,
  );
  useEventListener(
    "keydown",
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && modalList.length > 0) {
        const topModal = modalList[modalList.length - 1];
        if (topModal?.escapeKeyClose) {
          topModal.onCloseModal?.();
          if (!topModal.isControlled) {
            dispatchAction({
              type: CLOSE_MODAL,
              payload: {
                id: topModal.id,
              },
            });
          }
        }
      }
    },
    { current: document.body },
  );
  return (
    <AnimatePresence>
      {modalList.map((modal) => {
        const mergedModalBgSx = merge(
          {
            position: hasExternalContainer ? "absolute" : "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: modal.showBgOverlay ? base.color.overlay : "",
            zIndex: base.zLevel.modal,
          },
          modal.bgOverlaySx,
        );

        const modalBgAnimationProps =
          modal.motionProfile !== "none"
            ? {
                initial: {
                  opacity: 0,
                },
                animate: {
                  opacity:
                    typeof modal.bgOverlaySx.opacity === "number" ||
                    typeof modal.bgOverlaySx.opacity === "string"
                      ? modal.bgOverlaySx.opacity
                      : 1,
                },
                exit: {
                  opacity: 0,
                },
                transition: {
                  duration: base.motion.normal[modal.motionProfile].jsDuration,
                  ease: base.motion.normal[modal.motionProfile].jsEase,
                },
              }
            : {};
        const modalAnimationProps = getModalAnimationProps(
          modal.motionProfile,
          modal.motionPattern,
          base,
        );

        return (
          <Fragment key={modal.id}>
            <Box
              {...modalBgAnimationProps}
              key={`${modal.id}__bgOverlay`}
              testId={`${modal.testId}__bgOverlay`}
              sx={mergedModalBgSx}
              rc={
                <motion.div
                  onClick={() => {
                    if (modal.outsideClicksClose) {
                      modal.onCloseModal?.();
                      if (!modal.isControlled) {
                        dispatchAction({
                          type: CLOSE_MODAL,
                          payload: {
                            id: modal.id,
                          },
                        });
                      }
                    }
                  }}
                />
              }
            />

            <Box
              sx={{
                position: hasExternalContainer ? "absolute" : "fixed",
                zIndex: base.zLevel.modal,
                top: "0",
                left: "0",
                w: "100%",
                h: "100%",
                d: "flex",
                p: "base.spacing.x12",
                ...userSelect.none,
                ...getModalContainerPositionSx(modal.position),
                ...(modal.fullScreenUntilBreakpoint
                  ? {
                      [`@media screen and (max-width: ${
                        base.breakpoint[modal.fullScreenUntilBreakpoint]
                      }px)`]: {
                        p: "0px",
                        alignItems: "stretch",
                      },
                    }
                  : {}),
              }}
              testId={`${modal.testId}__modalContainer`}
            >
              <Box
                testId={`${modal.testId}__modalContainer__content`}
                sx={{
                  pos: "relative",
                  display: "flex",
                  flexDirection: "column",
                  ...userSelect.text,
                  ...(modal.fullScreenUntilBreakpoint
                    ? {
                        [`@media screen and (max-width: ${
                          base.breakpoint[modal.fullScreenUntilBreakpoint]
                        }px)`]: {
                          width: "100%",

                          "& .ModalContent": {
                            borderRadius: "0px",
                          },
                        },
                      }
                    : {}),
                }}
                rc={<motion.div {...modalAnimationProps} />}
              >
                {modal.content}
              </Box>
            </Box>
          </Fragment>
        );
      })}
    </AnimatePresence>
  );
}
