import {
  Accordion,
  AspectRatioImage,
  Body,
  Box,
  Button,
  Divider,
  Icon,
  Stack,
} from "@biom3/react";
import type { Content } from "@prismicio/client";
import { GamePlatforms } from "@ui-kit/GameHeader";
import { useIsLessThanMediumScreen } from "@ui-kit/hooks/screenSizeHooks";
import { trackGameDetailsEvent } from "@ui-kit/utils";
import NextLink from "next/link";
import { useMemo } from "react";
import { Specs } from "./Specs";

export type GameSpec = {
  main: GameMain;
  specifications: GameSpecifications;
};

export type GameSpecsProps = {
  gameSpec: GameSpec;
  wishlistProps: WishlistProps;
  game: Content.GameDocument;
};

type GameSocial = {
  network: string;
  link: string;
  logo: string;
};

type GameAgeRating = {
  name: string;
  description: string;
  logo: string;
};

type GameMain = {
  logo: string;
  socials: GameSocial[];
  website?: string;
};

type GameCompany = {
  name: string;
  website: string;
};

export type GameSpecifications = {
  studio: GameCompany;
  publisher: GameCompany;
  ageRating: GameAgeRating;
  genres: string[];
  platforms: [string, string][];
  releaseDate: string;
};

export type WishlistProps = {
  isWishlisted: boolean;
  onWishlistClick: (
    identifier: string,
    extras?: Record<string, unknown>,
  ) => Promise<void>;
  gemsState:
    | "alreadyClaimed"
    | "claimed"
    | "unclaimed"
    | "pending"
    | "creatingWallet"
    | "disabled";
  gemAmount?: number;
};

export const GameSpecs = ({
  gameSpec,
  wishlistProps,
  game,
}: GameSpecsProps) => {
  const isSmallScreenMode = useIsLessThanMediumScreen();
  const isWishlisted = useMemo(
    () => wishlistProps.isWishlisted,
    [wishlistProps.isWishlisted],
  );

  const onPlayNowButtonClick = () => {
    trackGameDetailsEvent({
      game,
      identifier: "GameSpecs",
      control: "PlayNowClicked",
      controlType: "Button",
      extras: {
        website: gameSpec.main.website,
      },
    });
  };

  return (
    <Stack gap="base.spacing.x2">
      <Stack>
        <AspectRatioImage
          imageUrl={gameSpec.main.logo}
          aspectRatio={"0:1"}
          responsiveSizes={[1024]}
          relativeImageSizeInLayout={"270px"}
          sx={{
            brad: "base.borderRadius.x6",
            w: "100%",
            mb: "base.spacing.x2",
          }}
        />
        <Box
          sx={{
            mb: "base.spacing.x6",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <GamePlatforms game={game} />
        </Box>
      </Stack>

      <Body
        sx={{
          textAlign: "center",
          pb: "base.spacing.x2",
          color: "base.color.text.body.secondary",
        }}
      >
        Follow to get access to exciting promotions and important updates from{" "}
        {game.data.name} and Immutable
      </Body>

      <Button
        size="large"
        variant="primary"
        sx={{
          ...(isWishlisted
            ? {
                bg: "base.color.status.success.bright",
                "&:hover:not([disabled])": {
                  boxShadow: ({ base }) =>
                    `inset 0 0 0 ${base.border.size[300]} ${base.color.status.success.bright}`,
                },
                "&::before": {
                  bg: "base.color.status.success.bright",
                },
              }
            : {}),
          justifyContent: "stretch",
          ".Button__innerSpan": { w: "100%" },
          ".Button__innerSpan__innerContent": { w: "100%" },
        }}
        disabled={
          wishlistProps.gemsState === "creatingWallet" ||
          wishlistProps.gemsState === "pending"
        }
        onClick={() => wishlistProps.onWishlistClick("GameSpecs")}
      >
        {wishlistProps.gemsState === "creatingWallet" ? (
          <Button.Icon icon={"Loading"} />
        ) : (
          <Button.Icon
            icon={isWishlisted ? "Tick" : "Add"}
            iconVariant="bold"
          />
        )}

        <Stack gap="0px">
          <Body sx={{ c: "inherit" }} weight="bold">
            {wishlistProps.gemsState === "creatingWallet"
              ? "Creating your wallet"
              : isWishlisted
                ? "Following"
                : "Follow"}
          </Body>
          {wishlistProps.gemsState === "creatingWallet" && (
            <Body
              size="xSmall"
              sx={{
                c: "inherit",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Hang tight, it may take a minute
            </Body>
          )}
          {wishlistProps.gemsState === "alreadyClaimed" && (
            <Body
              size="xSmall"
              sx={{
                c: "inherit",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Gems already claimed
            </Body>
          )}
          {wishlistProps.gemAmount !== undefined &&
            wishlistProps.gemAmount !== 0 &&
            (wishlistProps.gemsState === "claimed" ||
              wishlistProps.gemsState === "unclaimed") && (
              <Body
                size="xSmall"
                sx={{
                  c: "inherit",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {wishlistProps.gemsState === "claimed" ? "You earned" : ""}{" "}
                {`+${wishlistProps.gemAmount}`}
                <Icon
                  icon="ImxRewards"
                  sx={{
                    fill: "inherit",
                    w: "base.icon.size.100",
                    mr: "base.spacing.x1",
                  }}
                  variant="bold"
                />
                {wishlistProps.gemAmount === 1 ? "gem" : "gems"}
              </Body>
            )}
        </Stack>
      </Button>
      <Button
        variant="tertiary"
        size="medium"
        sx={{
          justifyContent: "stretch",
          ".Button__innerSpan": { w: "100%" },
          ".Button__innerSpan__innerContent": { w: "100%" },
          my: "base.spacing.x2",
        }}
        rc={
          <NextLink
            onClick={onPlayNowButtonClick}
            href={{ pathname: gameSpec.main.website }}
            target="_blank"
            referrerPolicy="no-referrer"
          />
        }
      >
        <Button.Icon icon="ESports" iconVariant="bold" />
        <Box sx={{ textAlign: "center" }}>Play</Box>
      </Button>

      {isSmallScreenMode ? (
        <Accordion chevronSide="right">
          <Accordion.TargetLeftSlot sx={{ display: "flex" }}>
            <Body size="medium" color="secondary">
              Details
            </Body>
            <Divider size={"xSmall"} sx={{ mt: "base.spacing.x8" }} />
          </Accordion.TargetLeftSlot>
          <Accordion.ExpandedContent>
            <Specs gameSpecifications={gameSpec.specifications} game={game} />
          </Accordion.ExpandedContent>
        </Accordion>
      ) : (
        <>
          <Divider size={"xSmall"} sx={{ mt: "base.spacing.x8" }} />
          <Specs gameSpecifications={gameSpec.specifications} game={game} />
        </>
      )}
    </Stack>
  );
};

export default GameSpecs;
