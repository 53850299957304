import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import {
  type GetHybridClickableRCandDomProps,
  type LogoBaseProps,
  type MakeResponsive,
  type OnClickFunction,
  isBaseClickable,
  isLogo,
} from "@/types";

import { BaseClickable } from "../Clickable";
import { Logo } from "../Logo";
import type { AppHeaderBarSize } from "./shared";

export type AppHeaderBarLeftLogoProps<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick> &
  Omit<LogoBaseProps, "domRef"> & {
    size?: MakeResponsive<AppHeaderBarSize>;
  };

export function AppHeaderBarLeftLogo<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({
  size,
  logo,
  sx = {},
  rc,
  className,
  ...otherProps
}: AppHeaderBarLeftLogoProps<RC, OnClick>) {
  const { onClick } =
    "onClick" in otherProps ? otherProps : { ...otherProps, onClick: null };
  const baseClickableProps = { onClick, rc, ...otherProps };
  const mergedSx = merge(
    { zIndex: 1, width: "auto", height: "base.icon.size.500", pos: "relative" },
    sx,
  );
  const mergedClassNames = `${className ?? ""} AppHeaderBarLeftLogo`;
  return isBaseClickable(onClick, rc, false, baseClickableProps) ? (
    <BaseClickable {...baseClickableProps} className={mergedClassNames}>
      <Logo logo={logo} sx={mergedSx} />
    </BaseClickable>
  ) : isLogo(onClick, rc, otherProps) ? (
    <Logo
      {...otherProps}
      logo={logo}
      sx={mergedSx}
      className={mergedClassNames}
    />
  ) : null;
}

AppHeaderBarLeftLogo.displayName = "AppHeaderBar.LeftLogo";
