import type { BiomeTheme, ButtonVariant } from "@/types";

import type { BannerVariant } from "./types";

export const bannerSx = {
  d: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  p: "base.spacing.x4",
  columnGap: "base.spacing.x2",
  rowGap: "base.spacing.x4",
  pos: "relative",
  boxShadow: "base.shadow.300",
  brad: "base.borderRadius.x6",
  minh: "base.spacing.x16",
  w: "100%",
};

export const rightHandButtonsContainerSx = {
  d: "flex",
  gap: "base.spacing.x2",
  ml: "auto",
};

export const getBannerColoring = (variant: BannerVariant) => {
  switch (variant) {
    case "success":
    case "fatal":
    case "guidance":
    case "attention":
      return { bg: `base.color.status.${variant}.bright` };

    case "standard":
    default:
      return { bg: "base.color.neutral.500" };
  }
};

export const shouldInvertButtonColoring = (
  bannerVariant: BannerVariant,
  { base }: BiomeTheme,
  buttonVariant?: ButtonVariant,
) => {
  if (
    /fatal|success/.test(bannerVariant) &&
    base.colorMode === "onLight" &&
    buttonVariant === "tertiary"
  ) {
    return true;
  }

  const isDarkMode = base.colorMode === "onDark";
  const isStandardVariant = bannerVariant === "standard";
  const invertButtonColoring = isDarkMode && !isStandardVariant;
  return invertButtonColoring;
};

export function shouldInvertTextColoring(
  variant: BannerVariant,
  { base }: BiomeTheme,
) {
  const isDarkMode = base.colorMode === "onDark";
  return (
    /fatal|success/.test(variant) || (isDarkMode && variant !== "standard")
  );
}
