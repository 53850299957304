import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { BodyProps, MakeResponsive, MenuItemSize } from "@/types";

import { useGetCaptionTextSizeFromMenuItemSize } from "./shared";
import { captionSx } from "./styles";

export type MenuItemCaptionProps<RC extends ReactElement | undefined> =
  BodyProps<RC> & {
    menuItemSize?: MakeResponsive<MenuItemSize>;
  };

export function MenuItemCaption<
  RC extends ReactElement | undefined = undefined,
>({
  menuItemSize = DEFAULT_MENU_ITEM_SIZE,
  size,
  sx = {},
  className,
  ...props
}: MenuItemCaptionProps<RC>) {
  const mergedSx = useMemo(() => merge(captionSx, sx), [sx]);
  const bodyTextSize = useGetCaptionTextSizeFromMenuItemSize(
    menuItemSize,
    size,
  );
  return (
    <Body
      {...props}
      sx={mergedSx}
      size={bodyTextSize}
      className={`${className ?? ""} MenuItemLabel`}
    />
  );
}

MenuItemCaption.displayName = "MenuItem.Caption";
