import type { BodySize } from "@biom3/design-tokens";
import { useMemo } from "react";

import type {
  ImageSizeVariant,
  MakeResponsive,
  MenuItemSize,
  RemoveErrorBrand,
} from "@/types";

export function getImageSizeForMenuItemSize<Size>(
  menuItemSize: RemoveErrorBrand<Size>,
): ImageSizeVariant | null {
  // xSmall MenuItem size === 'medium' Image size
  // small MenuItem size === 'large' Image size
  // medium MenuItem size === 'large' Image size
  switch (menuItemSize) {
    case "xSmall":
      return "medium";
    case "small":
    case "medium":
      return "large";
    case null:
    default:
      return null;
  }
}

export function useGetBiomeImageSizeFromMenuItemSize(
  size: MakeResponsive<MenuItemSize>,
) {
  const chosenBiomeImageSize = useMemo(() => {
    return isMenuItemSizeAnArray(size)
      ? size.map((s) => getImageSizeForMenuItemSize(s))
      : getImageSizeForMenuItemSize(size);
  }, [size]);
  return chosenBiomeImageSize as MakeResponsive<ImageSizeVariant>;
}

export function isMenuItemSizeAnArray(
  size: unknown,
): size is Array<MenuItemSize | null> {
  return Array.isArray(size);
}

export function isImageSizeAnArray(
  size: unknown,
): size is Array<ImageSizeVariant | null> {
  return Array.isArray(size);
}

type BodySizePickerFunction = (size: MenuItemSize | null) => BodySize | null;

export const pickLabelTextSizeFromMenuItemSize: BodySizePickerFunction = (
  size,
) => {
  switch (size) {
    case null:
      return null;

    case "xSmall":
      return "small";

    case "medium":
    case "small":
    default:
      return "medium";
  }
};

export const pickCaptionTextSizeFromMenuItemSize: BodySizePickerFunction = (
  size,
) => {
  switch (size) {
    case null:
      return null;

    case "xSmall":
      return "xSmall";

    case "medium":
    case "small":
    default:
      return "small";
  }
};

function useGetTextSizeFromMenuItemSize(
  menuItemSize: MakeResponsive<MenuItemSize>,
  picker: BodySizePickerFunction,
  size?: MakeResponsive<BodySize>,
) {
  const bodyTextSize = useMemo(() => {
    if (size) return size;
    return isMenuItemSizeAnArray(menuItemSize)
      ? menuItemSize.map((s) => picker(s))
      : picker(menuItemSize as MenuItemSize);
  }, [size, menuItemSize, picker]);
  return bodyTextSize as MakeResponsive<BodySize>;
}

export function useGetLabelTextSizeFromMenuItemSize(
  menuItemSize: MakeResponsive<MenuItemSize>,
  size?: MakeResponsive<BodySize>,
) {
  return useGetTextSizeFromMenuItemSize(
    menuItemSize,
    pickLabelTextSizeFromMenuItemSize,
    size,
  );
}

export function useGetCaptionTextSizeFromMenuItemSize(
  menuItemSize: MakeResponsive<MenuItemSize>,
  size?: MakeResponsive<BodySize>,
) {
  return useGetTextSizeFromMenuItemSize(
    menuItemSize,
    pickCaptionTextSizeFromMenuItemSize,
    size,
  );
}
