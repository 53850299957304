import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import type { FramedImageProps } from "@/types";

import { FramedImage } from "../FramedThings";
import { baseFramedSx } from "./styles";

export function CardFramedImage<
  Use extends ReactElement | undefined = undefined,
>({
  className,
  use,
  size = "large",
  sx = {},
  ...props
}: FramedImageProps<Use>) {
  const mergedSx = useMemo(() => merge(baseFramedSx, sx), [sx]);
  return (
    <FramedImage
      {...props}
      use={use}
      sx={mergedSx}
      size={size}
      className={`${className ?? ""} CardFramedImage`}
    />
  );
}

CardFramedImage.displayName = "Card.FramedImage";
