import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Caption } from "@/components/Text";
import type { CaptionProps } from "@/types";
import { lineClamp } from "@/utils/sxChunks";

export type CardHeroCaptionProps<RC extends ReactElement | undefined> =
  CaptionProps<RC>;

export function CardHeroCaption<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  weight = "bold",
  sx = {},
  rc = <span />,
  className,
  testId = "CardHeroCaption",
  ...props
}: CardHeroCaptionProps<RC>) {
  return (
    <Caption
      {...props}
      sx={merge(
        {
          textAlign: "left",
          c: "base.color.fixed.white.1000",
          ...lineClamp(1),
        },
        sx,
      )}
      className={`${className ?? ""} CardHeroCaption`}
      size={size}
      weight={weight}
      rc={rc}
      testId={testId}
    />
  );
}

CardHeroCaption.displayName = "CardHero.Caption";
