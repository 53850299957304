import type { DeeplyNestedSx } from "@/types";
import { lineClamp } from "@/utils/sxChunks";

export const baseRootSx = {
  maxw: "100%",
  w: "100%",

  "&.CarouselThumbnail--loop .CarouselThumbnailSlide": {
    "&:last-child": {
      mr: "var(--slideSpacing)",
    },
  },
};

export const viewportSx = {
  overflow: "hidden",
};

export const slideSx = {
  flex: "0 0 100%",
  overflow: "hidden",
  boxShadow: "unset",
  mr: "1px",

  "&:last-child": {
    mr: "0px",
  },

  "&.CardHero--isClickable:hover": {
    translate: "0px",
    boxShadow: "unset",
  },
};

export const controlBarBaseSx = {
  mt: "base.spacing.x4",
  flexDirection: "row",
  gap: "base.spacing.x6",
  alignItems: "center",
  justifyContent: "center",
};

const controlPaginationBaseSx = {
  pos: "absolute",
};

export const controlPaginationHoriztonalSx = {
  ...controlPaginationBaseSx,
  top: "50%",
  translate: "0 -50%",
};

export const controlPaginationVerticalSx = {
  ...controlPaginationBaseSx,
  left: "50%",
  translate: "-50% 0",
};

export const horizontalThumbImageSx = {
  pos: "absolute",
  top: "0px",
  left: "0px",
  w: "100%",
  h: "100%",
};

export const verticalThumbImageSx = {
  h: "100%",
  // @NOTE: do not change this width value, without also changing
  // the calculated relativeImageSizeInLayout value inside
  // ./CarouselThumbnailThumbBgImage.tsx
  w: "50px",
  brad: "base.borderRadius.x4",
};

export const thumbTextSx: DeeplyNestedSx = {
  ...lineClamp(3),
  pos: "relative",
};

export const baseThumbSx: DeeplyNestedSx = {
  brad: "base.borderRadius.x6",
  height: "var(--thumbHeight)",
  cursor: "pointer",
  textAlign: "center",
  flexShrink: "0",
  transitionProperty: "border",
  borderStyle: "solid",
  borderWidth: "0px",
  borderColor: "transparent",
  p: "unset",
  bg: "unset",
  pos: "relative",
  overflow: "hidden",

  "& .innerContainer": {
    brad: "base.borderRadius.x4",
    pos: "relative",
    overflow: "hidden",
    h: "100%",
    w: "100%",
    transitionProperty: "width, height",
    bg: "base.color.translucent.emphasis.100",
  },

  "&.CarouselThumbnailThumb--active": {
    borderColor: "base.color.translucent.standard.1000",
    borderWidth: "base.border.size.100",

    "& .innerContainer": {
      height: ({ base }) => `calc(100% - ${base.spacing.x2})`,
      width: ({ base }) => `calc(100% - ${base.spacing.x2})`,
    },
  },
};
