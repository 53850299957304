import type { Property } from "csstype";
import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Video } from "@/components/Video";
import type { MakeValidSxValue, VideoProps } from "@/types";

export type CardAssetVideoProps = VideoProps & {
  playOnCardHover?: boolean;
  aspectRatio?: MakeValidSxValue<Property.AspectRatio>;
};

export function CardAssetVideo({
  className,
  autoPlay = true,
  controls = false,
  playOnCardHover = false,
  muted = autoPlay,
  loop = true,
  aspectRatio = 1,
  sx = {},
  ...props
}: CardAssetVideoProps) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          w: "100%",
          objectFit: "cover",
          objectPosition: "center",
          aspectRatio,
        },
        sx,
      ),
    [aspectRatio, sx],
  );
  return (
    <Video
      {...props}
      className={`${className ?? ""} CardAssetVideo`}
      autoPlay={playOnCardHover ? false : autoPlay}
      controls={controls}
      muted={muted}
      loop={loop}
      sx={mergedSx}
    />
  );
}

CardAssetVideo.displayName = "Card.AssetVideo";
