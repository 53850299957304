import { useTheme } from "@emotion/react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TOOLBAR_SIZE, TOOLBAR_SIZES } from "@/constants";
import type { BoxProps, MakeResponsive, ToolbarSize } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";
import { lineClamp } from "@/utils/sxChunks";

import { Box } from "../Box";
import { getCaptionStyles, getResponsiveCaptionStyles } from "./styles";

export type ToolbarCaptionProps = Omit<BoxProps, "rc"> & {
  size?: MakeResponsive<ToolbarSize>;
};

export function ToolbarCaption({
  size = DEFAULT_TOOLBAR_SIZE,
  sx = {},
  ...props
}: ToolbarCaptionProps) {
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_TOOLBAR_SIZE,
    TOOLBAR_SIZES,
  );
  const captionStyles = merge(
    { ...lineClamp(1), c: "base.color.text.body.secondary" },
    getCaptionStyles({ themeProps, size: startingSize }),
    getResponsiveCaptionStyles({
      themeProps,
      size,
    }),
    sx,
  );
  return <Box {...props} sx={captionStyles} rc={<p />} />;
}

ToolbarCaption.displayName = "Toolbar.Caption";
