import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";

import config from "./slicemachine.config.json";
/**
 * The project's Prismic repository name.
 */
export const repositoryName =
  process.env.NEXT_PUBLIC_PRISMIC_ENVIRONMENT || config.repositoryName;
const endpoint = prismic.getRepositoryEndpoint(repositoryName);

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
const routes: prismic.ClientConfig["routes"] = [
  {
    type: "games_page",
    path: "/games",
  },
  {
    type: "game_details_page",
    path: "/games/:uid",
  },
  {
    type: "premium_game_details_page",
    path: "/games/:uid",
  },
  {
    type: "multi_game_details_page",
    path: "/games/:uid",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(endpoint, {
    routes,
    fetchOptions:
      process.env.NODE_ENV === "production"
        ? { next: { tags: ["prismic"] }, cache: "force-cache" }
        : { next: { revalidate: 5 } },
    ...config
  });

  const { req, previewData } = config;

  prismicNext.enableAutoPreviews({
    req,
    client,
    previewData,
  });

  return client;
};
