import type { ReactNode } from "react";

import { SmartClone } from "@/components/SmartClone";
import type { StandardComponentWithProps } from "@/types";

export type ModalTargetProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    children: ReactNode;
    id?: string;
  }
>;

export function ModalTarget({ children, ...props }: ModalTargetProps) {
  return <SmartClone {...props}>{children}</SmartClone>;
}

ModalTarget.displayName = "Modal.Target";
