import { throttleAnimationFrame } from "@/utils";
import type { AutoTextSizeOptions } from "./types";

type ApplyTextSizeCalculationProps = {
  innerEl: HTMLElement;
  containerEl: HTMLElement;
  fontSize: number;
  minFontSize: number;
  maxFontSize: number;
  fontSizePrecision: number;
  domFontSizeUpdater: (px: number) => number;
};

const getContentWidth = (element: HTMLElement): number => {
  const computedStyle = getComputedStyle(element);
  return (
    element.clientWidth -
    Number.parseFloat(computedStyle.paddingLeft) -
    Number.parseFloat(computedStyle.paddingRight)
  );
};

const getContentHeight = (element: HTMLElement): number => {
  const computedStyle = getComputedStyle(element);
  return (
    element.clientHeight -
    Number.parseFloat(computedStyle.paddingTop) -
    Number.parseFloat(computedStyle.paddingBottom)
  );
};

const applyCalculatedSizeToText = ({
  innerEl,
  containerEl,
  fontSize,
  minFontSize,
  maxFontSize,
  fontSizePrecision,
  domFontSizeUpdater,
}: ApplyTextSizeCalculationProps): void => {
  const maxIterationCount = 10;
  let iterationCount = 0;
  let prevOverflowFactor = 1;
  const containerWidth = getContentWidth(containerEl);

  while (iterationCount < maxIterationCount) {
    const innerWidth = innerEl.scrollWidth;
    const canGrow = fontSize < maxFontSize && innerWidth < containerWidth;
    const canShrink = fontSize > minFontSize && innerWidth > containerWidth;
    const overflowFactor = innerWidth / containerWidth;

    if (prevOverflowFactor === overflowFactor) {
      break;
    }

    if (!(canGrow || canShrink)) {
      break;
    }

    const updatePx = fontSize / overflowFactor - fontSize;
    const prevFontSizePx = fontSize;
    fontSize = domFontSizeUpdater(fontSize + updatePx);

    if (Math.abs(fontSize - prevFontSizePx) <= fontSizePrecision) {
      break;
    }

    prevOverflowFactor = overflowFactor;
    iterationCount += 1;
  }
};

/**
 * Adjusts the font-size of `innerEl` so that it fills `containerEl`.
 */
export function updateTextSize({
  innerEl,
  containerEl,
  minFontSize = 8,
  maxFontSize = 160,
  fontSizePrecision = 0.1,
}: AutoTextSizeOptions & {
  innerEl: HTMLElement;
  containerEl: HTMLElement;
}): void {
  if (!Number.isFinite(minFontSize)) {
    throw new Error(`Invalid minFontSize (${minFontSize})`);
  }

  if (!Number.isFinite(minFontSize)) {
    throw new Error(`Invalid maxFontSize (${maxFontSize})`);
  }

  if (!Number.isFinite(fontSizePrecision) || fontSizePrecision === 0) {
    throw new Error(`Invalid fontSizePrecision (${fontSizePrecision})`);
  }

  // @TODO: is this going to cause issues with SSR? :thinking_face:
  const fontSizeStr = window
    .getComputedStyle(innerEl)
    .getPropertyValue("font-size");
  let fontSize = Number.parseFloat(fontSizeStr);
  let iterationCount = 0;

  const domFontSizeUpdater = (px: number): number => {
    const newPx = Math.min(Math.max(px, minFontSize), maxFontSize);
    fontSize = newPx;
    innerEl.style.fontSize = `${fontSize}px`;
    iterationCount += 1;
    return fontSize;
  };

  if (fontSize > maxFontSize || fontSize < minFontSize) {
    domFontSizeUpdater(fontSize);
  }

  applyCalculatedSizeToText({
    innerEl,
    containerEl,
    fontSize,
    minFontSize,
    maxFontSize,
    fontSizePrecision,
    domFontSizeUpdater,
  });
}

type DisconnectableFunction = {
  (): void;
  disconnect: () => void;
};

export function autoTextSize({
  innerEl,
  containerEl,
  minFontSize,
  maxFontSize,
  fontSizePrecision,
}: AutoTextSizeOptions & {
  innerEl: HTMLElement;
  containerEl: HTMLElement;
}): DisconnectableFunction {
  let containerDimensions: [number, number] | undefined = undefined;

  const throttledUpdateTextSize = throttleAnimationFrame(() => {
    updateTextSize({
      innerEl,
      containerEl,
      maxFontSize,
      minFontSize,
      fontSizePrecision,
    });
    containerDimensions = [
      getContentWidth(containerEl),
      getContentHeight(containerEl),
    ];
  }) as DisconnectableFunction;

  const resizeObserver = new ResizeObserver(() => {
    const prevContainerDimensions = containerDimensions
      ? [...containerDimensions]
      : undefined;
    containerDimensions = [
      getContentWidth(containerEl),
      getContentHeight(containerEl),
    ];

    if (
      prevContainerDimensions?.[0] !== containerDimensions[0] ||
      prevContainerDimensions?.[1] !== containerDimensions[1]
    ) {
      throttledUpdateTextSize();
    }
  });

  resizeObserver.observe(containerEl);
  throttledUpdateTextSize.disconnect = () => resizeObserver.disconnect();

  return throttledUpdateTextSize;
}
