import { type ReactElement, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { useForwardLocalDomRef, useTheme } from "@/hooks";
import type {
  InputTextAlign,
  InputValidationStatus,
  StandardInputComponentWithProps,
} from "@/types";
import { cloneElementWithCssProp } from "@/utils";
import { inputResetStyles } from "../../utils/styleHelpers";
import { Box } from "../Box";
import {
  checkSx,
  containerSx,
  inputCssStyles,
  radioContainerSx,
} from "./styles";

export type RadioProps = StandardInputComponentWithProps<
  "input",
  {
    validationStatus?: InputValidationStatus;
    textAlign?: InputTextAlign;
    inputRef?: Ref<HTMLInputElement>;
  }
>;

export function Radio<RC extends ReactElement | undefined = undefined>({
  sx = {},
  testId = "Radio",
  name,
  id = name,
  children,
  domRef = { current: null },
  className,
  onChange,
  checked,
  value,
  disabled,
  validationStatus,
  textAlign,
  inputRef,
  rc,
  ...inputHtmlAttrs
}: RC extends undefined ? RadioProps : RadioProps & { rc: RC }) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const resetStyles = inputResetStyles(disabled);
  const theme = useTheme();

  const memoizedInputProps = useMemo(
    () => ({
      type: "radio",
      "data-testid": `${testId}__input`,
      id,
      name,
      ref: inputRef,
      value,
      checked,
      onChange,
      disabled,
      css: merge(resetStyles, inputCssStyles(theme)),
      className: "actualInput",
    }),
    [
      testId,
      id,
      name,
      inputRef,
      value,
      checked,
      onChange,
      disabled,
      resetStyles,
      theme,
    ],
  );

  const input = cloneElementWithCssProp(<input />, {
    ...inputHtmlAttrs,
    ...memoizedInputProps,
  });

  return (
    <Box
      rc={rc}
      domRef={localDomRef}
      testId={testId}
      className={`${className ?? ""} Radio`}
      sx={merge(containerSx, sx)}
    >
      {input}
      <Box
        className="radioContainer"
        sx={radioContainerSx}
        testId={`${testId}__radioContainer`}
      >
        <Box
          rc={<span />}
          className="checkCircle"
          sx={checkSx}
          testId={`${testId}__checkCircle`}
        />
      </Box>
    </Box>
  );
}

Radio.displayName = "Radio";
