import { type ReactElement, useMemo } from "react";

import { Avatar } from "@/components/Avatar";
import { DEFAULT_CHIP_SIZE } from "@/constants";
import type { ImageSizeVariant, MakeResponsive } from "@/types";

import type { ChipAvatarProps } from "./shared";

export function ChipAvatar<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  size = DEFAULT_CHIP_SIZE,
  avatarSize,
  className,
  rc = <span />,
  use,
  ...props
}: ChipAvatarProps<RC, Use>) {
  const { imageUrl } =
    "imageUrl" in props ? props : { ...props, imageUrl: undefined };
  const pickedAvatarSize = useMemo(() => {
    if (Array.isArray(size)) {
      return size.map((s) =>
        s === "large" ? "medium" : s === "medium" ? "small" : null,
      ) as MakeResponsive<ImageSizeVariant>;
    }
    return (size === "large" ? "medium" : "small") as ImageSizeVariant;
  }, [size]);
  return (
    <Avatar
      {...props}
      use={use}
      imageUrl={imageUrl}
      rc={rc}
      className={`${className ?? ""} ChipAvatar`}
      size={avatarSize ?? pickedAvatarSize}
    />
  );
}

ChipAvatar.displayName = "Chip.Avatar";
