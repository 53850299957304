import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { type ButtConProps, isDualVariantIcon } from "@/types";

import {
  BUTTON_SIZES,
  DEFAULT_BUTTON_SIZE,
  DEFAULT_BUTTON_VARIANT,
} from "@/constants";
import { useGetCurrentSizeClass } from "@/hooks/useGetCurrentSizeClass";
import { useGetSubcomponentChild } from "@/hooks/useGetSubcompnentChild";
import { useTheme } from "@/hooks/useTheme";
import { convertVariantToValidCssClass } from "@/utils/slugHelpers";
import { getStartingSize } from "@/utils/styleHelpers";

import { Icon } from "@/components/Icon";
import { SmartClone } from "@/components/SmartClone";
import { ButtConSvgIcon } from "./ButtConSvgIcon";
import { Button } from "./Button";
import {
  getButtConIconStyles,
  getButtConStyles,
  getResponsiveButtConIconStyles,
  getResponsiveButtConStyles,
} from "./styles";

export function ButtCon<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_BUTTON_SIZE,
  variant = DEFAULT_BUTTON_VARIANT,
  sx = {},
  iconSx = {},
  testId,
  className,
  ...props
}: ButtConProps<RC>) {
  const { icon, ...propsMinusIcon } =
    "icon" in props ? props : { icon: undefined, ...props };
  const { children, ...propsMinusIconAndChildren } =
    "children" in propsMinusIcon
      ? propsMinusIcon
      : { children: null, ...propsMinusIcon };
  const { iconVariant, ...propsMinusIconAndChildrenAndIconVariant } =
    "iconVariant" in propsMinusIconAndChildren
      ? propsMinusIconAndChildren
      : { iconVariant: undefined, ...propsMinusIconAndChildren };

  const themeProps = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_BUTTON_SIZE,
    BUTTON_SIZES,
  );
  const allButtonSx = useMemo(
    () =>
      merge(
        getButtConStyles({ size: startingSize, themeProps }),
        getResponsiveButtConStyles({
          size,
          themeProps,
        }),
        themeProps.components?.ButtCon?.sxOverride ?? {},
        sx,
      ),
    [size, startingSize, themeProps, sx],
  );
  const allIconSx = useMemo(
    () =>
      merge(
        getButtConIconStyles({ size: startingSize, variant, themeProps }),
        getResponsiveButtConIconStyles({
          variant,
          size,
          themeProps,
        }),
        iconSx,
      ),
    [size, startingSize, variant, themeProps, iconSx],
  );
  const svgIconChild = useGetSubcomponentChild(children, ButtConSvgIcon);
  const iconContent = useMemo(() => {
    if (!icon) return null;

    const iconProps = isDualVariantIcon(icon)
      ? { icon, variant: iconVariant }
      : { icon };
    return (
      <Icon
        {...iconProps}
        className="buttCon__icon"
        sx={allIconSx}
        testId={`${testId}__icon`}
      />
    );
  }, [icon, iconVariant, allIconSx, testId]);
  return (
    <Button
      {...propsMinusIconAndChildrenAndIconVariant}
      size={size}
      variant={variant}
      sx={allButtonSx}
      testId={testId}
      className={`${
        className ?? ""
      } ButtCon ButtCon--${convertVariantToValidCssClass(
        variant,
      )} ButtCon--${currentSizeClass}`}
    >
      {svgIconChild ? (
        <SmartClone testId={`${testId}__svgIcon`}>{svgIconChild}</SmartClone>
      ) : (
        iconContent || children
      )}
    </Button>
  );
}

ButtCon.displayName = "ButtCon";
ButtCon.SvgIcon = ButtConSvgIcon;
