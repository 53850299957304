import {
  type ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { SvgIcon } from "@/components/SvgIcon";
import { TooltipComponentContext } from "@/components/Tooltip/shared";
import { useGetMotionProfile, useTheme } from "@/hooks";
import { usePrevious } from "@/hooks/usePrevious";
import { useUpdateEffect } from "@/hooks/useUpdateEffect";
import { type IconProps, type IconVariant, isDualVariantIcon } from "@/types";
import { getMotionProfileSx } from "@/utils";
import { fetchIconContent, spinIconKeyframes } from "./shared";
import { baseIconSx } from "./styles";

export function Icon<RC extends ReactElement | undefined = undefined>({
  icon,
  sx = {},
  testId,
  className,
  motionProfile,
  ...props
}: IconProps<RC>) {
  const { variant, ...otherProps } =
    "variant" in props ? props : { ...props, variant: undefined };
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const theme = useTheme();
  const { triggerDomRecrawl } = useContext(TooltipComponentContext);
  const [svgContent, setSvgContent] = useState<string[] | null>(null);
  const previousVariant = usePrevious(variant);
  const isFetching = useRef(false);
  const previousIcon = usePrevious(icon);

  const mergedSx = useMemo(() => {
    return icon === "Loading"
      ? merge(
          baseIconSx,
          {
            animation: `${spinIconKeyframes} ${
              motionProfileToUse === "fast" ? "0.6s" : "1.2s"
            } linear infinite`,
            transformOrigin: "50% 50%",
          },
          sx,
        )
      : merge(baseIconSx, getMotionProfileSx(motionProfileToUse, theme), sx);
  }, [icon, motionProfileToUse, sx, theme]);

  useEffect(() => {
    async function fetchContent() {
      isFetching.current = true;
      const content = await fetchIconContent(icon, variant as IconVariant);
      if (content) setSvgContent(content);
      isFetching.current = false;
    }
    // @NOTE: ONLY fetch the icon content if it hasn't already
    // been fetched and when the variant changes
    if (!isFetching.current && (!svgContent || previousVariant !== variant)) {
      fetchContent();
    }
  }, [icon, variant, svgContent, previousVariant]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    triggerDomRecrawl?.();
  }, [triggerDomRecrawl, svgContent]);

  useUpdateEffect(() => {
    if (previousIcon !== icon) setSvgContent(null);
  }, [icon, previousIcon]);

  return (
    <SvgIcon
      {...otherProps}
      testId={testId}
      sx={mergedSx}
      data-icon={icon}
      data-variant={isDualVariantIcon(icon) && variant ? variant : "regular"}
      className={`${className ?? ""} Icon Icon--${icon}`}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}

Icon.displayName = "Icon";
