import {
  type MountedOverlayAndProviderProps,
  MountedOverlaysAndProvider,
} from "../BiomeOverlaysProvider/MountedOverlaysAndProvider";
import { BiomePortalIdProvider } from "../BiomePortalIdProvider";
import { BiomeShadowRootContext } from "../BiomeShadowRootProvider/shadowRootContext";
import { MountedTooltipOverlayAndProvider } from "../BiomeTooltipProvider";
import {
  DEFAULT_RESIZE_DEBOUNCE_TIME,
  SetWindowSize,
  type SetWindowSizeProps,
} from "../BiomeWindowSize";
import { BiomeWindowSizeProvider } from "../BiomeWindowSize/windowSizeContext";
import { ToggleableThemeProvider } from "../theming";
import { BiomeThemeProvider } from "../theming/BiomeThemeProvider";
import type { BiomeThemeProviderProps } from "../theming/BiomeThemeProvider/BiomeThemeProvider";
import type { InputTheme } from "../theming/shared";

import packageJson from "../../../package.json";

console.log(`
  @biom3/react
  
  version: ${packageJson.version}

`);

type GetThemeToggleProps<ThemeToggle extends boolean | undefined> =
  ThemeToggle extends true
    ? {
        withThemeToggle: ThemeToggle;
        autoPickTheme?: boolean;
        lightTheme?: InputTheme;
        darkTheme?: InputTheme;
      }
    : ThemeToggle extends false
      ? { withThemeToggle: ThemeToggle; theme?: InputTheme }
      : { withThemeToggle?: never; theme?: InputTheme };

export type BiomeCombinedProvidersProps<
  Id extends string,
  ThemeToggle extends boolean | undefined,
> = Omit<BiomeThemeProviderProps, "theme"> &
  SetWindowSizeProps &
  MountedOverlayAndProviderProps<Id> &
  GetThemeToggleProps<ThemeToggle> & {
    shadowRoot?: ShadowRoot;
  };

export function BiomeCombinedProviders<
  Id extends string,
  ThemeToggle extends boolean | undefined = undefined,
>(props: BiomeCombinedProvidersProps<Id, ThemeToggle>) {
  const {
    children,
    skipFontLoad,
    withThemeToggle,
    resizeDebounceDuration = DEFAULT_RESIZE_DEBOUNCE_TIME,
    modalContainerId,
    drawerContainerId,
    popoverContainerId,
    toastContainerId,
    shadowRoot,
    globalSx,
  } = props;
  const { autoPickTheme, lightTheme, darkTheme, theme } = withThemeToggle
    ? { ...props, theme: undefined }
    : {
        ...props,
        autoPickTheme: undefined,
        lightTheme: undefined,
        darkTheme: undefined,
      };

  const wrappedChildren = (
    <BiomeWindowSizeProvider>
      <SetWindowSize resizeDebounceDuration={resizeDebounceDuration} />
      <MountedTooltipOverlayAndProvider>
        <MountedOverlaysAndProvider
          modalContainerId={modalContainerId}
          drawerContainerId={drawerContainerId}
          popoverContainerId={popoverContainerId}
          toastContainerId={toastContainerId}
        >
          {children}
        </MountedOverlaysAndProvider>
      </MountedTooltipOverlayAndProvider>
    </BiomeWindowSizeProvider>
  );

  return (
    <BiomePortalIdProvider>
      <BiomeShadowRootContext.Provider value={shadowRoot}>
        {withThemeToggle ? (
          <ToggleableThemeProvider
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            globalSx={globalSx}
            autoPickTheme={autoPickTheme}
            skipFontLoad={skipFontLoad}
          >
            {wrappedChildren}
          </ToggleableThemeProvider>
        ) : (
          <BiomeThemeProvider
            theme={theme}
            globalSx={globalSx}
            skipFontLoad={skipFontLoad}
          >
            {wrappedChildren}
          </BiomeThemeProvider>
        )}
      </BiomeShadowRootContext.Provider>
    </BiomePortalIdProvider>
  );
}
