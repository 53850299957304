import { merge } from "ts-deepmerge";

import type { BiomeTheme, ButtonSize } from "@/types";

import {
  largeButtonStaticStyles,
  mediumButtonStaticStyles,
  smallButtonStaticStyles,
} from "./shared";

export const secondaryButtonGenericStyles = {
  color: "base.color.text.body.primary",
  flexShrink: 0,

  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    translate: "-50% -50%",
    boxShadow: "inset 0 0 0 0px transparent",
    background: "base.color.translucent.standard.200",
    backdropFilter: "base.frost.500",
    transitionProperty: "box-shadow, width, height",
  },
};

export const secondaryFatalButtonGenericStyles = {
  ...secondaryButtonGenericStyles,
  color: "base.color.text.status.fatal.primary",
};

export function getSmallSecondaryFatalButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc((${base.border.size[100]} + ${base.border.size[200]}) * 2)`;
  const pressCutaway = `calc((${base.border.size[100]} + ${base.border.size[200]}) * 2)`;

  return {
    ...smallButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status.fatal.bright}`,

    "&::before": {
      borderRadius: base.borderRadius.x15,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status.fatal.bright}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${base.border.size[100]} ${base.color.status.fatal.bright}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status.fatal.bright}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status.fatal.bright}`,
      },
    },
  };
}
export function getMediumSecondaryFatalButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc((${base.border.size[200]} + ${base.border.size[300]}) * 2)`;
  const pressCutaway = `calc((${base.border.size[200]} + ${base.border.size[300]}) * 2)`;

  return {
    ...mediumButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status.fatal.bright}`,

    "&::before": {
      borderRadius: base.borderRadius.x20,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status.fatal.bright}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${base.border.size[100]} ${base.color.status.fatal.bright}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status.fatal.bright}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status.fatal.bright}`,
      },
    },
  };
}

export function getLargeSecondaryFatalButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc((${base.border.size[200]} + ${base.border.size[400]}) * 2)`;
  const pressCutaway = `calc((${base.border.size[200]} + ${base.border.size[400]}) * 2)`;

  return {
    ...largeButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status.fatal.bright}`,

    "&::before": {
      borderRadius: base.borderRadius.x25,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.status.fatal.bright}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status.fatal.bright}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.status.fatal.bright}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.status.fatal.bright}`,
      },
    },
  };
}

export function getSmallSecondaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const hoverCutaway = `calc((${themeProps.base.border.size[100]} + ${themeProps.base.border.size[200]}) * 2)`;
  const pressCutaway = `calc((${themeProps.base.border.size[100]} + ${themeProps.base.border.size[200]}) * 2)`;

  return {
    ...smallButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${themeProps.base.border.size[200]} ${themeProps.base.color.brand[1]}`,

    "&::before": {
      borderRadius: themeProps.base.borderRadius.x15,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[200]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[100]} ${themeProps.base.color.brand[1]}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[200]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[200]} ${themeProps.base.color.brand[1]}`,
      },
    },
  };
}

export function getMediumSecondaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const hoverCutaway = `calc((${themeProps.base.border.size[200]} + ${themeProps.base.border.size[300]}) * 2)`;
  const pressCutaway = `calc((${themeProps.base.border.size[200]} + ${themeProps.base.border.size[300]}) * 2)`;

  return {
    ...mediumButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${themeProps.base.border.size[300]} ${themeProps.base.color.brand[1]}`,

    "&::before": {
      borderRadius: themeProps.base.borderRadius.x20,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[300]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[100]} ${themeProps.base.color.brand[1]}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[300]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[300]} ${themeProps.base.color.brand[1]}`,
      },
    },
  };
}

export function getLargeSecondaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const hoverCutaway = `calc((${themeProps.base.border.size[200]} + ${themeProps.base.border.size[400]}) * 2)`;
  const pressCutaway = `calc((${themeProps.base.border.size[200]} + ${themeProps.base.border.size[400]}) * 2)`;

  return {
    ...largeButtonStaticStyles(themeProps),
    boxShadow: `inset 0 0 0 ${themeProps.base.border.size[300]} ${themeProps.base.color.brand[1]}`,

    "&::before": {
      borderRadius: themeProps.base.borderRadius.x25,
      width: `calc(100% - ${hoverCutaway})`,
      height: `calc(100% - ${hoverCutaway})`,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[400]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[200]} ${themeProps.base.color.brand[1]}`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${themeProps.base.border.size[400]} ${themeProps.base.color.brand[1]}`,

      "&::before": {
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${themeProps.base.border.size[400]} ${themeProps.base.color.brand[1]}`,
      },
    },
  };
}

export function getSecondaryButtonStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  return merge(
    size === "small"
      ? getSmallSecondaryButtonUniqueStyles(themeProps)
      : size === "medium"
        ? getMediumSecondaryButtonUniqueStyles(themeProps)
        : getLargeSecondaryButtonUniqueStyles(themeProps),
  );
}

export function getSecondaryFatalButtonStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  return merge(
    size === "small"
      ? getSmallSecondaryFatalButtonUniqueStyles(themeProps)
      : size === "medium"
        ? getMediumSecondaryFatalButtonUniqueStyles(themeProps)
        : getLargeSecondaryFatalButtonUniqueStyles(themeProps),
  );
}
