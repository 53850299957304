import { useEffect, useRef } from "react";

export function useSpyScrollPercentage<Element extends HTMLElement>(
  onThreshold: (threshold: string) => void,
  thresholds: string[] = ["30%", "50%", "90%"],
) {
  const scrollElement = useRef<Element>(null);
  const triggeredThresholds = useRef<Set<string>>(new Set());

  const handleScroll = () => {
    if (!scrollElement.current) return;

    const scrollTop = scrollElement.current.scrollTop;
    const clientHeight = scrollElement.current.clientHeight;
    const scrollHeight = scrollElement.current.scrollHeight;

    const newScrollPercentage =
      ((scrollTop + clientHeight) / scrollHeight) * 100;

    for (let i = thresholds.length - 1; i >= 0; i--) {
      const threshold = thresholds[i];
      const thresholdValue = Number.parseFloat(threshold);

      if (
        newScrollPercentage >= thresholdValue &&
        !triggeredThresholds.current.has(threshold)
      ) {
        onThreshold(threshold);
        triggeredThresholds.current.add(threshold);
        break;
      }
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!scrollElement.current) return;

    scrollElement.current?.addEventListener("scroll", handleScroll);

    if (
      scrollElement.current.scrollTop === 0 &&
      scrollElement.current.clientHeight >= scrollElement.current.scrollHeight
    ) {
      onThreshold(thresholds[thresholds.length - 1]);
      for (const threshold of thresholds) {
        triggeredThresholds.current.add(threshold);
      }
    }

    return () => {
      scrollElement.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollElement;
}

export default useSpyScrollPercentage;
