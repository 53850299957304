import type { BaseTokens } from "@biom3/design-tokens";
import type { Properties } from "csstype";
import { merge } from "ts-deepmerge";

import type {
  BiomeTheme,
  DescendantStyleProperties,
  ErrorBrand,
  GetFontWeights,
  GetTypeSizes,
  MakeResponsive,
  RemoveErrorBrand,
} from "../types";

export function isError(key: unknown): key is ErrorBrand<string> {
  return typeof key === "object" && key !== null && "_error" in key;
}

const DEFAULT_TEXT_SIZES = {
  heading: "medium",
  body: "medium",
  caption: "medium",
} as const;
const DEFAULT_TEXT_WEIGHTS = {
  heading: "regular",
  body: "regular",
  caption: "regular",
} as const;

export function renderResponsiveTextStyles<T extends keyof BaseTokens["text"]>({
  size,
  weight,
  tokenTextComponent,
  themeProps,
}: {
  tokenTextComponent: T;
  size: MakeResponsive<GetTypeSizes<T>>;
  weight: MakeResponsive<GetFontWeights<T>>;
  themeProps: BiomeTheme;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  const weightAsArray = Array.isArray(weight) ? [...weight] : [weight];

  // We need to convince TS that the default values are a subset of the types in sizeAsArray and weightAsArray
  const defaultSize =
    sizeAsArray.shift() ??
    (DEFAULT_TEXT_SIZES[tokenTextComponent] as GetTypeSizes<T>);
  const defaultWeight =
    weightAsArray.shift() ??
    (DEFAULT_TEXT_WEIGHTS[tokenTextComponent] as GetFontWeights<T>);

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: {
            fontSize:
              // @NOTE: TS isn't smart enough to infer past the second level, so we need to cast here. Matt Pocock approved, we promise!
              (
                themeProps.base.text[tokenTextComponent][
                  responsiveSize
                ] as BaseTokens["text"]["heading"]["xxLarge"]
              ).regular.fontSize,
            lineHeight: (
              themeProps.base.text[tokenTextComponent][
                responsiveSize
              ] as BaseTokens["text"]["heading"]["xxLarge"]
            ).regular.lineHeight,
          },
        };
      }
      return {};
    }),
  );

  const weightStyles = merge(
    ...weightAsArray.map((responsiveWeight, index) => {
      const responsiveSize = sizeAsArray[index] ?? defaultSize;
      if (
        responsiveWeight != null &&
        !isError(responsiveSize) &&
        !isError(responsiveWeight)
      ) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: {
            fontWeight: (
              themeProps.base.text[tokenTextComponent][responsiveSize][
                responsiveWeight
              ] as BaseTokens["text"]["heading"]["xxLarge"]["regular"]
            ).fontWeight,
          },
        };
      }
      return {};
    }),
  );

  const styles = merge({}, sizeStyles, weightStyles);
  return {
    defaultSize: defaultSize as RemoveErrorBrand<typeof defaultSize>,
    defaultWeight: defaultWeight as RemoveErrorBrand<typeof defaultWeight>,
    styles,
  };
}

export function getBodyTextStyles({
  size,
  weight,
  themeProps,
  mono,
}: {
  mono?: boolean;
  size: MakeResponsive<GetTypeSizes<"body">>;
  weight: MakeResponsive<GetFontWeights<"body">>;
  themeProps: BiomeTheme;
}) {
  const {
    defaultSize,
    defaultWeight,
    styles: responsiveStyles,
  } = renderResponsiveTextStyles({
    tokenTextComponent: "body",
    size,
    weight,
    themeProps,
  });

  return {
    fontFamily: mono
      ? themeProps.base.font.family.body.secondary
      : themeProps.base.font.family.body.primary,
    fontSize: themeProps.base.text.body[defaultSize].regular.fontSize,
    lineHeight: themeProps.base.text.body[defaultSize].regular.lineHeight,
    fontWeight:
      themeProps.base.text.body[defaultSize][defaultWeight].fontWeight,
    textTransform: "none",
    letterSpacing: "unset",
    ...responsiveStyles,
    "&::selection": {
      backgroundColor: themeProps.base.color.text.highlight,
    },
  } satisfies DescendantStyleProperties;
}

export function getCaptionTextStyles({
  size,
  weight,
  themeProps,
}: {
  size: MakeResponsive<GetTypeSizes<"caption">>;
  weight: MakeResponsive<GetFontWeights<"caption">>;
  themeProps: BiomeTheme;
}) {
  const {
    defaultSize,
    defaultWeight,
    styles: responsiveStyles,
  } = renderResponsiveTextStyles({
    tokenTextComponent: "caption",
    size,
    weight,
    themeProps,
  });

  return {
    fontFamily: themeProps.base.font.family.body.primary,
    fontSize: themeProps.base.text.caption[defaultSize].regular.fontSize,
    lineHeight: themeProps.base.text.caption[defaultSize].regular.lineHeight,
    fontWeight:
      themeProps.base.text.caption[defaultSize][defaultWeight].fontWeight,
    textTransform: themeProps.base.text.caption[defaultSize][defaultWeight]
      .casing as Properties["textTransform"],
    letterSpacing:
      themeProps.base.text.caption[defaultSize][defaultWeight].letterSpacing,
    ...responsiveStyles,
    "&::selection": {
      backgroundColor: themeProps.base.color.text.highlight,
    },
  } satisfies DescendantStyleProperties;
}

export function getHeadingTextStyles({
  size,
  weight,
  themeProps,
}: {
  size: MakeResponsive<GetTypeSizes<"heading">>;
  weight: MakeResponsive<GetFontWeights<"heading">>;
  themeProps: BiomeTheme;
}) {
  const {
    defaultSize,
    defaultWeight,
    styles: responsiveStyles,
  } = renderResponsiveTextStyles({
    tokenTextComponent: "heading",
    size,
    weight,
    themeProps,
  });

  return {
    fontFamily: themeProps.base.font.family.heading.primary,
    fontSize: themeProps.base.text.heading[defaultSize].regular.fontSize,
    lineHeight: themeProps.base.text.heading[defaultSize].regular.lineHeight,
    fontWeight:
      themeProps.base.text.heading[defaultSize][defaultWeight].fontWeight,
    textTransform: "none",
    letterSpacing: "unset",
    ...responsiveStyles,
    "&::selection": {
      backgroundColor: themeProps.base.color.text.highlight,
    },
  } as DescendantStyleProperties;
}
