import type { CARD_HERO_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type {
  BiomeTheme,
  ButtonProps,
  ImageSizeVariant,
  MakeResponsive,
} from "@/types";
import { useMemo } from "react";

export type CardHeroSize = (typeof CARD_HERO_SIZES)[number];

export function getHeroInsetAmount(size: string, theme: BiomeTheme) {
  switch (size) {
    case "medium":
      return theme.base.spacing.x12;

    case "large":
      return theme.base.spacing.x16;

    case "small":
    default:
      return theme.base.spacing.x6;
  }
}

export function getTextContainerMinHeight(size: string) {
  switch (size) {
    case "large":
      return "60%";

    case "small":
      return "50%";

    case "medium":
    default:
      return "55%";
  }
}

export const DEFAULT_CARD_HERO_ASPECT = "5/7";

export function useGetCardHeroButtonVariant(variant: string) {
  const { base } = useTheme();
  const variantToUse = useMemo(() => {
    switch (variant) {
      case "tertiary":
        return base.colorMode === "onDark" ? "tertiary" : "tertiary/inverse";
      case "primary":
      default:
        return base.colorMode === "onDark" ? "primary" : "primary/inverse";
    }
  }, [variant, base]);

  return variantToUse;
}

export type CardHeroButtonVariant = Extract<
  ButtonProps["variant"],
  "primary" | "tertiary"
>;

export function isHeroCardSizeAnArray(
  size: unknown,
): size is Array<CardHeroSize | null> {
  return Array.isArray(size);
}

export function getFramedSizeForHeroCardSize(
  size: CardHeroSize | null,
): ImageSizeVariant | null {
  switch (size) {
    case "large":
      return "xLarge";
    case "medium":
    case "small":
      return "large";
    case null:
    default:
      return null;
  }
}

export function useGetFramedContentSizeFromHeroCardSize(
  size: MakeResponsive<CardHeroSize>,
) {
  const chosenBiomeImageSize = useMemo(() => {
    return isHeroCardSizeAnArray(size)
      ? size.map((s) => getFramedSizeForHeroCardSize(s))
      : getFramedSizeForHeroCardSize(size as CardHeroSize);
  }, [size]);
  return chosenBiomeImageSize as MakeResponsive<ImageSizeVariant>;
}
