import type {
  LooseAutocomplete,
  SpacingTokenPaths,
} from "@biom3/design-tokens";
import { ClassNames } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Stack } from "@/components/Stack";
import type {
  BoxWithRCAndDomProps,
  DeeplyNestedSx,
  MakeValidSxValue,
  MotionProfile,
} from "@/types";

import { useGetMotionProfile, useTheme } from "@/hooks";
import { getMotionProfileSx } from "@/utils";
import { innerContainerBaseSx, slideSx } from "./styles";

export type CarouselSimpleSlideProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & {
  active?: boolean;
  highlightActiveSlide?: boolean;
  innerContainerSx?: DeeplyNestedSx;
  slideWidth?: MakeValidSxValue<
    LooseAutocomplete<NonNullable<SpacingTokenPaths>>
  >;
  motionProfile?: MotionProfile;
};

export function CarouselSimpleSlide<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  className,
  active,
  testId = "CarouselSimpleSlide",
  slideWidth,
  sx = {},
  innerContainerSx = {},
  highlightActiveSlide,
  motionProfile,
  ...props
}: CarouselSimpleSlideProps<RC>) {
  const theme = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const mergedSx = useMemo(
    () => merge(slideSx, { "--slideWidth": slideWidth }, sx),
    [slideWidth, sx],
  );
  const mergedInnerContainerSx = useMemo(
    () =>
      merge(
        innerContainerBaseSx,
        getMotionProfileSx(motionProfileToUse, theme),
        {
          ...(highlightActiveSlide
            ? {
                border: "solid",
                borderWidth: "base.border.size.200",
                borderColor: "transparent",
              }
            : {}),
          ...(active
            ? {
                p: "base.border.size.200",
                borderColor: "base.color.translucent.standard.1000",
                boxShadow: ({ base }) =>
                  `inset 0 0 0 ${base.border.size[200]} ${base.color.translucent.inverse[1000]}`,

                "& .Card, & .CardHero": {
                  brad: "base.borderRadius.x2",
                },
              }
            : {}),
        } as DeeplyNestedSx,
        innerContainerSx,
      ),
    [innerContainerSx, active, motionProfileToUse, theme, highlightActiveSlide],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <Box
          {...props}
          testId={testId}
          className={cx(className, "CarouselSimpleSlide", {
            "CarouselSimpleSlide--active": active,
          })}
          sx={mergedSx}
        >
          <Stack
            className="CarouselSimpleSlide__innerContainer"
            sx={mergedInnerContainerSx}
            testId={`${testId}__innerContainer`}
          >
            {children}
          </Stack>
        </Box>
      )}
    </ClassNames>
  );
}

CarouselSimpleSlide.displayName = "CarouselSimple.Slide";
