import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { useTheme } from "@/hooks/useTheme";
import {
  type BiomeTheme,
  type DeeplyNestedSx,
  type IconSubcomponentDiscrimintatedUnion,
  type StatefulButtConProps,
  type StatefulButtConState,
  isDualVariantIcon,
} from "@/types";

import { ButtCon } from "./ButtCon";
import { ButtConSvgIcon } from "./ButtConSvgIcon";

const getButtConSx = ({ state }: { state: StatefulButtConState }) => {
  switch (state) {
    case "success":
      return {
        bg: "base.color.status.success.bright",
      };

    case "fatal":
      return {
        bg: "base.color.status.fatal.bright",
      };

    case "initial":
    case "loading":
    default:
      return {};
  }
};

const getIconSx = ({
  state,
  theme,
}: {
  state: StatefulButtConState;
  theme: BiomeTheme;
}): DeeplyNestedSx => {
  switch (state) {
    case "success":
    case "fatal":
      return {
        fill:
          theme.base.colorMode === "onDark"
            ? "base.color.text.body.inverse.primary"
            : "base.color.text.body.primary",
      };

    case "initial":
    case "loading":
    default:
      return {};
  }
};

export function StatefulButtCon<
  RC extends ReactElement | undefined = undefined,
>({ state = "initial", sx = {}, ...props }: StatefulButtConProps<RC>) {
  const { icon, ...propsMinusIcon } =
    "icon" in props ? props : { icon: undefined, ...props };
  const { iconVariant, ...propsMinusIconAndIconVariant } =
    "iconVariant" in propsMinusIcon
      ? propsMinusIcon
      : { iconVariant: undefined, ...propsMinusIcon };

  const theme = useTheme();
  const currentIconProps: IconSubcomponentDiscrimintatedUnion = useMemo(() => {
    if (!icon) return { icon: "Loading" };
    switch (state) {
      case "loading":
        return { icon: "Loading" };
      case "success":
        return { icon: "Tick", variant: "bold" };
      case "fatal":
        return { icon: "Exclamation", variant: "bold" };
      case "initial":
      default:
        return isDualVariantIcon(icon) ? { icon, iconVariant } : { icon };
    }
  }, [state, icon, iconVariant]);

  const buttConSx = merge(
    {
      transitionProperty: "background, box-shadow",
      transitionDuration: ({ base }: BiomeTheme) =>
        `${base.motion.normal.gentle.cssDuration}, ${base.motion.normal.fast.cssDuration}`,
      transitionTimingFunction: ({ base }: BiomeTheme) =>
        `${base.motion.normal.gentle.cssEase}, ${base.motion.normal.fast.cssEase}`,
    },
    getButtConSx({ state }),
    sx,
  );
  const iconSx = getIconSx({ state, theme });
  return (
    <ButtCon
      {...propsMinusIconAndIconVariant}
      {...currentIconProps}
      iconSx={iconSx}
      variant="tertiary"
      sx={buttConSx}
    />
  );
}

StatefulButtCon.displayName = "StatefulButtCon";
StatefulButtCon.SvgIcon = ButtConSvgIcon;
