import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import { type ReactElement, type ReactNode, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Body } from "@/components/Text/Body";
import { useGetSubcomponentChild } from "@/hooks";
import type { GetMultiTextProps, StandardComponentWithProps } from "@/types";
import { cloneElementWithCssProp } from "@/utils";
import { prettyFormatNumber } from "@/utils/numberHelpers";

import { PriceDisplayCaption } from "./PriceDisplayCaption";
import { PriceDisplayCurrencyImage } from "./PriceDisplayCurrencyImage";
import { PriceDisplayIcon } from "./PriceDisplayIcon";
import { baseImageSx, baseOuterContainerSx, priceContentSx } from "./styles";

type PriceDisplayBaseProps = StandardComponentWithProps<
  HTMLSpanElement,
  {
    /**
     * @deprecated Support for the `fiatAmount` prop will be removed in the future.
     * Please use the `<PriceDisplay.Caption />` subcomponent instead.
     */
    fiatAmount?: string;
    price: string;
    use?: ReactElement<unknown>;
    children?: ReactNode;
  }
>;

export type PriceDisplayProps<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined,
> = Omit<GetMultiTextProps<RC, Use, PriceDisplayBaseProps>, "shimmer"> & {
  shimmer?: boolean;
};

export function PriceDisplay<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined = undefined,
>({
  price,
  domRef,
  sx = {},
  testId = "PriceDisplay",
  rc = <span />,
  className,
  children,
  shimmer,
  fiatAmount,
  ...props
}: PriceDisplayProps<RC, Use>) {
  const { use, ...propsMinusUse } =
    "use" in props ? props : { ...props, use: undefined };
  const { size, ...propsMinusUseAndSize } =
    "size" in propsMinusUse
      ? propsMinusUse
      : { ...propsMinusUse, size: undefined };
  const { weight, ...boxDomAttrProps } =
    "weight" in propsMinusUseAndSize
      ? propsMinusUseAndSize
      : { ...propsMinusUseAndSize, weight: "bold" };

  const prettyPrice = prettyFormatNumber(price);
  const currencyImage = useGetSubcomponentChild(
    children,
    PriceDisplayCurrencyImage,
  );
  const caption = useGetSubcomponentChild(children, PriceDisplayCaption);
  const icon = useGetSubcomponentChild(children, PriceDisplayIcon);
  const pickedPriceImagery = currencyImage ?? icon ?? null;
  const pickedPriceImagerySx = pickedPriceImagery?.props?.sx ?? {};
  const mergedCurrencyImageSx = useMemo(
    () =>
      merge(
        baseImageSx,
        {
          fill: sx.color ?? sx.c ?? "base.color.text.body.primary",
        },
        pickedPriceImagerySx,
      ),
    [pickedPriceImagerySx, sx],
  );
  const priceContent = useMemo(
    () =>
      cloneElementWithCssProp(
        use || <Body size={size as BodySize} weight={weight as BodyWeight} />,
        {
          testId: `${testId}__price`,
          ...(size ? { size } : {}),
          sx: priceContentSx,
          shimmer: shimmer ? 1 : 0,
          children: (
            <>
              {prettyPrice}
              {currencyImage ? (
                <SmartClone
                  testId={`${testId}__price__currencyImage`}
                  sx={mergedCurrencyImageSx}
                  aspectRatio={currencyImage.props?.aspectRatio ?? "1"}
                >
                  {currencyImage}
                </SmartClone>
              ) : icon ? (
                <SmartClone
                  testId={`${testId}__price__icon`}
                  sx={mergedCurrencyImageSx}
                >
                  {icon}
                </SmartClone>
              ) : null}
            </>
          ),
          className: "priceContent",
        },
      ),
    [
      currencyImage,
      mergedCurrencyImageSx,
      icon,
      prettyPrice,
      size,
      testId,
      use,
      shimmer,
      weight,
    ],
  );

  const fiatAmountCaption = useMemo(() => {
    if (!caption && !fiatAmount) return null;

    return fiatAmount ? (
      <PriceDisplayCaption
        size="xSmall"
        weight="regular"
        testId={`${testId}__fiatAmount`}
        shimmer={shimmer ? 1 : 0}
      >
        {fiatAmount}
      </PriceDisplayCaption>
    ) : (
      <SmartClone shimmer={shimmer ? 1 : 0}>{caption}</SmartClone>
    );
  }, [caption, testId, fiatAmount, shimmer]);

  return (
    <Box
      {...boxDomAttrProps}
      rc={rc}
      testId={testId}
      // @TODO: this should be a Span element, not HTMLDivElement
      domRef={domRef as Ref<HTMLDivElement>}
      sx={merge(baseOuterContainerSx, sx)}
      className={`${className ?? ""} PriceDisplay`}
    >
      {priceContent}
      {fiatAmountCaption}
    </Box>
  );
}

PriceDisplay.displayName = "PriceDisplay";
PriceDisplay.CurrencyImage = PriceDisplayCurrencyImage;
PriceDisplay.Caption = PriceDisplayCaption;
PriceDisplay.Icon = PriceDisplayIcon;
