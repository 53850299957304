import { useEffect, useRef } from "react";

/**
 * The function `useInterval` sets up an interval that repeatedly calls a given callback function with
 * a specified time interval.
 * @param callback - A function that will be called repeatedly at the specified interval.
 * @param {number} interval - The `interval` parameter is a number that represents the time interval
 * (in milliseconds) at which the `callback` function should be called repeatedly.
 * @returns The `useInterval` hook is returning a `ref` object that holds the ID of the interval set by
 * `window.setInterval()`. This `ref` can be used to clear the interval using `window.clearInterval()`.
 */
export function useInterval(callback: () => void, interval: number) {
  const intervalRef = useRef<number>(0);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    intervalRef.current = window.setInterval(tick, interval);
    return () => window.clearInterval(intervalRef.current);
  }, [interval]);
  return intervalRef;
}
