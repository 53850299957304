import { CoinbaseSymbol } from "./CoinbaseSymbol";
import { DocsHorizontalLockup } from "./DocsHorizontalLockup";
import { DocsHorizontalLockupCompact } from "./DocsHorizontalLockupCompact";
import { DocsVerticalLockup } from "./DocsVerticalLockup";
import { GameStopSymbol } from "./GameStopSymbol";
import { HubHorizontalLockup } from "./HubHorizontalLockup";
import { HubHorizontalLockupCompact } from "./HubHorizontalLockupCompact";
import { HubVerticalLockup } from "./HubVerticalLockup";
import { ImmutableHorizontalLockup } from "./ImmutableHorizontalLockup";
import { ImmutableSymbol } from "./ImmutableSymbol";
import { ImmutableSymbolInverse } from "./ImmutableSymbolInverse";
import { ImmutableSymbolSolid } from "./ImmutableSymbolSolid";
import { ImmutableSymbolSolidInverse } from "./ImmutableSymbolSolidInverse";
import { ImmutableVerticalLockup } from "./ImmutableVerticalLockup";
import { MagicSymbol } from "./MagicSymbol";
import { MetaMaskSymbol } from "./MetaMaskSymbol";
import { PassportHorizontalLockup } from "./PassportHorizontalLockup";
import { PassportHorizontalLockupCompact } from "./PassportHorizontalLockupCompact";
import { PassportSymbol } from "./PassportSymbol";
import { PassportSymbolOutlined } from "./PassportSymbolOutlined";
import { PassportVerticalLockup } from "./PassportVerticalLockup";
import { PlayHorizontalLockup } from "./PlayHorizontalLockup";
import { PlayHorizontalLockupCompact } from "./PlayHorizontalLockupCompact";
import { PlayVerticalLockup } from "./PlayVerticalLockup";
import { RollupX } from "./RollupX";
import { RollupXFilled } from "./RollupXFilled";
import { RollupZkevm } from "./RollupZkevm";
import { RollupZkevmFilled } from "./RollupZkevmFilled";
import { WalletConnectSymbol } from "./WalletConnectSymbol";
import { ZkevmHorizontalLockup } from "./ZkevmHorizontalLockup";

export const lockupLogos = {
  ImmutableHorizontalLockup,
  ImmutableVerticalLockup,
  PassportHorizontalLockup,
  PassportHorizontalLockupCompact,
  PassportVerticalLockup,
  HubHorizontalLockup,
  HubHorizontalLockupCompact,
  HubVerticalLockup,
  DocsHorizontalLockup,
  DocsHorizontalLockupCompact,
  DocsVerticalLockup,
  PlayHorizontalLockup,
  PlayHorizontalLockupCompact,
  PlayVerticalLockup,
  ZkevmHorizontalLockup,
} as const;

export const immutableSymbolLogos = {
  ImmutableSymbol,
  ImmutableSymbolSolid,
  ImmutableSymbolInverse,
  ImmutableSymbolSolidInverse,
  PassportSymbol,
  PassportSymbolOutlined,
  RollupX,
  RollupXFilled,
  RollupZkevm,
  RollupZkevmFilled,
} as const;

export const otherSymbolLogos = {
  MetaMaskSymbol,
  WalletConnectSymbol,
  MagicSymbol,
  GameStopSymbol,
  CoinbaseSymbol,
} as const;

export const allLogos = {
  ...lockupLogos,
  ...immutableSymbolLogos,
  ...otherSymbolLogos,
};

export type AllLogoKeys = keyof typeof allLogos;
