import type { DistributiveOmit, MotionProfile } from "@/types";
import type { BodySize } from "@biom3/design-tokens";
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ReactElement,
  type ReactNode,
  isValidElement,
} from "react";

import type {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  MENU_ITEM_SHIMMER_TYPES,
  MENU_ITEM_SIZES,
} from "@/constants";
import type {
  BoxProps,
  DomPropsWithDomRef,
  MakeResponsive,
  OnClickFunction,
  StandardComponentWithProps,
} from "./core";
import type {
  IconProps,
  IconSubcomponentDiscrimintatedUnion,
  OptionalIconSubcomponentDiscrimintatedUnion,
} from "./iconAndLogo";
import type { InputTextAlign } from "./input";
import type { DeeplyNestedSx } from "./sx";
import type { BodyTextProps } from "./text";

export type BaseClickableProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    children?: ReactNode;
  }
>;

export type BaseClickableWithRCAndDomProps<
  RC extends ReactElement | undefined = undefined,
> = RC extends undefined
  ? DomPropsWithDomRef<"button"> & BaseClickableProps
  : BaseClickableProps & { rc: RC };

export type ButtonSize = (typeof BUTTON_SIZES)[number];

type BaseButtonProps = {
  size?: MakeResponsive<ButtonSize>;
  variant?: ButtonVariant;
  motionProfile?: MotionProfile;
};

export type ButtonProps<RC extends ReactElement | undefined = undefined> =
  BaseClickableWithRCAndDomProps<RC> & BaseButtonProps;

export type ButtConProps<RC extends ReactElement | undefined = undefined> =
  BaseClickableWithRCAndDomProps<RC> &
    BaseButtonProps & {
      iconSx?: DeeplyNestedSx;
    } & (IconSubcomponentDiscrimintatedUnion | { children: ReactNode });

export type OptionalIconButtConProps<
  RC extends ReactElement | undefined = undefined,
> = BaseClickableWithRCAndDomProps<RC> &
  BaseButtonProps & {
    iconSx?: DeeplyNestedSx;
  } & (OptionalIconSubcomponentDiscrimintatedUnion | { children: ReactNode });

export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export type LinkSize = BodySize;
export type LinkVariant = "primary" | "secondary";

export type LinkIconProps = IconProps;

export type LinkBaseProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    variant?: LinkVariant;
    children?: ReactNode;
    use?: ReactElement;
    size?: MakeResponsive<LinkSize>;
  }
>;

export type GetClickableMultiTextProps<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined,
  BaseProps extends {},
  BaseClickProps extends {},
> = RC extends undefined
  ? Use extends undefined
    ? DomPropsWithDomRef<"button"> & BaseProps & BaseClickProps & BodyTextProps
    : BaseProps & BaseClickProps & { use: Use }
  : Use extends undefined
    ? BaseProps & { rc: RC }
    : BaseProps & { rc: RC; use: Use };

export type LinkProps<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined,
> = GetClickableMultiTextProps<RC, Use, LinkBaseProps, BaseClickableProps>;

export type TextInputButtonProps = BaseClickableProps &
  ComponentPropsWithoutRef<"button"> & {
    disabled?: boolean;
    currentInputValue?: ComponentPropsWithoutRef<"input">["value"];
  };

type ComponentWithOnClick<Element extends "button" | "div"> = {
  onClick: ComponentPropsWithoutRef<Element>["onClick"];
};

export type GetHybridClickableRCandDomProps<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
  FallbackNodeTag extends ElementType = "div",
> = RC extends undefined
  ? OnClick extends undefined
    ? DomPropsWithDomRef<FallbackNodeTag> & Omit<BoxProps, "domRef">
    : DomPropsWithDomRef<"button"> & BaseClickableProps & { onClick?: OnClick }
  : Omit<BaseClickableProps, "domRef"> & { rc?: RC };

export type MenuItemSize = (typeof MENU_ITEM_SIZES)[number];
export type MenuItemShimmerType = (typeof MENU_ITEM_SHIMMER_TYPES)[number];

export type MenuItemProps<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick> & {
  emphasized?: boolean;
  size?: MakeResponsive<MenuItemSize>;
  selected?: boolean;
  shimmer?: boolean | MenuItemShimmerType;
  shimmerSx?: DeeplyNestedSx;
  controlledHover?: boolean;
  textAlign?: InputTextAlign;
  motionProfile?: MotionProfile;
};

export type MenuItemContentProps<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
> = Omit<MenuItemProps<RC, OnClick>, "shimmer">;

type BaseMenuItemShimmerProps = Omit<
  StandardComponentWithProps<
    HTMLDivElement,
    {
      shimmerType?: MenuItemShimmerType;
      size?: MakeResponsive<MenuItemSize>;
      emphasized?: boolean;
    }
  >,
  "domRef"
>;

export type MenuItemShimmerProps<
  RC extends ReactElement | undefined = undefined,
> = RC extends undefined
  ? DomPropsWithDomRef<"button"> & BaseMenuItemShimmerProps
  : BaseMenuItemShimmerProps & { rc: RC };

type ClickableComponentType = {
  props: { onClick?: unknown; href?: unknown; to?: unknown };
};
function isPotentialComponentWithClickableProps(
  rc: unknown,
): rc is ClickableComponentType {
  return typeof rc === "object";
}
function isClickableRc(rc: ReactElement | undefined): boolean {
  const isComponent = isValidElement(rc);
  // @NOTE: early exit if its not a component:
  if (!isComponent) return false;

  // @NOTE: try to detect Link components from next/link & react-router-dom etc
  // (a previous iteration of this logic attemtped to try do `/Link/.match(rc.type.render.name)`
  // but the name of the component function can be minified in production builds 🤦‍♂️🤪)
  if (isPotentialComponentWithClickableProps(rc)) {
    return (
      // @NOTE: detect when plain old HTML elements are passed in as RC:
      rc?.type === "a" ||
      rc?.type === "button" ||
      // @NOTE: detect NextLink and ReactRouter Link etc components:
      Boolean(rc?.props?.onClick) ||
      Boolean(rc?.props?.href) ||
      Boolean(rc?.props?.to)
    );
  }

  // @NOTE: if its none of the above, then its not clickable
  return false;
}

export function isBaseClickable(
  onClick: unknown,
  rc: ReactElement | undefined,
  containsClickables: boolean,
  props: unknown,
): props is BaseClickableProps & ComponentWithOnClick<"button"> {
  return isClickableRc(rc) || (!containsClickables && Boolean(onClick));
}

export function isBox(
  onClick: unknown,
  rc: ReactElement | undefined,
  containsClickables: boolean,
  props: unknown,
): props is Partial<BoxProps> & ComponentWithOnClick<"div"> {
  return containsClickables || (!isClickableRc(rc) && !onClick);
}

export type StatefulButtConState = "initial" | "loading" | "success" | "fatal";

export type StatefulButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "variant"> & {
  state?: StatefulButtConState;
};
