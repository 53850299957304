import type {
  BiomeTheme,
  ButtonSize,
  DeeplyNestedSx,
  MakeResponsive,
} from "@/types";
import { getResponsiveSx } from "@/utils";

export const horizontalMenuSxProps: DeeplyNestedSx = {
  justifyContent: "stretch",
  bg: "base.color.translucent.standard.100",
  position: "relative",
  d: "flex",
  w: "100%",
};

export function getContainerStyles({
  size,
  theme,
}: {
  size: ButtonSize;
  theme: BiomeTheme;
}) {
  const paddingAndGap = /large|medium/.test(size)
    ? theme.base.spacing.x2
    : theme.base.spacing.x1;
  return {
    gap: paddingAndGap,
    padding: paddingAndGap,
    borderRadius: /large|medium/.test(size)
      ? theme.base.borderRadius.x8
      : theme.base.borderRadius.x4,
  };
}

export const renderResponsiveContainerStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<ButtonSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    size,
    theme,
    renderSxAtSize: getContainerStyles,
  });

export const buttonBaseSxProps: DeeplyNestedSx = {
  flex: 1,
  paddingX: "base.spacing.x4",
  transitionProperty: "background, color, box-shadow",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",

  "& > span": {
    textAlign: "left",
    whiteSpace: "nowrap",
  },

  // @NOTE: make some minor style overrides for the Button and ButtCon components:
  "&.HorizontalMenuButton, &.HorizontalMenuButtCon": {
    "&:hover:not([disabled])::before": {
      content: "unset",
    },

    "&:hover:not([disabled])": {
      bg: "base.color.translucent.emphasis.200",
      boxShadow: ({ base }) =>
        `inset 0 0 0 ${base.border.size[100]} ${base.color.translucent.standard[1000]}`,
    },

    "&:active:not([disabled])": {
      bg: "base.color.translucent.emphasis.100",
    },

    "&.selected": {
      bg: "base.color.translucent.inverse.500",
    },

    "&.selected, &:active:not([disabled])": {
      boxShadow: ({ base }) =>
        `inset 0 0 0 ${base.border.size[200]} ${base.color.translucent.standard[1000]}`,
    },
  },
};

export function getButtonStyles({
  size,
  theme,
}: {
  size: ButtonSize;
  theme: BiomeTheme;
}) {
  return {
    borderRadius: /large|medium/.test(size)
      ? theme.base.borderRadius.x4
      : theme.base.spacing.x2,
  };
}

export const renderResponsiveButtonStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<ButtonSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    size,
    theme,
    renderSxAtSize: getButtonStyles,
  });

export function getButtonIconStyles({
  size,
  theme,
}: {
  size: ButtonSize;
  theme: BiomeTheme;
}) {
  return {
    width:
      size === "large" || size === "medium"
        ? theme.base.icon.size[250]
        : theme.base.icon.size[200],
  };
}

export const renderResponsiveButtonIconStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<ButtonSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    size,
    theme,
    renderSxAtSize: getButtonIconStyles,
  });
