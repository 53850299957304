import { ClassNames } from "@emotion/react";
import type { Property } from "csstype";
import {
  Children,
  type MouseEventHandler,
  type ReactElement,
  type Ref,
  useMemo,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import type { CardProps } from "@/components/Card";
import { BaseClickable } from "@/components/Clickable";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { CARD_HERO_SIZES, DEFAULT_CARD_HERO_SIZE } from "@/constants";
import {
  useForwardLocalDomRef,
  useGetCurrentSizeClass,
  useGetManySingleSubcomponentChildren,
  useGetMotionProfile,
  useGetSubcomponentChildren,
  useHoverCardVideoPlay,
  usePickPlaceholderImage,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import {
  type MakeResponsive,
  type MakeValidSxValue,
  type OnClickFunction,
  isBaseClickable,
  isBox,
} from "@/types";
import { checkChildrenForClickables, getMotionProfileSx } from "@/utils";

import { CardHeroAssetImage } from "./CardHeroAssetImage";
import { CardHeroAssetVideo } from "./CardHeroAssetVideo";
import { CardHeroButtCon } from "./CardHeroButtCon";
import { CardHeroButton } from "./CardHeroButton";
import { CardHeroCaption } from "./CardHeroCaption";
import { CardHeroChip } from "./CardHeroChip";
import { CardHeroDescription } from "./CardHeroDescription";
import { CardHeroFramedImage } from "./CardHeroFramedImage";
import { CardHeroFramedLogo } from "./CardHeroFramedLogo";
import { CardHeroTitle } from "./CardHeroTitle";
import {
  type CardHeroSize,
  DEFAULT_CARD_HERO_ASPECT,
  getHeroInsetAmount,
  getTextContainerMinHeight,
} from "./shared";
import { baseContainerSx, baseInnerContentSx, gradientScrimSx } from "./styles";

export type CardHeroProps<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
> = CardProps<RC, OnClick> & {
  size?: MakeResponsive<CardHeroSize>;
  aspectRatio?: MakeValidSxValue<Property.AspectRatio>;
  hideGradientSkrim?: boolean;
};

const DEFAULT_STACK_RC = <span />;

/**
 * @deprecated The `CardHero` component will be deprecated in the future.
 * Please use the `<HeroCard />` component instead.
 */
export function CardHero<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({
  size = DEFAULT_CARD_HERO_SIZE,
  className,
  testId = "CardHero",
  aspectRatio = DEFAULT_CARD_HERO_ASPECT,
  rc,
  sx = {},
  children,
  elevation = 1,
  hideGradientSkrim = false,
  selected,
  ...props
}: CardHeroProps<RC, OnClick>) {
  const motionProfile = useGetMotionProfile();
  const { onClick, ...propsMinusOnClick } =
    "onClick" in props ? props : { ...props, onClick: null };
  const { domRef, ...propsMinusOnClickAndDomRef } =
    "domRef" in propsMinusOnClick
      ? propsMinusOnClick
      : { ...propsMinusOnClick, domRef: null };
  const localDomRef = useForwardLocalDomRef(domRef as Ref<HTMLDivElement>);
  const pickedDefaultImage = usePickPlaceholderImage();

  const baseClickableProps = {
    onClick,
    domRef: localDomRef,
    ...(rc ? { rc } : {}),
    ...propsMinusOnClickAndDomRef,
  };
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_CARD_HERO_SIZE,
    CARD_HERO_SIZES,
  );

  const {
    subcomponents: [
      assetImage,
      assetVideo,
      title,
      caption,
      description,
      framedLogo,
      framedImage,
      chip,
    ],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    CardHeroAssetImage,
    CardHeroAssetVideo,
    CardHeroTitle,
    CardHeroCaption,
    CardHeroDescription,
    CardHeroFramedLogo,
    CardHeroFramedImage,
    CardHeroChip,
  ]);
  const buttons = useGetSubcomponentChildren(otherChildren, CardHeroButton);
  const buttCons = useGetSubcomponentChildren(otherChildren, CardHeroButtCon);
  const { otherChildren: nonSubcomponentChildren } =
    useSplitApartChildrenAndSubComponents(otherChildren, [
      CardHeroButtCon,
      CardHeroButton,
    ]);

  const containsClickables = useMemo(
    () =>
      Boolean(buttons?.length || buttCons?.length) ||
      checkChildrenForClickables(otherChildren),
    [buttons, buttCons, otherChildren],
  );
  const assetMediaToUse = assetVideo ?? assetImage;
  const framedMediaToUse = framedLogo ?? framedImage;
  const localAssetMediaRef = useForwardLocalDomRef(
    (assetMediaToUse?.props.domRef as Ref<HTMLVideoElement>) ?? {
      current: null,
    },
  );
  const theme = useTheme();
  const mergedContainerSx = useMemo(
    () =>
      merge(
        {
          "--cardHeroInsetAmount": getHeroInsetAmount(sizeClass, theme),
          "--textContainerMinHeight": getTextContainerMinHeight(sizeClass),
          "&::before, &::after": {
            ...getMotionProfileSx(motionProfile, theme),
          },
          "& .AspectRatioImage, & .Video": {
            ...getMotionProfileSx(motionProfile, theme),
          },
          aspectRatio,
        },
        getMotionProfileSx(motionProfile, theme),
        baseContainerSx,
        sx,
      ),
    [sx, sizeClass, theme, aspectRatio, motionProfile],
  );

  // @NOTE: setup the playOnCardHover functionality, if neccissary
  useHoverCardVideoPlay(localDomRef, assetVideo, localAssetMediaRef);

  const shouldRenderTextContainer = useMemo(
    () =>
      Boolean(
        title ||
          description ||
          caption ||
          chip ||
          containsClickables ||
          nonSubcomponentChildren,
      ),
    [
      title,
      description,
      chip,
      containsClickables,
      caption,
      nonSubcomponentChildren,
    ],
  );

  const innerContents = useMemo(
    () => (
      <>
        {assetMediaToUse ? (
          <SmartClone
            aspectRatio={assetMediaToUse.props.aspectRatio ?? aspectRatio}
            domRef={localAssetMediaRef}
          >
            {assetMediaToUse}
          </SmartClone>
        ) : (
          <CardHeroAssetImage
            aspectRatio={aspectRatio}
            imageUrl={pickedDefaultImage}
            relativeImageSizeInLayout="100%"
          />
        )}
        {shouldRenderTextContainer && !hideGradientSkrim ? (
          <Box
            sx={gradientScrimSx}
            className="gradientSkrim"
            testId={`${testId}__gradientSkrim`}
            rc={DEFAULT_STACK_RC}
          />
        ) : null}
        {framedMediaToUse && (
          <SmartClone cardHeroSize={size}>{framedMediaToUse}</SmartClone>
        )}

        {shouldRenderTextContainer ? (
          <Stack
            className="innerContentContainer"
            testId={`${testId}__innerContentContainer`}
            sx={baseInnerContentSx}
            rc={DEFAULT_STACK_RC}
            alignItems="flex-start"
          >
            {chip}
            {caption}
            {title && <SmartClone cardHeroSize={size}>{title}</SmartClone>}
            {description}

            {containsClickables && (
              <Stack
                sx={{ mt: "auto", alignSelf: "stretch" }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="base.spacing.x2"
                rc={DEFAULT_STACK_RC}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="base.spacing.x2"
                  rc={DEFAULT_STACK_RC}
                >
                  {Children.map(buttons, (button) => {
                    return button ? (
                      <SmartClone cardHeroSize={size}>{button}</SmartClone>
                    ) : null;
                  })}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="base.spacing.x2"
                  rc={DEFAULT_STACK_RC}
                >
                  {buttCons}
                </Stack>
              </Stack>
            )}

            {nonSubcomponentChildren}
          </Stack>
        ) : null}
      </>
    ),
    [
      hideGradientSkrim,
      shouldRenderTextContainer,
      nonSubcomponentChildren,
      assetMediaToUse,
      localAssetMediaRef,
      framedMediaToUse,
      pickedDefaultImage,
      chip,
      caption,
      title,
      description,
      containsClickables,
      buttons,
      buttCons,
      testId,
      aspectRatio,
      size,
    ],
  );
  const baseClassNames = useMemo(
    () => [
      className,
      "CardHero",
      `CardHero--${sizeClass}`,
      `CardHero--elevation${elevation}`,
      { "CardHero--selected": selected },
    ],
    [sizeClass, className, selected, elevation],
  );

  return (
    <ClassNames>
      {({ cx }) =>
        isBaseClickable(onClick, rc, containsClickables, baseClickableProps) ? (
          <BaseClickable
            {...baseClickableProps}
            testId={testId}
            sx={mergedContainerSx}
            className={cx(
              ...baseClassNames,
              "CardHero--BaseClickable",
              "CardHero--isClickable",
            )}
          >
            {innerContents}
          </BaseClickable>
        ) : isBox(
            onClick,
            rc,
            containsClickables,
            propsMinusOnClickAndDomRef,
          ) ? (
          <Box
            {...propsMinusOnClickAndDomRef}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
            domRef={localDomRef}
            testId={testId}
            rc={rc}
            sx={mergedContainerSx}
            className={cx(...baseClassNames, "CardHero--Box", {
              "CardHero--isClickable": Boolean(onClick),
            })}
          >
            {innerContents}
          </Box>
        ) : null
      }
    </ClassNames>
  );
}

CardHero.displayName = "CardHero";
CardHero.AssetImage = CardHeroAssetImage;
CardHero.AssetVideo = CardHeroAssetVideo;
CardHero.Title = CardHeroTitle;
CardHero.Description = CardHeroDescription;
CardHero.Caption = CardHeroCaption;
CardHero.ButtCon = CardHeroButtCon;
CardHero.Button = CardHeroButton;
CardHero.FramedLogo = CardHeroFramedLogo;
CardHero.FramedImage = CardHeroFramedImage;
CardHero.Chip = CardHeroChip;

// @NOTE: The `HeroCard` component is a direct copy of
// the `CardHero` component. We have renamed it to fit in with
// several other new "Hero" components which are being created
export function HeroCard<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({ children, rc, ...props }: CardHeroProps<RC, OnClick>) {
  return (
    <CardHero {...props} rc={rc}>
      {children}
    </CardHero>
  );
}

HeroCard.displayName = "HeroCard";
HeroCard.AssetImage = CardHeroAssetImage;
HeroCard.AssetVideo = CardHeroAssetVideo;
HeroCard.Title = CardHeroTitle;
HeroCard.Description = CardHeroDescription;
HeroCard.Caption = CardHeroCaption;
HeroCard.ButtCon = CardHeroButtCon;
HeroCard.Button = CardHeroButton;
HeroCard.FramedLogo = CardHeroFramedLogo;
HeroCard.FramedImage = CardHeroFramedImage;
HeroCard.Chip = CardHeroChip;
