import { AnalyticsBrowser } from "@segment/analytics-next";
import { EVENT_FORMATTER, PropertiesPlugin } from "./segmentPlugins";
import type { AnalyticsEvent, LegacyEventData, LegacyPageData } from "./types";

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY ?? "",
  },
  {
    user: {
      persist: true,
      cookie: {
        key: "play_user_id",
      },
      localStorage: {
        key: "play_user_traits",
      },
    },
    group: {
      persist: true,
      cookie: {
        key: "play_group_id",
      },
      localStorage: {
        key: "play_group_properties",
      },
    },
  },
);
export const propertiesPlugin = new PropertiesPlugin();
analytics.register(propertiesPlugin);
analytics.register(EVENT_FORMATTER);

function capitalize(string?: string): string {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function pageLegacyEvent(eventData: LegacyPageData) {
  analytics.page(
    `PassportDashboard${eventData.userJourney}${eventData.screen}${eventData.action ?? ""}`,
    {
      userJourney: eventData.userJourney,
      screen: eventData.screen,
      action: eventData.action,
      ...eventData.extras,
    },
  );
}

// Do not create new events with this function. Use trackEvent instead.
export function trackLegacyEvent(
  eventData: LegacyEventData,
  product: "PassportDashboard" | "Play" = "PassportDashboard",
) {
  let controlAndType = "";
  const extras: Record<string, unknown> = { ...eventData.extras };
  if ("control" in eventData && "controlType" in eventData) {
    extras.control = eventData.control;
    extras.controlType = eventData.controlType;
    controlAndType =
      capitalize(eventData.control) + capitalize(eventData.controlType);
  }
  analytics.track(
    `${product}${eventData.userJourney}${eventData.screen}_${controlAndType}${eventData.action ?? ""}`,
    {
      userJourney: eventData.userJourney,
      screen: eventData.screen,
      action: eventData.action,
      ...extras,
    },
  );
}

export function trackEvent(data: AnalyticsEvent) {
  let event = "";
  if ("component" in data) {
    event = `Play_${data.component}_${data.action}`;
  } else if ("product" in data) {
    event = `Play_${data.product}_${data.action}`;
  }
  analytics.track(event, data.extras);
}
