import { useTheme } from "@emotion/react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { type IconSubcomponentProps, isDualVariantIcon } from "@/types";

import type { ReactElement } from "react";
import { shouldInvertTextColoring } from "./styles";
import { type BannerVariant, DEFAULT_BANNER_VARIANT } from "./types";

export type BannerIconProps<RC extends ReactElement | undefined = undefined> =
  IconSubcomponentProps<
    {
      variant?: BannerVariant;
    },
    RC
  >;

export function BannerIcon<RC extends ReactElement | undefined>({
  sx = {},
  variant = DEFAULT_BANNER_VARIANT,
  iconVariant,
  icon,
  className,
  ...props
}: BannerIconProps<RC>) {
  const theme = useTheme();
  const invertColoring = shouldInvertTextColoring(variant, theme);
  const iconProps = isDualVariantIcon(icon)
    ? { icon, variant: iconVariant }
    : { icon };
  return (
    <Icon
      {...props}
      {...iconProps}
      className={`${className ?? ""} BannerIcon`}
      sx={merge(
        {
          position: "absolute",
          top: "base.spacing.x4",
          left: "base.spacing.x4",
          width: "base.icon.size.300",
          fill: invertColoring
            ? "base.color.text.body.inverse.primary"
            : "base.color.text.body.primary",
        },
        sx,
      )}
    />
  );
}

BannerIcon.displayName = "Banner.Icon";
