import type { MakeResponsive, StandardComponentWithProps } from "@/types";

export const DEFAULT_DIVIDER_SIZE = "medium";
export const DIVIDER_SIZES = ["xSmall", "small", "medium", "large"] as const;
export type DividerSize = (typeof DIVIDER_SIZES)[number];

export type DividerProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    size?: MakeResponsive<DividerSize>;
    children?: string | number | boolean;
    textAlign?: "left" | "center";
  }
>;
