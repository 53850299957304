import type { DistributiveOmit } from "@/types";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Pagination, type PaginationProps } from "@/components/Pagination";

export function TablePagination<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  className,
  ...props
}: DistributiveOmit<PaginationProps<RC>, "size">) {
  return (
    <Pagination
      {...props}
      className={`${className ?? ""} TablePagination`}
      sx={merge(
        {
          maxw: "470px",
        },
        sx,
      )}
      buttonSx={{
        h: "40px",
      }}
      size="small"
    />
  );
}

TablePagination.displayName = "TablePagination";
