import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import { Children, type ReactElement, useContext, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { Body } from "@/components/Text";
import {
  useGetSubcomponentChild,
  useGetSubcomponentChildren,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type { DomPropsWithDomRef, UnknownReactProps } from "@/types";
import { childHasSxProp, cloneElementWithCssProp } from "@/utils";

import { BulletListTitle } from "./BulletListTitle";
import {
  BulletListContext,
  type BulletListItemProps,
  type BulletListProps,
  BulletListProvider,
} from "./shared";
import {
  bulletListInnerSx,
  bulletListItemSx,
  nestedBulletListContainerSx,
} from "./styles";

type OrderedListProps = UnknownReactProps & {
  start?: number;
  type?: string;
};

export function BulletList<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  children,
  className,
  use = <ul />,
  testId = "BulletList",
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & BulletListProps
  : BulletListProps & { rc: RC }) {
  const title = useGetSubcomponentChild(children, BulletListTitle);
  const items = useGetSubcomponentChildren(children, BulletListItem);
  const listStart = useMemo(() => {
    if (use.type === "ol") {
      return (use.props as OrderedListProps)?.start ?? 1;
    }
    return undefined;
  }, [use]);
  const listType = useMemo(() => {
    if (use.type === "ul" || use.type === "ol") {
      return use.type;
    }
    return "ul";
  }, [use.type]);
  const mergedListSx = useMemo(
    () =>
      merge(bulletListInnerSx, {
        "--totalItems": items?.length ? items.length + 1 : 0,
        "--listStart": listStart ?? 1,
      }),
    [items?.length, listStart],
  );

  return (
    <BulletListProvider listType={listType}>
      <Stack
        {...props}
        testId={testId}
        direction="column"
        className={`${className ?? ""} BulletList`}
        gap="base.spacing.x2"
      >
        {title}
        <Stack
          rc={use}
          sx={mergedListSx}
          gap="base.spacing.x3"
          testId={`${testId}__actualList`}
          className="BulletList__actualList"
        >
          {items}
        </Stack>
      </Stack>
    </BulletListProvider>
  );
}

BulletList.displayName = "BulletList";
BulletList.Item = BulletListItem;
BulletList.Title = BulletListTitle;

// @NOTE: Because BulletList.Item has a dependency on BulletList
// (eg for nested bullet lists) these 2 components must reside in the
// same file, to avoid circular dependencies.

const ROOT_RC = <li />;
const STACK_RC = <span />;

export function BulletListItem<
  Use extends ReactElement | undefined = undefined,
>({
  className,
  domRef,
  sx = {},
  children,
  testId = "BulletListItem",
  ...props
}: BulletListItemProps<Use>) {
  const {
    dimensions: { titleDuoConWidth, titleGap },
    listType,
  } = useContext(BulletListContext);
  const { use, ...propsMinusUse } =
    "use" in props ? props : { ...props, use: undefined };
  const { size, ...propsMinusUseAndSize } =
    "size" in propsMinusUse
      ? propsMinusUse
      : { size: undefined, ...propsMinusUse };
  const { weight, ...propsMinusUseSizeAndWeight } =
    "weight" in propsMinusUseAndSize
      ? propsMinusUseAndSize
      : { weight: undefined, ...propsMinusUseAndSize };
  const mergedSx = useMemo(
    () =>
      merge(
        bulletListItemSx,
        {
          "--titleTextDuoConWidth": titleDuoConWidth
            ? `${titleDuoConWidth}px`
            : "2em",
          "--titleTextGap": titleGap ? `${titleGap}px` : "0.8em",
        },
        sx,
      ),
    [sx, titleGap, titleDuoConWidth],
  );
  const { subcomponents, otherChildren: listItemText } =
    useSplitApartChildrenAndSubComponents(children, [BulletList]);

  return cloneElementWithCssProp(
    use || <Body size={size as BodySize} weight={weight as BodyWeight} />,
    {
      ...propsMinusUseSizeAndWeight,
      rc: ROOT_RC,
      sx: mergedSx,
      domRef,
      testId,
      className: `${
        className ?? ""
      } BulletListItem BulletListItem--${listType}`,
      children: (
        <Stack rc={STACK_RC}>
          <Box rc={STACK_RC}>{listItemText}</Box>
          {subcomponents.length
            ? Children.map(subcomponents, (child) => {
                return (
                  <SmartClone
                    rc={STACK_RC}
                    sx={merge(
                      nestedBulletListContainerSx,
                      childHasSxProp(child) ? { sx: child.props.sx } : {},
                    )}
                  >
                    {child}
                  </SmartClone>
                );
              })
            : null}
        </Stack>
      ),
    },
  );
}

BulletListItem.displayName = "BulletList.Item";
