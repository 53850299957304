import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { useTheme } from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { Box } from "../Box";
import {
  DEFAULT_DIVIDER_SIZE,
  DIVIDER_SIZES,
  type DividerProps,
} from "./shared";
import { baseStyles, getResponsiveStyles, getSizeBasedStyles } from "./styles";

export function Divider<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_DIVIDER_SIZE,
  sx = {},
  children,
  textAlign = "left",
  className,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & DividerProps
  : DividerProps & { rc: RC }) {
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_DIVIDER_SIZE,
    DIVIDER_SIZES,
  );
  const mergedSx = merge(
    baseStyles(children !== undefined, textAlign),
    getSizeBasedStyles({ theme, size: startingSize }),
    getResponsiveStyles({ theme, size }),
    sx,
  );
  return (
    <Box {...props} sx={mergedSx} className={`${className ?? ""} Divider`}>
      {children}
    </Box>
  );
}

Divider.displayName = "Divider";
