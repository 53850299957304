import { ClassNames } from "@emotion/react";
import { AnimatePresence, motion } from "motion/react";
import {
  type MouseEventHandler,
  type ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { useGetSubcomponentChild, useTheme } from "@/hooks";
import { Box } from "../Box";
import { BaseClickable, ButtCon } from "../Clickable";
import { SmartClone } from "../SmartClone";
import { Stack, type StackProps } from "../Stack";
import { AccordionExpandedContent } from "./AccordionExpandedContent";
import {
  AccordionTargetLeftSlot,
  AccordionTargetRightSlot,
} from "./AccordionTargetSlot";
import type { ChevronSide } from "./shared";
import {
  buttConSx,
  clickableAreaSx,
  expandableAreaSx,
  getContainerSx,
} from "./style";

export type AccordionProps<RC extends ReactElement | undefined = undefined> =
  StackProps<RC> & {
    chevronSide?: ChevronSide;
    onExpandChange?: (expanded: boolean) => void;
    emphasized?: boolean;
    targetClickOveride?: MouseEventHandler<HTMLButtonElement>;
  } & (
      | {
          expanded: boolean;
          defaultExpanded?: never;
        }
      | {
          defaultExpanded?: boolean;
          expanded?: never;
        }
    );

export function Accordion<RC extends ReactElement | undefined = undefined>(
  props: AccordionProps<RC>,
) {
  const {
    emphasized,
    expanded,
    defaultExpanded,
    children,
    gap = "0",
    alignItems = "stretch",
    className,
    onExpandChange,
    chevronSide = "left",
    sx = {},
    rc = <motion.div />,
    targetClickOveride,
    testId = "Accordion",
    ...otherProps
  } = "expanded" in props
    ? { ...props, defaultExpanded: undefined }
    : { ...props, expanded: undefined };

  const theme = useTheme();
  const { base } = theme;
  const [uncontrolledExpanded, setUncontrolledExpanded] =
    useState(defaultExpanded);
  const expandedValueToUse = expanded ?? uncontrolledExpanded;
  const targetLeft = useGetSubcomponentChild(children, AccordionTargetLeftSlot);
  const targetRight = useGetSubcomponentChild(
    children,
    AccordionTargetRightSlot,
  );
  const expandedContent = useGetSubcomponentChild(
    children,
    AccordionExpandedContent,
  );

  const handleToggle = useCallback(() => {
    if (typeof expanded === "undefined") {
      setUncontrolledExpanded((old) => {
        onExpandChange?.(!old);
        return !old;
      });
    } else {
      onExpandChange?.(!expanded);
    }
  }, [expanded, onExpandChange]);

  const mergedSx = useMemo(
    () =>
      merge(
        getContainerSx(theme),
        theme.components?.Accordion?.sxOverride ?? {},
        sx,
      ),
    [sx, theme],
  );

  return (
    <ClassNames>
      {({ cx }) => (
        <Stack
          {...otherProps}
          className={`${className ?? ""} Accordion ${cx({
            emphasized,
            expanded: expandedValueToUse,
          })}`}
          gap={gap}
          alignItems={alignItems}
          rc={rc}
          sx={mergedSx}
          testId={testId}
        >
          <BaseClickable
            sx={merge(clickableAreaSx, {
              ...(chevronSide === "left"
                ? { pl: "base.spacing.x15" }
                : { pr: "base.spacing.x15" }),
            })}
            onClick={targetClickOveride || handleToggle}
            testId={`${testId}__clickableArea`}
            className="Accordion__clickableArea"
          >
            <ButtCon
              rc={<span />}
              icon={expandedValueToUse ? "ChevronCollapse" : "ChevronExpand"}
              variant="tertiary"
              size="small"
              testId={`${testId}__clickableArea__chevron`}
              sx={merge(buttConSx, {
                ...(chevronSide === "left"
                  ? { left: "base.spacing.x3" }
                  : { right: "base.spacing.x3" }),
              })}
              className="Accordion__clickableArea__ButtCon"
            />
            <SmartClone testId={`${testId}__clickableArea__targetLeft`}>
              {targetLeft}
            </SmartClone>
            {targetRight && (
              <SmartClone testId={`${testId}__clickableArea__targetRight`}>
                {targetRight}
              </SmartClone>
            )}
          </BaseClickable>

          <AnimatePresence>
            {Boolean(expandedContent) && expandedValueToUse ? (
              <Box
                sx={expandableAreaSx}
                className="Accordion__expandedContainer"
                rc={
                  <motion.div
                    initial={{
                      opacity: 0,
                      height: 0,
                      overflow: "hidden",
                      y: -16,
                    }}
                    animate={{
                      height: "auto",
                      opacity: 1,
                      y: 0,
                      transitionEnd: {
                        overflow: "visible",
                      },
                    }}
                    exit={{
                      height: 0,
                      opacity: 0,
                      overflow: "hidden",
                      y: -16,
                    }}
                    transition={{
                      duration: base.motion.normal.fast.jsDuration,
                      ease: base.motion.normal.fast.jsEase,
                    }}
                  />
                }
              >
                <SmartClone
                  chevronSide={chevronSide}
                  testId={`${testId}__expandedContent`}
                  className="Accordion__expandedContainer__content"
                >
                  {expandedContent}
                </SmartClone>
              </Box>
            ) : null}
          </AnimatePresence>
        </Stack>
      )}
    </ClassNames>
  );
}

Accordion.displayName = "Accordion";
Accordion.TargetLeftSlot = AccordionTargetLeftSlot;
Accordion.TargetRightSlot = AccordionTargetRightSlot;
Accordion.ExpandedContent = AccordionExpandedContent;
