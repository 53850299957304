import { keyframes } from "@emotion/react";

import type { AllIconKeys, IconVariant } from "@/types";
import { getCacheItem, setCacheItem, warnUser } from "@/utils";

export const dualVariantIcons = [
  "Add",
  "Alert",
  "AirDrop",
  "ArrowDown",
  "ArrowUp",
  "ArrowBackward",
  "ArrowForward",
  "Authenticated",
  "Calendar",
  "Cart",
  "Craft",
  "Circle",
  "ChevronBackward",
  "ChevronCollapse",
  "ChevronExpand",
  "ChevronForward",
  "CircleRightArrow",
  "CircleDownArrow",
  "Close",
  "CloseWithCircle",
  "Coins",
  "CopyText",
  "Countdown",
  "Dashboard",
  "Delete",
  "DevContracts",
  "DevExplorer",
  "Document",
  "Documents",
  "Dollar",
  "Education",
  "Edit",
  "EmailAddress",
  "EmbedCode",
  "ESports",
  "EthToken",
  "Exclamation",
  "Experiment",
  "Expand",
  "Faucet",
  "FavouriteActive",
  "Fullscreen",
  "FullscreenExit",
  "Groups",
  "Home",
  "HidePassword",
  "ImageAdd",
  "Immutable",
  "ImxRewards",
  "ImxToken",
  "Information",
  "InformationCircle",
  "Instagram",
  "JumpTo",
  "Logout",
  "Lock",
  "Menu",
  "Minting",
  "Minus",
  "More",
  "MoreVertical",
  "Notification",
  "NotificationBell",
  "PancakeSwap",
  "People",
  "PeopleNone",
  "ReturnKeyboard",
  "Sandbox",
  "Search",
  "Send",
  "Security",
  "SettingsCog",
  "Shield",
  "StarFull",
  "StarHalf",
  "SupportChat",
  "ShowPassword",
  "SocialShare",
  "SoundOn",
  "SoundOff",
  "Sparkle",
  "Swatch",
  "Tagging",
  "TickWithCircle",
  "Tags",
  "ThemeDark",
  "ThemeLight",
  "Tick",
  "Tokens",
  "TreasureChest",
  "TreeDiagram",
  "Trophy",
  "ViewList",
  "Wallet",
  "Profile",
  "Items",
  "BankCard",
  "X",
  "PlayGame",
  "NotificationBellActive",
  "HeartEffort",
  "Layer3",
  "Favourite",
  "Desktop",
  "Fire",
  "RaffleTicket",
  "ShoppingBag",
  "Flash",
  "GasHigh",
  "GasLow",
  "Gas",
  "TimeFast",
] as const;

export const singleVariantIcons = [
  "Apple",
  "ApplicationWeb",
  "ApplicationMobile",
  "Automation",
  "DevSdk",
  "Discord",
  "Email",
  "EpicStore",
  "Exchange",
  "Filter",
  "GitHub",
  "GraphPerformance",
  "Google",
  "GooglePlayStore",
  "Import",
  "JumpTo",
  "Key",
  "Language",
  "LinkedIn",
  "Loading",
  "Pause",
  "Percentage",
  "Planet",
  "Play",
  "Preferences",
  "Question",
  "Reddit",
  "Refresh",
  "Sort",
  "SushiSwap",
  "Swap",
  "Flip",
  "SteamStore",
  "Sword",
  "Telegram",
  "Twitter",
  "UniSwap",
  "Unity",
  "UnfoldMore",
  "UnfoldLess",
  "ViewGrid",
  "WalletConnect",
  "WalletDisconnect",
  "Webhooks",
  "Windows",
  "YouTube",
  "Overview",
] as const;

export const allIcons = [...dualVariantIcons, ...singleVariantIcons] as const;

type SingleVariantIconChildJson = {
  regular: string[];
};

type DualVariantIconChild = SingleVariantIconChildJson & {
  bold: string[];
};

type IconChildJsonResponse = SingleVariantIconChildJson | DualVariantIconChild;

function jsonHasBoldVariant(
  json: IconChildJsonResponse,
): json is DualVariantIconChild {
  return "bold" in json;
}

export async function fetchIconContent(
  icon: AllIconKeys,
  variant: IconVariant,
): Promise<string[] | null> {
  const localStorageKey = `icon-${icon}-${variant}`;
  const cachedIcon = await getCacheItem(localStorageKey);
  const isBoldVariant = variant === "bold";

  if (cachedIcon) {
    return cachedIcon as string[];
  }

  try {
    const resp = await fetch(
      `https://biome.immutable.com/hosted-assets/icons/${icon}.json`,
    );
    const children: IconChildJsonResponse = await resp.json();
    if (isBoldVariant && !jsonHasBoldVariant(children)) {
      warnUser(
        `<Icon /> warning - Bold variant is requested, but the SVG paths data was not found for icon: ${icon}.`,
      );
    }

    const content =
      isBoldVariant && jsonHasBoldVariant(children)
        ? children.bold
        : children.regular;
    setCacheItem(localStorageKey, content);
    return content;
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : "Unknown error";
    console.error("🧬 BIOME (icon) Error 🚨:", errorMessage);
    return null;
  }
}

export const spinIconKeyframes = keyframes`
  from { 
    rotate: 0deg; 
  } 
  to { 
    rotate: 360deg; 
  }
`;
