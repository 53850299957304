import type { DeeplyNestedSx } from "@/types";

export const verticalMenuSxProps: DeeplyNestedSx = {
  minWidth: "280px",
  maxHeight: "300px",
  bgc: "base.color.neutral.600",
  position: "relative",
  p: "base.spacing.x2",
  overflowY: "auto",
  brad: "base.borderRadius.x6",
  gap: "base.spacing.x1",

  "&.VerticalMenu--xSmall": {
    p: "base.spacing.x1",
  },
};
