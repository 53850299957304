import {
  type AllDualVariantIconKeys,
  type AllIconKeys,
  Card,
  CarouselSimple,
  type CarouselSimpleProps,
  HeroCard,
} from "@biom3/react";
import type {
  Content,
  FilledLinkToWebField,
  ImageField,
} from "@prismicio/client";
import { type QuestRules, RulesDialog } from "@ui-kit/RulesDialog";
import { trackGameDetailsEvent } from "@ui-kit/utils";
import Link from "next/link";
import { Fragment, useState } from "react";
import { CarouselContainer } from "./CarouselContainer";
import { useTileDimensions } from "./hooks";
import type { TileType } from "./types";

export type CarouselSlide = {
  game?: Content.GameDocument;
  caption?: string;
  title: string;
  description: string;
  image: ImageField;
  primaryCta: {
    icon?: AllIconKeys;
    link: FilledLinkToWebField;
  };
  questRules?: QuestRules;
  rulesLabel: string;
} & CarouselSimpleProps;

export type CarouselProps = {
  tileType: TileType;
  slides: CarouselSlide[];
};

export function Carousel({ tileType, slides }: CarouselProps) {
  const { tileWidth, tileGap } = useTileDimensions(tileType);
  const [openDialogIndex, setOpenDialogIndex] = useState(-1);

  const CardComponent = tileType === "mainQuest" ? HeroCard : Card;

  const onPrimaryCtaClick = (slide: CarouselSlide) => {
    trackGameDetailsEvent({
      game: slide.game,
      identifier: "Carousel",
      control: "QuestCarouselPlayNowClicked",
      controlType: "Button",
      extras: {
        primaryTarget: slide.primaryCta.link.target,
        primaryUrl: slide.primaryCta.link.url,
        primaryText: slide.primaryCta.link.text,
      },
    });
  };

  const onSocialLinkClick = (
    link: FilledLinkToWebField,
    game?: Content.GameDocument,
  ) => {
    trackGameDetailsEvent({
      game: game,
      identifier: "Carousel",
      control: "QuestCarouselSocialLinkClicked",
      controlType: "Button",
      extras: {
        socialLink: link.url,
        socialText: link.text,
        socialTarget: link.target,
      },
    });
  };

  const onOpenDialog = (index: number, slide: CarouselSlide) => {
    setOpenDialogIndex(index);
    trackGameDetailsEvent({
      game: slide.game,
      identifier: "Carousel",
      control: "QuestCarouselDialogOpened",
      controlType: "Button",
      extras: {
        questTitle: slide.title,
        questDescription: slide.description,
      },
    });
  };

  return (
    <CarouselContainer
      tileType={tileType}
      slideWidth={tileWidth}
      slideSpacing={tileGap}
    >
      {slides
        .filter((slide) => slide.image?.url)
        .map((slide, index) => (
          <Fragment key={slide.title}>
            <CarouselSimple.Slide>
              <CardComponent sx={{ h: "100%" }}>
                {slide.image.url && (
                  <CardComponent.AssetImage
                    imageUrl={slide.image.url}
                    alt={slide.image.alt || slide.title}
                    relativeImageSizeInLayout={tileWidth}
                  />
                )}
                <CardComponent.Title>{slide.title}</CardComponent.Title>
                <CardComponent.Description>
                  {slide.description}
                </CardComponent.Description>
                {slide.caption && (
                  <CardComponent.Caption>{slide.caption}</CardComponent.Caption>
                )}
                <CardComponent.Button
                  variant={tileType !== "default" ? "primary" : undefined}
                  rc={
                    <Link
                      onClick={() => onPrimaryCtaClick(slide)}
                      target={slide.primaryCta.link.target}
                      href={slide.primaryCta.link.url}
                    />
                  }
                >
                  {slide.primaryCta?.icon && (
                    <CardComponent.Button.Icon
                      icon={slide.primaryCta.icon as AllDualVariantIconKeys}
                    />
                  )}
                  {slide.primaryCta.link.text}
                </CardComponent.Button>
                {tileType !== "default" && (
                  <CardComponent.Button
                    variant={slide.primaryCta.link.url ? "tertiary" : "primary"}
                    onClick={() => onOpenDialog(index, slide)}
                  >
                    <Card.Button.Icon icon="Document" />
                    {slide.rulesLabel}
                  </CardComponent.Button>
                )}
                {slide.questRules?.game.socials.map((social) => (
                  <CardComponent.ButtCon
                    key={social.link.url}
                    rc={
                      <a
                        onClick={() =>
                          onSocialLinkClick(social.link, slide.game)
                        }
                        href={social.link.url}
                        target={social.link.target ?? "_blank"}
                      />
                    }
                    icon={social.logo.alt as AllDualVariantIconKeys}
                  />
                ))}
              </CardComponent>
            </CarouselSimple.Slide>
            <RulesDialog
              key={slide.title}
              image={slide.image}
              questRules={slide.questRules}
              isVisible={openDialogIndex === index}
              onClose={() => setOpenDialogIndex(-1)}
              testId={`${tileType}__rules-dialog`}
            />
          </Fragment>
        ))}
    </CarouselContainer>
  );
}
