import type { ReactElement } from "react";

import { FramedImage } from "@/components/FramedThings/FramedImage";
import type { FramedImageProps } from "@/types";

export type FramedStackImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = FramedImageProps<RC, Use>;

export function FramedStackPrimaryImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({ use, className, testId, ...props }: FramedStackImageProps<RC, Use>) {
  return (
    <FramedImage
      {...props}
      use={use}
      testId={`${testId}__framedImage--primary`}
      className={`${
        className ?? ""
      } FramedStackPrimaryImage FramedStack__image FramedStack__image--primary`}
    />
  );
}

export function FramedStackSecondaryImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({ use, testId, className, ...props }: FramedStackImageProps<RC, Use>) {
  return (
    <FramedImage
      {...props}
      use={use}
      testId={`${testId}__framedImage--secondary`}
      className={`${
        className ?? ""
      } FramedStackSecondaryImage FramedStack__innerContainer__image FramedStack__innerContainer__image--secondary`}
    />
  );
}

export function FramedStackTertiaryImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({ use, testId, className, ...props }: FramedStackImageProps<RC, Use>) {
  return (
    <FramedImage
      {...props}
      use={use}
      testId={`${testId}__framedImage--tertiary`}
      className={`${
        className ?? ""
      } FramedStackSecondaryImage FramedStack__innerContainer__image FramedStack__innerContainer__image--tertiary`}
    />
  );
}
