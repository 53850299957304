import { Body, Divider, Stack } from "@biom3/react";
import type { Content } from "@prismicio/client";
import type { GameSpecifications } from "@ui-kit";
import { trackGameDetailsEvent } from "@ui-kit/utils";
import NextLink from "next/link";
import { Fragment, useMemo } from "react";
import { Platforms } from "./Platforms";

type SpecsProps = {
  gameSpecifications: GameSpecifications;
  game: Content.GameDocument;
};

export const Specs = ({
  gameSpecifications,
  game,
}: SpecsProps): React.ReactNode => {
  const onLinkClick = (link: string, specLabel: string) => {
    trackGameDetailsEvent({
      game,
      identifier: "GameSpecs",
      control: "SpecsTableLinkClicked",
      controlType: "Link",
      extras: {
        link,
        specLabel,
      },
    });
  };

  const specs = useMemo(() => {
    return [
      { label: "Genre", value: gameSpecifications.genres.join(", ") },
      {
        label: "Platform",
        value: gameSpecifications.platforms.map(([name]) => name).join(", "),
        links: gameSpecifications.platforms.map(([_, link]) => link),
      },
      {
        label: "Studio",
        value: gameSpecifications.studio.name,
        links: [gameSpecifications.studio.website],
      },
      {
        label: "Publisher",
        value: gameSpecifications.publisher.name,
        links: [gameSpecifications.publisher.website],
      },
      {
        label: "Release date",
        value:
          new Date(gameSpecifications.releaseDate).getTime() >
          new Date("2050-01-01").getTime()
            ? "Coming Soon"
            : gameSpecifications.releaseDate,
      },
    ];
  }, [gameSpecifications]);

  const children: React.ReactNode[] = [];

  for (let i = 0; i < specs.length; i++) {
    const spec = specs[i];
    children.push(
      <Fragment key={spec.label}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ my: "base.spacing.x4" }}
        >
          <Body
            size={"small"}
            sx={{ color: "base.color.text.body.secondary", flex: 1 }}
          >
            {spec.label}
          </Body>
          {spec.label === "Platform" ? (
            <Platforms
              platforms={spec.value.split(", ")}
              links={spec.links}
              analyticsEvent={onLinkClick}
            />
          ) : (
            <Body
              size={"small"}
              sx={{
                color: "base.color.text.body.primary",
                textAlign: "right",
                textDecoration: "none",
              }}
              rc={
                spec.links?.[0] ? (
                  <NextLink
                    onClick={() =>
                      onLinkClick(spec?.links?.[0] || "", spec.label)
                    }
                    href={spec.links[0]}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  />
                ) : undefined
              }
            >
              {spec.value}
            </Body>
          )}
        </Stack>
        <Divider size={"xSmall"} />
      </Fragment>,
    );
  }
  return (
    <Stack gap="0px" sx={{ pt: "base.spacing.x3" }}>
      {children}
    </Stack>
  );
};
