import type { ReactElement } from "react";

import {
  MenuItemFramedImage,
  type MenuItemFramedImageProps,
} from "../MenuItem/MenuItemFramedImage";

export type SelectDefaultFramedImageProps<
  Use extends ReactElement | undefined = undefined,
> = MenuItemFramedImageProps<Use>;

export function SelectDefaultFramedImage<
  Use extends ReactElement | undefined = undefined,
>({ className, use, ...props }: SelectDefaultFramedImageProps<Use>) {
  return (
    <MenuItemFramedImage
      {...props}
      className={`${className ?? ""} SelectDefaultFramedImage`}
      use={use}
    />
  );
}

SelectDefaultFramedImage.displayName = "Select.DefaultFramedImage";
