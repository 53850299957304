import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_HEADING_PROPS } from "@/constants";
import { useGetCurrentSizeClass, useGetMotionProfile } from "@/hooks";
import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import { useTheme } from "@/hooks/useTheme";
import type { DistributiveOmit } from "@/types";
import type { HeadingProps } from "@/types";
import { cloneElementWithCssProp, getMotionProfileSx, slugify } from "@/utils";
import { getHeadingTextStyles } from "@/utils/textStyleHelpers";

import { MultiTextShimmer } from "../MultiTextShimmer";
import { baseTextComponentSx } from "../shared";

function HeadingContent<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_HEADING_PROPS.size,
  weight = DEFAULT_HEADING_PROPS.weight,
  rc = DEFAULT_HEADING_PROPS.rc,
  testId,
  children,
  sx = {},
  domRef,
  autoGenerateId,
  id: idProp,
  className,
  motionProfile,
  ...headingDomAttributes
}: DistributiveOmit<
  HeadingProps<RC>,
  "shimmer" | "shimmerSx" | "shimmerLines"
>) {
  let id = idProp;
  try {
    id =
      !idProp && autoGenerateId && typeof children === "string"
        ? slugify(children)
        : idProp;
  } catch (err) {
    // NOTE: fail silently here, as this is a nice-to-have feature
    // and the id is already set to idProp above
  }
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const themeProps = useTheme();
  const mergedSx = useMemo(
    () =>
      merge(
        baseTextComponentSx,
        { c: DEFAULT_HEADING_PROPS.color },
        getHeadingTextStyles({ themeProps, size, weight }),
        getMotionProfileSx(motionProfileToUse, themeProps),
        themeProps.components?.Heading?.sxOverride ?? {},
        sx,
      ),
    [sx, themeProps, size, weight, motionProfileToUse],
  );
  const css = useConvertSxToEmotionStyles(mergedSx);
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_HEADING_PROPS.size,
    Object.keys(themeProps.base.text.heading),
  );

  return cloneElementWithCssProp(rc, {
    ...headingDomAttributes,
    ...(testId ? { "data-testid": testId } : {}),
    ...(domRef ? { ref: domRef } : {}),
    css,
    id,
    children,
    className: `${className ?? ""} Heading Heading--${currentSizeClass}`,
  });
}

export function Heading<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_HEADING_PROPS.size,
  weight = DEFAULT_HEADING_PROPS.weight,
  rc = DEFAULT_HEADING_PROPS.rc,
  testId = "Heading",
  shimmer,
  shimmerSx,
  sx,
  children,
  domRef,
  className,
  id,
  autoGenerateId,
  motionProfile,
  ...headingDomAttributes
}: HeadingProps<RC>) {
  return shimmer ? (
    <MultiTextShimmer
      textKind="Heading"
      size={size}
      sx={shimmerSx}
      shimmerLines={shimmer}
      rc={rc}
      className={className}
      testId={`${testId}__shimmer`}
    />
  ) : (
    <HeadingContent
      {...headingDomAttributes}
      autoGenerateId={autoGenerateId}
      id={id}
      size={size}
      sx={sx}
      rc={rc}
      weight={weight}
      domRef={domRef}
      testId={testId}
      className={className}
      motionProfile={motionProfile}
    >
      {children}
    </HeadingContent>
  );
}

Heading.displayName = "Heading";
