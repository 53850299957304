import { AnimatePresence, motion } from "motion/react";
import { type MouseEvent, useCallback, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { ButtCon } from "@/components/Clickable/Buttons/ButtCon";
import { SmartClone } from "@/components/SmartClone";
import { Heading } from "@/components/Text";
import {
  DEFAULT_DRAWER_POSITION,
  DEFAULT_DRAWER_SIZE,
  DRAWER_POSITIONS,
  DRAWER_SIZES,
} from "@/constants";
import { useEventListener, useTheme } from "@/hooks";
import { CLOSE_DRAWER } from "@/providers/BiomeOverlaysProvider/actions";
import { useOverlaysStore } from "@/providers/BiomeOverlaysProvider/overlaysContext";
import { useWindowSizeStore } from "@/providers/BiomeWindowSize";
import type { AllDualVariantIconKeys } from "@/types";
import {
  centerFlexChildren,
  childHasSxProp,
  getCurrentSizeClass,
  getStartingSize,
  vFlex,
} from "@/utils";
import {
  BOTTOM_TRANSLATE,
  LEFT_TRANSLATE,
  NO_TRANSLATE,
  RIGHT_TRANSLATE,
  baseDrawerSx,
  headerBarButtConSx,
  headerBarSx,
} from "./styles";

export type DrawerOverlayProps = { hasExternalContainer?: boolean };

export function DrawerOverlay({ hasExternalContainer }: DrawerOverlayProps) {
  const theme = useTheme();
  const { base } = theme;
  const { state: windowWidth } = useWindowSizeStore((state) => state.width);
  const { state: drawerList, dispatchAction } = useOverlaysStore(
    (state) => state.drawerList,
  );
  const { state: modalList } = useOverlaysStore((state) => state.modalList);
  const currentDrawer = drawerList[drawerList.length - 1];
  const handleBgOverlayClick = useCallback(
    (ev: MouseEvent<HTMLDivElement>) => {
      if (currentDrawer?.outsideClicksClose) {
        currentDrawer.onCloseDrawer?.();
        if (!currentDrawer.isControlled) {
          dispatchAction({
            type: CLOSE_DRAWER,
            payload: {
              id: currentDrawer.id,
            },
          });
        }
      }
    },
    [currentDrawer, dispatchAction],
  );
  useEventListener(
    "keydown",
    (event: KeyboardEvent) => {
      if (
        event.key === "Escape" &&
        drawerList.length > 0 &&
        // @NOTE: when there is both Modals and Drawers open at the same time, first
        // allow Modals to close 1 at a time before then allowing the Drawers to close 1 at a time ...
        // (Modals sit above Drawers when it comes to z-index so it makes sense that they close first)
        modalList.length === 0
      ) {
        const topDrawer = drawerList[drawerList.length - 1];
        if (topDrawer?.escapeKeyClose) {
          topDrawer.onCloseDrawer?.();
          if (!topDrawer.isControlled) {
            dispatchAction({
              type: CLOSE_DRAWER,
              payload: {
                id: topDrawer.id,
              },
            });
          }
        }
      }
    },
    { current: document.body },
  );

  const mergedBgOverlaySx = useMemo(
    () =>
      merge(
        {
          pos: hasExternalContainer ? "absolute" : "fixed",
          top: 0,
          left: 0,
          w: "100%",
          h: "100%",
          bg: currentDrawer?.showBgOverlay ? base.color.overlay : "",
          zIndex: base.zLevel.drawer,
        },
        currentDrawer?.bgOverlaySx ?? {},
      ),
    [currentDrawer, base, hasExternalContainer],
  );

  return (
    <AnimatePresence>
      {currentDrawer ? (
        <Box
          sx={mergedBgOverlaySx}
          rc={
            <motion.div
              data-testid={`${currentDrawer.testId}__bgOverlay`}
              onClick={handleBgOverlayClick}
              {...(currentDrawer.motionProfile !== "none"
                ? {
                    initial: { opacity: 0 },
                    animate: { opacity: 1 },
                    exit: { opacity: 0 },
                    transition: {
                      duration:
                        base.motion.normal[currentDrawer.motionProfile]
                          .jsDuration,
                      ease: base.motion.normal[currentDrawer.motionProfile]
                        .jsEase,
                    },
                  }
                : {})}
            />
          }
        />
      ) : null}

      {drawerList.map((drawer) => {
        const drawerSx = childHasSxProp(drawer.content)
          ? drawer.content.props.sx
          : {};
        const { drawerPosition, size } = drawer;
        const startingSize = getStartingSize(
          size,
          DEFAULT_DRAWER_SIZE,
          DRAWER_SIZES,
        );
        const startingPosition = getStartingSize(
          drawerPosition,
          DEFAULT_DRAWER_POSITION,
          DRAWER_POSITIONS,
        );
        const drawerPositionClass = getCurrentSizeClass(
          drawerPosition,
          startingPosition,
          DRAWER_POSITIONS,
          theme.base.breakpointAsArray,
          windowWidth,
        );
        const drawerSizeClass = getCurrentSizeClass(
          size,
          startingSize,
          DRAWER_SIZES,
          theme.base.breakpointAsArray,
          windowWidth,
        );
        const mergedContainerSx = merge(
          baseDrawerSx,
          {
            position: hasExternalContainer ? "absolute" : "fixed",
          },
          drawer.containerSx ?? {},
        );
        return (
          <Box
            sx={mergedContainerSx}
            key={drawer.id}
            testId={`${drawer.testId}__container`}
            className={`Drawer Drawer--position_${drawerPositionClass} Drawer--size_${drawerSizeClass}`}
            rc={
              <motion.div
                {...(drawer.motionProfile !== "none"
                  ? {
                      initial: {
                        opacity: 0,
                        ...(drawerPositionClass === "left" && {
                          x: LEFT_TRANSLATE,
                        }),
                        ...(drawerPositionClass === "right" && {
                          x: RIGHT_TRANSLATE,
                        }),
                        ...(drawerPositionClass === "bottom" && {
                          y: BOTTOM_TRANSLATE,
                        }),
                      },
                      animate: {
                        opacity: 1,
                        ...(/left|right/.test(drawerPositionClass) && {
                          x: NO_TRANSLATE,
                        }),
                        ...(/bottom/.test(drawerPositionClass) && {
                          y: NO_TRANSLATE,
                        }),
                      },
                      exit: {
                        opacity: 0,
                        transition: {
                          ease: base.motion.normal[drawer.motionProfile].jsEase,
                        },
                        ...(drawerPositionClass === "left" && {
                          x: LEFT_TRANSLATE,
                        }),
                        ...(drawerPositionClass === "right" && {
                          x: RIGHT_TRANSLATE,
                        }),
                        ...(drawerPositionClass === "bottom" && {
                          y: BOTTOM_TRANSLATE,
                        }),
                      },
                      transition: {
                        duration:
                          base.motion.bounce[drawer.motionProfile].jsDuration,
                        ease: base.motion.bounce[drawer.motionProfile].jsEase,
                        opacity: {
                          ease: base.motion.normal[drawer.motionProfile].jsEase,
                        },
                      },
                    }
                  : {})}
              />
            }
          >
            {drawer.showHeaderBar && (
              <Box
                rc={<header />}
                sx={headerBarSx}
                testId={`${drawer.testId}__container__header`}
              >
                <ButtCon
                  testId={`${drawer.testId}__container__header__closeButtCon`}
                  variant="tertiary"
                  size="small"
                  icon={
                    (drawer.drawerCloseIcon ||
                      "Close") as AllDualVariantIconKeys
                  }
                  iconVariant={drawer.drawerCloseIconVariant || undefined}
                  sx={headerBarButtConSx}
                  onClick={() => {
                    drawer.onCloseDrawer?.();
                    if (!drawer.isControlled) {
                      dispatchAction({
                        type: CLOSE_DRAWER,
                        payload: {
                          id: drawer.id,
                        },
                      });
                    }
                  }}
                />

                <Heading
                  size="xSmall"
                  weight="bold"
                  testId={`${drawer.testId}__container__header__title`}
                  sx={{ ...vFlex, ...centerFlexChildren }}
                >
                  {drawer.headerBarTitle}
                </Heading>
              </Box>
            )}

            <SmartClone
              sx={merge(
                {
                  overflowX: "hidden",
                  overflowY: "auto",
                  flexGrow: 1,
                },
                drawerSx ?? {},
              )}
            >
              {drawer.content}
            </SmartClone>
          </Box>
        );
      })}
    </AnimatePresence>
  );
}
