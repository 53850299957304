import { useState } from "react";

import { useBrowserLayoutEffect } from "./useBrowserLayoutEffect";

export type UseDetectSvgImagePropTypes = {
  imageUrl?: string;
};

async function urlIsSvg(url: string) {
  try {
    const imageResponse = await fetch(url, { method: "HEAD" });
    return imageResponse.headers.get("content-type") === "image/svg+xml";
  } catch (err) {
    console.error("urlIsSvg() HEADERS Fetch Error:", url, err);
    return false;
  }
}

/**
 * This function detects whether an image URL is an SVG image or not.
 * @param {UseDetectSvgImagePropTypes}  - The function `useDetectSvgImage` takes in an object with a
 * single property `imageUrl`, which is a string representing the URL of an image. The function returns
 * a boolean value indicating whether the image at the given URL is an SVG image or not.
 * @returns The function `useDetectSvgImage` returns a boolean value indicating whether the provided
 * image URL is an SVG image or not.
 */
export function useDetectSvgImage({
  imageUrl,
}: UseDetectSvgImagePropTypes): boolean {
  const [imageIsSvg, setImageIsSvg] = useState<boolean>(false);

  useBrowserLayoutEffect(() => {
    if (imageUrl) {
      urlIsSvg(imageUrl).then((isSvg) => {
        setImageIsSvg(isSvg);
      });
    }
  }, [imageUrl]);

  return imageIsSvg;
}
