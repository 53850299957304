import { ClassNames } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { SmartClone } from "@/components/SmartClone";
import { Stack, type StackProps } from "@/components/Stack";
import {
  DEFAULT_CAROUSEL_THUMB_VARIANT,
  DEFAULT_MOTION_PROFILE,
} from "@/constants";
import {
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import type { MotionProfile } from "@/types";

import { getMotionProfileSx } from "@/utils";
import { CarouselThumbnailThumbImage } from "./CarouselThumbnailThumbImage";
import { CarouselThumbnailThumbText } from "./CarouselThumbnailThumbText";
import { baseThumbSx } from "./styles";
import type { CommonThumbProps } from "./types";

type CarouselThumbnailThumbProps<RC extends ReactElement | undefined> =
  StackProps<RC> &
    Partial<CommonThumbProps> & {
      motionProfile?: MotionProfile;
    };

const SPAN_RC = <span />;
export function CarouselThumbnailThumb<
  RC extends ReactElement | undefined = undefined,
>({
  index = 0,
  children,
  selected,
  variant = DEFAULT_CAROUSEL_THUMB_VARIANT,
  className,
  sx = {},
  rc = <button type="button" />,
  testId = "CarouselThumbnailThumb",
  motionProfile = DEFAULT_MOTION_PROFILE,
  ...props
}: CarouselThumbnailThumbProps<RC>) {
  const theme = useTheme();
  const image = useGetSubcomponentChild(children, CarouselThumbnailThumbImage);
  const text = useGetSubcomponentChild(children, CarouselThumbnailThumbText);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    CarouselThumbnailThumbImage,
    CarouselThumbnailThumbText,
  ]);
  const mergedSx = useMemo(
    () =>
      merge(
        baseThumbSx,
        {
          c: selected
            ? "base.color.text.body.primary"
            : "base.color.text.body.secondary",
          ...(variant === "horizontal"
            ? { aspectRatio: "16/9" }
            : { ".innerContainer": { p: "base.spacing.x3" } }),
          ...getMotionProfileSx(motionProfile, theme),
          "& .innerContainer": {
            ...getMotionProfileSx(motionProfile, theme),
          },
        },
        sx,
      ),
    [selected, sx, variant, motionProfile, theme],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <Stack
          {...props}
          rc={rc}
          sx={mergedSx}
          testId={testId}
          className={cx(className, "CarouselThumbnailThumb", {
            "CarouselThumbnailThumb--active": selected,
          })}
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            rc={SPAN_RC}
            className="innerContainer"
            direction="row"
            alignItems="center"
            gap={variant === "vertical" ? "base.spacing.x4" : "unset"}
            justifyContent={variant === "vertical" ? "flex-start" : "center"}
          >
            <SmartClone variant={variant}>
              {image || <CarouselThumbnailThumbImage />}
            </SmartClone>
            <SmartClone variant={variant}>
              {text ? (
                text
              ) : variant === "vertical" ? (
                <CarouselThumbnailThumbText>
                  Slide {index + 1}
                </CarouselThumbnailThumbText>
              ) : null}
            </SmartClone>
            {otherChildren}
          </Stack>
        </Stack>
      )}
    </ClassNames>
  );
}

CarouselThumbnailThumb.displayName = "CarouselThumbnail.Thumb";

CarouselThumbnailThumb.Image = CarouselThumbnailThumbImage;
CarouselThumbnailThumb.Text = CarouselThumbnailThumbText;
