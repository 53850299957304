import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";
import { SvgIcon } from "../SvgIcon";

export function DocsHorizontalLockupCompact({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent("DocsHorizontalLockupCompact");
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 130 48"
      testId={testId}
      sx={merge({ fill: "base.color.brand.1", w: "200px" }, sx)}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}
DocsHorizontalLockupCompact.displayName = "DocsHorizontalLockupCompact";
