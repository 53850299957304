import { createContext } from "react";

import { DEFAULT_TABLE_SPACING } from "@/constants";

import type { TableSettings } from "./shared";

export const TableContext = createContext<TableSettings>({
  verticalSpacing: DEFAULT_TABLE_SPACING,
  horizontalSpacing: DEFAULT_TABLE_SPACING,
  rowHoverFx: true,
  stripedBg: false,
  tableBorder: false,
  rowBorder: true,
  columnBorder: false,
  stickyHeaders: true,
  testId: "Table",
});
