import { designTokens } from "@biom3/design-tokens";
import isEqual from "lodash.isequal";
import { AnimatePresence, motion } from "motion/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { usePrevious } from "@/hooks/usePrevious";
import { CLOSE_POPOVER } from "@/providers/BiomeOverlaysProvider/actions";
import { useOverlaysStore } from "@/providers/BiomeOverlaysProvider/overlaysContext";
import { useWindowSizeStore } from "@/providers/BiomeWindowSize";
import type { DomReferenceRect, PopoverPosition } from "@/types";
import {
  getSafeBottomVerticalPosition,
  getSafeCenterHorizontalPosition,
  getSafeLeftHorizontalPosition,
  getSafeRightHorizontalPosition,
  getSafeTopVerticalPosition,
} from "@/utils/positionHelpers";

const DEFAULT_POSITION: PopoverPosition = {
  x: "center",
  y: "below",
};

export function PopoverOverlay() {
  const [contentRects, setContentRects] = useState<DomReferenceRect[]>([]);
  const localContentRefs = useRef<HTMLElement[]>([]);
  const { state: popoverList, dispatchAction } = useOverlaysStore(
    (state) => state.popoverList,
  );
  const { state: windowSize } = useWindowSizeStore((store) => store);
  const previousWindowSize = usePrevious(windowSize);
  const closeAllPopovers = useCallback(() => {
    popoverList.forEach((popover) => {
      popover.onOutsideClick?.();
      popover.onClosePopover?.();
      if (!popover.isControlled) {
        dispatchAction({
          type: CLOSE_POPOVER,
          payload: { id: popover.id },
        });
      }
    });
  }, [dispatchAction, popoverList]);

  // @NOTE: watch for resizes, and close all popovers
  // if the window size changes
  useEffect(() => {
    if (!isEqual(windowSize, previousWindowSize)) {
      closeAllPopovers();
    }
  }, [windowSize, previousWindowSize, closeAllPopovers]);

  // @NOTE: Trigger a re-render when the popover is opened, so that we can know
  // what it's content dimensions are
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setContentRects(
      localContentRefs.current.map((ref) => ref?.getBoundingClientRect()),
    );
  }, [localContentRefs, popoverList]);

  // @NOTE: close all popovers, when you click outside of them
  useOnClickOutside(
    [
      ...localContentRefs.current.map((ref) => ({ current: ref })),
      ...popoverList.map((popover) => popover.targetRef),
    ],
    closeAllPopovers,
  );

  const targetRects = useMemo(() => {
    return popoverList.map((popover) =>
      popover.targetRef?.current?.getBoundingClientRect(),
    );
  }, [popoverList]);

  const popoverPositioning = useMemo(() => {
    return popoverList.map((popover) => popover?.position || DEFAULT_POSITION);
  }, [popoverList]);

  const positions = useMemo(() => {
    return popoverList.map(({ verticalGap }, index) => {
      const targetRect = targetRects[index];
      const contentRect = contentRects[index];
      const position = {
        left:
          popoverPositioning[index]?.x === "center"
            ? getSafeCenterHorizontalPosition(targetRect, contentRect)
            : popoverPositioning[index]?.x === "left"
              ? getSafeLeftHorizontalPosition(targetRect, contentRect)
              : getSafeRightHorizontalPosition(targetRect, contentRect),
        top:
          popoverPositioning[index]?.y === "above"
            ? getSafeTopVerticalPosition(targetRect, contentRect, verticalGap)
            : getSafeBottomVerticalPosition(
                targetRect,
                contentRect,
                verticalGap,
              ),
      };
      return position;
    });
  }, [contentRects, popoverList, popoverPositioning, targetRects]);

  return (
    <AnimatePresence>
      {popoverList.map((popover, index) => {
        return (
          <motion.div
            key={popover.id}
            ref={(element) => {
              if (element) {
                localContentRefs.current[index] = element;
              }
            }}
            data-id={popover.id}
            style={{
              position: "absolute",
              ...positions[index],
            }}
            initial={{
              opacity: 0,
              y: "-5%",
            }}
            exit={{
              opacity: 0,
              y: "-5%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: designTokens.base.motion.normal.fast.jsDuration,
              ease: designTokens.base.motion.normal.fast.jsEase,
            }}
          >
            {popover?.content}
          </motion.div>
        );
      })}
    </AnimatePresence>
  );
}
