import { type Gradient, smartPickTokenValue } from "@biom3/design-tokens";
import {
  Children,
  type ReactElement,
  type ReactNode,
  isValidElement,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import { useTheme } from "@/hooks/useTheme";
import type { DomPropsWithDomRef, StandardComponentWithProps } from "@/types";
import { cloneElementWithCssProp } from "@/utils/componentHelpers";

export type SvgIconProps = StandardComponentWithProps<
  SVGSVGElement,
  {
    children: ReactNode;
    viewBox?: string;
  }
>;

export const CUSTOM_CSS_GRADIENT_ERROR =
  "<SvgIcon /> does not support fill styles that are css-gradients. Custom gradients must be converted into SVG gradient syntax.";
export const RESPONSIVE_GRADIENT_ERROR =
  "Due to the complexity involved, <SvgIcon /> does not yet support responsive gradients. Please only use a single gradient token. If this functionality is required, please indicate your interest in this functionality using the following jira ticket: https://immutable.atlassian.net/browse/DS-114.";

const GRADIENT_REGEX = /base\.gradient|linear-gradient|radial-gradient/g;
const DEFAULT_SX_PROPS = {
  fill: "base.color.text.body.primary",
  w: "base.icon.size.100",
};

export function SvgIcon<RC extends ReactElement | undefined = undefined>({
  testId,
  children,
  domRef,
  className,
  viewBox = "0 0 24 24",
  sx = {},
  rc = <svg />,
  ...svgDomAttributes
}: RC extends undefined
  ? DomPropsWithDomRef<"svg"> & SvgIconProps
  : SvgIconProps & { rc: RC }) {
  const themeProps = useTheme();
  let gradientToken: Gradient = {
    spectrum: "",
    blendMode: "",
    svgDefs: "",
    svgDefIds: "",
  };
  if (typeof sx.fill === "string") {
    if (sx.fill.includes("base.gradient")) {
      gradientToken = smartPickTokenValue<Gradient>(themeProps, sx.fill);
    } else if (sx.fill.match(/linear-gradient|radial-gradient/g)) {
      throw new Error(CUSTOM_CSS_GRADIENT_ERROR);
    }
  } else if (typeof sx.fill === "object") {
    // @TODO: we should eventually allow the ability to supply responsive gradients
    // but for now, we'll throw an error if we detect that the user is trying to do this
    let isArrayAndIncludesGradient = false;
    let isObjectAndIncludesGradient = false;
    if (Array.isArray(sx.fill)) {
      isArrayAndIncludesGradient = Boolean(
        sx.fill.find(
          (item) => typeof item === "string" && item.match(GRADIENT_REGEX),
        ),
      );
    } else {
      isObjectAndIncludesGradient = Boolean(
        Object.entries(sx.fill).find(
          ([, value]) =>
            typeof value === "string" && value.match(GRADIENT_REGEX),
        ),
      );
    }

    if (isArrayAndIncludesGradient || isObjectAndIncludesGradient) {
      throw new Error(RESPONSIVE_GRADIENT_ERROR);
    }
  }
  const { svgDefs, svgDefIds } = gradientToken;
  const svgDefIdsAsArray = svgDefIds?.split(",").map((id) => id.trim());
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const clonedChildren =
    svgDefIds?.length > 1
      ? Children.map(flattenedChildren, (child) => {
          return svgDefIdsAsArray.map((id: string) => {
            return isValidElement(child)
              ? cloneElementWithCssProp(child, {
                  key: id,
                  css: { fill: `url(#${id})` },
                })
              : null;
          });
        })
      : [children];

  const sxWithDefaults = useMemo(() => merge(DEFAULT_SX_PROPS, sx), [sx]);
  const customStyles = useConvertSxToEmotionStyles(sxWithDefaults);
  const modifiedChildren = useMemo(
    () => (
      <>
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
        {svgDefs && <defs dangerouslySetInnerHTML={{ __html: svgDefs }} />}
        {clonedChildren}
      </>
    ),
    [clonedChildren, svgDefs],
  );

  return cloneElementWithCssProp(rc, {
    ...svgDomAttributes,
    ...(testId ? { "data-testid": testId } : {}),
    ...(domRef ? { ref: domRef } : {}),
    viewBox,
    className: `SvgIcon ${className ?? ""}`,
    xmlns: "http://www.w3.org/2000/svg",
    css: customStyles,
    children: modifiedChildren,
  });
}

SvgIcon.displayName = "SvgIcon";
