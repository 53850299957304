import {
  baseContainerSx as cardContainerSx,
  framedLogoSx as cardFramedLogoSx,
} from "@/components/Card/styles";
import type { BiomeTheme } from "@/types";
import { hexToRgbaString, lineClamp, userSelect } from "@/utils";

export const baseContainerSx = {
  pos: cardContainerSx.pos,
  brad: cardContainerSx.brad,
  overflow: cardContainerSx.overflow,
  boxShadow: cardContainerSx.boxShadow,
  transitionProperty: cardContainerSx.transitionProperty,
  w: cardContainerSx.w,

  "&::before, &::after": cardContainerSx["&::before, &::after"],
  "&::after": cardContainerSx["&::after"],
  "&.CardHero--isClickable": cardContainerSx["&.Card--isClickable"],

  "&.CardHero--isClickable:hover": {
    boxShadow: "base.shadow.300",
    translate: ({ base }: BiomeTheme) => `0 -${base.spacing.x2}`,
  },

  "&.CardHero--isClickable:active":
    cardContainerSx["&.Card--isClickable:active"],

  "&.CardHero--selected::after": {
    borderColor: "base.color.translucent.standard.1000",
  },

  "&.CardHero--elevation1": {
    "&.CardHero--isClickable:hover": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.700",
      },
    },

    "&.CardHero--isClickable:active": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.800",
      },
    },

    "&.CardHero--selected&::before": {
      borderColor: "base.color.neutral.900",
    },
  },

  "&.CardHero--elevation2": {
    "&.CardHero--isClickable:hover": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.600",
      },
    },

    "&.CardHero--isClickable:active": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.700",
      },
    },

    "&.CardHero--selected&::before": {
      borderColor: "base.color.neutral.800",
    },
  },

  "&.CardHero--elevation3": {
    "&.CardHero--isClickable:hover": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.CardHero--isClickable:active": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.600",
      },
    },

    "&.CardHero--selected&::before": {
      borderColor: "base.color.neutral.700",
    },
  },

  "&.CardHero--elevation4": {
    "&.CardHero--isClickable:hover": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.CardHero--isClickable:active": {
      "&.CardHero--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.CardHero--selected&::before": {
      borderColor: "base.color.neutral.600",
    },
  },

  "&.CardHero--elevation5": {
    "&.CardHero--selected&::before": {
      borderColor: "base.color.neutral.500",
    },
  },
};

export const gradientScrimSx = {
  ...userSelect.none,
  pos: "absolute",
  bottom: "0",
  left: "0",
  w: "100%",
  h: "100%",
  bg: ({ base }: BiomeTheme) =>
    `linear-gradient(180deg, ${hexToRgbaString(
      base.color.fixed.black[1000],
      0,
    )} 0%, ${hexToRgbaString(base.color.fixed.black[1000], 0.9)} 90%)`,
};

export const baseAssetImageSx = {
  d: "block",
  w: "100%",
  h: "100%",
};

export const baseDescriptionSx = {
  ...lineClamp(4),
  textAlign: "left",
  c: "base.color.fixed.white.1000",
  mb: "base.spacing.x2",
};

export const baseTitleSx = {
  ...lineClamp(3),
  textAlign: "left",
  flexShrink: 0,
  c: "base.color.fixed.white.1000",
  gap: "base.spacing.x1",
};

export const baseInnerContentSx = {
  ...userSelect.none,
  pos: "absolute",
  bottom: "0",
  left: "0",
  w: "100%",
  pb: "var(--cardHeroInsetAmount)",
  px: "var(--cardHeroInsetAmount)",
  minh: "var(--textContainerMinHeight)",
  transitionProperty: "padding",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",

  "& > *": {
    ...userSelect.text,
  },
};

export const baseFramedSx = {
  transitionProperty: "top, left",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",
  top: "var(--cardHeroInsetAmount)",
  left: "var(--cardHeroInsetAmount)",
  pos: "absolute",
};

export const framedLogoContainerSx = {
  ...baseFramedSx,
};

export const framedLogoSx = cardFramedLogoSx;

export const chipBaseSx = {
  mb: "base.spacing.x1",
  backdropFilter: "base.frost.200",
};

export const buttonAndButtConBaseSx = {
  "&.Button--tertiary, &.Button--tertiary_inverse": {
    backdropFilter: "base.frost.200",
  },
};
