import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Modal } from "@/components/Modal";
import { ModalTarget } from "@/components/Modal/ModalTarget";
import { useTheme } from "@/hooks";
import type { ModalComponentProps } from "@/types";
import { ConfirmationDialogContent } from "./ConfirmationDialogContent";

export type ConfirmationDialogProps = Omit<
  ModalComponentProps,
  "ContentSubcomponent"
> & {};

export function ConfirmationDialog({
  bgOverlaySx = {},
  testId = "ConfirmationDialog",
  className,
  ...props
}: ConfirmationDialogProps) {
  const theme = useTheme();
  const mergedBgOverlaySx = useMemo(
    () =>
      merge(
        theme.components?.ConfirmationDialog?.bgOverlaySxOverride ?? {},
        bgOverlaySx,
      ),
    [bgOverlaySx, theme.components?.ConfirmationDialog?.bgOverlaySxOverride],
  );

  return (
    <Modal
      {...props}
      testId={testId}
      ContentSubcomponent={ConfirmationDialogContent}
      bgOverlaySx={mergedBgOverlaySx}
      className={`${className ?? ""} ConfirmationDialogTarget`}
    />
  );
}

ConfirmationDialog.displayName = "ConfirmationDialog";
ConfirmationDialog.Target = ModalTarget;
ConfirmationDialog.Content = ConfirmationDialogContent;
