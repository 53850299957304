import { ClassNames } from "@emotion/react";
import { type ReactElement, type ReactNode, useMemo, useRef } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Stack } from "@/components/Stack";
import { DEFAULT_TABLE_SPACING } from "@/constants";
import {
  useGetManySingleSubcomponentChildren,
  useGetSubcomponentChildren,
} from "@/hooks";
import type { DomPropsWithDomRef, StandardComponentWithProps } from "@/types";

import { TableBody } from "./TableBody";
import { TableCaption } from "./TableCaption";
import { TableCell } from "./TableCell";
import { TableFoot } from "./TableFoot";
import { TableHead } from "./TableHead";
import { TablePageSize } from "./TablePageSize";
import { TablePagination } from "./TablePagination";
import { TableRow } from "./TableRow";
import { TableContext } from "./context";
import type { TableSettings } from "./shared";
import { baseContainerSx, baseTableStyle } from "./styles";

export type TableProps = StandardComponentWithProps<
  HTMLTableElement,
  Partial<Omit<TableSettings, "setThWidths">> & {
    children: ReactNode;
    tableBorder?: boolean;
  }
>;

export function Table<RC extends ReactElement | undefined = undefined>({
  children,
  verticalSpacing = DEFAULT_TABLE_SPACING,
  horizontalSpacing = DEFAULT_TABLE_SPACING,
  rowHoverFx = true,
  stripedBg = false,
  tableBorder = false,
  rowBorder = true,
  columnBorder = false,
  stickyHeaders,
  testId = "TableContainer",
  sx = {},
  className,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & TableProps
  : TableProps & { rc: RC }) {
  const {
    subcomponents: [
      tableCaption,
      tableHead,
      tableBody,
      tableFoot,
      tablePagination,
      tablePageSize,
    ],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    {
      subcomponent: TableCaption,
    },
    {
      subcomponent: TableHead,
    },
    {
      subcomponent: TableBody,
    },
    {
      subcomponent: TableFoot,
    },
    {
      subcomponent: TablePagination,
    },
    {
      subcomponent: TablePageSize,
    },
  ]);
  const tableRows = useGetSubcomponentChildren(otherChildren, TableRow);
  const tableRef = useRef<HTMLTableElement>(null);
  const shouldUseStickyHeaders =
    typeof stickyHeaders === "boolean" ? stickyHeaders : Boolean(tableHead);

  const tableSettings = useMemo(() => {
    return {
      verticalSpacing,
      horizontalSpacing,
      rowHoverFx,
      stripedBg,
      tableBorder,
      rowBorder,
      columnBorder,
      stickyHeaders: shouldUseStickyHeaders,
      testId,
    };
  }, [
    testId,
    stripedBg,
    verticalSpacing,
    horizontalSpacing,
    tableBorder,
    rowBorder,
    rowHoverFx,
    columnBorder,
    shouldUseStickyHeaders,
  ]);

  return (
    <ClassNames>
      {({ cx }) => (
        <TableContext.Provider value={tableSettings}>
          <Box
            {...props}
            testId={testId}
            sx={merge(baseContainerSx, sx)}
            className={`${className ?? ""} Table`}
          >
            {tableCaption}

            <Box
              rc={<table ref={tableRef} />}
              sx={baseTableStyle}
              testId={`${testId}__table`}
              className={cx({
                rowHoverFx,
                stripedBg,
                tableBorder,
                columnBorder,
                rowBorder,
                stickyHeaders: shouldUseStickyHeaders,
              })}
            >
              {tableHead}
              {tableBody || <TableBody>{tableRows}</TableBody>}
              {tablePagination || tablePageSize ? (
                <TableFoot>
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {tablePageSize}
                        {tablePagination}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableFoot>
              ) : null}
              {tableFoot}
            </Box>
          </Box>
        </TableContext.Provider>
      )}
    </ClassNames>
  );
}

Table.displayName = "Table";

Table.Cell = TableCell;
Table.Row = TableRow;
Table.Body = TableBody;
Table.Head = TableHead;
Table.Foot = TableFoot;
Table.Caption = TableCaption;
Table.Pagination = TablePagination;
Table.PageSize = TablePageSize;
