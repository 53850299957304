import type { MakeResponsive, SafeSizes } from "@/types";
import { convertVariantToValidCssClass } from "./slugHelpers";

export function getCurrentSizeClass<Size extends string>(
  size: MakeResponsive<Size>,
  startingSize: Size,
  safeSizes: SafeSizes,
  breakpointAsArray: number[],
  windowWidth: number | null,
) {
  if (!windowWidth) {
    return startingSize;
  }

  if (Array.isArray(size)) {
    const actualResponsiveSizes = [...size];
    actualResponsiveSizes.shift();
    return actualResponsiveSizes.reduce((accum, curr, index) => {
      let newAccum = accum;
      const breakpoint = breakpointAsArray[index];
      if (
        curr !== null &&
        typeof breakpoint === "number" &&
        windowWidth >= breakpoint
      ) {
        newAccum = curr;
      }
      return newAccum;
    }, startingSize) as Size;
  }

  // @TODO: work out how to type the below function, so that it keeps the types of the input
  // but simply swaps "/" for "_". This should be possible with some ts magic:
  // https://www.totaltypescript.com/writing-string-replace-in-typescript
  return convertVariantToValidCssClass(
    safeSizes.indexOf(size) !== -1 ? size : startingSize,
  );
}
