import type {
  BiomeTheme,
  DeeplyNestedSx,
  ModalPosition,
  MotionPattern,
  MotionProfile,
} from "@/types";

function scaleWithBounce(
  motionProfile: Exclude<MotionProfile, "none">,
  base: BiomeTheme["base"],
) {
  return {
    initial: { scale: 0.8, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: {
      scale: 0.8,
      opacity: 0,
      transition: { ease: base.motion.normal[motionProfile].jsEase },
    },
    transition: {
      duration: base.motion.bounce[motionProfile].jsDuration,
      ease: base.motion.bounce[motionProfile].jsEase,
      opacity: { ease: base.motion.normal[motionProfile].jsEase },
    },
  };
}

function fade(
  motionProfile: Exclude<MotionProfile, "none">,
  base: BiomeTheme["base"],
) {
  return {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: {
      duration: base.motion.normal[motionProfile].jsDuration,
      ease: base.motion.normal[motionProfile].jsEase,
    },
  };
}

export function getModalAnimationProps(
  motionProfile: MotionProfile,
  motionPattern: MotionPattern,
  base: BiomeTheme["base"],
) {
  switch (motionProfile) {
    case "none":
      return {};

    case "fast":
    case "gentle":
      return motionPattern === "fade"
        ? fade(motionProfile, base)
        : scaleWithBounce(motionProfile, base);
  }
}

export function getModalContainerPositionSx(position: ModalPosition) {
  const sx: DeeplyNestedSx = {};
  switch (position.x) {
    case "left":
      sx.justifyContent = "flex-start";
      break;
    case "right":
      sx.justifyContent = "flex-end";
      break;
    case "center":
      sx.justifyContent = "center";
      break;
  }
  switch (position.y) {
    case "top":
      sx.alignItems = "flex-start";
      break;
    case "bottom":
      sx.alignItems = "flex-end";
      break;
    case "center":
      sx.alignItems = "center";
      break;
  }
  return sx;
}
