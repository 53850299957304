import type { ReactElement } from "react";

import type { TextInputStatefulButtConProps } from "@/types";
import { StatefulButtCon } from "../Clickable";

export function TextInputStatefulButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  currentInputValue,
  sx = {},
  ...props
}: TextInputStatefulButtConProps<RC>) {
  return (
    <StatefulButtCon {...props} size="small" sx={sx}>
      {children}
    </StatefulButtCon>
  );
}

TextInputStatefulButtCon.displayName = "TextInput.StatefulButtCon";
TextInputStatefulButtCon.SvgIcon = StatefulButtCon.SvgIcon;
