import type { Properties } from "csstype";

import { INPUT_MIN_WIDTH } from "@/constants";
import type {
  BiomeTheme,
  DeeplyNestedSx,
  InputTextAlign,
  InputValidationStatus,
  MakeResponsive,
  TextInputSize,
} from "@/types";
import { type RenderSxProps, getResponsiveSx } from "@/utils/styleHelpers";

export const inputContainerBaseSx = {
  pos: "relative",
  minw: INPUT_MIN_WIDTH,
  flexShrink: 0,
  gap: "0px",

  "& > *": {
    pos: "absolute",
  },
};

export const sideContainerSx = {
  top: "50%",
  transform: "translateY(-50%)",
};
export const leftContainerSx = {
  left: "base.spacing.x3",
  pointerEvents: "none",
};

export const rightButtonsContainerSx = {
  right: "base.spacing.x3",
};

export const clearValueButtonSx: DeeplyNestedSx = {
  mx: ({ base }) => `calc(${base.spacing.x1} * -1)`,
  pointerEvents: "auto",
};

export const baseInputSx = {
  appearance: "none",
  w: "100%",
  h: "100%",
  border: "none",
  brad: "base.borderRadius.x4",
  fontFamily: "base.font.family.body.primary",
  fontSize: "base.text.body.medium.regular.fontSize",
  lineHeight: "base.text.body.medium.regular.lineHeight",
  fontWeight: "base.text.body.medium.regular.fontWeight",
  c: "base.color.text.body.primary",
  transitionProperty: "box-shadow, background",

  "&:not(:disabled):hover": {
    bgc: "base.color.translucent.emphasis.200",
  },

  "&:not(:disabled):focus": {
    bgc: "base.color.translucent.emphasis.300",
  },

  "&:-webkit-autofill": {
    backgroundClip: "text",
    textFillColor: "base.color.text.body.primary",
    // @NOTE: the below seems to disable webkit autofill bg color styles
    transition: "background-color 5000s ease-in-out 0s",
  },
};

export const inputIconBaseSx = {
  // @NOTE: dont abbreviate these 2 rules - as doing so can conflict
  // with the sizing of Select.Option.FramedImage and Select.DefaultFramedImage
  // (which are sized responsively, using "width" & "height", not "w" and "h")
  width: "base.icon.size.300",
  height: "base.icon.size.300",
  // ----------------------------------------------------------
  bg: "transparent",
  fill: "base.color.text.body.primary",
  left: "base.spacing.x3",
};

export const adornmentSx = {
  c: "base.color.text.body.primary",
  lineHeight: "unset",
};

export function renderInputSx({
  validationStatus,
  leftHandButtonsWidth,
  rightHandButtonsWidth,
  textAlign,
  theme,
  hasValue,
}: {
  validationStatus: InputValidationStatus | undefined;
  rightHandButtonsWidth: number;
  leftHandButtonsWidth: number;
  textAlign: InputTextAlign;
  theme: BiomeTheme;
  hasValue: boolean;
}) {
  const paddingLeft =
    leftHandButtonsWidth > 0
      ? `calc(${leftHandButtonsWidth}px + ${theme.base.spacing.x5})`
      : theme.base.spacing.x3;
  const paddingRight =
    rightHandButtonsWidth > 0
      ? `calc(${rightHandButtonsWidth}px + ${theme.base.spacing.x5})`
      : theme.base.spacing.x3;
  const hoverShadow = theme.base.shadow[300];
  const focusShadow = theme.base.shadow[200];
  const emptyShadow = "0 0 0 rgba(0,0,0,0)";

  return {
    paddingLeft,
    paddingRight,
    textAlign,
    background: hasValue
      ? theme.base.color.translucent.emphasis[200]
      : theme.base.color.translucent.emphasis[100],

    boxShadow:
      validationStatus === "error"
        ? `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.status.fatal.bright}`
        : hasValue
          ? `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[800]}`
          : `${emptyShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[500]}`,

    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      // @NOTE: this hides the up and down arrows on number inputs
      display: "none",
    },

    "&::placeholder": {
      color: theme.base.color.text.body.secondary,
    },

    "&:not(:disabled):hover": {
      background: hasValue
        ? theme.base.color.translucent.emphasis[300]
        : theme.base.color.translucent.emphasis[200],
      boxShadow:
        validationStatus === "error"
          ? `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.status.fatal.bright}`
          : `${hoverShadow}, inset 0 0 0 ${theme.base.border.size[100]} ${theme.base.color.translucent.standard[800]}`,
    },

    "&:not(:disabled):focus": {
      outline: "none",
      background: theme.base.color.translucent.emphasis[300],
      boxShadow:
        validationStatus === "error"
          ? `${focusShadow}, inset 0 0 0 ${theme.base.border.size[200]} ${theme.base.color.status.fatal.bright}`
          : `${focusShadow}, inset 0 0 0 ${theme.base.border.size[200]} ${theme.base.color.translucent.standard[1000]}`,
    },
  };
}

export const renderResponsiveInputSx = (props: {
  validationStatus: InputValidationStatus | undefined;
  leftHandButtonsWidth: number;
  rightHandButtonsWidth: number;
  textAlign: InputTextAlign;
  theme: BiomeTheme;
  size: MakeResponsive<TextInputSize>;
  hasValue: boolean;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: renderInputSx as unknown as (
      props: RenderSxProps<TextInputSize>,
    ) => Properties,
  });

export function renderContainerStyles({
  size,
  theme,
}: {
  size: TextInputSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "large":
      return {
        height: theme.base.spacing.x16,
      };

    case "medium":
    default:
      return {
        height: theme.base.spacing.x12,
      };
  }
}

export const renderResponsiveContainerStyles = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<TextInputSize>;
}) => {
  const sx = getResponsiveSx({
    ...props,
    renderSxAtSize: renderContainerStyles,
  });
  return sx;
};
