import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import type { DeeplyNestedSx, FramedImageProps } from "@/types";

import { FramedImage } from "../FramedThings";
import { framedComponentSx } from "./styles";

export function StickerFramedImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  sx = {},
  emphasized = true,
  className,
  use,
  size = "small",
  ...props
}: FramedImageProps<RC, Use>) {
  const mergedSx = useMemo(
    () =>
      merge(
        framedComponentSx,
        {
          ...(emphasized
            ? ({
                boxShadow: ({ base }) =>
                  `0 0 0 ${base.border.size[200]} ${base.color.translucent.inverse[1000]}`,
              } as DeeplyNestedSx)
            : {}),
        },
        sx,
      ),
    [sx, emphasized],
  );
  return (
    <FramedImage
      {...props}
      use={use}
      sx={mergedSx}
      size={size}
      emphasized={emphasized}
      className={`${className ?? ""} StickerFramedImage`}
    />
  );
}

StickerFramedImage.displayName = "Sticker.FramedImage";
