import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { type CardHeroProps, HeroCard } from "@/components/HeroCard";
import type { OnClickFunction } from "@/types";

import { slideSx } from "./styles";

type CarouselThumbnailSlideProps<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
> = CardHeroProps<RC, OnClick>;

export function CarouselThumbnailSlide<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({
  sx = {},
  aspectRatio,
  className,
  testId = "CarouselThumbnailSlide",
  rc,
  ...props
}: CarouselThumbnailSlideProps<RC, OnClick>) {
  const mergedSx = useMemo(() => merge(slideSx, sx), [sx]);
  return (
    <HeroCard
      {...props}
      testId={testId}
      sx={mergedSx}
      rc={rc}
      aspectRatio={aspectRatio}
      className={`${className ?? ""} CarouselThumbnailSlide`}
    />
  );
}

CarouselThumbnailSlide.displayName = "CarouselThumbnail.Slide";
CarouselThumbnailSlide.AssetImage = HeroCard.AssetImage;
CarouselThumbnailSlide.AssetVideo = HeroCard.AssetVideo;
CarouselThumbnailSlide.Title = HeroCard.Title;
CarouselThumbnailSlide.Description = HeroCard.Description;
CarouselThumbnailSlide.Caption = HeroCard.Caption;
CarouselThumbnailSlide.ButtCon = HeroCard.ButtCon;
CarouselThumbnailSlide.Button = HeroCard.Button;
CarouselThumbnailSlide.FramedLogo = HeroCard.FramedLogo;
CarouselThumbnailSlide.FramedImage = HeroCard.FramedImage;
CarouselThumbnailSlide.Chip = HeroCard.Chip;
