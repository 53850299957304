import {
  type MakeValidSxValue,
  Stack,
  useIntersectionObserver,
} from "@biom3/react";
import { type ReactNode, Suspense } from "react";

export function RenderOnceInView({
  children,
  placeHolderHeight = "200px",
}: {
  children: ReactNode;
  placeHolderHeight?: MakeValidSxValue<string>;
}) {
  const { domRef, isIntersecting } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  return (
    <Stack
      domRef={domRef}
      className="RenderOnceInView"
      sx={{ minh: placeHolderHeight }}
      justifyContent="flex-start"
      alignItems="stretch"
      gap="0px"
    >
      {
        isIntersecting ? (
          <Suspense
          // @TODO: add this once the designers have had a crack
          // https://immutable.atlassian.net/browse/ENG-887
          // fallback={<Heading sx={{ c: "gold" }}>Loading component...</Heading>}
          >
            {children}
          </Suspense>
        ) : null
        // @TODO: add this once the designers have had a crack
        // https://immutable.atlassian.net/browse/ENG-887
        // <Heading sx={{ c: "gold" }}>Placeholder content...</Heading>
      }
    </Stack>
  );
}
