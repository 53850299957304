import type { ReactElement } from "react";

import type { ButtonProps } from "@/types";
import { Button } from "../Clickable";

export type ToastButtonProps<RC extends ReactElement | undefined = undefined> =
  ButtonProps<RC>;

export function ToastButton<RC extends ReactElement | undefined = undefined>({
  size = "small",
  variant = "tertiary/inverse",
  ...props
}: ToastButtonProps<RC>) {
  return <Button {...props} size={size} variant={variant} />;
}

ToastButton.displayName = "Toast.Button";
ToastButton.Icon = Button.Icon;
ToastButton.Logo = Button.Logo;
