import { ClassNames, type DistributiveOmit } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from "@/constants";
import { useTheme } from "@/hooks";
import type { ButtConProps } from "@/types";
import {
  getStartingSize,
  setDefaultSxBackgroundColor,
} from "@/utils/styleHelpers";

import { ButtCon } from "@/components/Clickable";
import {
  buttonBaseSxProps,
  getButtonStyles,
  renderResponsiveButtonStyles,
} from "./styles";

export type HorizontalMenuButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "variant"> & { selected?: boolean };

export function HorizontalMenuButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  size = "medium",
  selected,
  className,
  ...props
}: HorizontalMenuButtConProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);
  const mergedSx = useMemo(
    () =>
      merge(
        buttonBaseSxProps,
        getButtonStyles({ size: startingSize, theme }),
        renderResponsiveButtonStyles({
          size,
          theme,
        }),
        setDefaultSxBackgroundColor(sx, "base.color.translucent.emphasis.100"),
        sx,
      ),
    [sx, startingSize, size, theme],
  );

  return (
    <ClassNames>
      {({ cx }) => (
        <ButtCon
          {...props}
          size={size}
          variant="tertiary"
          sx={mergedSx}
          className={cx(className, "HorizontalMenuButtCon", { selected })}
        />
      )}
    </ClassNames>
  );
}

HorizontalMenuButtCon.displayName = "HorizontalMenuButtCon";
HorizontalMenuButtCon.SvgIcon = ButtCon.SvgIcon;
