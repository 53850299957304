import {
  type ComponentType,
  cloneElement,
  useCallback,
  useEffect,
  useId,
  useMemo,
} from "react";
import { merge } from "ts-deepmerge";

import {
  useControlledOverlay,
  useForwardLocalDomRef,
  useGetMotionProfile,
  useGetSubcomponentChild,
  useTheme,
} from "@/hooks";
import {
  CLOSE_MODAL,
  OPEN_MODAL,
  UPDATE_MODAL_PROPS,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { ModalComponentProps, ModalContentProps } from "@/types";

import { SmartClone } from "../SmartClone";
import { ModalContent } from "./ModalContent";
import { ModalTarget } from "./ModalTarget";

export function Modal({
  visible,
  id: idProp,
  children,
  domRef = { current: null },
  onCloseModal,
  showBgOverlay = true,
  bgOverlaySx = {},
  fullScreenUntilBreakpoint,
  outsideClicksClose = true,
  escapeKeyClose = true,
  position = { x: "center", y: "center" },
  testId = "modal",
  ContentSubcomponent = ModalContent,
  className,
  motionProfile,
  motionPattern = "scaleWithBounce",
}: ModalComponentProps) {
  const theme = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  // @NOTE: Move domRef to the Target element, by doing:
  // const localDomRef = useForwardLocalDomRef(target?.props?.domRef || { current: null });
  const target = useGetSubcomponentChild(children, ModalTarget);
  const localDomRef = useForwardLocalDomRef(domRef);
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const content = useGetSubcomponentChild(
    children,
    ContentSubcomponent as ComponentType<ModalContentProps<undefined>>,
  );

  const { state: modalList, dispatchAction } = useOverlaysStore(
    (state) => state.modalList,
  );
  const isControlled = useMemo(() => typeof visible !== "undefined", [visible]);
  const mergedBgOverlaySx = useMemo(
    () =>
      merge(theme.components?.Modal?.bgOverlaySxOverride ?? {}, bgOverlaySx),
    [bgOverlaySx, theme.components?.Modal?.bgOverlaySxOverride],
  );

  const modalProps = useMemo(
    () => ({
      id,
      content: content ? cloneElement(content, { id }) : null,
      targetRef: localDomRef,
      showBgOverlay,
      isControlled,
      onCloseModal,
      escapeKeyClose,
      outsideClicksClose,
      bgOverlaySx: mergedBgOverlaySx,
      fullScreenUntilBreakpoint,
      position,
      testId,
      motionProfile: motionProfileToUse,
      motionPattern,
    }),
    [
      content,
      fullScreenUntilBreakpoint,
      id,
      isControlled,
      localDomRef,
      mergedBgOverlaySx,
      onCloseModal,
      outsideClicksClose,
      escapeKeyClose,
      position,
      showBgOverlay,
      testId,
      motionProfileToUse,
      motionPattern,
    ],
  );

  // @NOTE: this useEffect allows the Modal props to stay up to date,
  // as the application re-renders and things potentially change
  useEffect(() => {
    dispatchAction({
      type: UPDATE_MODAL_PROPS,
      payload: modalProps,
    });
  }, [dispatchAction, modalProps]);

  const closeModal = useCallback(() => {
    dispatchAction({
      type: CLOSE_MODAL,
      payload: { id },
    });
    onCloseModal?.();
  }, [dispatchAction, onCloseModal, id]);

  const showModal = useCallback(() => {
    dispatchAction({
      type: OPEN_MODAL,
      payload: modalProps,
    });
  }, [dispatchAction, modalProps]);

  // @NOTE: Allow Modal to be controlled:
  useControlledOverlay({
    id,
    visible,
    showFunction: showModal,
    hideFunction: closeModal,
    overlayList: modalList,
  });

  const onClickProps = useMemo(() => {
    return !isControlled
      ? {
          onClick: () => {
            if (modalList.find((modal) => modal.id === id)) {
              closeModal();
            } else {
              showModal();
            }
          },
        }
      : {};
  }, [modalList, isControlled, id, closeModal, showModal]);

  return target ? (
    <SmartClone
      {...onClickProps}
      id={id}
      domRef={localDomRef}
      testId={testId}
      className={`${className ?? ""} ModalTarget`}
    >
      {target}
    </SmartClone>
  ) : null;
}

Modal.displayName = "Modal";
Modal.Content = ModalContent;
Modal.Target = ModalTarget;
