import { type ReactElement, useCallback, useMemo, useState } from "react";
import { merge } from "ts-deepmerge";

import { HorizontalMenu } from "@/components/HorizontalMenu";
import { Icon } from "@/components/Icon";
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from "@/constants";
import {
  useCheckForInvalidPaginationProps,
  useGetCurrentSizeClass,
  useTheme,
} from "@/hooks";
import type {
  ButtonSize,
  DeeplyNestedSx,
  DomPropsWithDomRef,
  MakeResponsive,
  PaginationDiscriminatedUnion,
  StandardComponentWithProps,
} from "@/types";
import { isNumberWithinTotal, range } from "@/utils";

type BasePaginationProps = StandardComponentWithProps<
  HTMLDivElement,
  PaginationDiscriminatedUnion<{
    siblingPages?: number;
    boundaryPages?: number;
    showArrows?: boolean;
    size?: MakeResponsive<ButtonSize>;
    buttonSx?: DeeplyNestedSx;
  }>
>;

export type PaginationProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"div"> & BasePaginationProps
    : BasePaginationProps & { rc: RC };

const DOTS = "dots";

export function Pagination<RC extends ReactElement | undefined = undefined>(
  props: PaginationProps<RC>,
) {
  const {
    domRef,
    testId,
    sx = {},
    buttonSx = {},
    currentPage,
    defaultPage = 1,
    onPageChange,
    totalPages = 10,
    siblingPages = 1,
    boundaryPages = 1,
    showArrows = true,
    disabled = false,
    size = DEFAULT_BUTTON_SIZE,
    className,
    ...otherProps
  } = "currentPage" in props
    ? { ...props, defaultPage: undefined }
    : { ...props, currentPage: undefined };

  const theme = useTheme();
  const errorState = useCheckForInvalidPaginationProps("Pagination", {
    defaultPage,
    totalPages,
    currentPage,
  });
  const [uncontrolledPageIndex, setUncontrolledPageIndex] = useState<number>(
    typeof currentPage === "number"
      ? currentPage
      : isNumberWithinTotal(defaultPage, totalPages)
        ? defaultPage
        : 1,
  );

  const currentPageIndex = currentPage ?? uncontrolledPageIndex;
  const atStart = currentPageIndex === 1;
  const atEnd = currentPageIndex === totalPages;

  const paginationRange = useMemo((): (number | "dots")[] => {
    const totalPageButtons = siblingPages * 2 + 3 + boundaryPages * 2;
    if (totalPageButtons >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(
      currentPageIndex - siblingPages,
      boundaryPages,
    );
    const rightSiblingIndex = Math.min(
      currentPageIndex + siblingPages,
      totalPages - boundaryPages,
    );

    const shouldShowLeftDots = leftSiblingIndex > boundaryPages + 2;
    const shouldShowRightDots =
      rightSiblingIndex < totalPages - (boundaryPages + 1);

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = siblingPages * 2 + boundaryPages + 2;
      return [
        ...range(1, leftItemCount),
        DOTS,
        ...range(totalPages - (boundaryPages - 1), totalPages),
      ];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = boundaryPages + 1 + 2 * siblingPages;
      return [
        ...range(1, boundaryPages),
        DOTS,
        ...range(totalPages - rightItemCount, totalPages),
      ];
    }

    return [
      ...range(1, boundaryPages),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(totalPages - boundaryPages + 1, totalPages),
    ];
  }, [siblingPages, boundaryPages, totalPages, currentPageIndex]);

  const handlePageClick = useCallback(
    (newPage: number) => () => {
      if (typeof currentPage !== "number") {
        setUncontrolledPageIndex(newPage);
      }
      onPageChange?.(newPage);
    },
    [onPageChange, currentPage],
  );

  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_BUTTON_SIZE,
    BUTTON_SIZES,
  );

  return errorState ? null : (
    <HorizontalMenu
      {...otherProps}
      size={size}
      domRef={domRef}
      testId={testId}
      sx={merge(
        { flexWrap: "wrap", justifyContent: "center" },
        theme.components?.Paginaiton?.sxOverride || {},
        sx,
      )}
      className={`${className ?? ""} Pagination Pagination--${sizeClass}`}
    >
      {!atStart && showArrows && (
        <HorizontalMenu.ButtCon
          disabled={disabled}
          size={size}
          iconVariant="bold"
          icon="ChevronBackward"
          onClick={handlePageClick(currentPageIndex - 1)}
          className="paginationArrow paginationArrow--previous"
          testId={`${testId}__arrow--previous`}
          sx={merge({ maxw: "32px" }, buttonSx)}
        />
      )}
      {paginationRange.map((page, index) => {
        const safeIndex = index.toString();
        return page === "dots" ? (
          <Icon
            key={`${page}-${safeIndex}-icon`}
            testId={`${testId}__dots--${safeIndex}`}
            icon="More"
            sx={{
              mx: "base.spacing.x1",
              w:
                size === "large"
                  ? "base.icon.size.300"
                  : size === "medium"
                    ? "base.icon.size.250"
                    : "base.icon.size.200",
            }}
          />
        ) : (
          <HorizontalMenu.Button
            disabled={disabled}
            testId={`${testId}__page--${page}`}
            key={`${page}-${safeIndex}-button`}
            selected={page === currentPageIndex}
            onClick={handlePageClick(page)}
            size={size}
            sx={merge({}, buttonSx)}
          >
            {page}
          </HorizontalMenu.Button>
        );
      })}
      {!atEnd && showArrows && (
        <HorizontalMenu.ButtCon
          disabled={disabled}
          icon="ChevronForward"
          iconVariant="bold"
          size={size}
          onClick={handlePageClick(currentPageIndex + 1)}
          className="paginationArrow paginationArrow--next"
          testId={`${testId}__arrow--next`}
          sx={merge({ maxw: "32px" }, buttonSx)}
        />
      )}
    </HorizontalMenu>
  );
}

Pagination.displayName = "Pagination";
