export const lineClamp = (lines = 2) => ({
  display: "-webkit-box",
  WebkitLineClamp: lines,
  WebkitBoxOrient: "vertical",
  wordBreak: "break-word",
  overflow: "hidden",
});

export const hFlex = { d: "flex" };
export const vFlex = { d: "flex", flexDirection: "column" };
export const centerFlexChildren = {
  alignItems: "center",
  justifyContent: "center",
};
export const padding = (value: number | string) => ({
  p: `base.spacing.x${value}`,
});
export const margin = (value: number | string) => ({
  m: `base.spacing.x${value}`,
});
export const userSelect = {
  none: {
    userSelect: "none",
    pointerEvents: "none",
  },
  auto: {
    userSelect: "auto",
    pointerEvents: "auto",
  },
  all: {
    userSelect: "all",
    pointerEvents: "auto",
  },
  text: {
    userSelect: "text",
    pointerEvents: "auto",
  },
};
