import debounce from "lodash.debounce";
import { type RefObject, useEffect, useState } from "react";

export type ResizeState = {
  width: number;
  height: number;
};

/**
 * This is a TypeScript function that uses the ResizeObserver API to track changes in the dimensions of
 * a specified HTML element and returns the updated dimensions.
 * @param elementRef - A reference to the DOM element that you want to observe for changes in size.
 * @param [debounceDuration=60] - `debounceDuration` is an optional parameter that specifies the
 * duration (in milliseconds) to wait before updating the dimensions after a resize event. It is used
 * to prevent the `setDimensions` function from being called too frequently and causing performance
 * issues. The default value is 60 milliseconds.
 * @returns The `useResizeObserver` hook returns an object containing the current dimensions of the
 * observed element, which includes its width and height.
 */
export function useResizeObserver(
  elementRef: RefObject<HTMLElement | null>,
  debounceDuration = 60,
) {
  const [dimensions, setDimensions] = useState<ResizeState>({
    width: 0,
    height: 0,
  });

  const debouncedSetDimensions = debounce(
    (entries: ResizeObserverEntry[]) => {
      const [entry] = entries;

      if (!entry) return;

      const newWidth =
        entry.borderBoxSize[0]?.inlineSize || elementRef?.current?.offsetWidth;
      const newHeight =
        entry.borderBoxSize[0]?.blockSize || elementRef?.current?.offsetHeight;

      if (!newWidth || !newHeight) return;

      // @NOTE: IMPORTANT do not spam the state with useless updates
      if (newWidth === dimensions.width && newHeight === dimensions.height) {
        return;
      }

      setDimensions({ width: newWidth, height: newHeight });
    },
    debounceDuration,
    { leading: true },
  );

  useEffect(() => {
    const observer = new ResizeObserver(debouncedSetDimensions);
    if (elementRef.current) {
      observer.observe(elementRef.current as Element);
    }
    return () => {
      observer.disconnect();
      debouncedSetDimensions.cancel();
    };
    // @NOTE: we do not include `debouncedSetDimensions` in the dependency array -
    // becuase when we do, it causes the useEffect to trigger constantly
  }, [elementRef, debouncedSetDimensions]);

  return dimensions;
}
