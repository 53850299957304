import type { Properties } from "csstype";

import type { BiomeTheme, ImageSizeVariant, MakeResponsive } from "@/types";
import { getResponsiveSx } from "@/utils/styleHelpers";

const maskImagePrefix = `url('data:image/svg+xml;utf8,`;
const svgXmlns = `xmlns="http://www.w3.org/2000/svg"`;

export const swapContainerSx = {
  pos: "relative",
  d: "block",
  // @TODO: might be able to use this to support "emphasized" prop
  // (needs a bit more work to get the drop shadow to look right)
  // filter: `drop-shadow(1px 0px 0px gold)
  //   drop-shadow(0px 1px 0px gold)
  //   drop-shadow(-1px 0px 0px gold)
  //   drop-shadow(0px -1px 0px gold)`,

  "&.SwapStack--left.SwapStack--circle.SwapStack--small > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 16 16" ${svgXmlns}><path d="M0.0618896 8.99951C0.553793 12.946 3.92028 15.9999 8.00005 15.9999C12.4183 15.9999 16.0001 12.4182 16.0001 7.99995C16.0001 3.92013 12.9461 0.55361 8.99947 0.0617676C8.96625 4.98305 4.98315 8.9662 0.0618896 8.99951Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--circle.SwapStack--medium > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 20 20" ${svgXmlns}><path d="M0.0706147 8.80541C0.0239869 9.19711 0 9.59575 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C9.59575 0 9.19711 0.0239869 8.80541 0.0706147C7.96454 4.48525 4.48525 7.96454 0.0706147 8.80541Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--circle.SwapStack--large > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}><path d="M0.0307617 17.0002C0.547045 25.3708 7.49936 32 16 32C24.8366 32 32 24.8366 32 16C32 7.49934 25.3708 0.547005 17.0001 0.0307617C16.9836 9.39535 9.39534 16.9837 0.0307617 17.0002Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--circle.SwapStack--xLarge > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 48 48" ${svgXmlns}><path d="M1.40918 32.1222C4.73812 41.3799 13.5959 48.0001 24 48.0001C37.2548 48.0001 48 37.2549 48 24.0001C48 13.5959 41.3797 4.73805 32.1219 1.40918C32.6943 3.509 32.9999 5.71887 32.9999 8.00011C32.9999 21.8072 21.807 33.0001 7.99986 33.0001C5.71871 33.0001 3.50892 32.6946 1.40918 32.1222Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--square.SwapStack--small > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 16 16" ${svgXmlns}><path d="M5.0541e-05 8.99972V13.9999C5.0541e-05 15.1045 0.895481 15.9999 2.00005 15.9999H14.0001C15.1046 15.9999 16.0001 15.1045 16.0001 13.9999V1.99995C16.0001 0.895376 15.1046 -5.44265e-05 14.0001 -5.44265e-05H8.99968V3.99972C8.99968 6.76114 6.76111 8.99972 3.99968 8.99972H5.0541e-05Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--square.SwapStack--medium > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 20 20" ${svgXmlns}><path d="M0 9V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H9V4C9 6.76142 6.76142 9 4 9H0Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--square.SwapStack--large > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}><path d="M8.74698e-06 17.0002V28C8.74698e-06 30.2092 1.79087 32 4.00001 32H28C30.2091 32 32 30.2092 32 28V4.00001C32 1.79087 30.2091 1.38581e-05 28 1.38581e-05H17.0001V12.0002C17.0001 14.7616 14.7616 17.0002 12.0001 17.0002H8.74698e-06Z"/></svg>')`,
    },
  "&.SwapStack--left.SwapStack--square.SwapStack--xLarge > .SwapStack__image--right":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 48 48" ${svgXmlns}><path d="M0 33V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0H33V28C33 30.7614 30.7614 33 28 33H0Z" /></svg>')`,
    },

  "&.SwapStack--right.SwapStack--circle.SwapStack--small > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 16 16" ${svgXmlns}><path d="M8 0C3.58172 0 0 3.58172 0 8C0 12.0797 3.0538 15.4461 7.00021 15.9381C7.03334 11.0167 11.0167 7.03334 15.9381 7.00021C15.4461 3.0538 12.0797 0 8 0Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--circle.SwapStack--medium > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 20 20" ${svgXmlns}><path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C10.4042 20 10.8029 19.976 11.1946 19.9294C12.0355 15.5147 15.5147 12.0355 19.9294 11.1946C19.976 10.8029 20 10.4042 20 10C20 4.47715 15.5228 0 10 0Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--circle.SwapStack--large > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}><path d="M16 0C7.16344 0 0 7.16344 0 16C0 24.5007 6.62929 31.4531 15 31.9693C15.0166 22.6048 22.6048 15.0166 31.9693 15C31.4531 6.62929 24.5007 0 16 0Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--circle.SwapStack--xLarge > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 48 48" ${svgXmlns}><path d="M24 0C10.7452 0 0 10.7452 0 24C0 34.4041 6.62021 43.2619 15.8779 46.5908C15.3055 44.491 15 42.2812 15 40C15 26.1929 26.1929 15 40 15C42.2812 15 44.491 15.3055 46.5908 15.8779C43.2619 6.62021 34.4041 0 24 0Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--square.SwapStack--small > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 16 16" ${svgXmlns}><path d="M4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H7V12C7 9.23858 9.23858 7 12 7H16V4C16 1.79086 14.2091 0 12 0H4Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--square.SwapStack--medium > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 20 20" ${svgXmlns}><path d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H11V16C11 13.2386 13.2386 11 16 11H20V2C20 0.895431 19.1046 0 18 0H2Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--square.SwapStack--large > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}><path d="M4 0C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H15V20C15 17.2386 17.2386 15 20 15H32V4C32 1.79086 30.2091 0 28 0H4Z"/></svg>')`,
    },
  "&.SwapStack--right.SwapStack--square.SwapStack--xLarge > .SwapStack__image--left":
    {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 48 48" ${svgXmlns}><path d="M4 0C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H15V20C15 17.2386 17.2386 15 20 15H48V4C48 1.79086 46.2091 0 44 0H4Z"/></svg>')`,
    },
};

export const swapContainerImageSx = {
  pos: "absolute",
  WebkitMaskPosition: "left center",
  WebkitMaskRepeat: "no-repeat",
  WebkitMaskSize: "cover",
};

export function getContainerSx({
  size,
  theme: { base },
}: {
  size: ImageSizeVariant;
  theme: BiomeTheme;
}): Properties {
  const sizeMapping: Record<ImageSizeVariant, string> = {
    xxSmall: base.icon.size[300],
    xSmall: base.icon.size[300],
    small: base.icon.size[300],
    medium: base.icon.size[400],
    large: base.icon.size[500],
    xLarge: base.icon.size[600],
  };

  return {
    width: sizeMapping[size],
    height: sizeMapping[size],
  };
}

export const getResponsiveContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ImageSizeVariant>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerSx,
  });
