import { baseContainerStyles as baseMenuItemStyles } from "../MenuItem/styles";

export const inputBarBaseSx = {
  position: "relative",
  d: "flex",
  width: "100%",

  "&:has(.actualInput:checked) .MenuItem": {
    ...baseMenuItemStyles["&.MenuItem--selected"],
  },

  "&:not(.InputBar--disabled):has(.actualInput:checked):hover .MenuItem": {
    ...baseMenuItemStyles[
      "&.MenuItem--selected.MenuItem--hasWholeOnClick.MenuItem--hover"
    ],
  },

  "&:not(.InputBar--disabled):hover .MenuItem": {
    ...baseMenuItemStyles["&.MenuItem--hasWholeOnClick.MenuItem--hover"],
  },
};
