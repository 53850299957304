import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { DEFAULT_IMAGE_SIZE_VARIANT } from "@/constants";
import { type FramedIconProps, isDualVariantIcon } from "@/types";

import { FramedContainer } from "../shared/FramedContainer";

export function FramedIcon<RC extends ReactElement | undefined = undefined>({
  circularFrame = false,
  emphasized = false,
  padded = false,
  className,
  testId = "FramedIcon",
  icon,
  variant,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  sx = {},
  motionProfile,
  ...props
}: FramedIconProps<RC>) {
  const iconProps = isDualVariantIcon(icon) ? { icon, variant } : { icon };
  const mergedContainerSx = useMemo(
    () => merge({ fill: "base.color.text.body.primary" }, sx),
    [sx],
  );

  return (
    <FramedContainer
      {...props}
      circularFrame={circularFrame}
      emphasized={emphasized}
      padded={padded}
      size={size}
      testId={testId}
      className={`${className ?? ""} FramedIcon`}
      sx={mergedContainerSx}
      motionProfile={motionProfile}
    >
      <Icon
        {...iconProps}
        sx={{ w: "50%", fill: "inherit" }}
        testId={`${testId}__icon`}
        motionProfile={motionProfile}
      />
    </FramedContainer>
  );
}

FramedIcon.displayName = "FramedIcon";
