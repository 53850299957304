import {
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Heading } from "@/components/Text";
import { DUMMY_WIDTH_PUSHER_TEXT } from "@/constants";
import { useTheme } from "@/hooks";
import { getHeadingTextStyles, warnUser } from "@/utils";

import { baseDummyDisplaySx, baseInnerTextSx, containerSx } from "./styles";
import type { HeroZoomTextProps } from "./types";
import { autoTextSize } from "./utils";

export function HeroZoomText<RC extends ReactElement | undefined>({
  children,
  sx = {},
  weight = "bold",
  testId = "HeroZoomText",
  maxFontSize = 1000,
  minFontSize = 1,
  fontSizePrecision = 10,
  textScaleRatio = "1.14",
  className,
  ...props
}: HeroZoomTextProps<RC>) {
  const theme = useTheme();
  const updateTextSizeRef = useRef<ReturnType<typeof autoTextSize> | null>(
    null,
  );

  useEffect(() => updateTextSizeRef.current?.(), []);

  const innerTextRef = useCallback(
    (innerEl: HTMLElement | null) => {
      updateTextSizeRef.current?.disconnect();

      const containerEl = innerEl?.parentElement;
      // @NOTE: handle erroneous use-cases:
      if (!innerEl || !containerEl) return;
      if (minFontSize >= maxFontSize) {
        return warnUser(
          `HeroZoomText has disabled auto-text resizing functionality, because minFontSize (${minFontSize}) must be less than maxFontSize (${maxFontSize})`,
        );
      }

      updateTextSizeRef.current = autoTextSize({
        innerEl,
        containerEl,
        minFontSize,
        maxFontSize,
        fontSizePrecision,
      });
    },
    [minFontSize, maxFontSize, fontSizePrecision],
  );

  const baseTextCss = useMemo(
    () =>
      getHeadingTextStyles({
        size: "xxLarge",
        weight,
        themeProps: theme,
      }),
    [weight, theme],
  );
  const mergedDummyFlexWidthPusherSx = useMemo(
    () =>
      merge(baseTextCss, baseDummyDisplaySx, {
        opacity: 0,
        "&::before": {
          content: `"${DUMMY_WIDTH_PUSHER_TEXT}"`,
          d: "inline",
        },
      }),
    [baseTextCss],
  );
  const innerTextSx = useMemo(
    () =>
      merge(baseInnerTextSx, {
        scale: textScaleRatio,
      }),
    [textScaleRatio],
  );
  const mergedContainerSx = useMemo(() => merge(containerSx, sx), [sx]);

  return (
    <Box
      {...props}
      testId={testId}
      className={`${className ?? ""} HeroZoomText`}
      sx={mergedContainerSx}
    >
      {/* 
      @NOTE: this box ensures that even in flex layouts, 
      this component will take up as much of the 
      available width as possible */}
      <Box
        sx={mergedDummyFlexWidthPusherSx}
        testId={`${testId}__dummyFlexWidthPusherContainer`}
        className="dummyFlexWidthPusherContainer"
      />

      <Heading
        domRef={innerTextRef}
        testId={`${testId}__innerText`}
        sx={innerTextSx}
        weight={weight}
        className="HeroZoomText__innerText"
        // @NOTE: stop the text size from transitioning,
        // so that calcs can always be accurate
        motionProfile="none"
      >
        {children}
      </Heading>
    </Box>
  );
}

HeroZoomText.displayName = "HeroZoomText";
