export const containerSx = { pos: "relative", pb: "base.spacing.x4" };

export const bgLayersSx = {
  pos: "absolute",
  bottom: "0",
  w: "100%",
  h: "100%",
  pointerEvents: "none",
};

export const backBgLayerSx = {
  opacity: 0.2,
  pos: "absolute",
  bottom: "0",
  w: "100%",
};

export const midBgLayerSx = {
  opacity: 0.4,
  pos: "absolute",
  bottom: "base.spacing.x2",
  w: "100%",
};

export const frontLayerSx = {
  pos: "relative",
};
