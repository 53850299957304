import { Children, type ReactElement, useEffect, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { DEFAULT_TIMELINE_VARIANT, TIMELINE_VARIANTS } from "@/constants";
import { useGetCurrentSizeClass, useGetSubcomponentChildren } from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";
import { renderNullAndWarnUser } from "@/utils";

import { TimelineStep } from "./TimelineStep";
import { type TimelineProps, useGetBasicLayoutMode } from "./shared";
import { baseTimelineSx } from "./styles";

export function Timeline<RC extends ReactElement | undefined = undefined>({
  className,
  testId = "Timeline",
  variant = DEFAULT_TIMELINE_VARIANT,
  children,
  sx = {},
  currentStep = 1,
  allStepsComplete = false,
  showCurrentStepSpinner = false,
  ...otherProps
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & TimelineProps
  : TimelineProps & { rc: RC }) {
  const steps = useGetSubcomponentChildren(children, TimelineStep);
  const totalSteps = steps?.length || 0;
  const mergedSx = useMemo(() => merge(baseTimelineSx, sx), [sx]);
  const variantClass = useGetCurrentSizeClass(
    variant,
    DEFAULT_TIMELINE_VARIANT,
    TIMELINE_VARIANTS,
  );
  const basicLayoutMode = useGetBasicLayoutMode(variantClass);
  useEffect(() => {
    if (
      (typeof currentStep === "number" && currentStep <= 0) ||
      (typeof currentStep === "number" && currentStep > totalSteps)
    ) {
      renderNullAndWarnUser(
        "Timeline",
        `Timeline recieved an invalid currentStep property (${currentStep})`,
      );
    }
  }, [currentStep, totalSteps]);

  return (
    <Stack
      {...otherProps}
      testId={testId}
      sx={mergedSx}
      className={`${
        className ?? ""
      } Timeline Timeline--${basicLayoutMode} Timeline--${variantClass}`}
    >
      {/* 
      @TODO: rather than prop-drilling these things down, perhaps it should instead
      be done via context, because then the TimelineStep's types wont need to
      contain these props, which means that consumers wont be confused and think that
      they need to pass these props in
      */}
      {Children.map(steps, (child, index) => (
        <SmartClone
          variant={variant}
          stepIndex={index + 1}
          activeStepIndex={currentStep}
          totalSteps={totalSteps}
          allStepsComplete={allStepsComplete}
          testId={`${testId}__step`}
          showCurrentStepSpinner={showCurrentStepSpinner}
        >
          {child}
        </SmartClone>
      ))}
    </Stack>
  );
}

Timeline.displayName = "Timeline";
Timeline.Step = TimelineStep;
