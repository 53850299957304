import type { ReactElement } from "react";

import { Body } from "@/components/Text";
import type { BodyProps } from "@/types";

export function ConfirmationDialogContentBody<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: BodyProps<RC>) {
  return <Body {...props} rc={rc} />;
}
