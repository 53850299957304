import { type ReactElement, useId, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Stack } from "@/components/Stack";
import { SvgIcon } from "@/components/SvgIcon";
import { useGetMotionProfile, useTheme } from "@/hooks";
import type { BoxWithRCAndDomProps, MotionProfile } from "@/types";

import { getMotionProfileSx } from "@/utils";
import { backStarSx, frontStarSx, starContainerSx } from "./styles";

export type RatingStarProps<RC extends ReactElement | undefined> =
  BoxWithRCAndDomProps<RC> & {
    mode?: "full" | "half" | "empty";
    isHovering?: boolean;
    motionProfile?: MotionProfile;
  };

export function RatingStar<RC extends ReactElement | undefined = undefined>({
  sx = {},
  mode = "empty",
  className,
  isHovering,
  testId,
  motionProfile,
  ...props
}: RatingStarProps<RC>) {
  const id = useId();
  const theme = useTheme();
  const { base } = theme;
  const fullStarPath = useMemo(
    () => (
      <path
        id={`star-${id}`}
        d="M5.825 22L7.45 14.975L2 10.25L9.2 9.625L12 3L14.8 9.625L22 10.25L16.55 14.975L18.175 22L12 18.275L5.825 22Z"
      />
    ),
    [id],
  );

  const halfStarPath = useMemo(
    () => (
      <path
        id={`halfStar-${id}`}
        d="M12,18.3l-6.2,3.7,1.6-7-5.4-4.7,7.2-.6,2.8-6.6v15.3Z"
      />
    ),
    [id],
  );
  const foreground = useMemo(() => {
    const useHalfStar = mode === "half";
    return (
      <SvgIcon
        sx={frontStarSx}
        className="RatingStar__foreground"
        testId={`${testId}__SvgIcon--front`}
      >
        <defs>
          {useHalfStar ? halfStarPath : fullStarPath}
          <clipPath id={useHalfStar ? "insideHalfStarOnly" : "insideStarOnly"}>
            <use xlinkHref={useHalfStar ? `#halfStar-${id}` : `#star-${id}`} />
          </clipPath>
        </defs>

        <use
          xlinkHref={useHalfStar ? `#halfStar-${id}` : `#star-${id}`}
          {...(mode !== "empty"
            ? {
                strokeWidth: 2,
                stroke: base.color.translucent.standard[600],
                clipPath: useHalfStar
                  ? "url(#insideHalfStarOnly)"
                  : "url(#insideStarOnly)",
              }
            : {})}
        />
      </SvgIcon>
    );
  }, [
    mode,
    base.color.translucent.standard[600],
    halfStarPath,
    fullStarPath,
    id,
    testId,
  ]);

  const background = useMemo(() => {
    return /half|empty/.test(mode) ? (
      <SvgIcon
        sx={backStarSx}
        className="RatingStar__background"
        testId={`${testId}__SvgIcon--back`}
      >
        {fullStarPath}
      </SvgIcon>
    ) : null;
  }, [mode, fullStarPath, testId]);
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const mergedSx = useMemo(
    () =>
      merge(starContainerSx, getMotionProfileSx(motionProfileToUse, theme), sx),
    [motionProfileToUse, theme, sx],
  );

  return (
    <Stack
      {...props}
      testId={testId}
      sx={mergedSx}
      className={`${className ?? ""} RatingStar RatingStar--${mode} ${
        isHovering ? "RatingStar--hover" : ""
      }`}
    >
      {background}
      {foreground}
    </Stack>
  );
}

RatingStar.displayName = "Rating.Star";
