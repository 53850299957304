// @NOTE: Positioned Overlays (tooltip & popover) must be positioned fixed -
// so that they are always accurately positioned - even when the Biome Root
// container is inset inside the viewport, and also even when the Biome Root
// container is a shadowRoot (e.g. inside a web component).
export const positionedOverlayContainerCss = {
  position: "fixed",
  pointerEvents: "none",
  top: "0px",
  left: "0px",
  bottom: "0px",
  right: "0px",

  "& > *": {
    pointerEvents: "auto",
  },
} as const;
