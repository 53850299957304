export const spineMenuSx = {
  d: "flex",
  flexDirection: "column",
  w: "112px",
  h: "100%",
  flexGrow: 0,
  flexShrink: 0,
  px: "base.spacing.x2",
  py: "base.spacing.x6",
  gap: "base.spacing.x1",
  bg: "base.color.neutral.700",
  boxShadow: "base.shadow.200",
  overflowX: "hidden",
  overflowY: "auto",
};

export const spineMenuItemSx = {
  d: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  gap: "base.spacing.x2",
  py: "base.spacing.x3",
  c: "base.color.text.body.primary",
  fill: "base.color.text.body.primary",
  borderRadius: "base.borderRadius.x4",

  "&::before": {
    content: '""',
    position: "absolute",
    left: "0px",
    top: "0px",
    w: "100%",
    h: "100%",
    borderRadius: "base.borderRadius.x4",
    border: "solid transparent",
    borderWidth: "base.border.size.100",
  },

  "&.selected": {
    bg: "base.color.translucent.inverse.500",
  },

  "&:hover": {
    fill: "base.color.text.body.primary",
    bg: "base.color.translucent.emphasis.200",
  },

  "&:hover::before": {
    borderColor: "base.color.translucent.standard.500",
  },

  "&.selected::before": {
    borderColor: "base.color.translucent.standard.1000",
  },

  "&:active::before": {
    borderWidth: "base.border.size.200",
    borderColor: "base.color.translucent.standard.1000",
  },
};

export const badgeSx = {
  position: "absolute",
  top: "4px",
  left: "50%",
  transform: "translateX(-50%)",
  ml: "10px",
};

export const iconSx = { width: "base.icon.size.250", fill: "inherit" };
