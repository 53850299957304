import { useMemo } from "react";

import { DEFAULT_IMAGE_SIZE_VARIANT, IMAGE_SIZE_VARIANTS } from "@/constants";
import { useWindowSizeStore } from "@/providers/BiomeWindowSize";
import type {
  ImageComponentKind,
  ImageSizeVariant,
  MakeResponsive,
  ResponsiveSizes,
} from "@/types";
import {
  getImageSizeMapping,
  pickResponsiveSize,
  pickSizeInLayout,
} from "@/utils/imageHelpers";
import { getStartingSize } from "@/utils/styleHelpers";
import { useTheme } from "./useTheme";

export function useGetResponsiveImageSizes(
  size: MakeResponsive<ImageSizeVariant>,
  componentKind: ImageComponentKind,
) {
  const { state: width } = useWindowSizeStore((state) => state.width);
  const theme = useTheme();
  const {
    base: { breakpointAsArray },
  } = theme;
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );

  const sizeMapping = getImageSizeMapping(componentKind, theme, true);
  const responsiveSizes = useMemo(() => {
    const defaultSize = pickResponsiveSize(startingSize, sizeMapping);
    if (width === null) return defaultSize;

    if (Array.isArray(size)) {
      const actualResponsiveSizes = [...size] as ImageSizeVariant[];
      actualResponsiveSizes.shift();
      return actualResponsiveSizes.reduce((accum, curr, index) => {
        let newAccum = accum;
        const breakpoint = breakpointAsArray[index];
        if (
          curr !== null &&
          typeof breakpoint === "number" &&
          width >= breakpoint
        ) {
          newAccum = pickResponsiveSize(curr, sizeMapping);
        }
        return newAccum;
      }, defaultSize);
    }

    return /small|medium|large|xLarge/.test(size)
      ? pickResponsiveSize(size, sizeMapping)
      : defaultSize;
  }, [startingSize, sizeMapping, width, size, breakpointAsArray]);

  return responsiveSizes as ResponsiveSizes;
}

export function useGetRelativeImageSizeInLayout(
  size: MakeResponsive<ImageSizeVariant>,
  componentKind: ImageComponentKind,
) {
  const { state: width } = useWindowSizeStore((state) => state.width);
  const theme = useTheme();
  const {
    base: { breakpointAsArray },
  } = theme;
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const sizeMapping = getImageSizeMapping(componentKind, theme, true);
  const relativeImageSizeInLayout = useMemo(() => {
    const defaultSize = pickSizeInLayout(startingSize, sizeMapping);
    if (width === null) return defaultSize;

    if (Array.isArray(size)) {
      const actualResponsiveSizes = [...size] as ImageSizeVariant[];
      actualResponsiveSizes.shift();
      return actualResponsiveSizes.reduce((accum, curr, index) => {
        let newAccum = accum;
        const breakpoint = breakpointAsArray[index];
        if (
          curr !== null &&
          typeof breakpoint === "number" &&
          width >= breakpoint
        ) {
          newAccum = pickSizeInLayout(curr, sizeMapping);
        }
        return newAccum;
      }, defaultSize);
    }

    return /small|medium|large|xLarge/.test(size)
      ? pickSizeInLayout(size, sizeMapping)
      : defaultSize;
  }, [startingSize, sizeMapping, width, size, breakpointAsArray]);

  return relativeImageSizeInLayout;
}
