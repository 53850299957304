import * as passport from "@imtbl/passport";
import type { Eip1193Provider } from "ethers";
import jwtDecode from "jwt-decode";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { appConfig } from "@/constants";
import type { ChainAddress, PassportConfig } from "@/types";
import { EnvironmentNames } from "@/types";
import { notifyError, setNewRelicUserId } from "@/utils/monitoring";
import { analytics, propertiesPlugin, trackLegacyEvent } from "@analytics";
import { usePrevious } from "@biom3/react";
import * as config from "@imtbl/config";
import * as x from "@imtbl/x-client";
import type * as xProvider from "@imtbl/x-provider";
import { getCoreSdkConfig } from "./util";

export type PassportState = {
  authenticated: boolean;
  starkExRegistered: boolean;
  zkEvmRegistered: boolean;
  ready: boolean;
};

type DecodedAccessToken = {
  imx_eth_address?: string;
  imx_stark_address?: string;
  imx_user_admin_address?: string;
  zkevm_eth_address?: string;
  zkevm_user_admin_address?: string;
};

const getPassportConfig = (environment: EnvironmentNames) => {
  const passportConfig: PassportConfig = {
    logoutMode: "redirect",
    audience: "platform_api",
    scope: "openid email offline_access transact read:grants",
    clientId: appConfig.AUTH0_CLIENT_ID,
    logoutRedirectUri: appConfig.AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI,
    redirectUri: `${appConfig.BASE_REDIRECT_URL}/ab-login`,
    popupRedirectUri: appConfig.AUTH0_REDIRECT_PLAY_PORTAL_URI,
    forceScwDeployBeforeMessageSignature: true,
  };

  switch (environment) {
    case EnvironmentNames.PRODUCTION: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.PRODUCTION,
        }),
        ...passportConfig,
      };
    }
    case EnvironmentNames.SANDBOX: {
      return {
        baseConfig: new config.ImmutableConfiguration({
          environment: config.Environment.SANDBOX,
        }),
        ...passportConfig,
      };
    }

    case EnvironmentNames.DEV: {
      const baseConfig = new config.ImmutableConfiguration({
        environment: config.Environment.SANDBOX,
      });

      return {
        baseConfig,
        overrides: {
          authenticationDomain: "https://auth.dev.immutable.com",
          magicPublishableApiKey: "pk_live_4058236363130CA9",
          magicProviderId: "C9odf7hU4EQ5EufcfgYfcBaT5V6LhocXyiPRhIjw2EY=",
          passportDomain: "https://passport.dev.immutable.com",
          imxPublicApiDomain: "https://api.dev.immutable.com",
          immutableXClient: new x.IMXClient({
            baseConfig,
            overrides: {
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              immutableXConfig: getCoreSdkConfig(EnvironmentNames.DEV)
                .overrides!.immutableXConfig,
            },
          }),
          zkEvmRpcUrl: "https://rpc.dev.immutable.com",
          relayerUrl: "https://api.dev.immutable.com/relayer-mr",
          indexerMrBasePath: "https://api.dev.immutable.com",
          orderBookMrBasePath: "https://api.dev.immutable.com",
          passportMrBasePath: "https://api.dev.immutable.com",
        },
        ...passportConfig,
      };
    }
    default: {
      throw new Error("Invalid environment");
    }
  }
};

export type PassportContext = {
  logout: () => void;
  loginCallback: () => void;
  triggerLogin: () => void;
  triggerLoginWithCallback: (
    callback: () => Promise<void> | void,
  ) => Promise<void>;
  userInfo?: passport.UserProfile;
  passportState: PassportState;
  isLoggedIn: boolean;
  walletAddress?: ChainAddress;
  zkEvmProvider?: Eip1193Provider;
  passportClient: passport.Passport;
};

const PassportContext = createContext<PassportContext | null>(null);

export function PassportProvider({ children }: PropsWithChildren) {
  const [provider, setProvider] = useState<xProvider.IMXProvider | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [zkEvmProvider, setZkEvmProvider] = useState<
    Eip1193Provider | undefined
  >();
  const [userInfo, setUserInfo] = useState<passport.UserProfile>();
  const [walletAddress, setWalletAddress] = useState<ChainAddress>();
  const [userProfile, setUserProfile] = useState<passport.UserProfile | null>();
  const previousUserProfile = usePrevious(userProfile);
  const [passportState, setPassportState] = useState<PassportState>({
    authenticated: false,
    starkExRegistered: false,
    zkEvmRegistered: false,
    ready: false,
  });
  const previousPassportState = usePrevious(passportState);

  const passportClient = useMemo<passport.Passport>(
    () => new passport.Passport(getPassportConfig(appConfig.ENVIRONMENT)),
    [],
  );

  useEffect(() => {
    propertiesPlugin.setIsLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  const triggerLogin = useCallback(async () => {
    try {
      setUserProfile(await passportClient.login());
      trackLegacyEvent({
        screen: "Login",
        userJourney: "Profile",
        control: "Screen",
        controlType: "Effect",
        action: "Succeeded",
      });
    } catch (error) {
      notifyError(error, "login");
    }
  }, [passportClient]);

  const triggerLoginWithCallback = useCallback(
    async (callback: () => Promise<void> | void) => {
      try {
        setUserProfile(await passportClient.login());
        trackLegacyEvent({
          screen: "Login",
          userJourney: "Profile",
          control: "Screen",
          controlType: "Effect",
          action: "Succeeded",
        });
        if (callback) await callback();
      } catch (error) {
        notifyError(error, "login");
      }
    },
    [passportClient],
  );

  const logout = useCallback(async () => {
    try {
      await passportClient.logout();
    } catch (e) {
      notifyError(e, "logout");
    }
    setUserProfile(undefined);
    setUserInfo(undefined);
    setWalletAddress(undefined);
    setNewRelicUserId(null);
    setPassportState({
      authenticated: false,
      starkExRegistered: false,
      zkEvmRegistered: false,
      ready: true,
    });
    setIsLoggedIn(false);
  }, [passportClient]);

  const loginCallback = useCallback(
    async () => passportClient.loginCallback(),
    [passportClient],
  );

  const getImxProvider = useCallback(async () => {
    let passportProvider = provider;
    try {
      if (!passportProvider) {
        passportProvider = await passportClient.connectImx();
        setProvider(passportProvider);
      }
    } catch (error) {
      notifyError(error, "getImxProvider");
      throw error;
    }
    return passportProvider;
  }, [passportClient, provider]);

  // Check if a session exists on first initialisation
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const evmProvider = await passportClient.connectEvm();
        setZkEvmProvider(evmProvider);
        const profile = await passportClient.getUserInfo();
        if (profile) {
          setUserProfile(profile);
        } else {
          setIsLoggedIn(false);
          setPassportState({
            authenticated: false,
            starkExRegistered: false,
            zkEvmRegistered: false,
            ready: true,
          });
        }
      } catch (e) {
        // Likely due to invalid refresh token, force a re-auth
        notifyError(e, "fetchUser");
        await logout();
      }
    };
    fetchUser();
  }, [passportClient, logout]);

  // Handle userProfile set
  useEffect(() => {
    const authenticate = async () => {
      if (previousUserProfile === userProfile || !userProfile) return;

      try {
        const idToken = await passportClient.getIdToken();
        const accessToken = await passportClient.getAccessToken();
        if (!!accessToken && !!idToken) {
          const imxProvider = await getImxProvider();

          // Currently no way to get this immediately from the SDK post login so we decode the access token for speed
          const decodedAccessToken = jwtDecode<DecodedAccessToken>(accessToken);
          const zkEvmRegistered =
            !!decodedAccessToken.zkevm_eth_address &&
            !!decodedAccessToken.zkevm_user_admin_address;

          if (zkEvmRegistered && zkEvmProvider) {
            // Need to call eth_requestAccounts to setup signer
            zkEvmProvider.request({ method: "eth_requestAccounts" });
            setWalletAddress(
              decodedAccessToken.zkevm_eth_address as ChainAddress,
            );
          }

          const starkExRegistered = await imxProvider.isRegisteredOffchain();
          if (starkExRegistered) {
            setWalletAddress((await imxProvider.getAddress()) as ChainAddress);
          }
          analytics.identify(userProfile.sub, {
            name: userProfile?.nickname,
            email: userProfile?.email,
          });

          setUserInfo(userProfile);
          setNewRelicUserId(userProfile.sub);
          setPassportState({
            authenticated: true,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        } else {
          // Invalid session, no access or id token
          notifyError(new Error("Invalid session tokens"), "authenticate");
          await logout();
        }
      } catch (error) {
        console.error({ error });
        notifyError(error, "decodePassportToken");
        await logout();
      }
    };
    authenticate();
  }, [
    userProfile,
    previousUserProfile,
    passportClient,
    zkEvmProvider,
    getImxProvider,
    logout,
  ]);

  // Register off chain if necessary and get wallet address
  useEffect(() => {
    let starkExRegistered = passportState.starkExRegistered;
    let zkEvmRegistered = passportState.zkEvmRegistered;
    if (
      passportState.authenticated === previousPassportState?.authenticated ||
      passportState.authenticated !== true
    ) {
      return;
    }

    // ID-2462: Register each chain synchronously to prevent race conditions when updating Auth0 Metadata
    const register = async () => {
      try {
        const passportProvider = await passportClient.connectEvm();
        if (!zkEvmRegistered) {
          const addresses = await passportProvider.request({
            method: "eth_requestAccounts",
          });
          zkEvmRegistered = true;
          // Update states now so the user can start using the Dashboard
          setWalletAddress(addresses[0]);
          setPassportState({
            authenticated: true,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        }
      } catch (error) {
        notifyError(error, "passportRegistration", { network: "zkEVM" });
      }
      try {
        const imxProvider = await getImxProvider();
        if (!starkExRegistered) {
          await imxProvider.registerOffchain();
          const address = (await imxProvider.getAddress()) as ChainAddress;
          starkExRegistered = true;
          setWalletAddress(address);
          setPassportState({
            authenticated: true,
            starkExRegistered: starkExRegistered,
            zkEvmRegistered: zkEvmRegistered,
            ready: true,
          });
          setIsLoggedIn(true);
        }
      } catch (error) {
        notifyError(error, "passportRegistration", { network: "StarkEx" });
      }
    };
    register();
  }, [passportState, previousPassportState, passportClient, getImxProvider]);

  const providerValues = useMemo(
    () => ({
      loginCallback,
      logout,
      triggerLogin,
      triggerLoginWithCallback,
      isLoggedIn,
      userInfo,
      walletAddress,
      passportState,
      zkEvmProvider,
      passportClient,
    }),
    [
      loginCallback,
      logout,
      triggerLogin,
      triggerLoginWithCallback,
      isLoggedIn,
      userInfo,
      walletAddress,
      passportState,
      zkEvmProvider,
      passportClient,
    ],
  );

  return (
    <PassportContext.Provider value={providerValues}>
      {children}
    </PassportContext.Provider>
  );
}

export function usePassportProvider() {
  const ctx = useContext(PassportContext);
  if (!ctx) {
    throw new Error(
      "usePassportProvider must be used within a PassportProvider",
    );
  }
  return ctx;
}
