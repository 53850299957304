import { type ReactElement, type ReactNode, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Body } from "@/components/Text";
import { useGetMotionProfile, useTheme } from "@/hooks";
import type {
  DomPropsWithDomRef,
  MotionProfile,
  StandardComponentWithProps,
} from "@/types";
import { getMotionProfileSx } from "@/utils";

import type { BadgeVariant } from "./shared";
import {
  baseBadgeSx,
  getBadgeAnimationSx,
  getBadgeVariantSx,
  plainDotBadgeSx,
  textSx,
} from "./styles";

export type BadgeBaseProps = StandardComponentWithProps<
  HTMLSpanElement,
  {
    variant?: BadgeVariant;
    isAnimated?: boolean;
    badgeContent?: ReactNode;
    maxNumber?: number;
    showZero?: boolean;
    motionProfile?: MotionProfile;
  }
>;

export type BadgeProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"span"> & BadgeBaseProps
    : BadgeBaseProps & { rc: RC };

export function Badge<RC extends ReactElement | undefined = undefined>({
  rc = <span />,
  domRef,
  testId,
  sx = {},
  variant = "fatal",
  isAnimated = false,
  badgeContent,
  maxNumber = 999,
  showZero,
  className,
  motionProfile,
  ...badgeDomAttributes
}: BadgeProps<RC>) {
  const theme = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const displayContent = useMemo(() => {
    if (typeof badgeContent === "number") {
      if (typeof maxNumber === "number" && badgeContent > maxNumber) {
        return `+${maxNumber}`;
      }
      if (badgeContent === 0 && showZero) {
        return `${badgeContent}`;
      }
      if (badgeContent === 0 && !showZero) {
        return null;
      }
    }

    return badgeContent;
  }, [badgeContent, maxNumber, showZero]);

  const hasContent = useMemo(() => Boolean(displayContent), [displayContent]);
  const badgeSx = useMemo(
    () =>
      merge(
        baseBadgeSx,
        getBadgeVariantSx(variant),
        getMotionProfileSx(motionProfileToUse, theme),
        getBadgeAnimationSx(isAnimated, hasContent),
        {
          ...(!displayContent ? plainDotBadgeSx : {}),
          "&::before": {
            ...getMotionProfileSx(motionProfileToUse, theme),
          },
        },
        theme.components?.Badge?.sxOverride ?? {},
        sx,
      ),
    [
      variant,
      isAnimated,
      hasContent,
      displayContent,
      motionProfileToUse,
      theme,
      sx,
    ],
  );

  const invertText = useMemo(
    () =>
      (theme.base.colorMode === "onLight" && /fatal|success/.test(variant)) ||
      variant === "dark",
    [theme.base.colorMode, variant],
  );
  const mergedTextSx = useMemo(
    () =>
      merge(textSx, {
        c: invertText
          ? "base.color.fixed.white.1000"
          : "base.color.fixed.black.1000",
        fill: invertText
          ? "base.color.fixed.white.1000"
          : "base.color.fixed.black.1000",
      }),
    [invertText],
  );

  return (
    <Box
      {...badgeDomAttributes}
      rc={rc}
      sx={badgeSx}
      domRef={domRef as Ref<HTMLDivElement>}
      testId={testId}
      className={`${className ?? ""} Badge Badge--${variant} ${
        invertText ? "Badge--inverted" : ""
      }`}
    >
      {Boolean(displayContent) && (
        <Body
          sx={mergedTextSx}
          weight="bold"
          size="xxSmall"
          testId={`${testId}__text`}
          className="Badge__text"
        >
          {displayContent}
        </Body>
      )}
    </Box>
  );
}

Badge.displayName = "Badge";
