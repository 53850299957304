import {
  Children,
  type ReactElement,
  type MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { ModalContent } from "@/components/Modal/ModalContent";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {} from "@/components/Text";
import {
  useGetManySingleSubcomponentChildren,
  useGetSubcomponentChildren,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import { useOverlaysStore } from "@/providers";
import { CLOSE_MODAL } from "@/providers/BiomeOverlaysProvider";
import type { ConfirmationDialogContentProps } from "@/types";
import { vFlex } from "@/utils/sxChunks";

import { childHasOnClickProp } from "@/utils";
import { ConfirmationDialogContentBody } from "./ConfirmationDialogContentBody";
import { ConfirmationDialogContentButton } from "./ConfirmationDialogContentButton";
import { ConfirmationDialogContentCloseButtCon } from "./ConfirmationDialogContentCloseButtCon";
import { ConfirmationDialogContentDuoCon } from "./ConfirmationDialogContentDuoCon";
import { ConfirmationDialogContentHeading } from "./ConfirmationDialogContentHeading";
import { ConfirmationDialogContentImage } from "./ConfirmationDialogContentImage";
import { ConfirmationDialogContentRiveBox } from "./ConfirmationDialogContentRiveBox";

export function ConfirmationDialogContent<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  id = "",
  sx = {},
  testId,
  textAlign = "center",
  className,
  ...props
}: ConfirmationDialogContentProps<RC>) {
  const theme = useTheme();
  const { dispatchAction } = useOverlaysStore((state) => state.modalList);
  const closeModal = useCallback(
    () =>
      dispatchAction({
        type: CLOSE_MODAL,
        payload: { id },
      }),
    [dispatchAction, id],
  );
  const renderedChildren =
    typeof children === "function"
      ? children({
          closeModal,
        })
      : children;

  const {
    subcomponents: [aspectRatioImage, duoCon, title, closeButtCon],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(renderedChildren, [
    { subcomponent: ConfirmationDialogContentImage },
    { subcomponent: ConfirmationDialogContentDuoCon },
    { subcomponent: ConfirmationDialogContentHeading },
    { subcomponent: ConfirmationDialogContentCloseButtCon },
  ]);
  const buttons = useGetSubcomponentChildren(
    otherChildren,
    ConfirmationDialogContentButton,
  );
  const captions = useGetSubcomponentChildren(
    otherChildren,
    ConfirmationDialogContentBody,
  );
  const riveBoxes = useGetSubcomponentChildren(
    otherChildren,
    ConfirmationDialogContentRiveBox,
  );
  const { otherChildren: remainingOtherChildren } =
    useSplitApartChildrenAndSubComponents(otherChildren, [
      ConfirmationDialogContentButton,
      ConfirmationDialogContentBody,
      ConfirmationDialogContentRiveBox,
    ]);
  const onCloseButtConClick = useCallback(
    (ev: ReactMouseEvent<unknown>) => {
      if (childHasOnClickProp(closeButtCon?.props?.rc)) {
        closeButtCon.props.rc.props.onClick?.(ev);
      }
      closeModal();
    },
    [closeButtCon, closeModal],
  );
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...vFlex,
          justifyContent: "flex-start",
          minw: "280px",
          maxw: "430px",
          w: "100dvw",
          bg: "base.color.neutral.500",
          boxShadow: "base.shadow.500",
          brad: "base.borderRadius.x6",
          p: "0px",
        },
        theme.components?.ConfirmationDialog?.ConfirmationDialogContent
          ?.sxOverride ?? {},
        sx,
      ),
    [
      sx,
      theme.components?.ConfirmationDialog?.ConfirmationDialogContent
        ?.sxOverride,
    ],
  );

  return (
    <ModalContent
      {...props}
      sx={mergedSx}
      testId={testId}
      className={`${className ?? ""} ConfirmationDialogContent`}
    >
      {aspectRatioImage && (
        <SmartClone testId={`${testId}__aspectRaioImage`}>
          {aspectRatioImage}
        </SmartClone>
      )}

      {riveBoxes?.length
        ? riveBoxes?.map((riveBox, index) => (
            <SmartClone
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              testId={riveBox.props.testId ?? `${testId}__riveBox`}
            >
              {riveBox}
            </SmartClone>
          ))
        : null}

      {duoCon && (
        <Stack
          alignItems="center"
          justifyContent={textAlign === "center" ? "center" : "flex-start"}
          gap="0px"
          sx={{
            pt: "base.spacing.x10",
            ml: textAlign === "center" ? "unset" : "base.spacing.x6",
          }}
          testId={`${testId}__duoConContainer`}
          className="ConfirmationDialogContent__duoConContainer"
        >
          <SmartClone
            sx={{ w: "72px" }}
            testId={`${testId}__duoConContainer__duoCon`}
          >
            {duoCon}
          </SmartClone>
        </Stack>
      )}

      <Stack
        gap="0px"
        sx={{
          px: "base.spacing.x6",
          pt: "base.spacing.x8",
          pb: "base.spacing.x10",
          textAlign,
          flexGrow: 1,
        }}
        className="ConfirmationDialogContent__contentContainer"
      >
        {title || captions ? (
          <Box
            sx={{ mb: "base.spacing.x10" }}
            className="ConfirmationDialogContent__contentContainer__textContainer"
          >
            {title && (
              <SmartClone
                size="medium"
                sx={{ textAlign }}
                testId={`${testId}__title`}
              >
                {title}
              </SmartClone>
            )}

            {captions && (
              <Box
                sx={{
                  ...vFlex,
                  gap: "base.spacing.x3",
                  pt: "base.spacing.x2",
                  textAlign,
                }}
                testId={`${testId}__captions`}
              >
                {Children.map(captions, (child) => (
                  <SmartClone
                    size="small"
                    sx={{ textAlign }}
                    testId={`${testId}__captions__caption`}
                  >
                    {child}
                  </SmartClone>
                ))}
              </Box>
            )}
          </Box>
        ) : null}

        {buttons && (
          <Box
            testId={`${testId}__buttons`}
            className="ConfirmationDialogContent__buttonsContainer"
            sx={{
              ...vFlex,
              gap: "base.spacing.x2",
              mt: "auto",
            }}
          >
            {Children.map(buttons, (child) => (
              <SmartClone testId={`${testId}__buttons__button`}>
                {child}
              </SmartClone>
            ))}
          </Box>
        )}

        {remainingOtherChildren}
      </Stack>

      <SmartClone onClick={onCloseButtConClick}>{closeButtCon}</SmartClone>
    </ModalContent>
  );
}

ConfirmationDialogContent.displayName = "ConfirmationDialog.Content";
ConfirmationDialogContent.RiveBox = ConfirmationDialogContentRiveBox;
ConfirmationDialogContent.Image = ConfirmationDialogContentImage;
ConfirmationDialogContent.DuoCon = ConfirmationDialogContentDuoCon;
ConfirmationDialogContent.Title = ConfirmationDialogContentHeading;
ConfirmationDialogContent.Caption = ConfirmationDialogContentBody;
ConfirmationDialogContent.Button = ConfirmationDialogContentButton;
ConfirmationDialogContent.CloseButtCon = ConfirmationDialogContentCloseButtCon;
