import type {
  ToastButtonsPosition,
  ToastPosition,
  ToastVariant,
} from "@/types";

export const DEFAULT_TOAST_POSITION: ToastPosition = {
  x: "right",
  y: "top",
};

export const DEFAULT_TOAST_BUTTONS_POSITION: ToastButtonsPosition = "right";

export const DEFAULT_TOAST_VARIANT: ToastVariant = "guidance";
