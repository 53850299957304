import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { RollingText } from "@/components/Text";
import type { RollingTextProps } from "@/types";

import { leftRollingTextBaseSx } from "./styles";

export type ProgressBarLabelProps<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined,
> = RollingTextProps<RC, Use> & {
  centerText?: boolean;
};

export function ProgressBarLabel<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  className,
  text,
  rc,
  use,
  sx = {},
  centerText = false,
  testId,
  ...props
}: ProgressBarLabelProps<RC, Use>) {
  const { size, ...propsMinusSize } =
    "size" in props ? props : { size: "small", ...props };
  const { weight, ...propsMinusSizeWeight } =
    "weight" in propsMinusSize
      ? propsMinusSize
      : { ...propsMinusSize, weight: "regular" };

  const mergedSx = useMemo(
    () =>
      merge(
        leftRollingTextBaseSx,
        {
          w: "auto",
          ...(centerText
            ? { "& .RollingText__textContainer": { justifyContent: "center" } }
            : {
                "& .RollingText__textContainer": {
                  justifyContent: "flex-start",
                },
              }),
        },
        sx,
      ),
    [sx, centerText],
  );
  return (
    <RollingText
      {...propsMinusSizeWeight}
      rc={rc}
      use={use}
      size={size as BodySize}
      weight={weight as BodyWeight}
      text={text}
      sx={mergedSx}
      className={`${className ?? ""} ProgressBarLabel`}
      testId={testId}
    />
  );
}

ProgressBarLabel.displayName = "ProgressBar.Label";
