import type { Property } from "csstype";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps, MakeValidSxValue } from "@/types";

import { shimmerSx } from "./style";

export function ShimmerCircle<RC extends ReactElement | undefined = undefined>({
  sx = {},
  radius = "50px",
  className,
  ...props
}: BoxWithRCAndDomProps<RC> & { radius?: MakeValidSxValue<Property.Width> }) {
  return (
    <Box
      {...props}
      sx={merge(shimmerSx, { brad: "50%", w: radius, h: radius }, sx)}
      className={`${className ?? ""} ShimmerCircle`}
    />
  );
}

ShimmerCircle.displayName = "ShimmerCircle";
