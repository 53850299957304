import type { ReactElement } from "react";

import { FramedImage } from "@/components/FramedThings/FramedImage";
import { DEFAULT_IMAGE_SIZE_VARIANT } from "@/constants";
import type {
  BoxWithRCAndDomProps,
  FramedMediaBaseProps,
  ImageResizerProps,
  RelativeImageSizeInLayoutProp,
  SupportedNativeImgAttributes,
  UseImageProps,
} from "@/types";
import { useGetFramedImageSizeFromSize } from "@/utils";

export type SwapStackImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = Use extends undefined
  ? BoxWithRCAndDomProps<RC> &
      UseImageProps &
      SupportedNativeImgAttributes &
      Omit<FramedMediaBaseProps, "emphasized"> &
      // @NOTE: this prop is now optional for FramedImages
      Omit<ImageResizerProps, "relativeImageSizeInLayout"> & {
        relativeImageSizeInLayout?: RelativeImageSizeInLayoutProp;
      }
  : BoxWithRCAndDomProps<RC> &
      Omit<FramedMediaBaseProps, "emphasized"> &
      UseImageProps & {
        use?: Use;
      };

export function SwapStackPrimaryImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  use,
  className,
  testId,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  ...props
}: SwapStackImageProps<RC, Use>) {
  const sizeToUse = useGetFramedImageSizeFromSize("SwapStack", size);
  return (
    <FramedImage
      {...props}
      use={use}
      size={sizeToUse}
      testId={`${testId}__framedImage--left`}
      className={`${
        className ?? ""
      } SwapStackPrimaryImage SwapStack__image SwapStack__image--left`}
    />
  );
}

export function SwapStackSecondaryImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  use,
  testId,
  className,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  ...props
}: SwapStackImageProps<RC, Use>) {
  const sizeToUse = useGetFramedImageSizeFromSize("SwapStack", size);
  return (
    <FramedImage
      {...props}
      use={use}
      size={sizeToUse}
      testId={`${testId}__framedImage--right`}
      className={`${
        className ?? ""
      } SwapStackSecondaryImage SwapStack__image SwapStack__image--right`}
    />
  );
}
