import { Body, Box, BulletList, Heading, Stack } from "@biom3/react";
import type { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";

export type ContextBoxProps = {
  title: string;
  description: string;
  formattedDescription?: RichTextField;
  testId?: string;
};

const ContentBox = ({
  title,
  description,
  testId,
  formattedDescription,
}: ContextBoxProps) => {
  return (
    <Stack
      testId={testId}
      direction={{
        default: "column",
        large: "row",
      }}
      gap={"base.spacing.x6"}
    >
      <Heading
        // biome-ignore lint/a11y/useHeadingContent: <explanation>
        rc={<h4 />}
        size="small"
        weight="regular"
        sx={{
          flex: 1,
          color: "base.color.text.heading.primary",
        }}
      >
        {title}
      </Heading>
      {formattedDescription ? (
        <Box
          sx={{
            flex: 1,
          }}
        >
          <PrismicRichText
            field={formattedDescription}
            components={{
              list: (p) => (
                <BulletList
                  sx={{ "& > ul": { gap: "base.spacing.x2" } }}
                  {...p}
                />
              ),
              listItem: ({ key, ...p }) => <BulletList.Item {...p} key={key} />,
              paragraph: ({ key, ...p }) => (
                <Body
                  {...p}
                  key={key}
                  size="medium"
                  sx={{
                    color: "base.color.text.body.secondary",
                    display: "block",
                    padding: "base.spacing.x1",
                  }}
                />
              ),
            }}
          />
        </Box>
      ) : (
        <Body
          size="medium"
          sx={{
            flex: 1,
            color: "base.color.text.body.secondary",
          }}
        >
          {description}
        </Body>
      )}
    </Stack>
  );
};

export default ContentBox;
