import type { Animation } from "../types";

export const motion: Animation = {
  normal: {
    gentle: {
      cssDuration: "0.4s",
      jsDuration: 0.4,
      cssEase: "ease-in-out",
      jsEase: "easeInOut",
    },
    fast: {
      cssDuration: "0.25s",
      jsDuration: 0.25,
      cssEase: "ease-in-out",
      jsEase: "easeInOut",
    },
  },
  bounce: {
    gentle: {
      cssDuration: "0.7s",
      jsDuration: 0.7,
      cssEase: "cubic-bezier(0.47, 1.74, 0.41, 0.8)",
      jsEase: [0.47, 1.74, 0.41, 0.8],
    },
    fast: {
      cssDuration: "0.45s",
      jsDuration: 0.45,
      cssEase: "cubic-bezier(0.47, 1.74, 0.41, 0.8)",
      jsEase: [0.47, 1.74, 0.41, 0.8],
    },
  },
};
