import {
  Children,
  type ReactElement,
  cloneElement,
  useContext,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps } from "@/types";
import { isChildSubcomponent, renderNullAndWarnUser } from "@/utils";

import { TableRow } from "./TableRow";
import { TableContext } from "./context";

export type TableFootProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC>;

export function TableFoot<RC extends ReactElement | undefined = undefined>({
  children,
  rc = <tfoot />,
  className,
  testId: testIdProp,
  ...props
}: TableFootProps<RC>) {
  const { testId } = useContext(TableContext);
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  return (
    <Box
      {...props}
      rc={rc}
      testId={`${testIdProp ?? testId}__tfoot`}
      className={`${className ?? ""} TableFoot`}
    >
      {Children.map(flattenedChildren, (child) => {
        if (isChildSubcomponent(child, TableRow)) {
          return cloneElement(child, { parentNode: "tfoot" });
        }

        return renderNullAndWarnUser(TableFoot.displayName);
      })}
    </Box>
  );
}

TableFoot.displayName = "Table.Foot";
