import type { Font } from "../types";

export const font: Font = {
  family: {
    heading: {
      primary: `'Suisse-Intl', sans-serif`,
      secondary: `'Suisse-Intl', sans-serif`,
    },
    body: {
      primary: `'Roboto', sans-serif`,
      secondary: `'Roboto Mono', monospace`,
    },
  },
  decoration: {
    underline: "underline",
    lineThrough: "line-through",
    none: "none",
  },
  casing: {
    uppercase: "uppercase",
    none: "none",
  },
};
