import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text/Body";
import type { BodyProps } from "@/types";
import { prettyFormatNumber } from "@/utils";

type PriceDisplayCaptionProps<RC extends ReactElement | undefined> =
  BodyProps<RC>;

export function PriceDisplayCaption<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  children,
  testId = "PriceDisplayCaption",
  size = "xSmall",
  weight = "regular",
  className,
  ...props
}: PriceDisplayCaptionProps<RC>) {
  const prettyContent =
    typeof children === "string" ? prettyFormatNumber(children) : children;
  const mergedSx = useMemo(
    () =>
      merge({ c: "base.color.text.body.secondary", textAlign: "right" }, sx),
    [sx],
  );
  return (
    <Body
      {...props}
      sx={mergedSx}
      size={size}
      weight={weight}
      testId={testId}
      className={`${className ?? ""} PriceDisplayCaption`}
    >
      {prettyContent}
    </Body>
  );
}

PriceDisplayCaption.displayName = "PriceDisplay.Caption";
