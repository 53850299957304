import type { BiomeTheme } from "@/types";
import { merge } from "ts-deepmerge";
import { lineClamp } from "../../utils";

export const baseTabSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  flexGrow: 1,
  background: "transparent",
  cursor: "pointer",
  gap: "base.spacing.x2",
  bradtl: "base.borderRadius.x6",
  bradtr: "base.borderRadius.x6",
  color: "base.color.text.body.primary",
  border: "2px solid transparent",
  p: "base.spacing.x2",
  transitionProperty: "background-color, border-color, color",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",

  "&::before": {
    transitionProperty: "border-color",
    transitionDuration: "base.motion.normal.fast.cssDuration",
    transitionTimingFunction: "base.motion.normal.fast.cssEase",
  },

  ".TabsList__vertical > &": {
    flexDirection: "column",
    minHeight: "base.spacing.x19",
    textAlign: "center",

    "& .Body": {
      textAlign: "center",
    },
  },
  ".TabsList__horizontal > &": {
    flexDirection: "row",
    minHeight: "base.spacing.x12",
  },
};

export const tabLabelBaseSx = {
  ...lineClamp(2),
  textAlign: "left",
  lineHeight: "1.3",
};

export const renderTabStyles = ({
  isActive,
  disabled,
}: {
  isActive: boolean;
  disabled: boolean;
}) => {
  return isActive
    ? {
        backgroundColor: "base.color.neutral.500",
        cursor: "pointer",
        borderColor: "base.color.text.body.primary",
        borderWidth: "base.border.size.200",
        borderStyle: "solid",
        pos: "relative",

        "&::before": {
          content: "''",
          pos: "absolute",
          bg: "base.color.neutral.500",
          width: "100%",
          height: "4px",
          left: "0px",
          bottom: ({ base }: BiomeTheme) =>
            `calc(-2 * ${base.border.size[200]})`,
        },
      }
    : disabled
      ? {
          cursor: "not-allowed",
        }
      : {
          bg: "base.color.translucent.emphasis.100",
          cursor: "pointer",
          "&:hover": {
            bg: "base.color.translucent.emphasis.300",
            pos: "relative",
            "&::before": {
              content: "''",
              pos: "absolute",
              bg: "transparent",
              top: ({ base }: BiomeTheme) =>
                `calc(-1 * ${base.border.size[200]})`,
              left: ({ base }: BiomeTheme) =>
                `calc(-1 * ${base.border.size[200]})`,
              right: ({ base }: BiomeTheme) =>
                `calc(-1 * ${base.border.size[200]})`,
              bottom: ({ base }: BiomeTheme) =>
                `calc(-0.5 * ${base.border.size[200]})`,
              bradtl: "base.borderRadius.x6",
              bradtr: "base.borderRadius.x6",
              borderColor: "base.color.translucent.standard[500]",
              borderWidth: "base.border.size.100",
              borderStyle: "solid",
            },
          },
          "&:active": {
            bg: "base.color.translucent.emphasis.400",
            borderColor: "base.color.translucent.standard.1000",
            borderWidth: "base.border.size.200",
            borderStyle: "solid",
            borderBottomColor: "transparent",
          },
        };
};

export const renderTabIconStyles = ({
  isActive,
  disabled,
}: {
  isActive: boolean;
  disabled: boolean;
}) => {
  return merge(
    {
      width: "base.spacing.x6",
      flexShrink: 0,
    },

    isActive
      ? {
          fill: "base.color.brand.1",
        }
      : disabled
        ? {
            fill: "base.color.brand.4",
          }
        : {
            fill: "base.color.brand.4",

            "*:hover > &": {
              fill: "base.color.brand.1",
            },
          },
  );
};

// Tab List
export const tabsListStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "stretch",
  minHeight: "base.spacing.x12",
  width: "100%",
  flexWrap: "nowrap",
  columnGap: "1px",
  flexGrow: "1",
};

// Tab Panels
export const tabsPanelStyles = {
  backgroundColor: "base.color.neutral.500",
  width: "100%",
  minHeight: "base.spacing.x50",
  borderColor: "base.color.text.body.primary",
  borderWidth: "base.border.size.200",
  borderStyle: "solid",
  bradbl: "base.borderRadius.x6",
  bradbr: "base.borderRadius.x6",
};
