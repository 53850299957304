import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Avatar, type AvatarProps } from "@/components/Avatar";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { DistributiveOmit, MenuItemProps } from "@/types";

import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseLeftImageStyles } from "./styles";

export type MenuItemAvatarProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = DistributiveOmit<AvatarProps<RC, Use>, "size"> &
  Pick<MenuItemProps, "size">;

export function MenuItemAvatar<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  sx = {},
  rc = <span />,
  size = DEFAULT_MENU_ITEM_SIZE,
  ...props
}: MenuItemAvatarProps<RC, Use>) {
  const { imageUrl } =
    "imageUrl" in props ? props : { ...props, imageUrl: undefined };
  const mergedSx = useMemo(() => merge(baseLeftImageStyles, sx), [sx]);
  const avatarSize = useGetBiomeImageSizeFromMenuItemSize(size);
  return (
    <Avatar
      {...props}
      imageUrl={imageUrl}
      rc={rc}
      sx={mergedSx}
      size={avatarSize}
    />
  );
}

MenuItemAvatar.displayName = "MenuItem.Avatar";
