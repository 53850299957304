import type { BiomeTheme } from "@/types";

export const containerStyles = {
  w: "base.spacing.x5",
  h: "base.spacing.x5",
  pos: "relative",
};

export const inputCssStyles = (theme: BiomeTheme) => ({
  "&:disabled + .checkContainer": {
    boxShadow: "none",
  },

  "&:checked + .checkContainer": {
    borderWidth: theme.base.border.size[200],
    borderColor: theme.base.color.text.body.primary,
  },

  "&:hover:not(:disabled) + .checkContainer": {
    borderColor: theme.base.color.translucent.standard[800],
    boxShadow: theme.base.shadow[300],
  },

  "&:checked:hover:not(:disabled) + .checkContainer": {
    borderColor: theme.base.color.text.body.secondary,
    borderWidth: theme.base.border.size[100],
  },

  "&:checked + .checkContainer > .checkIcon": {
    opacity: "1",
  },
});

export const checkContainerSx = {
  borderStyle: "solid",
  borderWidth: "base.border.size.100",
  borderColor: "base.color.translucent.standard.500",
  bg: "base.color.translucent.emphasis.100",
  brad: "base.borderRadius.x2",
  w: "base.spacing.x5",
  h: "base.spacing.x5",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const tickSx = {
  fill: "base.color.text.body.primary",
  pointerEvents: "none",
  opacity: "0",
};
