import { trackLegacyEvent } from "@analytics";
import type { Content } from "@prismicio/client";

export type TrackGameDetailsParams = {
  game?: Content.GameDocument;
  extras?: Record<string, unknown>;
  action?: string;
  control: string;
  controlType: string;
  identifier: string;
};

export const getGameDetailsExtras = (game?: Content.GameDocument) => {
  const gameDetailsTemplate = game?.data?.template_tier;
  return {
    gameDetailsTemplate,
    gameName: game?.data?.name,
    gameId: game?.uid,
  };
};

export function trackGameDetailsEvent({
  game,
  action,
  control,
  controlType,
  extras,
  identifier,
}: TrackGameDetailsParams) {
  trackLegacyEvent({
    screen: "Details",
    userJourney: "Games",
    action,
    control,
    controlType,
    extras: {
      ...extras,
      // Pass identifiers, feel free to append additional data here
      ...getGameDetailsExtras(game),
      identifier,
    },
  });
}
