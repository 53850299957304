import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import type { TabsContextType, TabsProviderProps } from "./types";

export const TabsContext = createContext<TabsContextType>({
  currentTabValue: "",
  onTabChange: () => {},
});

export function TabsProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: TabsProviderProps;
}) {
  const { currentTabValue, defaultTabValue, onTabChange } = value;
  const [uncontrolledValue, setUncontrolledValue] = useState(
    currentTabValue ?? defaultTabValue ?? "",
  );

  const valueToUse = currentTabValue ?? uncontrolledValue;
  const handleTabChange = useCallback(
    (newTabValue: string) => {
      onTabChange?.(newTabValue);
      setUncontrolledValue(newTabValue);
    },
    [onTabChange],
  );
  const contextValue = useMemo(
    () => ({ currentTabValue: valueToUse, onTabChange: handleTabChange }),
    [handleTabChange, valueToUse],
  );

  return (
    <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>
  );
}

export const useTabsContext = () => {
  const context = useContext(TabsContext);
  return context;
};
