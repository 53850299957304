import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedImage } from "@/components/FramedThings";
import {} from "@/hooks";
import type { FixedSizeFramedImageProps, MenuItemProps } from "@/types";
import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseLeftImageStyles } from "./styles";

export type MenuItemFramedImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = FixedSizeFramedImageProps<RC, Use> & Pick<MenuItemProps, "size">;

export function MenuItemFramedImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  use,
  sx = {},
  rc,
  size = "small",
  className,
  ...props
}: MenuItemFramedImageProps<RC, Use>) {
  const mergedSx = useMemo(() => merge(baseLeftImageStyles, sx), [sx]);
  const sizeToUse = useGetBiomeImageSizeFromMenuItemSize(size);
  return (
    <FramedImage
      {...props}
      rc={rc}
      use={use}
      sx={mergedSx}
      size={sizeToUse}
      className={`${className ?? ""} MenuItemFramedImage`}
    />
  );
}

MenuItemFramedImage.displayName = "MenuItem.FramedImage";
