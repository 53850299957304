import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { useGetSubcomponentChildren } from "@/hooks/useGetSubcomponentChildren";
import { Box } from "../Box";
import { TabsTab } from "./TabsTab";
import { tabsListStyles } from "./styles";
import type { TabsListProps } from "./types";

export function TabsList<RC extends ReactElement | undefined = undefined>({
  testId = "Tabs__List",
  domRef = { current: null },
  children,
  contentOrientation = "horizontal",
  sx = {},
  className,
  ...props
}: TabsListProps<RC>) {
  const tabSubcomponentChildren = useGetSubcomponentChildren(children, TabsTab);
  const allSx = merge(
    tabsListStyles,
    {
      ...(contentOrientation === "vertical"
        ? { "& .Tab": { gap: "base.spacing.x1" } }
        : {}),
    },
    sx,
  );

  return (
    <Box
      {...props}
      testId={testId}
      domRef={domRef}
      className={`${className ?? ""} TabsList TabsList__${contentOrientation}`}
      sx={allSx}
    >
      {tabSubcomponentChildren}
    </Box>
  );
}

TabsList.displayName = "Tabs.List";
