import { appConfigFn } from "@/constants/appConfig";
import { onDarkBase } from "@biom3/design-tokens";
import { mediaQuerySizesHelper } from "@biom3/react";
import { BREAKPOINTS } from "@ui-kit";
import type { QuestKey } from "@ui-kit/QuestList";
import { Roboto, Roboto_Mono } from "next/font/google";
import localFont from "next/font/local";

export const APP_HEADER_HEIGHT = "80px";
export const SIDEBAR_WIDTH = "220px";
export const HOME_PAGE = "/";
export const DISCOVER_PAGE = "/discover";
export const GAMES_PAGE = "/games";
export const MODAL_WIDTH = "430px";
export const MODAL_HEIGHT = "640px";
export const HERO_GRID_MIN_COLUMN_WIDTH = "330px";

export const NEXT_IMAGE_QUALITY = 85;

export const appConfig = appConfigFn();
// @NOTE: use this when we have no valid image url to pass to Biome Image
// components, as this will have them display default imagery
export const BAD_IMAGE_URL = "https://something";
export const PINATA_GATEWAY_URL = "https://gateway.pinata.cloud";

export const TOOLKIT_URL =
  appConfig.ENVIRONMENT === "production"
    ? "https://toolkit.immutable.com"
    : "https://checkout-playground.sandbox.immutable.com";

const bodyPrimary = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

const bodySecondary = Roboto_Mono({
  weight: ["400", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

const headingPrimary = localFont({
  display: "swap",
  src: [
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../../node_modules/@biom3/design-tokens/fonts/suisseintl-bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
});

export const CUSTOM_BIOME_THEME = {
  base: {
    ...onDarkBase,
    font: {
      ...onDarkBase.font,
      family: {
        heading: {
          primary: headingPrimary.style.fontFamily,
          secondary: headingPrimary.style.fontFamily,
        },
        body: {
          primary: bodyPrimary.style.fontFamily,
          secondary: bodySecondary.style.fontFamily,
        },
      },
    },
    breakpoint: BREAKPOINTS,
  },
  globalConfig: {
    imageResizeServiceUrl: appConfig.IMAGE_RESIZER_URL,
  },
} as const;

export const QUEST_CAROUSEL_IMAGE_SIZES = mediaQuerySizesHelper([
  {
    rule: "default",
    width: "calc(100dvw - 48px - 16px)",
    // 48px: 24px padding on each side inner padding
    // 16px: 8px padding on each side outer padding
  },
  {
    rule: "minWidth",
    breakpoint: "medium",
    width: `calc(100dvw - ${SIDEBAR_WIDTH} - 48px - 32px)`,
    // 220px: sidebar width,
    // 48px: 24px padding on each side inner padding
    // 32px: 16px padding on each side outer padding
  },
  {
    rule: "minWidth",
    breakpoint: "xLarge",
    width: `calc((100dvw - ${SIDEBAR_WIDTH} - 48px - 32px) * 0.72)`,
    // 220px: sidebar width,
    // 48px: 24px padding on each side inner padding
    // 32px: 16px padding on each side outer padding
    // 0.72: the % width of the main carousel display area
  },
])({
  // For the purposes of consumption by mediaQuerySizesHelper,
  // we dont need the calculcated breakpointAsArray field inside the theme
  base: { ...CUSTOM_BIOME_THEME.base, breakpointAsArray: [] },
});

export const APP_GLOBAL_SX = {
  "html, body": {
    p: "0",
    m: "0",
    bg: "base.color.neutral.1000",
    h: "100dvh",
  },

  "#__next": {
    w: "100%",
    h: "100%",
  },
} as const;

export const SOCIAL_LINK_ERROR_MESSAGES = {
  GENERIC: "That didn't work. Go back to the other tab and try again.",
  SOCIAL_ACCOUNT_ALREADY_LINKED:
    "This social account is already linked to another Passport user",
  DIFFERENT_SOCIAL_ACCOUNT_ALREADY_LINKED:
    "You already have a different social account linked",
  NOT_LOGGED_IN: "User is not authenticated yet",
};

export interface SocialConnection {
  platform: "X" | "Discord";
  key: QuestKey;
  questId: string;
}

export const SOCIAL_CONNECTIONS: SocialConnection[] = [
  { platform: "X", key: "x-v2", questId: "link-x-account" },
  { platform: "Discord", key: "discord-v2", questId: "link-discord-account" },
];

export const IMMUTABLE_GAME_NAME = "Immutable";
export const IMMUTABLE_GAME_ID = "11111111-1111-1111-1111-111111111111";

export const MAIN_QUESTS_GROUP = "Main Quests";
export const SIDE_QUESTS_GROUP = "Side Quests";
