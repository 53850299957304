import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {
  DEFAULT_PROGRESS_BAR_COLOR_VARIANT,
  DEFAULT_PROGRESS_BAR_SIZE,
  PROGRESS_BAR_SIZES,
} from "@/constants";
import {
  useGetCurrentSizeClass,
  useGetMotionProfile,
  useGetSubcomponentChild,
  useTheme,
} from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { ProgressBarCaption } from "./ProgressBarCaption";
import { ProgressBarLabel } from "./ProgressBarLabel";
import type { ProgressBarProps } from "./shared";
import {
  barContainerBaseSx,
  baseBarSx,
  getBarContainerSx,
  getContainerColorVariantSx,
  getResponsiveBarContainerSx,
  getVariantBasedBarSx,
} from "./styles";

export function ProgressBar<RC extends ReactElement | undefined = undefined>({
  children,
  size = DEFAULT_PROGRESS_BAR_SIZE,
  variant,
  colorVariant = DEFAULT_PROGRESS_BAR_COLOR_VARIANT,
  sx = {},
  className,
  testId = "ProgressBar",
  motionProfile,
  centerText = false,
  disableBarTransition = false,
  hideText = false,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & ProgressBarProps
  : ProgressBarProps & { rc: RC }) {
  const isDeterminant = useMemo(() => variant === "determinate", [variant]);
  const { globalConfig } = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const { value, ...otherProps } = isDeterminant
    ? props
    : { ...props, value: undefined };
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_PROGRESS_BAR_SIZE,
    PROGRESS_BAR_SIZES,
  );
  const allBarContainerSx = merge(
    barContainerBaseSx,
    getBarContainerSx({ theme, size: startingSize }),
    getResponsiveBarContainerSx({ theme, size }),
  );
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_PROGRESS_BAR_SIZE,
    PROGRESS_BAR_SIZES,
  );
  const label = useGetSubcomponentChild(children, ProgressBarLabel);
  const caption = useGetSubcomponentChild(children, ProgressBarCaption);

  const allBarSx = merge(
    baseBarSx,
    getVariantBasedBarSx({
      variant,
      value,
      disableBarTransition,
      motionProfile: motionProfileToUse,
    }),
  );
  const allContainerSx = merge(
    { w: "100%" },
    getContainerColorVariantSx(colorVariant),
    sx,
  );
  const shouldRenderGap =
    (!label && isDeterminant) ||
    label ||
    caption ||
    (!caption && isDeterminant);
  return (
    <Stack
      {...otherProps}
      className={`${
        className ?? ""
      } ProgressBar ProgressBar--${sizeClass} ProgresBar--${variant} ${
        centerText ? "ProgressBar--centerText" : ""
      }`}
      sx={allContainerSx}
      gap={shouldRenderGap ? "base.spacing.x2" : "0px"}
      testId={testId}
    >
      <Box
        sx={allBarContainerSx}
        testId={`${testId}__barContainer`}
        className="barContainer"
      >
        <Box
          sx={allBarSx}
          testId={`${testId}__barContainer__bar`}
          className="barContainer__bar"
        />
      </Box>

      {!hideText && (
        <Stack
          direction={centerText ? "column" : "row"}
          alignItems="center"
          className="textContainer"
          testId={`${testId}__textContainer`}
        >
          {!label && isDeterminant ? (
            <ProgressBarLabel
              text={`${value}%`}
              testId={`${testId}__label`}
              centerText={centerText}
            />
          ) : label ? (
            <SmartClone testId={`${testId}__label`} centerText={centerText}>
              {label}
            </SmartClone>
          ) : null}

          {!caption && isDeterminant ? (
            <ProgressBarCaption
              testId={`${testId}__caption`}
              centerText={centerText}
            >
              100%
            </ProgressBarCaption>
          ) : caption ? (
            <SmartClone testId={`${testId}__caption`} centerText={centerText}>
              {caption}
            </SmartClone>
          ) : null}
        </Stack>
      )}
    </Stack>
  );
}

ProgressBar.displayName = "ProgressBar";
ProgressBar.Label = ProgressBarLabel;
ProgressBar.Caption = ProgressBarCaption;
