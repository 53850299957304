import debounce from "lodash.debounce";
import { motion } from "motion/react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { recursivelyAddTooltipAttrsToChildNodes } from "@/components/Tooltip/shared";
import {
  DEFAULT_BODY_PROPS,
  DEFAULT_TOOLTIP_SIZE,
  TOOLTIP_SIZES,
} from "@/constants";
import { useEventListener, useTheme } from "@/hooks";
import { BiomeShadowRootContext } from "@/providers/BiomeShadowRootProvider";
import { useTooltipStore } from "@/providers/BiomeTooltipProvider/tooltipContext";
import type { TooltipItem } from "@/types";
import { isTouchDevice } from "@/utils/deviceHelpers";
import {
  getSafeCenterHorizontalPosition,
  getSafeTopVerticalPosition,
} from "@/utils/positionHelpers";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  baseContainerSx,
  getContainerStyles,
  responsiveContainerStyles,
} from "./styles";

const getBoundingRect = (element: Element) => element.getBoundingClientRect();
const LEAVE_DELAY = 55;
const HOVER_INTENT_DELAY = 100;

export function TooltipOverlay() {
  const shadowRoot = useContext(BiomeShadowRootContext);
  const containerElementRef = useRef<ShadowRoot | Window>(shadowRoot || window);
  const theme = useTheme();
  const hoverLeaveTimer = useRef<number>(0);
  const hoverIntentTimer = useRef<number>(0);
  const currentTooltipBubbleRef = useRef<HTMLDivElement>(null);
  const animatedTooltipBubbleRef = useRef<HTMLDivElement>(null);
  const { state: tooltipList } = useTooltipStore((state) => state.tooltipList);
  const [currentTooltip, setCurrentTooltip] = useState<TooltipItem | undefined>(
    undefined,
  );
  const [currentTooltipTargetRect, setCurrentTooltipTargetRect] = useState<
    DOMRect | undefined
  >(undefined);
  const [currentTooltipBubbleRect, setCurrentTooltipBubbleRect] = useState<
    DOMRect | undefined
  >(undefined);

  const calculatedPosition = useMemo(() => {
    if (
      !currentTooltipTargetRect ||
      !currentTooltipBubbleRect ||
      !currentTooltip
    ) {
      return {
        left: 0,
        top: 0,
      };
    }

    const left = getSafeCenterHorizontalPosition(
      currentTooltipTargetRect,
      currentTooltipBubbleRect,
    );
    const top = getSafeTopVerticalPosition(
      currentTooltipTargetRect,
      currentTooltipBubbleRect,
      16,
    );

    return { left, top };
  }, [currentTooltip, currentTooltipBubbleRect, currentTooltipTargetRect]);

  const onDocumentBodyClick = useCallback(
    (event: MouseEvent) => {
      if (!isTouchDevice()) return false;

      if (tooltipList.length > 0) {
        const clickedElement = event.target as HTMLElement;
        const clickedTooptiptId =
          clickedElement.getAttribute("data-tooltip-id");
        const isTooltipTarget =
          clickedElement.getAttribute("data-tooltip-target") === "true";
        const isClickingOnOpenTooltip =
          clickedTooptiptId === currentTooltip?.id;

        if (!isTooltipTarget && isClickingOnOpenTooltip) {
          // @NOTE: If we're clicking on a tooltip bubble element, then do nothing
          // (allows user to select things inside of a tooltip)
          return false;
        }

        if (isTooltipTarget && isClickingOnOpenTooltip) {
          // @NOTE: If the target is clicked again and the tooltip is already open, toggle it closed
          setCurrentTooltip(undefined);
        } else if (clickedTooptiptId !== currentTooltip?.id) {
          // @NOTE: Else If the tooltip id of the clicked element is different to the
          // current tooltip, open a new one
          setCurrentTooltip(
            tooltipList.find((tooltip) => tooltip.id === clickedTooptiptId),
          );
        } else {
          // @NOTE: Else we're not clicking atop a tooltip, so just close any open tooltip
          setCurrentTooltip(undefined);
        }
        return false;
      }

      return false;
    },
    [tooltipList, currentTooltip],
  );

  const clearCurrentTooltipData = useCallback(() => {
    setCurrentTooltip(undefined);
    setCurrentTooltipTargetRect(undefined);
    setCurrentTooltipBubbleRect(undefined);
  }, []);

  const clearHoverTimers = useCallback(() => {
    clearTimeout(hoverLeaveTimer.current);
    clearTimeout(hoverIntentTimer.current);
  }, []);

  const handleHoverStart = useCallback(
    (hoveredTooptipTargetId: string) => {
      clearHoverTimers();

      if (hoveredTooptipTargetId !== currentTooltip?.id) {
        hoverIntentTimer.current = window.setTimeout(() => {
          const newTooltip = tooltipList.find(
            (tooltip) => tooltip.id === hoveredTooptipTargetId,
          );
          setCurrentTooltip(newTooltip);
        }, HOVER_INTENT_DELAY);
      }
    },
    [tooltipList, currentTooltip, clearHoverTimers],
  );

  const handleHoverTooltipClose = useCallback(() => {
    clearHoverTimers();
    if (currentTooltip) {
      clearCurrentTooltipData();
    }
  }, [currentTooltip, clearCurrentTooltipData, clearHoverTimers]);

  const handleHoverStop = useCallback(() => {
    clearHoverTimers();
    hoverLeaveTimer.current = window.setTimeout(() => {
      handleHoverTooltipClose();
    }, LEAVE_DELAY);
  }, [handleHoverTooltipClose, clearHoverTimers]);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isTouchDevice() && tooltipList.length > 0) {
        const hoveredElement = event.target as HTMLElement;
        const hoveredTooptipTargetId =
          hoveredElement?.getAttribute("data-tooltip-id");
        if (hoveredTooptipTargetId) {
          handleHoverStart(hoveredTooptipTargetId);
        } else {
          handleHoverStop();
        }

        return false;
      }

      return false;
    },
    [tooltipList.length, handleHoverStart, handleHoverStop],
  );

  useEventListener("mousemove", onMouseMove, containerElementRef);
  useEventListener("click", onDocumentBodyClick, containerElementRef);

  const onResizeAndScroll = useCallback(() => {
    if (currentTooltip) {
      clearCurrentTooltipData();
    }
  }, [currentTooltip, clearCurrentTooltipData]);
  const debouncedOnResizeAndOnScroll = debounce(onResizeAndScroll, 300, {
    leading: true,
  });
  useEventListener(
    "scroll",
    debouncedOnResizeAndOnScroll,
    containerElementRef,
    true,
  );
  useEventListener("resize", debouncedOnResizeAndOnScroll, containerElementRef);

  useEffect(() => {
    if (shadowRoot) {
      containerElementRef.current = shadowRoot;
    }
  }, [shadowRoot]);

  useEffect(() => {
    if (currentTooltip) {
      const target = shadowRoot
        ? shadowRoot.querySelector(`[data-tooltip-id="${currentTooltip.id}"]`)
        : document.body.querySelector(
            `[data-tooltip-id="${currentTooltip.id}"]`,
          );
      if (target) {
        setCurrentTooltipTargetRect(getBoundingRect(target));
      } else {
        setCurrentTooltipTargetRect(undefined);
      }

      recursivelyAddTooltipAttrsToChildNodes(
        animatedTooltipBubbleRef.current,
        currentTooltip.id,
      );
    } else {
      setCurrentTooltipTargetRect(undefined);
    }
  }, [currentTooltip, shadowRoot]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!currentTooltipBubbleRef.current) return;
    setCurrentTooltipBubbleRect(
      getBoundingRect(currentTooltipBubbleRef.current),
    );
  }, [currentTooltipTargetRect, currentTooltipBubbleRef]);

  // @NOTE: if the tooltipList changes to be empty, then we should
  // clear out the current tooltip (if there is one)
  useEffect(() => {
    if (tooltipList.length === 0 && currentTooltip) {
      clearCurrentTooltipData();
    }
  }, [tooltipList, currentTooltip, clearCurrentTooltipData]);

  // @NOTE: Clear the hover timers whenever the component unmounts
  useEffect(() => {
    return () => clearHoverTimers();
  }, [clearHoverTimers]);

  const startingSize = getStartingSize(
    currentTooltip?.size,
    DEFAULT_TOOLTIP_SIZE,
    TOOLTIP_SIZES,
  );

  const mergedContainerSx = useMemo(
    () =>
      merge(
        baseContainerSx,
        {
          c: DEFAULT_BODY_PROPS.color,
          top: calculatedPosition.top,
          left: calculatedPosition.left,
        },
        getContainerStyles({
          size: startingSize ?? DEFAULT_TOOLTIP_SIZE,
          theme,
        }),
        responsiveContainerStyles({
          size: currentTooltip?.size ?? DEFAULT_TOOLTIP_SIZE,
          theme,
        }),
      ),
    [theme, calculatedPosition, startingSize, currentTooltip],
  );
  const dummyTooltipSx = useMemo(
    () =>
      merge(mergedContainerSx, {
        top: "0",
        left: "0",
        opacity: 0,
        pointerEvents: "none",
      }),
    [mergedContainerSx],
  );

  // @NOTE: keep the current tooltip in sync with the tooltipList content, as/when it changes
  useEffect(() => {
    if (currentTooltip) {
      setCurrentTooltip(
        tooltipList.find((tooltip) => tooltip.id === currentTooltip.id),
      );
    }
  }, [currentTooltip, tooltipList]);

  return currentTooltip ? (
    <>
      {/* 
      @NOTE: sometimes the left + animation props can have a weird impact
      on the dimensions / layout of rendered tooltip bubble.
      To combat this, we can render a hidden version of it on the page. 
      This version is the one we will use for more accurate measurements etc.
     */}
      <Box domRef={currentTooltipBubbleRef} sx={dummyTooltipSx}>
        {currentTooltip?.content}
      </Box>
      <Box
        sx={mergedContainerSx}
        rc={
          <motion.div
            ref={animatedTooltipBubbleRef}
            data-tooltip-id={currentTooltip?.id}
            data-testid="tooltip__container"
            initial={{
              opacity: 0,
              y: "-8%",
            }}
            animate={{
              opacity: 1,
              y: "0%",
            }}
            transition={{
              duration: theme.base.motion.normal.fast.jsDuration,
              ease: theme.base.motion.normal.fast.jsEase,
            }}
          />
        }
      >
        {currentTooltip?.content}
      </Box>
    </>
  ) : null;
}
