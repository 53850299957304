import { Box, CarouselThumbnail, type VideoMimeTypes } from "@biom3/react";
import {
  controlCarouselVideosPlayback,
  getMuxThumbnailImageUrl,
} from "@ui-kit/utils/videos";
import type { EmblaCarouselType } from "embla-carousel";
import {
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import type ReactPlayer from "react-player";
import type { ReactPlayerProps } from "react-player/lazy";
import LazyReactPlayer from "react-player/lazy";
import { Fragment } from "react/jsx-runtime";

function CustomVideoPlayer({
  url,
  playerRef,
  muted = true,
  controls = true,
  loop = true,
  config = {
    youtube: {
      // @NOTE: sadly, this does not disable related videos on youtube anymore,
      // but when set to zero, it will only show related videos from the same channel
      // https://developers.google.com/youtube/player_parameters#rel
      playerVars: { rel: 0 },
    },
  },
  ...reactPlayerProps
}: ReactPlayerProps & { url: string; playerRef?: Ref<ReactPlayer> }) {
  return (
    <Box
      sx={{
        top: "0",
        left: "0",
        pos: "absolute",
        w: "100%",
        h: "100%",
      }}
    >
      <LazyReactPlayer
        {...reactPlayerProps}
        config={config}
        ref={playerRef}
        url={url}
        controls={controls}
        muted={muted}
        loop={loop}
        width="100%"
        height="100%"
      />
    </Box>
  );
}

export type MediaCarouselSlide = {
  video_url?: string;
  video_mime_type?: string;
  image: {
    url: string;
    alt?: string;
  };
  id: string;
};

export type MediaCarouselProps = {
  slides: MediaCarouselSlide[];
  onSlideChange?: (url: string) => void;
};

type VideoDomRefs = Record<string, HTMLVideoElement | null>;

export const MediaCarousel = ({
  slides,
  onSlideChange,
}: MediaCarouselProps) => {
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);
  const videoDomRefs = useRef<VideoDomRefs>({});
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const handleSlideChange = useCallback(async () => {
    const index = emblaApi?.selectedScrollSnap() || 0;
    setActiveVideoIndex(index);
    const url = slides?.[index].image.url;
    url && onSlideChange?.(url);
    await controlCarouselVideosPlayback(index, videoDomRefs.current);
  }, [emblaApi, slides, onSlideChange]);
  const handleInit = useCallback(async () => {
    const index = emblaApi?.selectedScrollSnap() || 0;
    setActiveVideoIndex(index);
    await controlCarouselVideosPlayback(index, videoDomRefs.current);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    handleInit();
    emblaApi?.on("reInit", handleInit);
    emblaApi?.on("select", handleSlideChange);

    return () => {
      emblaApi?.off("select", handleSlideChange);
      emblaApi?.off("reInit", handleInit);
    };
  }, [emblaApi, handleInit, handleSlideChange]);

  return (
    <CarouselThumbnail
      getApi={setEmblaApi}
      thumbHeight={["base.spacing.x12", null, "base.spacing.x16"]}
    >
      {slides.map((slide, index) => {
        const isSlideEmbedVideo = slide.video_mime_type === "iframe/embed";
        let slideMediaContent: ReactNode = null;
        if (slide.video_url && slide.video_mime_type) {
          slideMediaContent = isSlideEmbedVideo ? (
            <CustomVideoPlayer
              url={slide.video_url}
              playing={activeVideoIndex === index}
            />
          ) : slide.video_mime_type ? (
            <CarouselThumbnail.Slide.AssetVideo
              muted
              controls
              autoPlay={false}
              videoUrl={slide.video_url}
              mimeType={slide.video_mime_type as VideoMimeTypes}
              domRef={(el) => {
                videoDomRefs.current[`slide-${index}-video`] = el ? el : null;
              }}
              poster={getMuxThumbnailImageUrl(slide.video_url)}
            />
          ) : null;
        } else if (slide.image.url) {
          slideMediaContent = (
            <CarouselThumbnail.Slide.AssetImage
              loading="eager"
              imageUrl={slide.image.url}
              relativeImageSizeInLayout="80vw"
            />
          );
        }

        return (
          <Fragment key={slide.id}>
            <CarouselThumbnail.Slide
              {...(isSlideEmbedVideo
                ? { sx: { "--textContainerMinHeight": "100%" } }
                : {})}
            >
              {slideMediaContent}
            </CarouselThumbnail.Slide>
            <CarouselThumbnail.Thumb>
              {slide.image.url && (
                <CarouselThumbnail.Thumb.Image
                  imageUrl={slide.image.url}
                  loading="eager"
                />
              )}
            </CarouselThumbnail.Thumb>
          </Fragment>
        );
      })}
      <CarouselThumbnail.NextButtCon />
      <CarouselThumbnail.PreviousButtCon />
    </CarouselThumbnail>
  );
};

export default MediaCarousel;
