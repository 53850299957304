import { type MouseEvent, type ReactElement, useCallback } from "react";
import { merge } from "ts-deepmerge";

import type { ButtonProps } from "@/types";
import { Button } from "../Clickable";

export type CardButtonProps<RC extends ReactElement | undefined = undefined> =
  ButtonProps<RC>;

export function CardButton<RC extends ReactElement | undefined = undefined>({
  sx = {},
  variant = "tertiary",
  className,
  ...props
}: CardButtonProps<RC>) {
  const { onClick, ...otherProps } =
    "onClick" in props ? props : { ...props, onClick: null };
  const handleOnClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onClick === "function") {
        onClick(ev);
      }
    },
    [onClick],
  );
  return (
    <Button
      {...otherProps}
      {...(onClick ? { onClick: handleOnClick } : {})}
      variant={variant}
      sx={merge({ flexGrow: 1 }, sx)}
      className={`${className ?? ""} CardButton`}
    />
  );
}

CardButton.displayName = "Card.Button";
CardButton.Icon = Button.Icon;
CardButton.Logo = Button.Logo;
