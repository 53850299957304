import type { ReactElement } from "react";

import { ButtCon } from "@/components/Clickable";
import { useTheme } from "@/hooks";

import { shouldInvertButtonColoring } from "./styles";
import { type BannerRightButtConProps, DEFAULT_BANNER_VARIANT } from "./types";

export function BannerRightButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  variant = "tertiary",
  bannerVariant = DEFAULT_BANNER_VARIANT,
  className,
  ...props
}: BannerRightButtConProps<RC>) {
  const theme = useTheme();
  const invertButtonColoring = shouldInvertButtonColoring(
    bannerVariant,
    theme,
    variant,
  );
  return (
    <ButtCon
      {...props}
      size="small"
      variant={invertButtonColoring ? `${variant}/inverse` : variant}
      className={`${className ?? ""} BannerRightButtCon`}
    />
  );
}

BannerRightButtCon.displayName = "Banner.RightButtCon";
