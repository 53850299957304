import { useWindowSizeStore } from "@biom3/react";
import { useMemo } from "react";

export const BREAKPOINTS = {
  xSmall: 385,
  small: 430,
  medium: 768,
  large: 950,
  xLarge: 1240,
  xxLarge: 1440,
  xxxLarge: 1920,
};

export const useScreenWidth = () => {
  const { state: width } = useWindowSizeStore((store) => store.width);
  return width;
};

function safeWindowMatchMedia(query: string) {
  if (typeof window === "undefined") return false;
  return window.matchMedia(query).matches;
}

export function useIsLessThanXSmallScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.xSmall}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanSmallScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.small}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanMediumScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.medium}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanLargeScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.large}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanXLargeScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.xLarge}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanXXLargeScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.xxLarge}px)`),
    [width],
  );
  return matches;
}

export function useIsLessThanXXXLargeScreen() {
  const width = useScreenWidth();
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want this value to update when the width value changes (eg when the screen resizes)
  const matches = useMemo(
    () => safeWindowMatchMedia(`(max-width: ${BREAKPOINTS.xxxLarge}px)`),
    [width],
  );
  return matches;
}
