import { ClassNames } from "@emotion/react";
import { AnimatePresence } from "motion/react";
import {
  type MouseEvent,
  type ReactElement,
  useCallback,
  useMemo,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { BaseClickable, ClearFieldButton } from "@/components/Clickable";
import { SmartClone } from "@/components/SmartClone";
import { Body } from "@/components/Text";
import { CHIP_SIZES, DEFAULT_CHIP_SIZE } from "@/constants";
import {
  useGetCurrentSizeClass,
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import { type OnClickFunction, isBaseClickable, isBox } from "@/types";

import { ChipAvatar } from "./ChipAvatar";
import { ChipFramedImage } from "./ChipFramedImage";
import { ChipIcon } from "./ChipIcon";
import type { ChipProps } from "./shared";
import { baseChipStyles, labelSx } from "./styles";

export function Chip<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
>({
  label,
  className,
  rc,
  children,
  testId = "Chip",
  sx = {},
  size = DEFAULT_CHIP_SIZE,
  variant = "regular",
  onRemove,
  ...otherProps
}: ChipProps<RC, OnClick>) {
  const { onClick, ...otherPropsMinusOnClick } =
    "onClick" in otherProps ? otherProps : { ...otherProps, onClick: null };
  const mergedSx = useMemo(() => merge(baseChipStyles, sx), [sx]);
  const icon = useGetSubcomponentChild(children, ChipIcon);
  const framedImage = useGetSubcomponentChild(children, ChipFramedImage);
  const avatar = useGetSubcomponentChild(children, ChipAvatar);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    ChipIcon,
    ChipFramedImage,
    ChipAvatar,
  ]);
  const leftSlot = useMemo(
    () => icon ?? framedImage ?? avatar ?? undefined,
    [icon, framedImage, avatar],
  );
  const sizeClass = useGetCurrentSizeClass(size, DEFAULT_CHIP_SIZE, CHIP_SIZES);
  const containsRemoveButton = useMemo(() => Boolean(onRemove), [onRemove]);
  const onRemoveClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      onRemove?.(event);
    },
    [onRemove],
  );
  const innerContents = useMemo(
    () => (
      <>
        {leftSlot && (
          <SmartClone
            size={leftSlot.props.size ?? size}
            testId={`${testId}__leftSlot`}
          >
            {leftSlot}
          </SmartClone>
        )}

        {otherChildren && otherChildren}

        <Body
          size={sizeClass === "large" ? "medium" : "small"}
          testId={`${testId}__label`}
          sx={labelSx}
          className="Chip__label"
        >
          {label}
        </Body>

        <AnimatePresence>
          {containsRemoveButton && (
            <ClearFieldButton
              onClick={onRemoveClick}
              testId={`${testId}__removeButton`}
              sx={{ mx: ({ base }) => `calc(${base.spacing.x1} * -1)` }}
            />
          )}
        </AnimatePresence>
      </>
    ),
    [
      size,
      label,
      leftSlot,
      testId,
      sizeClass,
      containsRemoveButton,
      onRemoveClick,
      otherChildren,
    ],
  );
  const baseClickableProps = {
    ...otherPropsMinusOnClick,
    ...(rc ? { rc } : {}),
    onClick,
  };
  const classNames = useMemo(
    () => [
      className,
      "Chip",
      {
        "Chip--hasWholeOnClick": Boolean(onClick),
        "Chip--hasRemoveButton": containsRemoveButton,
      },
      `Chip--${sizeClass}Size`,
      `Chip--${variant}Variant`,
    ],
    [className, onClick, containsRemoveButton, sizeClass, variant],
  );

  return (
    <ClassNames>
      {({ cx }) =>
        isBaseClickable(
          onClick,
          rc,
          containsRemoveButton,
          baseClickableProps,
        ) ? (
          <BaseClickable
            {...baseClickableProps}
            testId={testId}
            sx={mergedSx}
            className={cx(...classNames)}
          >
            {innerContents}
          </BaseClickable>
        ) : isBox(onClick, rc, containsRemoveButton, otherProps) ? (
          <Box
            {...otherPropsMinusOnClick}
            rc={rc}
            testId={testId}
            sx={mergedSx}
            className={cx(...classNames)}
          >
            {innerContents}
          </Box>
        ) : null
      }
    </ClassNames>
  );
}

Chip.displayName = "Chip";
Chip.Icon = ChipIcon;
Chip.FramedImage = ChipFramedImage;
Chip.Avatar = ChipAvatar;
