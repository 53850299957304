import type { Interpolation } from "@emotion/react";
import { useMemo } from "react";

import {
  DEFAULT_AWS_IMAGE_RESIZER_URL,
  DEFAULT_IMAGE_SIZE,
  DEFAULT_LOADING_IMG_ATTR,
} from "@/constants";
import {
  useDetectBadImage,
  useDetectSvgImage,
  usePickPlaceholderImage,
  useTheme,
} from "@/hooks";
import type {
  BaseImageWithDomProps,
  BiomeTheme,
  ImageResizerProps,
} from "@/types";

import { cloneElementWithCssProp } from "@/utils";
import { generateBaseImageUrl, generateResizedImageUrl } from "./shared";

export type AwsResizerImageProps = Omit<BaseImageWithDomProps, "use" | "sx"> &
  ImageResizerProps & { css?: Interpolation<BiomeTheme> };

export function AwsResizerImage({
  css,
  testId = "AwsResizerImage",
  loading = DEFAULT_LOADING_IMG_ATTR,
  responsiveSizes = [32, 48, 64, 128, 256],
  relativeImageSizeInLayout,
  domRef,
  alt,
  className,
  imageUrl,
  defaultImageClassName,
  defaultImageUrl,
  fetchPriority,
  // @TODO: remove this prop in the future
  imageResizeServiceUrl: imageResizeServiceUrlProp,
  ...props
}: AwsResizerImageProps) {
  const theme = useTheme();
  const imageResizeServiceUrl =
    imageResizeServiceUrlProp ??
    theme.globalConfig?.imageResizeServiceUrl ??
    DEFAULT_AWS_IMAGE_RESIZER_URL;
  const pickedDefaultImage = usePickPlaceholderImage(defaultImageUrl);

  const imageIsSvg = useDetectSvgImage({
    imageUrl: generateBaseImageUrl({
      imageUrl,
      imageResizeServiceUrl,
    }),
  });
  const imageIsValid = useDetectBadImage({
    imageUrl: generateResizedImageUrl({
      imageUrl,
      imageResizeServiceUrl,
      // @NOTE: to see if an exists, only try to get a tiny version of it (dont get the fullsized image)
      width: 32,
    }),
  });

  const srcSet = useMemo(
    () =>
      responsiveSizes?.reduce(
        (accum, current) =>
          `${accum}${generateResizedImageUrl({
            imageUrl,
            imageResizeServiceUrl,
            width: current,
            isSvg: false,
          })} ${current}w, `,
        "",
      ),
    [responsiveSizes, imageUrl, imageResizeServiceUrl],
  );

  const sizesAttrToUse = useMemo(() => {
    if (!relativeImageSizeInLayout) return "";

    if (typeof relativeImageSizeInLayout === "function") {
      return relativeImageSizeInLayout(theme);
    }

    return relativeImageSizeInLayout;
  }, [relativeImageSizeInLayout, theme]);

  const srcAttrs = useMemo(
    () =>
      imageIsSvg
        ? {
            src: generateResizedImageUrl({
              imageUrl,
              imageResizeServiceUrl,
              isSvg: true,
            }),
          }
        : srcSet
          ? {
              srcSet,
              sizes: sizesAttrToUse,
            }
          : {
              src: generateResizedImageUrl({
                imageUrl,
                imageResizeServiceUrl,
                width: DEFAULT_IMAGE_SIZE,
                isSvg: false,
              }),
            },
    [imageIsSvg, imageUrl, imageResizeServiceUrl, srcSet, sizesAttrToUse],
  );

  const commonImageDomProps = useMemo(
    () => ({
      loading,
      fetchPriority,
    }),
    [fetchPriority, loading],
  );

  const memoizedResizerProps = useMemo(
    () => ({
      ...srcAttrs,
      ...commonImageDomProps,
      className: `${className ?? ""} AwsResizerImage`,
      css,
      "data-testid": testId,
      ref: domRef,
    }),
    [srcAttrs, commonImageDomProps, className, css, testId, domRef],
  );

  const resizerImage = cloneElementWithCssProp(<img alt={alt} />, {
    ...props,
    ...memoizedResizerProps,
  });

  const memoizedDefaultImageProps = useMemo(
    () => ({
      ...commonImageDomProps,
      className: `${className ?? ""} ${
        defaultImageClassName ?? ""
      } AwsResizerImage AwsResizerImage--defaultImage`,
      css,
      "data-testid": `${testId}--defaultImage`,
      src: pickedDefaultImage,
      ref: domRef,
    }),
    [
      commonImageDomProps,
      className,
      defaultImageClassName,
      css,
      testId,
      domRef,
      pickedDefaultImage,
    ],
  );

  const defaultImage = cloneElementWithCssProp(<img alt={alt} />, {
    ...props,
    ...memoizedDefaultImageProps,
  });

  return imageIsValid ? resizerImage : defaultImage;
}

AwsResizerImage.displayName = "AwsResizerImage";
