export function hexToRgb(hex: string): {
  r: number;
  g: number;
  b: number;
  a?: number;
} {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex,
  );
  return result
    ? {
        r: Number.parseInt(result[1] || "00", 16),
        g: Number.parseInt(result[2] || "00", 16),
        b: Number.parseInt(result[3] || "00", 16),
        a: result[4] ? Number.parseInt(result[4], 16) / 255 : undefined,
      }
    : { r: 0, g: 0, b: 0 };
}

export function hexToRgbString(hex: string): string {
  const rgb = hexToRgb(hex);
  if (rgb.a !== undefined && rgb.a !== 1) {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${Number.parseFloat(
      rgb.a.toFixed(2),
    )})`;
  }
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
}

export function hexToRgbaString(hex: string, alpha: number): string {
  const rgb = hexToRgb(hex);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function hexWithAlphaToRgbaString(hex: string): string {
  const r = Number.parseInt(hex.slice(1, 3), 16);
  const g = Number.parseInt(hex.slice(3, 5), 16);
  const b = Number.parseInt(hex.slice(5, 7), 16);
  const a = Number.parseInt(hex.slice(7, 9), 16) / 255;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function rgbaToHex(rgba: string): string {
  // Parse the input string to get the individual red, green, blue, and alpha values
  const matches = rgba.match(
    /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/,
  );
  if (!matches) return "";

  const [, r, g, b, a] = matches;

  // Convert the red, green, and blue values to hexadecimals
  const rHex = Number.parseInt(r || "0", 10).toString(16);
  const gHex = Number.parseInt(g || "0", 10).toString(16);
  const bHex = Number.parseInt(b || "0", 10).toString(16);

  // Add leading zeros if necessary
  const rHexPadded = rHex.length === 1 ? `0${rHex}` : rHex;
  const gHexPadded = gHex.length === 1 ? `0${gHex}` : gHex;
  const bHexPadded = bHex.length === 1 ? `0${bHex}` : bHex;

  // Convert the alpha value to a hexadecimal
  const aHex = Math.round(Number.parseFloat(a || "1") * 255).toString(16);
  const aHexPadded = aHex.length === 1 ? `0${aHex}` : aHex;

  // Return the final hexadecimal color with transparency
  return `#${rHexPadded}${gHexPadded}${bHexPadded}${aHexPadded}`;
}
