import type { Properties } from "csstype";
import { merge } from "ts-deepmerge";

import { SHORTHAND_CSS_RULE_MAPPING } from "@/constants";
import type {
  BiomeTheme,
  DeeplyNestedSx,
  MakeResponsive,
  RemoveErrorBrand,
  SafeSizes,
} from "@/types";

import { isError } from "./textStyleHelpers";

export const inputResetStyles = (disabled?: boolean): Properties => ({
  margin: 0,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  cursor: disabled ? "default" : "pointer",
  border: "none",
  appearance: "none",
});

export function isSafeSize<T>(
  size: T,
  safeSizes: SafeSizes,
): size is NonNullable<RemoveErrorBrand<T>> {
  return typeof size === "string" && safeSizes.includes(size);
}

export function getStartingSize<T>(
  size: MakeResponsive<T>,
  defaultSize: T,
  safeSizes: SafeSizes,
) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  const startingSize = sizeAsArray.shift();
  return isSafeSize(startingSize, safeSizes) ? startingSize : defaultSize;
}

export function setDefaultSxBackgroundColor(sx: DeeplyNestedSx, color: string) {
  switch (true) {
    case sx.bg !== undefined:
      return { bg: color };
    case sx.bgc !== undefined:
      return { bgc: color };
    case sx.backgroundColor !== undefined:
      return { backgroundColor: color };
    case sx.background !== undefined:
    default:
      return { background: color };
  }
}

export type RenderSxProps<S extends string> = {
  theme: BiomeTheme;
  size: S;
  [x: string]: unknown;
};

export function getResponsiveSx<S extends string>({
  theme,
  size,
  renderSxAtSize,
  ...props
}: {
  theme: BiomeTheme;
  size: MakeResponsive<S>;
  renderSxAtSize: (props: RenderSxProps<S>) => Properties;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: renderSxAtSize({
            size: responsiveSize,
            theme,
            ...props,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
}

// @NOTE: these functions can likely be deprecated, due to changes in the
// way sx is handled - they should no longer be needed
export const setDefaultWidthSx = (sx: DeeplyNestedSx, width: string) =>
  setDefaultSxProp("w", sx, width);
export const setDefaultHeightSx = (sx: DeeplyNestedSx, height: string) =>
  setDefaultSxProp("h", sx, height);
export const setDefaultSxTextColor = (sx: DeeplyNestedSx, color: string) =>
  setDefaultSxProp("c", sx, color);

export function setDefaultSxProp(
  abbreviated: keyof typeof SHORTHAND_CSS_RULE_MAPPING,
  sx: DeeplyNestedSx,
  defaultValue: unknown,
) {
  const full = SHORTHAND_CSS_RULE_MAPPING[abbreviated];
  return sx[abbreviated]
    ? { [abbreviated]: defaultValue }
    : { [full]: defaultValue };
}
