import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";

import { SvgIcon } from "../SvgIcon";

export function RollupXFilled({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent("RollupXFilled");
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 33 32"
      testId={testId}
      sx={merge({ w: "200px" }, sx)}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}
RollupXFilled.displayName = "RollupXFilled";
