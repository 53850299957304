import type { DeeplyNestedSx } from "@/types";

export const baseRootSx = {
  maxw: "100%",
};

export const viewportSx = {
  overflow: "hidden",
};

export const containerSx = {
  backfaceVisibility: "hidden",
  ml: "calc(var(--slideSpacing) * -1)",
};

export const slideSx: DeeplyNestedSx = {
  flex: "0 0 var(--slideWidth)",
  height: "var(--slideHeight)",
  pl: "var(--slideSpacing)",
  minw: "0",
};

export const innerContainerBaseSx = {
  h: "100%",
  w: "100%",
  overflow: "hidden",
  brad: "base.borderRadius.x4",
  transitionProperty: "box-shadow, border-color, padding",

  "& .Card, & .CardHero": {
    flexShrink: "unset",
    boxShadow: "unset",
    h: "100%",

    "&.Card--isClickable": {
      "&:hover": {
        translate: "0px",
      },
    },
  },
};

export const controlBarBaseSx = {
  mt: "base.spacing.x4",
  flexDirection: "row",
  gap: "base.spacing.x6",
  alignItems: "center",
  justifyContent: "center",
};
