import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import type { ReactElement } from "react";

import type { GetMultiTextProps, StandardComponentWithProps } from "@/types";
import { cloneElementWithCssProp } from "@/utils";
import { staticMiddleEllipsis } from "@/utils/ellipsisHelpers";
import { Body } from "../Body";

export type EllipsizedTextBaseProps = StandardComponentWithProps<
  HTMLSpanElement,
  {
    text: string;
    leftSideLength?: number;
    rightSideLength?: number;
    use?: ReactElement;
  }
>;

export type EllipsizedTextProps<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined,
> = GetMultiTextProps<RC, Use, EllipsizedTextBaseProps>;

export function EllipsizedText<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  leftSideLength = 8,
  rightSideLength = 4,
  text,
  rc = <span />,
  sx,
  ...props
}: EllipsizedTextProps<RC, Use>) {
  const { use, ...propsMinusUse } =
    "use" in props ? props : { ...props, use: undefined };
  const { size, ...propsMinusUseAndSize } =
    "size" in propsMinusUse
      ? propsMinusUse
      : { size: undefined, ...propsMinusUse };
  const { weight, ...propsMinusUseSizeAndWeight } =
    "weight" in propsMinusUseAndSize
      ? propsMinusUseAndSize
      : { weight: undefined, ...propsMinusUseAndSize };

  const content = staticMiddleEllipsis({
    leftSideLength,
    rightSideLength,
    text,
  });
  return cloneElementWithCssProp(
    use || <Body size={size as BodySize} weight={weight as BodyWeight} />,
    {
      ...propsMinusUseSizeAndWeight,
      rc,
      sx,
      children: content,
    },
  );
}

EllipsizedText.displayName = "EllipsizedText";
