import { type ReactElement, useEffect, useMemo, useState } from "react";

import { useTheme } from "@/hooks/useTheme";
import type { LoopingTextProps } from "@/types";
import { warnUser } from "@/utils";

import { RollingText } from "../RollingText";

const DEFAULT_TEXT_DURATION = 3 * 1000;

export function LoopingText<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  text,
  textDuration = DEFAULT_TEXT_DURATION,
  className,
  testId = "LoopingText",
  rc,
  ...props
}: LoopingTextProps<RC, Use>) {
  const [index, setIndex] = useState(0);
  const { base } = useTheme();

  const safeDuration = useMemo(() => {
    if (textDuration <= base.motion.normal.gentle.jsDuration * 1030) {
      const currentMotionDuration = `${
        base.motion.normal.gentle.jsDuration * 1030
      }ms`;
      warnUser(
        `LoopingText has recieved ${textDuration}ms as a duration. This is invalid, as the text animation takes longer than this (${currentMotionDuration}). 
  TextDuration must be longer than ${currentMotionDuration}.
  A default duration (${DEFAULT_TEXT_DURATION}ms) has been applied instead.`,
      );
      return DEFAULT_TEXT_DURATION;
    }

    return textDuration;
  }, [base.motion.normal.gentle.jsDuration, textDuration]);

  useEffect(() => {
    setTimeout(() => {
      let next = index + 1;
      if (next === text.length) {
        next = 0;
      }
      setIndex(next);
    }, safeDuration);
  }, [index, text, safeDuration]);

  return (
    <RollingText
      {...props}
      rc={rc}
      testId={testId}
      text={text[index]}
      className={`${className ?? ""} LoopingText`}
    />
  );
}

LoopingText.displayName = "LoopingText";
