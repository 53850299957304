import type { ComponentProps, ReactElement, ReactNode } from "react";
import flattenChildren from "react-keyed-flatten-children";

import type { SubcomponentElement } from "@/types/core";
import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

type ReturnType<Sub> = Sub extends Array<SubcomponentElement>
  ? ReactElement<ComponentProps<Sub[number]>> | undefined
  : Sub extends SubcomponentElement
    ? ReactElement<ComponentProps<Sub>> | undefined
    : undefined;

export function useGetSubcomponentChild<
  Sub extends SubcomponentElement | Array<SubcomponentElement>,
>(
  children: ReactNode,
  subcomponent: Sub,
  excludeParent?: SubcomponentElement | Array<SubcomponentElement>,
): ReturnType<Sub> {
  const result = flattenChildren(children).find((child) => {
    if (Array.isArray(subcomponent)) {
      return subcomponent.some((sub) =>
        isChildSubcomponent(child, sub, excludeParent),
      );
    }
    return isChildSubcomponent(child, subcomponent, excludeParent);
  });

  return result as ReturnType<Sub>;
}
