import { designTokens } from "@biom3/design-tokens";
import { AnimatePresence, motion } from "motion/react";
import { useMemo } from "react";

import { Box } from "@/components/Box";
import { InlineToast } from "@/components/Toast/InlineToast";
import { useGetMotionProfile, useTheme } from "@/hooks";
import { useOverlaysStore } from "@/providers/BiomeOverlaysProvider/overlaysContext";
import { vFlex } from "@/utils/sxChunks";

type ToastGroupPosition =
  | "topLeft"
  | "topRight"
  | "topCenter"
  | "bottomLeft"
  | "bottomRight"
  | "bottomCenter";

// @NOTE: margin is applied to each one, so that when toasts are
// butting up against the edge of the screen, there's a small
// gap - for visual aesthetics
function getToastGroupPositionStyles(group: ToastGroupPosition) {
  switch (group) {
    case "topLeft":
      return {
        top: "base.spacing.x6",
        left: "base.spacing.x6",
        marginRight: "base.spacing.x4",
      };

    case "topCenter":
      return {
        top: "base.spacing.x6",
        left: "50%",
        translate: "-50% 0",
        marginLeft: "base.spacing.x4",
        marginRight: "base.spacing.x4",
      };

    case "bottomLeft":
      return {
        bottom: "base.spacing.x6",
        left: "base.spacing.x6",
        marginRight: "base.spacing.x4",
      };

    case "bottomRight":
      return {
        bottom: "base.spacing.x6",
        right: "base.spacing.x6",
        marginLeft: "base.spacing.x4",
      };

    case "bottomCenter":
      return {
        bottom: "base.spacing.x6",
        left: "50%",
        translate: "-50% 0",
        marginLeft: "base.spacing.x4",
        marginRight: "base.spacing.x4",
      };

    case "topRight":
    default: {
      return {
        top: "base.spacing.x6",
        right: "base.spacing.x6",
        marginLeft: "base.spacing.x6",
      };
    }
  }
}

const RIGHT_TRANSLATE = "12px";
const LEFT_TRANSLATE = "-12px";
const TOP_TRANSLATE = "-12px";
const BOTTOM_TRANSLATE = "12px";
const NO_TRANSLATE = "0px";

export function ToastOverlay() {
  const { state: toastList } = useOverlaysStore((state) => state.toastList);
  const motionProfile = useGetMotionProfile();
  const { base } = useTheme();

  const toastsGroupedByPosition = useMemo(() => {
    return {
      topLeft: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "top" && x === "left";
      }),
      topCenter: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "top" && x === "center";
      }),
      topRight: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "top" && x === "right";
      }),
      bottomLeft: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "bottom" && x === "left";
      }),
      bottomRight: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "bottom" && x === "right";
      }),
      bottomCenter: toastList.filter((toast) => {
        const {
          position: { x, y },
        } = toast;
        return y === "bottom" && x === "center";
      }),
    };
  }, [toastList]);

  return (
    <AnimatePresence>
      {Object.keys(toastsGroupedByPosition).map((key) => {
        const typedKey = key as ToastGroupPosition;
        const group = toastsGroupedByPosition[typedKey];
        if (group.length === 0) return null;

        return (
          <Box
            data-toast-group={typedKey}
            key={typedKey}
            testId={`toastsContainer--${typedKey}`}
            sx={{
              ...vFlex,
              ...getToastGroupPositionStyles(typedKey),
              gap: "base.spacing.x2",
              position: "fixed",
              zIndex: "base.zLevel.toast",
              alignItems: "center",
            }}
            rc={
              <motion.div
                initial={{
                  opacity: 0,
                  ...(typedKey === "topRight" && { x: RIGHT_TRANSLATE }),
                  ...(typedKey === "topLeft" && { x: LEFT_TRANSLATE }),
                  ...(typedKey === "topCenter" && { y: TOP_TRANSLATE }),
                  ...(typedKey === "bottomRight" && { x: RIGHT_TRANSLATE }),
                  ...(typedKey === "bottomLeft" && { x: LEFT_TRANSLATE }),
                  ...(typedKey === "bottomCenter" && { y: BOTTOM_TRANSLATE }),
                }}
                exit={{
                  opacity: 0,
                  ...(typedKey === "topRight" && { x: RIGHT_TRANSLATE }),
                  ...(typedKey === "topLeft" && { x: LEFT_TRANSLATE }),
                  ...(typedKey === "topCenter" && { y: TOP_TRANSLATE }),
                  ...(typedKey === "bottomRight" && { x: RIGHT_TRANSLATE }),
                  ...(typedKey === "bottomLeft" && { x: LEFT_TRANSLATE }),
                  ...(typedKey === "bottomCenter" && { y: BOTTOM_TRANSLATE }),
                }}
                animate={{
                  opacity: 1,
                  ...(typedKey === "topRight" && { x: NO_TRANSLATE }),
                  ...(typedKey === "topLeft" && { x: NO_TRANSLATE }),
                  ...(typedKey === "topCenter" && { y: NO_TRANSLATE }),
                  ...(typedKey === "bottomRight" && { x: NO_TRANSLATE }),
                  ...(typedKey === "bottomLeft" && { x: NO_TRANSLATE }),
                  ...(typedKey === "bottomCenter" && { y: NO_TRANSLATE }),
                }}
                transition={{
                  duration: designTokens.base.motion.normal.fast.jsDuration,
                  ease: designTokens.base.motion.normal.fast.jsEase,
                }}
              />
            }
          >
            <AnimatePresence>
              {group.map((toast) => {
                const {
                  id,
                  testId,
                  content,
                  autoDismissDuration,
                  position,
                  ...otherToastProps
                } = toast;
                return (
                  <InlineToast
                    {...otherToastProps}
                    id={id}
                    className="OverlayToast"
                    testId={testId ?? "toastsContainer__toast"}
                    key={id}
                    rc={
                      motionProfile === "none" ? undefined : (
                        <motion.div
                          initial={{
                            opacity: 0,
                            ...(typedKey === "topRight" && {
                              x: RIGHT_TRANSLATE,
                            }),
                            ...(typedKey === "topLeft" && {
                              x: LEFT_TRANSLATE,
                            }),
                            ...(typedKey === "topCenter" && {
                              y: TOP_TRANSLATE,
                            }),
                            ...(typedKey === "bottomRight" && {
                              x: RIGHT_TRANSLATE,
                            }),
                            ...(typedKey === "bottomLeft" && {
                              x: LEFT_TRANSLATE,
                            }),
                            ...(typedKey === "bottomCenter" && {
                              y: BOTTOM_TRANSLATE,
                            }),
                          }}
                          exit={{
                            opacity: 0,
                            ...(typedKey === "topRight" && {
                              x: RIGHT_TRANSLATE,
                            }),
                            ...(typedKey === "topLeft" && {
                              x: RIGHT_TRANSLATE,
                            }),
                            ...(typedKey === "topCenter" && {
                              y: TOP_TRANSLATE,
                            }),
                            ...(typedKey === "bottomRight" && {
                              x: RIGHT_TRANSLATE,
                            }),
                            ...(typedKey === "bottomLeft" && {
                              x: LEFT_TRANSLATE,
                            }),
                            ...(typedKey === "bottomCenter" && {
                              y: BOTTOM_TRANSLATE,
                            }),
                          }}
                          animate={{
                            opacity: 1,
                            ...(typedKey === "topRight" && { x: NO_TRANSLATE }),
                            ...(typedKey === "topLeft" && { x: NO_TRANSLATE }),
                            ...(typedKey === "topCenter" && {
                              y: NO_TRANSLATE,
                            }),
                            ...(typedKey === "bottomRight" && {
                              x: NO_TRANSLATE,
                            }),
                            ...(typedKey === "bottomLeft" && {
                              x: NO_TRANSLATE,
                            }),
                            ...(typedKey === "bottomCenter" && {
                              y: NO_TRANSLATE,
                            }),
                          }}
                          transition={{
                            duration:
                              base.motion.normal[motionProfile].jsDuration,
                            ease: base.motion.normal[motionProfile].jsEase,
                          }}
                        />
                      )
                    }
                  >
                    {content}
                  </InlineToast>
                );
              })}
            </AnimatePresence>
          </Box>
        );
      })}
    </AnimatePresence>
  );
}
