import type { DeeplyNestedSx } from "@/types";

export const chipBaseSx = { pos: "relative" };

export const baseMultiSelectSx: DeeplyNestedSx = {
  pr: "base.spacing.x10",
  cursor: "pointer",

  "&.MultiSelect--selectedChipsWrap": {
    height: "unset",
    py: "base.spacing.x2",
    alignItems: "flex-start",

    "& > .MultiSelect__chipsContainer": {
      flexWrap: "wrap",
      overflowX: "unset",
    },

    "& .Chip__label": { whiteSpace: "normal" },
  },

  "&.MultiSelect--medium": {
    "&.MultiSelect--selectedChipsWrap": {
      "& .MultiSelect__defaultLabel": {
        mt: "base.spacing.x1",
      },
      "& .MultiSelect__leftIcon": {
        mt: ({ base }) => `calc(${base.spacing.x1} - 1px)`,
      },
      "& .MultiSelect__chevron": {
        mt: ({ base }) => `calc(${base.spacing.x1} + 1px)`,
      },
    },
    "& > .MultiSelect__chipsContainer": {
      minh: "base.spacing.x8",
    },
  },

  "&.MultiSelect--large": {
    "&.MultiSelect--selectedChipsWrap": {
      "& .MultiSelect__defaultLabel": {
        mt: "base.spacing.x3",
      },
      "& .MultiSelect__leftIcon": {
        mt: ({ base }) => `calc(${base.spacing.x3} - 1px)`,
      },
      "& .MultiSelect__chevron": {
        mt: ({ base }) => `calc(${base.spacing.x3} + 1px)`,
      },
    },
    "& > .MultiSelect__chipsContainer": {
      minh: "base.spacing.x12",
    },
  },

  "& > .MultiSelect__chipsContainer": { pos: "relative" },
  "& .Chip__label": { whiteSpace: "nowrap" },
};

export const baseChipsContainerSx = {
  pos: "relative",
  flexWrap: "nowrap",
  overflowX: "auto",

  "&::-webkit-scrollbar": {
    display: "none",
  },
};

export const defaultLabelSx = { c: "base.color.text.body.secondary" };

export const multiSelectOptionSx = {
  "&.MultiSelectOption": {
    "&:has(.actualInput:checked) .MenuItem": {
      borderColor: "transparent",
    },
  },
};
