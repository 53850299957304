import type { ReactElement } from "react";

import { Icon } from "@/components/Icon";
import type { IconProps } from "@/types";

export function PriceDisplayIcon<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: IconProps<RC>) {
  return <Icon {...props} rc={rc} />;
}
