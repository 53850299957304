import type { BiomeTheme, MakeResponsive } from "@/types";
import { getResponsiveSx } from "@/utils/styleHelpers";

import type { CountdownTimerSize, CountdownTimerVariant } from "./shared";

export const countDownContainerBaseSx = {
  flexShrink: 0,
  flexGrow: 0,
  flexWrap: "nowrap",
  position: "relative",
};

export const baseTileSx = {
  backdropFilter: "blur(28px)",
  "& .Heading, & .Body": {
    c: "inherit",
  },
};

export function getTileSx({
  size,
  theme,
}: {
  size: CountdownTimerSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        width: theme.base.spacing.x12,
        height: theme.base.spacing.x8,
        borderRadius: theme.base.borderRadius.x2,
      };

    case "large":
      return {
        width: theme.base.spacing.x16,
        height: theme.base.spacing.x16,
        borderRadius: theme.base.borderRadius.x6,
      };

    case "xLarge":
      return {
        width: theme.base.spacing.x20,
        height: theme.base.spacing.x20,
        borderRadius: theme.base.borderRadius.x6,
      };

    case "medium":
    default:
      return {
        width: theme.base.spacing.x12,
        height: theme.base.spacing.x12,
        borderRadius: theme.base.borderRadius.x4,
      };
  }
}

export const getResponsiveTileSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<CountdownTimerSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getTileSx,
  });

export function getTileVariantSx(variant: CountdownTimerVariant) {
  switch (variant) {
    case "inverse":
      return {
        bg: "base.color.translucent.inverse.600",
        c: "base.color.text.body.primary",
      };

    case "primary":
    default:
      return {
        bg: "base.color.translucent.standard.100",
        c: "base.color.text.body.inverse.primary",
      };
  }
}
