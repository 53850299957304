import { Children, type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import {
  useForwardLocalDomRef,
  useGetSubcomponentChild,
  useResizeObserver,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";
import { isChildSubcomponent } from "@/utils";

import { BannerCaption } from "./BannerCaption";
import { BannerIcon } from "./BannerIcon";
import { BannerRightButtCon } from "./BannerRightButtCon";
import { BannerRightButton } from "./BannerRightButton";
import { BannerTitle } from "./BannerTitle";
import {
  bannerSx,
  getBannerColoring,
  rightHandButtonsContainerSx,
} from "./styles";
import { type BannerProps, DEFAULT_BANNER_VARIANT } from "./types";

export function Banner<RC extends ReactElement | undefined = undefined>({
  children,
  variant = DEFAULT_BANNER_VARIANT,
  sx = {},
  testId = "banner",
  forceMultilineText,
  domRef = { current: null },
  className,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & BannerProps
  : BannerProps & { rc: RC }) {
  const theme = useTheme();
  const localDomRef = useForwardLocalDomRef(domRef);
  const icon = useGetSubcomponentChild(children, BannerIcon);
  const title = useGetSubcomponentChild(children, BannerTitle);
  const caption = useGetSubcomponentChild(children, BannerCaption);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    BannerIcon,
    BannerTitle,
    BannerCaption,
  ]);
  const hasIcon = useMemo(() => Boolean(icon), [icon]);
  const mergedSx = useMemo(
    () =>
      merge(
        bannerSx,
        {
          ...(hasIcon ? { paddingLeft: "base.spacing.x14" } : {}),
          alignItems: forceMultilineText ? "flex-start" : "center",
        },
        getBannerColoring(variant),
        theme.components?.Banner?.sxOverride ?? {},
        sx,
      ),
    [
      sx,
      theme.components?.Banner?.sxOverride,
      variant,
      hasIcon,
      forceMultilineText,
    ],
  );
  const { height } = useResizeObserver(localDomRef);
  const iconTopMargin = { top: height > 64 ? "16px" : "19px" };

  return (
    <Box
      {...props}
      sx={mergedSx}
      testId={testId}
      domRef={localDomRef}
      className={`${className ?? ""} Banner Banner--${variant}`}
    >
      {icon && (
        <SmartClone
          variant={variant}
          testId={icon.props.testId ? icon.props.testId : `${testId}__icon`}
          sx={
            icon.props.sx ? merge(iconTopMargin, icon.props.sx) : iconTopMargin
          }
        >
          {icon}
        </SmartClone>
      )}
      <Box
        sx={{
          d: "flex",
          ...(forceMultilineText
            ? { flexDirection: "column", flex: 1 }
            : { flexWrap: "wrap", columnGap: "base.spacing.x3" }),
        }}
        className="innerTextContainer"
      >
        <SmartClone variant={variant} testId={`${testId}__title`}>
          {title}
        </SmartClone>
        <SmartClone variant={variant} testId={`${testId}__caption`}>
          {caption}
        </SmartClone>
      </Box>
      {otherChildren ||
      (Array.isArray(otherChildren) && otherChildren.length) ? (
        <Box
          className="rightContainer"
          testId={`${testId}__rightContainer`}
          sx={{
            ...rightHandButtonsContainerSx,
            ...(forceMultilineText
              ? { alignSelf: "flex-start", flexDirection: "column" }
              : {}),
          }}
        >
          {Children.map(otherChildren, (child) => {
            const isButtConSubcomponent = isChildSubcomponent(
              child,
              BannerRightButtCon,
            );
            return isChildSubcomponent(child, BannerRightButton) ||
              isButtConSubcomponent ? (
              <SmartClone
                bannerVariant={variant}
                testId={
                  isButtConSubcomponent
                    ? `${testId}__rightButtCon`
                    : `${testId}__rightButton`
                }
              >
                {child}
              </SmartClone>
            ) : (
              child
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
}
Banner.displayName = "Banner";
Banner.Icon = BannerIcon;
Banner.Title = BannerTitle;
Banner.Caption = BannerCaption;
Banner.RightButton = BannerRightButton;
Banner.RightButtCon = BannerRightButtCon;
