import type { BiomeTheme, ImageSizeVariant, MakeResponsive } from "@/types";
import { getImageSizeMapping } from "@/utils";
import { getResponsiveSx } from "@/utils/styleHelpers";

const maskImagePrefix = `url('data:image/svg+xml;utf8,`;
const svgXmlns = `xmlns="http://www.w3.org/2000/svg"`;
export const baseContainerSx = {
  pos: "relative",
  d: "block",

  "&.FramedStack--square.FramedStack--spiral": {
    "& .FramedStack__innerContainer": {
      w: "137.5%",
      h: "137.5%",
      top: "50%",
      left: "50%",
      translate: "-50% -50%",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 88 88" ${svgXmlns}><path d="M0,0v88h88V0H0z M77,74.1c0,1.6-1.3,2.9-2.9,2.9H13.9c-1.6,0-2.9-1.3-2.9-2.9V13.9c0-1.6,1.3-2.9,2.9-2.9h60.1c1.6,0,2.9,1.3,2.9,2.9V74.1z"/></svg>')`,
    },

    "& .FramedStack__innerContainer__image": {
      transformOrigin: "50% 50%",
      left: "50%",
      top: "50%",
      translate: "-50% -50%",
    },

    "& .FramedStack__innerContainer__image--secondary": {
      rotate: "-24deg",
    },
    "& .FramedStack__innerContainer__image--tertiary": {
      rotate: "-48deg",
    },
  },

  "&.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      w: "118.75%",
      h: "100%",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 64" ${svgXmlns}><path d="M63,64h3h6c2.2,0,4-1.8,4-4V4c0-2.2-1.8-4-4-4h-6h-3c1.2,0.9,2,2.4,2,4v56C65,61.6,64.2,63.1,63,64z"/></svg>')`,
    },

    "& .FramedStack__innerContainer__image--secondary": {
      position: "relative",
    },
    "& .FramedStack__innerContainer__image--tertiary": {
      right: 0,
    },
  },

  "&.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      right: 0,
      w: "118.75%",
      h: "100%",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 64" ${svgXmlns}><path d="M12.9995 0C11.7853 0.912229 11 2.3644 11 4V60C11 61.6356 11.7853 63.0878 12.9995 64H0V0H12.9995Z"/></svg>')`,
    },

    "& .FramedStack__innerContainer__image--secondary": {
      position: "relative",
    },
    "& .FramedStack__innerContainer__image--tertiary": {
      left: 0,
    },
  },

  "&.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      w: "118.75%",
      h: "118.75%",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 76" ${svgXmlns}><path d="M0 63.0005V76H76V0H63.0005C64.2147 0.912229 65 2.3644 65 4V60C65 62.7614 62.7614 65 60 65H4C2.3644 65 0.912229 64.2147 0 63.0005Z"/></svg>')`,
    },

    "& .FramedStack__innerContainer__image--secondary": {
      position: "relative",
    },
    "& .FramedStack__innerContainer__image--tertiary": {
      right: 0,
      bottom: 0,
    },
  },

  "&.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      right: 0,
      w: "118.75%",
      h: "118.75%",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 76" ${svgXmlns}><path d="M12.9995 0C11.7853 0.912229 11 2.3644 11 4V60C11 62.7614 13.2386 65 16 65H72C73.6356 65 75.0878 64.2147 76 63.0005V76H0V0H12.9995Z"/></svg>')`,
    },

    "& .FramedStack__innerContainer__image--secondary": {
      position: "relative",
    },

    "& .FramedStack__innerContainer__image--tertiary": {
      bottom: 0,
      left: 0,
    },
  },

  "&.FramedStack--circle.FramedStack--spiral": {
    "& .FramedStack__innerContainer": {
      w: "137.5%",
      h: "100%",
      left: "50%",
      translate: "-50% 0",
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 88 64" ${svgXmlns}> <path d="M35.9056 0C21.5936 3.60895 11 16.5673 11 32C11 47.4327 21.5936 60.3911 35.9056 64H24H0V0H24H35.9056ZM52.0944 0C66.4064 3.60895 77 16.5673 77 32C77 47.4327 66.4064 60.3911 52.0944 64H64H88V0H64H52.0944Z"/> </svg>')`,
    },

    "& .FramedStack__innerContainer__image": {
      top: 0,
    },
    "& .FramedStack__innerContainer__image--secondary": {
      right: 0,
    },
    "& .FramedStack__innerContainer__image--tertiary": {
      left: 0,
    },
  },

  "&.FramedStack--circle.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 76" ${svgXmlns}><path d="M0 40.0944V76H76V0H40.0944C54.4064 3.60895 65 16.5673 65 32C65 50.2254 50.2254 65 32 65C16.5673 65 3.60895 54.4064 0 40.0944ZM23.9056 0H0V23.9056C2.95586 12.1835 12.1835 2.95586 23.9056 0Z"/></svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      right: 0,
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 76" ${svgXmlns}><path d="M35.9,0L0,0l0,76h76V40.1C72.4,54.4,59.4,65,44,65c-18.2,0-33-14.8-33-33C11,16.6,21.6,3.6,35.9,0z M76,23.9V0L52.1,0C63.8,3,73,12.2,76,23.9z"/></svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 64" ${svgXmlns}> <path d="M0 40.0944V64H23.9056C12.1835 61.0441 2.95586 51.8165 0 40.0944ZM40.0944 64H76V0H40.0944C54.4064 3.60895 65 16.5673 65 32C65 47.4327 54.4064 60.3911 40.0944 64ZM23.9056 0H0V23.9056C2.95586 12.1835 12.1835 2.95586 23.9056 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 76 64" ${svgXmlns}> <path d="M35.9056 0C21.5936 3.60895 11 16.5673 11 32C11 47.4327 21.5936 60.3911 35.9056 64H0V0H35.9056ZM52.0944 0C63.8165 2.95586 73.0441 12.1835 76 23.9056V0H52.0944ZM76 40.0944C73.0441 51.8165 63.8165 61.0441 52.0944 64H76V40.0944Z"/> </svg>')`,
    },
  },

  //
  //
  // CLEANUP MEDIUM SIZING
  //
  "&.FramedStack--medium.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      w: "125%",
      h: "125%",
    },
  },

  "&.FramedStack--medium.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      w: "125%",
      h: "125%",
    },
  },

  "&.FramedStack--medium.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      w: "125%",
    },
  },

  "&.FramedStack--medium.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      w: "125%",
    },
  },

  //
  //
  // CLEANUP SMALL SIZING
  //
  "&.FramedStack--small.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      w: "133.5%",
      h: "133.5%",
    },
  },

  "&.FramedStack--small.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      w: "133.5%",
      h: "133.5%",
    },
  },

  "&.FramedStack--small.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      w: "133.5%",
    },
  },

  "&.FramedStack--small.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      w: "133.5%",
    },
  },

  //
  //
  // CLEANUP MEDIUM SQUARE MASKS
  //
  "&.FramedStack--square.FramedStack--medium.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 40" ${svgXmlns}> <path d="M0 31.0004V40H40V0H31.0005C32.2147 0.91223 33 2.3644 33 3.99999V28C33 30.7614 30.7615 33 28 33H4.00004C2.36442 33 0.912227 32.2146 0 31.0004Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--medium.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 40" ${svgXmlns}> <path d="M8.99947 0C7.78532 0.91223 6.99998 2.3644 6.99998 3.99999V28C6.99998 30.7614 9.23856 33 12 33H36C37.6356 33 39.0878 32.2146 40 31.0004V40H0V0H8.99947Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--medium.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 44 32" ${svgXmlns}> <path d="M31.0004 32.0001H44V6.10352e-05H31.0006C32.2147 0.912294 33 2.36444 33 4V28C33 29.6356 32.2146 31.0878 31.0004 32.0001Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--medium.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 32" ${svgXmlns}> <path d="M8.99951 0C7.78534 0.912229 7 2.3644 7 4V28C7 29.6356 7.78534 31.0878 8.99951 32H0V0H8.99951Z"/> </svg>')`,
    },
  },

  //
  //
  // CLEANUP MEDIUM SQUARE MASKS
  //
  "&.FramedStack--square.FramedStack--small.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}> <path d="M-3.05176e-05 23.0004V32H32V0H23.0005C24.2147 0.91223 25 2.36439 25 3.99998V20C25 22.7614 22.7614 25 20 25H4C2.36438 25 0.912196 24.2146 -3.05176e-05 23.0004Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--small.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}> <path d="M8.9995 0C7.78534 0.91223 7 2.3644 7 3.99999V20C7 22.7614 9.23858 25 12 25H28C29.6356 25 31.0878 24.2147 32 23.0005V32H-1.52588e-05V0H8.9995Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--small.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 24" ${svgXmlns}> <path d="M4.57764e-05 23.0005V24H0.9996C0.621159 23.7157 0.284376 23.3789 4.57764e-05 23.0005ZM23.0004 24H28C30.2092 24 32 22.2091 32 20V4C32 1.79086 30.2092 0 28 0H23.0006C24.2147 0.912233 25 2.36438 25 3.99994V19.9999C25 21.6356 24.2146 23.0878 23.0004 24ZM0.999437 0H4.57764e-05V0.999399C0.284335 0.621022 0.621063 0.284292 0.999437 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--square.FramedStack--small.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 24" ${svgXmlns}> <path d="M8.99951 0C7.78534 0.912229 7 2.3644 7 4V20C7 21.6356 7.78534 23.0878 8.99951 24H0V0H8.99951Z"/> </svg>')`,
    },
  },

  //
  //
  // CLEANUP MEDIUM CIRCULAR MASKS
  //
  "&.FramedStack--circle.FramedStack--medium.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 40" ${svgXmlns}> <path d="M3.05176e-05 21.7583V40H40V0H21.7584C28.3132 2.35943 33 8.63236 33 16C33 25.3888 25.3888 33 16 33C8.63239 33 2.35946 28.3131 3.05176e-05 21.7583ZM10.2416 0H3.05176e-05V10.2416C1.71217 5.48505 5.48508 1.71214 10.2416 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--medium.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 40" ${svgXmlns}> <path d="M18.2415 0C11.6868 2.35945 6.99998 8.63236 6.99998 15.9999C6.99998 25.3888 14.6111 32.9999 24 32.9999C31.3676 32.9999 37.6406 28.313 40 21.7581V40H0V0H18.2415ZM29.7584 0C34.515 1.71217 38.2879 5.48513 40 10.2417V0H29.7584Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--medium.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 32" ${svgXmlns}> <path d="M0 21.7583V32H10.2417C5.48511 30.2879 1.71213 26.5149 0 21.7583ZM21.7583 32H40V0H21.7583C28.3131 2.3594 33 8.63236 33 16C33 23.3676 28.3131 29.6406 21.7583 32ZM10.2417 0H0V10.2417C1.71213 5.48511 5.48511 1.71213 10.2417 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--medium.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 40 32" ${svgXmlns}> <path d="M18.2417 0C11.6869 2.3594 7 8.63236 7 16C7 23.3676 11.6869 29.6406 18.2417 32H0V0H18.2417ZM29.7583 0C34.5149 1.71213 38.2879 5.48511 40 10.2417V0H29.7583ZM40 21.7583C38.2879 26.5149 34.5149 30.2879 29.7583 32H40V21.7583Z"/> </svg>')`,
    },
  },

  //
  //
  // CLEANUP SMALL CIRCULAR MASKS
  //
  "&.FramedStack--circle.FramedStack--small.FramedStack--rightDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}> <path d="M4.57764e-05 17.0089V32H32V0H17.0089C21.7021 1.96126 25 6.59547 25 12C25 19.1797 19.1797 25 12 25C6.59552 25 1.96131 21.702 4.57764e-05 17.0089ZM6.99111 0H4.57764e-05V6.99106C1.31709 3.83946 3.8395 1.31704 6.99111 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--small.FramedStack--leftDiagonal": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 32" ${svgXmlns}> <path d="M14.991 0C10.2979 1.96128 7 6.59547 7 11.9999C7 19.1796 12.8203 24.9999 20 24.9999C25.4045 24.9999 30.0387 21.702 32 17.0088V32H-1.52588e-05V0H14.991ZM25.009 0C28.1606 1.31706 30.683 3.83948 32 6.99109V0H25.009Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--small.FramedStack--right": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 24" ${svgXmlns}> <path d="M0 17.0088V24H6.9912C3.83952 22.683 1.31705 20.1605 0 17.0088ZM17.0088 24H32V0H17.0088C21.702 1.96124 25 6.59548 25 12C25 17.4045 21.702 22.0388 17.0088 24ZM6.9912 0H0V6.9912C1.31705 3.83952 3.83952 1.31705 6.9912 0Z"/> </svg>')`,
    },
  },

  "&.FramedStack--circle.FramedStack--small.FramedStack--left": {
    "& .FramedStack__innerContainer": {
      WebkitMaskImage: `${maskImagePrefix} <svg viewBox="0 0 32 24" ${svgXmlns}> <path d="M14.9912 0C10.298 1.96124 7 6.59548 7 12C7 17.4045 10.298 22.0388 14.9912 24H0V0H14.9912ZM25.0088 0C28.1605 1.31705 30.683 3.83952 32 6.9912V0H25.0088ZM32 17.0088C30.683 20.1605 28.1605 22.683 25.0088 24H32V17.0088Z"/> </svg>')`,
    },
  },
};

export const baseMainImageSx = {
  h: "100%",
  w: "100%",
};

export function getContainerSx({
  size,
  theme: { base },
}: {
  size: ImageSizeVariant;
  theme: BiomeTheme;
}) {
  const sizeMapping = getImageSizeMapping("FramedStack", { base });
  return {
    width: sizeMapping[size],
    height: sizeMapping[size],

    "& .FramedStack__innerContainer__image": {
      width: sizeMapping[size],
      height: sizeMapping[size],
    },
  };
}

export const getResponsiveContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ImageSizeVariant>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerSx,
  });

export const baseOuterLayerSx = {
  pos: "absolute",
  WebkitMaskRepeat: "no-repeat",
  WebkitMaskSize: "cover",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .FramedStack__innerContainer__image": {
    position: "absolute",
  },

  "& .FramedStack__innerContainer__image--tertiary": {
    opacity: 0.48,
  },
};
