import { type MouseEvent, type ReactElement, useMemo } from "react";

import type { AvatarProps } from "@/components/Avatar";
import type { CHIP_SIZES } from "@/constants";
import type {
  DistributiveOmit,
  FixedSizeFramedImageProps,
  GetHybridClickableRCandDomProps,
  IconProps,
  ImageSizeVariant,
  MakeResponsive,
  OnClickFunction,
} from "@/types";

export type ChipSize = (typeof CHIP_SIZES)[number];
export type ChipVariant = "regular" | "inverse";
export type ChipProps<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick> & {
  label: string;
  size?: MakeResponsive<ChipSize>;
  variant?: ChipVariant;
  onRemove?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type ChipIconProps<RC extends ReactElement | undefined> =
  IconProps<RC> & { size?: MakeResponsive<ChipSize> };

export type ChipFramedImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = FixedSizeFramedImageProps<RC, Use> & {
  size?: MakeResponsive<ChipSize>;
};

export type ChipAvatarProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = DistributiveOmit<AvatarProps<RC, Use>, "size"> & {
  size?: MakeResponsive<ChipSize>;
  avatarSize?: MakeResponsive<ImageSizeVariant>;
};

export function isChipSizeAnArray(
  size: unknown,
): size is Array<ChipSize | null> {
  return Array.isArray(size);
}

export function getImageSizeForChipSize(
  chipSize: ChipSize | null,
): ImageSizeVariant | null {
  switch (chipSize) {
    case "large":
      return "medium";
    case "medium":
      return "small";
    case null:
    default:
      return null;
  }
}

export function useGetFramedImageSizeFromChipSize(
  size: MakeResponsive<ChipSize>,
) {
  const chosenBiomeImageSize = useMemo(() => {
    return isChipSizeAnArray(size)
      ? size.map((s) => getImageSizeForChipSize(s))
      : getImageSizeForChipSize(size as ChipSize);
  }, [size]);
  return chosenBiomeImageSize as MakeResponsive<ImageSizeVariant>;
}
