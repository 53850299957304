import { type ReactElement, type ReactNode, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { DEFAULT_IMAGE_SIZE_VARIANT, IMAGE_SIZE_VARIANTS } from "@/constants";
import {
  useGetCurrentSizeClass,
  useGetResponsiveImageSizes,
  useGetSubcomponentChild,
  useTheme,
} from "@/hooks";
import type {
  DomPropsWithDomRef,
  FramedComponentBaseProps,
  ImageSizeVariant,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  type FramedStackImageProps,
  FramedStackPrimaryImage,
  FramedStackSecondaryImage,
  FramedStackTertiaryImage,
} from "./FramedStackImages";
import {
  baseContainerSx,
  baseMainImageSx,
  baseOuterLayerSx,
  getContainerSx,
  getResponsiveContainerSx,
} from "./styles";

export type FramedStackBaseProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    direction?: "spiral" | "rightDiagonal" | "leftDiagonal" | "left" | "right";
    size?: MakeResponsive<ImageSizeVariant>;
    children?: ReactNode;
  } & FramedComponentBaseProps
>;

export type FramedStackProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"div"> & FramedStackBaseProps
    : FramedStackBaseProps & { rc: RC };

export function FramedStack<RC extends ReactElement | undefined = undefined>({
  direction = "spiral",
  testId = "FramedStack",
  circularFrame,
  padded = false,
  emphasized = false,
  className,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  sx = {},
  children,
  ...props
}: FramedStackProps<RC>) {
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const theme = useTheme();
  const containerSx = useMemo(
    () =>
      merge(
        baseContainerSx,
        getContainerSx({ theme, size: startingSize }),
        getResponsiveContainerSx({ theme, size }),
        sx,
      ),
    [sx, theme, size, startingSize],
  );
  const responsiveSizes = useGetResponsiveImageSizes(size, "FramedStack");

  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );

  const primaryImage = useGetSubcomponentChild(
    children,
    FramedStackPrimaryImage,
  );
  const secondaryImage = useGetSubcomponentChild(
    children,
    FramedStackSecondaryImage,
  );
  const tertiaryImage = useGetSubcomponentChild(
    children,
    FramedStackTertiaryImage,
  );

  const secondaryImageWithFallback = useMemo(() => {
    if (secondaryImage) return secondaryImage;

    // @NOTE: if no image is supplied for the Secondary image, we will simply use the Primary image
    const pickedSecondaryImageUrl = (
      primaryImage?.props as FramedStackImageProps<undefined>
    )?.imageUrl;
    const secondaryImageUseProp = primaryImage?.props?.use;
    return secondaryImageUseProp ? (
      <FramedStackSecondaryImage use={secondaryImageUseProp} />
    ) : (
      <FramedStackSecondaryImage imageUrl={pickedSecondaryImageUrl} />
    );
  }, [primaryImage, secondaryImage]);

  const tertiaryImageWithFallback = useMemo(() => {
    if (tertiaryImage) return tertiaryImage;

    // @NOTE: if no image is supplied for the Tertiary image, we will simply use the Primary image
    const pickedTertiaryImageUrl = (
      primaryImage?.props as FramedStackImageProps<undefined>
    )?.imageUrl;
    const tertiaryImageUseProp = primaryImage?.props?.use;
    return tertiaryImageUseProp ? (
      <FramedStackTertiaryImage use={tertiaryImageUseProp} />
    ) : (
      <FramedStackTertiaryImage imageUrl={pickedTertiaryImageUrl} />
    );
  }, [tertiaryImage, primaryImage]);

  const commonImageProps = useMemo(
    () => ({
      circularFrame,
      responsiveSizes,
      padded,
      emphasized,
      testId,
      size,
    }),
    [circularFrame, testId, responsiveSizes, size, padded, emphasized],
  );
  const primaryImageProps = useMemo(
    () => ({
      ...commonImageProps,
      use: primaryImage?.props?.use || undefined,
      sx: baseMainImageSx,
    }),
    [primaryImage, commonImageProps],
  );
  const secondaryImageProps = useMemo(
    () => ({
      ...commonImageProps,
      use: secondaryImageWithFallback?.props?.use || undefined,
    }),
    [secondaryImageWithFallback, commonImageProps],
  );
  const tertiaryImageProps = useMemo(
    () => ({
      ...commonImageProps,
      use: tertiaryImageWithFallback?.props?.use || undefined,
    }),
    [tertiaryImageWithFallback, commonImageProps],
  );

  return (
    <Box
      {...props}
      testId={testId}
      className={`${
        className ?? ""
      } FramedStack FramedStack--${sizeClass} FramedStack--${direction} FramedStack--${
        circularFrame ? "circle" : "square"
      }`}
      sx={containerSx}
    >
      <Box className="FramedStack__innerContainer" sx={baseOuterLayerSx}>
        <SmartClone {...tertiaryImageProps}>
          {tertiaryImageWithFallback}
        </SmartClone>
        <SmartClone {...secondaryImageProps}>
          {secondaryImageWithFallback}
        </SmartClone>
      </Box>
      <SmartClone {...primaryImageProps}>{primaryImage}</SmartClone>
    </Box>
  );
}

FramedStack.displayName = "FramedStack";
FramedStack.PrimaryImage = FramedStackPrimaryImage;
FramedStack.SecondaryImage = FramedStackSecondaryImage;
FramedStack.TertiaryImage = FramedStackTertiaryImage;
