import type { ReactElement } from "react";

import { AspectRatioImage } from "@/components/AspectRatioImage";
import type { AspectRatioBaseProps, FixedSizeCloudImageProps } from "@/types";

import { CURRENCY_IMAGE_SIZE } from "./styles";

export type PriceDisplayCurrencyImageProps<
  Use extends ReactElement | undefined = undefined,
> = FixedSizeCloudImageProps<Use> & AspectRatioBaseProps;

export function PriceDisplayCurrencyImage<
  Use extends ReactElement | undefined,
>({ className, use, ...props }: PriceDisplayCurrencyImageProps<Use>) {
  return (
    <AspectRatioImage
      {...props}
      use={use}
      className={`${className ?? ""} PriceDisplayCurrencyImage`}
      relativeImageSizeInLayout={CURRENCY_IMAGE_SIZE}
    />
  );
}

PriceDisplayCurrencyImage.displayName = "PriceDisplay.CurrencyImage";
