import {
  Children,
  type ReactElement,
  type ReactNode,
  isValidElement,
  useMemo,
} from "react";

import { Divider } from "@/components/Divider";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type { InputTextAlign } from "@/types";

import { InputListCaption } from "./InputListCaption";
import { InputListFormControl } from "./InputListFormControl";
import { InputListInputBar } from "./InputListInputBar";
import { InputListLabel } from "./InputListLabel";
import { type InputListProps, SUPPORTED_INPUT_COMPONENTS_MAP } from "./shared";

function shouldCloneChild(
  child: ReactNode,
): child is ReactElement<{ textAlign?: InputTextAlign }> {
  return (
    isValidElement(child) &&
    (child.type === InputListInputBar || child.type === InputListFormControl)
  );
}

export function InputList<RC extends ReactElement | undefined>({
  children,
  textAlign = "left",
  id,
  gap = "base.spacing.x1",
  className,
  dividerSize = "xSmall",
  hideFormControlDividers = false,
  ...props
}: InputListProps<RC>) {
  const label = useGetSubcomponentChild(children, InputListLabel);
  const caption = useGetSubcomponentChild(children, InputListCaption);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    InputListLabel,
    InputListCaption,
  ]);
  const containsInputBars = useMemo(() => {
    return (
      (Array.isArray(otherChildren) &&
        otherChildren?.some(
          (child) => isValidElement(child) && child.type === InputListInputBar,
        )) ||
      (isValidElement(otherChildren) &&
        otherChildren.type === InputListInputBar)
    );
  }, [otherChildren]);

  return (
    <Stack
      {...props}
      gap={gap}
      direction="column"
      className={`${className ?? ""} InputList InputList--${textAlign}Align`}
    >
      {label && <SmartClone textAlign={textAlign}>{label}</SmartClone>}
      {containsInputBars ? null : (
        <Divider size={dividerSize} testId="topDivider" />
      )}
      {Children.map(otherChildren, (child) => {
        const shouldClone = shouldCloneChild(child);
        const propsProvidedChild = shouldClone ? (
          <SmartClone textAlign={child?.props?.textAlign ?? textAlign}>
            {child}
          </SmartClone>
        ) : (
          child
        );
        return containsInputBars ? (
          propsProvidedChild
        ) : (
          <>
            {propsProvidedChild}
            {!hideFormControlDividers ? (
              <Divider size={dividerSize} testId="formControlDivider" />
            ) : null}
          </>
        );
      })}
      {caption && <SmartClone textAlign={textAlign}>{caption}</SmartClone>}
    </Stack>
  );
}

InputList.displayName = "InputList";

InputList.Label = InputListLabel;
InputList.Caption = InputListCaption;
InputList.InputBar = InputListInputBar;
InputList.inputs = SUPPORTED_INPUT_COMPONENTS_MAP;
InputList.FormControl = InputListFormControl;
