import { Children, isValidElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { SmartClone } from "@/components/SmartClone";
import { Stack, type StackProps } from "@/components/Stack";
import { Body } from "@/components/Text";
import { DEFAULT_MENU_ITEM_SIZE, MENU_ITEM_SIZES } from "@/constants";
import { useSplitApartChildrenAndSubComponents, useTheme } from "@/hooks";
import type {
  BiomeTheme,
  DeeplyNestedSx,
  MakeResponsive,
  MenuItemSize,
} from "@/types";
import { getResponsiveSx, getStartingSize, isChildSubcomponent } from "@/utils";
import { MenuItemBottomSlotDivider } from "./MenuItemBottomSlotDivider";

type MenuItemBottomSlotProps = StackProps<undefined> & {
  selected?: boolean;
  size?: MakeResponsive<MenuItemSize>;
  showDivider?: boolean;
};

function getSizeBasedStyles({
  size,
  theme,
}: { size: MenuItemSize; theme: BiomeTheme }) {
  switch (size) {
    case "xSmall":
      return {
        paddingLeft: "base.spacing.x12",
      };

    case "small":
    case "medium":
    default:
      return {
        paddingLeft: "base.spacing.x16",
      };
  }
}

const getResponsiveSizeStyles = (props: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getSizeBasedStyles,
  });

export function MenuItemBottomSlot({
  direction = "column",
  gap = "0px",
  alignItems = "flex-start",
  children,
  selected,
  rc = <span />,
  size = DEFAULT_MENU_ITEM_SIZE,
  showDivider = false,
  sx = {},
  className,
  ...props
}: MenuItemBottomSlotProps) {
  const {
    subcomponents: [divider],
    otherChildren,
  } = useSplitApartChildrenAndSubComponents(
    children,
    MenuItemBottomSlotDivider,
  );
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );
  const mergedSx = useMemo(
    () =>
      merge(
        {
          alignSelf: "stretch",
          pt: divider ? "0px" : "base.spacing.x1",
        } as DeeplyNestedSx,
        getSizeBasedStyles({ size: startingSize, theme }),
        getResponsiveSizeStyles({ size, theme }),
        sx,
      ),
    [sx, size, theme, startingSize, divider],
  );

  return (
    <Stack
      {...props}
      rc={rc}
      sx={mergedSx}
      direction={direction}
      gap={gap}
      alignItems={alignItems}
      className={`${className ?? ""} MenuItemBottomSlot`}
    >
      {divider && <SmartClone menuItemSize={size}>{divider}</SmartClone>}
      {Children.map(otherChildren, (child) => {
        if (isValidElement(child) && isChildSubcomponent(child, Body)) {
          return (
            <SmartClone
              size={child.props.size ?? "xSmall"}
              sx={merge(
                { c: "base.color.text.body.secondary" },
                child?.props?.sx ?? {},
              )}
            >
              {child}
            </SmartClone>
          );
        }

        if (isValidElement(child) && isChildSubcomponent(child, Icon)) {
          return (
            <SmartClone
              sx={merge(
                {
                  fill: "base.color.text.body.secondary",
                  w: "base.icon.size.250",
                },
                child?.props?.sx ?? {},
              )}
            >
              {child}
            </SmartClone>
          );
        }

        return child;
      })}
    </Stack>
  );
}

MenuItemBottomSlot.displayName = "MenuItem.BottomSlot";
MenuItemBottomSlot.Divider = MenuItemBottomSlotDivider;
