import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedStack, type FramedStackProps } from "@/components/FramedThings";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { MenuItemProps } from "@/types";

import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseLeftImageStyles } from "./styles";

export type MenuItemFramedStackProps<
  RC extends ReactElement | undefined = undefined,
> = Omit<FramedStackProps<RC>, "size"> & Pick<MenuItemProps, "size">;

export function MenuItemFramedStack<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  rc = <span />,
  size = DEFAULT_MENU_ITEM_SIZE,
  ...props
}: MenuItemFramedStackProps<RC>) {
  const mergedSx = useMemo(() => merge(baseLeftImageStyles, sx), [sx]);
  const framedStackSize = useGetBiomeImageSizeFromMenuItemSize(size);
  return (
    <FramedStack {...props} rc={rc} sx={mergedSx} size={framedStackSize} />
  );
}

MenuItemFramedStack.displayName = "MenuItem.FramedStack";
MenuItemFramedStack.PrimaryImage = FramedStack.PrimaryImage;
MenuItemFramedStack.SecondaryImage = FramedStack.SecondaryImage;
MenuItemFramedStack.TertiaryImage = FramedStack.TertiaryImage;
