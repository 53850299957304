import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { FramedImage } from "@/components/FramedThings";
import { DEFAULT_IMAGE_SIZE_VARIANT, IMAGE_SIZE_VARIANTS } from "@/constants";
import { useGetCurrentSizeClass, useTheme } from "@/hooks";
import { useGetResponsiveImageSizes } from "@/hooks/responsiveImageSizeHooks";
import type {
  BoxWithRCAndDomProps,
  DistributiveOmit,
  GetAvatarImageResizerProps,
  ImageSizeVariant,
  MakeResponsive,
  StringOrNull,
  SupportedNativeImgAttributes,
} from "@/types";
import { hFlex, isImageResizerProps } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  getContainerSx,
  getResponsiveContainerSx,
  getResponsiveTextSx,
  getTextSx,
} from "./styles";

export type AvatarProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> &
  DistributiveOmit<GetAvatarImageResizerProps<Use>, "domRef" | "imageUrl"> &
  SupportedNativeImgAttributes & {
    size?: MakeResponsive<ImageSizeVariant>;
    initials?: string;
    imageUrl?: StringOrNull;
  };

const AVATAR_DEFAULT_IMAGE =
  "https://biome.immutable.com/hosted-assets/images/avatarDefaultImage.svg";

export function Avatar<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  initials,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  testId = "Avatar",
  className,
  sx = {},
  use,
  // @NOTE: Native <img /> attr props:
  alt,
  loading,
  fetchPriority,
  crossOrigin,
  decoding,
  useMap,
  referrerPolicy,
  ...props
}: AvatarProps<RC, Use>) {
  const {
    imageUrl,
    defaultImageUrl = AVATAR_DEFAULT_IMAGE,
    defaultImageClassName,
    imageResizeServiceUrl,
    ...otherProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        imageUrl: undefined,
        defaultImageUrl: undefined,
        imageResizeServiceUrl: undefined,
        defaultImageClassName: undefined,
      };
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const mergedContainerSx = useMemo(
    () =>
      merge(
        {
          ...hFlex,
          borderRadius: "50%",
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          pos: "relative",
          ...(initials
            ? {
                bg: "base.color.accent.3",
              }
            : {}),
        },
        getContainerSx({ theme, size: startingSize }),
        getResponsiveContainerSx({ theme, size }),
        theme.components?.Avatar?.sxOverride ?? {},
        sx,
      ),
    [initials, size, startingSize, sx, theme],
  );

  const textSx = merge(
    { c: "base.color.fixed.black.1000" },
    getTextSx({ theme, size: startingSize }),
    getResponsiveTextSx({ theme, size }),
  );

  const responsiveSizes = useGetResponsiveImageSizes(size, "Avatar");
  const framedImageProps = useMemo(() => {
    return use
      ? {
          use,
        }
      : {
          imageUrl,
          responsiveSizes,
          imageResizeServiceUrl,
          defaultImageClassName,
          defaultImageUrl,
          loading,
          fetchPriority,
          crossOrigin,
          decoding,
          useMap,
          referrerPolicy,
          alt,
        };
  }, [
    use,
    imageUrl,
    responsiveSizes,
    imageResizeServiceUrl,
    defaultImageClassName,
    defaultImageUrl,
    loading,
    fetchPriority,
    crossOrigin,
    decoding,
    useMap,
    referrerPolicy,
    alt,
  ]);

  return (
    <Box
      {...otherProps}
      testId={testId}
      className={`${className ?? ""} Avatar Avatar--${currentSizeClass}`}
      sx={mergedContainerSx}
    >
      {imageUrl || (!initials && !imageUrl) ? (
        <FramedImage
          {...framedImageProps}
          size={size}
          circularFrame
          testId={`${testId}__framedImage`}
          className="Avatar__image"
        />
      ) : initials ? (
        <Box
          sx={textSx}
          testId={`${testId}__text`}
          rc={<span />}
          className="Avatar__initials"
        >
          {initials}
        </Box>
      ) : null}
    </Box>
  );
}

Avatar.displayName = "Avatar";
