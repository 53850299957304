import { Button, type DeeplyNestedSx } from "@biom3/react";
import type { WishlistProps } from "@ui-kit";
import { type ComponentProps, useMemo } from "react";
import { merge } from "ts-deepmerge";

type WishlistButtonProps = {
  wishlistProps: WishlistProps;
  trackGameDetailsEventIdentifier: string;
  buttonSize?: ComponentProps<typeof Button>["size"];
  sx?: ComponentProps<typeof Button>["sx"];
  showButtonIcon?: boolean;
  analyticsExtras?: Record<string, unknown>;
};

export function WishlistButton({
  wishlistProps,
  buttonSize = "medium",
  sx = {},
  trackGameDetailsEventIdentifier,
  analyticsExtras,
  showButtonIcon = true,
}: WishlistButtonProps) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...(wishlistProps?.isWishlisted
            ? {
                bg: "base.color.status.success.bright",
                "&:hover:not([disabled])": {
                  boxShadow: ({ base }) =>
                    `inset 0 0 0 ${base.border.size[300]} ${base.color.status.success.bright}`,
                },
                "&::before": {
                  bg: "base.color.status.success.bright",
                },
              }
            : {}),
        } as DeeplyNestedSx,
        sx,
      ),
    [wishlistProps?.isWishlisted, sx],
  );

  return (
    <Button
      variant="primary"
      size={buttonSize}
      sx={mergedSx}
      onClick={() =>
        wishlistProps.onWishlistClick(
          trackGameDetailsEventIdentifier,
          analyticsExtras,
        )
      }
    >
      {showButtonIcon && (
        <Button.Icon
          icon={wishlistProps?.isWishlisted ? "Tick" : "Add"}
          iconVariant="bold"
        />
      )}
      {wishlistProps?.isWishlisted ? "Following" : "Follow"}
    </Button>
  );
}
