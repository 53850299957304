import { useCallback, useEffect, useId, useMemo } from "react";

import {
  useControlledOverlay,
  useForwardLocalDomRef,
  useGetSubcomponentChild,
} from "@/hooks";
import {
  CLOSE_POPOVER,
  OPEN_POPOVER,
  UPDATE_POPOVER_PROPS,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { PopoverComponentProps } from "@/types";

import { SmartClone } from "../SmartClone";
import { PopoverContent } from "./PopoverContent";
import { PopoverTarget } from "./PopoverTarget";

export function Popover({
  visible,
  id: idProp,
  children,
  position = { x: "center", y: "below" },
  domRef = { current: null },
  onOutsideClick,
  onClosePopover,
  verticalGap = 4,
}: PopoverComponentProps) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const target = useGetSubcomponentChild(children, PopoverTarget);
  const content = useGetSubcomponentChild(children, PopoverContent);
  const { state: popoverList, dispatchAction } = useOverlaysStore(
    (state) => state.popoverList,
  );

  const isControlled = useMemo(() => typeof visible !== "undefined", [visible]);
  const popoverProps = useMemo(
    () => ({
      id,
      content,
      targetRef: localDomRef,
      position,
      isControlled,
      onOutsideClick,
      onClosePopover,
      verticalGap,
    }),
    [
      content,
      id,
      isControlled,
      localDomRef,
      onOutsideClick,
      onClosePopover,
      position,
      verticalGap,
    ],
  );

  // @NOTE: this useEffect allows the Popover props to stay up to date,
  // as the application re-renders and things potentially change
  useEffect(() => {
    dispatchAction({
      type: UPDATE_POPOVER_PROPS,
      payload: popoverProps,
    });
  }, [dispatchAction, popoverProps]);

  const showPopover = useCallback(() => {
    dispatchAction({
      type: OPEN_POPOVER,
      payload: popoverProps,
    });
  }, [dispatchAction, popoverProps]);

  const hidePopover = useCallback(() => {
    dispatchAction({
      type: CLOSE_POPOVER,
      payload: { id },
    });
  }, [dispatchAction, id]);

  // @NOTE: Allow popover to be controlled:
  useControlledOverlay({
    id,
    visible,
    showFunction: showPopover,
    hideFunction: hidePopover,
    overlayList: popoverList,
  });

  const onClickProps = useMemo(() => {
    return !isControlled
      ? {
          onClick: () => {
            if (popoverList.find((popover) => popover.id === id)) {
              hidePopover();
            } else {
              showPopover();
            }
          },
        }
      : {};
  }, [popoverList, isControlled, id, hidePopover, showPopover]);

  return target ? (
    <SmartClone {...onClickProps} id={id} domRef={localDomRef}>
      {target}
    </SmartClone>
  ) : null;
}

Popover.displayName = "Popover";
Popover.Target = PopoverTarget;
Popover.Content = PopoverContent;
