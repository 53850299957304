import { motion } from "motion/react";
import { type ReactElement, useMemo } from "react";

import { useTheme } from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";

import { SimpleIconButton } from "./SimpleIconButton";
import type { ClearFieldButtonBaseProps } from "./shared";

export function ClearFieldButton<
  RC extends ReactElement | undefined = undefined,
>({
  className,
  rc,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"button"> & ClearFieldButtonBaseProps
  : ClearFieldButtonBaseProps & { rc: RC }) {
  const { base } = useTheme();
  const rcWithFallback = useMemo(
    () =>
      rc ?? (
        <motion.button
          type="button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: base.motion.normal.fast.jsEase,
            duration: base.motion.normal.fast.jsDuration,
          }}
        />
      ),
    [rc, base],
  );
  return (
    <SimpleIconButton
      {...props}
      icon="CloseWithCircle"
      iconVariant="bold"
      className={`${className ?? ""} ClearFieldButton`}
      rc={rcWithFallback}
    />
  );
}

ClearFieldButton.displayName = "ClearFieldButton";
