import type { Property } from "csstype";
import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Video } from "@/components/Video";
import type { MakeValidSxValue, VideoProps } from "@/types";
import { DEFAULT_CARD_HERO_ASPECT } from "./shared";

export type CardHeroAssetVideoProps = VideoProps & {
  playOnCardHover?: boolean;
  aspectRatio?: MakeValidSxValue<Property.AspectRatio>;
};

export function CardHeroAssetVideo({
  className,
  autoPlay = true,
  controls = false,
  playOnCardHover = false,
  muted = autoPlay,
  loop = true,
  aspectRatio = DEFAULT_CARD_HERO_ASPECT,
  sx = {},
  ...props
}: CardHeroAssetVideoProps) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          w: "100%",
          objectFit: "cover",
          objectPosition: "center",
          aspectRatio,
        },
        sx,
      ),
    [aspectRatio, sx],
  );
  return (
    <Video
      {...props}
      className={`${className ?? ""} CardHeroAssetVideo`}
      autoPlay={playOnCardHover ? false : autoPlay}
      controls={controls}
      muted={muted}
      loop={loop}
      sx={mergedSx}
    />
  );
}

CardHeroAssetVideo.displayName = "CardHero.AssetVideo";
