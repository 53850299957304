import type {
  GetMultiTextProps,
  StandardComponentWithProps,
  UnknownReactProps,
} from "@/types";
import {
  type Dispatch,
  type ReactElement,
  type ReactNode,
  type SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";

export type ListType = "ol" | "ul";
export const DEFAULT_BULLET_LIST_TYPE: ListType = "ul";

type Dimensions = {
  titleDuoConWidth: number;
  titleGap: number;
};

type BulletListContext = {
  dimensions: Dimensions;
  setDimensions: Dispatch<SetStateAction<Dimensions>>;
  listType: ListType;
};

const INITIAL_VALUE: BulletListContext = {
  dimensions: {
    titleDuoConWidth: 0,
    titleGap: 0,
  },
  setDimensions: () => {},
  listType: DEFAULT_BULLET_LIST_TYPE,
};

export const BulletListContext =
  createContext<BulletListContext>(INITIAL_VALUE);

export function BulletListProvider({
  children,
  listType,
}: { children: ReactNode; listType: ListType }) {
  const [dimensions, setDimensions] = useState<Dimensions>(
    INITIAL_VALUE.dimensions,
  );
  const memoizedValue = useMemo(
    () => ({ dimensions, setDimensions, listType }),
    [dimensions, listType],
  );
  return (
    <BulletListContext.Provider value={memoizedValue}>
      {children}
    </BulletListContext.Provider>
  );
}

export type BulletListItemBaseProps = Omit<
  StandardComponentWithProps<
    HTMLLIElement,
    {
      children?: ReactNode;
      use?: ReactElement;
    }
  >,
  "rc"
>;

export type BulletListItemProps<Use extends ReactElement | undefined> =
  GetMultiTextProps<undefined, Use, BulletListItemBaseProps, "li">;

export type BulletListProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    children: ReactNode;
    use?: ReactElement<UnknownReactProps>;
  }
>;
