import {
  Children,
  type ReactElement,
  cloneElement,
  useContext,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps } from "@/types";
import { isChildSubcomponent, renderNullAndWarnUser } from "@/utils";

import { TableCell } from "./TableCell";
import { TableContext } from "./context";
import type { TableChildParentNode } from "./shared";

export type TableRowProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & { parentNode?: TableChildParentNode };

export function TableRow<RC extends ReactElement | undefined = undefined>({
  children,
  rc = <tr />,
  className,
  testId: testIdProp,
  parentNode,
  ...props
}: TableRowProps<RC>) {
  const { testId } = useContext(TableContext);
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  return (
    <Box
      {...props}
      rc={rc}
      testId={`${testIdProp ?? testId}__tr`}
      // @TODO: deprecate the use of plain "tr" class, infavour of
      // the more specific "TableRow" class
      className={`${className ?? ""} TableRow tr`}
    >
      {Children.map(flattenedChildren, (child, index) => {
        if (isChildSubcomponent(child, TableCell)) {
          return cloneElement(child, { parentNode, columnNumber: index });
        }
        return renderNullAndWarnUser(TableRow.displayName);
      })}
    </Box>
  );
}

TableRow.displayName = "Table.Row";
