import { Children, type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { APP_HEADER_BAR_SIZES, DEFAULT_APP_HEADER_BAR_SIZE } from "@/constants";
import {
  useGetCurrentSizeClass,
  useGetSubcomponentChild,
  useGetSubcomponentChildren,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import type { BoxWithRCAndDomProps, MakeResponsive } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { AppHeaderBarLeftButtCon } from "./AppHeaderBarLeftButtCon";
import { AppHeaderBarLeftLogo } from "./AppHeaderBarLeftLogo";
import { AppHeaderBarOverflowPopoverMenu } from "./AppHeaderBarOverflowPopoverMenu";
import {
  AppHeaderBarLeftSlot,
  AppHeaderBarRightSlot,
} from "./AppHeaderBarSlot";
import { AppHeaderBarTitle } from "./AppHeaderBarTitle";
import { AppHeaderBarTitleLogo } from "./AppHeaderBarTitleLogo";
import type {
  AppHeaderBarContentAlign,
  AppHeaderBarSize,
  AppHeaderBarVariant,
} from "./shared";
import {
  baseInnerContainerSx,
  getOuterContainerSx,
  innerContainerSx,
  responsiveInnerContainerSx,
  responsiveOuterContainerSx,
} from "./styles";

export type AppHeaderBarProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & {
    size?: MakeResponsive<AppHeaderBarSize>;
    emphasized?: boolean;
    contentAlign?: AppHeaderBarContentAlign;
    variant?: AppHeaderBarVariant;
  };

export function AppHeaderBar<RC extends ReactElement | undefined = undefined>({
  sx = {},
  rc = <header />,
  variant = "dim",
  size = DEFAULT_APP_HEADER_BAR_SIZE,
  children,
  emphasized,
  contentAlign = "center",
  testId = "AppHeaderBar",
  className,
  ...props
}: AppHeaderBarProps<RC>) {
  const theme = useTheme();
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    AppHeaderBarLeftButtCon,
    AppHeaderBarLeftLogo,
    AppHeaderBarTitle,
    AppHeaderBarTitleLogo,
    AppHeaderBarRightSlot,
    AppHeaderBarLeftSlot,
    AppHeaderBarOverflowPopoverMenu,
  ]);

  const leftButtCon = useGetSubcomponentChild(
    children,
    AppHeaderBarLeftButtCon,
  );
  const leftLogo = useGetSubcomponentChildren(children, AppHeaderBarLeftLogo);
  const title = useGetSubcomponentChild(children, AppHeaderBarTitle);
  const titleLogo = useGetSubcomponentChild(children, AppHeaderBarTitleLogo);
  const rightSlot = useGetSubcomponentChild(children, AppHeaderBarRightSlot);
  const leftSlot = useGetSubcomponentChild(children, AppHeaderBarLeftSlot);
  const overflowPopoverMenu = useGetSubcomponentChild(
    children,
    AppHeaderBarOverflowPopoverMenu,
  );
  const startingSize = getStartingSize(
    size,
    DEFAULT_APP_HEADER_BAR_SIZE,
    APP_HEADER_BAR_SIZES,
  );
  const mergedOuterContainerSx = useMemo(
    () =>
      merge(
        {
          w: "100%",
          p: "base.spacing.x2",
          bg:
            variant === "dim"
              ? "base.color.neutral.1000"
              : variant === "transparent"
                ? "transparent"
                : variant === "bright"
                  ? "base.color.neutral.700"
                  : "base.color.neutral.1000",
        },
        getOuterContainerSx({ size: startingSize }),
        responsiveOuterContainerSx({ size, theme: theme }),
        theme.components?.AppHeaderBar?.sxOverride ?? {},
        sx,
      ),
    [startingSize, sx, theme, variant, size],
  );
  const mergedInnerContainerSx = merge(
    baseInnerContainerSx,
    { ...(emphasized ? { bg: "base.color.neutral.800" } : {}) },
    innerContainerSx({ theme, size: startingSize }),
    responsiveInnerContainerSx({
      theme,
      size,
    }),
  );
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_APP_HEADER_BAR_SIZE,
    APP_HEADER_BAR_SIZES,
  );

  return (
    <Box
      {...props}
      rc={rc}
      sx={mergedOuterContainerSx}
      testId={testId}
      className={`${
        className ?? ""
      } AppHeaderBar AppHeaderBar--${currentSizeClass} AppHeaderBar--${variant}`}
    >
      <Box sx={mergedInnerContainerSx} testId={`${testId}__innerContainer`}>
        {leftButtCon && (
          <SmartClone
            testId={`${testId}__innerContainer__leftButtCon`}
            size={size}
          >
            {leftButtCon}
          </SmartClone>
        )}

        {leftLogo
          ? Children.map(leftLogo, (logo, index) => (
              <SmartClone
                testId={
                  logo.props.testId ?? `${testId}__innerContainer__leftLogo`
                }
                size={size}
                key={`leftLogo-${
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  index
                }`}
              >
                {logo}
              </SmartClone>
            ))
          : null}

        {leftSlot && (
          <SmartClone
            testId={
              leftSlot.props.testId ?? `${testId}__innerContainer__leftSlot`
            }
          >
            {leftSlot}
          </SmartClone>
        )}

        {titleLogo ? (
          <SmartClone
            testId={`${testId}__innerContainer__titleLogo`}
            size={size}
            contentAlign={contentAlign}
          >
            {titleLogo}
          </SmartClone>
        ) : title ? (
          <SmartClone
            testId={`${testId}__innerContainer__title`}
            contentAlign={contentAlign}
            size={size}
          >
            {title}
          </SmartClone>
        ) : null}

        <Box
          sx={{
            d: "flex",
            gap: "base.spacing.x6",
            alignItems: "center",
            ml: "auto",
          }}
          testId={`${testId}__innerContainer__rightSide`}
        >
          {rightSlot && (
            <SmartClone
              testId={
                rightSlot.props.testId ??
                `${testId}__innerContainer__rightSide__slotContent`
              }
            >
              {rightSlot}
            </SmartClone>
          )}

          {overflowPopoverMenu ? (
            <SmartClone
              testId={`${testId}__innerContainer__rightSide__overflowPopoverMenu`}
              size={size}
            >
              {overflowPopoverMenu}
            </SmartClone>
          ) : null}
        </Box>

        {otherChildren}
      </Box>
    </Box>
  );
}

AppHeaderBar.displayName = "AppHeaderBar";
AppHeaderBar.LeftButtCon = AppHeaderBarLeftButtCon;
AppHeaderBar.LeftLogo = AppHeaderBarLeftLogo;
AppHeaderBar.Title = AppHeaderBarTitle;
AppHeaderBar.TitleLogo = AppHeaderBarTitleLogo;
AppHeaderBar.RightSlot = AppHeaderBarRightSlot;
AppHeaderBar.LeftSlot = AppHeaderBarLeftSlot;
AppHeaderBar.OverflowPopoverMenu = AppHeaderBarOverflowPopoverMenu;
