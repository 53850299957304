import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TEXT_INPUT_ALIGN } from "@/constants";
import type {
  BaseBodyProps,
  DomPropsWithDomRef,
  InputTextAlign,
} from "@/types";
import { Body } from "../Text";

export type FormControlLabelProps = Omit<
  BaseBodyProps & DomPropsWithDomRef<"label">,
  "rc"
> & { textAlign?: InputTextAlign };

// biome-ignore lint/a11y/noLabelWithoutControl: <explanation>
const LABEL_RC = <label />;

export function FormControlLabel({
  size = "small",
  children,
  testId,
  sx = {},
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  className,
  ...props
}: FormControlLabelProps) {
  const mergedSx = useMemo(
    () =>
      merge(
        { c: "base.color.text.body.primary", textAlign, cursor: "pointer" },
        sx,
      ),
    [sx, textAlign],
  );
  return (
    <Body
      {...props}
      testId={testId}
      rc={LABEL_RC}
      size={size}
      sx={mergedSx}
      className={`${className ?? ""} FormControlLabel`}
    >
      {children}
    </Body>
  );
}

FormControlLabel.displayName = "FormControlLabel";
