import { useBrowserEffect } from "./useBrowserEffect";
import { useIsMounted } from "./useIsMounted";

export function useControlledOverlay({
  visible,
  id,
  overlayList,
  showFunction,
  hideFunction,
}: {
  visible?: boolean;
  overlayList: { id: string }[];
  id: string;
  showFunction: () => void;
  hideFunction: () => void;
}) {
  const isMounted = useIsMounted();
  useBrowserEffect(() => {
    if (visible === undefined) return () => {};

    if (visible && !overlayList.find((overlay) => overlay.id === id)) {
      showFunction();
    } else if (!visible && overlayList.find((overlay) => overlay.id === id)) {
      hideFunction();
    }

    return () => {
      if (!isMounted() && overlayList.find((overlay) => overlay.id === id)) {
        hideFunction();
      }
    };
  }, [visible, overlayList, id, showFunction, hideFunction, isMounted]);
}
