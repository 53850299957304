import type {
  DrawerItem,
  ModalItem,
  OverlayStore,
  PopoverItem,
  ToastItem,
} from "@/types";
import { serializeChildren } from "@/utils/componentHelpers";
import {
  type DispatchActionPayload,
  type FastContextDispatchAction,
  createFastContext,
} from "@/utils/createFastContext";

import {
  CLOSE_DRAWER,
  CLOSE_MODAL,
  CLOSE_POPOVER,
  CLOSE_TOAST,
  OPEN_DRAWER,
  OPEN_MODAL,
  OPEN_POPOVER,
  OPEN_TOAST,
  UPDATE_DRAWER_PROPS,
  UPDATE_MODAL_PROPS,
  UPDATE_POPOVER_PROPS,
  UPDATE_TOAST_PROPS,
} from "./actions";

export const initialState: OverlayStore = {
  modalList: [],
  popoverList: [],
  drawerList: [],
  toastList: [],
};

type OverlayContextAction = Omit<FastContextDispatchAction, "payload"> & {
  payload: DispatchActionPayload;
};

const overlayReducer = (state: OverlayStore, action: OverlayContextAction) => {
  // @TODO: perhaps split up this reducer somehow, its pretty damn long
  switch (action.type) {
    /**
     *
     * MODAL OVERLAY ACTIONS ......
     *
     */
    case UPDATE_MODAL_PROPS: {
      let currentIndex = -1;
      state.modalList.forEach((modal, i) => {
        if (modal.id === action.payload.id) {
          currentIndex = i;
        }
      });
      if (currentIndex >= 0) {
        // @TODO: this is a pretty shitty way to check if content has
        // changed, and could have pretty regrettable performance
        // but it's the only option we can think of right now...
        const newContentSerialised = serializeChildren(action.payload);
        const oldContentSerialised = serializeChildren(
          state.modalList[currentIndex],
        );
        const contentHasChanged = newContentSerialised !== oldContentSerialised;

        if (contentHasChanged) {
          const updatedList = [...state.modalList];
          updatedList[currentIndex] = action.payload as ModalItem;
          return { ...state, modalList: updatedList };
        }
        return state;
      }
      return state;
    }
    case OPEN_MODAL: {
      const doesntAlreadyExist = !state.modalList.find(
        (modal) => modal.id === action.payload.id,
      );
      return {
        ...state,
        modalList: [
          ...state.modalList,
          ...(doesntAlreadyExist ? [action.payload as ModalItem] : []),
        ],
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        modalList: state.modalList.filter(
          (modal) => modal.id !== action.payload.id,
        ),
      };
    }
    /**
     *
     * POPOVER OVERLAY ACTIONS ......
     *
     */
    case UPDATE_POPOVER_PROPS: {
      let currentIndex = -1;
      state.popoverList.forEach((popover, i) => {
        if (popover.id === action.payload.id) {
          currentIndex = i;
        }
      });
      if (currentIndex >= 0) {
        const newContentSerialised = serializeChildren(action.payload);
        const oldContentSerialised = serializeChildren(
          state.popoverList[currentIndex],
        );
        const contentHasChanged = newContentSerialised !== oldContentSerialised;

        if (contentHasChanged) {
          const updatedList = [...state.popoverList];
          updatedList[currentIndex] = action.payload as PopoverItem;
          return { ...state, popoverList: updatedList };
        }
        return state;
      }
      return state;
    }
    case OPEN_POPOVER: {
      // @NOTE: allow for React.StrictMode where double renders happen
      // (which causes multiple copies of popover to be stored)
      const doesntAlreadyExist = !state.popoverList.find(
        (popover) => popover.id === action.payload.id,
      );
      return {
        ...state,
        popoverList: [
          ...state.popoverList,
          ...(doesntAlreadyExist ? ([action.payload] as PopoverItem[]) : []),
        ],
      };
    }
    case CLOSE_POPOVER: {
      return {
        ...state,
        popoverList: state.popoverList.filter(
          (popover) => popover.id !== action.payload.id,
        ),
      };
    }

    /**
     *
     * BOTTOM SHEET OVERLAY ACTIONS ......
     *
     */
    case UPDATE_DRAWER_PROPS: {
      let currentIndex = -1;
      state.drawerList.forEach((drawer, i) => {
        if (drawer.id === action.payload.id) {
          currentIndex = i;
        }
      });
      if (currentIndex >= 0) {
        const newContentSerialised = serializeChildren(action.payload);
        const oldContentSerialised = serializeChildren(
          state.drawerList[currentIndex],
        );
        const contentHasChanged = newContentSerialised !== oldContentSerialised;
        if (contentHasChanged) {
          const updatedList = [...state.drawerList];
          updatedList[currentIndex] = action.payload as DrawerItem;
          return { ...state, drawerList: updatedList };
        }
        return state;
      }
      return state;
    }
    case OPEN_DRAWER: {
      const doesntAlreadyExist = !state.drawerList.find(
        (drawer) => drawer.id === action.payload.id,
      );
      return {
        ...state,
        drawerList: [
          ...state.drawerList,
          ...(doesntAlreadyExist ? [action.payload as DrawerItem] : []),
        ],
      };
    }
    case CLOSE_DRAWER: {
      return {
        ...state,
        drawerList: state.drawerList.filter(
          (drawer) => drawer.id !== action.payload.id,
        ),
      };
    }

    /**
     *
     * TOAST OVERLAY ACTIONS ......
     *
     */
    case UPDATE_TOAST_PROPS: {
      let currentIndex = -1;
      state.toastList.forEach((drawer, i) => {
        if (drawer.id === action.payload.id) {
          currentIndex = i;
        }
      });
      if (currentIndex >= 0) {
        const newContentSerialised = serializeChildren(action.payload);
        const oldContentSerialised = serializeChildren(
          state.toastList[currentIndex],
        );
        const contentHasChanged = newContentSerialised !== oldContentSerialised;

        if (contentHasChanged) {
          const updatedList = [...state.toastList];
          updatedList[currentIndex] = action.payload as ToastItem;
          return { ...state, toastList: updatedList };
        }
        return state;
      }
      return state;
    }
    case OPEN_TOAST: {
      const doesntAlreadyExist = !state.toastList.find(
        (toast) => toast.id === action.payload.id,
      );
      return {
        ...state,
        toastList: [
          ...state.toastList,
          ...(doesntAlreadyExist ? [action.payload as ToastItem] : []),
        ],
      };
    }
    case CLOSE_TOAST: {
      return {
        ...state,
        toastList: state.toastList.filter(
          (toast) => toast.id !== action.payload.id,
        ),
      };
    }

    default: {
      console.error("Unknown reducer action:", action);
      return state;
    }
  }
};

export const { Provider: BiomeOverlaysProvider, useStore: useOverlaysStore } =
  createFastContext(initialState, overlayReducer);

// @NOTE: The below shows up in react dev tools - which aids identifying
// the provider in the tree
BiomeOverlaysProvider.displayName = "BiomeOverlaysProvider";
