import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_BUTTON_SIZE } from "@/constants";
import type { ButtConProps, DistributiveOmit, MakeResponsive } from "@/types";

import { ButtCon } from "../Clickable";
import type { AppHeaderBarSize } from "./shared";

export type AppHeaderBarLeftButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "size"> & {
  size?: MakeResponsive<AppHeaderBarSize>;
};

export function AppHeaderBarLeftButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  size,
  variant = "tertiary",
  sx = {},
  className,
  ...props
}: AppHeaderBarLeftButtConProps<RC>) {
  return (
    <ButtCon
      {...props}
      variant={variant}
      size={DEFAULT_BUTTON_SIZE}
      sx={merge({ pos: "relative", zIndex: 1 }, sx)}
      className={`${className ?? ""} AppHeaderBarLeftButtCon`}
    />
  );
}

AppHeaderBarLeftButtCon.displayName = "AppHeaderBar.LeftButtCon";
