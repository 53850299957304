import { merge } from "ts-deepmerge";

import type {
  BiomeTheme,
  ButtonSize,
  ButtonVariant,
  MakeResponsive,
} from "@/types";
import { isError } from "@/utils/textStyleHelpers";

import {
  getPrimaryButtonStyles,
  getPrimaryInverseButtonGenericStyles,
  getPrimaryInverseButtonStyles,
  getPrimaryStatusButtonGenericStyles,
  getPrimaryStatusButtonStyles,
  primaryButtonGenericStyles,
} from "./primaryStyles";
import {
  getSecondaryButtonStyles,
  getSecondaryFatalButtonStyles,
  secondaryButtonGenericStyles,
  secondaryFatalButtonGenericStyles,
} from "./secondaryStyles";
import { baseButtonSx } from "./shared";
import {
  getTertiaryButtonStyles,
  tertiaryButtonGenericStyles,
  tertiaryInverseButtonGenericStyles,
} from "./tertiaryStyles";

export const baseButtonInnerSpanStyles = {
  position: "relative",
  c: "inherit",
  whiteSpace: "pre",
};

export function getResponsiveButtonStyles({
  size,
  variant,
  themeProps,
}: {
  size: MakeResponsive<ButtonSize>;
  variant: ButtonVariant;
  themeProps: BiomeTheme;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        let styles = {};
        switch (variant) {
          case "secondary":
            styles = getSecondaryButtonStyles(responsiveSize, themeProps);
            break;

          case "tertiary":
            styles = getTertiaryButtonStyles(responsiveSize, themeProps);
            break;

          case "tertiary/inverse":
            styles = getTertiaryButtonStyles(responsiveSize, themeProps, true);
            break;

          case "primary/fatal":
            styles = getPrimaryStatusButtonStyles(
              "fatal",
              responsiveSize,
              themeProps,
            );
            break;

          case "primary/success":
            styles = getPrimaryStatusButtonStyles(
              "success",
              responsiveSize,
              themeProps,
            );
            break;

          case "secondary/fatal":
            styles = getSecondaryFatalButtonStyles(responsiveSize, themeProps);
            break;

          case "primary/inverse":
            styles = getPrimaryInverseButtonStyles(responsiveSize, themeProps);
            break;

          case "primary":
          default:
            styles = getPrimaryButtonStyles(responsiveSize, themeProps);
            break;
        }
        return {
          [mediaStyleRule]: styles,
        };
      }
      return {};
    }),
  );
}

export function getDefaultButtonStyles({
  size,
  variant,
  themeProps,
}: {
  size: ButtonSize;
  variant: ButtonVariant;
  themeProps: BiomeTheme;
}) {
  switch (variant) {
    case "secondary":
      return merge(
        baseButtonSx,
        getSecondaryButtonStyles(size, themeProps),
        secondaryButtonGenericStyles,
      );

    case "secondary/fatal":
      return merge(
        baseButtonSx,
        getSecondaryFatalButtonStyles(size, themeProps),
        secondaryFatalButtonGenericStyles,
      );

    case "tertiary/inverse":
      return merge(
        baseButtonSx,
        getTertiaryButtonStyles(size, themeProps, true),
        tertiaryInverseButtonGenericStyles,
      );

    case "tertiary":
      return merge(
        baseButtonSx,
        getTertiaryButtonStyles(size, themeProps),
        tertiaryButtonGenericStyles,
      );

    case "primary/fatal":
      return merge(
        baseButtonSx,
        primaryButtonGenericStyles,
        getPrimaryStatusButtonStyles("fatal", size, themeProps),
        getPrimaryStatusButtonGenericStyles("fatal"),
      );

    case "primary/success":
      return merge(
        baseButtonSx,
        primaryButtonGenericStyles,
        getPrimaryStatusButtonStyles("success", size, themeProps),
        getPrimaryStatusButtonGenericStyles("success"),
      );

    case "primary/inverse": {
      return merge(
        baseButtonSx,
        primaryButtonGenericStyles,
        getPrimaryInverseButtonStyles(size, themeProps),
        getPrimaryInverseButtonGenericStyles(themeProps),
      );
    }

    case "primary":
    default:
      return merge(
        baseButtonSx,
        primaryButtonGenericStyles,
        getPrimaryButtonStyles(size, themeProps),
      );
  }
}

export const getButtConIconStyles = ({
  variant,
  size,
  themeProps: { base },
}: {
  variant: ButtonVariant;
  size: ButtonSize;
  themeProps: BiomeTheme;
}) => {
  return {
    width:
      size === "small"
        ? base.icon.size[200]
        : size === "medium"
          ? base.icon.size[250]
          : base.icon.size[300],
    fill: variant.includes("primary/inverse")
      ? base.color.text.body.primary
      : variant.includes("primary")
        ? base.color.brand[2]
        : variant.includes("secondary/fatal")
          ? base.color.status.fatal.bright
          : variant.includes("secondary")
            ? base.color.text.body.primary
            : variant.includes("tertiary/inverse")
              ? base.color.text.body.inverse.primary
              : base.color.text.body.primary,
  };
};

export const getButtConStyles = ({
  size,
  themeProps,
}: {
  size: ButtonSize;
  themeProps: BiomeTheme;
}) => ({
  width:
    size === "small"
      ? themeProps.base.spacing.x8
      : size === "medium"
        ? themeProps.base.spacing.x12
        : themeProps.base.spacing.x16,
  minWidth:
    size === "small"
      ? themeProps.base.spacing.x8
      : size === "medium"
        ? themeProps.base.spacing.x12
        : themeProps.base.spacing.x16,
  paddingLeft: "0px",
  paddingRight: "0px",
});

export function getResponsiveButtConStyles({
  size,
  themeProps,
}: {
  size: MakeResponsive<ButtonSize>;
  themeProps: BiomeTheme;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getButtConStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
}

export function getResponsiveButtConIconStyles({
  variant,
  size,
  themeProps,
}: {
  variant: ButtonVariant;
  size: MakeResponsive<ButtonSize>;
  themeProps: BiomeTheme;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getButtConIconStyles({
            size: responsiveSize,
            themeProps,
            variant,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
}

export function getButtonIconOrLogoSizeStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  const iconSize =
    size === "small"
      ? themeProps.base.icon.size[200]
      : themeProps.base.icon.size[250];
  return {
    width: iconSize,
    height: iconSize,
    verticalAlign: "middle",
  };
}

export function getButtonIconVariantStyles(
  variant: ButtonVariant,
  { base }: BiomeTheme,
) {
  let fill = "";
  switch (variant) {
    case "primary/fatal":
    case "primary/success":
      fill = base.color.text.body.inverse.primary;
      break;
    case "primary/inverse":
      fill = base.color.text.body.primary;
      break;
    case "primary":
      fill = base.color.text.body.inverse.primary;
      break;
    case "tertiary/inverse":
      fill = base.color.text.body.inverse.primary;
      break;
    case "secondary/fatal":
      fill = base.color.status.fatal.bright;
      break;
    case "secondary":
    case "tertiary":
    default:
      fill = base.color.text.body.primary;
      break;
  }
  return { fill };
}

export function getResponsiveButtonIconOrLogoStyles({
  size,
  variant,
  themeProps,
}: {
  size: MakeResponsive<ButtonSize>;
  variant: MakeResponsive<ButtonVariant>;
  themeProps: BiomeTheme;
}) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  const defaultSize = sizeAsArray.shift() as ButtonSize;
  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getButtonIconOrLogoSizeStyles(
            responsiveSize,
            themeProps,
          ),
        };
      }
      return {};
    }),
  );

  const variantAsArray = Array.isArray(variant) ? [...variant] : [variant];
  const defaultVariant = variantAsArray.shift() as ButtonVariant;
  const variantStyles = merge(
    ...variantAsArray.map((responsiveVariant, index) => {
      if (responsiveVariant != null && !isError(responsiveVariant)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getButtonIconVariantStyles(
            responsiveVariant,
            themeProps,
          ),
        };
      }
      return {};
    }),
  );

  return {
    defaultSize,
    defaultVariant,
    styles: merge({}, sizeStyles, variantStyles),
  };
}
