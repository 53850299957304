import type {
  DeeplyNestedSx,
  IconSubcomponentDiscrimintatedUnion,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";
import { type DesignTokens, onLightBase } from "@biom3/design-tokens";

export type SimpleIconButtonSize =
  `${keyof DesignTokens["base"]["icon"]["size"]}`;

export const DEFAULT_SIMPLE_ICON_BUTTON_SIZE = "300" as const;
export const SIMPLE_ICON_BUTTON_SIZES = Object.keys(
  onLightBase.icon.size,
) as SimpleIconButtonSize[];

export type SimpleIconButtonBaseProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    size?: MakeResponsive<SimpleIconButtonSize>;
    iconSx?: DeeplyNestedSx;
  }
> &
  IconSubcomponentDiscrimintatedUnion;

export type ClearFieldButtonBaseProps = Omit<
  SimpleIconButtonBaseProps,
  "icon" | "iconVariant"
>;
