import localForage from "localforage";

export const logoAndIconCache = localForage.createInstance({
  name: "biom3/react",
  version: 1.0,
  storeName: "Logo_and_Icon_Cache",
  description:
    "A small IndexDB of cache for Icons & Logo artwork SVG json data",
});

const TTL = 1000 * 60 * 60 * 24 * 4; // 4 days

type CacheItem = {
  expire: number;
  value: unknown;
} | null;

export async function getCacheItem(key: string) {
  const data: CacheItem = await logoAndIconCache.getItem(key);

  if (!data) return null;

  const { expire, value } = data;

  if (expire < Date.now()) {
    logoAndIconCache.removeItem(key);
    return null;
  }

  return value;
}

export async function setCacheItem(key: string, value: unknown) {
  return logoAndIconCache.setItem(key, {
    value,
    expire: Math.round(TTL + Date.now()),
  });
}

export async function clearCacheItems() {
  return logoAndIconCache.clear();
}
