import { useEffect, useState } from "react";

export type UseDetectBadImagePropTypes = {
  imageUrl?: string;
};

export const imageUrlChecks = /(http|https|\/)/i;

/**
 * This function uses React hooks to detect if an image URL is valid or not.
 * @param {UseDetectBadImagePropTypes}  - The function `useDetectBadImage` takes in an object with a
 * single property `imageUrl`, which is a string representing the URL of an image. The object is of
 * type `UseDetectBadImagePropTypes`, which is not shown in the code snippet but is likely defined
 * elsewhere in the codebase.
 * @returns a boolean value indicating whether the provided image URL is valid or not. The validity of
 * the image is determined by attempting to load the image using an HTMLImageElement and checking if it
 * successfully loads or not. If the image loads successfully, the function returns true, otherwise it
 * returns false.
 */
export function useDetectBadImage({
  imageUrl,
}: UseDetectBadImagePropTypes): boolean {
  const [imageIsValid, setImageIsValid] = useState<boolean>(false);

  useEffect(() => {
    let image: HTMLImageElement;
    const onLoad = () => setImageIsValid(true);
    const onError = () => setImageIsValid(false);
    if (imageUrl && imageUrlChecks.test(imageUrl)) {
      image = new Image();
      image.addEventListener("load", onLoad);
      image.addEventListener("error", onError);
      image.src = imageUrl;
    }
    return () => {
      image?.removeEventListener("load", onLoad);
      image?.removeEventListener("error", onError);
    };
  }, [imageUrl]);

  return imageIsValid;
}
