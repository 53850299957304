import { type ReactNode, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { Stack } from "@/components/Stack";
import type { StandardComponentWithProps } from "@/types";

import { baseDotSx } from "./styles";

type TimelineStepDotProps = StandardComponentWithProps<
  HTMLDivElement,
  { children?: ReactNode; isLoadingDot?: boolean }
>;

export function TimelineStepDot({
  children,
  sx = {},
  className,
  isLoadingDot = false,
  testId,
}: TimelineStepDotProps) {
  const mergedSx = useMemo(() => merge(baseDotSx, sx), [sx]);
  return (
    <Stack
      testId={testId}
      sx={mergedSx}
      alignItems="center"
      justifyContent="center"
      className={`${className ?? ""} TimelineStep__dot ${
        isLoadingDot ? "TimelineStep__dot--loading" : ""
      }`}
    >
      {isLoadingDot ? <Icon icon="Loading" /> : children}
    </Stack>
  );
}

TimelineStepDot.displayName = "Timeline.Step.Dot";
