import {
  Children,
  type ReactElement,
  cloneElement,
  useContext,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps } from "@/types";
import { isChildSubcomponent, renderNullAndWarnUser } from "@/utils";

import { TableRow } from "./TableRow";
import { TableContext } from "./context";

export type TableBodyProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC>;

export function TableBody<RC extends ReactElement | undefined = undefined>({
  children,
  rc = <tbody />,
  className,
  testId: testIdProp,
  ...props
}: TableBodyProps<RC>) {
  const { testId } = useContext(TableContext);
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  return (
    <Box
      {...props}
      rc={rc}
      testId={`${testIdProp ?? testId}__tbody`}
      // @TODO: deprecate the use of plain "tbody" class, infavour of
      // the more specific "TableBody" classes
      className={`${className ?? ""} TableBody tbody`}
    >
      {Children.map(flattenedChildren, (child) => {
        if (isChildSubcomponent(child, TableRow)) {
          return cloneElement(child, { parentNode: "tbody" });
        }

        return renderNullAndWarnUser(TableBody.displayName);
      })}
    </Box>
  );
}

TableBody.displayName = "Table.Body";
