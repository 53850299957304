import { type AllSingleVariantIconKeys, Box, Icon, Stack } from "@biom3/react";
import NextLink from "next/link";

function mapIcons(network: string): AllSingleVariantIconKeys | undefined {
  switch (network) {
    case "App Store":
      return "Apple";
    case "Windows":
      return "Windows";
    case "Play Store":
      return "GooglePlayStore";
    case "Browser":
      return "ApplicationWeb";
  }

  return undefined;
}

export const Platforms = ({
  platforms,
  links,
  analyticsEvent,
}: {
  platforms: string[];
  links?: string[];
  analyticsEvent: (link: string, specLabel: string) => void;
}): React.ReactNode => {
  return (
    <Stack direction="row" gap="base.spacing.x2">
      {platforms.map((platform, idx) => {
        const icon = mapIcons(platform);
        const link = links?.[idx];

        if (!icon) return null;

        return (
          <Box
            key={`${icon}-${link}`}
            rc={
              link ? (
                <NextLink
                  onClick={() => analyticsEvent(link, "Platform")}
                  href={link}
                  target="_blank"
                  referrerPolicy="no-referrer"
                />
              ) : undefined
            }
          >
            <Icon sx={{ w: "base.icon.size.300" }} icon={icon} key={platform} />
          </Box>
        );
      })}
    </Stack>
  );
};
