import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import type { BiomeTheme, BodyProps } from "@/types";

import { thumbTextSx } from "./styles";
import type { CarouselThumbVariants } from "./types";

type CarouselThumbnailThumbTextProps<RC extends ReactElement | undefined> =
  BodyProps<RC> & { variant?: CarouselThumbVariants };

export function CarouselThumbnailThumbText<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  variant,
  sx = {},
  className,
  weight = "bold",
  ...props
}: CarouselThumbnailThumbTextProps<RC>) {
  return (
    <Body
      {...props}
      weight={weight}
      className={`${className ?? ""} CarouselThumbnailThumbText`}
      sx={merge(
        thumbTextSx,
        {
          ...(variant === "horizontal"
            ? {
                c: "base.color.fixed.white.1000",
                textShadow: ({ base }: BiomeTheme) =>
                  `0px 2px 2px ${base.color.fixed.black[1000]}`,
              }
            : {
                c: "base.color.text.body.primary",
                textAlign: "left",
              }),
        },
        sx,
      )}
    >
      {children}
    </Body>
  );
}

CarouselThumbnailThumbText.displayName = "CarouselThumbnail.Thumb.Text";
