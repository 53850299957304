import type { DistributiveOmit } from "@/types";

import { Select } from "@/components/MenuItemRelatedComponents";
import { useGetSubcomponentChild } from "@/hooks";
import type { SelectProps } from "@/types";

export type TablePageSizeProps = DistributiveOmit<SelectProps, "size"> & {};

export function TablePageSize({
  children,
  defaultLabel = "Rows per page",
  className,
  ...props
}: TablePageSizeProps) {
  const defaultIcon = useGetSubcomponentChild(children, Select.DefaultIcon);
  return (
    <Select
      {...props}
      size="medium"
      defaultLabel={defaultLabel}
      className={`${className ?? ""} TablePageSize`}
    >
      {children}
      {defaultIcon ?? <Select.DefaultIcon icon="ViewList" />}
    </Select>
  );
}

TablePageSize.displayName = "Table.PageSize";
TablePageSize.DefaultIcon = Select.DefaultIcon;
TablePageSize.Option = Select.Option;
