import type { CoreContext, PluginType } from "@segment/analytics-core";
import type { Plugin } from "@segment/analytics-next";

export const EVENT_FORMATTER: Plugin = {
  name: "App name prefix",
  type: "before" as PluginType,
  version: "1.0.0",
  isLoaded: () => true,
  load: () => Promise.resolve(),
  track: (ctx: CoreContext) => {
    // Replace spaces with underscores
    if (ctx.event.event) {
      ctx.event.event = ctx.event.event.replace(/\s+/g, "_");
    }
    return ctx;
  },
};

// Add the previous route as a referrer property on every page/event
export class PropertiesPlugin implements Plugin {
  name = "Properties";
  type: PluginType = "before";
  version = "1.0.0";
  private _referrer: string | undefined;
  private _isLoggedIn = false;

  constructor(initialReferrer?: string) {
    this._referrer = initialReferrer;
  }

  isLoaded = () => true;
  load = () => Promise.resolve();

  track = (ctx: CoreContext) => {
    ctx.event.properties = {
      ...ctx.event.properties,
      referrer: this._referrer ?? "",
      isLoggedIn: this._isLoggedIn,
    };
    return ctx;
  };
  page = (ctx: CoreContext) => {
    ctx.event.properties = {
      ...ctx.event.properties,
      referrer: this._referrer ?? "",
      isLoggedIn: this._isLoggedIn,
    };
    return ctx;
  };

  setReferrer(referrer: string) {
    this._referrer = referrer.toLowerCase();
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this._isLoggedIn = isLoggedIn;
  }
}
