import { merge } from "ts-deepmerge";

import type { BiomeTheme, MakeResponsive } from "@/types";
import {
  getBodyTextStyles,
  getCaptionTextStyles,
  getHeadingTextStyles,
  isError,
} from "@/utils/textStyleHelpers";
import type { DividerSize } from "./shared";

export const baseStyles = (
  hasChildren: boolean,
  textAlign: "left" | "center",
) => ({
  display: "flex",
  justifyContent: textAlign === "left" ? "flex-start" : "center",
  textAlign,
  alignItems: "center",
  width: "100%",
  border: "none",

  ...(textAlign === "left"
    ? {
        "&::after": {
          content: '""',
          flex: 1,
          ml: hasChildren ? "base.spacing.x6" : "0",
        },
      }
    : {
        "&::after, &::before": {
          content: '""',
          flex: 1,
        },
        "&::after": {
          ml: hasChildren ? "base.spacing.x6" : "0",
        },
        "&::before": {
          mr: hasChildren ? "base.spacing.x6" : "0",
        },
      }),
});

export const getSizeBasedStyles = ({
  size,
  theme,
}: {
  size: DividerSize;
  theme: BiomeTheme;
}) => {
  const textStyles =
    size === "large"
      ? getHeadingTextStyles({
          size: "xSmall",
          weight: "bold",
          themeProps: theme,
        })
      : size === "medium"
        ? getBodyTextStyles({
            size: "medium",
            weight: "bold",
            themeProps: theme,
          })
        : getCaptionTextStyles({
            size: "small",
            weight: "bold",
            themeProps: theme,
          });

  return {
    ...textStyles,
    color:
      size === "xSmall"
        ? theme.base.color.text.body.secondary
        : theme.base.color.text.body.primary,

    "&::after, &::before": {
      height: size === "large" ? "4px" : size === "medium" ? "2px" : "1px",
      background:
        size === "xSmall"
          ? theme.base.color.translucent.emphasis[200]
          : theme.base.color.text.body.primary,
    },
  };
};

export const getResponsiveStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<DividerSize>;
  theme: BiomeTheme;
}) => {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getSizeBasedStyles({
            size: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
};
