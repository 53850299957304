import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import type { IconProps } from "@/types";

export type MenuItemIconProps<RC extends ReactElement | undefined> =
  IconProps<RC>;

export function MenuItemIcon<RC extends ReactElement | undefined>({
  sx = {},
  ...props
}: MenuItemIconProps<RC>) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          w: "base.icon.size.300",
          fill: "base.color.text.body.primary",
          flexShrink: 0,
        },
        sx,
      ),
    [sx],
  );
  return <Icon {...props} sx={mergedSx} />;
}

MenuItemIcon.displayName = "MenuItem.Icon";
