import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";

import { SvgIcon } from "../SvgIcon";

export function MetaMaskSymbol({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent("MetaMaskSymbol");
  return (
    <SvgIcon
      {...props}
      testId={testId}
      viewBox="0 0 48 48"
      sx={merge({ w: "100px" }, sx)}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}

MetaMaskSymbol.displayName = "MetaMaskSymbol";
