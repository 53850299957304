import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedImage } from "@/components/FramedThings";
import { DEFAULT_CHIP_SIZE } from "@/constants";

import {
  type ChipFramedImageProps,
  useGetFramedImageSizeFromChipSize,
} from "./shared";
import { baseChipLeftImageContentSx } from "./styles";

export function ChipFramedImage<
  Use extends ReactElement | undefined = undefined,
>({
  size = DEFAULT_CHIP_SIZE,
  className,
  sx = {},
  use,
  ...props
}: ChipFramedImageProps<Use>) {
  const mergedSx = useMemo(() => merge(baseChipLeftImageContentSx, sx), [sx]);
  const sizeToUse = useGetFramedImageSizeFromChipSize(size);
  return (
    <FramedImage
      {...props}
      size={sizeToUse}
      className={`${className ?? ""} ChipFramedImage`}
      sx={mergedSx}
    />
  );
}

ChipFramedImage.displayName = "Chip.FramedImage";
