import type {
  LooseAutocomplete,
  SpacingTokenPaths,
} from "@biom3/design-tokens";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps, MakeValidSxValue } from "@/types";

export type GridProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & {
    minColumnWidth?: string;
    gap?: MakeValidSxValue<LooseAutocomplete<NonNullable<SpacingTokenPaths>>>;
  };

export function Grid<RC extends ReactElement | undefined = undefined>({
  sx = {},
  minColumnWidth = "180px",
  gap = "base.spacing.x4",
  className,
  ...props
}: GridProps<RC>) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`,
          gap,
          alignItems: "stretch",
          width: "100%",
        },
        sx,
      ),
    [sx, gap, minColumnWidth],
  );
  return <Box {...props} sx={mergedSx} className={`${className ?? ""} Grid`} />;
}

Grid.displayName = "Grid";
