import "../styles/globals.css";
import "../i18n";
import "regenerator-runtime/runtime";
import { GoogleTagManager } from "@next/third-parties/google";
import { PrismicPreview } from "@prismicio/next";

import { repositoryName } from "@/prismic";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import type { ComponentType, ReactElement, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { appConfig } from "@/constants";
import {
  FeatureFlagProvider,
  FiatPricingProvider,
  PassportProvider,
  QueryProvider,
  RouteProvider,
} from "@/context";
import { AssetImportProvider } from "@/context/AssetImportProvider";
import { PreferencesProvider } from "@/context/PreferencesProvider";
import { ProfileDrawerProvider } from "@/context/ProfileDrawerContext";
import ErrorComponent from "./_error";

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

function App({ Component, pageProps }: AppProps) {
  // @ts-ignore
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <RouteProvider>
        <PassportProvider>
          <QueryProvider>
            <PreferencesProvider>
              <FeatureFlagProvider>
                <FiatPricingProvider>
                  <AssetImportProvider>
                    <ProfileDrawerProvider>
                      {getLayout(<Component {...pageProps} />)}
                    </ProfileDrawerProvider>

                    <GoogleTagManager gtmId={appConfig.GTM_CONTAINER_ID} />
                    <PrismicPreview repositoryName={repositoryName} />
                  </AssetImportProvider>
                </FiatPricingProvider>
              </FeatureFlagProvider>
            </PreferencesProvider>
          </QueryProvider>
        </PassportProvider>
      </RouteProvider>
    </ErrorBoundary>
  );
}

export default withLDProvider({
  clientSideID: appConfig.LAUNCH_DARKLY_CLIENT_ID,
})(App as ComponentType<unknown>);
