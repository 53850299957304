import { useCallback, useEffect, useId, useMemo, useState } from "react";

import { DEFAULT_TOOLTIP_SIZE } from "@/constants";
import { useForwardLocalDomRef, useGetSubcomponentChild } from "@/hooks";
import { useTooltipStore } from "@/providers";
import {
  CLEAR_TOOLTIP,
  STORE_TOOLTIP,
  UPDATE_TOOLTIP_CONTENT,
} from "@/providers/BiomeTooltipProvider/tooltipContext";
import type { TooltipComponentProps } from "@/types";
import { SmartClone } from "../SmartClone";
import { TooltipContent } from "./TooltipContent";
import { TooltipTarget } from "./TooltipTarget";
import {
  TooltipComponentContext,
  recursivelyAddTooltipAttrsToChildNodes,
} from "./shared";

export function Tooltip({
  id: idProp,
  children,
  size = DEFAULT_TOOLTIP_SIZE,
  domRef = { current: null },
  ...props
}: TooltipComponentProps) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const target = useGetSubcomponentChild(children, TooltipTarget);
  const content = useGetSubcomponentChild(children, TooltipContent);
  const { dispatchAction } = useTooltipStore((state) => state);
  const [currentTooltipId, setCurrentTooltipId] = useState("");

  // @NOTE: this useEffect allows Modal content to stay up to date,
  // as the application re-renders and children potentially change
  useEffect(() => {
    dispatchAction({
      type: UPDATE_TOOLTIP_CONTENT,
      payload: {
        id,
        content,
      },
    });
  }, [content, id, dispatchAction]);

  // @NOTE: we cannot have the uuid change, when this component re-renders,
  // so, we need to set it once and only once at component onMount.
  useEffect(() => {
    setCurrentTooltipId(id);
  }, [id]);

  // @NOTE: clean up the tooltip when the component is unmounted
  useEffect(() => {
    return () =>
      dispatchAction({
        type: CLEAR_TOOLTIP,
        payload: {
          id,
        },
      });
  }, [id, dispatchAction]);

  // @NOTE: recursively add tooltip attributes to all child nodes
  // whenever things change
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentTooltipId) {
      recursivelyAddTooltipAttrsToChildNodes(
        localDomRef.current,
        currentTooltipId,
        true,
      );
    }
  }, [currentTooltipId, localDomRef, children]);

  useEffect(() => {
    const targetRect = localDomRef.current?.getBoundingClientRect();
    dispatchAction({
      type: STORE_TOOLTIP,
      payload: {
        id,
        size,
        content,
        targetRect,
      },
    });
  }, [id, size, content, localDomRef, dispatchAction]);

  const triggerDomRecrawl = useCallback(() => {
    recursivelyAddTooltipAttrsToChildNodes(
      localDomRef.current,
      currentTooltipId,
      true,
    );
  }, [currentTooltipId, localDomRef]);
  const contextValue = useMemo(
    () => ({ triggerDomRecrawl }),
    [triggerDomRecrawl],
  );

  return target ? (
    <TooltipComponentContext.Provider value={contextValue}>
      <SmartClone {...props} id={id} domRef={localDomRef}>
        {target}
      </SmartClone>
    </TooltipComponentContext.Provider>
  ) : null;
}

Tooltip.displayName = "Tooltip";
Tooltip.Target = TooltipTarget;
Tooltip.Content = TooltipContent;
