import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedLogo } from "@/components/FramedThings";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type { FramedLogoProps, MenuItemProps } from "@/types";

import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseFramedIconStyles } from "./styles";

export type MenuItemFramedLogoProps<
  RC extends ReactElement | undefined = undefined,
> = Omit<FramedLogoProps<RC>, "size"> & Pick<MenuItemProps, "size">;

export function MenuItemFramedLogo<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  size = DEFAULT_MENU_ITEM_SIZE,
  rc = <span />,
  className,
  ...props
}: MenuItemFramedLogoProps<RC>) {
  const allSx = useMemo(() => merge(baseFramedIconStyles, sx), [sx]);
  const sizeToUse = useGetBiomeImageSizeFromMenuItemSize(size);
  return (
    <FramedLogo
      {...props}
      sx={allSx}
      rc={rc}
      size={sizeToUse}
      className={`${className ?? ""} MenuItemFramedLogo`}
    />
  );
}

MenuItemFramedLogo.displayName = "MenuItem.FramedLogo";
