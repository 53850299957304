import { type RefObject, useCallback, useContext, useEffect } from "react";

import { BiomeShadowRootContext } from "../providers/BiomeShadowRootProvider";

/**
 * This function listens for clicks/touches outside of specified elements and triggers a handler
 * function when detected.
 * @param elementRefs - An array of RefObject or undefined elements that represent the elements that
 * should not trigger the handler when clicked.
 * @param handler - The function that will be called when a click event occurs outside of the specified
 * elementRefs.
 */
export function useOnClickOutside<T extends HTMLElement | null>(
  elementRefs: Array<RefObject<T> | undefined> = [],
  handler: () => void = () => null,
) {
  const shadowRoot = useContext(BiomeShadowRootContext);
  const clickAnywhereListener = useCallback(
    (event: Event) => {
      // Do nothing if we do not have a handler, or if there are
      // no elementRefs to check against
      if (!handler || elementRefs.length === 0) {
        return;
      }

      const isClickInside = elementRefs.some((elementRef) => {
        return elementRef
          ? elementRef?.current?.contains(event.target as Node)
          : false;
      });

      if (!isClickInside) handler();
    },
    [elementRefs, handler],
  );

  useEffect(() => {
    if (shadowRoot) {
      shadowRoot.addEventListener("mousedown", clickAnywhereListener);
      shadowRoot.addEventListener("touchstart", clickAnywhereListener);
    } else {
      document.body.addEventListener("mousedown", clickAnywhereListener);
      document.body.addEventListener("touchstart", clickAnywhereListener);
    }

    return () => {
      if (shadowRoot) {
        shadowRoot.removeEventListener("mousedown", clickAnywhereListener);
        shadowRoot.removeEventListener("touchstart", clickAnywhereListener);
      } else {
        document.body.removeEventListener("mousedown", clickAnywhereListener);
        document.body.removeEventListener("touchstart", clickAnywhereListener);
      }
    };
  }, [clickAnywhereListener, shadowRoot]);
}
