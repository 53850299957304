import Hls, { type ErrorData, type Events } from "hls.js";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { merge } from "ts-deepmerge";

import { VIDEO_MIME_TYPES } from "@/constants";
import {
  useConvertSxToEmotionStyles,
  useForwardLocalDomRef,
  usePrevious,
} from "@/hooks";
import type { VideoProps } from "@/types";
import { cloneElementWithCssProp, warnUser } from "@/utils";
import { createSyntheticEvent } from "@/utils/eventHelpers";

const HLS_MIME_TYPE = VIDEO_MIME_TYPES[2];

// @TODO: add some sensible default values to the below:
const HLS_CONFIG_DEFAULTS = {};

export function Video({
  videoUrl,
  mimeType,
  domRef = { current: null },
  testId = "Video",
  className,
  sx = {},
  playsInline = true,
  controls = false,
  autoPlay = false,
  muted = autoPlay,
  hlsConfig,
  getHlsApi,
  onError,
  onHlsError,
  ...props
}: VideoProps) {
  const css = useConvertSxToEmotionStyles(sx);
  const localDomRef = useForwardLocalDomRef(domRef);
  const hlsRef = useRef<Hls | null>(null);
  const previousVideoUrl = usePrevious(videoUrl);

  useEffect(() => {
    return () => {
      checkThenDestroyPlayer();
    };
  }, []);

  const checkThenDestroyPlayer = useCallback(() => {
    if (hlsRef.current !== null) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
  }, []);

  const handleHlsError = useCallback(
    (event: Events.ERROR, data: ErrorData) => {
      if (!localDomRef.current) return;
      onHlsError?.(data);
      if (data.fatal) {
        const reactSyntheticErrorEvent = createSyntheticEvent(
          localDomRef.current,
          new Event(event, { bubbles: true }),
        );
        onError?.(reactSyntheticErrorEvent);
      }
    },
    [onError, onHlsError, localDomRef.current],
  );

  const initHlsPlayer = useCallback(() => {
    // @NOTE: if there is no <video> tag to attach to, early exit
    if (localDomRef.current === null) return;

    // @NOTE: cleanup a previous player if it exists
    checkThenDestroyPlayer();

    const newHls = new Hls(
      hlsConfig ? merge(HLS_CONFIG_DEFAULTS, hlsConfig) : HLS_CONFIG_DEFAULTS,
    );
    // @NOTE: allow parent components to access the Hls API:
    getHlsApi?.(newHls);

    // @NOTE: bind to various HLS events:
    newHls.on(Hls.Events.ERROR, handleHlsError);
    newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
      newHls.loadSource(videoUrl);
      newHls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          localDomRef?.current?.play().catch(() => {
            warnUser(
              "Video - Unable to autoplay prior to user interaction with the dom.",
            );
          });
        }
      });
    });

    newHls.attachMedia(localDomRef.current);
    hlsRef.current = newHls;
  }, [
    autoPlay,
    getHlsApi,
    handleHlsError,
    hlsConfig,
    localDomRef,
    videoUrl,
    checkThenDestroyPlayer,
  ]);

  const memoizedVideoProps = useMemo(
    () => ({
      playsInline,
      controls,
      muted,
      autoPlay,
      "data-testid": testId,
      ref: localDomRef,
      className: `${className ?? ""} Video`,
      css,
      onError,
    }),
    [
      playsInline,
      controls,
      muted,
      autoPlay,
      testId,
      localDomRef,
      className,
      css,
      onError,
    ],
  );

  const video = cloneElementWithCssProp(
    // biome-ignore lint/a11y/useMediaCaption: <explanation>
    <video />,
    {
      ...props,
      ...memoizedVideoProps,
    },
  );

  // @NOTE: init the video:
  useEffect(() => {
    // Check for Media Source support
    if (Hls.isSupported() && mimeType === HLS_MIME_TYPE) {
      // @NOTE: dont constantly re-init the player if the videoUrl is the same
      if (previousVideoUrl !== videoUrl) {
        initHlsPlayer();
      }
    } else if (localDomRef.current !== null) {
      localDomRef.current.src = videoUrl;
    }
  }, [initHlsPlayer, localDomRef, mimeType, videoUrl, previousVideoUrl]);

  return video;
}

Video.displayName = "Video";
