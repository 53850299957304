import type { BiomeTheme } from "@/types";
import { centerFlexChildren, hFlex } from "@/utils";

export const smallButtonStaticStyles = ({ base }: BiomeTheme) => ({
  height: base.spacing.x8,
  fontSize: base.text.body.xSmall.regular.fontSize,
  fontWeight: base.text.body.medium.bold.fontWeight,
  fontFamily: base.font.family.body.primary,
  borderRadius: base.borderRadius.x15,
  paddingX: base.spacing.x3,
});

export const mediumButtonStaticStyles = ({ base }: BiomeTheme) => ({
  height: base.spacing.x12,
  fontSize: base.text.body.medium.regular.fontSize,
  fontWeight: base.text.body.medium.bold.fontWeight,
  fontFamily: base.font.family.body.primary,
  borderRadius: base.borderRadius.x15,
  paddingX: base.spacing.x4,
});

export const largeButtonStaticStyles = ({ base }: BiomeTheme) => ({
  height: base.spacing.x16,
  fontSize: base.text.body.medium.regular.fontSize,
  fontWeight: base.text.body.medium.bold.fontWeight,
  fontFamily: base.font.family.body.primary,
  borderRadius: base.borderRadius.x20,
  paddingX: base.spacing.x5,
});

export const baseButtonSx = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
  transitionProperty: "box-shadow",
  // @NOTE: we seem to need this line of SX purely to stop iOS Safari
  // from displaying weird render artifacts on the button
  // https://immutable.atlassian.net/browse/DS-809
  translate: "0px",

  "&[disabled]": {
    cursor: "default",
  },

  "&.Button--large, &.Button--medium": {
    "& .Button__innerSpan__innerContent": {
      pl: "base.spacing.x3",

      "&:last-child": {
        pr: "base.spacing.x3",
      },
    },
    "& .Button__innerSpan__innerContent + .Button__iconOrLogo": {
      ml: "base.spacing.x3",
    },
  },

  "&.Button--small": {
    "& .Button__innerSpan__innerContent": {
      pl: "base.spacing.x2",

      "&:last-child": {
        pr: "base.spacing.x2",
      },
    },

    "& .Button__innerSpan__innerContent + .Button__iconOrLogo": {
      ml: "base.spacing.x2",
    },
  },

  "& .Button__innerSpan__innerContent + .Button__innerSpan__innerContent": {
    p: "0px",
  },

  "&.ButtCon .Button__innerSpan": {
    ...hFlex,
    ...centerFlexChildren,
    h: "100%",

    "& .Button__innerSpan__innerContent": {
      ...hFlex,
      ...centerFlexChildren,
      h: "100%",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
};
