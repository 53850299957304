import { merge } from "ts-deepmerge";

import {
  BUTTON_SIZES,
  DEFAULT_BUTTON_SIZE,
  DEFAULT_BUTTON_VARIANT,
} from "@/constants";
import { useTheme } from "@/hooks";
import type { ButtonSize, ButtonVariant, MakeResponsive } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { SvgIcon, type SvgIconProps } from "../../SvgIcon";
import { getButtConIconStyles, getResponsiveButtConIconStyles } from "./styles";

export type ButtonIconProps = SvgIconProps & {
  size?: MakeResponsive<ButtonSize>;
  variant?: ButtonVariant;
};

export function ButtConSvgIcon({
  size = DEFAULT_BUTTON_SIZE,
  variant = DEFAULT_BUTTON_VARIANT,
  domRef,
  children,
  sx = {},
  ...props
}: ButtonIconProps) {
  const themeProps = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);
  const allIconSx = merge(
    getButtConIconStyles({ size: startingSize, variant, themeProps }),
    getResponsiveButtConIconStyles({
      variant,
      size,
      themeProps,
    }),
    {
      ...sx,
      // @NOTE: convert sx shorthand to longhand (if neccissary)
      // So that these sizes are correctly applied down below inside SvgIcon
      ...(sx.w ? { width: sx.w } : sx.width ? { width: sx.width } : {}),
      ...(sx.h ? { height: sx.w } : sx.height ? { height: sx.height } : {}),
    },
  );
  return (
    <SvgIcon {...props} sx={allIconSx}>
      {children}
    </SvgIcon>
  );
}

ButtConSvgIcon.displayName = "ButtCon.SvgIcon";
