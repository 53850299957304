import {
  type BaseTokensLeaf,
  type DesignTokens,
  designTokens,
  leafIsObject,
  smartPickTokenValue,
} from "@biom3/design-tokens";
import { Global, type Interpolation, ThemeProvider } from "@emotion/react";
import { type ReactNode, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { useConvertSxToEmotionStyles } from "@/hooks";
import type { BiomeTheme, DeeplyNestedSx } from "@/types";
import type { InputTheme } from "../shared";
import { DEFAULT_GLOBAL_SX, getFontStyles } from "./styles";

export function recursivelyConvertNestedColorTokens<T extends BaseTokensLeaf>(
  leaf: T,
  theme: DesignTokens,
) {
  if (leafIsObject(leaf)) {
    let leafKey: keyof typeof leaf;
    for (leafKey in leaf) {
      const child = leaf[leafKey];
      if (typeof child === "object") {
        leaf[leafKey] = recursivelyConvertNestedColorTokens(
          leaf[leafKey],
          theme,
        );
      } else if (typeof child === "string" && child.includes("base.")) {
        leaf[leafKey] = smartPickTokenValue(theme, child);
      }
    }
  }
  return leaf;
}

function augmentThemeWithCalculatedTokens(theme: DesignTokens): BiomeTheme {
  const { base, ...other } = theme;
  const { color } = base;
  return {
    base: {
      ...base,
      color: recursivelyConvertNestedColorTokens(color, theme),
      breakpointAsArray: Object.values(base.breakpoint),
    },
    ...other,
  };
}

const DEFAULT_THEME = designTokens;

export type BiomeThemeProviderProps = {
  theme?: InputTheme;
  children: ReactNode;
  globalSx?: DeeplyNestedSx;
  skipFontLoad?: boolean;
};

export function BiomeThemeProvider({
  children,
  theme = DEFAULT_THEME,
  globalSx = {},
  skipFontLoad = false,
}: BiomeThemeProviderProps) {
  const augmentedTheme = useMemo(
    () => augmentThemeWithCalculatedTokens(theme),
    [theme],
  );
  const globalStyles = useConvertSxToEmotionStyles(
    merge(
      DEFAULT_GLOBAL_SX,
      {
        html: {
          // @NOTE: this ensures that ui elements like TextArea's and
          // Scrollbars etc are colored correctly for that specific theme :tada:
          colorScheme:
            augmentedTheme.base.colorMode === "onDark" ? "dark" : "light",
        },
      },
      globalSx,
    ),
    augmentedTheme,
  );

  return (
    <ThemeProvider theme={augmentedTheme}>
      <Global
        styles={[
          ...getFontStyles(skipFontLoad),
          globalStyles as Interpolation<BiomeTheme>,
        ]}
      />
      {children}
    </ThemeProvider>
  );
}
