import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import type { BoxWithRCAndDomProps } from "@/types";

import { shimmerSx } from "./style";

export function ShimmerBox<RC extends ReactElement | undefined = undefined>({
  sx = {},
  className,
  ...props
}: BoxWithRCAndDomProps<RC>) {
  return (
    <Box
      {...props}
      sx={merge(shimmerSx, sx)}
      className={`${className ?? ""} ShimmerBox`}
    />
  );
}

ShimmerBox.displayName = "ShimmerBox";
