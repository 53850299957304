import { type ReactElement, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { APP_HEADER_BAR_SIZES, DEFAULT_APP_HEADER_BAR_SIZE } from "@/constants";
import { useTheme } from "@/hooks";
import {
  type GetHybridClickableRCandDomProps,
  type LogoBaseProps,
  type MakeResponsive,
  type OnClickFunction,
  isBaseClickable,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { BaseClickable } from "../Clickable";
import { Logo } from "../Logo";
import { SmartClone } from "../SmartClone";
import type { AppHeaderBarContentAlign, AppHeaderBarSize } from "./shared";
import { renderResponsiveTitleLogoSx, renderTitleLogoSx } from "./styles";

export type AppHeaderBarTitleLogoProps<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick, "svg"> &
  Omit<LogoBaseProps, "domRef"> & {
    size?: MakeResponsive<AppHeaderBarSize>;
    contentAlign?: AppHeaderBarContentAlign;
  };

export function AppHeaderBarTitleLogo<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({
  size = DEFAULT_APP_HEADER_BAR_SIZE,
  logo,
  sx = {},
  contentAlign,
  rc,
  testId,
  className,
  ...props
}: AppHeaderBarTitleLogoProps<RC, OnClick>) {
  const { onClick, ...propsWithoutOnClick } =
    "onClick" in props ? props : { ...props, onClick: null };
  const { domRef, ...propsWithoutOnClickAndDomRef } =
    "domRef" in propsWithoutOnClick
      ? propsWithoutOnClick
      : { ...propsWithoutOnClick, domRef: null };
  const baseClickableProps = { onClick, rc, ...propsWithoutOnClickAndDomRef };
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_APP_HEADER_BAR_SIZE,
    APP_HEADER_BAR_SIZES,
  );
  const titleLogoSx = useMemo(
    () =>
      merge(
        contentAlign === "left"
          ? {
              pl: "base.spacing.x1",
              w: "auto",
              mr: "auto",
            }
          : {
              left: 0,
              textAlign: "center",
              pos: "absolute",
              w: "100%",
            },
        renderTitleLogoSx({ theme: themeProps, size: startingSize }),
        renderResponsiveTitleLogoSx({
          theme: themeProps,
          size,
        }),
        sx,
      ),
    [sx, themeProps, size, startingSize, contentAlign],
  );
  const logoChildren = (
    <Logo {...propsWithoutOnClickAndDomRef} logo={logo} sx={titleLogoSx} />
  );
  const mergedClassNames = `${className ?? ""} AppHeaderBarTitleLogo`;
  return isBaseClickable(onClick, rc, false, baseClickableProps) ? (
    <BaseClickable
      {...baseClickableProps}
      sx={titleLogoSx}
      testId={testId}
      className={mergedClassNames}
      domRef={domRef}
    >
      {logoChildren}
    </BaseClickable>
  ) : (
    <SmartClone
      sx={titleLogoSx}
      testId={testId}
      className={mergedClassNames}
      domRef={domRef as Ref<HTMLElement>}
    >
      {logoChildren}
    </SmartClone>
  );
}

AppHeaderBarTitleLogo.displayName = "AppHeaderBar.TitleLogo";
