import { Children, isValidElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { DEFAULT_TOOLBAR_SIZE, TOOLBAR_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type { BoxProps, MakeResponsive, ToolbarSize } from "@/types";
import { getStartingSize } from "../../utils/styleHelpers";
import { isChildSubcomponent } from "../../utils/subcomponentHelpers";
import { Box } from "../Box";
import { ButtCon, Button } from "../Clickable";
import { HorizontalMenu } from "../HorizontalMenu";
import { SmartClone } from "../SmartClone";
import {
  getResponsiveRightSideMarginStyles,
  getRightSideMarginStyles,
} from "./styles";

export type ToolbarRightSlotProps = BoxProps & {
  size?: MakeResponsive<ToolbarSize>;
};

export function ToolbarRightSlot({
  size = DEFAULT_TOOLBAR_SIZE,
  children,
  sx = {},
  testId,
  ...props
}: ToolbarRightSlotProps) {
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_TOOLBAR_SIZE,
    TOOLBAR_SIZES,
  );
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  return (
    <Box
      {...props}
      sx={merge(
        {
          display: "flex",
          alignItems: "center",
          gap: "base.spacing.x2",
        },
        getRightSideMarginStyles({ size: startingSize, themeProps }),
        getResponsiveRightSideMarginStyles({ size, themeProps }),
        sx,
      )}
      testId={testId}
    >
      {Children.map(flattenedChildren, (child) => {
        if (
          isChildSubcomponent(child, Button) ||
          isChildSubcomponent(child, ButtCon)
        ) {
          // @NOTE: render sub-component children, with some special extra props
          return (
            <SmartClone
              variant={
                isValidElement(child) && child.props.variant
                  ? child.props.variant
                  : "tertiary"
              }
              size={
                isValidElement(child) && child.props.size
                  ? child.props.size
                  : size
              }
            >
              {child}
            </SmartClone>
          );
        }
        if (isChildSubcomponent(child, HorizontalMenu)) {
          return (
            <SmartClone
              size={
                isValidElement(child) && child.props.size
                  ? child.props.size
                  : size
              }
            >
              {child}
            </SmartClone>
          );
        }

        return child;
      })}
    </Box>
  );
}

ToolbarRightSlot.displayName = "Toolbar.ToolbarRightSlot";
