import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import type { BodyProps } from "@/types";

import type { ProgressBarVariant } from "./shared";

export type ProgressBarCaptionProps<RC extends ReactElement | undefined> =
  BodyProps<RC> & {
    variant?: ProgressBarVariant;
    value?: number;
    centerText?: boolean;
  };

export function ProgressBarCaption<
  RC extends ReactElement | undefined = undefined,
>({
  size,
  className,
  children,
  sx = {},
  centerText = false,
  ...props
}: ProgressBarCaptionProps<RC>) {
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...(centerText
            ? { textAlign: "center", order: -1 }
            : { textAlign: "right", ml: "auto" }),
        },
        sx,
      ),
    [sx, centerText],
  );
  return (
    <Body
      {...props}
      size={size ?? "small"}
      sx={mergedSx}
      className={`${className ?? ""} ProgressBarCaption`}
    >
      {children}
    </Body>
  );
}

ProgressBarCaption.displayName = "ProgressBar.Caption";
