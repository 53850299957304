import { motion } from "motion/react";
import { Children, type ReactElement, useCallback, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import {
  splitApartChildrenAndSubComponents,
  useGetMotionProfile,
  useTheme,
} from "@/hooks";
import type { BoxWithRCAndDomProps, ToastItem } from "@/types";
import { hFlex, isChildSubcomponent, vFlex } from "@/utils";

import { ToastButtCon } from "./ToastButtCon";
import { ToastButton } from "./ToastButton";
import { ToastMessage } from "./ToastMessage";
import {
  DEFAULT_TOAST_BUTTONS_POSITION,
  DEFAULT_TOAST_VARIANT,
} from "./shared";

export type InlineToastComponentProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> &
  Partial<Omit<ToastItem, "content" | "position" | "autoDismissDuration">>;

const DEFAULT_OFF_MOTION_PROPS = {
  opacity: 0,
  y: -15,
};

export function InlineToast<RC extends ReactElement | undefined>({
  id,
  className,
  testId = "InlineToast",
  buttonsPosition = DEFAULT_TOAST_BUTTONS_POSITION,
  variant = DEFAULT_TOAST_VARIANT,
  hideDismissButton,
  onCloseToast,
  children,
  sx = {},
  rc: rcProp,
  ...props
}: InlineToastComponentProps<RC>) {
  const { base } = useTheme();
  const { otherChildren } = splitApartChildrenAndSubComponents(children, [
    ToastMessage,
    ToastButtCon,
    ToastButton,
  ]);
  const motionProfile = useGetMotionProfile();
  const rc = useMemo(() => {
    if (rcProp) return rcProp;

    return motionProfile !== "none" ? (
      <motion.div
        initial={DEFAULT_OFF_MOTION_PROPS}
        exit={DEFAULT_OFF_MOTION_PROPS}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: base.motion.normal[motionProfile].jsDuration,
          ease: base.motion.normal[motionProfile].jsEase,
        }}
      />
    ) : undefined;
  }, [rcProp, motionProfile, base.motion.normal]);

  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...(buttonsPosition === "right"
            ? {
                ...hFlex,
                alignItems: "center",
                justifyContent: "space-between",
              }
            : { ...vFlex }),
          gap: "base.spacing.x4",
          bg: "base.color.translucent.standard.1000",
          p: "base.spacing.x2",
          pr: "base.spacing.x3",
          pl: "base.spacing.x9",
          brad: "base.borderRadius.x6",
          minw: "230px",
          maxw: "450px",
          minh: "64px",
          boxShadow: "base.shadow.500",
          position: "relative",
        },
        sx,
      ),
    [sx, buttonsPosition],
  );
  const handleCloseToast = useCallback(() => onCloseToast?.(), [onCloseToast]);
  const variantBarSx = useMemo(
    () => ({
      top: "base.spacing.x2",
      bottom: "base.spacing.x2",
      position: "absolute",
      left: "base.spacing.x2",
      width: "6px",
      brad: "base.borderRadius.x6",
      bg: `base.color.status.${variant}.bright`,
    }),
    [variant],
  );
  const buttonsContainerSx = useMemo(
    () => ({
      ...hFlex,
      ...(buttonsPosition === "bottomRight"
        ? { justifyContent: "flex-end" }
        : {}),
      gap: "base.spacing.x1",
    }),
    [buttonsPosition],
  );
  return (
    <Box
      rc={rc}
      data-id={id}
      key={id}
      testId={testId}
      className={`${className ?? ""} InlineToast`}
      sx={mergedSx}
      {...props}
    >
      <Box testId="toastVariantBar" sx={variantBarSx} />

      {Children.map(children, (child) => {
        return isChildSubcomponent(child, ToastMessage) ? child : null;
      })}
      <Box testId="toastButtonsContainer" sx={buttonsContainerSx}>
        {Children.map(children, (child) => {
          return isChildSubcomponent(child, ToastButtCon) ||
            isChildSubcomponent(child, ToastButton)
            ? child
            : null;
        })}
        {!hideDismissButton ? (
          <ToastButton testId="dismissToastButton" onClick={handleCloseToast}>
            Dismiss
          </ToastButton>
        ) : null}
      </Box>
      {otherChildren}
    </Box>
  );
}

InlineToast.displayName = "InlineToast";
InlineToast.Button = ToastButton;
InlineToast.ButtCon = ToastButtCon;
InlineToast.Message = ToastMessage;
