import { ClassNames } from "@emotion/react";
import { AnimatePresence } from "motion/react";
import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type FocusEvent,
  type ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { ClearFieldButton } from "@/components/Clickable";
import {
  DEFAULT_TEXT_AREA_ALIGN,
  DEFAULT_TEXT_AREA_SIZE,
  TEXT_AREA_SIZES,
} from "@/constants";
import {
  useConvertSxToEmotionStyles,
  useForwardLocalDomRef,
  useGetMotionProfile,
  useTheme,
} from "@/hooks";
import type { BiomeTheme, TextAreaProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { cloneElementWithCssProp, getMotionProfileSx } from "@/utils";
import {
  baseTextAreaSx,
  clearValueButtonSx,
  containerBaseSx,
  getClearValueButtonSx,
  getContainerSx,
  getResponsiveClearValueButtonSx,
  getResponsiveContainerSx,
  getResponsiveTextAreaSx,
  getTextAreaSx,
} from "./styles";

export function TextArea<RC extends ReactElement | undefined = undefined>({
  onClick,
  domRef,
  textAreaRef = { current: null },
  placeholder,
  testId = "TextArea",
  name,
  id = name,
  children,
  className,
  validationStatus,
  onChange,
  onClearValue,
  value,
  // @NOTE: normally, the ClearValueButton would be a subcomponent, and engineers
  // can choose whether they want it or not. This would mean however, that by default,
  // we have no ClearValueButton. This is not ideal - as design wants inputs to
  // feature this by default. Thus, for now, it is a simple flag instead, and is not
  // a subcomponent.
  hideClearValueButton,
  rc,
  // @NOTE: this would normally be undefined, but then we get react errors
  // around a component switching between controlled and uncontrolled.
  defaultValue = "",
  disabled,
  // @NOTE: make some minor visual adjustments to the input, so that
  // it takes a very similar size to the TextInput component, when left
  // at default values.
  rows = 5,
  cols = 4,
  // @NOTE: normally this prop would simply be called "size", but
  // native text input tags already have a property called "size",
  // (The size attribute defines the width of the <input> and the
  // height of the <select> element).
  // Thus we will need to call this property something else,
  // eg "sizeVariant"
  sizeVariant = DEFAULT_TEXT_AREA_SIZE,
  textAlign = DEFAULT_TEXT_AREA_ALIGN,
  sx = {},
  motionProfile,
  onBlur,
  onFocus,
  ...inputDomAttributes
}: RC extends undefined ? TextAreaProps : TextAreaProps & { rc: RC }) {
  const localTextAreaRef = useForwardLocalDomRef(textAreaRef);
  const [uncontrolledValue, setUncontrolledValue] = useState<
    ComponentPropsWithoutRef<"textarea">["value"]
  >(
    typeof value === "number"
      ? value.toString()
      : typeof value === "string"
        ? value
        : defaultValue,
  );

  const onClearInputText = useCallback(() => {
    // @NOTE: if its a CONTROLLED input, simply call the
    // onClearValue prop, and then early exit
    if (value !== undefined) {
      return onClearValue?.();
    }

    // @NOTE: input is UNCONTROLLED, so we need to manually
    // update it's value:
    if (localTextAreaRef.current) {
      localTextAreaRef.current.value = "";
      localTextAreaRef.current.focus();
    }
    onChange?.({
      type: "change",
      target: localTextAreaRef?.current,
    } as ChangeEvent<HTMLTextAreaElement>);
    setUncontrolledValue("");
    onClearValue?.();
    return false;
  }, [localTextAreaRef, onChange, value, onClearValue]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (value === undefined) setUncontrolledValue(event.target.value);
      onChange?.(event);
    },
    [onChange, value],
  );

  const valueToUse = value ?? uncontrolledValue;
  const theme = useTheme();
  const defaultSize = getStartingSize(
    sizeVariant,
    DEFAULT_TEXT_AREA_SIZE,
    TEXT_AREA_SIZES,
  );
  const motionProfileToUse = useGetMotionProfile(motionProfile);

  const allContainerStyles = merge(
    containerBaseSx,
    getContainerSx({
      validationStatus,
      size: defaultSize,
      theme,
      hasValue: Boolean(valueToUse),
    }),
    getResponsiveContainerSx({
      validationStatus,
      size: sizeVariant,
      theme,
      hasValue: Boolean(valueToUse),
    }),
    getMotionProfileSx(motionProfileToUse, theme),
    sx,
  );

  const allInputSx = merge(
    baseTextAreaSx,
    getTextAreaSx({
      textAlign,
      theme,
      size: defaultSize,
    }),
    getResponsiveTextAreaSx({
      textAlign,
      theme,
      size: sizeVariant,
    }),
  );
  const convertedInputStyles = useConvertSxToEmotionStyles(allInputSx);
  const allClearButtonSx = merge(
    clearValueButtonSx,
    {
      mx: ({ base }: BiomeTheme) => `calc(${base.spacing.x1} * -1)`,
    },
    getClearValueButtonSx({ theme, size: defaultSize }),
    getResponsiveClearValueButtonSx({ theme, size: sizeVariant }),
  );

  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(
    (ev: FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      onFocus?.(ev);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (ev: FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false);
      onBlur?.(ev);
    },
    [onBlur],
  );

  const memoizedInputProps = useMemo(
    () => ({
      rows,
      cols,
      disabled,
      name,
      ref: localTextAreaRef,
      "data-testid": `${testId}__textarea`,
      onChange: handleInputChange,
      value: valueToUse,
      id,
      placeholder,
      css: convertedInputStyles,
      onFocus: handleFocus,
      onBlur: handleBlur,
    }),
    [
      cols,
      convertedInputStyles,
      disabled,
      handleBlur,
      handleFocus,
      handleInputChange,
      id,
      localTextAreaRef,
      name,
      placeholder,
      testId,
      valueToUse,
      rows,
    ],
  );

  const textArea = cloneElementWithCssProp(<textarea />, {
    ...inputDomAttributes,
    ...memoizedInputProps,
  });

  return (
    <ClassNames>
      {({ cx }) => (
        <Box
          rc={rc}
          domRef={domRef}
          className={cx(`${className ?? ""} TextArea`, { disabled, focused })}
          testId={testId}
          sx={allContainerStyles}
          data-validation-status={validationStatus}
          onClick={(ev) => {
            onClick?.(ev);
            localTextAreaRef.current?.focus();
          }}
        >
          {textArea}

          <AnimatePresence>
            {valueToUse && !hideClearValueButton && (
              <ClearFieldButton
                testId={`${testId}__clearValueButton`}
                sx={allClearButtonSx}
                onClick={onClearInputText}
                disabled={disabled}
              />
            )}
          </AnimatePresence>
        </Box>
      )}
    </ClassNames>
  );
}

TextArea.displayName = "TextArea";
