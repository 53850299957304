import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import {
  type DeeplyNestedSx,
  type FramedIconProps,
  isDualVariantIcon,
} from "@/types";

import { FramedIcon } from "../FramedThings";
import { framedComponentSx } from "./styles";

export function StickerFramedIcon<
  RC extends ReactElement | undefined = undefined,
>({
  icon,
  variant,
  sx = {},
  emphasized = true,
  size = "small",
  ...props
}: FramedIconProps<RC>) {
  const iconProps = isDualVariantIcon(icon) ? { icon, variant } : { icon };
  const mergedSx = useMemo(
    () =>
      merge(
        framedComponentSx,
        {
          ...(emphasized
            ? ({
                boxShadow: ({ base }) =>
                  `0 0 0 ${base.border.size[200]} ${base.color.translucent.inverse[1000]}`,
              } as DeeplyNestedSx)
            : {}),
        },
        sx,
      ),
    [sx, emphasized],
  );
  return (
    <FramedIcon
      {...iconProps}
      {...props}
      sx={mergedSx}
      size={size}
      emphasized={emphasized}
    />
  );
}

StickerFramedIcon.displayName = "Sticker.FramedIcon";
