import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "../Box";
import { useTabsContext } from "./TabsContext";
import { tabsPanelStyles } from "./styles";
import type { TabsPanelProps } from "./types";

export function TabsPanel<RC extends ReactElement | undefined = undefined>({
  testId = "Tabs__Panel",
  children,
  value,
  sx = {},
  className,
  ...props
}: TabsPanelProps<RC>) {
  const { currentTabValue } = useTabsContext();

  // @NOTE: if this panel is not the one that should currently be rendered,
  // then simply return null
  if (currentTabValue !== value) return null;
  const allStyles = merge(tabsPanelStyles, sx);

  return (
    <Box
      {...props}
      testId={testId}
      sx={allStyles}
      className={`${className ?? ""} TabsPanel`}
    >
      {children}
    </Box>
  );
}

TabsPanel.displayName = "Tabs.Panel";
