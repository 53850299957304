import type { DeeplyNestedSx } from "@/types";

export const BASE_TRANSLATE = "32px";

export const baseDrawerSx: DeeplyNestedSx = {
  zIndex: "base.zLevel.drawer",
  background: "base.color.neutral.800",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  "&.Drawer--position_left": {
    left: "0px",
    top: "0px",
    boxShadow: ({ base }) =>
      `
      -${BASE_TRANSLATE} 0 0 ${base.color.neutral[700]},
      ${base.border.size[100]} 0px 0px ${base.color.translucent.standard[500]}
      `,
    brad: ({ base }) => `0 ${base.borderRadius.x10} ${base.borderRadius.x10} 0`,
  },

  "&.Drawer--position_right": {
    right: "0px",
    top: "0px",
    boxShadow: ({ base }) => `
      ${BASE_TRANSLATE} 0 0 ${base.color.neutral[700]},
      -${base.border.size[100]} 0px 0px ${base.color.translucent.standard[500]}
    `,
    brad: ({ base }) => `${base.borderRadius.x10} 0 0 ${base.borderRadius.x10}`,
  },

  "&.Drawer--position_bottom": {
    bottom: "0px",
    left: "0px",
    boxShadow: ({ base }) => `
      0 ${BASE_TRANSLATE} 0 ${base.color.neutral[700]},
      0px -${base.border.size[100]} 0px ${base.color.translucent.standard[500]}
    `,
    brad: ({ base }) => `${base.borderRadius.x10} ${base.borderRadius.x10} 0 0`,
  },

  "&.Drawer--size_quarter": {
    "&.Drawer--position_left, &.Drawer--position_right": {
      w: "25%",
      h: "100%",
    },
    "&.Drawer--position_bottom": {
      w: "100%",
      h: "25%",
    },
  },

  "&.Drawer--size_half": {
    "&.Drawer--position_left, &.Drawer--position_right": {
      w: "50%",
      h: "100%",
    },
    "&.Drawer--position_bottom": {
      w: "100%",
      h: "50%",
    },
  },

  "&.Drawer--size_threeQuarter": {
    "&.Drawer--position_left, &.Drawer--position_right": {
      w: "75%",
      h: "100%",
    },
    "&.Drawer--position_bottom": {
      w: "100%",
      h: "75%",
    },
  },

  "&.Drawer--size_full": {
    "&.Drawer--position_left, &.Drawer--position_right": {
      w: "calc(100% - 48px)",
      h: "100%",
    },
    "&.Drawer--position_bottom": {
      w: "100%",
      h: "calc(100% - 48px)",
    },
  },

  "&.Drawer--size_edge": {
    "&.Drawer--position_left, &.Drawer--position_right": {
      w: "calc(100% - 8px)",
      h: "100%",
    },
    "&.Drawer--position_bottom": {
      w: "100%",
      h: "calc(100% - 8px)",
    },
  },
};

export const RIGHT_TRANSLATE = BASE_TRANSLATE;
export const LEFT_TRANSLATE = `-${BASE_TRANSLATE}`;
export const BOTTOM_TRANSLATE = BASE_TRANSLATE;
export const NO_TRANSLATE = "0px";

export const headerBarSx = {
  d: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "base.spacing.x14",
  flexShrink: 0,
};

export const headerBarButtConSx = {
  pos: "absolute",
  top: "base.spacing.x3",
  left: "base.spacing.x3",
};
