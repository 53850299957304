import type {
  LooseAutocomplete,
  SpacingTokenPaths,
} from "@biom3/design-tokens";
import { ClassNames } from "@emotion/react";
import type { Property } from "csstype";
import type {
  EmblaCarouselType,
  EmblaOptionsType,
  EmblaPluginType,
} from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
  Children,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import {
  CarouselSimpleNextButtCon,
  CarouselSimplePreviousButtCon,
} from "@/components/CarouselSimple/CarouselSimpleControlButtCons";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { DEFAULT_CAROUSEL_THUMB_VARIANT } from "@/constants";
import {
  useGetMotionProfile,
  useGetSubcomponentChild,
  useGetSubcomponentChildren,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  DeeplyNestedSx,
  MakeValidSxValue,
  MotionProfile,
  StandardComponentWithProps,
} from "@/types";
import { childHasIconProp, warnUser } from "@/utils";

import { CarouselThumbnailSlide } from "./CarouselThumbnailSlide";
import { CarouselThumbnailThumb } from "./CarouselThumbnailThumb";
import { DefaultThumb } from "./DefaultThumb";
import {
  baseRootSx,
  controlPaginationHoriztonalSx,
  controlPaginationVerticalSx,
  viewportSx,
} from "./styles";
import type { CarouselThumbVariants } from "./types";

type CarouselThumbnailOptions = Pick<
  EmblaOptionsType,
  // @TODO: add support for breakpoints and active props
  "loop" | "dragFree" | "dragThreshold"
>;

export type CarouselThumbnailProps<
  RC extends ReactElement | undefined = undefined,
> = StandardComponentWithProps<
  HTMLDivElement,
  CarouselThumbnailOptions &
    BoxWithRCAndDomProps<RC> & {
      variant?: CarouselThumbVariants;
      motionProfile?: MotionProfile;
      plugins?: EmblaPluginType[];
      thumbHeight?: MakeValidSxValue<
        LooseAutocomplete<NonNullable<SpacingTokenPaths>>
      >;
      thumbSpacing?: MakeValidSxValue<
        LooseAutocomplete<NonNullable<SpacingTokenPaths>>
      >;
      getApi?: (api: EmblaCarouselType) => void;
      getThumbsApi?: (api: EmblaCarouselType) => void;
      onNextSlide?: () => void;
      onPreviousSlide?: () => void;
      onSlideChange?: (index: number) => void;
      slideAspectRatio?: MakeValidSxValue<Property.AspectRatio>;
      controlBarSx?: DeeplyNestedSx;
    }
>;

const makeBadThumbsErrorMsg = (
  slidesLength?: number,
  thumbsLength?: number,
) => `CarouselThumbnail: The number of supplied slide children (${slidesLength}) and custom thumb children (${thumbsLength}) do not match. 
CarouselThumbnail will not show any custom thumbnails. Please supply a custom thumb for all slides, or none to see the default thumbnails.`;

export function CarouselThumbnail<
  RC extends ReactElement | undefined = undefined,
>({
  // @NOTE: Embla props --------------------------------
  loop = true,
  dragThreshold = 10,
  dragFree = false,
  plugins = [],
  // @NOTE: Biome props --------------------------------
  variant = DEFAULT_CAROUSEL_THUMB_VARIANT,
  onNextSlide,
  onPreviousSlide,
  onSlideChange,
  testId = "CarouselThumbnail",
  slideAspectRatio = "16/9",
  getApi,
  getThumbsApi,
  children,
  className,
  motionProfile,
  thumbSpacing = "base.spacing.x2",
  thumbHeight = "base.spacing.x20",
  sx = {},
  controlBarSx = {},
  ...props
}: CarouselThumbnailProps<RC>) {
  const slidesViewportRef = useRef<HTMLDivElement | null>(null);
  const thumbsViewportRef = useRef<HTMLDivElement | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const duration = useMemo(
    () =>
      motionProfileToUse === "fast"
        ? 15
        : motionProfileToUse === "gentle"
          ? 30
          : 0,
    [motionProfileToUse],
  );
  const slides = useGetSubcomponentChildren(children, CarouselThumbnailSlide);
  const thumbs = useGetSubcomponentChildren(children, CarouselThumbnailThumb);
  const nextButtCon = useGetSubcomponentChild(
    children,
    CarouselSimpleNextButtCon,
  );
  const prevButtCon = useGetSubcomponentChild(
    children,
    CarouselSimplePreviousButtCon,
  );
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    CarouselThumbnailSlide,
    CarouselThumbnailThumb,
    CarouselSimpleNextButtCon,
    CarouselSimplePreviousButtCon,
  ]);
  const [slidesEmblaRef, slidesEmblaApi] = useEmblaCarousel(
    {
      loop,
      skipSnaps: false,
      slidesToScroll: 1,
      dragFree,
      duration,
      dragThreshold,
    },
    plugins,
  );
  const [thumbsEmblaRef, thumbsEmblaApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
    duration,
    axis: variant === "vertical" ? "y" : "x",
  });

  // @NOTE: handle when a new slide selection is made:
  const handleSelect = useCallback(() => {
    if (!slidesEmblaApi || !thumbsEmblaApi) return;
    const slide = slidesEmblaApi.selectedScrollSnap();
    setSelectedIndex(slide);
    thumbsEmblaApi.scrollTo(slide);
    onSlideChange?.(slide);
  }, [slidesEmblaApi, thumbsEmblaApi, onSlideChange]);

  // @NOTE: handle when a thumb is clicked:
  const onThumbClick = useCallback(
    (index: number) => {
      if (!slidesEmblaApi || !thumbsEmblaApi) return;
      slidesEmblaApi.scrollTo(index);
    },
    [slidesEmblaApi, thumbsEmblaApi],
  );

  // @NOTE: Allow parent component to access Embla API:
  useEffect(() => {
    if (slidesEmblaApi) getApi?.(slidesEmblaApi);
    if (thumbsEmblaApi) getThumbsApi?.(thumbsEmblaApi);
  }, [slidesEmblaApi, thumbsEmblaApi, getApi, getThumbsApi]);

  // @NOTE: ensure embla carousel re-inits when children change:
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (slidesEmblaApi) {
      getApi?.(slidesEmblaApi);
      handleSelect();
      slidesEmblaApi.on("select", handleSelect);

      return () => {
        slidesEmblaApi.off("select", handleSelect);
      };
    }
  }, [slides?.length, slidesEmblaApi]);

  const invalidCustomThumbs = useMemo(() => {
    if (thumbs?.length !== 0 && slides?.length !== thumbs?.length) {
      warnUser(makeBadThumbsErrorMsg(slides?.length, thumbs?.length));
      return true;
    }

    return false;
  }, [slides?.length, thumbs?.length]);

  // @NOTE: some logic for the next and prev buttons
  const canScrollPrev = slidesEmblaApi?.canScrollPrev() || false;
  const canScrollNext = slidesEmblaApi?.canScrollNext() || false;
  const scrollPrev = useCallback(() => {
    slidesEmblaApi?.scrollPrev();
    canScrollPrev && onPreviousSlide?.();
  }, [slidesEmblaApi, canScrollPrev, onPreviousSlide]);
  const scrollNext = useCallback(() => {
    slidesEmblaApi?.scrollNext();
    canScrollNext && onNextSlide?.();
  }, [slidesEmblaApi, canScrollNext, onNextSlide]);

  const mergedRootSx = useMemo(
    () =>
      merge(
        {
          "--thumbSpacing": thumbSpacing,
          "--thumbHeight": thumbHeight,
        },
        baseRootSx,
        sx,
      ),
    [sx, thumbSpacing, thumbHeight],
  );

  const paginationButtonsExist = Boolean(nextButtCon || prevButtCon);
  const mergedControlBarSx = useMemo(
    () =>
      merge(
        {
          pos: "relative",
          ...(variant === "horizontal"
            ? {
                w: "100%",
                mt: "base.spacing.x4",
                px: paginationButtonsExist ? "base.spacing.x15" : "0px",
              }
            : {
                minw: "170px",
                w: "28%",
                ml: "base.spacing.x4",
                py: paginationButtonsExist ? "base.spacing.x15" : "0px",
              }),
        },
        controlBarSx,
      ),
    [controlBarSx, variant, paginationButtonsExist],
  );
  const handleThumbClick = useCallback(
    (index: number) => () => onThumbClick(index),
    [onThumbClick],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <Stack
          {...props}
          className={cx(
            className,
            "CarouselThumbnail",
            `CarouselThumbnail--${variant}`,
            {
              "CarouselThumbnail--loop": loop,
            },
          )}
          testId={testId}
          sx={mergedRootSx}
          direction={variant === "horizontal" ? "column" : "row"}
          gap="0px"
        >
          <Box
            domRef={(el) => {
              if (el) {
                slidesViewportRef.current = el;
                slidesEmblaRef(el);
              }
            }}
            sx={merge(viewportSx, {
              w: "100%",
            })}
            className="CarouselThumbnail__slidesViewport"
            testId={`${testId}__slidesViewport`}
          >
            <Stack
              direction="row"
              alignItems="stretch"
              className="CarouselThumbnail__slidesContainer"
              testId={`${testId}__slidesContainer`}
              gap="0px"
            >
              {Children.map(slides, (slide, index) => (
                <SmartClone
                  aspectRatio={slideAspectRatio}
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={`slide--${index}`}
                >
                  {slide}
                </SmartClone>
              ))}
            </Stack>
          </Box>

          <Stack
            className="CarouselThumbnail__controlBar"
            justifyContent="center"
            direction={variant === "horizontal" ? "row" : "column"}
            sx={mergedControlBarSx}
          >
            <Stack
              domRef={(el) => {
                if (el) {
                  thumbsViewportRef.current = el;
                  thumbsEmblaRef(el);
                }
              }}
              sx={{
                ...viewportSx,
                ...(variant === "vertical"
                  ? {
                      h: "100%",
                      pos: "relative",
                    }
                  : { pr: "1px" }),
              }}
              className="CarouselThumbnail__thumbsViewport"
              testId={`${testId}__thumbsViewport`}
              direction={variant === "horizontal" ? "row" : "column"}
            >
              <Stack
                direction={variant === "horizontal" ? "row" : "column"}
                alignItems="stretch"
                className="CarouselThumbnail__thumbsContainer"
                testId={`${testId}__thumbsContainer`}
                gap="var(--thumbSpacing)"
                sx={{
                  ...(variant === "horizontal"
                    ? { w: "100%" }
                    : {
                        // @NOTE: we do not want the thumbs list to affect
                        // the CarouselThumbnail's root element height
                        // so we use absolute positioning here
                        pos: "absolute",
                        h: "100%",
                        w: "100%",
                        top: "0",
                        left: "0",
                      }),
                }}
              >
                {Children.map(slides, (_, index) => {
                  const customThumb = thumbs?.[index];
                  const isSelected = index === selectedIndex;
                  return !invalidCustomThumbs && customThumb ? (
                    <SmartClone
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      key={`thumb--${index}`}
                      onClick={handleThumbClick(index)}
                      selected={isSelected}
                      index={index}
                      variant={variant}
                      motionProfile={motionProfileToUse}
                    >
                      {customThumb}
                    </SmartClone>
                  ) : (
                    <DefaultThumb
                      variant={variant}
                      selected={isSelected}
                      index={index}
                      onClick={handleThumbClick(index)}
                      motionProfile={motionProfileToUse}
                    />
                  );
                })}
              </Stack>
            </Stack>

            {prevButtCon && (
              <SmartClone
                onClick={scrollPrev}
                className="CarouselThumbnail__prevButtCon"
                sx={{
                  ...(variant === "horizontal"
                    ? { ...controlPaginationHoriztonalSx, left: "0px" }
                    : { ...controlPaginationVerticalSx, top: "0px" }),
                }}
                size={prevButtCon?.props?.size || "medium"}
                icon={
                  childHasIconProp(prevButtCon)
                    ? prevButtCon.props.icon
                    : variant === "vertical"
                      ? "ArrowUp"
                      : undefined
                }
                testId="CarouselThumbnail__prevButtCon"
              >
                {prevButtCon}
              </SmartClone>
            )}

            {nextButtCon && (
              <SmartClone
                onClick={scrollNext}
                className="CarouselThumbnail__nextButtCon"
                sx={{
                  ...(variant === "horizontal"
                    ? { ...controlPaginationHoriztonalSx, right: "0px" }
                    : { ...controlPaginationVerticalSx, bottom: "0px" }),
                }}
                size={nextButtCon?.props?.size || "medium"}
                icon={
                  childHasIconProp(nextButtCon)
                    ? nextButtCon.props.icon
                    : variant === "vertical"
                      ? "ArrowDown"
                      : undefined
                }
                testId="CarouselThumbnail__nextButtCon"
              >
                {nextButtCon}
              </SmartClone>
            )}
          </Stack>

          {otherChildren}
        </Stack>
      )}
    </ClassNames>
  );
}

CarouselThumbnail.displayName = "CarouselThumbnail";

CarouselThumbnail.Slide = CarouselThumbnailSlide;
CarouselThumbnail.Thumb = CarouselThumbnailThumb;
CarouselThumbnail.NextButtCon = CarouselSimpleNextButtCon;
CarouselThumbnail.PreviousButtCon = CarouselSimplePreviousButtCon;
