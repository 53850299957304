import type { DistributiveOmit } from "@/types";
import { base } from "@biom3/design-tokens";
import { motion } from "motion/react";
import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type KeyboardEvent,
  type MouseEvent,
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TEXT_INPUT_SIZE, TEXT_INPUT_SIZES } from "@/constants";
import {
  useBrowserEffect,
  useForwardLocalDomRef,
  useGetCurrentSizeClass,
  useGetSubcomponentChild,
  useOnClickOutside,
  useResizeObserver,
  useTheme,
} from "@/hooks";
import { useWindowSizeStore } from "@/providers";
import type { BoxWithRCAndDomProps, TextInputProps } from "@/types";

import { Box } from "../Box";
import { MenuItem, VerticalMenu } from "../MenuItemRelatedComponents";
import { selectChevronSx } from "../MenuItemRelatedComponents/Select/styles";
import { Popover } from "../Popover";
import { SvgIcon } from "../SvgIcon";
import { TextInput } from "../TextInput";
import { TextInputIcon } from "../TextInput/TextInputIcon";

export type AutocompleteProps<RC extends ReactElement | undefined = undefined> =
  DistributiveOmit<BoxWithRCAndDomProps<RC>, "onChange"> &
    Omit<
      TextInputProps,
      | "onChange"
      | "hideClearValueButton"
      | "inputMode"
      | "onFocus"
      | "onBlur"
      | "value"
    > & {
      options: string[];
      onChange?: (selectedValue: string | null) => void;
      onInputChange?: ComponentPropsWithoutRef<"input">["onChange"];
      inputValue?: string;
      value?: string | null;
    };

export function Autocomplete<RC extends ReactElement | undefined = undefined>({
  children,
  options,
  onClearValue,
  name,
  sizeVariant = DEFAULT_TEXT_INPUT_SIZE,
  textAlign,
  domRef = { current: null },
  inputRef = { current: null },
  defaultValue = "",
  value,
  inputValue,
  onChange,
  onInputChange,
  placeholder,
  validationStatus,
  testId = "Autocomplete",
  className,
  sx = {},
  ...props
}: AutocompleteProps<RC>) {
  const theme = useTheme();
  const { state: windowSize } = useWindowSizeStore((store) => store);
  const menuItemRefs = useRef<HTMLElement[]>([]);
  const localContainerRef = useForwardLocalDomRef(domRef);
  const size = useResizeObserver(localContainerRef);
  const localInputRef = useForwardLocalDomRef(inputRef);
  const leftIcon = useGetSubcomponentChild(children, TextInputIcon);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState<string[]>(options);
  const [activeHoverIndex, setActiveHoverIndex] = useState<number | null>(null);
  const closePopoverAndBlur = useCallback(() => {
    setPopoverOpen(false);
    localInputRef.current?.blur();
  }, [localInputRef]);
  const [uncontrolledValue, setUncontrolledValue] = useState(
    typeof inputValue === "string" ? inputValue : defaultValue,
  );

  const handleDownArrow = useCallback(() => {
    let newActiveHoverIndex = activeHoverIndex;
    if (
      activeHoverIndex === null ||
      activeHoverIndex === visibleOptions.length - 1
    ) {
      newActiveHoverIndex = 0;
    } else {
      newActiveHoverIndex = activeHoverIndex + 1;
    }
    setActiveHoverIndex(newActiveHoverIndex);
    menuItemRefs.current[newActiveHoverIndex]?.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "auto",
    });
  }, [activeHoverIndex, visibleOptions.length]);

  const handleUpArrow = useCallback(() => {
    let newActiveHoverIndex = activeHoverIndex;
    if (activeHoverIndex === null) {
      newActiveHoverIndex = 0;
    } else if (activeHoverIndex === 0) {
      newActiveHoverIndex = visibleOptions.length - 1;
    } else {
      newActiveHoverIndex = activeHoverIndex - 1;
    }

    setActiveHoverIndex(newActiveHoverIndex);
    menuItemRefs.current[newActiveHoverIndex]?.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "auto",
    });
  }, [activeHoverIndex, visibleOptions.length]);

  const handleEnter = useCallback(() => {
    if (activeHoverIndex !== null) {
      const option = visibleOptions[activeHoverIndex] ?? null;
      onChange?.(option);
      setUncontrolledValue(option ?? "");
      closePopoverAndBlur();
    } else {
      const option = visibleOptions[0] ?? null;
      onChange?.(option);
      setUncontrolledValue(option ?? "");
      closePopoverAndBlur();
    }
  }, [activeHoverIndex, closePopoverAndBlur, onChange, visibleOptions]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "Escape":
          closePopoverAndBlur();
          break;

        case "Enter":
          handleEnter();
          break;

        case "ArrowUp":
          event.preventDefault();
          handleUpArrow();
          break;

        case "ArrowDown":
          event.preventDefault();
          handleDownArrow();
          break;

        default:
          break;
      }
    },
    [closePopoverAndBlur, handleDownArrow, handleEnter, handleUpArrow],
  );

  const valueToUse =
    typeof value === "string"
      ? value
      : typeof inputValue === "string"
        ? inputValue
        : uncontrolledValue;

  useBrowserEffect(() => {
    if (valueToUse !== "") {
      setVisibleOptions(
        options.filter((option) =>
          option.toLowerCase().includes(valueToUse.toLowerCase()),
        ),
      );
    } else {
      setVisibleOptions(options);
    }
  }, [valueToUse, options]);
  useBrowserEffect(() => setPopoverOpen(false), [windowSize]);
  useOnClickOutside([localContainerRef], () => setPopoverOpen(false));
  const handleInputClick = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      ev.preventDefault();
      setPopoverOpen(!popoverOpen);
    },
    [popoverOpen],
  );
  const handleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setActiveHoverIndex(null);
      onChange?.(null);
      onInputChange?.(ev);
      setUncontrolledValue(ev.target.value);
    },
    [onChange, onInputChange],
  );
  const handleInputClear = useCallback(() => {
    // @NOTE: if its a CONTROLLED input, simply call the
    // onClearValue prop, and then early exit
    if (value !== undefined) {
      onChange?.(null);
      return onClearValue?.();
    }

    return setUncontrolledValue("");
  }, [onChange, onClearValue, value]);

  const rightChevronSx = useMemo(
    () =>
      merge(selectChevronSx, {
        position: "absolute",
        right: "14px",
        top: "50%",
        translate: "0 -50%",
        pointerEvents: "none",
        userSelect: "none",
      }),
    [],
  );
  const currentSizeClass = useGetCurrentSizeClass(
    sizeVariant,
    DEFAULT_TEXT_INPUT_SIZE,
    TEXT_INPUT_SIZES,
  );
  const mergedContainerSx = merge(
    theme.components?.Autocomplete?.sxOverride ?? {},
    sx,
  );

  return (
    <Box
      {...props}
      domRef={localContainerRef}
      testId={testId}
      sx={mergedContainerSx}
      className={`${
        className ?? ""
      } Autocomplete AutoComplete--${currentSizeClass}`}
    >
      <Popover visible={popoverOpen} position={{ y: "below", x: "left" }}>
        <Popover.Target>
          <TextInput
            name={name}
            value={valueToUse}
            inputMode="text"
            textAlign={textAlign}
            testId={`${testId}__textInput`}
            validationStatus={validationStatus}
            sizeVariant={sizeVariant}
            inputRef={localInputRef}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onClick={handleInputClick}
            onChange={handleInputChange}
            onClearValue={handleInputClear}
            autoComplete="off"
            sx={{
              "& .rightButtonsContainer": {
                pointerEvents: "none",
                userSelect: "none",
              },
            }}
          >
            {leftIcon}

            {/* @TODO: DUMMY ELEMENT, here simply to allow correct spacing on 
            the righthand side of the TextInput, would probably be better to use some 
            sx on the TextInput to alter the paddingRight instead - though this 
            could get quite complicated */}
            <TextInput.StatefulButtCon
              icon="Add"
              sx={{
                opacity: "0",
                // @NOTE: make the button match the rough size of the chevron
                minw: "24px",
                w: "24px",
              }}
            />

            <SvgIcon
              className="chevron"
              sx={rightChevronSx}
              rc={<motion.svg />}
            >
              <motion.path
                initial="down"
                variants={{
                  down: { d: "M5 9L12 16L19 9" },
                  up: { d: "M5 16L12 9L19 16" },
                }}
                animate={popoverOpen ? "up" : "down"}
                transition={{
                  ease: base.motion.normal.fast.jsEase,
                  duration: base.motion.normal.fast.jsDuration,
                }}
              />
            </SvgIcon>
          </TextInput>
        </Popover.Target>
        <Popover.Content
          sx={{ w: `${size.width}px`, boxShadow: "base.shadow.500" }}
        >
          <VerticalMenu
            testId={`${testId}__verticalMenu`}
            textAlign={textAlign}
            sx={{ minw: "unset" }}
          >
            {visibleOptions.length === 0 ? (
              <MenuItem>
                <MenuItem.Label>No results found</MenuItem.Label>
              </MenuItem>
            ) : (
              visibleOptions.map((option, index) => (
                <MenuItem
                  domRef={(element) => {
                    if (element) {
                      menuItemRefs.current[index] = element;
                    }
                  }}
                  key={option}
                  onClick={() => {
                    onChange?.(option);
                    setUncontrolledValue(option);
                  }}
                  controlledHover={activeHoverIndex === index}
                  onMouseEnter={() => setActiveHoverIndex(index)}
                >
                  <MenuItem.Label>{option}</MenuItem.Label>
                </MenuItem>
              ))
            )}
          </VerticalMenu>
        </Popover.Content>
      </Popover>
    </Box>
  );
}

Autocomplete.displayName = "Autocomplete";
Autocomplete.Icon = TextInputIcon;
