import { type ReactNode, useMemo, useRef } from "react";
import { merge } from "ts-deepmerge";

import { Badge } from "@/components/Badge/Badge";
import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import {
  useGetManySingleSubcomponentChildren,
  useResizeObserver,
} from "@/hooks";
import type { DeeplyNestedSx, StandardComponentWithProps } from "@/types";
import { StickerBadge } from "./StickerBadge";
import { StickerFramedIcon } from "./StickerFramedIcon";
import { StickerFramedImage } from "./StickerFramedImage";
import { StickerFramedLogo } from "./StickerFramedLogo";

export type StickerContentPosition = {
  y: "top" | "bottom" | "topInside" | "bottomInside" | "center";
  x: "left" | "right" | "leftInside" | "rightInside" | "center";
};

export type StickerProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    position?: StickerContentPosition;
    children: ReactNode;
  }
>;

function getPositionSx(position: StickerContentPosition) {
  let sx: DeeplyNestedSx = {};
  switch (position.y) {
    case "bottom":
    case "bottomInside":
      sx = {
        bottom: "0px",
        ...(position.y === "bottom" ? { transform: "translateY(50%)" } : {}),
      };
      break;
    case "center":
      sx = { top: "50%", transform: "translateY(-50%)" };
      break;
    case "top":
    case "topInside":
      sx = {
        top: "0px",
        ...(position.y === "top" ? { transform: "translateY(-50%)" } : {}),
      };
      break;
  }
  switch (position.x) {
    case "left":
    case "leftInside":
      sx = merge(sx, {
        left: "0px",
        ...(position.x === "left"
          ? { transform: `${sx.transform ?? ""} translateX(-50%)` }
          : {}),
      });
      break;

    case "right":
    case "rightInside":
      sx = merge(sx, {
        right: "0px",
        ...(position.x === "right"
          ? { transform: `${sx.transform ?? ""} translateX(50%)` }
          : {}),
      });
      break;

    case "center":
      sx = merge(sx, {
        left: "50%",
        transform: `${sx.transform ?? ""} translateX(-50%)`,
      });
      break;
  }
  return sx;
}

export function Sticker({
  position = { y: "top", x: "right" },
  children,
  rc = <span />,
  sx = {},
  testId = "Sticker",
  className,
  domRef,
  // @NOTE: allow for props like onClick etc to be passed through from
  // parent component's like Popover.Target, etc
  ...props
}: StickerProps) {
  const {
    subcomponents: [badge, framedImage, framedIcon, framedLogo],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    {
      subcomponent: StickerBadge,
      excludeParent: [Sticker],
    },
    {
      subcomponent: StickerFramedImage,
      excludeParent: [Sticker],
    },
    {
      subcomponent: StickerFramedIcon,
      excludeParent: [Sticker],
    },
    {
      subcomponent: StickerFramedLogo,
      excludeParent: [Sticker],
    },
  ]);
  const stickerContent = badge || framedImage || framedIcon || framedLogo || (
    <Badge />
  );
  const childRef = useRef<HTMLElement>(null);
  const { width, height } = useResizeObserver(childRef);
  const mergedContainerSx = useMemo(
    () =>
      merge(
        {
          pos: "relative",
          d: "inline-flex",
          ...(width && height ? { w: `${width}px`, h: `${height}px` } : {}),
        },
        sx,
      ),
    [sx, width, height],
  );

  const mergedStickerContentSx = useMemo(
    () =>
      merge(
        {
          ...getPositionSx(position),
          pos: "absolute",
        },
        stickerContent.props.sx ?? {},
      ),
    [position, stickerContent.props.sx],
  );
  const firstChild = otherChildren[0];

  return (
    <Box
      testId={testId}
      className={`${className ?? ""} Sticker Sticker--x-${position.x}_y-${position.y}`}
      rc={rc}
      sx={mergedContainerSx}
      domRef={domRef}
    >
      <SmartClone domRef={childRef} {...props}>
        {firstChild}
      </SmartClone>

      <SmartClone
        testId={`${testId}__stickerContent`}
        sx={mergedStickerContentSx}
      >
        {stickerContent}
      </SmartClone>
    </Box>
  );
}

Sticker.displayName = "Sticker";
Sticker.Badge = StickerBadge;
Sticker.FramedImage = StickerFramedImage;
Sticker.FramedIcon = StickerFramedIcon;
Sticker.FramedLogo = StickerFramedLogo;
