import { motion } from "motion/react";
import { type ReactElement, cloneElement, useMemo, useRef } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { useGetMotionProfile, useResizeObserver, useTheme } from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  DeeplyNestedSx,
  MotionProfile,
} from "@/types";

export type AnimateHeightBoxProps<RC extends ReactElement | undefined> =
  BoxWithRCAndDomProps<RC> & {
    motionProfile?: MotionProfile;
    innerContainerSx?: DeeplyNestedSx;
  };

export function AnimateHeightBox<
  RC extends ReactElement | undefined = undefined,
>({
  motionProfile,
  testId = "AnimateHeightBox",
  sx = {},
  innerContainerSx = {},
  children,
  rc = <motion.div />,
  className,
  ...props
}: AnimateHeightBoxProps<RC>) {
  const innerContainerRef = useRef<HTMLDivElement>(null);
  const { height } = useResizeObserver(innerContainerRef);
  const { base } = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const mergedSx = useMemo(
    () =>
      merge(
        {
          pos: "relative",
          w: "100%",
          h: "auto",
        },
        sx,
      ),
    [sx],
  );

  return (
    <Box
      {...props}
      testId={testId}
      className={`${className ?? ""} AnimateHeightBox`}
      sx={mergedSx}
      rc={cloneElement(rc, {
        ...(motionProfileToUse !== "none"
          ? {
              animate: { height: height || "auto" },
              transition: {
                duration: base.motion.normal[motionProfileToUse].jsDuration,
                ease: base.motion.normal[motionProfileToUse].jsEase,
              },
            }
          : {}),
      })}
    >
      <Box
        domRef={innerContainerRef}
        testId={`${testId}__innerContainer`}
        className="innerContainer"
        sx={innerContainerSx}
      >
        {children}
      </Box>
    </Box>
  );
}

AnimateHeightBox.displayName = "AnimateHeightBox";
