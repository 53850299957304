import type { ReactNode } from "react";

import type { StandardComponentWithProps } from "@/types";
import { SmartClone } from "../SmartClone";

export type PopoverTargetProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    children: ReactNode;
    id?: string;
  }
>;

export function PopoverTarget({ children, ...props }: PopoverTargetProps) {
  return <SmartClone {...props}>{children}</SmartClone>;
}

PopoverTarget.displayName = "Popover.Target";
