import { type MouseEvent, type ReactElement, useCallback } from "react";

import type { ButtConProps } from "@/types";
import { ButtCon } from "../Clickable";

export type CardButtConProps<RC extends ReactElement | undefined = undefined> =
  ButtConProps<RC>;

export function CardButtCon<RC extends ReactElement | undefined = undefined>({
  variant = "tertiary",
  size = "small",
  className,
  ...props
}: CardButtConProps<RC>) {
  const { onClick, ...otherProps } =
    "onClick" in props ? props : { ...props, onClick: null };
  const handleOnClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onClick === "function") {
        onClick(ev);
      }
    },
    [onClick],
  );
  return (
    <ButtCon
      {...otherProps}
      {...(onClick ? { onClick: handleOnClick } : {})}
      size={size}
      variant={variant}
      className={`${className ?? ""} CardButtCon`}
    />
  );
}

CardButtCon.displayName = "Card.ButtCon";
CardButtCon.SvgIcon = ButtCon.SvgIcon;
