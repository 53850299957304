import {
  Children,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { useGetCurrentSizeClass, useGetSubcomponentChildren } from "@/hooks";
import type {
  DomPropsWithDomRef,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";
import { isNumberWithinTotal, renderNullAndWarnUser } from "@/utils";
import { SmartClone } from "../SmartClone";
import { Stack } from "../Stack";
import { StepperStep } from "./StepperStep";
import {
  DEFAULT_STEPPER_SIZE,
  DEFAULT_STEPPER_VARIANT,
  STEPPER_SIZES,
  STEPPER_VARIANTS,
  type StepperSize,
  type StepperVariant,
} from "./shared";
import { baseStepperSx } from "./styles";

export type StepperDiscriminatedUnion<T> =
  | (T & {
      currentStep: number;
      onStepChange: (value: number) => void;
    })
  | (T & {
      defaultStep?: number;
      onStepChange?: (value: number) => void;
      currentStep?: never;
    });

export type StepperProps = StandardComponentWithProps<
  HTMLDivElement,
  StepperDiscriminatedUnion<{
    variant?: MakeResponsive<StepperVariant>;
    size?: MakeResponsive<StepperSize>;
    children?: ReactNode;
    disableFutureStepSelect?: boolean;
  }>
>;

export function Stepper<RC extends ReactElement | undefined = undefined>(
  props: RC extends undefined
    ? DomPropsWithDomRef<"div"> & StepperProps
    : StepperProps & { rc: RC },
) {
  const {
    testId = "Stepper",
    sx = {},
    currentStep,
    defaultStep = 1,
    onStepChange,
    children,
    variant = DEFAULT_STEPPER_VARIANT,
    size = DEFAULT_STEPPER_SIZE,
    disableFutureStepSelect = false,
    className,
    ...otherProps
  } = "currentStep" in props
    ? { ...props, defaultStep: undefined }
    : { ...props, currentStep: undefined };

  const steps = useGetSubcomponentChildren(children, StepperStep);
  const totalSteps = steps?.length || 0;
  const [uncontrolledStepIndex, setUncontrolledStepIndex] = useState<number>(
    typeof currentStep === "number"
      ? currentStep
      : isNumberWithinTotal(defaultStep, totalSteps)
        ? defaultStep
        : 1,
  );
  const activeStepIndex = currentStep ?? uncontrolledStepIndex;
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_STEPPER_SIZE,
    STEPPER_SIZES,
  );
  const variantClass = useGetCurrentSizeClass(
    variant,
    DEFAULT_STEPPER_VARIANT,
    STEPPER_VARIANTS,
  );
  const handleStepClick = useCallback(
    (newStep: number) => () => {
      if (typeof currentStep !== "number") {
        setUncontrolledStepIndex(newStep);
      }
      onStepChange?.(newStep);
    },
    [currentStep, onStepChange],
  );
  useEffect(() => {
    if (
      (typeof currentStep === "number" && currentStep <= 0) ||
      (typeof currentStep === "number" && currentStep > totalSteps)
    ) {
      renderNullAndWarnUser(
        "Stepper",
        `Stepper recieved an invalid currentStep property (${currentStep})`,
      );
    }
  }, [currentStep, totalSteps]);

  return (
    <Stack
      {...otherProps}
      testId={testId}
      className={`${
        className ?? ""
      } Stepper Stepper--${variantClass} Stepper--${sizeClass}`}
      sx={merge(baseStepperSx, sx)}
    >
      {Children.map(steps, (child, index) => {
        return (
          <SmartClone
            size={child?.props?.size || size}
            testId={`${testId}__step`}
            stepIndex={index + 1}
            activeStepIndex={activeStepIndex}
            handleStepClick={handleStepClick(index + 1)}
            totalSteps={totalSteps}
            variant={variant}
            disableFutureStepSelect={disableFutureStepSelect}
          >
            {child}
          </SmartClone>
        );
      })}
    </Stack>
  );
}

Stepper.displayName = "Stepper";
Stepper.Step = StepperStep;
