import type { DomPropsWithDomRef } from "@/types";
import type { ReactElement } from "react";
import {
  OnboardingPagination,
  type OnboardingPaginationProps,
} from "../OnboardingPagination";

type CarouselSimplePaginationProps = OnboardingPaginationProps & {};

export function CarouselSimplePagination<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  testId = "CarouselSimplePagination",
  className,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & CarouselSimplePaginationProps
  : CarouselSimplePaginationProps & { rc: RC }) {
  return (
    <OnboardingPagination
      {...props}
      size={size}
      testId={testId}
      className={`${className ?? ""} CarouselSimplePagination`}
    />
  );
}

CarouselSimplePagination.displayName = "CarouselSimple.Pagination";
