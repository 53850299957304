import {
  Children,
  type ReactElement,
  cloneElement,
  useContext,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { Box } from "@/components/Box";
import { useForwardLocalDomRef } from "@/hooks";
import type { BoxWithRCAndDomProps } from "@/types";
import { isChildSubcomponent, renderNullAndWarnUser } from "@/utils";

import { merge } from "ts-deepmerge";
import { TableRow } from "./TableRow";
import { TableContext } from "./context";

export type TableHeadProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC>;

export function TableHead<RC extends ReactElement | undefined = undefined>({
  children,
  rc = <thead />,
  className,
  testId: testIdProp,
  domRef = { current: null },
  sx = {},
  ...props
}: TableHeadProps<RC>) {
  const { testId, stickyHeaders } = useContext(TableContext);
  const localRef = useForwardLocalDomRef(domRef);
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...(stickyHeaders
            ? {
                position: "sticky",
                top: "0px",
                backdropFilter: "base.frost.100",
                zIndex: 1,
              }
            : {}),
        },
        sx,
      ),
    [stickyHeaders, sx],
  );

  return (
    <Box
      {...props}
      rc={rc}
      testId={`${testIdProp ?? testId}__thead`}
      className={`${className ?? ""} TableHead`}
      domRef={localRef}
      sx={mergedSx}
    >
      {Children.map(flattenedChildren, (child) => {
        if (isChildSubcomponent(child, TableRow)) {
          return cloneElement(child, { parentNode: "thead" });
        }

        return renderNullAndWarnUser(TableHead.displayName);
      })}
    </Box>
  );
}

TableHead.displayName = "Table.Head";
