import type { UnknownReactProps } from "@/types";
import { type ReactElement, type RefObject, useEffect } from "react";
import { useHover } from "./useHover";

export function useHoverCardVideoPlay(
  hoverContainerRef: RefObject<HTMLElement | null>,
  assetVideoChild: ReactElement<UnknownReactProps> | undefined,
  assetVideoRef: RefObject<HTMLVideoElement | null>,
) {
  const isHovering = useHover(hoverContainerRef);
  useEffect(() => {
    const hasAssetVideo = Boolean(assetVideoChild);

    if (!hasAssetVideo) return;

    const shouldPlayOnHover =
      hasAssetVideo && assetVideoChild?.props?.playOnCardHover;

    if (!shouldPlayOnHover) return;

    if (isHovering) {
      assetVideoRef?.current?.play();
    } else {
      assetVideoRef?.current?.pause();
    }
  }, [isHovering, assetVideoChild, assetVideoRef]);
}
