import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { AspectRatioImage } from "@/components/AspectRatioImage";
import type { AspectRatioImageProps } from "@/types";
import { isImageResizerProps } from "@/utils";

import { DEFAULT_CARD_HERO_ASPECT } from "./shared";
import { baseAssetImageSx } from "./styles";

export type CardHeroAssetImageProps<
  Use extends ReactElement | undefined = undefined,
> = AspectRatioImageProps<Use>;

export function CardHeroAssetImage<
  Use extends ReactElement | undefined = undefined,
>({
  sx = {},
  className,
  use,
  aspectRatio = DEFAULT_CARD_HERO_ASPECT,
  ...props
}: CardHeroAssetImageProps<Use>) {
  const {
    responsiveSizes = [
      128, 256, 450, 640, 860, 1024, 1440, 1920, 2048, 2560, 3072, 3456,
    ],
    relativeImageSizeInLayout,
    ...otherImageProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        relativeImageSizeInLayout: undefined,
        responsiveSizes: undefined,
      };
  const mergedSx = useMemo(() => merge(baseAssetImageSx, sx), [sx]);
  return (
    <AspectRatioImage
      {...otherImageProps}
      {...(use ? { use } : { responsiveSizes, relativeImageSizeInLayout })}
      aspectRatio={aspectRatio}
      className={`${className ?? ""} CardHeroAssetImage`}
      sx={mergedSx}
    />
  );
}

CardHeroAssetImage.displayName = "CardHero.AssetImage";
