import { ClassNames } from "@emotion/react";
import { type ReactElement, useCallback, useMemo, useState } from "react";
import { merge } from "ts-deepmerge";

import { useCheckForInvalidPaginationProps, useTheme } from "@/hooks";
import type { DomPropsWithDomRef } from "@/types";
import { isNumberWithinTotal } from "../../utils";
import { getStartingSize } from "../../utils/styleHelpers";
import { Box } from "../Box";
import { BaseClickable } from "../Clickable";
import {
  DEFAULT_PAGINATION_SIZE,
  getContainerStyles,
  getResponsiveContainerStyles,
  getResponsivePageButtonStyles,
  getSizeBasedPageButtonStyles,
  pageButtonBaseStyles,
} from "./styles";
import {
  ONBOARDING_PAGINATION_SIZES,
  type OnboardingPaginationProps,
} from "./types";

export function OnboardingPagination<
  RC extends ReactElement | undefined = undefined,
>(
  props: RC extends undefined
    ? DomPropsWithDomRef<"div"> & OnboardingPaginationProps
    : OnboardingPaginationProps & { rc: RC },
) {
  const {
    domRef,
    testId,
    sx = {},
    currentPage,
    defaultPage = 1,
    totalPages = 6,
    onPageChange,
    disabled = false,
    size = DEFAULT_PAGINATION_SIZE,
    ...domAttributes
  } = "currentPage" in props
    ? { ...props, defaultPage: undefined }
    : { ...props, currentPage: undefined };
  const errorState = useCheckForInvalidPaginationProps("OnboardingPagination", {
    defaultPage,
    totalPages,
    currentPage,
  });

  const [uncontrolledPageIndex, setUncontrolledPageIndex] = useState<number>(
    typeof currentPage === "number"
      ? currentPage
      : isNumberWithinTotal(defaultPage, totalPages)
        ? defaultPage
        : 1,
  );

  const handlePageClick = useCallback(
    (newPage: number) => () => {
      if (typeof currentPage !== "number") {
        setUncontrolledPageIndex(newPage);
      }
      onPageChange?.(newPage);
    },
    [onPageChange, currentPage],
  );

  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_PAGINATION_SIZE,
    ONBOARDING_PAGINATION_SIZES,
  );

  const allContainerStyles = useMemo(
    () =>
      merge(
        { display: "flex" },
        getContainerStyles({ size: startingSize, theme }),
        getResponsiveContainerStyles({
          size,
          theme,
        }),
        sx,
      ),
    [sx, size, theme, startingSize],
  );

  const allButtonStyles = useMemo(
    () =>
      merge(
        pageButtonBaseStyles,
        getSizeBasedPageButtonStyles({
          size: startingSize,
          theme,
        }),
        getResponsivePageButtonStyles({
          size,
          theme,
        }),
      ),
    [size, theme, startingSize],
  );

  const pageIndexToUse = currentPage ?? uncontrolledPageIndex;

  return errorState ? null : (
    <ClassNames>
      {({ cx }) => (
        <Box
          {...domAttributes}
          testId={testId}
          domRef={domRef}
          sx={allContainerStyles}
        >
          {Array.from({ length: totalPages }).map((_, index) => {
            const isActive = index + 1 === pageIndexToUse;
            const safeIndex = Number(index + 1).toString();
            return (
              <BaseClickable
                disabled={disabled}
                key={safeIndex}
                onClick={handlePageClick(index + 1)}
                className={cx({ selected: isActive })}
                sx={allButtonStyles}
                testId={`${testId}__page--${index + 1}`}
              />
            );
          })}
        </Box>
      )}
    </ClassNames>
  );
}

OnboardingPagination.displayName = "OnboardingPagination";
