import type { BiomeTheme } from "@/types";
import { hFlex } from "@/utils";

export const baseContainerSx = {
  ...hFlex,
  pos: "relative",
  overflow: "hidden",
  w: "100%",
};

export const baseDummyDisplaySx = {
  pos: "absolute",
  top: "0",
  left: "0",
  c: "red",
  opacity: "0",
  pointerEvents: "none",
  whiteSpace: "nowrap",
};

export const baseInputCss = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  textAlign: "center",
  background: "transparent",
  padding: "0px",
  border: "none",
  outline: "none",

  // @NOTE: this hides the up and down arrows on number inputs
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    display: "none",
  },

  // @NOTE: fix the autofill styles when dealing with things
  // like 1 password / chrome auto fill
  "&:-webkit-autofill": {
    backgroundClip: "text",
    textFillColor: ({ base }: BiomeTheme) => base.color.text.body.primary,
    // @NOTE: the below seems to disable webkit autofill bg color styles
    transition: "background-color 5000s ease-in-out 0s",
  },
};
