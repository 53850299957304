export const APP_HEADER_BAR_SIZES = ["small", "medium"] as const;
export const DEFAULT_APP_HEADER_BAR_SIZE = APP_HEADER_BAR_SIZES[1];

export const BUTTON_SIZES = ["small", "medium", "large"] as const;
export const DEFAULT_BUTTON_SIZE = BUTTON_SIZES[1];
export const DEFAULT_BUTTON_VARIANT = "primary" as const;

export const DEFAULT_LINK_SIZE = "medium";
export const DEFAULT_LINK_WEIGHT = "regular";

export const BUTTON_VARIANTS = [
  "primary",
  "primary/fatal",
  "primary/inverse",
  "primary/success",
  "secondary",
  "secondary/fatal",
  "tertiary",
  "tertiary/inverse",
] as const;

export const SHORTHAND_CSS_RULE_MAPPING = {
  c: "color",
  d: "display",
  f: "flex",
  o: "order",
  pos: "position",
  m: "margin",
  mt: "marginTop",
  mb: "marginBottom",
  ml: "marginLeft",
  mr: "marginRight",
  mx: "marginX",
  my: "marginY",
  marginX: "marginX",
  marginY: "marginY",
  p: "padding",
  pt: "paddingTop",
  pb: "paddingBottom",
  pr: "paddingRight",
  pl: "paddingLeft",
  px: "paddingX",
  py: "paddingY",
  paddingX: "paddingX",
  paddingY: "paddingY",
  w: "width",
  maxw: "maxWidth",
  minw: "minWidth",
  h: "height",
  minh: "minHeight",
  maxh: "maxHeight",
  bgc: "backgroundColor",
  bgi: "backgroundImage",
  bg: "background",
  b: "border",
  bl: "borderLeft",
  br: "borderRight",
  bt: "borderTop",
  bb: "borderBottom",
  brad: "borderRadius",
  bradtl: "borderTopLeftRadius",
  bradtr: "borderTopRightRadius",
  bradbl: "borderBottomLeftRadius",
  bradbr: "borderBottomRightRadius",
} as const;

export const DEFAULT_AWS_IMAGE_RESIZER_URL =
  "https://image-resizer-cache.dev.immutable.com";
export const DEFAULT_LOADING_IMG_ATTR = "lazy";

export const LOADING_OVERLAY_SIZES = ["small", "medium"] as const;
export const DEFAULT_LOADING_OVERLAY_SIZE = LOADING_OVERLAY_SIZES[1];

export const IMAGE_SIZE_VARIANTS = [
  "xxSmall",
  "xSmall",
  "small",
  "medium",
  "large",
  "xLarge",
] as const;
export const DEFAULT_IMAGE_SIZE_VARIANT = IMAGE_SIZE_VARIANTS[3];

export const DEFAULT_IMAGE_SIZE = 256 as const;

export const TOOLTIP_SIZES = ["small", "medium", "large"] as const;
export const DEFAULT_TOOLTIP_SIZE = TOOLTIP_SIZES[1];

export const DRAWER_POSITIONS = ["bottom", "right", "left"] as const;
export const DRAWER_SIZES = [
  "quarter",
  "half",
  "threeQuarter",
  "full",
  "edge",
] as const;
export const DEFAULT_DRAWER_SIZE = DRAWER_SIZES[2];
export const DEFAULT_DRAWER_POSITION = DRAWER_POSITIONS[0];

export const TEXT_AREA_SIZES = ["medium", "large"] as const;
export const DEFAULT_TEXT_AREA_SIZE = TEXT_AREA_SIZES[0];
export const DEFAULT_TEXT_AREA_ALIGN = "left";

export const MENU_ITEM_SIZES = ["xSmall", "small", "medium"] as const;
export const DEFAULT_MENU_ITEM_SIZE = MENU_ITEM_SIZES[2];

export const MENU_ITEM_SHIMMER_TYPES = ["regular", "withBottomSlot"] as const;
export const DEFAULT_MENU_ITEM_SHIMMER_TYPE = MENU_ITEM_SHIMMER_TYPES[0];

export const DEFAULT_TEXT_INPUT_SIZE = "medium" as const;
export const DEFAULT_TEXT_INPUT_ALIGN = "left" as const;
export const TEXT_INPUT_SIZES = ["medium", "large"] as const;
export const INPUT_MIN_WIDTH = "216px";

export const DEFAULT_SELECT_SIZE = DEFAULT_TEXT_INPUT_SIZE;
export const SELECT_SIZES = [...TEXT_INPUT_SIZES] as const;
export const CHIP_SIZES = [...TEXT_INPUT_SIZES] as const;
export const DEFAULT_CHIP_SIZE = CHIP_SIZES[0];

export const TOOLBAR_SIZES = ["small", "medium"] as const;
export const DEFAULT_TOOLBAR_SIZE = TOOLBAR_SIZES[1];

export const TIMELINE_VARIANTS = [
  "vertical/before",
  "vertical/split",
  "vertical/after",
  "horizontal/left/before",
  "horizontal/left/after",
  "horizontal/center/before",
  "horizontal/center/after",
  // @TODO: these modes will require additional work. :(
  // 'horizontal/left/split',
  // 'horizontal/center/split',
] as const;
export const DEFAULT_TIMELINE_VARIANT = TIMELINE_VARIANTS[2];
export const DUO_CON_OUTER_SHAPES = ["circle", "hexagon", "triangle"] as const;

export const PROGRESS_BAR_VARIANTS = ["determinate", "indeterminate"] as const;
export const PROGRESS_BAR_SIZES = ["small", "medium"] as const;
export const DEFAULT_PROGRESS_BAR_SIZE = PROGRESS_BAR_SIZES[1];
export const PROGRESS_BAR_COLOR_VARIANTS = [
  "guidance",
  "brand",
  "inverted",
  "gradient",
] as const;
export const DEFAULT_PROGRESS_BAR_COLOR_VARIANT =
  PROGRESS_BAR_COLOR_VARIANTS[0];

export const CARD_HERO_SIZES = ["small", "medium", "large"] as const;
export const DEFAULT_CARD_HERO_SIZE = CARD_HERO_SIZES[0];

export const TABLE_SPACING_SIZES = [
  "xxSmall",
  "xSmall",
  "small",
  "medium",
  "xLarge",
  "xxLarge",
] as const;
export const DEFAULT_TABLE_SPACING = "medium";

export const DUMMY_ALPHA_RASTER_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/demo-images/oxellus.webp";
export const DUMMY_RASTER_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/demo-images/frog.png";
export const DUMMY_RASTER_IMAGE_2_URL =
  "https://biome.immutable.com/hosted-assets/demo-images/tree-dude.jpg";
export const DUMMY_RASTER_IMAGE_3_URL =
  "https://biome.immutable.com/hosted-assets/demo-images/pastelle-gradients.png";
export const DUMMY_VECTOR_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--eth.svg";
export const IMX_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--imx.svg";
export const GODS_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--gods.svg";
export const ETH_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--eth.svg";
export const GOG_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--gog.svg";
export const USDC_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/currency-icons/currency--usdc.svg";
export const ON_LIGHT_FALLBACK_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/images/defaultTokenImage--onLight.svg";
export const ON_DARK_FALLBACK_TOKEN_IMAGE_URL =
  "https://biome.immutable.com/hosted-assets/images/defaultTokenImage--onDark.svg";

export const DUMMY_RIVE_ANIMATION_URL =
  "https://cdn.rive.app/animations/vehicles.riv";

export const DUMMY_HLS_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/Big_Buck_Bunny_HLS/bbb.m3u8";
export const DUMMY_BAD_410_HLS_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/bad-hls-video.m3u8";
export const DUMMY_SMALL_MP4_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/Big_Buck_Bunny_360_10s.mp4";
export const DUMMY_SMALL_WEBM_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/Big_Buck_Bunny_360_10s.webm";
export const DUMMY_LARGE_MP4_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/Big_Buck_Bunny_1080_30s.mp4";
export const DUMMY_LARGE_WEBM_VIDEO_URL =
  "https://biome.immutable.com/hosted-assets/video/Big_Buck_Bunny_1080_30s.webm";

export const RATING_SIZES = ["small", "medium", "large", "xLarge"] as const;
export const DEFAULT_RATING_SIZE = RATING_SIZES[1];

export const DEFAULT_HEADING_PROPS = {
  size: "medium",
  weight: "bold",
  color: "base.color.text.heading.primary",
  rc: <h1 />,
} as const;

export const DEFAULT_BODY_PROPS = {
  size: "medium",
  weight: "regular",
  color: "base.color.text.body.primary",
  rc: <span />,
} as const;

export const DEFAULT_CAPTION_PROPS = {
  size: "medium",
  weight: "regular",
  color: "base.color.text.caption.primary",
  rc: <span />,
} as const;

export const DUMMY_WIDTH_PUSHER_TEXT =
  "Its crazy how long this line of text is. It's a very long text which shouldnt fit on the screen in a single line. This way, it will always push out to fill the entire available width of any flex containers which contain it ... how now brown cow? the quick brown fox jumps over the lazy dog";

export const CAROUSEL_THUMB_VARIANTS = ["horizontal", "vertical"] as const;
export const DEFAULT_CAROUSEL_THUMB_VARIANT = CAROUSEL_THUMB_VARIANTS[0];

export const MOTION_PROFILES = ["gentle", "fast", "none"] as const;
export const DEFAULT_MOTION_PROFILE = "gentle" as const;

export const VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/webm",
  "application/vnd.apple.mpegurl",
] as const;
