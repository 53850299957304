import {
  ON_DARK_DEFAULT_IMAGE,
  ON_LIGHT_DEFAULT_IMAGE,
} from "@/components/CloudImage/shared";
import { useTheme } from "./useTheme";

export function usePickPlaceholderImage(defaultImageUrl?: string) {
  const theme = useTheme();
  return (
    defaultImageUrl ||
    (theme.base.colorMode === "onDark"
      ? ON_DARK_DEFAULT_IMAGE
      : ON_LIGHT_DEFAULT_IMAGE)
  );
}
