import { type ReactElement, type ReactNode, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { DEFAULT_IMAGE_SIZE_VARIANT, IMAGE_SIZE_VARIANTS } from "@/constants";
import {
  useGetCurrentSizeClass,
  useGetSubcomponentChild,
  useTheme,
} from "@/hooks";
import type {
  DomPropsWithDomRef,
  FramedComponentBaseProps,
  ImageSizeVariant,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  type SwapStackImageProps,
  SwapStackPrimaryImage,
  SwapStackSecondaryImage,
} from "./SwapStackImages";
import {
  getContainerSx,
  getResponsiveContainerSx,
  swapContainerImageSx,
  swapContainerSx,
} from "./styles";

export type SwapStackBaseProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    /**
     * @deprecated The `primaryImageUrl` prop will be deprecated in the future.
     * Please use the `<SwapStack.PrimaryImage />` subcomponent instead.
     */
    primaryImageUrl?: string;
    /**
     * @deprecated The `secondaryImageUrl` prop will be deprecated in the future.
     * Please use the `<SwapStack.SecondaryImage />` subcomponent instead.
     */
    secondaryImageUrl?: string;
    direction?: "left" | "right";
    size?: MakeResponsive<ImageSizeVariant>;
    children?: ReactNode;

    // @NOTE: due to it's use of masks internally, the emphasized prop
    // is not currently supported for the SwapStack component
  } & Omit<FramedComponentBaseProps, "emphasized">
>;

export type SwapStackProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"div"> & SwapStackBaseProps
    : SwapStackBaseProps & { rc: RC };

export function SwapStack<RC extends ReactElement | undefined = undefined>({
  primaryImageUrl,
  secondaryImageUrl,
  circularFrame,
  padded = false,
  direction = "left",
  testId = "SwapStack",
  className,
  children,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  sx = {},
  ...props
}: SwapStackProps<RC>) {
  const startingSize = getStartingSize(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );
  const theme = useTheme();
  const containerSx = useMemo(
    () =>
      merge(
        swapContainerSx,
        {
          pos: "relative",
        },
        getContainerSx({ theme, size: startingSize }),
        getResponsiveContainerSx({ theme, size }),
        sx,
      ),
    [size, startingSize, sx, theme],
  );

  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_IMAGE_SIZE_VARIANT,
    IMAGE_SIZE_VARIANTS,
  );

  const leftImage = useGetSubcomponentChild(children, SwapStackPrimaryImage);
  const rightImage = useGetSubcomponentChild(children, SwapStackSecondaryImage);

  const leftImageWithFallback = useMemo(() => {
    if (leftImage) {
      return leftImage;
    }
    return primaryImageUrl ? (
      <SwapStackPrimaryImage imageUrl={primaryImageUrl} />
    ) : null;
  }, [leftImage, primaryImageUrl]);

  const rightImageWithFallback = useMemo(() => {
    if (rightImage) {
      return rightImage;
    }

    // @NOTE: if no image is supplied for the right image, we will simply use the left image
    const rightImageUrl =
      secondaryImageUrl ??
      primaryImageUrl ??
      (leftImageWithFallback?.props as SwapStackImageProps<undefined>)
        ?.imageUrl;
    const rightImageUseProp = leftImageWithFallback?.props?.use;
    return rightImageUseProp ? (
      <SwapStackSecondaryImage use={rightImageUseProp} />
    ) : (
      <SwapStackSecondaryImage imageUrl={rightImageUrl} />
    );
  }, [rightImage, primaryImageUrl, secondaryImageUrl, leftImageWithFallback]);

  const leftImageSx = useMemo(
    () =>
      merge(
        swapContainerImageSx,
        {
          top: 0,
          left: 0,
        },
        leftImageWithFallback?.props?.sx ?? {},
      ),
    [leftImageWithFallback?.props?.sx],
  );
  const rightImageSx = useMemo(
    () =>
      merge(
        swapContainerImageSx,
        {
          bottom: 0,
          right: 0,
        },
        rightImageWithFallback?.props?.sx ?? {},
      ),
    [rightImageWithFallback?.props?.sx],
  );

  const commonProps = useMemo(
    () => ({
      circularFrame,
      padded,
      testId,
      size,
    }),
    [circularFrame, testId, size, padded],
  );
  const leftImageProps = useMemo(
    () => ({
      ...commonProps,
      use: leftImageWithFallback?.props?.use || undefined,
      sx: leftImageSx,
    }),
    [commonProps, leftImageWithFallback, leftImageSx],
  );
  const rightImageProps = useMemo(
    () => ({
      ...commonProps,
      use: rightImageWithFallback?.props?.use || undefined,
      sx: rightImageSx,
    }),
    [commonProps, rightImageWithFallback, rightImageSx],
  );

  return (
    <Box
      {...props}
      testId={testId}
      className={`${
        className ?? ""
      } SwapStack SwapStack--${sizeClass} SwapStack--${direction} SwapStack--${
        circularFrame ? "circle" : "square"
      }`}
      sx={containerSx}
    >
      <SmartClone {...leftImageProps}>{leftImageWithFallback}</SmartClone>
      <SmartClone {...rightImageProps}>{rightImageWithFallback}</SmartClone>
    </Box>
  );
}

SwapStack.displayName = "SwapStack";
SwapStack.PrimaryImage = SwapStackPrimaryImage;
SwapStack.SecondaryImage = SwapStackSecondaryImage;
