import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import {
  DEFAULT_LOADING_OVERLAY_SIZE,
  LOADING_OVERLAY_SIZES,
} from "@/constants";
import { useTheme } from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  LoadingOverlaySize,
  MakeResponsive,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { Box } from "../Box";
import { getAnimationBoxSx, getResponsiveAnimationBoxSx } from "./style";

export type LoadingOverlayContentAnimationBoxProps<
  RC extends ReactElement | undefined,
> = BoxWithRCAndDomProps<RC> & { size?: MakeResponsive<LoadingOverlaySize> };

export function LoadingOverlayContentRiveBox<
  RC extends ReactElement | undefined,
>({
  sx = {},
  size = DEFAULT_LOADING_OVERLAY_SIZE,
  rc,
  ...props
}: LoadingOverlayContentAnimationBoxProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_LOADING_OVERLAY_SIZE,
    LOADING_OVERLAY_SIZES,
  );

  const animationBoxSx = merge(
    { flexShrink: 0 },
    getAnimationBoxSx({ size: startingSize, theme }),
    getResponsiveAnimationBoxSx({ size, theme }),
    sx,
  );

  return (
    <Box {...props} sx={animationBoxSx}>
      {rc}
    </Box>
  );
}

LoadingOverlayContentRiveBox.displayName = "LoadingOverlay.Content.RiveBox";
