import type { ReactElement } from "react";

import type { DuoConProps } from "@/types";

import { DuoCon } from "../DuoCon";

export function ConfirmationDialogContentDuoCon<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: DuoConProps<RC>) {
  return <DuoCon {...props} rc={rc} />;
}

ConfirmationDialogContentDuoCon.displayName =
  "ConfirmationDialog.Content.DuoCon";
