import type { DeeplyNestedSx } from "@/types";
import { hFlex } from "@/utils";

export const baseTimelineSx = {
  width: "100%",
  gap: "0px",

  "&.Timeline--horizontal": {
    flexDirection: "row",
  },

  "&.Timeline--vertical": {
    flexDirection: "column",
  },

  "&.Timeline--vertical_after": {
    pl: "base.spacing.x1",
  },
  "&.Timeline--vertical_before": {
    pr: "base.spacing.x1",
  },

  "&.Timeline--horizontal_center_before": {
    pb: "base.spacing.x1",
  },
  "&.Timeline--horizontal_center_after": {
    pt: "base.spacing.x1",
  },
};

export const verticalTimelineStepSx: DeeplyNestedSx = {
  "&.TimelineStep--vertical": {
    py: "base.spacing.x4",

    "&.TimelineStep--lastStep::after": {
      content: "unset",
    },

    "&.TimelineStep--vertical_before": {
      pr: "base.spacing.x10",
      alignItems: "flex-end",
      gap: "base.spacing.x1",

      "&::before": {
        top: "0",
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        right: "base.spacing.x2",
      },

      "&::after": {
        top: ({ base }) => `calc(50% + ${base.icon.size[200]})`,
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        right: "base.spacing.x2",
      },

      "& .TimelineStep__dot": {
        pos: "absolute",
        top: "50%",
        right: "0px",
        translate: "0 -50%",
      },

      "& .TimelineStep__label, & .TimelineStep__caption": {
        textAlign: "right",
      },

      "& .TimelineStep__caption--empty": {
        display: "none",
      },

      "&.TimelineStep--hasCustomDot": {
        pr: ({ base }) => `calc(${base.icon.size[500]} + ${base.spacing.x5})`,

        "&::before": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
          right: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },

        "&::after": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
          top: "unset",
          bottom: "0px",
          right: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },
      },
    },

    "&.TimelineStep--vertical_after": {
      pl: "base.spacing.x10",
      gap: "base.spacing.x1",

      "&::before": {
        top: "0",
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        left: "base.spacing.x2",
      },

      "&::after": {
        top: ({ base }) => `calc(50% + ${base.icon.size[200]})`,
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        left: "base.spacing.x2",
      },

      "& .TimelineStep__dot": {
        pos: "absolute",
        top: "50%",
        left: "0px",
        translate: "0 -50%",
      },

      "& .TimelineStep__caption--empty": {
        display: "none",
      },

      "&.TimelineStep--hasCustomDot": {
        pl: ({ base }) => `calc(${base.icon.size[500]} + ${base.spacing.x5})`,

        "&::before": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
          left: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },

        "&::after": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
          top: "unset",
          bottom: "0px",
          left: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },
      },
    },

    "&.TimelineStep--vertical_split": {
      flexDirection: "row",
      alignItems: "center",
      minw: "200px",

      "&::before": {
        top: "0",
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        left: "50%",
        translate: "-50% 0",
      },

      "&::after": {
        top: ({ base }) => `calc(50% + ${base.icon.size[200]})`,
        h: ({ base }) => `calc(50% - ${base.icon.size[200]})`,
        w: "base.border.size.100",
        left: "50%",
        translate: "-50% 0",
      },

      "& .TimelineStep__dot": {
        pos: "absolute",
        left: "50%",
        top: "50%",
        translate: "-50% -50%",
      },

      "& .TimelineStep__label, & .TimelineStep__caption": {
        w: "50%",
      },

      "& .TimelineStep__label": {
        textAlign: "right",
        pr: "base.spacing.x6",
      },

      "& .TimelineStep__caption": {
        minh: "1px",
        textAlign: "left",
        pl: "base.spacing.x6",
      },

      "&.TimelineStep--hasCustomDot": {
        minw: "220px",
        minh: "80px",

        "&::before": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
        },

        "&::after": {
          h: ({ base }) =>
            `calc(50% - (${base.icon.size[500]} * 0.5) - ${base.spacing.x1})`,
          top: "unset",
          bottom: "0px",
        },

        "& .TimelineStep__label": {
          pr: ({ base }) =>
            `calc((${base.icon.size[500]} * 0.5) + ${base.spacing.x5})`,
        },
        "& .TimelineStep__caption": {
          pl: ({ base }) =>
            `calc((${base.icon.size[500]} * 0.5) + ${base.spacing.x5})`,
        },
      },
    },

    "&.TimelineStep::after, &.TimelineStep:not(.TimelineStep--firstStep)::before":
      {
        bg: "base.color.translucent.emphasis.200",
      },

    "&.TimelineStep--complete": {
      "&::after": {
        bg: "base.color.text.body.primary",
      },
      "& + .TimelineStep::before": {
        bg: "base.color.text.body.primary",
      },
    },
  },
};

export const horizontalTimelineStepSx: DeeplyNestedSx = {
  "&.TimelineStep--horizontal": {
    justifyContent: "flex-start",

    "&::after": {
      left: ({ base }) => `calc(${base.icon.size[200]} + ${base.spacing.x2})`,
      w: ({ base }) =>
        `calc(100% - (${base.icon.size[200]} + ${base.spacing.x4}))`,
      h: "base.border.size.100",
      bg: "base.color.translucent.standard.500",
    },

    "&.TimelineStep--lastStep::after": {
      content: "unset",
    },

    "&.TimelineStep--complete::after": {
      bg: "base.color.translucent.standard.1000",
    },

    "&.TimelineStep--horizontal_left_after": {
      "&::after": {
        top: "base.spacing.x2",
      },

      "& .TimelineStep__label": {
        mt: "base.spacing.x2",
        mb: "base.spacing.x1",
      },

      "&:not(.TimelineStep--lastStep)": {
        pr: "base.spacing.x4",
      },

      "&.TimelineStep--hasCustomDot": {
        "&::after": {
          w: ({ base }) =>
            `calc(100% - ${base.icon.size[500]} - ${base.spacing.x4})`,
          top: "base.spacing.x6",
          left: "base.spacing.x14",
        },
      },
    },

    "&.TimelineStep--horizontal_left_before": {
      justifyContent: "flex-end",

      "&:not(.TimelineStep--lastStep)": {
        pr: "base.spacing.x4",
      },

      "&::after": {
        bottom: "base.spacing.x2",
      },

      "& .TimelineStep__caption:not(.TimelineStep__caption--empty)": {
        mt: "base.spacing.x1",
      },

      "& .TimelineStep__dot": {
        order: 2,
        mt: "base.spacing.x2",
      },

      "&.TimelineStep--hasCustomDot": {
        "&::after": {
          w: ({ base }) =>
            `calc(100% - ${base.icon.size[500]} - ${base.spacing.x4})`,
          left: ({ base }) =>
            `calc(${base.icon.size[500]} + ${base.spacing.x2})`,
          bottom: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },
      },
    },

    "&.TimelineStep--horizontal_center_before": {
      justifyContent: "flex-end",
      alignItems: "center",

      "&:not(.TimelineStep--lastStep)": {
        pr: "base.spacing.x4",
      },

      "&::after": {
        w: ({ base }) =>
          `calc(100% - ${base.icon.size[200]} - ${base.spacing.x4})`,
        left: ({ base }) => `calc(50% + ${base.icon.size[200]} * 0.5)`,
        bottom: "base.spacing.x2",
      },

      "& .TimelineStep__caption:not(.TimelineStep__caption--empty)": {
        mt: "base.spacing.x1",
      },

      "& .TimelineStep__label, & .TimelineStep__caption": {
        textAlign: "center",
      },

      "& .TimelineStep__dot": {
        order: 2,
        mt: "base.spacing.x2",
      },

      "&.TimelineStep--hasCustomDot": {
        "&::after": {
          w: ({ base }) =>
            `calc(100% - ${base.icon.size[500]} - ${base.spacing.x4})`,
          left: ({ base }) => `calc(50% + (${base.icon.size[500]} * 0.5))`,
          bottom: ({ base }) => `calc(${base.icon.size[500]} * 0.5)`,
        },
      },
    },

    "&.TimelineStep--horizontal_center_after": {
      alignItems: "center",

      "&::after": {
        w: ({ base }) =>
          `calc(100% - ${base.icon.size[200]} - ${base.spacing.x4})`,
        left: ({ base }) =>
          `calc(50% + (${base.icon.size[200]} - ${base.spacing.x2}))`,
        top: "base.spacing.x2",
      },

      "&:not(.TimelineStep--lastStep)": {
        pr: "base.spacing.x4",
      },

      "& .TimelineStep__label, & .TimelineStep__caption": {
        textAlign: "center",
      },

      "& .TimelineStep__label": {
        mt: "base.spacing.x2",
        mb: "base.spacing.x1",
      },

      "&.TimelineStep--hasCustomDot": {
        "&::after": {
          w: ({ base }) =>
            `calc(100% - ${base.icon.size[500]} - ${base.spacing.x4})`,
          left: ({ base }) => `calc(50% + (${base.icon.size[500]} * 0.5))`,
          top: "base.spacing.x6",
        },
      },
    },
  },
};

export const baseTimelineStepSx: DeeplyNestedSx = {
  d: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "0px",
  flex: 1,
  textAlign: "left",
  pos: "relative",

  "&::after, &::before": {
    content: '""',
    pos: "absolute",
  },

  "&.TimelineStep--complete": {
    "& .TimelineStep__dot::after": {
      boxShadow: ({ base }) =>
        `inset 0 0 0 ${base.border.size[200]} ${base.color.translucent.standard[1000]}`,
    },
    "&.TimelineStep--hasCustomDot": {
      "& .TimelineStep__dot": {
        borderColor: "base.color.translucent.standard.1000",

        "&::after": {
          content: "unset",
        },
      },
    },
  },

  "&.TimelineStep--active": {
    "& .TimelineStep__dot": {
      borderColor: "base.color.translucent.standard.1000",
    },
  },

  "&.TimelineStep--future": {
    "& .TimelineStep__dot": {
      borderColor: "base.color.translucent.standard.400",
    },
    "&.TimelineStep--hasCustomDot": {
      "& .TimelineStep__dot": {
        borderColor: "base.color.translucent.standard.1000",
        w: "base.icon.size.500",
        h: "base.icon.size.500",

        "&::after": {
          boxShadow: "none",
        },
      },
    },
  },

  ...verticalTimelineStepSx,
  ...horizontalTimelineStepSx,
};

export const baseDotSx = {
  w: "base.icon.size.200",
  h: "base.icon.size.200",
  brad: "50%",
  pos: "relative",
  border: "solid",
  borderWidth: "base.border.size.200",
  borderColor: "transparent",

  "&::after": {
    content: '""',
    pos: "absolute",
    left: "50%",
    top: "50%",
    brad: "50%",
    translate: "-50% -50%",
    w: "base.icon.size.300",
    h: "base.icon.size.300",
  },

  "&.TimelineStep__dot--loading": {
    border: "unset",
    w: "base.icon.size.250",
    h: "base.icon.size.250",

    "& > .Icon": {
      width: "100%",
    },
  },
};

export const captionSx = {
  ...hFlex,
  alignItems: "center",
  gap: "base.spacing.x1",
  c: "base.color.text.body.secondary",
};
export const labelSx = {
  c: "base.color.text.body.primary",
};

export const completeDuoConSx = {
  w: "base.icon.size.200",
};

export const customDotSx = {
  w: "base.icon.size.500",
  h: "base.icon.size.500",
  pos: "relative",
};
export const customDotDuoConSx = {
  w: "44px",
};
export const customDotDuoConLoadingSx = {
  pos: "absolute",
  top: "50%",
  left: "50%",
  w: "112%",
  translate: "-50% -50%",
  fill: "base.color.fixed.black.1000",
};
