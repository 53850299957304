import { keyframes } from "@emotion/react";

import { centerFlexChildren, hFlex } from "@/utils";

import type { BadgeVariant } from "./shared";

const dotPulseAnimation = keyframes`
  0% {
    scale: 1;
    opacity: 0.8;  
  }
  100% {
    scale: 2.8;
    opacity: 0
  }
`;

const contentPulseAnimation = keyframes`
  0% {
    scale: 1;
    opacity: 0.8;  
  }
  100% {
    scale: 1.5 1.9;
    opacity: 0
  }
`;

export function getBadgeAnimationSx(isAnimated: boolean, hasContent: boolean) {
  return isAnimated
    ? {
        "&:before": {
          animation: `${
            hasContent ? contentPulseAnimation : dotPulseAnimation
          } infinite`,
          animationDuration: "1.3s",
          animationTimingFunction: "base.motion.normal.gentle.cssEase",
        },
      }
    : {};
}

export const baseBadgeSx = {
  pos: "relative",
  flexShrink: "0",
  d: "flex",
  alignItems: "center",
  justifyContent: "center",
  px: "base.spacing.x2",
  py: "2px",
  brad: "base.spacing.x6",
  whiteSpace: "nowrap",
  transitionProperty: "background, color",

  "&:before": {
    content: "''",
    pos: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    brad: "base.spacing.x6",
    scale: "1",
    transitionProperty: "background",
  },
};

export const plainDotBadgeSx = {
  p: "0px",
  w: "base.spacing.x2",
  h: "base.spacing.x2",
};

export const getBadgeVariantSx = (variant: BadgeVariant) => {
  switch (true) {
    case variant === "emphasis":
      return {
        bg: "base.gradient.2",
        "&:before": {
          bg: "base.gradient.2",
        },
      };

    case variant === "dark":
      return {
        bg: "base.color.fixed.black.1000",
        "&:before": {
          bg: "base.color.fixed.black.1000",
        },
      };

    case variant === "light":
      return {
        bg: "base.color.fixed.white.1000",
        "&:before": {
          bg: "base.color.fixed.white.1000",
        },
      };

    case /guidance|success|attention|fatal/.test(variant):
    default:
      return {
        bg: `base.color.status.${variant}.bright`,

        "&:before": {
          bg: `base.color.status.${variant}.bright`,
        },
      };
  }
};

export const textSx = {
  pos: "relative",
  ...hFlex,
  ...centerFlexChildren,
  gap: "3px",

  "& .Icon, & .SvgIcon": {
    fill: "inherit",
  },
  "& .Body": {
    c: "inherit",
  },
};
