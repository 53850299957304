import { ClassNames } from "@emotion/react";
import type { MouseEventHandler } from "react";
import { merge } from "ts-deepmerge";

import { useGetCurrentSizeClass, useTheme } from "@/hooks";
import type { BaseClickableWithRCAndDomProps, MakeResponsive } from "@/types";
import { renderNullAndWarnUser } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";

import { BaseClickable } from "../Clickable";
import { DuoCon } from "../DuoCon";
import { Stack } from "../Stack";
import {
  DEFAULT_STEPPER_SIZE,
  DEFAULT_STEPPER_VARIANT,
  STEPPER_SIZES,
  STEPPER_VARIANTS,
  type StepperSize,
  type StepperVariant,
} from "./shared";
import {
  baseStepBadgeSx,
  baseStepSx,
  getResponsiveStepBadgeSx,
  getResponsiveStepSx,
  getStepBadgeStateSx,
  getStepBadgeSx,
  getStepSx,
} from "./styles";

export type StepperStepProps = Omit<
  BaseClickableWithRCAndDomProps<undefined>,
  "size" | "rc"
> & {
  size?: MakeResponsive<StepperSize>;
  stepIndex?: number;
  activeStepIndex?: number;
  handleStepClick?: MouseEventHandler;
  totalSteps?: number;
  variant?: StepperVariant;
  disableFutureStepSelect?: boolean;
};

export function StepperStep({
  children,
  className,
  size = DEFAULT_STEPPER_SIZE,
  stepIndex,
  activeStepIndex,
  sx = {},
  handleStepClick,
  variant = DEFAULT_STEPPER_VARIANT,
  totalSteps,
  testId,
  disableFutureStepSelect,
  ...props
}: StepperStepProps) {
  const theme = useTheme();
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_STEPPER_SIZE,
    STEPPER_SIZES,
  );
  const variantClass = useGetCurrentSizeClass(
    variant,
    DEFAULT_STEPPER_VARIANT,
    STEPPER_VARIANTS,
  );
  // @NOTE: early exit, render nothing, when these values are not valid:
  if (typeof stepIndex !== "number")
    return renderNullAndWarnUser(
      "Stepper",
      `Stepper recieved an invalid stepIndex property (${stepIndex})`,
    );
  if (typeof activeStepIndex !== "number")
    return renderNullAndWarnUser(
      "Stepper",
      `Stepper recieved an invalid activeStepIndex property (${activeStepIndex})`,
    );
  if (typeof totalSteps !== "number")
    return renderNullAndWarnUser(
      "Stepper",
      `Stepper recieved an invalid totalSteps property (${totalSteps})`,
    );

  const startingSize = getStartingSize(
    size,
    DEFAULT_STEPPER_SIZE,
    STEPPER_SIZES,
  );
  const stepSx = merge(
    baseStepSx,
    getStepSx({ size: startingSize, theme }),
    getResponsiveStepSx({ size, theme }),
    sx,
  );
  const stepBadgeSx = merge(
    baseStepBadgeSx,
    getStepBadgeStateSx({ stepIndex, activeStepIndex }),
    getStepBadgeSx({ size: startingSize, theme }),
    getResponsiveStepBadgeSx({ size, theme }),
  );
  const stepDuoConSx = merge(
    baseStepBadgeSx,
    getStepBadgeSx({ size: startingSize, theme }),
    getResponsiveStepBadgeSx({ size, theme }),
  );
  const isCompleteStep = stepIndex < activeStepIndex;
  const isActiveStep = stepIndex === activeStepIndex;
  const isFutureStep = stepIndex > activeStepIndex;
  const isLastStep = stepIndex === totalSteps;

  return (
    <ClassNames>
      {({ cx }) => (
        <BaseClickable
          {...props}
          testId={testId}
          className={cx(
            className,
            "StepperStep",
            `StepperStep--${variantClass}`,
            `StepperStep--${sizeClass}`,
            {
              "StepperStep--active": isActiveStep,
              "StepperStep--complete": isCompleteStep,
              "StepperStep--future": isFutureStep,
              "StepperStep--lastStep": isLastStep,
            },
          )}
          sx={stepSx}
          onClick={handleStepClick}
          disabled={isFutureStep && disableFutureStepSelect}
        >
          {isCompleteStep ? (
            <DuoCon
              icon="Tick"
              colorVariant="success"
              className="StepperStep__duoCon"
              iconVariant="bold"
              variant="bold"
              sx={stepDuoConSx}
              testId={`${testId}__duoCon`}
            />
          ) : (
            <Stack
              sx={stepBadgeSx}
              rc={<span />}
              testId={`${testId}__badge`}
              className="StepperStep__badge"
              alignItems="center"
              justifyContent="center"
            >
              {stepIndex}
            </Stack>
          )}

          {children}
        </BaseClickable>
      )}
    </ClassNames>
  );
}

StepperStep.displayName = "Stepper.Step";
