import type { Breakpoints } from "../types";

export const breakpoint: Breakpoints = {
  small: 450,
  medium: 768,
  large: 1024,
  xLarge: 1280,
  xxLarge: 1536,
  xxxLarge: 1920,
} as const;
