import type { ReactElement } from "react";

import type { BoxWithRCAndDomProps } from "@/types";
import { Box } from "../Box";

export type TooltipContentProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & { id?: string };

export function TooltipContent<
  RC extends ReactElement | undefined = undefined,
>({ children, ...props }: TooltipContentProps<RC>) {
  return <Box {...props}>{children}</Box>;
}

TooltipContent.displayName = "Tooltip.Content";
