export const baseTextComponentSx = {
  transitionProperty: "color, font-size, font-weight, line-height",
  // @NOTE: We set this to get Link components to inherit the
  // font size of the parent Body component, when neccissary
  "& > .Link": {
    "& .Link__innerContainer": {
      "&:not(.Link__innerContainer__text--explicitWeight)": {
        fontWeight: "inherit",
      },
      "&:not(.Link__innerContainer__text--explicitSize)": {
        fontSize: "inherit",
        fontFamily: "inherit",
        lineHeight: "inherit",
        textTransform: "inherit",
      },
    },
  },
};
