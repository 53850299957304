import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { DomPropsWithDomRef } from "@/types";

import { Divider, type DividerProps } from "../Divider";

export type SpineMenuDividerProps = DividerProps;

export function SpineMenuDivider<
  RC extends ReactElement | undefined = undefined,
>({
  size = "xSmall",
  sx = {},
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & SpineMenuDividerProps
  : SpineMenuDividerProps & { rc: RC }) {
  return (
    <Divider {...props} size={size} sx={merge({ my: "base.spacing.x2" }, sx)} />
  );
}

SpineMenuDivider.displayName = "SpineMenuDivider.Divider";
