import { keyframes } from "@emotion/react";
import type { Properties } from "csstype";

import type { BiomeTheme, MakeResponsive, MotionProfile } from "@/types";
import { getResponsiveSx } from "@/utils/styleHelpers";

import type {
  ProgressBarColorVariant,
  ProgressBarSize,
  ProgressBarVariant,
} from "./shared";

export function getContainerColorVariantSx(variant: ProgressBarColorVariant) {
  switch (variant) {
    case "brand":
      return {
        "& .barContainer__bar": {
          bg: "base.color.brand.1",
        },
      };

    case "gradient":
      return {
        "& .barContainer__bar": {
          bg: "base.gradient.1",
        },
      };

    case "inverted":
      return {
        "& .barContainer__bar": {
          bg: "base.color.translucent.inverse.1000",
        },
        "& .ProgressBarCaption, & .ProgressBarLabel .RollingText__textContainer":
          {
            c: "base.color.text.body.inverse.primary",
          },
      };

    case "guidance":
    default:
      return {
        "& .barContainer__bar": {
          bg: "base.color.status.guidance.bright",
        },
      };
  }
}

export const barContainerBaseSx = {
  overflow: "hidden",
  brad: "base.borderRadius.x4",
  minw: "100px",
};

export function getBarContainerSx({
  size,
  theme: { base },
}: {
  size: ProgressBarSize;
  theme: BiomeTheme;
}): Properties {
  switch (size) {
    case "small":
      return {
        height: base.spacing.x1,
      };

    case "medium":
    default:
      return {
        height: base.spacing.x2,
      };
  }
}

export const getResponsiveBarContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ProgressBarSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getBarContainerSx,
  });

export const baseBarSx = {
  h: "100%",
};

const wipeMask = keyframes`
  0% {
    translate: -95% 0;
  }
  100% {
    translate: 97% 0;
  }
`;

export function getVariantBasedBarSx({
  motionProfile,
  variant,
  disableBarTransition,
  value,
}: {
  variant: ProgressBarVariant;
  motionProfile: MotionProfile;
  disableBarTransition: boolean;
  value?: number;
}) {
  switch (variant) {
    case "determinate":
      return {
        w: `${value}%`,
        transitionProperty: "width",
        ...(!disableBarTransition || motionProfile !== "none"
          ? {
              transitionDuration: `base.motion.normal.${motionProfile}.cssDuration`,
              transitionTimingFunction: `base.motion.normal.${motionProfile}.cssEase`,
            }
          : {}),
      };

    case "indeterminate":
    default:
      return {
        WebkitMaskImage:
          "linear-gradient(to right, transparent 0%, black 50%, transparent 100%)",
        animation: `${wipeMask} ${
          motionProfile === "gentle" ? "1.7s" : "0.8s"
        } infinite`,
        animationTimingFunction: "linear",
      };
  }
}

export const leftRollingTextBaseSx = {
  minw: "50%",
};
