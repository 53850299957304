import { useCallback, useEffect, useState } from "react";

import { usePassportProvider } from "@/context";
import { notifyError } from "@/utils/monitoring";

export function useAccessToken(): () => Promise<string | undefined> {
  const { logout, passportClient } = usePassportProvider();

  const getAccessToken = useCallback(async () => {
    let retryCount = 0;
    const maxRetries = 3;

    while (retryCount < maxRetries) {
      try {
        return await passportClient.getAccessToken();
      } catch (e) {
        // If this is reached there was an error silently refreshing inside the Passport SDK and a re-login is required
        notifyError(e, "useAccessToken");

        if (
          e instanceof Error &&
          e.message.includes("Unknown or invalid refresh token")
        ) {
          break;
        }

        retryCount++;
      }
    }

    logout();
    return undefined;
  }, [passportClient, logout]);

  return getAccessToken;
}

// This is a hook that returns the current access token. It will automatically refresh the token if it is expired.
// Doesnt rely on you calling it in a useEffect, it will always return the current token or undefined unwrapped from annoying promises.
export const useAccessTokenState = () => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const { passportClient, passportState } = usePassportProvider();

  useEffect(() => {
    if (!passportClient || !passportState.authenticated) {
      setAccessToken(undefined);
      return;
    }

    const fetchToken = async () => {
      try {
        const token = await passportClient.getAccessToken();
        setAccessToken(token);
      } catch (error) {
        console.error("Failed to retrieve access token", error);
        setAccessToken(undefined);
      }
    };
    fetchToken();
  }, [passportClient, passportState.authenticated]);

  return accessToken;
};
