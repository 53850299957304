import type { BorderRadius } from "../types";

export const borderRadius: BorderRadius = {
  x1: "2px",
  x2: "4px",
  x3: "6px",
  x4: "8px",
  x5: "10px",
  x6: "12px",
  x7: "14px",
  x8: "16px",
  x9: "18px",
  x10: "20px",
  x11: "22px",
  x12: "24px",
  x13: "26px",
  x14: "28px",
  x15: "30px",
  x16: "32px",
  x17: "34px",
  x18: "36px",
  x19: "38px",
  x20: "40px",
  x21: "42px",
  x22: "44px",
  x23: "46px",
  x24: "48px",
  x25: "50px",
} as const;
