import { Stack } from "@/components/Stack";
import type {
  BoxWithRCAndDomProps,
  MakeValidSxValue,
  StandardComponentWithProps,
} from "@/types";
import type { Property } from "csstype";
import { type ReactElement, type ReactNode, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { useGetManySingleSubcomponentChildren } from "@/hooks";

import { HeroBackgroundImage } from "./HeroBackgroundImage";
import { HeroBackgroundVideo } from "./HeroBackgroundVideo";
import { HeroBackgroundZoomText } from "./HeroBackgroundZoomText";
import { baseContainerSx, innerMaskedMediaSx } from "./styles";

type HeroBackgroundBaseProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    children?: ReactNode;
    aspectRatio?: MakeValidSxValue<Property.AspectRatio>;
  }
>;

export type HeroBackgroundProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & HeroBackgroundBaseProps;

export function HeroBackground<
  RC extends ReactElement | undefined = undefined,
>({
  className,
  children,
  sx = {},
  aspectRatio = "16/9",
  testId = "HeroBackground",
  ...props
}: HeroBackgroundProps<RC>) {
  const mergedSx = useMemo(
    () => merge(baseContainerSx, { aspectRatio }, sx),
    [sx, aspectRatio],
  );
  const {
    subcomponents: [image, video, zoomText],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    HeroBackgroundImage,
    HeroBackgroundVideo,
    HeroBackgroundZoomText,
  ]);
  const media = image ?? video;
  return (
    <Stack
      {...props}
      className={`${className ?? ""} HeroBackground`}
      sx={mergedSx}
      testId={testId}
    >
      <Box className="innerMaskedMedia" sx={innerMaskedMediaSx}>
        {media}
      </Box>
      {zoomText}
      {otherChildren}
    </Stack>
  );
}

HeroBackground.displayName = "HeroBackground";
HeroBackground.Video = HeroBackgroundVideo;
HeroBackground.ZoomText = HeroBackgroundZoomText;
HeroBackground.Image = HeroBackgroundImage;
