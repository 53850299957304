import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";
import { SvgIcon } from "../SvgIcon";

const LOGO_REFERENCE = "ImmutableSymbol";

export function ImmutableSymbol({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent(LOGO_REFERENCE);
  return (
    <SvgIcon
      {...props}
      testId={testId}
      viewBox="0 0 33 32"
      sx={merge({ w: "32px", fill: "base.color.brand.1" }, sx)}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}

ImmutableSymbol.displayName = LOGO_REFERENCE;
