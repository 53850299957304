import { hFlex } from "@/utils";

export const bulletListSx = {
  p: "0",
};

export const bulletListTitleSx = {
  ...hFlex,
  gap: "0.5em",
  alignItems: "center",
  pos: "relative",
};

export const bulletListTitleDuoConSx = {
  w: "1.4em",
};

export const bulletListInnerSx = {
  m: "0",
  p: "0",
  listStyle: "none",
  listStylePosition: "inside",

  "& > li": {
    counterIncrement: "ol",
  },

  "&[reversed]": {
    counterSet: "ol var(--totalItems)",

    "& > li": {
      counterIncrement: "ol -1",
    },
  },

  "&[start]": {
    counterSet: "ol var(--listStart)",
  },
};

export const bulletListItemSx = {
  ...hFlex,
  gap: "var(--titleTextGap)",

  "&::before": {
    minw: "var(--titleTextDuoConWidth)",
    display: "block",
    flexShrink: 0,
    textAlign: "center",
  },

  "&.BulletListItem--ul": {
    "&::before": {
      content: "'●'", // https://www.compart.com/en/unicode/U+25CF
    },

    "& .BulletList": {
      ".BulletListItem--ul": {
        "&::before": {
          minw: "calc(var(--titleTextDuoConWidth) * 0.5)",
          textAlign: "left",
        },
      },
    },
  },

  "&.BulletListItem--ol": {
    "::before": {
      content: 'counter(ol) "."',
    },
  },

  "& .Body, & .Link": {
    fontSize: "inherit",
  },
};

export const nestedBulletListContainerSx = { pt: "base.spacing.x1" };
