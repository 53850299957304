import { trackLegacyEvent } from "@analytics";
import { Box, Button, Grid, HeroCard, useWindowSizeStore } from "@biom3/react";
import { BREAKPOINTS } from "@ui-kit/hooks/screenSizeHooks";
import NextLink from "next/link";
import { type RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useFilter } from "../../../../apps/play-portal/src/context/FilterProvider";

type GameContext = {
  clientIds: string[];
  network: "imx-zkevm-mainnet" | "imx-mainnet";
  tokenTroveUri: string;
  searchQuery: string;
};

export type Game = {
  name: string;
  link: string;
  image: string;
  description?: string;
  context?: GameContext;
  genres: string[];
  platforms: string[];
};

export type GamesGridProps = {
  games: Game[];
  tag: string;
  onClick?: (game: Game) => void;
};

export function GamesGrid({ games, tag, onClick }: GamesGridProps) {
  const { filters, filterConfig } = useFilter();

  // track loading state
  const [loading, setLoading] = useState(false);

  const sliceSize = tag === "coming_soon" ? 20 : games.length;

  // track the currently displayed games
  const [displayedGames, setDisplayedGames] = useState<Game[]>(
    games.slice(0, sliceSize),
  );

  const scrollElement = useRef<Element | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      setDisplayedGames(games);
      return;
    }

    const genreFilters = filters.genre || [];
    const platformFilters = filters.platform || [];
    const searchFilters = filters.search || [];

    const filteredGames = games.filter((game) => {
      const { genres, platforms, name } = game;

      const matchesGenres =
        genreFilters.length === 0 ||
        genreFilters.some((filter) => {
          const genreOptions = filterConfig.genre.options.find(
            (option) => option.value === filter,
          );
          return (
            genres.includes(filter) ||
            (genreOptions?.originalValues?.some((original) =>
              genres.includes(original),
            ) ??
              false)
          );
        });

      const matchesPlatforms =
        platformFilters.length === 0 ||
        platformFilters.some((filter) => {
          const platformOptions = filterConfig.platform.options.find(
            (option) => option.value === filter,
          );
          return (
            platforms.includes(filter) ||
            (platformOptions?.originalValues?.some((original) =>
              platforms.includes(original),
            ) ??
              false)
          );
        });

      const matchesSearch =
        searchFilters.length === 0 ||
        searchFilters.some((filter) => name.toLowerCase().includes(filter));

      return matchesGenres && matchesPlatforms && matchesSearch;
    });

    setDisplayedGames(filteredGames);
  }, [filters, games]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const filteredGames = games.length - displayedGames.length;
    if (displayedGames.length + filteredGames === games.length) {
      setLoading(false);
      scrollElement.current?.removeEventListener("scroll", handleScroll);
      scrollElement.current = null;
    }
  }, [displayedGames]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (loading && displayedGames.length < games.length) {
      const index = displayedGames.length;
      const visibleGameSlice = games.slice(index, index + sliceSize);
      if (visibleGameSlice.length > 0)
        setDisplayedGames([...displayedGames, ...visibleGameSlice]);
      setLoading(false);
    }
  }, [loading]);

  const handleScroll = () => {
    if (scrollElement.current !== null) {
      const scrollTop = scrollElement.current?.scrollTop;
      const clientHeight = scrollElement.current?.clientHeight;
      const scrollHeight = scrollElement.current?.scrollHeight;

      if (!scrollTop || !clientHeight) {
        return;
      }

      if (
        scrollTop + clientHeight >= scrollHeight - 600 &&
        displayedGames.length < games.length
      ) {
        setLoading(true);
      }
    }
  };

  const handleOnClick = (game: Game) => {
    trackLegacyEvent({
      screen: "GamesList",
      userJourney: "Games",
      action: "Pressed",
      control: "Click",
      controlType: "Link",
      extras: {
        tag,
        gameName: game.name,
      },
    });
    onClick?.(game);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // we currently only want to 'lazy-load' the coming soon games
    if (tag === "coming_soon" && games.length > sliceSize) {
      const scrollableContainer = document.getElementById(
        "rootScrollableContainer",
      );

      if (scrollableContainer) {
        scrollElement.current = scrollableContainer;
        scrollElement.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        scrollElement.current?.removeEventListener("scroll", handleScroll);
        scrollElement.current = null;
      };
    }
  }, []);

  const { state: width } = useWindowSizeStore((store) => store.width);
  const tileSize = useMemo(() => {
    let tile = "286px";
    if (typeof width === "number" && width < BREAKPOINTS.large) {
      tile = "200px";
    } else if (typeof width === "number" && width < BREAKPOINTS.xxLarge) {
      tile = "225px";
    } else if (typeof width === "number" && width < BREAKPOINTS.xxxLarge) {
      tile = "250px";
    }
    return tile;
  }, [width]);

  return (
    <Grid
      domRef={scrollElement as RefObject<HTMLDivElement>}
      testId="games-list"
      sx={{
        gap: "base.spacing.x6",
        mb: "base.spacing.x10",
      }}
      minColumnWidth={tileSize}
    >
      {displayedGames.map((game) => (
        <HeroCard
          rc={
            <NextLink
              href={{ pathname: game.link }}
              referrerPolicy={
                game.link.startsWith("/") ? "same-origin" : "no-referrer"
              }
              target={game.link.startsWith("/") ? "_self" : "_blank"}
              onClick={() => handleOnClick(game)}
            />
          }
          testId={`game-${game.name}`}
          key={game.name}
        >
          <HeroCard.Title testId={`game-title-${game.name}`}>
            {game.name}
          </HeroCard.Title>
          <HeroCard.Description>{game.description}</HeroCard.Description>
          <HeroCard.AssetImage
            aspectRatio="1:1"
            imageUrl={game.image}
            relativeImageSizeInLayout="1024px"
            objectFit="cover"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Button
              variant="primary/inverse"
              size="medium"
              sx={{
                alignSelf: "flex-start",
                mr: "base.spacing.x8",
                w: "100%",
              }}
              onClick={(event) => {
                event.preventDefault();
                const target = game.link.startsWith("/") ? "_self" : "_blank";
                window.open(game.link, target);
                handleOnClick(game);
              }}
            >
              View game
            </Button>
          </Box>
        </HeroCard>
      ))}
    </Grid>
  );
}

export default GamesGrid;
