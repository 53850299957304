import { ClassNames, type DistributiveOmit } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Button } from "@/components/Clickable";
import { BUTTON_SIZES, DEFAULT_BUTTON_SIZE } from "@/constants";
import { useTheme } from "@/hooks";
import type { ButtonProps } from "@/types";
import {
  getStartingSize,
  setDefaultSxBackgroundColor,
} from "@/utils/styleHelpers";

import {
  buttonBaseSxProps,
  getButtonStyles,
  renderResponsiveButtonStyles,
} from "./styles";

export type HorizontalMenuButtonProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtonProps<RC>, "variant"> & {
  selected?: boolean;
};

export function HorizontalMenuButton<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  sx = {},
  size = "medium",
  selected,
  className,
  ...props
}: HorizontalMenuButtonProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_BUTTON_SIZE, BUTTON_SIZES);

  const mergedSx = useMemo(
    () =>
      merge(
        buttonBaseSxProps,
        getButtonStyles({ size: startingSize, theme }),
        renderResponsiveButtonStyles({
          size,
          theme,
        }),
        setDefaultSxBackgroundColor(sx, "base.color.translucent.emphasis.100"),
        sx,
      ),
    [sx, startingSize, size, theme],
  );

  return (
    <ClassNames>
      {({ cx }) => (
        <Button
          {...props}
          size={size}
          variant="tertiary"
          className={cx(className, "HorizontalMenuButton", { selected })}
          sx={mergedSx}
        >
          {children}
        </Button>
      )}
    </ClassNames>
  );
}

HorizontalMenuButton.displayName = "HorizontalMenuButton";
HorizontalMenuButton.Icon = Button.Icon;
HorizontalMenuButton.Logo = Button.Logo;
HorizontalMenuButton.FramedImage = Button.FramedImage;
