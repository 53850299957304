import {
  InputBar,
  type InputBarProps,
} from "@/components/MenuItemRelatedComponents";

type InputListInputBarProps = InputBarProps;

export function InputListInputBar({
  size = "xSmall",
  emphasized = true,
  className,
  children,
  testId = "InputListInputBar",
  ...props
}: InputListInputBarProps) {
  return (
    <InputBar
      {...props}
      size={size}
      testId={testId}
      emphasized={emphasized}
      className={`${className ?? ""} InputListInputBar`}
    >
      {children}
    </InputBar>
  );
}

InputListInputBar.displayName = "InputList.InputBar";

InputListInputBar.Icon = InputBar.Icon;
InputListInputBar.FramedIcon = InputBar.FramedIcon;
InputListInputBar.FramedImage = InputBar.FramedImage;
InputListInputBar.FramedLogo = InputBar.FramedLogo;
InputListInputBar.FramedStack = InputBar.FramedStack;
InputListInputBar.Avatar = InputBar.Avatar;
InputListInputBar.SwapStack = InputBar.SwapStack;
InputListInputBar.Label = InputBar.Label;
InputListInputBar.Caption = InputBar.Caption;
