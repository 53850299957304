import { merge } from "ts-deepmerge";

import type { BiomeTheme, ButtonSize } from "@/types";
import {
  largeButtonStaticStyles,
  mediumButtonStaticStyles,
  smallButtonStaticStyles,
} from "./shared";

export const primaryButtonGenericStyles = {
  background: "base.color.brand.1",
  color: "base.color.text.body.inverse.primary",
  boxShadow: "inset 0 0 0 0 transparent",
  transitionProperty: "none",
  flexShrink: 0,

  "&::before": {
    content: '""',
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: "base.color.brand.1",
    transitionProperty: "clip-path",
  },
};

export const getPrimaryInverseButtonGenericStyles = ({ base }: BiomeTheme) => ({
  background:
    base.colorMode === "onDark"
      ? "base.color.translucent.inverse.1000"
      : "base.color.neutral.500",
  color: "base.color.text.body.primary",

  "&::before": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background:
      base.colorMode === "onDark"
        ? "base.color.translucent.inverse.1000"
        : "base.color.neutral.500",
  },
});

export const getPrimaryStatusButtonGenericStyles = (
  status: PrimaryButtonStatus,
) => ({
  background: `base.color.status.${status}.bright`,

  "&::before": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    background: `base.color.status.${status}.bright`,
  },
});

export function getSmallPrimaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[100]} + ${base.border.size[200]})`;
  const pressCutaway = `calc(${base.border.size[200]} + ${base.border.size[200]})`;

  return {
    ...smallButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x10})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.brand[1]}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.brand[1]}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x10})`,
      },
    },
  };
}

export function getMediumPrimaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[300]})`;
  const pressCutaway = `calc(${base.border.size[300]} + ${base.border.size[300]})`;

  return {
    ...mediumButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x15})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.brand[1]}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.brand[1]}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x15})`,
      },
    },
  };
}

export function getLargePrimaryButtonUniqueStyles(themeProps: BiomeTheme) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[400]})`;
  const pressCutaway = `calc(${base.border.size[400]} + ${base.border.size[400]})`;

  return {
    ...largeButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x20})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.brand[1]}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.brand[1]}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x20})`,
      },
    },
  };
}

export function getSmallPrimaryInverseButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[100]} + ${base.border.size[200]})`;
  const pressCutaway = `calc(${base.border.size[200]} + ${base.border.size[200]})`;

  return {
    ...smallButtonStaticStyles(themeProps),

    "&::before": {
      background: "base.color.text.body.primary",
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x10})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x10})`,
      },
    },
  };
}

export function getMediumPrimaryInverseButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[300]})`;
  const pressCutaway = `calc(${base.border.size[300]} + ${base.border.size[300]})`;

  return {
    ...mediumButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x15})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x15})`,
      },
    },
  };
}

export function getLargePrimaryInverseButtonUniqueStyles(
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[400]})`;
  const pressCutaway = `calc(${base.border.size[400]} + ${base.border.size[400]})`;

  return {
    ...largeButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x20})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${
        base.colorMode === "onDark"
          ? base.color.translucent.inverse[1000]
          : base.color.neutral[500]
      }`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x20})`,
      },
    },
  };
}

type PrimaryButtonStatus = "success" | "fatal";
export function getSmallPrimaryStatusButtonUniqueStyles(
  status: PrimaryButtonStatus,
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[100]} + ${base.border.size[200]})`;
  const pressCutaway = `calc(${base.border.size[200]} + ${base.border.size[200]})`;

  return {
    ...smallButtonStaticStyles(themeProps),

    "&::before": {
      background: "base.color.text.body.primary",
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x10})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status[status].bright}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[200]} ${base.color.status[status].bright}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x10})`,
      },
    },
  };
}

export function getMediumPrimaryStatusButtonUniqueStyles(
  status: PrimaryButtonStatus,
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[300]})`;
  const pressCutaway = `calc(${base.border.size[300]} + ${base.border.size[300]})`;

  return {
    ...mediumButtonStaticStyles(themeProps),
    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x15})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status[status].bright}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[300]} ${base.color.status[status].bright}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x15})`,
      },
    },
  };
}

export function getLargePrimaryStatusButtonUniqueStyles(
  status: PrimaryButtonStatus,
  themeProps: BiomeTheme,
) {
  const { base } = themeProps;
  const hoverCutaway = `calc(${base.border.size[200]} + ${base.border.size[400]})`;
  const pressCutaway = `calc(${base.border.size[400]} + ${base.border.size[400]})`;

  return {
    ...largeButtonStaticStyles(themeProps),

    "&::before": {
      clipPath: `inset(${hoverCutaway} ${hoverCutaway} ${hoverCutaway} ${hoverCutaway} round ${base.borderRadius.x20})`,
    },

    "&:hover:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.status[status].bright}`,
    },

    "&:active:not([disabled])": {
      background: "transparent",
      boxShadow: `inset 0 0 0 ${base.border.size[400]} ${base.color.status[status].bright}`,

      "&::before": {
        clipPath: `inset(${pressCutaway} ${pressCutaway} ${pressCutaway} ${pressCutaway} round ${base.borderRadius.x20})`,
      },
    },
  };
}

export function getPrimaryButtonStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  return merge(
    size === "small"
      ? getSmallPrimaryButtonUniqueStyles(themeProps)
      : size === "medium"
        ? getMediumPrimaryButtonUniqueStyles(themeProps)
        : getLargePrimaryButtonUniqueStyles(themeProps),
  );
}

export function getPrimaryInverseButtonStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  return merge(
    size === "small"
      ? getSmallPrimaryInverseButtonUniqueStyles(themeProps)
      : size === "medium"
        ? getMediumPrimaryInverseButtonUniqueStyles(themeProps)
        : getLargePrimaryInverseButtonUniqueStyles(themeProps),
  );
}

export function getPrimaryStatusButtonStyles(
  status: PrimaryButtonStatus,
  size: ButtonSize,
  themeProps: BiomeTheme,
) {
  return merge(
    size === "small"
      ? getSmallPrimaryStatusButtonUniqueStyles(status, themeProps)
      : size === "medium"
        ? getMediumPrimaryStatusButtonUniqueStyles(status, themeProps)
        : getLargePrimaryStatusButtonUniqueStyles(status, themeProps),
  );
}
