import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Divider, type DividerProps } from "@/components/Divider";
import { DEFAULT_MENU_ITEM_SIZE, MENU_ITEM_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type { MakeResponsive, MenuItemSize } from "@/types";
import { getStartingSize } from "@/utils";

import { renderResponsiveDividerSx, renderSizeBasedDividerSx } from "./styles";

export type MenuItemBottomSlotDividerProps = DividerProps & {
  menuItemSize?: MakeResponsive<MenuItemSize>;
};

export function MenuItemBottomSlotDivider({
  menuItemSize = DEFAULT_MENU_ITEM_SIZE,
  size = "xSmall",
  className,
  sx = {},
  ...props
}: MenuItemBottomSlotDividerProps) {
  const theme = useTheme();
  const startingSize = getStartingSize(
    menuItemSize,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );
  const mergedSx = useMemo(
    () =>
      merge(
        renderSizeBasedDividerSx({ size: startingSize, theme }),
        renderResponsiveDividerSx({ size: menuItemSize, theme }),
        {
          "&:has(+ .MenuItem)": {
            mb: "0px",
          },
        },
        sx,
      ),
    [startingSize, menuItemSize, theme, sx],
  );
  return (
    <Divider
      {...props}
      size={size}
      sx={mergedSx}
      className={`${className ?? ""} MenuItemBottomSlotDivider`}
    />
  );
}

MenuItemBottomSlotDivider.displayName = "MenuItem.BottomSlot.Divider";
