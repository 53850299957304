import type {
  MakeResponsive,
  PaginationDiscriminatedUnion,
  StandardComponentWithProps,
} from "@/types";

export const ONBOARDING_PAGINATION_SIZES = ["small", "medium"] as const;
export type OnboardingPaginationSize =
  (typeof ONBOARDING_PAGINATION_SIZES)[number];

export type OnboardingPaginationProps = PaginationDiscriminatedUnion<
  StandardComponentWithProps<
    HTMLDivElement,
    {
      size?: MakeResponsive<OnboardingPaginationSize>;
    }
  >
>;
