import type { EmblaCarouselType } from "embla-carousel";
import { useCallback, useEffect, useState } from "react";

type UseDotButtonProps = {
  selectedIndex: number;
  scrollSnaps: number[];
  scrollProgress: number;
  onDotButtonClick: (index: number) => void;
};

export function useEmblaDotButton(
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonProps {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => emblaApi?.scrollTo(index),
    [emblaApi],
  );
  const onInit = useCallback(
    (emblaApi: EmblaCarouselType) => setScrollSnaps(emblaApi.scrollSnapList()),
    [],
  );
  const onSelect = useCallback(
    (emblaApi: EmblaCarouselType) =>
      setSelectedIndex(emblaApi.selectedScrollSnap()),
    [],
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);

    return () => {
      emblaApi.off("reInit", onInit);
      emblaApi.off("reInit", onSelect);
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    scrollProgress: (selectedIndex / (scrollSnaps.length - 1)) * 100,
    onDotButtonClick,
  };
}
