export const RE_DIGIT = /^\d+$/;

export const focusToNextInput = (target: HTMLElement) => {
  const nextInputElementSibling = target.parentElement?.nextElementSibling
    ?.firstElementChild as HTMLElement | null;
  nextInputElementSibling?.focus();
};

export const focusToPrevInput = (target: HTMLElement) => {
  const previousInputElementSibling = target.parentElement
    ?.previousElementSibling?.firstElementChild as HTMLElement | null;
  previousInputElementSibling?.focus();
};
