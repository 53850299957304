import { base64UrlEncodeImageUrl } from "@imtbl/image-resizer-utils";

export const ON_LIGHT_DEFAULT_IMAGE =
  "https://biome.immutable.com/hosted-assets/images/cloudImageDefaultImage--onLight.svg";
export const ON_DARK_DEFAULT_IMAGE =
  "https://biome.immutable.com/hosted-assets/images/cloudImageDefaultImage--onDark.svg";

export const generateBaseImageUrl = ({
  imageUrl,
  imageResizeServiceUrl,
}: {
  imageResizeServiceUrl: string;
  imageUrl?: string | null;
}) =>
  imageUrl
    ? `${imageResizeServiceUrl}/url=${base64UrlEncodeImageUrl(imageUrl)}`
    : undefined;

export const generateResizedImageUrl = ({
  width,
  height,
  imageUrl,
  imageResizeServiceUrl,
  isSvg,
}: {
  width?: number;
  height?: number;
  imageUrl?: string | null;
  imageResizeServiceUrl: string;
  isSvg?: boolean;
}) =>
  imageUrl
    ? `${generateBaseImageUrl({ imageResizeServiceUrl, imageUrl })}${
        width && !isSvg ? `&w=${width}` : height && !isSvg ? `&h=${height}` : ""
      }`
    : undefined;
