import {
  type ComponentProps,
  type ReactElement,
  isValidElement,
  useId,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { FormControlCaption } from "@/components/FormControl/FormControlCaption";
import { FormControlLabel } from "@/components/FormControl/FormControlLabel";
import { FormControlValidation } from "@/components/FormControl/FormControlValidation";
import { SmartClone } from "@/components/SmartClone";
import { useGetSubcomponentChild } from "@/hooks";
import type { HeroFormControlProps } from "@/types";
import { centerFlexChildren, vFlex } from "@/utils";

import { HeroTextInput } from "../HeroTextInput";

const SUPPORTED_INPUT_COMPONENTS = [HeroTextInput] as const;

const SUPPORTED_INPUT_COMPONENTS_MAP = {
  HeroTextInput,
} as const;

export function HeroFormControl<
  RC extends ReactElement | undefined = undefined,
>({
  domRef,
  sx = {},
  id,
  testId = "HeroFormControl",
  children,
  className,
  validationStatus,
  ...domAttributes
}: HeroFormControlProps<RC>) {
  const fallbackId = useId();
  const idToUse = id ?? fallbackId;
  const label = useGetSubcomponentChild(children, FormControlLabel);
  const caption = useGetSubcomponentChild(children, FormControlCaption);
  const validation = useGetSubcomponentChild(children, FormControlValidation);
  const flattendChildren = useMemo(() => flattenChildren(children), [children]);

  // @NOTE: Typescript struggles to type 'find' and infer
  // prop types, so we need to cast here.
  const inputComponent = flattendChildren.find((child) =>
    SUPPORTED_INPUT_COMPONENTS.some(
      (inputEl) => isValidElement(child) && child.type === inputEl,
    ),
  ) as ReactElement<
    ComponentProps<(typeof SUPPORTED_INPUT_COMPONENTS)[number]>
  >;

  const mergedContainerSx = useMemo(
    () =>
      merge(
        {
          ...vFlex,
          ...centerFlexChildren,
          w: "100%",
        },
        sx,
      ),
    [sx],
  );

  return (
    <Box
      {...domAttributes}
      domRef={domRef}
      testId={testId}
      sx={mergedContainerSx}
      className={`${className ?? ""} HeroFormControl`}
    >
      {label && (
        <SmartClone
          htmlFor={idToUse}
          textAlign={label.props.textAlign ?? "center"}
          weight={label.props.weight ?? "bold"}
        >
          {label}
        </SmartClone>
      )}
      {inputComponent && (
        <SmartClone
          id={idToUse}
          validationStatus={
            inputComponent.props.validationStatus || validationStatus
          }
        >
          {inputComponent}
        </SmartClone>
      )}
      {caption && (
        <SmartClone htmlFor={idToUse} textAlign="center">
          {caption}
        </SmartClone>
      )}
      {validation && (
        <SmartClone
          textAlign="center"
          htmlFor={idToUse}
          validationStatus={
            validation.props.validationStatus || validationStatus
          }
        >
          {validation}
        </SmartClone>
      )}
    </Box>
  );
}

HeroFormControl.displayName = "HeroFormControl";

HeroFormControl.Label = FormControlLabel;
HeroFormControl.Validation = FormControlValidation;
HeroFormControl.Caption = FormControlCaption;
HeroFormControl.inputs = SUPPORTED_INPUT_COMPONENTS_MAP;
