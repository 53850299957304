import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { StatefulButtCon } from "@/components/Clickable";
import type { StatefulButtConProps } from "@/types";

type MenuItemStatefulButtConProps<
  RC extends ReactElement | undefined = undefined,
> = StatefulButtConProps<RC>;

export function MenuItemStatefulButtCon<
  RC extends ReactElement | undefined = undefined,
>({ sx = {}, size = "small", ...props }: MenuItemStatefulButtConProps<RC>) {
  const { onClick } = "onClick" in props ? props : { onClick: null };
  const mergedSx = useMemo(() => merge({ flexShrink: 0 }, sx), [sx]);
  return (
    <StatefulButtCon
      {...props}
      sx={mergedSx}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (typeof onClick === "function") {
          onClick(ev);
        }
      }}
      size={size}
    />
  );
}

MenuItemStatefulButtCon.displayName = "MenuItem.StatefulButtCon";
MenuItemStatefulButtCon.SvgIcon = StatefulButtCon.SvgIcon;
