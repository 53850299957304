import { merge } from "ts-deepmerge";

import type { BiomeTheme, MakeResponsive, ToolbarSize } from "@/types";
import {
  getBodyTextStyles,
  getHeadingTextStyles,
  isError,
} from "@/utils/textStyleHelpers";

export const toolbarContainerBaseSx = {
  bg: "base.color.neutral.800",
  width: "100%",
  paddingX: "base.spacing.x2",
  paddingY: "base.spacing.x3",
  display: "flex",
  alignItems: "center",
};

type StyleFunctionProps = {
  size: ToolbarSize;
  themeProps: BiomeTheme;
};
type ResponsiveStyleFunctionProps = Omit<StyleFunctionProps, "size"> & {
  size: MakeResponsive<ToolbarSize>;
};

export function getContainerStyles({ size, themeProps }: StyleFunctionProps) {
  switch (size) {
    case "small":
      return {
        height: "64px",
        paddingX: themeProps.base.spacing.x4,
      };

    case "medium":
    default:
      return {
        height: "80px",
        paddingX: themeProps.base.spacing.x6,
      };
  }
}

export function getResponsiveContainerStyles({
  size,
  themeProps,
}: ResponsiveStyleFunctionProps) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getContainerStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );
}

export function getTitleStyles({ themeProps, size }: StyleFunctionProps) {
  switch (size) {
    case "small":
      return getHeadingTextStyles({
        themeProps,
        size: "xSmall",
        weight: "bold",
      });

    case "medium":
    default:
      return getHeadingTextStyles({
        themeProps,
        size: "small",
        weight: "bold",
      });
  }
}

export function getResponsiveTitleStyles({
  themeProps,
  size,
}: ResponsiveStyleFunctionProps) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getTitleStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );
}

export function getCaptionStyles({ themeProps, size }: StyleFunctionProps) {
  switch (size) {
    case "small":
      return getBodyTextStyles({
        themeProps,
        size: "xSmall",
        weight: "regular",
      });

    case "medium":
    default:
      return getBodyTextStyles({
        themeProps,
        size: "small",
        weight: "regular",
      });
  }
}

export function getResponsiveCaptionStyles({
  themeProps,
  size,
}: ResponsiveStyleFunctionProps) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getCaptionStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );
}

export function getLeftButtConStyles({ themeProps, size }: StyleFunctionProps) {
  switch (size) {
    case "small":
      return { marginRight: themeProps.base.spacing.x4 };

    case "medium":
    default:
      return { marginRight: themeProps.base.spacing.x6 };
  }
}

export function getResponsiveLeftButtConStyles({
  themeProps,
  size,
}: ResponsiveStyleFunctionProps) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getLeftButtConStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );
}

export function getRightSideMarginStyles({
  themeProps,
  size,
}: StyleFunctionProps) {
  switch (size) {
    case "small":
      return { marginLeft: themeProps.base.spacing.x4 };

    case "medium":
    default:
      return { marginLeft: themeProps.base.spacing.x6 };
  }
}

export function getResponsiveRightSideMarginStyles({
  themeProps,
  size,
}: ResponsiveStyleFunctionProps) {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();
  return merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${themeProps.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getRightSideMarginStyles({
            size: responsiveSize,
            themeProps,
          }),
        };
      }
      return {};
    }),
  );
}
