import type { ComponentType, ReactElement, ReactNode } from "react";
import flattenChildren from "react-keyed-flatten-children";

import { isChildSubcomponent } from "../utils/subcomponentHelpers";

/**
 * This function filters and returns an array of React elements that are either direct children or
 * nested within a smart clone of a specified subcomponent.
 * @param {ReactNode} children - The children prop is a special prop in React that allows components to
 * pass children elements (components, text, etc.) to other components. It is typically used to compose
 * components and create complex UIs. In this function, the children prop is being passed as an
 * argument and is expected to be a React
 * @param subcomponent - The subcomponent parameter is a React component type that is expected to be a
 * child of the parent component. The function filters through the children of the parent component and
 * returns an array of React elements that match the subcomponent type.
 * @returns The function `useGetSubcomponentChildren` returns an array of React elements that are
 * either direct children of the specified `subcomponent` or nested within a smart clone of the
 * `subcomponent`. The function takes two arguments: `children`, which is a ReactNode representing the
 * children of a component, and `subcomponent`, which is a ComponentType representing the subcomponent
 * whose children are being filtered.
 */
export function useGetSubcomponentChildren<TProps>(
  children: ReactNode,
  subcomponent: ComponentType<TProps>,
) {
  return flattenChildren(children).filter((child) => {
    return isChildSubcomponent(child, subcomponent);
  }) as undefined | ReactElement<TProps>[];
}
