import type { Ref } from "react";
import { merge } from "ts-deepmerge";

import type { MenuItemProps } from "@/types";
import { MenuItem } from "../MenuItemRelatedComponents";

// @NOTE: when rendered inside a Card (which can be clickable), MenuItem should
// not be anything but a HTMLDivElement, so we can Omit the rc prop.
export type CardMenuItemProps = Omit<MenuItemProps<undefined, undefined>, "rc">;

export function CardMenuItem({
  sx = {},
  children,
  size,
  domRef,
  className,
  ...props
}: CardMenuItemProps) {
  return (
    <MenuItem
      {...props}
      size={size}
      domRef={domRef as Ref<HTMLButtonElement>}
      sx={merge({ px: "base.spacing.x5", bradbl: "0px", bradbr: "0px" }, sx)}
      className={`${className ?? ""} CardMenuItem`}
    >
      {children}
    </MenuItem>
  );
}

CardMenuItem.displayName = "Card.MenuItem";

CardMenuItem.Label = MenuItem.Label;
CardMenuItem.Icon = MenuItem.Icon;
CardMenuItem.Caption = MenuItem.Caption;
CardMenuItem.FramedIcon = MenuItem.FramedIcon;
CardMenuItem.FramedLogo = MenuItem.FramedLogo;
CardMenuItem.FramedImage = MenuItem.FramedImage;
CardMenuItem.IntentIcon = MenuItem.IntentIcon;
CardMenuItem.PriceDisplay = MenuItem.PriceDisplay;
CardMenuItem.Badge = MenuItem.Badge;
CardMenuItem.OverflowPopoverMenu = MenuItem.OverflowPopoverMenu;
CardMenuItem.OverflowDrawerMenu = MenuItem.OverflowDrawerMenu;
CardMenuItem.StatefulButtCon = MenuItem.StatefulButtCon;
CardMenuItem.SwapStack = MenuItem.SwapStack;
CardMenuItem.FramedStack = MenuItem.FramedStack;
CardMenuItem.Avatar = MenuItem.Avatar;
