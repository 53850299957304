import type { DuoConColorVariant, IconVariant } from "@/types";

export function getBgFillColoring(
  colorVariant: DuoConColorVariant,
  variant: IconVariant,
) {
  return variant === "regular"
    ? "base.color.fixed.black.1000"
    : `base.color.status.${colorVariant}.bright`;
}

export function getFillColoring(
  colorVariant: DuoConColorVariant,
  variant: IconVariant,
) {
  if (variant === "regular") {
    return `base.color.status.${colorVariant}.bright`;
  }
  if (variant === "bold" && /fatal|success/.test(colorVariant)) {
    return "base.color.text.body.inverse.primary";
  }
  return "base.color.fixed.black.1000";
}
