import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Video } from "@/components/Video";
import { DEFAULT_IMAGE_SIZE_VARIANT } from "@/constants";
import type { FramedVideoProps } from "@/types";

import { FramedContainer } from "../shared/FramedContainer";
import { innerMediaElementSx } from "../shared/styles";

export function FramedVideo<RC extends ReactElement | undefined = undefined>({
  circularFrame = false,
  emphasized = false,
  padded = false,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  className,
  sx = {},
  domRef,
  rc,
  testId = "FramedVideo",
  autoPlay = true,
  muted = true,
  loop = true,
  objectFit = "cover",
  objectPosition = "center",
  motionProfile,
  ...otherVideoProps
}: FramedVideoProps<RC>) {
  return (
    <FramedContainer
      padded={padded}
      circularFrame={circularFrame}
      emphasized={emphasized}
      sx={sx}
      testId={testId}
      rc={rc}
      domRef={domRef}
      size={size}
      className={`${className ?? ""} FramedVideo`}
      motionProfile={motionProfile}
    >
      <Video
        {...otherVideoProps}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
        testId={`${testId}__video`}
        className="FramedVideo__video"
        sx={merge(innerMediaElementSx, {
          objectFit,
          objectPosition,
          borderRadius: circularFrame ? "50%" : "base.borderRadius.x2",
          w: padded ? "70%" : "100%",
          h: padded ? "70%" : "100%",
          minw: "unset",
        })}
      />
    </FramedContainer>
  );
}

FramedVideo.displayName = "FramedVideo";
