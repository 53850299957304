import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { useTheme } from "@/hooks";
import type { BoxProps, DomPropsWithDomRef } from "@/types";
import { lineClamp } from "../../utils/sxChunks";
import { getHeadingTextStyles } from "../../utils/textStyleHelpers";
import { Box } from "../Box";

type BaseCardTitleProps = BoxProps;

export type CardTitleProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"span"> & BaseCardTitleProps
    : BaseCardTitleProps & { rc: RC };

export function CardTitle<RC extends ReactElement | undefined = undefined>({
  children,
  sx = {},
  rc = <span />,
  className,
  ...props
}: CardTitleProps<RC>) {
  const theme = useTheme();
  const titleStyles = merge(
    {
      textAlign: "left",
      color: "base.color.text.heading.primary",
      flexShrink: 0,
      ...lineClamp(2),
    },
    getHeadingTextStyles({
      themeProps: theme,
      size: "xSmall",
      weight: "bold",
    }),
    sx,
  );
  return (
    <Box
      {...props}
      sx={titleStyles}
      rc={rc}
      className={`${className ?? ""} CardTitle`}
    >
      {children}
    </Box>
  );
}

CardTitle.displayName = "Card.Title";
