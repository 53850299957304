import type { BaseTokens, Borders, Frost, Icons, ZLevels } from "../types";
import { borderRadius } from "./borderRadius";
import { breakpoint } from "./breakpoint";
import { font } from "./font";
import { motion } from "./motion";
import { spacing } from "./spacing";
import { text } from "./text";
import { onDarkColors, onDarkGradients, onDarkShadows } from "./theme/onDark";
import {
  onLightColors,
  onLightGradients,
  onLightShadows,
} from "./theme/onLight";

export const fontFaceStylesImport = `@import url("https://biome.immutable.com/hosted-assets/css/im-fonts-v4.css");`;

const border: Borders = {
  size: {
    600: "6px",
    500: "5px",
    400: "4px",
    300: "3px",
    200: "2px",
    100: "1px",
  },
} as const;

const icon: Icons = {
  size: {
    600: "64px",
    500: "48px",
    400: "32px",
    300: "24px",
    250: "20px",
    200: "16px",
    100: "12px",
  },
} as const;

const zLevel: ZLevels = {
  tooltip: 12,
  toast: 11,
  popover: 10,
  modal: 9,
  drawer: 8,
} as const;

const frost: Frost = {
  100: "blur(16px)",
  200: "blur(24px)",
  300: "blur(48px)",
  400: "blur(56px)",
  500: "blur(64px)",
  600: "blur(112px)",
} as const;

const commonTokens = {
  spacing,
  borderRadius,
  font,
  text,
  breakpoint,
  icon,
  border,
  zLevel,
  motion,
  frost,
};

export const base: BaseTokens = {
  ...commonTokens,
  colorMode: "onLight",
  color: onLightColors,
  gradient: onLightGradients,
  shadow: onLightShadows,
};

export const onDarkBase: BaseTokens = {
  ...commonTokens,
  colorMode: "onDark",
  color: onDarkColors,
  gradient: onDarkGradients,
  shadow: onDarkShadows,
};
