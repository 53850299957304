import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { SmartClone } from "@/components/SmartClone";
import { PriceDisplay, type PriceDisplayProps } from "@/components/Text";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import {
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type { MakeResponsive, MenuItemSize } from "@/types";

import {
  useGetCaptionTextSizeFromMenuItemSize,
  useGetLabelTextSizeFromMenuItemSize,
} from "./shared";

export type MenuItemPriceDisplayProps = PriceDisplayProps<
  undefined,
  undefined
> & {
  menuItemSize?: MakeResponsive<MenuItemSize>;
};

export function MenuItemPriceDisplay({
  sx = {},
  size,
  menuItemSize = DEFAULT_MENU_ITEM_SIZE,
  price,
  children,
  use,
  className,
  ...props
}: MenuItemPriceDisplayProps) {
  const bodyTextSize = useGetLabelTextSizeFromMenuItemSize(menuItemSize, size);
  const caption = useGetSubcomponentChild(children, PriceDisplay.Caption);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    PriceDisplay.Caption,
  ]);
  const captionTextSize = useGetCaptionTextSizeFromMenuItemSize(
    menuItemSize,
    caption?.props.size,
  );
  const mergedSx = useMemo(() => merge({ whiteSpace: "nowrap" }, sx), [sx]);
  return (
    <PriceDisplay
      {...props}
      {...(!use && { size: bodyTextSize })}
      use={use}
      sx={mergedSx}
      price={price}
      className={`${className ?? ""} MenuItemPriceDisplay`}
    >
      {caption && <SmartClone size={captionTextSize}>{caption}</SmartClone>}
      {otherChildren}
    </PriceDisplay>
  );
}

MenuItemPriceDisplay.displayName = "MenuItem.PriceDisplay";
MenuItemPriceDisplay.CurrencyImage = PriceDisplay.CurrencyImage;
MenuItemPriceDisplay.Icon = PriceDisplay.Icon;
MenuItemPriceDisplay.Caption = PriceDisplay.Caption;
