import type { Text } from "../types";

export const text: Text = {
  body: {
    large: {
      regular: {
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 400,
      },
      bold: {
        fontWeight: 500,
      },
    },
    medium: {
      regular: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
      },
      bold: {
        fontWeight: 500,
      },
    },
    small: {
      regular: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
      },
      bold: {
        fontWeight: 500,
      },
    },
    xSmall: {
      regular: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
      },
      bold: {
        fontWeight: 500,
      },
    },
    xxSmall: {
      regular: {
        fontSize: "10px",
        lineHeight: "14px",
        fontWeight: 400,
      },
      bold: {
        fontWeight: 500,
      },
    },
  },
  caption: {
    medium: {
      regular: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        casing: "uppercase",
        letterSpacing: "2px",
      },
      bold: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 700,
        casing: "uppercase",
        letterSpacing: "2px",
      },
    },
    small: {
      regular: {
        fontSize: "10px",
        lineHeight: "14px",
        fontWeight: 400,
        casing: "uppercase",
        letterSpacing: "2px",
      },
      bold: {
        fontSize: "10px",
        lineHeight: "14px",
        fontWeight: 700,
        casing: "uppercase",
        letterSpacing: "2px",
      },
    },
  },
  heading: {
    xxLarge: {
      regular: {
        fontSize: "96px",
        lineHeight: "112px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
    xLarge: {
      regular: {
        fontSize: "64px",
        lineHeight: "72px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
    large: {
      regular: {
        fontSize: "40px",
        lineHeight: "48px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
    medium: {
      regular: {
        fontSize: "28px",
        lineHeight: "32px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
    small: {
      regular: {
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
    xSmall: {
      regular: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 600,
      },
      light: {
        fontWeight: 400,
      },
      bold: {
        fontWeight: 700,
      },
    },
  },
} as const;
