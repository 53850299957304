import type { BiomeTheme, DeeplyNestedSx } from "@/types";
import { hFlex, vFlex } from "@/utils";

export const baseContainerSx = {
  d: "flex",
  flexDirection: "column",
  flexShrink: 0,
  alignItems: "stretch",
  w: "100%",
  pos: "relative",
  brad: "base.borderRadius.x4",
  overflow: "hidden",
  boxShadow: "base.shadow.100",
  transitionProperty: "border, box-shadow, translate",

  "&::before, &::after": {
    content: '""',
    pos: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    brad: "base.borderRadius.x4",
    border: "solid transparent",
    borderWidth: "base.border.size.400",
    pointerEvents: "none",
    userSelect: "none",
    transitionProperty: "border-color",
  },

  "&::after": {
    borderWidth: "base.border.size.200",
  },

  "&.Card--selected::after": {
    borderColor: "base.color.translucent.standard.1000",
  },

  "&.Card--isClickable": {
    cursor: "pointer",
  },

  "& .AspectRatioImage, & .Video": {
    scale: "1.0",
    transformOrigin: "bottom center",
    transitionProperty: "scale",
  },

  "&.Card--isClickable:hover": {
    boxShadow: "base.shadow.300",
    translate: ({ base }: BiomeTheme) => `0 -${base.spacing.x2}`,

    "& .AspectRatioImage, & .Video": {
      scale: "1.05",
    },
  },

  "&.Card--isClickable:active": {
    boxShadow: "base.shadow.200",
    translate: ({ base }: BiomeTheme) => `0 -${base.spacing.x1}`,
  },

  "&.Card--elevation1": {
    bgc: "base.color.neutral.900",

    "&.Card--isClickable:hover": {
      bgc: "base.color.neutral.700",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.700",
      },
    },

    "&.Card--isClickable:active": {
      bgc: "base.color.neutral.800",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.800",
      },
    },

    "&.Card--selected&::before": {
      borderColor: "base.color.neutral.900",
    },
  },

  "&.Card--elevation2": {
    bgc: "base.color.neutral.800",

    "&.Card--isClickable:hover": {
      bgc: "base.color.neutral.600",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.600",
      },
    },

    "&.Card--isClickable:active": {
      bgc: "base.color.neutral.700",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.700",
      },
    },

    "&.Card--selected&::before": {
      borderColor: "base.color.neutral.800",
    },
  },

  "&.Card--elevation3": {
    bgc: "base.color.neutral.700",

    "&.Card--isClickable:hover": {
      bgc: "base.color.neutral.500",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.Card--isClickable:active": {
      bgc: "base.color.neutral.600",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.600",
      },
    },

    "&.Card--selected&::before": {
      borderColor: "base.color.neutral.700",
    },
  },

  "&.Card--elevation4": {
    bgc: "base.color.neutral.600",

    "&.Card--isClickable:hover": {
      bgc: "base.color.neutral.500",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.Card--isClickable:active": {
      bgc: "base.color.neutral.500",

      "&.Card--selected&::before": {
        borderColor: "base.color.neutral.500",
      },
    },

    "&.Card--selected&::before": {
      borderColor: "base.color.neutral.600",
    },
  },

  "&.Card--elevation5": {
    bgc: "base.color.neutral.500",

    "&.Card--selected&::before": {
      borderColor: "base.color.neutral.500",
    },
  },
};

export const baseFramedSx: DeeplyNestedSx = {
  d: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: ({ base }) => `-${base.spacing.x8}`,
  left: "base.spacing.x6",
  overflow: "hidden",
};

export const textContainerSx = {
  ...vFlex,
  pt: "base.spacing.x6",
  px: "base.spacing.x6",
  pb: "base.spacing.x6",
  pos: "relative",
};

export const buttonsContainerSx = {
  ...hFlex,
  gap: "base.spacing.x2",
  px: "base.spacing.x6",
  pb: "base.spacing.x8",
  alignItems: "center",
  mt: "auto",
};

export const framedLogoContainerSx = {
  ...baseFramedSx,
  aspectRatio: 1,
};

export const framedLogoSx = {
  width: "80%",
};
