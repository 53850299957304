import get from "lodash.get";

import type { DesignTokens } from "../types";

export const pickTokenValue = <T>(themeProps: DesignTokens, selector: string) =>
  get(themeProps, selector) as T;

export function smartPickTokenValue<T>(
  themeProps: DesignTokens,
  selector: string,
): T {
  let pickedToken = pickTokenValue<string | object>(themeProps, selector);
  if (typeof pickedToken === "string" && pickedToken.match(/^base./)) {
    pickedToken = smartPickTokenValue<string | object>(themeProps, pickedToken);
  }
  return pickedToken as T;
}
