export const COUNTDOWN_TIMER_SIZES = [
  "small",
  "medium",
  "large",
  "xLarge",
] as const;
export const DEFAULT_COUNTDOWN_TIMER_SIZE = COUNTDOWN_TIMER_SIZES[1];
export type CountdownTimerSize = (typeof COUNTDOWN_TIMER_SIZES)[number];

export const COUNTDOWN_TIMER_VARIANTS = ["primary", "inverse"] as const;
export const DEFAULT_COUNTDOWN_TIMER_VARIANT = COUNTDOWN_TIMER_VARIANTS[0];
export type CountdownTimerVariant = (typeof COUNTDOWN_TIMER_VARIANTS)[number];
