import type { ReactElement } from "react";

import { Button } from "@/components/Clickable";
import { useTheme } from "@/hooks";

import { shouldInvertButtonColoring } from "./styles";
import { type BannerRightButtonProps, DEFAULT_BANNER_VARIANT } from "./types";

export function BannerRightButton<
  RC extends ReactElement | undefined = undefined,
>({
  variant = "tertiary",
  bannerVariant = DEFAULT_BANNER_VARIANT,
  className,
  ...props
}: BannerRightButtonProps<RC>) {
  const theme = useTheme();
  const invertButtonColoring = shouldInvertButtonColoring(
    bannerVariant,
    theme,
    variant,
  );
  return (
    <Button
      {...props}
      size="small"
      variant={invertButtonColoring ? `${variant}/inverse` : variant}
      className={`${className ?? ""} BannerRightButton`}
    />
  );
}

BannerRightButton.displayName = "Banner.RightButton";
