import type { DistributiveOmit } from "@/types";
import { merge } from "ts-deepmerge";

import type { BaseClickableWithRCAndDomProps } from "@/types";
import { BaseClickable } from "../Clickable";
import { SmartClone } from "../SmartClone";
import { Body } from "../Text";
import { useTabsContext } from "./TabsContext";
import {
  baseTabSx,
  renderTabIconStyles,
  renderTabStyles,
  tabLabelBaseSx,
} from "./styles";
import type { IconReactElement } from "./types";

export type TabProps = DistributiveOmit<
  BaseClickableWithRCAndDomProps<undefined>,
  "rc"
> & {
  icon?: IconReactElement;
  value: string;
};

export function TabsTab({
  testId = "Tabs__Tab",
  value = "",
  disabled = false,
  children,
  icon,
  sx = {},
  className,
  ...props
}: TabProps) {
  const { currentTabValue, onTabChange } = useTabsContext();

  const isActive = currentTabValue === value;
  const tabIconStyles = icon ? renderTabIconStyles({ isActive, disabled }) : {};

  const allStyles = merge(
    baseTabSx,
    renderTabStyles({ isActive, disabled }),
    sx,
  );

  const onTabClick = disabled ? () => null : () => onTabChange(value);

  return (
    <BaseClickable
      {...props}
      testId={testId}
      onClick={onTabClick}
      sx={allStyles}
      className={`${className ?? ""} Tab`}
    >
      {icon && (
        <SmartClone
          sx={merge(tabIconStyles, icon.props?.sx ? icon.props.sx : {})}
        >
          {icon}
        </SmartClone>
      )}

      <Body sx={tabLabelBaseSx}>{children}</Body>
    </BaseClickable>
  );
}

TabsTab.displayName = "Tabs.Tab";
