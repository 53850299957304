import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { FormControl } from "@/components/FormControl";
import type { FormControlProps } from "@/types";

import { SUPPORTED_INPUT_COMPONENTS_MAP } from "./shared";

type InputListFormControlProps<
  RC extends ReactElement | undefined = undefined,
> = FormControlProps<RC>;

export function InputListFormControl<
  RC extends ReactElement | undefined = undefined,
>({
  rc,
  sx = {},
  testId = "InputListFormControl",
  textAlign,
  ...props
}: InputListFormControlProps<RC>) {
  return (
    <FormControl
      {...props}
      textAlign={textAlign}
      rc={rc}
      sx={merge(
        {
          py: "base.spacing.x2",
          ...(textAlign === "right"
            ? { ".FormControlLabel": { textAlign: "left" } }
            : {}),
        },
        sx,
      )}
      testId={testId}
    />
  );
}

InputListFormControl.displayName = "InputList.FormControl";

InputListFormControl.Label = FormControl.Label;
InputListFormControl.Caption = FormControl.Caption;
InputListFormControl.Validation = FormControl.Validation;
InputListFormControl.inputs = SUPPORTED_INPUT_COMPONENTS_MAP;
