import type { ReactElement } from "react";

import { useGetSubcomponentChildren } from "@/hooks/useGetSubcomponentChildren";
import { Box } from "../Box";
import { TabsProvider } from "./TabsContext";
import { TabsList } from "./TabsList";
import { TabsPanel } from "./TabsPanel";
import { TabsTab } from "./TabsTab";
import type { TabsProps } from "./types";

export function Tabs<RC extends ReactElement | undefined = undefined>(
  props: TabsProps<RC>,
) {
  const {
    currentTabValue,
    defaultTabValue,
    onTabChange,
    children,
    ...boxProps
  } =
    "currentTabValue" in props
      ? { ...props, defaultTabValue: null }
      : { ...props, currentTabValue: null };

  const tabsListSubcomponentChildren = useGetSubcomponentChildren(
    children,
    TabsList,
  );
  const tabsPanelSubcomponentChildren = useGetSubcomponentChildren(
    children,
    TabsPanel,
  );

  return (
    <TabsProvider value={{ currentTabValue, defaultTabValue, onTabChange }}>
      <Box {...boxProps}>
        {tabsListSubcomponentChildren}
        {tabsPanelSubcomponentChildren}
      </Box>
    </TabsProvider>
  );
}

Tabs.displayName = "Tabs";

Tabs.List = TabsList;
Tabs.Tab = TabsTab;
Tabs.Panel = TabsPanel;
