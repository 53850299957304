import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { CloudImage } from "@/components/CloudImage";
import type {
  AspectRatioBaseProps,
  BoxWithRCAndDomProps,
  CloudImageProps,
} from "@/types";
import { isImageResizerProps } from "@/utils";

import { dimVariantOverlaySx, mediaAssetBaseSx } from "./styles";
import type {
  HeroBackgroundMediaFrost,
  HeroBackgroundMediaVariant,
} from "./types";

export type HeroBackgroundImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = CloudImageProps<Use> &
  Omit<AspectRatioBaseProps, "aspectRatio" | "domRef"> &
  BoxWithRCAndDomProps<RC> & {
    frost?: HeroBackgroundMediaFrost;
    variant?: HeroBackgroundMediaVariant;
  };

export function HeroBackgroundImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  sx = {},
  objectFit = "cover",
  objectPosition = "center center",
  frost = "none",
  variant = "dim",
  testId = "HeroBackgroundImage",
  use,
  rc,
  className,
  domRef,
  // @NOTE: Native <img /> attr props:
  loading,
  fetchPriority,
  crossOrigin,
  decoding,
  useMap,
  referrerPolicy,
  alt,
  ...props
}: HeroBackgroundImageProps<RC, Use>) {
  const {
    responsiveSizes = [
      256, 450, 640, 860, 1024, 1440, 1920, 2048, 2560, 3072, 3456, 4096,
    ],
    imageUrl,
    relativeImageSizeInLayout,
    defaultImageUrl,
    defaultImageClassName,
    imageResizeServiceUrl,
    ...otherBoxProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        responsiveSizes: undefined,
        imageUrl: undefined,
        relativeImageSizeInLayout: undefined,
        defaultImageUrl: undefined,
        imageResizeServiceUrl: undefined,
        defaultImageClassName: undefined,
      };
  const mergedSx = useMemo(
    () =>
      merge(
        {
          pos: "relative",
          h: "100%",
          ...(variant === "dim" ? dimVariantOverlaySx : {}),
        },
        sx,
      ),
    [sx, variant],
  );
  const imageSx = useMemo(
    () =>
      merge(mediaAssetBaseSx, {
        objectFit,
        objectPosition,
        ...(frost !== "none" ? { filter: `base.frost.${frost}` } : {}),
      }),
    [frost, objectFit, objectPosition],
  );
  return (
    <Box
      {...otherBoxProps}
      sx={mergedSx}
      domRef={domRef}
      className={`${className ?? ""} HeroBackgroundImage`}
      rc={rc}
      testId={testId}
    >
      <CloudImage
        sx={imageSx}
        testId={`${testId}__image`}
        loading={loading}
        {...(use
          ? { use }
          : {
              imageUrl,
              responsiveSizes,
              relativeImageSizeInLayout,
              defaultImageUrl,
              defaultImageClassName,
              imageResizeServiceUrl,
              alt,
              loading,
              fetchPriority,
              crossOrigin,
              decoding,
              useMap,
              referrerPolicy,
            })}
      />
    </Box>
  );
}

HeroBackgroundImage.displayName = "HeroBackground.Image";
