import { Children, type ReactElement } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { SmartClone } from "@/components/SmartClone";
import { Body, EllipsizedText } from "@/components/Text";
import { useTheme } from "@/hooks";
import { isChildSubcomponent } from "@/utils";

import { shouldInvertTextColoring } from "./styles";
import { type BannerTextProps, DEFAULT_BANNER_VARIANT } from "./types";

export function BannerTitle<RC extends ReactElement | undefined = undefined>({
  children,
  size = "medium",
  weight = "bold",
  variant = DEFAULT_BANNER_VARIANT,
  className,
  sx = {},
  ...props
}: BannerTextProps<RC>) {
  const theme = useTheme();
  const invertColoring = shouldInvertTextColoring(variant, theme);
  const mergedSx = merge(
    {
      color: invertColoring
        ? "base.color.text.body.inverse.primary"
        : "base.color.text.body.primary",
    },
    sx,
  );
  const flattenedChildren = flattenChildren(children);
  return (
    <Body
      {...props}
      size={size}
      weight={weight}
      sx={mergedSx}
      className={`${className ?? ""} BannerTitle`}
    >
      {Children.map(flattenedChildren, (child) => {
        const childIsBiomeTextComponent =
          isChildSubcomponent(child, Body) ||
          isChildSubcomponent(child, EllipsizedText);
        if (childIsBiomeTextComponent && child.props) {
          const childProps = child.props as {
            size?: typeof size;
            weight?: typeof weight;
          };
          return (
            <SmartClone
              size={childProps.size ?? size}
              weight={childProps.weight ?? weight}
            >
              {child}
            </SmartClone>
          );
        }

        return child;
      })}
    </Body>
  );
}

BannerTitle.displayName = "Banner.Title";
