import type { BiomeTheme, LoadingOverlaySize, MakeResponsive } from "@/types";

import { getResponsiveSx } from "@/utils/styleHelpers";

export function getContainerSx({
  theme: { base },
  size,
}: {
  size: LoadingOverlaySize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        width: "180px",
        paddingY: base.spacing.x6,
      };
    case "medium":
    default:
      return {
        width: "220px",
        paddingY: base.spacing.x9,
      };
  }
}

export const getResponsiveContainerSx = ({
  ...props
}: {
  size: MakeResponsive<LoadingOverlaySize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerSx,
  });

export function getAnimationBoxSx({
  size,
  theme,
}: {
  size: LoadingOverlaySize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        width: theme.base.spacing.x12,
        height: theme.base.spacing.x12,
      };
    case "medium":
    default:
      return {
        width: theme.base.spacing.x16,
        height: theme.base.spacing.x16,
      };
  }
}

export const getResponsiveAnimationBoxSx = ({
  size,
  theme,
}: {
  size: MakeResponsive<LoadingOverlaySize>;
  theme: BiomeTheme;
}) => getResponsiveSx({ theme, size, renderSxAtSize: getAnimationBoxSx });
