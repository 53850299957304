import { useTheme } from "@emotion/react";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { APP_HEADER_BAR_SIZES, DEFAULT_APP_HEADER_BAR_SIZE } from "@/constants";
import {
  type GetHybridClickableRCandDomProps,
  type MakeResponsive,
  type OnClickFunction,
  isBaseClickable,
  isBox,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";
import { lineClamp } from "@/utils/sxChunks";

import { Box } from "../Box";
import { BaseClickable } from "../Clickable";
import type { AppHeaderBarContentAlign, AppHeaderBarSize } from "./shared";
import { renderResponsiveTitleSx, renderTitleSx } from "./styles";

export type AppHeaderBarTitleProps<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick> & {
  size?: MakeResponsive<AppHeaderBarSize>;
  contentAlign?: AppHeaderBarContentAlign;
};

export function AppHeaderBarTitle<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
>({
  size = DEFAULT_APP_HEADER_BAR_SIZE,
  sx = {},
  contentAlign,
  rc,
  className,
  ...otherProps
}: AppHeaderBarTitleProps<RC, OnClick>) {
  const { onClick } = "onClick" in otherProps ? otherProps : { onClick: null };
  const baseClickableProps = { onClick, rc, ...otherProps };
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_APP_HEADER_BAR_SIZE,
    APP_HEADER_BAR_SIZES,
  );
  const mergedSx = merge(
    { c: "base.color.text.heading.primary" },
    lineClamp(1),
    contentAlign === "left"
      ? {
          flex: 1,
        }
      : {
          left: 0,
          textAlign: "center",
          pos: "relative",
          w: "100%",
          px: "base.spacing.x5",
        },
    renderTitleSx({ theme: themeProps, size: startingSize }),
    renderResponsiveTitleSx({
      theme: themeProps,
      size,
    }),
    sx,
  );

  const mergedClassNames = `${className ?? ""} AppHeaderBarTitle`;
  return isBaseClickable(onClick, rc, false, baseClickableProps) ? (
    <BaseClickable
      {...baseClickableProps}
      sx={mergedSx}
      className={mergedClassNames}
    />
  ) : isBox(onClick, rc, false, otherProps) ? (
    <Box
      {...otherProps}
      rc={rc || <h1 />}
      sx={mergedSx}
      className={mergedClassNames}
    />
  ) : null;
}

AppHeaderBarTitle.displayName = "AppHeaderBar.Title";
