import type { Spacing } from "../types";

export const spacing: Spacing = {
  x1: "4px",
  x2: "8px",
  x3: "12px",
  x4: "16px",
  x5: "20px",
  x6: "24px",
  x7: "28px",
  x8: "32px",
  x9: "36px",
  x10: "40px",
  x11: "44px",
  x12: "48px",
  x13: "52px",
  x14: "56px",
  x15: "60px",
  x16: "64px",
  x17: "68px",
  x18: "72px",
  x19: "76px",
  x20: "80px",
  x21: "84px",
  x22: "88px",
  x23: "92px",
  x24: "96px",
  x25: "100px",
  x26: "104px",
  x27: "108px",
  x28: "112px",
  x29: "116px",
  x30: "120px",
  x31: "124px",
  x32: "128px",
  x33: "132px",
  x34: "136px",
  x35: "140px",
  x36: "144px",
  x37: "148px",
  x38: "152px",
  x39: "156px",
  x40: "160px",
  x41: "164px",
  x42: "168px",
  x43: "172px",
  x44: "176px",
  x45: "180px",
  x46: "184px",
  x47: "188px",
  x48: "192px",
  x49: "196px",
  x50: "200px",
} as const;
