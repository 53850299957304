import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_CARD_HERO_SIZE } from "@/constants";
import type { FramedLogoProps, MakeResponsive } from "@/types";

import { FramedLogo } from "../FramedThings";
import {
  type CardHeroSize,
  useGetFramedContentSizeFromHeroCardSize,
} from "./shared";
import { framedLogoContainerSx } from "./styles";

export type CardHeroFramedLogoProps<
  RC extends ReactElement | undefined = undefined,
> = FramedLogoProps<RC> & {
  cardHeroSize?: MakeResponsive<CardHeroSize>;
};

export function CardHeroFramedLogo<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  className,
  rc = <span />,
  cardHeroSize = DEFAULT_CARD_HERO_SIZE,
  size,
  ...props
}: CardHeroFramedLogoProps) {
  const mergedSx = useMemo(() => merge(framedLogoContainerSx, sx), [sx]);
  const defaultSize = useGetFramedContentSizeFromHeroCardSize(cardHeroSize);
  return (
    <FramedLogo
      {...props}
      sx={mergedSx}
      rc={rc}
      size={size ?? defaultSize}
      className={`${className ?? ""} CardHeroFramedLogo`}
    />
  );
}
CardHeroFramedLogo.displayName = "CardHero.FramedLogo";
