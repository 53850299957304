import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { CloudImage } from "@/components/CloudImage";
import { DEFAULT_CAROUSEL_THUMB_VARIANT } from "@/constants";
import { useForwardLocalDomRef } from "@/hooks";
import type { OptionalRelativeSizeCloudImageProps } from "@/types";
import { isImageResizerProps } from "@/utils";

import { horizontalThumbImageSx, verticalThumbImageSx } from "./styles";
import type { CarouselThumbVariants } from "./types";

type CarouselThumbnailThumbImageProps<Use extends ReactElement | undefined> =
  OptionalRelativeSizeCloudImageProps<Use> & {
    variant?: CarouselThumbVariants;
  };

export function CarouselThumbnailThumbImage<
  Use extends ReactElement | undefined,
>({
  sx = {},
  className,
  use,
  variant = DEFAULT_CAROUSEL_THUMB_VARIANT,
  domRef = { current: null },
  ...props
}: CarouselThumbnailThumbImageProps<Use>) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const {
    responsiveSizes = variant === "horizontal"
      ? [256, 370, 450, 512, 640]
      : [64, 72, 128, 256],
    relativeImageSizeInLayout,
    ...otherImageProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        relativeImageSizeInLayout: undefined,
        responsiveSizes: undefined,
      };
  const relativeImageSizeInLayoutToUse = useMemo(() => {
    if (relativeImageSizeInLayout) return relativeImageSizeInLayout;
    if (localDomRef.current) {
      // @TODO: ideally, info like this could be stored in a context,
      // and then accessed in any child component that needs it.
      // Right now, this prop is stored as a css-var in the root
      // element of the carousel, and accessed here
      const style = getComputedStyle(localDomRef.current);
      const thumbHeight = Number.parseInt(
        style.getPropertyValue("--thumbHeight"),
      );
      // @NOTE: 1.7777 is the aspect ratio of a 16:9 image, and 50px is the hardcoded
      // width of the vertical thumb image (as set in css)
      return variant === "horizontal" ? `${thumbHeight * 1.77777}px` : "50px";
    }
  }, [variant, localDomRef.current, relativeImageSizeInLayout]);
  return (
    <CloudImage
      {...otherImageProps}
      {...(use
        ? { use }
        : {
            responsiveSizes,
            relativeImageSizeInLayout: relativeImageSizeInLayoutToUse,
          })}
      sx={merge(
        {
          flexShrink: 0,
          objectFit: "cover",
          ...(variant === "horizontal"
            ? horizontalThumbImageSx
            : verticalThumbImageSx),
        },
        sx,
      )}
      domRef={localDomRef}
      className={`${className ?? ""} CarouselThumbnailThumbImage`}
    />
  );
}

CarouselThumbnailThumbImage.displayName = "CarouselThumbnail.Thumb.Image";
