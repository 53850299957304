import {
  Children,
  type ComponentType,
  type ReactElement,
  type ReactNode,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";

import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

function isChildPartOfSubcomponentCollection(
  child: ReactNode,
  subcomponents: ComponentType[],
): boolean {
  const isSub = subcomponents.some((component) => {
    return isChildSubcomponent(child, component);
  });
  return isSub;
}

/**
 * This function separates React children into subcomponents and other children based on a specified
 * subcomponent type.
 * @param {ReactNode} children - `children` is a prop that is passed to a React component and
 * represents the child elements that are nested within the component. It can be a single child element
 * or an array of child elements.
 * @param subcomponent - The `subcomponent` parameter is a React component type that is expected to be
 * a child of the `children` parameter. The function will split the `children` into two arrays: one
 * containing all the `subcomponent` instances, and the other containing all the other children.
 * @returns An object with two properties: `subComponents` and `otherChildren`. `subComponents` is an
 * array of React nodes that are subcomponents of the specified `subcomponent` type, and
 * `otherChildren` is an array of all other React nodes that are not subcomponents of the specified
 * `subcomponent` type.
 */
export function useSplitApartChildrenAndSubComponents(
  children: ReactNode,
  // biome-ignore lint/suspicious/noExplicitAny: the props type could really be anyting right now.
  subcomponents: ComponentType<any> | ComponentType<any>[],
) {
  let subcomponentsArray = subcomponents;
  if (!Array.isArray(subcomponents)) subcomponentsArray = [subcomponents];
  const splitSubComponents: ReactElement[] = [];
  const otherChildren: ReactNode[] = [];
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );

  Children.forEach(flattenedChildren, (child) => {
    if (
      isChildPartOfSubcomponentCollection(
        child,
        subcomponentsArray as ComponentType[],
      )
    ) {
      splitSubComponents.push(child as ReactElement);
    } else {
      otherChildren.push(child);
    }
  });

  return {
    subcomponents: splitSubComponents,
    otherChildren:
      otherChildren.length === 0
        ? null
        : otherChildren.length === 1
          ? otherChildren[0]
          : otherChildren,
  };
}

export function splitApartChildrenAndSubComponents(
  children: ReactNode,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  subcomponents: ComponentType<any> | ComponentType<any>[],
) {
  let subcomponentsArray = subcomponents;
  if (!Array.isArray(subcomponents)) subcomponentsArray = [subcomponents];
  const splitSubComponents: ReactElement[] = [];
  const otherChildren: ReactNode[] = [];
  const flattenedChildren = flattenChildren(children);

  Children.forEach(flattenedChildren, (child) => {
    if (
      isChildPartOfSubcomponentCollection(
        child,
        subcomponentsArray as ComponentType[],
      )
    ) {
      splitSubComponents.push(child as ReactElement);
    } else {
      otherChildren.push(child);
    }
  });

  return {
    subcomponents: splitSubComponents,
    otherChildren:
      otherChildren.length === 0
        ? null
        : otherChildren.length === 1
          ? otherChildren[0]
          : otherChildren,
  };
}
