import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { ButtCon, Button, Link } from "@/components/Clickable";
import {
  ExpandableMenuItem,
  InputBar,
  MenuItem,
} from "@/components/MenuItemRelatedComponents";
import { SmartClone } from "@/components/SmartClone";
import { Stack, type StackProps } from "@/components/Stack";
import { DEFAULT_APP_HEADER_BAR_SIZE } from "@/constants";
import type { MakeResponsive } from "@/types";
import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

import type { AppHeaderBarSize } from "./shared";

type AppHeaderBarSlotProps<RC extends ReactElement | undefined = undefined> =
  StackProps<RC> & {
    size?: MakeResponsive<AppHeaderBarSize>;
  };

function AppHeaderBarSlot<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_APP_HEADER_BAR_SIZE,
  children,
  sx = {},
  direction = "row",
  gap = "base.spacing.x2",
  alignItems = "center",
  ...props
}: AppHeaderBarSlotProps<RC>) {
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const mergedSx = useMemo(
    () => merge({ pos: "relative", zIndex: 1 }, sx),
    [sx],
  );
  return (
    <Stack
      {...props}
      direction={direction}
      gap={gap}
      alignItems={alignItems}
      sx={mergedSx}
    >
      {Children.map(flattenedChildren, (child) => {
        if (
          isChildSubcomponent(child, Button) ||
          isChildSubcomponent(child, ButtCon)
        ) {
          // @NOTE: render Button & ButtCon children,
          // with some special extra props
          return (
            <SmartClone
              variant={child.props.variant ?? "tertiary"}
              size={child.props.size ?? size}
            >
              {child}
            </SmartClone>
          );
        }

        // @NOTE: render MenuItem, ExpandableMenuItem & InputBar children,
        // with some special extra props
        if (
          isChildSubcomponent(child, MenuItem) ||
          isChildSubcomponent(child, ExpandableMenuItem) ||
          isChildSubcomponent(child, InputBar)
        ) {
          return (
            <SmartClone
              size={child.props.size ?? "xSmall"}
              emphasized={child.props.emphasized ?? true}
            >
              {child}
            </SmartClone>
          );
        }

        // @NOTE: render Link children, with some special extra props
        if (isChildSubcomponent(child, Link)) {
          return (
            <SmartClone variant={child.props.variant ?? "secondary"}>
              {child}
            </SmartClone>
          );
        }

        // @NOTE: Otherwise just render whatever the user has
        // passed in, with no extra props
        return child;
      })}
    </Stack>
  );
}

export function AppHeaderBarLeftSlot<
  RC extends ReactElement | undefined = undefined,
>({ className, ...props }: AppHeaderBarSlotProps<RC>) {
  return (
    <AppHeaderBarSlot
      {...props}
      className={`${className ?? ""} AppHeaderBarLeftSlot`}
    />
  );
}

export function AppHeaderBarRightSlot<
  RC extends ReactElement | undefined = undefined,
>({ className, ...props }: AppHeaderBarSlotProps<RC>) {
  return (
    <AppHeaderBarSlot
      {...props}
      className={`${className ?? ""} AppHeaderBarRightSlot`}
    />
  );
}

AppHeaderBarLeftSlot.displayName = "AppHeaderBar.LeftSlot";
AppHeaderBarRightSlot.displayName = "AppHeaderBar.RightSlot";
