import { type RefObject, useRef, useState } from "react";

import { useEventListener } from "./useEventListener";

/**
 * This is a TypeScript function that uses React hooks to detect when a mouse is hovering over a
 * specified HTML element.
 * @param [domRef] - A reference to the DOM element that you want to track hover events on. It is an
 * optional parameter and if not provided, the hook will track hover events on the entire document.
 * @param {number} [mouseLeaveDelay=0] - `mouseLeaveDelay` is an optional parameter that specifies the
 * delay (in milliseconds) before the `hovering` state is set to `false` after the mouse leaves the
 * element. If `mouseLeaveDelay` is not provided or is set to 0, the `hovering` state will
 * @returns The `useHover` function returns a boolean value indicating whether the element referenced
 * by the `domRef` is currently being hovered over by the mouse cursor.
 */
export function useHover<T extends HTMLElement | null = HTMLElement>(
  domRef?: RefObject<T>,
  mouseLeaveDelay = 0,
): boolean {
  const [hovering, setHovering] = useState<boolean>(false);
  const leaveTimer = useRef<number>(0);
  const handleMouseEnter = () => {
    clearTimeout(leaveTimer.current);
    setHovering(true);
  };
  const handleMouseLeave = () => {
    if (mouseLeaveDelay) {
      leaveTimer.current = window.setTimeout(() => {
        setHovering(false);
      }, mouseLeaveDelay);
    } else {
      setHovering(false);
    }
  };

  useEventListener("mouseenter", handleMouseEnter, domRef);
  useEventListener("mouseleave", handleMouseLeave, domRef);

  return hovering;
}
