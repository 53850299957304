import { useTheme as useEmotionTheme } from "@emotion/react";

import type { BiomeTheme } from "../types";

/**
 * This function returns the current theme as a BiomeTheme object using the useEmotionTheme hook.
 * @returns The `useTheme` function is returning the `theme` object as a `BiomeTheme`.
 */
export function useTheme() {
  const theme = useEmotionTheme();
  return theme as BiomeTheme;
}
