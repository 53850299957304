import type { BiomeTheme, MakeResponsive, TooltipSize } from "@/types";
import { getBodyTextStyles } from "@/utils";
import { getResponsiveSx } from "../../utils/styleHelpers";

export const baseContainerSx = {
  position: "absolute",
  maxw: "370px",
  brad: "base.borderRadius.x4",
  boxShadow: "base.shadow.500",
  background: "base.color.neutral.500",
  textAlign: "center",
};

export function getContainerStyles({
  size,
  theme,
}: {
  size: TooltipSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        ...getBodyTextStyles({
          size: "xxSmall",
          mono: false,
          themeProps: theme,
          weight: "regular",
        }),
        paddingY: theme.base.spacing.x1,
        paddingX: theme.base.spacing.x2,
      };

    case "medium":
      return {
        ...getBodyTextStyles({
          size: "small",
          mono: false,
          themeProps: theme,
          weight: "regular",
        }),
        paddingY: theme.base.spacing.x1,
        paddingX: theme.base.spacing.x3,
      };

    case "large":
      return {
        ...getBodyTextStyles({
          size: "small",
          mono: false,
          themeProps: theme,
          weight: "regular",
        }),
        paddingY: theme.base.spacing.x2,
        paddingX: theme.base.spacing.x4,
      };
  }
}

export const responsiveContainerStyles = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<TooltipSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerStyles,
  });
