import type { ReactElement } from "react";

import { Logo } from "@/components/Logo";
import { DEFAULT_IMAGE_SIZE_VARIANT } from "@/constants";
import type { FramedLogoProps } from "@/types";
import { FramedContainer } from "../shared/FramedContainer";
import { innerMediaElementSx } from "../shared/styles";

export function FramedLogo<RC extends ReactElement | undefined = undefined>({
  circularFrame = false,
  emphasized = false,
  padded = false,
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  sx = {},
  className,
  domRef,
  rc,
  testId = "FramedLogo",
  motionProfile,
  ...logoProps
}: FramedLogoProps<RC>) {
  return (
    <FramedContainer
      sx={sx}
      testId={testId}
      className={`${className ?? ""} FramedLogo`}
      rc={rc}
      domRef={domRef}
      padded={padded}
      circularFrame={circularFrame}
      emphasized={emphasized}
      size={size}
      motionProfile={motionProfile}
    >
      <Logo {...logoProps} sx={innerMediaElementSx} />
    </FramedContainer>
  );
}

FramedLogo.displayName = "FramedLogo";
