import type { Properties } from "csstype";

export const containerSx = {
  w: "base.spacing.x12",
  h: "base.spacing.x7",
  pos: "relative",
  d: "flex",
  alignItems: "center",
  justifyContent: "flexStart",

  "&:disabled + .toggleContainer": {
    boxShadow: "none",
  },

  "input:hover:not(:disabled) + .toggleContainer": {
    boxShadow: "base.shadow.300",
    backgroundColor: "base.color.translucent.emphasis.200",
  },

  "input:hover:not(:disabled) + .toggleContainer:before": {
    borderColor: "base.color.translucent.standard.800",
  },

  "input:hover:not(:disabled):not(checked) + .toggleContainer > .toggleHandle":
    {
      translate: "5px 0",
      boxShadow: "base.shadow.300",
    },

  // Checked state styles:
  "input:checked + .toggleContainer": {
    background: "base.color.status.success.bright",
    boxShadow: "none",
  },

  "input:checked": {
    "& + .toggleContainer::before": {
      borderColor: "base.color.translucent.standard.1000",
      borderWidth: "base.border.size.200",
    },
    "& + .toggleContainer::after": {
      content: '""',
    },
  },

  "input:checked:hover:not(:disabled) + .toggleContainer": {
    background: "base.color.status.success.bright",
    boxShadow: "base.shadow.200",
  },

  "input:checked:not(:disabled):hover": {
    "& + .toggleContainer::before": {
      borderColor: "base.color.translucent.standard.1000",
      borderWidth: "base.border.size.100",
    },
    "& + .toggleContainer::after": {
      top: "1px",
      left: "1px",
      w: "calc(100% - 2px)",
      h: "calc(100% - 2px)",
    },
  },

  "input:checked + .toggleContainer > .toggleHandle": {
    translate: "23px 0",
    bg: "base.color.fixed.black.1000",
  },

  "input:checked + .toggleContainer > .toggleHandle > .toggleHandle__tickIcon":
    {
      opacity: "1",
    },

  "input:hover:checked:not(:disabled) + .toggleContainer > .toggleHandle": {
    translate: "21px 0",
  },
};

export const toggleContainerSx = {
  pos: "relative",
  pointerEvents: "none",
  w: "base.spacing.x12",
  h: "base.spacing.x7",
  borderRadius: "base.borderRadius.x12",
  bg: "base.color.translucent.emphasis.100",
  d: "flex",
  alignItems: "center",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",
  transitionProperty: "all",

  "&::before": {
    content: '""',
    pos: "absolute",
    top: "0",
    left: "0",
    w: "100%",
    h: "100%",
    brad: "base.borderRadius.x12",
    borderWidth: "base.border.size.100",
    borderStyle: "solid",
    borderColor: "base.color.translucent.standard.500",
  },

  "&::after": {
    pos: "absolute",
    top: "2px",
    left: "2px",
    w: "calc(100% - 4px)",
    h: "calc(100% - 4px)",
    brad: "base.borderRadius.x12",
    borderWidth: "base.border.size.100",
    borderStyle: "solid",
    borderColor: "base.color.neutral.1000",
  },
};

export const inputCss = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
} satisfies Properties;

export const handleSx = {
  w: "20px",
  h: "20px",
  d: "flex",
  pos: "relative",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "base.color.translucent.standard.1000",
  boxShadow: "base.shadow.100",
  transitionDuration: "base.motion.normal.fast.cssDuration",
  transitionTimingFunction: "base.motion.normal.fast.cssEase",
  transitionProperty: "translate, box-shadow",
  pointerEvents: "none",
  translate: "3px 0",
};

export const tickSx = {
  fill: "base.color.fixed.white.1000",
  opacity: "0",
};
