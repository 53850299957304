import type { BiomeTheme, MakeResponsive } from "@/types";
import { getResponsiveSx, hFlex } from "@/utils";
import { getHeadingTextStyles } from "@/utils/textStyleHelpers";

import type { AppHeaderBarSize } from "./shared";

export const baseInnerContainerSx = {
  ...hFlex,
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "base.borderRadius.x10",
  position: "relative",
  gap: "base.spacing.x6",
};

export function getOuterContainerSx({
  size,
}: { size: AppHeaderBarSize; theme?: BiomeTheme }) {
  switch (size) {
    case "small":
      return {
        // @TODO: This value is TBC
        height: "80px",
      };

    case "medium":
    default:
      return {
        height: "80px",
      };
  }
}

export const responsiveOuterContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<AppHeaderBarSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getOuterContainerSx,
  });

export function innerContainerSx({
  size,
  theme,
}: {
  size: AppHeaderBarSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return {
        paddingX: theme.base.spacing.x2,
        paddingY: theme.base.spacing.x1,
        // @TODO: This value is TBC
        minHeight: "64px",
      };

    case "medium":
    default:
      return {
        paddingX: theme.base.spacing.x6,
        paddingY: theme.base.spacing.x2,
        minHeight: "64px",
      };
  }
}

export const responsiveInnerContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<AppHeaderBarSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: innerContainerSx,
  });

export function renderTitleSx({
  size,
  theme,
}: {
  size: AppHeaderBarSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return getHeadingTextStyles({
        themeProps: theme,
        size: "xSmall",
        weight: "bold",
      });

    case "medium":
    default:
      return getHeadingTextStyles({
        themeProps: theme,
        size: "medium",
        weight: "bold",
      });
  }
}

export const renderResponsiveTitleSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<AppHeaderBarSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: renderTitleSx,
  });

export function renderTitleLogoSx({
  size,
  theme,
}: {
  size: AppHeaderBarSize;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return { height: theme.base.spacing.x8 };

    case "medium":
    default:
      return { height: theme.base.spacing.x12 };
  }
}

export const renderResponsiveTitleLogoSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<AppHeaderBarSize>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: renderTitleLogoSx,
  });
