import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import type { BaseClickableWithRCAndDomProps } from "@/types";

import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import { cloneElementWithCssProp } from "@/utils";

export function BaseClickable<RC extends ReactElement | undefined = undefined>({
  rc = <button type="button" />,
  testId,
  sx = {},
  children,
  domRef,
  className,
  ...props
}: BaseClickableWithRCAndDomProps<RC>) {
  const { onClick, ...otherProps } =
    "onClick" in props ? props : { ...props, onClick: null };

  const mergedSx = useMemo(
    () =>
      merge(
        {
          // @NOTE Some basic reset styles:
          padding: 0,
          border: "none",
          background: "transparent",
          display: "inline-flex",
          alignItems: "baseline",
          cursor: "pointer",
          textDecoration: "none",
        },
        sx,
      ),
    [sx],
  );
  const convertedCss = useConvertSxToEmotionStyles(mergedSx);
  const mergedClassNames = `${className ?? ""} ${rc.props.className ?? ""} BaseClickable`;

  return cloneElementWithCssProp(rc, {
    ...otherProps,
    ...(testId ? { "data-testid": testId } : {}),
    ...(domRef ? { ref: domRef } : {}),
    ...(onClick ? { onClick } : {}),
    className: mergedClassNames,
    css: convertedCss,
    children,
  });
}

BaseClickable.displayName = "BaseClickable";
