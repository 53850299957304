import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import { DEFAULT_LINK_SIZE, DEFAULT_LINK_WEIGHT } from "@/constants";
import { useTheme } from "@/hooks";
import type { LinkProps } from "@/types";
import { cloneElementWithCssProp } from "@/utils";
import { isChildSubcomponent } from "@/utils/subcomponentHelpers";

import { SmartClone } from "../../SmartClone";
import { BaseClickable } from "../BaseClickable";
import { LinkIcon } from "./LinkIcon";
import { innerContainerSx, linkBaseSx } from "./styles";

export function Link<
  RC extends ReactElement | undefined,
  Use extends ReactElement | undefined = undefined,
>({
  variant = "primary",
  sx = {},
  children,
  testId = "Link",
  // @NOTE: we default Link to be an <a> because links need to be inline text
  // (so that they can wrap elegantly when the text is too long to fit on 1 line),
  // and you cannot set <button> to be fully inline. :(
  // https://stackoverflow.com/questions/27764419/why-does-width-apply-to-a-button-with-display-inline
  // biome-ignore lint/a11y/useValidAnchor: <explanation>
  rc = <a />,
  className,
  ...props
}: LinkProps<RC, Use>) {
  const { use, ...propsMinusUse } =
    "use" in props ? props : { ...props, use: undefined };
  const { size, ...propsMinusUseSize } =
    "size" in propsMinusUse
      ? propsMinusUse
      : { ...propsMinusUse, size: DEFAULT_LINK_SIZE };
  const { weight, ...propsMinusUseSizeWeight } =
    "weight" in propsMinusUseSize
      ? propsMinusUseSize
      : { ...propsMinusUseSize, weight: DEFAULT_LINK_WEIGHT };

  const theme = useTheme();
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const hasWeightProp =
    (props as LinkProps<undefined, undefined>).weight !== undefined;

  const textContent = useMemo(
    () =>
      cloneElementWithCssProp(
        use || <Body size={size as BodySize} weight={weight as BodyWeight} />,
        {
          testId: `${testId}__innerContainer`,
          className: `Link__innerContainer ${
            props?.size ? "Link__innerContainer__text--explicitSize" : ""
          } ${
            hasWeightProp ? "Link__innerContainer__text--explicitWeight" : ""
          }`,
          sx: innerContainerSx,
          children: Children.map(flattenedChildren, (child) => {
            if (isChildSubcomponent(child, LinkIcon)) {
              return (
                <SmartClone
                  testId={
                    child.props.testId ?? `${testId}__innerContainer__icon`
                  }
                >
                  {child}
                </SmartClone>
              );
            }

            return <span className="Link__innerContainer__text">{child}</span>;
          }),
        },
      ),
    [flattenedChildren, testId, use, weight, size, props.size, hasWeightProp],
  );
  const mergedSx = useMemo(
    () =>
      merge(
        linkBaseSx,
        { c: "var(--linkColor)" },
        theme.components?.Link?.sxOverride ?? {},
        sx,
      ),
    [sx, theme.components?.Link?.sxOverride],
  );

  return (
    <BaseClickable
      {...propsMinusUseSizeWeight}
      sx={mergedSx}
      rc={rc}
      testId={testId}
      className={`${className ?? ""} Link Link--${variant}`}
    >
      {textContent}
    </BaseClickable>
  );
}

Link.displayName = "Link";
Link.Icon = LinkIcon;
