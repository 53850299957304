// @NOTE:
// Due to they way that these components all interact with each other,
// it is not possible to split them into seperate files. Thus, they are all
// in this file ... :(

import { ClassNames, type DistributiveOmit } from "@emotion/react";
import { AnimatePresence, motion } from "motion/react";
import {
  type ChangeEvent,
  Children,
  type MouseEvent,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
  type Ref,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import {
  DEFAULT_BUTTON_SIZE,
  DEFAULT_DRAWER_POSITION,
  DEFAULT_MENU_ITEM_SIZE,
  DEFAULT_SELECT_SIZE,
  DEFAULT_TEXT_INPUT_ALIGN,
  DEFAULT_TEXT_INPUT_SIZE,
  MENU_ITEM_SIZES,
  SELECT_SIZES,
  TEXT_INPUT_SIZES,
} from "@/constants";
import {
  useBrowserEffect,
  useBrowserLayoutEffect,
  useForwardLocalDomRef,
  useGetCurrentSizeClass,
  useGetMotionProfile,
  useResizeObserver,
} from "@/hooks";
import { useGetSubcomponentChild } from "@/hooks/useGetSubcompnentChild";
import { useGetSubcomponentChildren } from "@/hooks/useGetSubcomponentChildren";
import { useSplitApartChildrenAndSubComponents } from "@/hooks/useSplitApartChildrenAndSubComponents";
import { useTheme } from "@/hooks/useTheme";
import { useWindowSizeStore } from "@/providers";
import {
  type DeeplyNestedSx,
  type DomPropsWithDomRef,
  type InputTextAlign,
  type MakeResponsive,
  type MenuItemContentProps,
  type MenuItemProps,
  type MenuItemSize,
  type MotionProfile,
  type MultiSelectOptionProps,
  type MultiSelectProps,
  type OnClickFunction,
  type OnSelectOptionClick,
  type OptionKey,
  type OverflowDrawerMenuProps,
  type OverflowPopoverMenuProps,
  type SelectOptionProps,
  type SelectProps,
  type StandardComponentWithProps,
  type StandardInputComponentWithProps,
  type VerticalMenuProps,
  type WithDrawerDisriminatedProps,
  isBaseClickable,
  isBox,
  isDualVariantIcon,
} from "@/types";
import { childHasTestIdProp, noop, warnUser } from "@/utils/componentHelpers";
import { getStartingSize } from "@/utils/styleHelpers";
import {
  getSubcomponentChild,
  isChildSubcomponent,
} from "@/utils/subcomponentHelpers";
import { vFlex } from "@/utils/sxChunks";

import { useGetManySingleSubcomponentChildren } from "@/hooks/useGetManySingleSubcomponentChildren";
import { getMotionProfileSx } from "@/utils";
import { Box } from "../Box";
import { Checkbox } from "../Checkbox";
import {
  Chip,
  type ChipAvatarProps,
  type ChipFramedImageProps,
  type ChipIconProps,
} from "../Chip";
import { BaseClickable, ButtCon } from "../Clickable";
import { Drawer } from "../Drawer";
import { Popover } from "../Popover";
import { Radio } from "../Radio";
import { SmartClone } from "../SmartClone";
import { Stack } from "../Stack";
import { SvgIcon } from "../SvgIcon";
import { Body } from "../Text";
import {
  baseInputSx,
  inputContainerBaseSx,
  inputIconBaseSx,
  renderContainerStyles,
  renderInputSx,
  renderResponsiveContainerStyles,
  renderResponsiveInputSx,
} from "../TextInput/styles";
import { Toggle } from "../Toggle";
import {
  containerSx,
  nestedVerticalMenuSx,
  renderContainerResponsiveStyles,
  renderSizeBasedContainerStyles,
  rightChevronSx,
} from "./ExpandableMenuItem/style";
import { inputBarBaseSx } from "./InputBar/styles";
import { MenuItemAvatar } from "./MenuItem/MenuItemAvatar";
import { MenuItemBadge } from "./MenuItem/MenuItemBadge";
import { MenuItemBottomSlot } from "./MenuItem/MenuItemBottomSlot";
import { MenuItemButton } from "./MenuItem/MenuItemButton";
import { MenuItemCaption } from "./MenuItem/MenuItemCaption";
import { MenuItemFramedIcon } from "./MenuItem/MenuItemFramedIcon";
import { MenuItemFramedImage } from "./MenuItem/MenuItemFramedImage";
import { MenuItemFramedLogo } from "./MenuItem/MenuItemFramedLogo";
import { MenuItemFramedStack } from "./MenuItem/MenuItemFramedStack";
import { MenuItemFramedVideo } from "./MenuItem/MenuItemFramedVideo";
import { MenuItemIcon } from "./MenuItem/MenuItemIcon";
import { MenuItemIntentIcon } from "./MenuItem/MenuItemIntentIcon";
import { MenuItemLabel } from "./MenuItem/MenuItemLabel";
import { MenuItemPriceDisplay } from "./MenuItem/MenuItemPriceDisplay";
import { MenuItemShimmer } from "./MenuItem/MenuItemShimmer";
import { MenuItemStatefulButtCon } from "./MenuItem/MenuItemStatefulButtCon";
import { MenuItemSwapStack } from "./MenuItem/MenuItemSwapStack";
import {
  baseContainerStyles,
  innerTextContainerSxProps,
  renderResponsiveStyles,
  renderSizeBasedStyles,
} from "./MenuItem/styles";
import { MultiSelectIcon } from "./MultiSelect/MultiSelectIcon";
import { createMultiSelectErrorMessage } from "./MultiSelect/shared";
import {
  baseChipsContainerSx,
  baseMultiSelectSx,
  chipBaseSx,
  defaultLabelSx,
  multiSelectOptionSx,
} from "./MultiSelect/styles";
import { SelectDefaultFramedImage } from "./Select/SelectDefaultFramedImage";
import { SelectDefaultFramedLogo } from "./Select/SelectDefaultFramedLogo";
import { SelectDefaultIcon } from "./Select/SelectDefaultIcon";
import {
  noOptionFoundErrorText,
  pickAnOption,
  useGetControlledContent,
} from "./Select/shared";
import {
  baseSelectSx,
  getResponsiveSelectCustomSx,
  getSelectCustomSx,
  selectChevronSx,
} from "./Select/styles";
import { VerticalMenuDivider } from "./VerticalMenu/VerticalMenuDivider";
import { verticalMenuSxProps } from "./VerticalMenu/styles";

/*








      MENU ITEM








*/
const INNER_BOX_RC = <span />;
const THINGS_WHICH_NEST_MENU_ITEMS = [
  MenuItemOverflowPopoverMenu,
  MenuItemOverflowDrawerMenu,
  MenuItemBottomSlot,
];
function MenuItemContent<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
>({
  testId,
  className,
  children,
  emphasized,
  selected,
  sx = {},
  rc,
  size = DEFAULT_MENU_ITEM_SIZE,
  controlledHover,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  motionProfile,
  ...otherProps
}: MenuItemContentProps<RC, OnClick>) {
  const { onClick } =
    "onClick" in otherProps ? otherProps : { ...otherProps, onClick: null };
  const theme = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const mergedContainerSx = useMemo(
    () =>
      merge(
        baseContainerStyles,
        renderSizeBasedStyles({ size: startingSize, theme }),
        renderResponsiveStyles({
          size,
          theme,
        }),
        getMotionProfileSx(motionProfileToUse, theme),
        sx,
      ),
    [size, startingSize, theme, sx, motionProfileToUse],
  );

  const {
    subcomponents: [
      icon,
      framedIcon,
      framedImage,
      framedLogo,
      framedVideo,
      intentIcon,
      priceDisplay,
      popoverMenu,
      drawerMenu,
      swapStack,
      framedStack,
      avatar,
      bottomSlot,
      button,
      label,
      caption,
    ],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    {
      subcomponent: MenuItemIcon,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemFramedIcon,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemFramedImage,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemFramedLogo,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemFramedVideo,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemIntentIcon,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemPriceDisplay,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemOverflowPopoverMenu,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemOverflowDrawerMenu,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemSwapStack,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemFramedStack,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemAvatar,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemBottomSlot,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemButton,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemLabel,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
    {
      subcomponent: MenuItemCaption,
      excludeParent: THINGS_WHICH_NEST_MENU_ITEMS,
    },
  ]);

  const badges = useGetSubcomponentChildren(otherChildren, MenuItemBadge);
  const statefulButtCons = useGetSubcomponentChildren(
    otherChildren,
    MenuItemStatefulButtCon,
  );
  const { otherChildren: nonSubcomponentChildren } =
    useSplitApartChildrenAndSubComponents(otherChildren, [
      MenuItemStatefulButtCon,
      MenuItemBadge,
    ]);

  const baseClickableProps = { ...otherProps, onClick, rc };
  const boxProps = { ...otherProps, onClick, rc };
  const containsClickables =
    Boolean(Array.isArray(statefulButtCons) && statefulButtCons.length) ||
    Boolean(button) ||
    Boolean(popoverMenu) ||
    Boolean(drawerMenu);
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );
  const [isHovering, setIsHovering] = useState<boolean>(false);
  useEffect(() => {
    if (typeof controlledHover !== "undefined") setIsHovering(controlledHover);
  }, [controlledHover]);
  const leftImageContent = useMemo(() => {
    switch (true) {
      case Boolean(avatar):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(avatar)
                ? avatar.props.testId
                : `${testId}__avatar`
            }
            size={size}
          >
            {avatar}
          </SmartClone>
        );

      case Boolean(framedIcon):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(framedIcon)
                ? framedIcon.props.testId
                : `${testId}__framedIcon`
            }
            size={size}
            sx={{ alignSelf: "center" }}
          >
            {framedIcon}
          </SmartClone>
        );

      case Boolean(framedImage):
        return (
          <SmartClone
            size={size}
            testId={
              childHasTestIdProp(framedImage)
                ? framedImage.props.testId
                : `${testId}__framedImage`
            }
          >
            {framedImage}
          </SmartClone>
        );

      case Boolean(framedLogo):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(framedLogo)
                ? framedLogo.props.testId
                : `${testId}__framedLogo`
            }
            size={size}
            sx={{ alignSelf: "center" }}
          >
            {framedLogo}
          </SmartClone>
        );

      case Boolean(framedStack):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(framedStack)
                ? framedStack.props.testId
                : `${testId}__framedStack`
            }
            size={size}
          >
            {framedStack}
          </SmartClone>
        );

      case Boolean(icon):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(icon) ? icon.props.testId : `${testId}__icon`
            }
          >
            {icon}
          </SmartClone>
        );

      case Boolean(swapStack):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(swapStack)
                ? swapStack.props.testId
                : `${testId}__swapStack`
            }
            size={size}
          >
            {swapStack}
          </SmartClone>
        );

      case Boolean(framedVideo):
        return (
          <SmartClone
            testId={
              childHasTestIdProp(framedVideo)
                ? framedVideo.props.testId
                : `${testId}__framedVideo`
            }
            size={size}
          >
            {framedVideo}
          </SmartClone>
        );

      default:
        return null;
    }
  }, [
    avatar,
    framedLogo,
    framedImage,
    framedIcon,
    framedVideo,
    icon,
    framedStack,
    swapStack,
    size,
    testId,
  ]);

  const innerContents = useMemo(
    () => (
      <>
        <Stack
          rc={INNER_BOX_RC}
          direction="row"
          sx={{ alignSelf: "stretch" }}
          className="innerContainer"
        >
          {leftImageContent}

          <Box
            rc={INNER_BOX_RC}
            sx={merge(innerTextContainerSxProps, {
              alignItems: textAlign === "left" ? "flex-start" : "flex-end",
            })}
            className="innerTextContainer"
          >
            {label && (
              <SmartClone
                testId={
                  childHasTestIdProp(label)
                    ? label.props.testId
                    : `${testId}__label`
                }
                menuItemSize={size}
              >
                {label}
              </SmartClone>
            )}

            {caption && (
              <SmartClone
                testId={
                  childHasTestIdProp(caption)
                    ? caption.props.testId
                    : `${testId}__caption`
                }
                menuItemSize={size}
              >
                {caption}
              </SmartClone>
            )}
          </Box>

          {priceDisplay ||
          statefulButtCons ||
          button ||
          drawerMenu ||
          popoverMenu ||
          badges ||
          intentIcon ||
          nonSubcomponentChildren ? (
            <Stack
              direction="row"
              alignItems="center"
              gap="base.spacing.x2"
              sx={{
                flexShrink: 0,
              }}
              rc={INNER_BOX_RC}
              className="rightContainer"
            >
              {priceDisplay && (
                <SmartClone
                  testId={
                    childHasTestIdProp(priceDisplay)
                      ? priceDisplay.props.testId
                      : `${testId}__priceDisplay`
                  }
                  menuItemSize={size}
                >
                  {priceDisplay}
                </SmartClone>
              )}

              {button ? (
                <SmartClone
                  testId={
                    childHasTestIdProp(button)
                      ? button.props.testId
                      : `${testId}__button`
                  }
                >
                  {button}
                </SmartClone>
              ) : null}

              {statefulButtCons?.map((statefulButtCon, index) => (
                <SmartClone
                  key={`statefulButtCon-${
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    index
                  }`}
                  testId={
                    statefulButtCon.props.testId || `${testId}__statefulButtCon`
                  }
                >
                  {statefulButtCon}
                </SmartClone>
              ))}

              {drawerMenu ? (
                <SmartClone
                  testId={
                    childHasTestIdProp(drawerMenu)
                      ? drawerMenu.props.testId
                      : `${testId}__drawerMenu`
                  }
                >
                  {drawerMenu}
                </SmartClone>
              ) : popoverMenu ? (
                <SmartClone
                  testId={
                    childHasTestIdProp(popoverMenu)
                      ? popoverMenu.props.testId
                      : `${testId}__popoverMenu`
                  }
                >
                  {popoverMenu}
                </SmartClone>
              ) : null}

              {(badges || intentIcon || nonSubcomponentChildren) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="base.spacing.x1"
                  rc={INNER_BOX_RC}
                >
                  {badges?.map((badge, index) => (
                    <SmartClone
                      key={`badge-${
                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                        index
                      }`}
                      testId={
                        childHasTestIdProp(badge)
                          ? badge.props.testId
                          : `${testId}__badge`
                      }
                    >
                      {badge}
                    </SmartClone>
                  ))}

                  {nonSubcomponentChildren}

                  {intentIcon && (
                    <SmartClone
                      testId={
                        childHasTestIdProp(intentIcon)
                          ? intentIcon.props.testId
                          : `${testId}__intentIcon`
                      }
                    >
                      {intentIcon}
                    </SmartClone>
                  )}
                </Stack>
              )}
            </Stack>
          ) : null}
        </Stack>

        {bottomSlot ? (
          <SmartClone selected={selected} size={size}>
            {bottomSlot}
          </SmartClone>
        ) : null}
      </>
    ),
    [
      intentIcon,
      badges,
      priceDisplay,
      statefulButtCons,
      button,
      leftImageContent,
      testId,
      drawerMenu,
      caption,
      label,
      size,
      popoverMenu,
      textAlign,
      bottomSlot,
      selected,
      nonSubcomponentChildren,
    ],
  );

  const isClickable = isBaseClickable(
    onClick,
    rc,
    containsClickables,
    baseClickableProps,
  );

  return (
    <ClassNames>
      {({ cx }) => {
        const commonProps = {
          className: cx(className, "MenuItem", `MenuItem--${sizeClass}`, {
            "MenuItem--selected": selected,
            "MenuItem--emphasized": emphasized,
            "MenuItem--hover": isHovering,
            "MenuItem--hasWholeOnClick": isClickable || Boolean(onClick),
          }),
          testId,
          sx: mergedContainerSx,
          ...(typeof controlledHover === "undefined"
            ? {
                onMouseEnter: () => setIsHovering(true),
                onMouseLeave: () => setIsHovering(false),
              }
            : {}),
        };

        if (isClickable) {
          return (
            <BaseClickable {...baseClickableProps} {...commonProps}>
              {innerContents}
            </BaseClickable>
          );
        }

        return isBox(onClick, rc, containsClickables, boxProps) ? (
          <Box {...boxProps} {...commonProps}>
            {innerContents}
          </Box>
        ) : null;
      }}
    </ClassNames>
  );
}

export function MenuItem<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
>({
  shimmer = false,
  size = DEFAULT_MENU_ITEM_SIZE,
  rc,
  testId = "MenuItem",
  className,
  children,
  emphasized,
  selected,
  sx = {},
  shimmerSx = {},
  controlledHover,
  textAlign,
  ...otherProps
}: MenuItemProps<RC, OnClick>) {
  return shimmer ? (
    <MenuItemShimmer
      {...(typeof shimmer === "string" ? { shimmerType: shimmer } : {})}
      size={size}
      rc={rc}
      testId={`${testId}__shimmer`}
      className={className}
      emphasized={emphasized}
      sx={shimmerSx}
    />
  ) : (
    <MenuItemContent
      {...otherProps}
      controlledHover={controlledHover}
      rc={rc}
      size={size}
      testId={testId}
      className={className}
      emphasized={emphasized}
      selected={selected}
      sx={sx}
      textAlign={textAlign}
    >
      {children}
    </MenuItemContent>
  );
}

MenuItem.displayName = "MenuItem";
MenuItem.Icon = MenuItemIcon;
MenuItem.FramedIcon = MenuItemFramedIcon;
MenuItem.FramedImage = MenuItemFramedImage;
MenuItem.FramedLogo = MenuItemFramedLogo;
MenuItem.FramedVideo = MenuItemFramedVideo;
MenuItem.Label = MenuItemLabel;
MenuItem.IntentIcon = MenuItemIntentIcon;
MenuItem.PriceDisplay = MenuItemPriceDisplay;
MenuItem.Caption = MenuItemCaption;
MenuItem.Badge = MenuItemBadge;
MenuItem.OverflowPopoverMenu = MenuItemOverflowPopoverMenu;
MenuItem.OverflowDrawerMenu = MenuItemOverflowDrawerMenu;
MenuItem.StatefulButtCon = MenuItemStatefulButtCon;
MenuItem.Button = MenuItemButton;
MenuItem.SwapStack = MenuItemSwapStack;
MenuItem.FramedStack = MenuItemFramedStack;
MenuItem.Avatar = MenuItemAvatar;
MenuItem.BottomSlot = MenuItemBottomSlot;

export type MenuItemOverflowPopoverMenuProps = DistributiveOmit<
  OverflowPopoverMenuProps,
  "icon" | "iconVariant"
>;

export function MenuItemOverflowPopoverMenu({
  children,
  sx = {},
  ...props
}: MenuItemOverflowPopoverMenuProps) {
  return (
    <OverflowPopoverMenu
      {...props}
      size="small"
      sx={merge({ flexShrink: 0 }, sx)}
    >
      {children}
    </OverflowPopoverMenu>
  );
}

MenuItemOverflowPopoverMenu.displayName = "MenuItem.OverflowPopoverMenu";

export type MenuItemOverflowDrawerMenuProps = DistributiveOmit<
  OverflowDrawerMenuProps,
  "icon" | "iconVariant"
>;

export function MenuItemOverflowDrawerMenu({
  children,
  sx = {},
  ...props
}: MenuItemOverflowDrawerMenuProps) {
  return (
    <OverflowDrawerMenu
      {...props}
      size="small"
      sx={merge({ flexShrink: 0 }, sx)}
    >
      {children}
    </OverflowDrawerMenu>
  );
}

MenuItemOverflowDrawerMenu.displayName = "MenuItem.OverflowDrawerMenu";

/*









      EXPANDABLE MENU ITEM








      
*/

export type ExpandableMenuItemProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    emphasized?: boolean;
    children: ReactNode;
    size?: MakeResponsive<MenuItemSize>;
    textAlign?: InputTextAlign;
    onExpandChange?: (expanded: boolean) => void;
    shimmer?: boolean;
    shimmerSx?: DeeplyNestedSx;
    motionProfile?: MotionProfile;
  } & (
    | {
        expanded: boolean;
        defaultExpanded?: never;
      }
    | {
        defaultExpanded?: boolean;
        expanded?: never;
      }
  )
>;

export function ExpandableMenuItem<
  RC extends ReactElement | undefined = undefined,
>(
  props: RC extends undefined
    ? DomPropsWithDomRef<"div"> & ExpandableMenuItemProps
    : ExpandableMenuItemProps & { rc: RC },
) {
  const {
    children,
    size = DEFAULT_MENU_ITEM_SIZE,
    emphasized = false,
    textAlign,
    expanded,
    defaultExpanded,
    testId,
    onExpandChange,
    className,
    shimmer,
    shimmerSx,
    sx = {},
    motionProfile,
    ...otherProps
  } = "expanded" in props
    ? { ...props, defaultExpanded: undefined }
    : { ...props, expanded: undefined };
  const theme = useTheme();
  const { base } = theme;
  const [uncontrolledExpanded, setUncontrolledExpanded] =
    useState(defaultExpanded);
  const expandedValueToUse = expanded ?? uncontrolledExpanded;
  const nestedVerticalMenu = useGetSubcomponentChild(children, VerticalMenu);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    VerticalMenu,
    // @NOTE: its not possible to put these components into a nested MenuItem, so make
    // sure they are picked out of the children, before passing them on to MenuItem
    MenuItemIntentIcon,
    MenuItemStatefulButtCon,
  ]);
  const startingSize = getStartingSize(
    size,
    DEFAULT_MENU_ITEM_SIZE,
    MENU_ITEM_SIZES,
  );
  const motionProfileToUse = useGetMotionProfile(motionProfile);

  const mergedSx = merge(
    containerSx,
    getMotionProfileSx(motionProfileToUse, theme),
    renderSizeBasedContainerStyles({ size: startingSize, theme }),
    renderContainerResponsiveStyles({
      size,
      theme,
    }),
    sx,
  );

  const handleToggle = useCallback(() => {
    if (shimmer) return;
    if (typeof expanded === "undefined") {
      setUncontrolledExpanded((old) => {
        onExpandChange?.(!old);
        return !old;
      });
    } else {
      onExpandChange?.(!expanded);
    }
  }, [expanded, onExpandChange, shimmer]);

  return (
    <Stack
      {...otherProps}
      onClick={handleToggle}
      direction="column"
      gap="base.spacing.x1"
      sx={mergedSx}
      className={`${className ?? ""} ExpandableMenuItem`}
      testId={testId}
    >
      <MenuItem
        size={size}
        emphasized={emphasized}
        textAlign={textAlign}
        shimmer={shimmer}
        shimmerSx={shimmerSx}
        // @NOTE: ensure that hoverFX are automatically given to any
        // ExpandableMenuItem component (to indicate interactivity)
        onClick={noop}
        sx={{ position: "relative" }}
        selected={expandedValueToUse}
        testId={`${testId}__menuItem`}
      >
        {otherChildren}
        <MenuItem.IntentIcon sx={{ opacity: 0 }} />
        <SvgIcon className="chevron" sx={rightChevronSx} rc={<motion.svg />}>
          <motion.path
            initial="down"
            animate={expandedValueToUse ? "up" : "down"}
            variants={{
              down: {
                d: "M5 9L12 16L19 9",
              },
              up: {
                d: "M5 16L12 9L19 16",
              },
            }}
            transition={{
              ease: base.motion.normal.fast.jsEase,
              duration: base.motion.normal.fast.jsDuration,
            }}
          />
        </SvgIcon>
      </MenuItem>

      <AnimatePresence>
        {Boolean(nestedVerticalMenu) && expandedValueToUse ? (
          <SmartClone
            rc={
              <motion.div
                initial={{
                  opacity: 0,
                  height: 0,
                  overflow: "hidden",
                }}
                animate={{
                  height: "auto",
                  opacity: 1,
                  transitionEnd: {
                    overflow: "visible",
                  },
                }}
                exit={{
                  height: 0,
                  opacity: 0,
                  overflow: "hidden",
                }}
                transition={{
                  duration: base.motion.normal.fast.jsDuration,
                  ease: base.motion.normal.fast.jsEase,
                }}
              />
            }
            onClick={(event) => event.stopPropagation()}
            emphasized={emphasized}
            sx={merge(nestedVerticalMenuSx, nestedVerticalMenu?.props.sx || {})}
            testId={
              nestedVerticalMenu?.props.testId ??
              `${testId}__nestedVerticalMenu`
            }
          >
            {nestedVerticalMenu}
          </SmartClone>
        ) : null}
      </AnimatePresence>
    </Stack>
  );
}

ExpandableMenuItem.displayName = "ExpandableMenuItem";

ExpandableMenuItem.Icon = MenuItemIcon;
ExpandableMenuItem.FramedIcon = MenuItemFramedIcon;
ExpandableMenuItem.FramedImage = MenuItemFramedImage;
ExpandableMenuItem.FramedLogo = MenuItemFramedLogo;
ExpandableMenuItem.FramedVideo = MenuItemFramedVideo;
ExpandableMenuItem.FramedStack = MenuItemFramedStack;
ExpandableMenuItem.Avatar = MenuItemAvatar;
ExpandableMenuItem.SwapStack = MenuItemSwapStack;
ExpandableMenuItem.Label = MenuItemLabel;
ExpandableMenuItem.Caption = MenuItemCaption;
ExpandableMenuItem.Badge = MenuItemBadge;
ExpandableMenuItem.BottomSlot = MenuItemBottomSlot;

/*









      VERTICAL MENU








      
*/

export const DEFAULT_VERTICAL_MENU_SIZE = "xSmall";

export function VerticalMenu<RC extends ReactElement | undefined = undefined>({
  children,
  sx = {},
  size = DEFAULT_VERTICAL_MENU_SIZE,
  emphasized,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  className,
  ...props
}: VerticalMenuProps<RC>) {
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_VERTICAL_MENU_SIZE,
    MENU_ITEM_SIZES,
  );
  return (
    <Box
      {...props}
      sx={merge(
        vFlex,
        verticalMenuSxProps,
        {
          ...(emphasized ? { boxShadow: "base.shadow.500" } : {}),
        },
        sx,
      )}
      className={`${
        className ?? ""
      } VerticalMenu VerticalMenu--${currentSizeClass} ${
        emphasized ? "VerticalMenu--emphasized" : ""
      }`}
    >
      {Children.map(flattenedChildren, (child) => {
        if (
          isChildSubcomponent(child, MultiSelectOption) ||
          isChildSubcomponent(child, SelectOption) ||
          isChildSubcomponent(child, MenuItem) ||
          isChildSubcomponent(child, InputBar) ||
          isChildSubcomponent(child, ExpandableMenuItem)
        ) {
          return (
            <SmartClone
              size={child.props.size ?? size}
              textAlign={textAlign}
              sx={merge(
                {
                  "& .innerTextContainer": {
                    alignItems:
                      textAlign === "left" ? "flex-start" : "flex-end",
                  },
                },
                child.props.sx || {},
              )}
            >
              {child}
            </SmartClone>
          );
        }
        if (isChildSubcomponent(child, VerticalMenuDivider)) {
          return (
            <SmartClone
              size={child.props.size ?? "xSmall"}
              sx={merge({ my: "base.spacing.x1" }, child.props.sx ?? {})}
            >
              {child}
            </SmartClone>
          );
        }

        return null;
      })}
    </Box>
  );
}

VerticalMenu.displayName = "VerticalMenu";
VerticalMenu.Divider = VerticalMenuDivider;
VerticalMenu.Item = MenuItem;

/*








    SELECT OPTION









*/

export function SelectOption({
  size = "xSmall",
  onOptionClick,
  children,
  optionKey,
  sx = {},
  ...props
}: SelectOptionProps) {
  const handleOptionClick = useCallback(
    (event: MouseEvent<unknown>) =>
      onOptionClick?.(
        event as MouseEvent<HTMLButtonElement>,
        children,
        optionKey,
      ),
    [children, onOptionClick, optionKey],
  );

  return (
    <MenuItem
      {...props}
      onClick={handleOptionClick}
      size={size}
      sx={merge(
        {
          "&.MenuItem--hasWholeOnClick.MenuItem--hover": {
            boxShadow: "none",
          },
        },
        sx,
      )}
    >
      {children}
    </MenuItem>
  );
}

SelectOption.displayName = "Select.Option";
SelectOption.Label = MenuItem.Label;
SelectOption.Caption = MenuItem.Caption;
SelectOption.Icon = MenuItem.Icon;
SelectOption.FramedImage = MenuItem.FramedImage;
SelectOption.FramedLogo = MenuItem.FramedLogo;
SelectOption.FramedIcon = MenuItem.FramedIcon;
SelectOption.PriceDisplay = MenuItem.PriceDisplay;
SelectOption.BottomSlot = MenuItem.BottomSlot;

/*








    MULTI SELECT OPTION









*/

export function MultiSelectOption({
  size = "xSmall",
  onOptionClick,
  children,
  optionKey,
  sx = {},
  className,
  ...props
}: MultiSelectOptionProps) {
  const handleOptionClick = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) =>
      onOptionClick?.(ev.target.checked, optionKey),
    [onOptionClick, optionKey],
  );
  const mergedSx = useMemo(() => merge(multiSelectOptionSx, sx), [sx]);
  return (
    <InputBar
      {...props}
      inputType="checkbox"
      onChange={handleOptionClick}
      size={size}
      sx={mergedSx}
      className={`${className ?? ""} MultiSelectOption`}
    >
      {children}
    </InputBar>
  );
}

MultiSelectOption.displayName = "MultiSelect.Option";
MultiSelectOption.Label = MenuItem.Label;
MultiSelectOption.Caption = MenuItem.Caption;
MultiSelectOption.Icon = MenuItem.Icon;
MultiSelectOption.FramedImage = MenuItem.FramedImage;
MultiSelectOption.FramedIcon = MenuItem.FramedIcon;
MultiSelectOption.Avatar = MenuItem.Avatar;

/* 










    INPUT BAR










*/

export type InputBarProps = Omit<
  StandardInputComponentWithProps<
    "input",
    {
      inputRef?: Ref<HTMLInputElement>;
      emphasized?: boolean;
      children: ReactNode;
      inputType?: "checkbox" | "radio" | "toggle";
      textAlign?: InputTextAlign;
    }
  >,
  "size" | "type"
> & { size?: MakeResponsive<MenuItemSize> };

export function InputBar<RC extends ReactElement | undefined = undefined>({
  children,
  checked,
  size,
  emphasized,
  disabled,
  id: idProp,
  inputType = "toggle",
  domRef = { current: null },
  inputRef = { current: null },
  onChange,
  testId,
  defaultChecked,
  sx = {},
  textAlign,
  className,
  rc,
  ...inputProps
}: RC extends undefined ? InputBarProps : InputBarProps & { rc: RC }) {
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const localRef = useForwardLocalDomRef(domRef);
  const localInputRef = useForwardLocalDomRef(inputRef);
  const { width, height } = useResizeObserver(localRef);

  const inputStyles = useMemo(
    () => ({
      position: "absolute",
      width: "100%",
      height: "100%",

      "& .actualInput": {
        width: `${width}px`,
        height: `${height}px`,
        zIndex: 1,

        "&:hover:not(:disabled)": {
          "& + .toggleContainer, & + .checkContainer, & + .radioContainer": {
            boxShadow: "none",
          },
        },
      },

      "& .toggleContainer, & .checkContainer, & .radioContainer": {
        position: "absolute",
        right: "base.spacing.x4",
        top: "50%",
        translate: "0 -50%",
      },
    }),
    [width, height],
  );

  const allInputProps = {
    ...inputProps,
    id,
    testId: `${testId}__biomeInput`,
    onChange,
    defaultChecked,
    checked,
    disabled,
    sx: inputStyles,
    inputRef: localInputRef,
  };
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const mergedSx = useMemo(() => merge(inputBarBaseSx, sx), [sx]);

  return (
    <ClassNames>
      {({ cx }) => (
        <Box
          rc={rc}
          sx={mergedSx}
          testId={testId}
          className={cx(className, "InputBar", {
            "InputBar--disabled": disabled,
          })}
          domRef={localRef}
        >
          {inputType === "toggle" && <Toggle {...allInputProps} />}
          {inputType === "checkbox" && <Checkbox {...allInputProps} />}
          {inputType === "radio" && <Radio {...allInputProps} />}

          <MenuItem
            size={size}
            emphasized={emphasized}
            textAlign={textAlign}
            testId={`${testId}__menuItem`}
            sx={{
              pr:
                inputType === "toggle"
                  ? "base.spacing.x15"
                  : inputType === "checkbox"
                    ? "base.spacing.x9"
                    : "base.spacing.x9",
            }}
          >
            {Children.map(flattenedChildren, (child) => {
              if (isChildSubcomponent(child, MenuItem.Label)) {
                return cloneElement(child, {
                  // biome-ignore lint/a11y/noLabelWithoutControl: <explanation>
                  rc: <label htmlFor={id} />,
                });
              }
              return child;
            })}
          </MenuItem>
        </Box>
      )}
    </ClassNames>
  );
}

InputBar.displayName = "InputBar";

InputBar.Icon = MenuItem.Icon;
InputBar.FramedIcon = MenuItem.FramedIcon;
InputBar.FramedImage = MenuItem.FramedImage;
InputBar.FramedLogo = MenuItem.FramedLogo;
InputBar.FramedVideo = MenuItem.FramedVideo;
InputBar.FramedStack = MenuItem.FramedStack;
InputBar.Avatar = MenuItem.Avatar;
InputBar.SwapStack = MenuItem.SwapStack;
InputBar.Label = MenuItem.Label;
InputBar.Caption = MenuItem.Caption;

/*









    OVEFLOW DRAWER MENU









*/

export function OverflowDrawerMenu({
  children,
  domRef,
  testId,
  icon = "MoreVertical",
  iconVariant,
  size = DEFAULT_BUTTON_SIZE,
  variant = "tertiary",
  onClick,
  drawerSize = "half",
  drawerCloseIcon,
  showHeaderBar = true,
  headerBarTitle,
  showBgOverlay,
  outsideClicksClose = true,
  escapeKeyClose = true,
  drawerPosition = DEFAULT_DRAWER_POSITION,
  customTarget,
  ...buttConProps
}: OverflowDrawerMenuProps) {
  const [visible, setVisible] = useState(false);
  const typedProps = {
    headerBarTitle,
    showHeaderBar,
  } as WithDrawerDisriminatedProps;
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const iconProps = isDualVariantIcon(icon) ? { icon, iconVariant } : { icon };
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      setVisible(!visible);
      onClick?.(event);
    },
    [onClick, visible],
  );
  return (
    <Drawer
      {...typedProps}
      visible={visible}
      onCloseDrawer={() => setVisible(false)}
      outsideClicksClose={outsideClicksClose}
      escapeKeyClose={escapeKeyClose}
      size={drawerSize}
      showBgOverlay={showBgOverlay}
      domRef={domRef}
      drawerPosition={drawerPosition}
      testId={testId}
      drawerCloseIcon={drawerCloseIcon}
    >
      <Drawer.Target>
        {customTarget ? (
          <SmartClone
            onClick={handleClick as MouseEventHandler<unknown>}
            testId={testId}
          >
            {customTarget}
          </SmartClone>
        ) : (
          <ButtCon
            {...buttConProps}
            {...iconProps}
            variant={variant}
            testId={testId}
            size={size}
            onClick={handleClick}
          />
        )}
      </Drawer.Target>
      <Drawer.Content
        // @NOTE: weird emotion/react css issue here, padding-bottom is
        // over-written by a `padding` definition inside of the
        // DrawerOverlay component, despite the object order being correct
        sx={{ paddingX: "base.spacing.x2" }}
      >
        <VerticalMenu
          testId={`${testId}__overflowDrawerMenu`}
          sx={{ maxHeight: "100%" }}
        >
          {Children.map(flattenedChildren, (child) => {
            const typedChild = child as ReactElement<{ onClick: unknown }>;
            const shouldCloneWithOnClick =
              isValidElement(typedChild) && Boolean(typedChild.props?.onClick);
            return shouldCloneWithOnClick ? (
              <SmartClone
                // @NOTE: this ensures that children props like size etc,
                // get mapped correctly onto all MenuItem children
                {...typedChild.props}
                // @NOTE: SmartClone will automatically merge any
                // onClicks on children passed in
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setVisible(false);
                }}
              >
                {child}
              </SmartClone>
            ) : (
              child
            );
          })}
        </VerticalMenu>
      </Drawer.Content>
    </Drawer>
  );
}

OverflowDrawerMenu.displayName = "OverflowDrawerMenu";

/*












      OVERFLOW POPOVER MENU












*/

export function OverflowPopoverMenu({
  children,
  domRef,
  testId,
  icon = "MoreVertical",
  iconVariant,
  size = "medium",
  variant = "tertiary",
  onClick,
  customTarget,
  ...buttConProps
}: OverflowPopoverMenuProps) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { state: windowSize } = useWindowSizeStore((store) => store);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => setPopoverVisible(false), [windowSize]);
  const handleTargetClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setPopoverVisible(!popoverVisible);
      onClick?.(event);
    },
    [onClick, popoverVisible],
  );
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const iconProps = isDualVariantIcon(icon) ? { icon, iconVariant } : { icon };

  return (
    <Popover
      position={{ x: "right", y: "below" }}
      visible={popoverVisible}
      onClosePopover={() => setPopoverVisible(false)}
      domRef={domRef}
      verticalGap={8}
    >
      <Popover.Target>
        {customTarget ? (
          <SmartClone
            onClick={handleTargetClick as MouseEventHandler<unknown>}
            testId={testId}
          >
            {customTarget}
          </SmartClone>
        ) : (
          <ButtCon
            {...buttConProps}
            {...iconProps}
            testId={testId}
            size={size}
            variant={variant}
            onClick={handleTargetClick}
          />
        )}
      </Popover.Target>
      <Popover.Content>
        <VerticalMenu
          testId={`${testId}__overflowPopoverMenu`}
          emphasized
          sx={{
            // @NOTE: if VerticalMenu is nested inside another
            // VerticalMenu, we want to not keep making the parent
            // VerticalMenu's larger as each child opens. :P
            "& .VerticalMenu": {
              minw: "unset",
            },
          }}
        >
          {Children.map(flattenedChildren, (child) => {
            const typedChild = child as ReactElement<{
              onClick: OnClickFunction;
              rc: ReactElement;
            }>;
            const shouldCloneWithOnClick =
              isValidElement(typedChild) &&
              (Boolean(typedChild.props.onClick) ||
                Boolean(typedChild.props.rc));

            return shouldCloneWithOnClick ? (
              <SmartClone
                onClick={(event) => {
                  event.stopPropagation();
                  setPopoverVisible(false);
                }}
              >
                {child}
              </SmartClone>
            ) : (
              child
            );
          })}
        </VerticalMenu>
      </Popover.Content>
    </Popover>
  );
}

OverflowPopoverMenu.displayName = "OverflowPopoverMenu";

/*












      MULTI SELECT











*/

export function MultiSelect({
  children,
  validationStatus,
  defaultLabel = "Select options",
  sx = {},
  popoverContainerSx = {},
  domRef = { current: null },
  onSelectChange,
  testId = "MultiSelect",
  id: idProp,
  size = DEFAULT_SELECT_SIZE,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  targetClickOveride,
  showSelectAll,
  selectedChipsWrap = false,
  disabled,
  className,
  ...props
}: MultiSelectProps) {
  const { selectedOptions, defaultOptions, ...otherProps } =
    "selectedOptions" in props
      ? { ...props, defaultOptions: undefined }
      : {
          ...props,
          selectedOptions: undefined,
        };

  const theme = useTheme();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const options = useGetSubcomponentChildren(children, MultiSelectOption);
  const dedupedDefaultOptions = useMemo(() => {
    return defaultOptions !== undefined
      ? [...new Set(defaultOptions)]
      : undefined;
  }, [defaultOptions]);
  const dedupedSelectedOptions = useMemo(() => {
    return selectedOptions !== undefined
      ? [...new Set(selectedOptions)]
      : undefined;
  }, [selectedOptions]);
  const [uncontrolledSelectedOptions, setUncontrolledSelectedOptions] =
    useState<OptionKey[]>(
      dedupedSelectedOptions ?? dedupedDefaultOptions ?? [],
    );

  const selectedOptionsToUse = useMemo(
    () => dedupedSelectedOptions ?? uncontrolledSelectedOptions,
    [dedupedSelectedOptions, uncontrolledSelectedOptions],
  );
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const startingSize = useMemo(
    () => getStartingSize(size, DEFAULT_TEXT_INPUT_SIZE, TEXT_INPUT_SIZES),
    [size],
  );
  const leftIcon = useGetSubcomponentChild(children, MultiSelectIcon);
  const hasValue = selectedOptionsToUse.length > 0;
  const allButtonSx = useMemo(
    () =>
      merge(
        inputContainerBaseSx,
        baseInputSx,
        renderContainerStyles({ size: startingSize, theme }),
        renderResponsiveContainerStyles({
          size,
          theme,
        }),
        renderInputSx({
          validationStatus,
          leftHandButtonsWidth: leftIcon ? 24 : 0,
          rightHandButtonsWidth: 0,
          textAlign,
          theme,
          hasValue,
        }),
        renderResponsiveInputSx({
          theme,
          size,
          validationStatus,
          leftHandButtonsWidth: leftIcon ? 24 : 0,
          rightHandButtonsWidth: 0,
          textAlign,
          hasValue,
        }),
        baseSelectSx,
        getSelectCustomSx({ textAlign, disabled, size: startingSize }),
        getResponsiveSelectCustomSx({ theme, size, textAlign, disabled }),
        baseMultiSelectSx,
        sx,
      ),
    [
      leftIcon,
      size,
      startingSize,
      sx,
      textAlign,
      theme,
      validationStatus,
      disabled,
      hasValue,
    ],
  );
  const leftIconStyles = useMemo(
    () => merge(inputIconBaseSx, { mr: "0", svg: { width: "100%" } }),
    [],
  );
  const localTargetRef = useForwardLocalDomRef(domRef);
  const { width } = useResizeObserver(localTargetRef, 30);
  const sizeAsClass = useGetCurrentSizeClass(
    size,
    DEFAULT_SELECT_SIZE,
    SELECT_SIZES,
  );
  const handleOptionClick = useCallback(
    (selected: boolean, optionKey: OptionKey) => {
      if (selected) {
        const modifySelectedOptions = [...selectedOptionsToUse, optionKey];
        onSelectChange?.(modifySelectedOptions);
        setUncontrolledSelectedOptions(modifySelectedOptions);
      } else {
        const modifySelectedOptions = selectedOptionsToUse.filter(
          (key) => key !== optionKey,
        );
        onSelectChange?.(modifySelectedOptions);
        setUncontrolledSelectedOptions(modifySelectedOptions);
      }
    },
    [selectedOptionsToUse, onSelectChange],
  );
  const handleSelectAllToggle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (ev.target.checked) {
        const modifySelectedOptions =
          options?.map((option) => option.props.optionKey) ?? [];
        onSelectChange?.(modifySelectedOptions);
        return setUncontrolledSelectedOptions(modifySelectedOptions);
      }

      onSelectChange?.([]);
      return setUncontrolledSelectedOptions([]);
    },
    [options, onSelectChange],
  );
  const handleRemoveChip = useCallback(
    (optionKey: OptionKey) => () => {
      const modifySelectedOptions = selectedOptionsToUse.filter(
        (key) => key !== optionKey,
      );
      onSelectChange?.(modifySelectedOptions);
      setUncontrolledSelectedOptions(modifySelectedOptions);
    },
    [onSelectChange, selectedOptionsToUse],
  );
  const selectAllIsChecked = useMemo(() => {
    return (
      selectedOptionsToUse.length === options?.length &&
      selectedOptionsToUse.length > 0
    );
  }, [selectedOptionsToUse, options]);

  // @NOTE: runtime check to ensure that the selectedOptions
  // prop is only ever set to optionKeys that are valid
  useEffect(() => {
    if (selectedOptions === undefined) {
      return;
    }

    const hasBadOptionKeys = selectedOptions.some(
      (key) => !options?.some((option) => option.props.optionKey === key),
    );
    if (hasBadOptionKeys) {
      warnUser(createMultiSelectErrorMessage(selectedOptions));
    }
  }, [selectedOptions, options]);

  return (
    <Popover
      id={id}
      domRef={localTargetRef}
      position={{ x: textAlign, y: "below" }}
      visible={popoverVisible}
      onOutsideClick={() => setPopoverVisible(false)}
      verticalGap={0}
    >
      <Popover.Target>
        <Box
          {...otherProps}
          testId={`${testId}__target`}
          sx={allButtonSx}
          onClick={(event) => {
            if (targetClickOveride) {
              targetClickOveride?.(event);
            } else {
              setPopoverVisible(!popoverVisible);
            }
          }}
          className={`${
            className ?? ""
          } MultiSelect MultiSelect--${sizeAsClass} MultiSelect--textAlign_${textAlign} ${
            disabled ? "MultiSelect--disabled" : ""
          } ${selectedChipsWrap ? "MultiSelect--selectedChipsWrap" : ""}`}
        >
          {leftIcon && (
            <SmartClone
              testId={`${testId}__target__left`}
              sx={leftIconStyles}
              fallBackDomNode="div"
              className="MultiSelect__leftIcon"
            >
              {leftIcon}
            </SmartClone>
          )}

          {selectedOptionsToUse.length === 0 ? (
            <Body
              size="medium"
              testId={`${testId}__target__defaultLabel`}
              sx={defaultLabelSx}
              className="MultiSelect__defaultLabel"
            >
              {defaultLabel}
            </Body>
          ) : null}

          <Stack
            rc={<span />}
            sx={baseChipsContainerSx}
            gap="base.spacing.x1"
            direction="row"
            className="MultiSelect__chipsContainer"
            testId={`${testId}__target__chips`}
          >
            {selectedOptionsToUse.map((optionKey) => {
              const pickedOption = options?.find(
                (opt) => opt.props.optionKey === optionKey,
              );
              if (!pickedOption) return null;
              const label = getSubcomponentChild(
                pickedOption.props.children,
                MenuItem.Label,
              );
              const icon =
                getSubcomponentChild(
                  pickedOption.props.children,
                  MenuItem.Icon,
                ) ??
                getSubcomponentChild(
                  pickedOption.props.children,
                  MenuItem.FramedIcon,
                );
              const framedImage = getSubcomponentChild(
                pickedOption.props.children,
                MenuItem.FramedImage,
              );
              const avatar = getSubcomponentChild(
                pickedOption.props.children,
                MenuItem.Avatar,
              );
              const iconProps: ChipIconProps<undefined> | undefined =
                isValidElement(icon) ? icon.props : undefined;
              const labelText: string = isValidElement(label)
                ? label?.props.children
                : "";
              const framedImageProps: ChipFramedImageProps | undefined =
                isValidElement(framedImage) ? framedImage.props : undefined;
              const { sx: framedImageSx, ...otherFramedImageProps } =
                framedImageProps ?? {
                  sx: undefined,
                };
              const avatarProps: ChipAvatarProps | undefined = isValidElement(
                avatar,
              )
                ? avatar.props
                : undefined;
              // @NOTE: we strip away size and sx props here, as that is set
              // automatically via the Chip component
              const {
                size: avatarSize,
                sx: avatarSx,
                ...otherAvatarProps
              } = avatarProps ?? ({} as ChipAvatarProps);
              return (
                <Chip
                  key={optionKey}
                  label={labelText}
                  sx={chipBaseSx}
                  size={size}
                  onRemove={handleRemoveChip(optionKey)}
                  rc={<span />}
                  testId={`${testId}__target__chips__chip--${optionKey}`}
                >
                  {iconProps && <Chip.Icon {...iconProps} />}
                  {framedImageProps && (
                    <Chip.FramedImage
                      {...(otherFramedImageProps as ChipFramedImageProps)}
                    />
                  )}
                  {avatarProps && <Chip.Avatar {...otherAvatarProps} />}
                </Chip>
              );
            })}
          </Stack>

          <SvgIcon
            className="MultiSelect__chevron"
            sx={selectChevronSx}
            rc={<motion.svg />}
          >
            <motion.path
              initial="down"
              variants={{
                down: {
                  d: "M5 9L12 16L19 9",
                },
                up: {
                  d: "M5 16L12 9L19 16",
                },
              }}
              animate={popoverVisible ? "up" : "down"}
              transition={{
                ease: theme.base.motion.normal.fast.jsEase,
                duration: theme.base.motion.normal.fast.jsDuration,
              }}
            />
          </SvgIcon>
        </Box>
      </Popover.Target>

      <Popover.Content
        testId={`${testId}__content`}
        sx={merge(
          {
            w: `${width}px`,
            bradbl: "base.borderRadius.x6",
            bradbr: "base.borderRadius.x6",
          },
          popoverContainerSx,
        )}
      >
        <VerticalMenu
          textAlign={textAlign}
          sx={{ minw: "unset" }}
          testId={`${testId}__content__verticalMenu`}
          emphasized
        >
          {showSelectAll && (
            <>
              <InputBar
                sx={multiSelectOptionSx}
                inputType="checkbox"
                testId={`${testId}__content__verticalMenu__selectAll`}
                size="xSmall"
                onChange={handleSelectAllToggle}
                checked={selectAllIsChecked}
                className="MultiSelectOption"
              >
                <InputBar.Label>Select all</InputBar.Label>
              </InputBar>
              <VerticalMenu.Divider size="xSmall" sx={{ my: "unset" }} />
            </>
          )}

          {Children.map(options, (child) => {
            const optionKey = child?.props?.optionKey ?? "";
            return (
              <SmartClone
                onOptionClick={handleOptionClick}
                checked={selectedOptionsToUse.includes(optionKey)}
                testId={`${testId}__content__verticalMenu__option--${optionKey}`}
              >
                {child}
              </SmartClone>
            );
          })}
        </VerticalMenu>
      </Popover.Content>
    </Popover>
  );
}

MultiSelect.displayName = "MultiSelect";
MultiSelect.Option = MultiSelectOption;
MultiSelect.Icon = MultiSelectIcon;

/*












      SELECT











*/

function useGetControlledLeftContent({
  options,
  selectedOption,
}: {
  options?: ReactElement<SelectOptionProps>[];
  selectedOption?: OptionKey | null;
}) {
  const controlledSelectionFramedImage = useGetControlledContent({
    options,
    selectedOption,
    subcomponent: SelectOption.FramedImage,
  });
  const controlledSelectionIcon = useGetControlledContent({
    options,
    selectedOption,
    subcomponent: SelectOption.Icon,
  });
  const controlledSelectionFramedIcon = useGetControlledContent({
    options,
    selectedOption,
    subcomponent: SelectOption.FramedIcon,
  });
  const controlledSelectionFramedLogo = useGetControlledContent({
    options,
    selectedOption,
    subcomponent: SelectOption.FramedLogo,
  });

  return (
    controlledSelectionFramedIcon ||
    controlledSelectionFramedImage ||
    controlledSelectionFramedLogo ||
    controlledSelectionIcon ||
    null
  );
}

export function Select({
  children,
  validationStatus,
  defaultLabel = "Select an option",
  sx = {},
  popoverContainerSx = {},
  onSelectChange,
  domRef = { current: null },
  testId = "Select",
  id: idProp,
  size = DEFAULT_SELECT_SIZE,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  targetClickOveride,
  disabled,
  className,
  ...props
}: SelectProps) {
  const { selectedOption, defaultOption, ...otherProps } =
    "selectedOption" in props
      ? { ...props, defaultOption: undefined }
      : {
          ...props,
          selectedOption: undefined,
        };

  const theme = useTheme();
  const [selectedOptionChildren, setSelectedOptionChildren] =
    useState<ReactNode | null>(null);
  const localRef = useForwardLocalDomRef(domRef);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const options = useGetSubcomponentChildren(children, SelectOption);
  const defaultIcon = useGetSubcomponentChild(children, SelectDefaultIcon);
  const defaultFramedImage = useGetSubcomponentChild(
    children,
    SelectDefaultFramedImage,
  );
  const defaultFramedLogo = useGetSubcomponentChild(
    children,
    SelectDefaultFramedLogo,
  );
  const targetSize = useResizeObserver(localRef, 30);
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const {
    state: { width: windowWidth },
  } = useWindowSizeStore((store) => store);

  // @NOTE: collect up specific children, depending on the current
  // state of the Select component
  const {
    selectedOptionFramedImage,
    selectedOptionIcon,
    selectedOptionText,
    selectedOptionFramedIcon,
    selectedOptionFramedLogo,
  } = useMemo(
    () => ({
      selectedOptionFramedImage: getSubcomponentChild(
        selectedOptionChildren,
        SelectOption.FramedImage,
      ),
      selectedOptionIcon: getSubcomponentChild(
        selectedOptionChildren,
        SelectOption.Icon,
      ),
      selectedOptionText: getSubcomponentChild(
        selectedOptionChildren,
        SelectOption.Label,
      ),
      selectedOptionFramedIcon: getSubcomponentChild(
        selectedOptionChildren,
        SelectOption.FramedIcon,
      ),
      selectedOptionFramedLogo: getSubcomponentChild(
        selectedOptionChildren,
        SelectOption.FramedLogo,
      ),
    }),
    [selectedOptionChildren],
  );

  // @NOTE: setup controlled API selection children
  const controlledLeftSideContent = useGetControlledLeftContent({
    options,
    selectedOption,
  });
  const controlledSelectionText = useMemo(() => {
    if (selectedOption === null) return null;
    const pickedOption = pickAnOption(selectedOption, options);
    if (pickedOption?.props.children) {
      return getSubcomponentChild(
        pickedOption.props.children,
        SelectOption.Label,
      );
    }
    if (selectedOption !== undefined) {
      warnUser(noOptionFoundErrorText(selectedOption));
    }
    return false;
  }, [options, selectedOption]);

  const startingSize = useMemo(
    () => getStartingSize(size, DEFAULT_TEXT_INPUT_SIZE, TEXT_INPUT_SIZES),
    [size],
  );
  const leftSideContent = useMemo(
    () =>
      controlledLeftSideContent ||
      selectedOptionIcon ||
      selectedOptionFramedImage ||
      selectedOptionFramedIcon ||
      selectedOptionFramedLogo ||
      defaultIcon ||
      defaultFramedImage ||
      defaultFramedLogo ||
      null,
    [
      controlledLeftSideContent,
      selectedOptionFramedImage,
      selectedOptionIcon,
      selectedOptionFramedIcon,
      selectedOptionFramedLogo,
      defaultFramedImage,
      defaultIcon,
      defaultFramedLogo,
    ],
  );
  const allButtonSx = useMemo(
    () =>
      merge(
        inputContainerBaseSx,
        baseInputSx,
        renderContainerStyles({ size: startingSize, theme }),
        renderResponsiveContainerStyles({
          size,
          theme,
        }),
        renderInputSx({
          validationStatus,
          leftHandButtonsWidth: leftSideContent ? 24 : 0,
          rightHandButtonsWidth: 0,
          textAlign,
          theme,
          hasValue: Boolean(selectedOptionText || controlledSelectionText),
        }),
        renderResponsiveInputSx({
          theme,
          size,
          validationStatus,
          leftHandButtonsWidth: leftSideContent ? 24 : 0,
          rightHandButtonsWidth: 0,
          textAlign,
          hasValue: Boolean(selectedOptionText || controlledSelectionText),
        }),
        baseSelectSx,
        getSelectCustomSx({ textAlign, disabled, size: startingSize }),
        getResponsiveSelectCustomSx({ theme, size, textAlign, disabled }),
        sx,
      ),
    [
      leftSideContent,
      size,
      startingSize,
      sx,
      textAlign,
      theme,
      validationStatus,
      disabled,
      selectedOptionText,
      controlledSelectionText,
    ],
  );

  const leftContentStyles = useMemo(() => {
    let leftSideSx: DeeplyNestedSx = {};
    if (isValidElement(leftSideContent)) {
      const typedProps = leftSideContent.props as { sx?: DeeplyNestedSx };
      leftSideSx = typedProps.sx ?? {};
    }
    return merge(
      inputIconBaseSx,
      { mr: "0", svg: { width: "100%" } },
      leftSideSx,
    );
  }, [leftSideContent]);
  const sizeAsClass = useGetCurrentSizeClass(
    size,
    DEFAULT_SELECT_SIZE,
    SELECT_SIZES,
  );

  // @NOTE: life cycle stuff below here:
  // ------------------------------------------------------------------

  useBrowserLayoutEffect(() => {
    // @NOTE: if the popover is visible, and the window width changes,
    // close it, so it doesnt look out of place ...
    setPopoverVisible(false);
  }, [windowWidth, setPopoverVisible]);

  // @NOTE: When selectedOption is null, we need to clear out any
  // selected option children, BUT ONLY when we're in controlled mode
  useBrowserEffect(() => {
    if (selectedOption === null && selectedOptionChildren) {
      setSelectedOptionChildren(null);
    }
  }, [selectedOption, selectedOptionChildren]);

  useBrowserEffect(() => {
    if (defaultOption !== undefined) {
      const pickedOption = pickAnOption(defaultOption, options);
      setSelectedOptionChildren(pickedOption?.props.children);
    }
  }, [defaultOption]);

  const labelContent = useMemo(() => {
    if (controlledSelectionText || selectedOptionText) {
      return (
        <SmartClone testId={`${testId}__target__label`} size="small">
          {controlledSelectionText || selectedOptionText}
        </SmartClone>
      );
    }

    return (
      <Body
        size="medium"
        testId={`${testId}__target__label`}
        sx={{ c: "base.color.text.body.secondary" }}
      >
        {defaultLabel}
      </Body>
    );
  }, [controlledSelectionText, defaultLabel, selectedOptionText, testId]);

  return (
    <Popover
      id={id}
      domRef={localRef}
      position={{ x: textAlign, y: "below" }}
      visible={popoverVisible}
      onOutsideClick={() => setPopoverVisible(false)}
      verticalGap={0}
    >
      <Popover.Target>
        <BaseClickable
          {...otherProps}
          disabled={disabled}
          testId={`${testId}__target`}
          sx={allButtonSx}
          onClick={(event) => {
            if (targetClickOveride) {
              targetClickOveride?.(event);
            } else {
              setPopoverVisible(!popoverVisible);
            }
          }}
          className={`${
            className ?? ""
          } Select Select--${sizeAsClass} Select--textAlign_${textAlign} ${
            disabled ? "Select--disabled" : ""
          }`}
        >
          {leftSideContent && (
            <SmartClone
              testId={`${testId}__target__left`}
              sx={leftContentStyles}
            >
              {leftSideContent}
            </SmartClone>
          )}

          {labelContent}

          <SvgIcon className="chevron" sx={selectChevronSx} rc={<motion.svg />}>
            <motion.path
              initial="down"
              variants={{
                down: {
                  d: "M5 9L12 16L19 9",
                },
                up: {
                  d: "M5 16L12 9L19 16",
                },
              }}
              animate={popoverVisible ? "up" : "down"}
              transition={{
                ease: theme.base.motion.normal.fast.jsEase,
                duration: theme.base.motion.normal.fast.jsDuration,
              }}
            />
          </SvgIcon>
        </BaseClickable>
      </Popover.Target>

      <Popover.Content
        testId={`${testId}__content`}
        sx={merge(
          {
            w: `${targetSize.width}px`,
            bradbl: "base.borderRadius.x6",
            bradbr: "base.borderRadius.x6",
          },
          popoverContainerSx,
        )}
      >
        <VerticalMenu
          textAlign={textAlign}
          sx={{ minw: "unset" }}
          testId={`${testId}__content__verticalMenu`}
          emphasized
        >
          {Children.map(options, (child) => (
            <SmartClone
              onOptionClick={
                ((ev, childrenEls, optionKey) => {
                  child?.props.onClick?.(ev);
                  setPopoverVisible(false);
                  setSelectedOptionChildren(childrenEls);
                  onSelectChange?.(optionKey);
                }) as OnSelectOptionClick
              }
            >
              {child}
            </SmartClone>
          ))}
        </VerticalMenu>
      </Popover.Content>
    </Popover>
  );
}

Select.displayName = "Select";
Select.Option = SelectOption;
Select.DefaultIcon = SelectDefaultIcon;
Select.DefaultFramedImage = SelectDefaultFramedImage;
Select.DefaultFramedLogo = SelectDefaultFramedLogo;
