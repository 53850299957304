import type {
  LooseAutocomplete,
  SpacingTokenPaths,
} from "@biom3/design-tokens";
import type { Property } from "csstype";
import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { BoxWithRCAndDomProps, MakeValidSxValue } from "@/types";
import { Box } from "../Box";

export type StackProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & {
    direction?: MakeValidSxValue<Property.FlexDirection>;
    gap?: MakeValidSxValue<LooseAutocomplete<NonNullable<SpacingTokenPaths>>>;
    rowGap?: MakeValidSxValue<
      LooseAutocomplete<NonNullable<SpacingTokenPaths>>
    >;
    columnGap?: MakeValidSxValue<
      LooseAutocomplete<NonNullable<SpacingTokenPaths>>
    >;
    justifyContent?: MakeValidSxValue<Property.JustifyContent>;
    alignItems?: MakeValidSxValue<Property.AlignItems>;
    alignContent?: MakeValidSxValue<Property.AlignContent>;
    flexWrap?: MakeValidSxValue<Property.FlexWrap>;
    flexFlow?: MakeValidSxValue<Property.FlexFlow>;
  };

export function Stack<RC extends ReactElement | undefined = undefined>({
  sx = {},
  direction = "column",
  rowGap,
  columnGap,
  gap = Boolean(rowGap) || Boolean(columnGap) ? undefined : "base.spacing.x2",
  justifyContent,
  alignContent,
  alignItems,
  flexWrap,
  flexFlow,
  className,
  ...props
}: StackProps<RC>) {
  return (
    <Box
      {...props}
      className={`${className ?? ""} Stack`}
      sx={merge(
        {
          d: "flex",
          flexDirection: direction,
          rowGap,
          columnGap,
          gap,
          justifyContent,
          alignContent,
          alignItems,
          flexWrap,
          flexFlow,
        },
        sx,
      )}
    />
  );
}

Stack.displayName = "Stack";
