import type { DistributiveOmit } from "@/types";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_BODY_PROPS } from "@/constants";
import { useGetCurrentSizeClass, useGetMotionProfile } from "@/hooks";
import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import { useTheme } from "@/hooks/useTheme";
import type { CaptionProps } from "@/types";
import { cloneElementWithCssProp, getMotionProfileSx } from "@/utils";
import { getCaptionTextStyles } from "@/utils/textStyleHelpers";

import { MultiTextShimmer } from "../MultiTextShimmer/MultiTextShimmer";
import { baseTextComponentSx } from "../shared";

function CaptionContent<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_BODY_PROPS.size,
  rc = DEFAULT_BODY_PROPS.rc,
  weight = DEFAULT_BODY_PROPS.weight,
  sx = {},
  testId,
  children,
  domRef,
  className,
  motionProfile,
  ...props
}: DistributiveOmit<
  CaptionProps<RC>,
  "shimmer" | "shimmerSx" | "shimmerLines"
>) {
  const themeProps = useTheme();
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const mergedSx = useMemo(
    () =>
      merge(
        baseTextComponentSx,
        { c: DEFAULT_BODY_PROPS.color },
        getCaptionTextStyles({ themeProps, size, weight }),
        getMotionProfileSx(motionProfileToUse, themeProps),
        themeProps.components?.Caption?.sxOverride ?? {},
        sx,
      ),
    [sx, themeProps, size, weight, motionProfileToUse],
  );
  const css = useConvertSxToEmotionStyles(mergedSx);
  const currentSizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_BODY_PROPS.size,
    Object.keys(themeProps.base.text.body),
  );

  return cloneElementWithCssProp(rc, {
    ...props,
    ...(testId ? { "data-testid": testId } : {}),
    ...(domRef ? { ref: domRef } : {}),
    css,
    children,
    className: `${className ?? ""} Caption Caption--${currentSizeClass}`,
  });
}

export function Caption<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_BODY_PROPS.size,
  rc = DEFAULT_BODY_PROPS.rc,
  weight = DEFAULT_BODY_PROPS.weight,
  shimmer,
  shimmerSx,
  sx,
  children,
  domRef,
  testId = "Caption",
  className,
  motionProfile,
  ...props
}: CaptionProps<RC>) {
  return shimmer ? (
    <MultiTextShimmer
      size={size}
      textKind="Caption"
      sx={shimmerSx}
      shimmerLines={shimmer}
      rc={rc}
      className={className}
      testId={`${testId}__shimmer`}
    />
  ) : (
    <CaptionContent
      {...props}
      size={size}
      sx={sx}
      weight={weight}
      rc={rc}
      domRef={domRef}
      testId={testId}
      className={className}
      motionProfile={motionProfile}
    >
      {children}
    </CaptionContent>
  );
}

Caption.displayName = "Caption";
