import type {
  BiomeTheme,
  InputTextAlign,
  MakeResponsive,
  TextInputSize,
} from "@/types";
import { type RenderSxProps, getResponsiveSx } from "@/utils";
import type { Properties } from "csstype";

export const selectChevronSx = {
  w: "base.icon.size.250",
  stroke: "base.color.text.body.primary",
  strokeWidth: 2.75,
  fill: "transparent",
  right: "base.spacing.x3",
};

export const baseSelectSx = {
  pos: "relative",
  d: "flex",
  alignItems: "center",
  w: "auto",
};

export const getSelectCustomSx = ({
  textAlign,
  disabled,
}: {
  textAlign: InputTextAlign;
  disabled?: boolean;
  size?: TextInputSize;
}) => {
  return {
    ...(textAlign === "right" ? { pr: "base.spacing.x10" } : {}),
    justifyContent: textAlign === "left" ? "flex-start" : "flex-end",
    pointerEvents: disabled ? "none" : "auto",
  };
};

export const getResponsiveSelectCustomSx = (props: {
  textAlign: InputTextAlign;
  size: MakeResponsive<TextInputSize>;
  theme: BiomeTheme;
  disabled?: boolean;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getSelectCustomSx as unknown as (
      props: RenderSxProps<TextInputSize>,
    ) => Properties,
  });
