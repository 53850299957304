import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { CHIP_SIZES, DEFAULT_CHIP_SIZE } from "@/constants";
import { useTheme } from "@/hooks";
import { getStartingSize } from "@/utils";

import type { ChipIconProps } from "./shared";
import { baseChipLeftImageContentSx, getResponsiveChipIconSx } from "./styles";

export function ChipIcon<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_CHIP_SIZE,
  className,
  sx = {},
  ...props
}: ChipIconProps<RC>) {
  const theme = useTheme();
  const startingSize = getStartingSize(size, DEFAULT_CHIP_SIZE, CHIP_SIZES);
  const mergedSx = useMemo(
    () =>
      merge(
        baseChipLeftImageContentSx,
        {
          fill: "base.color.text.body.primary",
          w:
            startingSize === "large"
              ? "base.icon.size.200"
              : "base.icon.size.100",
        },
        getResponsiveChipIconSx({ size, theme }),
        sx,
      ),
    [size, startingSize, theme, sx],
  );

  return (
    <Icon {...props} className={`${className ?? ""} ChipIcon`} sx={mergedSx} />
  );
}

ChipIcon.displayName = "Chip.Icon";
