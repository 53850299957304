import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import type { CaptionProps } from "@/types";
import { lineClamp } from "../../utils/sxChunks";
import { Caption } from "../Text";

export type CardCaptionProps<RC extends ReactElement | undefined = undefined> =
  CaptionProps<RC>;

export function CardCaption<RC extends ReactElement | undefined = undefined>({
  size = "small",
  weight = "bold",
  sx = {},
  rc = <span />,
  className,
  ...props
}: CardCaptionProps<RC>) {
  return (
    <Caption
      {...props}
      sx={merge(
        {
          textAlign: "left",
          ...lineClamp(1),
        },
        sx,
      )}
      className={`${className ?? ""} CardCaption`}
      size={size}
      weight={weight}
      rc={rc}
    />
  );
}

CardCaption.displayName = "Card.Caption";
