import { type ReactNode, createContext, useId } from "react";

export const BiomePortalIdContext = createContext<string | undefined>(
  undefined,
);

export function BiomePortalIdProvider({ children }: { children: ReactNode }) {
  const id = useId().replaceAll(":", "-");
  return (
    <BiomePortalIdContext.Provider value={id}>
      {children}
    </BiomePortalIdContext.Provider>
  );
}
