import {
  MenuItemFramedLogo,
  type MenuItemFramedLogoProps,
} from "../MenuItem/MenuItemFramedLogo";

export type SelectDefaultFramedLogoProps = MenuItemFramedLogoProps;

export function SelectDefaultFramedLogo({
  className,
  ...props
}: SelectDefaultFramedLogoProps) {
  return (
    <MenuItemFramedLogo
      {...props}
      className={`${className ?? ""} SelectDefaultFramedLogo`}
    />
  );
}

SelectDefaultFramedLogo.displayName = "Select.DefaultFramedLogo";
