import type { AllIconKeys, DeeplyNestedSx, UnknownReactProps } from "@/types";
import { jsx } from "@emotion/react";
import type { Properties } from "csstype";
import {
  Children,
  type FunctionComponent,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  isValidElement,
} from "react";

export type ReactElementWithRef = ReactElement<UnknownReactProps> & {
  ref?: HTMLOrSVGElement;
};

export default function getChildRef(element: ReactElement<UnknownReactProps>) {
  if (!element || !isValidElement(element)) return null;

  // @NOTE: 'ref' is passed as prop in React 19, whereas 'ref'
  // is directly attached to children in React 18.
  // The below check is to ensure 'ref' is accessible in both cases
  // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
  return element.props.propertyIsEnumerable("ref")
    ? element?.props?.ref
    : (element as ReactElementWithRef)?.ref;
}

export function cloneElementWithCssProp(
  element: ReactElement,
  props: UnknownReactProps,
) {
  const typedElement = element as ReactElementWithRef;
  const ref = getChildRef(typedElement);
  // @TODO: the Timeline component, sadly, still relies on this unsafe emotion prop. :(
  // EMOTION_TYPE handles non-React elements (native JSX/HTML nodes)
  const clonedElement =
    (typedElement?.props
      ?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__ as FunctionComponent<UnknownReactProps>) ||
    typedElement.type;

  const clonedProps: UnknownReactProps = {
    key: typedElement.key,
    ...typedElement?.props,
    ...(ref ? { ref } : {}),
    ...props,
  };

  // @NOTE: We need to merge the css prop from the original element with the new props
  if (props.css || typedElement.props.css) {
    clonedProps.css = [typedElement.props.css, props.css];
  }

  return jsx(clonedElement, clonedProps);
}

export function isChildCustomReactComponent(child: ReactNode) {
  if (typeof child === "object" && isValidElement(child)) {
    return typeof child.type === "function";
  }
  return false;
}

export const serializeChildren = (children: ReactNode | unknown) => {
  // Comparison function to JSON.stringify that can handle
  // circular references and ignores internal React properties.
  // https://github.com/facebook/react/issues/8669#issuecomment-531515508
  function circular() {
    const seen = new WeakSet();
    return (key: string, value: unknown) => {
      // @NOTE: Don't compare React's internal props.
      if (key.startsWith("_")) return null;

      // @NOTE: Sometimes components have a renderProp function as their children,
      // when this is the case, we need to make sure that this rendered
      // children content is included in the serialised outout (so we can diff it correctly)
      if (key === "children" && typeof value === "function") {
        const renderedChildren = value({});
        return serializeChildren(renderedChildren);
      }

      if (typeof value === "bigint") {
        return value.toString();
      }

      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) return null;
        seen.add(value);
      }

      return value;
    };
  }

  return JSON.stringify(children, circular());
};

export function warnUser(message: string) {
  if (process.env.NODE_ENV !== "production") {
    console.warn(`🧬 BIOME Warning 🚨: ${message}`);
  }
}

export function renderNullAndWarnUser(
  componentName: string,
  errorMessage = `A component (${componentName}) is currently rendering null, instead of expected subcomponent children. 
This is normally due the usage of non-subcomponent children, within a component which expects only subcomponents children.`,
) {
  warnUser(errorMessage);
  return null;
}

export const noop = () => {};

function isDOMTypeElement(
  element: ReactNode,
): element is ReactElement & { type: string } {
  return isValidElement(element) && typeof element.type === "string";
}

type ReactElementWithDisplayName = ReactElement<{ children?: ReactNode }> & {
  type: { displayName?: string };
};

// @TODO: we use displayName here, instead of the actual component function
// simply to avoid depednency cycle errors. This is a temporary solution,
// perhaps there's a better option
function isChildClickable(child: ReactNode) {
  const isDomClickable =
    isDOMTypeElement(child) && child.type?.match(/a|button/);
  const biomeTypedChild = child as ReactElementWithDisplayName;
  return (
    isDomClickable ||
    biomeTypedChild.type?.displayName?.match(
      /BaseClickable|Button|ButtCon|Link|SimpleIconButton|IconButton|MenuItem/,
    )
  );
}

// @TODO: we use displayName here, instead of the actual component function
// simply to avoid depednency cycle errors. This is a temporary solution,
// perhaps there's a better option
export function checkChildrenForClickables(children: ReactNode): boolean {
  return Children.toArray(children).some((child) => {
    const biomeTypedChild = child as ReactElementWithDisplayName;
    const isContainer =
      biomeTypedChild.type?.displayName?.match(/Box|Stack|Grid/);
    if (isContainer) {
      return checkChildrenForClickables(biomeTypedChild?.props?.children);
    }

    return isChildClickable(child);
  });
}

type ChildWithSxProp = ReactElement<{ sx: DeeplyNestedSx }>;
export function childHasSxProp(child: ReactNode): child is ChildWithSxProp {
  const typedChild = child as ChildWithSxProp;
  return typedChild?.props?.sx !== undefined;
}

type ChildWithCssProp = ReactElement<{ css: Properties }>;
export function childHasCssProp(child: ReactNode): child is ChildWithCssProp {
  const typedChild = child as ChildWithCssProp;
  return typedChild?.props?.css !== undefined;
}

type ChildWithIconProp = ReactElement<{ icon: AllIconKeys }>;
export function childHasIconProp(child: ReactNode): child is ChildWithIconProp {
  const typedChild = child as ChildWithIconProp;
  return typedChild?.props?.icon !== undefined;
}

type ChildWithClassNameProp = ReactElement<{ className: string }>;
export function childHasClassNameProp(
  child: ReactNode,
): child is ChildWithClassNameProp {
  const typedChild = child as ChildWithClassNameProp;
  return typedChild?.props?.className !== undefined;
}

type ChildWithAltProp = ReactElement<{ alt: string }>;
export function childHasAltProp(child: ReactNode): child is ChildWithAltProp {
  const typedChild = child as ChildWithAltProp;
  return typedChild?.props?.alt !== undefined;
}

export function isValidUseProp(
  use: ReactNode,
): use is ReactElement<UnknownReactProps> {
  return isValidElement(use);
}

type ChildWithOnClickProp = ReactElement<{
  onClick?: (ev: MouseEvent<unknown>) => void;
}>;
export function childHasOnClickProp(
  child: unknown,
): child is ChildWithOnClickProp {
  if (!child) return false;
  const typedChild = child as ChildWithOnClickProp;
  return typedChild?.props?.onClick !== undefined;
}

type ChildWithTestIdProp = ReactElement<{ testId: string }>;
export function childHasTestIdProp(
  child: ReactNode,
): child is ChildWithTestIdProp {
  const typedChild = child as ChildWithTestIdProp;
  return typedChild?.props?.testId !== undefined;
}

type ChildWithWidthProp = ReactElement<{ width: string }>;
export function childHasWidthProp(
  child: ReactNode,
): child is ChildWithWidthProp {
  const typedChild = child as ChildWithWidthProp;
  return typedChild?.props?.width !== undefined;
}

type ChildWithHeightProp = ReactElement<{ height: string }>;
export function childHasHeightProp(
  child: ReactNode,
): child is ChildWithHeightProp {
  const typedChild = child as ChildWithHeightProp;
  return typedChild?.props?.height !== undefined;
}

type ChildWithChildrenProp = ReactElement<{ children: ReactNode }>;
export function childHasChildrenProp(
  child: ReactNode,
): child is ChildWithChildrenProp {
  const typedChild = child as ChildWithChildrenProp;
  return typedChild?.props?.children !== undefined;
}
