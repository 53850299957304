import type { TIMELINE_VARIANTS } from "@/constants";
import type { MakeResponsive, StandardComponentWithProps } from "@/types";
import type { ReactElement, ReactNode } from "react";
import type { StackProps } from "../Stack";

export type TimelineVariant = (typeof TIMELINE_VARIANTS)[number];
export type TimelineProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    variant?: MakeResponsive<TimelineVariant>;
    children: ReactNode;
    allStepsComplete?: boolean;
    currentStep?: number;
    showCurrentStepSpinner?: boolean;
  }
>;

export type TimelineStepProps<RC extends ReactElement | undefined = undefined> =
  Omit<StackProps<RC>, "children"> & {
    allStepsComplete?: boolean;
    activeStepIndex?: number;
    variant?: MakeResponsive<TimelineVariant>;
    stepIndex?: number;
    totalSteps?: number;
    label: string;
    caption?: ReactNode;
    customDot?: ReactNode;
    showCurrentStepSpinner?: boolean;
  };

export function useGetBasicLayoutMode(variantClass: string) {
  return variantClass.includes("vertical") ? "vertical" : "horizontal";
}
