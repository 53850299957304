import { useMemo } from "react";

import { useWindowSizeStore } from "@/providers/BiomeWindowSize";
import type { MakeResponsive, SafeSizes } from "@/types";
import { getCurrentSizeClass } from "@/utils";
import { getStartingSize } from "@/utils/styleHelpers";
import { useTheme } from "./useTheme";

export function useGetCurrentSizeClass<Size extends string>(
  size: MakeResponsive<Size>,
  defaultSize: Size,
  safeSizes: SafeSizes,
) {
  const startingSize = getStartingSize(size, defaultSize, safeSizes);
  const { state: windowWidth } = useWindowSizeStore((state) => state.width);
  const {
    base: { breakpointAsArray },
  } = useTheme();
  const currentSizeClass = useMemo(
    () =>
      getCurrentSizeClass(
        size,
        startingSize,
        safeSizes,
        breakpointAsArray,
        windowWidth,
      ),
    [windowWidth, size, safeSizes, startingSize, breakpointAsArray],
  );

  return currentSizeClass;
}
