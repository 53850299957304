import { DEFAULT_TEXT_INPUT_SIZE } from "@/constants";
import type { DomPropsWithDomRef } from "@/types";
import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";
import { SmartClone } from "../SmartClone";
import { Stack } from "../Stack";
import {
  type InputGroupRowBaseProps,
  getSizePropForInput,
  isSupportedInputComponent,
} from "./shared";
import { baseRowSx } from "./styles";

export function InputGroupRow<RC extends ReactElement | undefined = undefined>({
  textAlign,
  children,
  className,
  testId,
  validationStatus,
  size = DEFAULT_TEXT_INPUT_SIZE,
  sx = {},
  id,
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & InputGroupRowBaseProps
  : InputGroupRowBaseProps & { rc: RC }) {
  const mergedRowSx = merge(baseRowSx, sx);
  const flattendChildren = useMemo(() => flattenChildren(children), [children]);
  return (
    <Stack
      {...props}
      direction="row"
      sx={mergedRowSx}
      testId={testId}
      className={`${className ?? ""} InputGroupRow`}
    >
      {Children.map(flattendChildren, (child) => {
        const isSupportedInput = isSupportedInputComponent(child);
        const sizeProp = getSizePropForInput(child, size);
        if (isSupportedInput) {
          return (
            <SmartClone
              {...sizeProp}
              sx={{ textAlign }}
              testId={`${testId}__TextInput`}
              validationStatus={validationStatus}
              textAlign={textAlign}
              className="InputGroupRow__child"
            >
              {child}
            </SmartClone>
          );
        }

        // @TODO: should we support any component inside here?
        return null;
      })}
    </Stack>
  );
}

InputGroupRow.displayName = "InputGroup.Row";
