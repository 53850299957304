import type { ReactElement } from "react";

import type { RATING_SIZES } from "@/constants";
import type {
  DomPropsWithDomRef,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";

export type RatingSize = (typeof RATING_SIZES)[number];
type RatingChangeFunction = (value: number) => void;

export type RatingDiscriminatedUnion<T> =
  | (T & {
      currentRating: number;
      onRatingChange: RatingChangeFunction;
    })
  | (T & {
      defaultRating?: number;
      onRatingChange?: RatingChangeFunction;
      currentRating?: never;
    });

type BaseRatingProps = StandardComponentWithProps<
  HTMLDivElement,
  RatingDiscriminatedUnion<{
    size?: MakeResponsive<RatingSize>;
    step?: 1 | 0.5;
    maxStars?: number;
    disabled?: boolean;
  }>
>;

export type RatingProps<RC extends ReactElement | undefined = undefined> =
  RC extends undefined
    ? DomPropsWithDomRef<"div"> & BaseRatingProps
    : BaseRatingProps & { rc: RC };

export function pickStarSize(size: string) {
  switch (size) {
    case "small":
      return 12;
    case "medium":
      return 16;
    case "large":
      return 20;
    case "xLarge":
      return 32;
  }
}

export function pickGapSpacingModifier(size: string) {
  switch (size) {
    case "small":
      return 0;
    case "medium":
      return 0;
    case "large":
      return 1;
    case "xLarge":
      return 2;
  }
}

export function pickStarKind(
  starNumber: number,
  rating: number,
  step: 0.5 | 1,
): "full" | "half" | "empty" {
  const ratingToUse = rating - starNumber + 1;
  if (ratingToUse >= 1) {
    return "full";
  }
  if (ratingToUse >= 0.5 && step === 0.5) {
    return "half";
  }
  return "empty";
}

export function isHovering(starNumber: number, rating: number | null) {
  if (rating === null) {
    return false;
  }

  // return isHovering;
  switch (starNumber) {
    case 1:
      return rating > 0 && rating <= 1;
    case 2:
      return rating > 1 && rating <= 2;
    case 3:
      return rating > 2 && rating <= 3;
    case 4:
      return rating > 3 && rating <= 4;
    case 5:
      return rating > 4 && rating <= 5;
  }
}
