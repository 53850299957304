"use client";

import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from "react";

export type DispatchActionPayload = {
  id: string;
} & Record<string, unknown>;

// @TODO: Find a way to make this more type safe
// EG. pass in the action types into createFastContext and have
// it return a discriminated union of the action types?
export type FastContextDispatchAction = {
  type: string;
  payload?: DispatchActionPayload;
};

export function createFastContext<
  Store,
  Action extends FastContextDispatchAction,
>(initialState: Store, reducer?: (store: Store, action: Action) => Store) {
  type UseStoreDataReturnType = {
    get: () => Store;
    set: (value: Partial<Store>) => void;
    subscribe: (callback: () => void) => () => void;
    dispatch: (action: Action) => void;
  };
  function useStoreData(): UseStoreDataReturnType {
    const store = useRef(initialState);
    const get = useCallback(() => store.current, []);
    const subscribers = useRef(new Set<() => void>());
    const set = useCallback((value: Partial<Store>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const dispatch = useCallback((action: Action) => {
      if (reducer) {
        store.current = reducer(store.current, action);
        subscribers.current.forEach((callback) => callback());
      } else {
        throw new Error(
          "Dispatching actions is only possible when a reducer is provided",
        );
      }
    }, []);
    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    // @NOTE: ensure that these useCallback functions are apprioriately named
    // inside of React Dev tools (eases debugging)
    Object.defineProperty(get, "name", { value: "get", writable: false });
    Object.defineProperty(set, "name", { value: "set", writable: false });
    Object.defineProperty(subscribe, "name", {
      value: "subscribe",
      writable: false,
    });
    Object.defineProperty(dispatch, "name", {
      value: "dispatch",
      writable: false,
    });
    return { get, set, subscribe, dispatch };
  }

  const StoreContext = createContext<UseStoreDataReturnType | null>(null);
  function Provider({ children }: { children: ReactNode }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }
  // @NOTE: allow the naming of this Provider in the consumer of it
  // (mostly for debugging purposes)
  Provider.displayName = "";

  type UseStoreHookReturnType<T> = {
    state: T;
    setState: (value: Partial<Store>) => void;
    dispatchAction: (action: Action) => void;
  };

  function useStore<SelectorOutput>(
    selector: (store: Store) => SelectorOutput,
  ): UseStoreHookReturnType<SelectorOutput> {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => selector(store.get()),
      () => selector(initialState),
    );

    return { state, setState: store.set, dispatchAction: store.dispatch };
  }

  return {
    Provider,
    useStore,
  };
}
