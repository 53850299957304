import type { BiomeTheme } from "@/types";
import { hFlex } from "@/utils";
import { getTertiaryButtonStyles } from "../Clickable/Buttons/styles/tertiaryStyles";

export const getContainerSx = (theme: BiomeTheme) => {
  const smallButtConStyles = getTertiaryButtonStyles("small", theme, false);
  return {
    brad: "base.borderRadius.x4",
    overflow: "hidden",
    transitionProperty: "background-color",
    transitionDuration: "base.motion.normal.fast.cssDuration",
    transitionTimingFunction: "base.motion.normal.fast.cssEase",

    "&.emphasized": {
      bgc: "base.color.translucent.emphasis.100",
    },

    "&.expanded": {
      bgc: "base.color.translucent.emphasis.100",
    },

    "&.emphasized.expanded": {
      bgc: "base.color.translucent.emphasis.200",
    },

    "&:hover": {
      bgc: "base.color.translucent.emphasis.200",
    },

    "&.emphasized:hover": {
      bgc: "base.color.translucent.emphasis.300",
    },

    "&:hover > .Accordion__clickableArea > .ButtCon": {
      ...smallButtConStyles["&:hover:not([disabled])"],
    },
  };
};

export const clickableAreaSx = {
  ...hFlex,
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "base.spacing.x4",
  position: "relative",
  px: "base.spacing.x3",
  py: "base.spacing.x4",
  minh: "base.spacing.x16",
};

export const buttConSx = {
  position: "absolute",
  top: "base.spacing.x4",
};

export const expandableAreaSx = {
  overflow: "visible",
  bg: "transparent",
  maxh: "unset",
  boxShadow: "unset",
  brad: "unset",
  p: "0",
};

export const expandedContentContainer = {
  mb: "base.spacing.x10",
  px: "base.spacing.x3",

  "& .Divider:first-of-type": {
    mb: "base.spacing.x4",
  },
};
