import { merge } from "ts-deepmerge";

import { useFetchLogoContent } from "@/hooks/useFetchLogoContent";
import type { NestedLogoComponentProps } from "@/types";
import { SvgIcon } from "../SvgIcon";

export function PassportHorizontalLockup({
  sx = {},
  testId,
  ...props
}: NestedLogoComponentProps) {
  const svgContent = useFetchLogoContent("PassportHorizontalLockup");
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 328 48"
      testId={testId}
      sx={merge({ w: "200px", fill: "base.color.brand.1" }, sx)}
    >
      {svgContent && (
        <g
          data-testid={`${testId}__g`}
          dangerouslySetInnerHTML={{ __html: svgContent?.join?.("") }}
        />
      )}
    </SvgIcon>
  );
}

PassportHorizontalLockup.displayName = "PassportHorizontalLockup";
