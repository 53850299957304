export const baseOuterContainerSx = {
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "flex-end",
  textAlign: "right",
  wordBreak: "break-word",
  position: "relative",
  c: "base.color.text.body.primary",
};

export const CURRENCY_IMAGE_SIZE = "0.84em";

export const baseImageSx = {
  width: CURRENCY_IMAGE_SIZE,
  minw: "unset",
  mt: "-0.08em",
};

export const priceContentSx = {
  d: "flex",
  alignItems: "center",
  gap: "0.3em",
  color: "inherit",
};
