import { type RefObject, useRef } from "react";

import { noop } from "@/utils";
import { useBrowserLayoutEffect } from "./useBrowserLayoutEffect";

/**
 * This is a TypeScript function that adds an event listener to a specified DOM element or the window
 * and removes it on cleanup.
 * @param {keyof WindowEventMap | string} eventName - The name of the event to listen for, which can be
 * a key of the `WindowEventMap` interface or a custom event name as a string.
 * @param handler - The function that will be called when the event is triggered. It takes an event
 * object as its argument.
 * @param {RefObject<T> | MutableRefObject<T>} [domRef] - `domRef` is an optional parameter that can be
 * a `RefObject` or `MutableRefObject` that points to the DOM element that the event listener should be
 * attached to. If `domRef` is not provided, the event listener will be attached to the `window`
 * object.
 */
export function useEventListener<
  T extends
    | HTMLElement
    | Document
    | Window
    | ShadowRoot
    | null = HTMLDivElement,
  E extends Event = Event,
>(
  eventName: keyof WindowEventMap | string, // string to allow custom event
  handler: (event: E) => void,
  domRef?: RefObject<T>,
  eventOptions?: boolean | AddEventListenerOptions,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: E) => void>(noop);

  useBrowserLayoutEffect(() => {
    // Define the listening target
    const targetElement = domRef?.current;
    if (!targetElement) {
      return () => ({});
    }

    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler;
    }

    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      savedHandler?.current?.(event as E);
    };

    targetElement.addEventListener(eventName, eventListener, eventOptions);

    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener);
    };
  }, [eventName, domRef, handler]);
}
