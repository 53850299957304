import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_LOADING_IMG_ATTR } from "@/constants";
import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import type { CloudImageProps } from "@/types";
import {
  childHasAltProp,
  childHasClassNameProp,
  cloneElementWithCssProp,
  isImageResizerProps,
  isValidUseProp,
} from "@/utils";

import { AwsResizerImage } from "./AwsResizerImage";

export function CloudImage<Use extends ReactElement | undefined = undefined>({
  testId = "CloudImage",
  className,
  domRef,
  loading = DEFAULT_LOADING_IMG_ATTR,
  sx = {},
  use,
  alt,
  ...props
}: CloudImageProps<Use>) {
  const mergedSx = useMemo(
    () => merge({ minw: "base.icon.size.200" }, sx),
    [sx],
  );
  const customStyles = useConvertSxToEmotionStyles(mergedSx);
  const mergedClassName = `${className ?? ""} ${childHasClassNameProp(use) ? use.props.className : ""} CloudImage`;
  const shouldUseImageResizer = isImageResizerProps(use, props);
  const altToUse = alt
    ? alt
    : childHasAltProp(use)
      ? use.props.alt
      : "CloudImage";

  return shouldUseImageResizer ? (
    <AwsResizerImage
      {...props}
      className={mergedClassName}
      domRef={domRef}
      css={customStyles}
      loading={loading}
      testId={testId}
      alt={altToUse}
    />
  ) : isValidUseProp(use) ? (
    cloneElementWithCssProp(use, {
      ...props,
      ...use?.props,
      loading,
      ref: use?.props.ref ?? domRef,
      css: customStyles,
      className: mergedClassName,
      "data-testid": testId,
      alt: altToUse,
    })
  ) : null;
}

CloudImage.displayName = "CloudImage";
