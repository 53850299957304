import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DuoCon } from "@/components/DuoCon";
import type { DuoConProps } from "@/types";

import { bulletListTitleDuoConSx } from "./styles";

type BulletListTitleDuoConProps<
  RC extends ReactElement | undefined = undefined,
> = DuoConProps<RC> & {};

export function BulletListTitleDuoCon<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  rc = <span />,
  testId = "BulletListTitleDuoCon",
  className,
  ...props
}: BulletListTitleDuoConProps<RC>) {
  const mergedSx = useMemo(() => merge(bulletListTitleDuoConSx, sx), [sx]);
  return (
    <DuoCon
      {...props}
      sx={mergedSx}
      rc={rc}
      testId={testId}
      className={`${className ?? ""} BulletListTitleDuoCon`}
      motionProfile="none"
    />
  );
}

BulletListTitleDuoCon.displayName = "BulletList.Title.DuoCon";
