import type { DeeplyNestedSx } from "@/types";

export const linkBaseSx: DeeplyNestedSx = {
  verticalAlign: "baseline",
  textAlign: "inherit",
  d: "inline",
  alignItems: "unset",

  "&.Link--primary": {
    "--linkColor": "base.color.text.link.primary",
    // @TODO: @tony will provide these colors at a later date.
    "--linkHoverColor": "base.color.text.link.primary",
    "--linkVisitedColor": "base.color.text.link.primary",
  },

  "&.Link--secondary": {
    "--linkColor": "base.color.text.link.secondary",
    // @TODO: @tony will provide these colors at a later date.
    "--linkHoverColor": "base.color.text.link.secondary",
    "--linkVisitedColor": "base.color.text.link.secondary",
  },

  "&:hover:not([disabled])": {
    "& .Link__innerContainer": {
      c: "var(--linkHoverColor)",
      textDecoration: "underline",
    },
    "& .LinkIcon": {
      fill: "var(--linkHoverColor)",
    },
  },

  "&:active:not([disabled])": {
    "& .Link__innerContainer": {
      c: "var(--linkHoverColor)",
      textDecoration: "underline",
    },
    "& .LinkIcon": {
      fill: "var(--linkHoverColor)",
    },
  },

  "&:visited": {
    "& .Link__innerContainer": {
      c: "var(--linkVisitedColor)",
    },
    "& .LinkIcon": {
      fill: "var(--linkVisitedColor)",
    },
  },

  "&[disabled]": {
    cursor: "default",
  },
};

export const innerContainerSx = {
  textAlign: "inherit",
  c: "inherit",

  ".Link__innerContainer__text + .LinkIcon": {
    ml: "0.2em",
  },

  ".LinkIcon + .Link__innerContainer__text": {
    ml: "0.2em",
  },
};

export const linkIconBaseSx = {
  fill: "var(--linkColor)",
  verticalAlign: "middle",
  mt: "-0.1em",
  w: "1em",
};
