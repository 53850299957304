import { merge } from "ts-deepmerge";

import { Logo } from "@/components/Logo";
import { useTheme } from "@/hooks/useTheme";
import type {
  ButtonSize,
  ButtonVariant,
  LogoProps,
  MakeResponsive,
} from "@/types";

import { useMemo } from "react";
import {
  getButtonIconOrLogoSizeStyles,
  getButtonIconVariantStyles,
  getResponsiveButtonIconOrLogoStyles,
} from "./styles";

export type ButtonLogoProps = LogoProps & {
  size?: MakeResponsive<ButtonSize>;
  variant?: MakeResponsive<ButtonVariant>;
};

export function ButtonLogo({
  sx = {},
  size = "medium",
  variant = "primary",
  logo,
  ...props
}: ButtonLogoProps) {
  const themeProps = useTheme();
  const {
    defaultSize,
    defaultVariant,
    styles: responsiveStyles,
  } = getResponsiveButtonIconOrLogoStyles({
    size,
    variant,
    themeProps,
  });
  const allStyles = useMemo(
    () =>
      merge(
        { flexShrink: 0 },
        getButtonIconOrLogoSizeStyles(defaultSize, themeProps),
        getButtonIconVariantStyles(defaultVariant, themeProps),
        responsiveStyles,
        sx,
      ),
    [defaultSize, defaultVariant, responsiveStyles, sx, themeProps],
  );
  return <Logo {...props} sx={allStyles} logo={logo} />;
}

ButtonLogo.displayName = "Button.Logo";
