import type { HeadingSize } from "@biom3/design-tokens";
import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { DUMMY_WIDTH_PUSHER_TEXT } from "@/constants";
import {
  useBrowserEffect,
  useConvertSxToEmotionStyles,
  useForwardLocalDomRef,
  useGetMotionProfile,
  usePrevious,
  useResizeObserver,
  useTheme,
} from "@/hooks";
import type { HeroTextInputProps } from "@/types";
import {
  cloneElementWithCssProp,
  getHeadingTextStyles,
  getMotionProfileSx,
} from "@/utils";
import { baseContainerSx, baseDummyDisplaySx, baseInputCss } from "./styles";

export function HeroTextInput<RC extends ReactElement | undefined = undefined>({
  domRef,
  inputRef = { current: null },
  rc,
  placeholder,
  sx = {},
  testId = "HeroTextInput",
  name,
  id = name,
  children,
  className,
  validationStatus,
  onChange,
  weight = "bold",
  value,
  // @NOTE: this would normally be undefined, but then we get react errors
  // around a component switching between controlled and uncontrolled.
  defaultValue = "",
  disabled,
  motionProfile,
  maxTextSize = "xxLarge",
  ...inputDomAttributes
}: RC extends undefined
  ? HeroTextInputProps
  : HeroTextInputProps & { rc: RC }) {
  const testTextRef = useRef<HTMLDivElement>(null);
  const localInputRef = useForwardLocalDomRef(inputRef);
  const [uncontrolledValue, setUncontrolledValue] = useState<
    ComponentPropsWithoutRef<"input">["value"]
  >(
    typeof value === "number"
      ? value.toString()
      : typeof value === "string"
        ? value
        : defaultValue,
  );

  const valueToUse = value ?? uncontrolledValue;

  const { width: inputWidth } = useResizeObserver(localInputRef);
  const { width: testTextWidth } = useResizeObserver(testTextRef);
  const theme = useTheme();
  const [size, setSize] = useState<HeadingSize>(maxTextSize);
  const previousTestTextWidth = usePrevious(testTextWidth);
  const { base } = theme;
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const useMotion = motionProfileToUse !== "none";

  useBrowserEffect(() => {
    // @NOTE: prevent infinite loop, when size changes
    // So only run this code when the testTextWidth is changing:
    if (testTextWidth === previousTestTextWidth) return;

    // @NOTE: prevent the below logic running when the DOM
    // is not yet ready (eg elements have no widths)
    if (inputWidth === 0 || testTextWidth === 0) return;

    const headingSizes = Object.keys(theme.base.text.heading);
    const currentSizeIndex = headingSizes.indexOf(size);

    // @NOTE: make size smaller if the text is too big
    if (testTextWidth >= inputWidth) {
      // @NOTE: dont make the size smaller if the text is already the smallest size
      if (currentSizeIndex === headingSizes.length - 1) return;

      const newSize = headingSizes[currentSizeIndex + 1] as HeadingSize;
      return setSize(newSize);
    }

    // @NOTE: make size larger if the text is too small
    if (testTextWidth <= inputWidth * 0.6) {
      // @NOTE: dont make the size smaller if the text is already the largest size
      const indexOfLargestSize = headingSizes.indexOf(maxTextSize);
      if (currentSizeIndex === indexOfLargestSize) return;

      const newSize = headingSizes[currentSizeIndex - 1] as HeadingSize;
      return setSize(newSize);
    }
  }, [inputWidth, testTextWidth, theme, size, previousTestTextWidth]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (value === undefined) setUncontrolledValue(event.target.value);
      onChange?.(event);
    },
    [onChange, value],
  );

  const mergedContainerSx = useMemo(() => merge(baseContainerSx, sx), [sx]);
  const baseTextCss = useMemo(
    () =>
      getHeadingTextStyles({
        size,
        weight,
        themeProps: theme,
      }),
    [size, weight, theme],
  );

  const mergedDummyDisplaySx = useMemo(
    () => merge(baseTextCss, baseDummyDisplaySx),
    [baseTextCss],
  );
  const conditionalMotionSx = getMotionProfileSx(motionProfileToUse, theme);
  const convertedInputStyles = useConvertSxToEmotionStyles(
    merge(baseInputCss, baseTextCss, conditionalMotionSx, {
      transitionProperty: "font-size, line-height",
      color:
        validationStatus === "error"
          ? base.color.text.status.fatal.primary
          : base.color.text.heading.primary,
    }),
  );
  const mergedDummyFlexWidthPusherSx = useMemo(
    () =>
      merge(baseTextCss, conditionalMotionSx, {
        c: "gold",
        flexGrow: 1,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        opacity: 0,

        "&::before": {
          content: `"${DUMMY_WIDTH_PUSHER_TEXT}"`,
          d: "inline",
        },
      }),
    [baseTextCss, conditionalMotionSx],
  );

  const memoizedInputProps = useMemo(
    () => ({
      disabled,
      name,
      ref: localInputRef,
      "data-testid": `${testId}__input`,
      onChange: handleInputChange,
      value: valueToUse,
      id,
      placeholder,
      css: convertedInputStyles,
      className: "HeroTextInput__input",
    }),
    [
      disabled,
      name,
      localInputRef,
      testId,
      valueToUse,
      id,
      placeholder,
      convertedInputStyles,
      handleInputChange,
    ],
  );

  const input = cloneElementWithCssProp(<input />, {
    ...inputDomAttributes,
    ...memoizedInputProps,
  });

  return (
    <Box
      sx={mergedContainerSx}
      className={`${className ?? ""} HeroTextInput HeroTextInput--${size}`}
      testId={testId}
      domRef={domRef}
      rc={rc}
    >
      {/* @NOTE: this element drives the actual block height of this component */}
      <Box
        sx={mergedDummyFlexWidthPusherSx}
        testId={`${testId}__dummyFlexWidthPusherContainer`}
        className="dummyFlexWidthPusherContainer"
      />

      {/* @NOTE: this element is what we measure to work out how to 
          fit all characters inside the input without overflow ... */}
      <Box
        sx={mergedDummyDisplaySx}
        domRef={testTextRef}
        testId={`${testId}__measuredTextContainer`}
        className="measuredTextContainer"
      >
        {valueToUse || placeholder}
      </Box>

      {input}
    </Box>
  );
}

HeroTextInput.displayName = "HeroTextInput";
