import { type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import type { BiomeTheme, BoxWithRCAndDomProps } from "@/types";
import { Box } from "../Box";
import { SpineMenuDivider } from "./SpineMenuDivider";
import { SpineMenuItem } from "./SpineMenuItem";
import { spineMenuSx } from "./styles";

export const SPINE_MENU_SIZES = ["small", "medium", "large"] as const;
export const DEFAULT_SPINE_MENU_SIZE: SpineMenuSize = "medium";
export type SpineMenuSize = (typeof SPINE_MENU_SIZES)[number];

export type SpineMenuBaseProps<
  RC extends ReactElement | undefined = undefined,
> = BoxWithRCAndDomProps<RC> & { spinePosition?: "left" | "right" };

export function SpineMenu<RC extends ReactElement | undefined = undefined>({
  sx = {},
  children,
  rc = <aside />,
  spinePosition = "left",
  className,
  ...props
}: SpineMenuBaseProps<RC>) {
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const mergedSx = useMemo(
    () =>
      merge(
        spineMenuSx,
        {
          brad: (theme: BiomeTheme) =>
            spinePosition === "left"
              ? `0px ${theme.base.borderRadius.x8} 0px 0px`
              : `${theme.base.borderRadius.x8} 0px 0px 0px`,
        },
        sx,
      ),
    [spinePosition, sx],
  );
  return (
    <Box
      {...props}
      rc={rc}
      sx={mergedSx}
      className={`${className ?? ""} SpineMenu`}
    >
      {flattenedChildren}
    </Box>
  );
}

SpineMenu.displayName = "SpineMenu";
SpineMenu.Item = SpineMenuItem;
SpineMenu.Divider = SpineMenuDivider;
