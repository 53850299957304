import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedLogo } from "@/components/FramedThings";
import type { FramedLogoProps } from "@/types";
import { baseFramedSx } from "./styles";

export function CardFramedLogo<
  RC extends ReactElement | undefined = undefined,
>({ className, rc = <span />, sx = {}, ...props }: FramedLogoProps<RC>) {
  const mergedSx = useMemo(() => merge(baseFramedSx, sx), [sx]);
  return (
    <FramedLogo
      {...props}
      sx={mergedSx}
      rc={rc}
      size="large"
      className={`${className ?? ""} CardFramedLogo`}
    />
  );
}
CardFramedLogo.displayName = "Card.FramedLogo";
