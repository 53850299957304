import { z } from "zod";

import { type ChainAddress, EnvironmentNames } from "@/types";

const ethereumAddress = () =>
  z.custom<ChainAddress>(
    (val) => typeof val === "string" && val.startsWith("0x"),
  );

export const schema = z
  .object({
    ALCHEMY_API_KEY: z.string().optional(),
    ASSET_BASE_URL: z.string().url(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI: z.string().url(),
    AUTH0_REDIRECT_PLAY_PORTAL_URI: z.string().url(),
    BRAZE_API_KEY: z.string(),
    BRAZE_API_ENDPOINT: z.string(),
    ENVIRONMENT: z.nativeEnum(EnvironmentNames),
    IMAGE_RESIZER_URL: z.string().url(),
    IMMUTABLE_BASE_URI: z.string().url(),
    IMX_CONTRACT_ADDRESS: ethereumAddress(),
    LAUNCH_DARKLY_CLIENT_ID: z.string(),
    LAYERSWAP_CLIENT_ID: z.string(),
    MOONPAY_API_KEY: z.string(),
    SEGMENT_APP_NAME: z.string(),
    SEGMENT_KEY: z.string(),
    TOKEN_ICONS_URL: z.string().url(),
    WALLET_CONNECT_PROJECT_ID: z.string().optional(),
    GTM_CONTAINER_ID: z.string(),
    PLAY_ASSETS_CDN_BASE_URL: z.string().url(),
    HLS_DOMAIN: z.string().url(),
    BLOCK_EXPLORER_URL: z.string().url(),
    GEMS_GAME_CONTRACT_ADDRESS: z.string().length(42),
    PROMO_TILES_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),
    QUESTS_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),
    QUEST_SECTIONS_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),
    DISCOVER_FEED_CMS_URL: z
      .string()
      .url()
      .transform((url) => new URL(url)),

    // Social links
    BASE_REDIRECT_URL: z.string().url(),
    SPLINE_VIEWER: z.string().url(),
  })
  .refine(
    (data) =>
      data.ENVIRONMENT === EnvironmentNames.DEV ||
      ((data.ENVIRONMENT === EnvironmentNames.PRODUCTION ||
        data.ENVIRONMENT === EnvironmentNames.SANDBOX) &&
        data.WALLET_CONNECT_PROJECT_ID !== undefined),
    {
      message:
        "WALLET_CONNECT_PROJECT_ID must be set in production or sandbox environments",
    },
  );

export const appConfigFn = () =>
  schema.parse({
    ALCHEMY_API_KEY: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY,
    ASSET_BASE_URL: process.env.NEXT_PUBLIC_ASSET_BASE_URL,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI:
      process.env.NEXT_PUBLIC_AUTH0_LOGOUT_REDIRECT_PLAY_PORTAL_URI,
    AUTH0_REDIRECT_PLAY_PORTAL_URI:
      process.env.NEXT_PUBLIC_AUTH0_REDIRECT_PLAY_PORTAL_URI,
    BRAZE_API_KEY: process.env.NEXT_PUBLIC_BRAZE_API_KEY,
    BRAZE_API_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_API_ENDPOINT,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    IMAGE_RESIZER_URL: process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL,
    IMMUTABLE_BASE_URI: process.env.NEXT_PUBLIC_IMMUTABLE_BASE_URI,
    IMX_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_IMX_CONTRACT_ADDRESS,
    LAUNCH_DARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    LAYERSWAP_CLIENT_ID: process.env.NEXT_PUBLIC_LAYERSWAP_CLIENT_ID,
    MOONPAY_API_KEY: process.env.NEXT_PUBLIC_MOONPAY_API_KEY,
    SEGMENT_APP_NAME: process.env.NEXT_PUBLIC_SEGMENT_APP_NAME,
    SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    TOKEN_ICONS_URL: process.env.NEXT_PUBLIC_TOKEN_ICONS_URL,
    WALLET_CONNECT_PROJECT_ID:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
    GTM_CONTAINER_ID: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,

    PROMO_TILES_CMS_URL: process.env.NEXT_PUBLIC_PROMO_TILES_CMS_URL,
    QUESTS_CMS_URL: process.env.NEXT_PUBLIC_QUESTS_CMS_URL,
    QUEST_SECTIONS_CMS_URL: process.env.NEXT_PUBLIC_QUEST_SECTIONS_CMS_URL,
    DISCOVER_FEED_CMS_URL: process.env.NEXT_PUBLIC_DISCOVER_FEED_CMS_URL,
    BLOCK_EXPLORER_URL: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL,
    GEMS_GAME_CONTRACT_ADDRESS:
      process.env.NEXT_PUBLIC_GEMS_GAME_CONTRACT_ADDRESS,
    HLS_DOMAIN: process.env.NEXT_PUBLIC_HLS_DOMAIN,

    // Social links
    BASE_REDIRECT_URL: process.env.NEXT_PUBLIC_BASE_REDIRECT_URL,

    SPLINE_VIEWER: process.env.NEXT_PUBLIC_SPLINE_VIEWER,

    // @NOTE: this is the same for all envs for now...
    PLAY_ASSETS_CDN_BASE_URL: "https://play-assets.immutable.com",
  });
