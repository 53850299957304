import type { Properties } from "csstype";

import type { BiomeTheme, ImageSizeVariant, MakeResponsive } from "@/types";
import { getImageSizeMapping } from "@/utils";
import { getResponsiveSx } from "@/utils/styleHelpers";
import { getBodyTextStyles } from "@/utils/textStyleHelpers";

export function getContainerSx({
  size,
  theme: { base },
}: {
  size: ImageSizeVariant;
  theme: BiomeTheme;
}): Properties {
  const sizeMapping = getImageSizeMapping("Avatar", { base });
  return {
    width: sizeMapping[size],
    height: sizeMapping[size],
  };
}

export const getResponsiveContainerSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ImageSizeVariant>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getContainerSx,
  });

export function getTextSx({
  size,
  theme,
}: {
  size: ImageSizeVariant;
  theme: BiomeTheme;
}) {
  switch (size) {
    case "small":
      return getBodyTextStyles({
        size: "xxSmall",
        weight: "bold",
        themeProps: theme,
      });

    case "large":
      return getBodyTextStyles({
        size: "medium",
        weight: "bold",
        themeProps: theme,
      });

    case "xLarge":
      return getBodyTextStyles({
        size: "medium",
        weight: "bold",
        themeProps: theme,
      });

    case "medium":
    default:
      return getBodyTextStyles({
        size: "xSmall",
        weight: "bold",
        themeProps: theme,
      });
  }
}

export const getResponsiveTextSx = (props: {
  theme: BiomeTheme;
  size: MakeResponsive<ImageSizeVariant>;
}) =>
  getResponsiveSx({
    ...props,
    renderSxAtSize: getTextSx,
  });
