import { merge } from "ts-deepmerge";

import type {
  BiomeTheme,
  MakeResponsive,
  MakeWriteable,
  SxProps,
} from "@/types";
import { vFlex } from "@/utils/sxChunks";
import { isError } from "@/utils/textStyleHelpers";
import type { TableSpacing } from "./shared";

export const baseContainerSx = {
  ...vFlex,
  alignItems: "flex-start",
  maxw: "100%",
  margin: "0 auto",
  position: "relative",
};

export const baseTableStyle = {
  border: "none",
  borderCollapse: "collapse",
  d: "table",
  textAlign: "left",
  maxw: "100%",
  w: "100%",
  m: "0",
  overflowX: "auto",
  borderRadius: "base.borderRadius.x6",

  "&.tableBorder": {
    border: "solid",
    borderWidth: "base.border.size.100",
    borderColor: "base.color.translucent.standard.500",
  },

  "&.stickyHeaders.tableBorder": {
    borderTopWidth: "0px",
  },

  "&.stickyTable.tableBorder": {
    border: "none",
  },

  "&.columnBorder .TableCell--td, &.columnBorder .TableCell--th": {
    borderRight: "solid",
    borderRightWidth: "base.border.size.100",
    borderColor: "base.color.translucent.standard.200",
  },

  "&.rowBorder .TableRow": {
    borderBottom: "solid",
    borderBottomWidth: "base.border.size.100",
    borderColor: "base.color.translucent.standard.200",
  },

  "&.rowBorder .TableHead .TableRow": {
    borderColor: "base.color.translucent.standard.200",
  },

  "&.rowBorder .TableFoot .TableRow": {
    borderBottomWidth: "0px",
  },

  "&.rowBorder .TableFoot + .TableFoot .TableRow": {
    borderBottom: "solid",
    borderBottomWidth: "base.border.size.100",
    borderBottomColor: "base.color.translucent.standard.200",
  },

  "& .TableHead .TableRow, & .TableFoot .TableRow": {
    bg: "base.color.translucent.emphasis.400",
  },

  "& .TableBody .TableRow": {
    bg: "base.color.translucent.emphasis.100",
    transitionDuration: "base.motion.normal.fast.cssDuration",
    transitionTimingFunction: "base.motion.normal.fast.cssEase",
    transitionProperty: "background",
  },

  "&.rowHoverFx .TableBody .TableRow": {
    cursor: "pointer",
  },
  "&.rowHoverFx .TableBody .TableRow:hover": {
    bg: "base.color.translucent.emphasis.200",
  },

  "&.stripedBg .TableBody .TableRow:nth-of-type(even)": {
    bg: "base.color.translucent.emphasis.300",

    "&:hover": {
      bg: "base.color.translucent.emphasis.400",
    },
  },

  "& .TextInput": {
    minw: "60px",
  },
};

export function getCellSx({
  horizontalSpacing,
  verticalSpacing,
  theme: { base },
}: {
  horizontalSpacing?: TableSpacing;
  verticalSpacing?: TableSpacing;
  theme: BiomeTheme;
}) {
  const style: MakeWriteable<SxProps, string> = {};

  switch (horizontalSpacing) {
    case "xxSmall":
      style.px = base.spacing.x1;
      break;

    case "xSmall":
      style.px = base.spacing.x2;
      break;

    case "small":
      style.px = base.spacing.x3;
      break;

    case "xLarge":
      style.px = base.spacing.x8;
      break;

    case "xxLarge":
      style.px = base.spacing.x10;
      break;

    case "medium":
      style.px = base.spacing.x6;
      break;

    default:
      break;
  }

  switch (verticalSpacing) {
    case "xxSmall":
      style.py = base.spacing.x1;
      break;

    case "xSmall":
      style.py = base.spacing.x2;
      break;

    case "small":
      style.py = base.spacing.x3;
      break;

    case "xLarge":
      style.py = base.spacing.x8;
      break;

    case "xxLarge":
      style.py = base.spacing.x10;
      break;

    case "medium":
      style.py = base.spacing.x6;
      break;

    default:
      break;
  }

  return style;
}

export function getResponsiveCellSx({
  horizontalSpacing,
  verticalSpacing,
  theme,
}: {
  horizontalSpacing?: MakeResponsive<TableSpacing>;
  verticalSpacing?: MakeResponsive<TableSpacing>;
  theme: BiomeTheme;
}) {
  const horizontalSpacingAsArray = Array.isArray(horizontalSpacing)
    ? [...horizontalSpacing]
    : [horizontalSpacing];
  horizontalSpacingAsArray.shift();
  const verticalSpacingAsArray = Array.isArray(verticalSpacing)
    ? [...verticalSpacing]
    : [verticalSpacing];
  verticalSpacingAsArray.shift();

  const horizontalSizeStyles = merge(
    ...horizontalSpacingAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getCellSx({
            horizontalSpacing: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );

  const verticalSizeStyles = merge(
    ...verticalSpacingAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getCellSx({
            verticalSpacing: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );

  return merge(horizontalSizeStyles, verticalSizeStyles);
}

export const baseCaptionSx = {
  captionSide: "bottom",
  textAlign: "left",
  order: 3,
  c: "base.color.text.body.secondary",
};

export const baseTdSx = {
  verticalAlign: "middle",
};

export function getCaptionSx({
  verticalSpacing,
  theme: { base },
}: {
  verticalSpacing?: TableSpacing;
  theme: BiomeTheme;
}) {
  switch (verticalSpacing) {
    case "xxSmall":
      return { "--captionGap": base.spacing.x1 };

    case "xSmall":
      return { "--captionGap": base.spacing.x2 };

    case "small":
      return { "--captionGap": base.spacing.x3 };

    case "xLarge":
      return { "--captionGap": base.spacing.x8 };

    case "xxLarge":
      return { "--captionGap": base.spacing.x10 };

    case "medium":
    default:
      return { "--captionGap": base.spacing.x6 };
  }
}

export function getResponsiveCaptionSx({
  verticalSpacing,
  theme,
}: {
  verticalSpacing?: MakeResponsive<TableSpacing>;
  theme: BiomeTheme;
}) {
  const verticalSpacingAsArray = Array.isArray(verticalSpacing)
    ? [...verticalSpacing]
    : [verticalSpacing];
  verticalSpacingAsArray.shift();

  return merge(
    ...verticalSpacingAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getCaptionSx({
            verticalSpacing: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );
}
