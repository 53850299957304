import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import type { Property } from "csstype";
import { AnimatePresence, motion } from "motion/react";
import type { ReactElement } from "react";

import { AnimateHeightBox } from "@/components/AnimateHeightBox";
import { Body } from "@/components/Text/Body";
import { useGetMotionProfile } from "@/hooks";
import { useTheme } from "@/hooks/useTheme";
import type { RollingTextProps } from "@/types";
import { childHasChildrenProp, cloneElementWithCssProp } from "@/utils";

const variants = {
  enter: {
    y: "-0.8em",
    opacity: 0,
    position: "absolute" as Property.Position,
  },
  center: {
    y: 0,
    opacity: 1,
    position: "relative" as Property.Position,
  },
  exit: {
    y: "0.8em",
    opacity: 0,
    position: "absolute" as Property.Position,
  },
};

export function RollingText<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  text,
  testId = "RollingText",
  className,
  domRef,
  motionProfile,
  ...props
}: RollingTextProps<RC, Use>) {
  const themeProps = useTheme();
  const { base } = themeProps;
  const motionProfileToUse = useGetMotionProfile(motionProfile);
  const { use, ...otherProps } =
    "use" in props ? props : { ...props, use: undefined };
  const { size } = "size" in props ? props : { ...props, size: undefined };
  const { weight } =
    "weight" in props ? props : { ...props, weight: undefined };

  const textAsKey: string =
    typeof text === "string"
      ? text
      : childHasChildrenProp(text) && typeof text.props.children === "string"
        ? text.props.children
        : (text?.toString() ?? "");

  return (
    <AnimateHeightBox
      {...otherProps}
      motionProfile={motionProfileToUse}
      testId={testId}
      domRef={domRef}
      className={`${className ?? ""} RollingText`}
    >
      {cloneElementWithCssProp(
        use || <Body size={size as BodySize} weight={weight as BodyWeight} />,
        {
          testId: `${testId}__text--${text}`,
          sx: {
            lineHeight: "1",
            d: "flex",
            justifyContent: "center",
            textAlign: "center",
          },
          className: "RollingText__textContainer",
          children: (
            <AnimatePresence>
              {typeof text !== "undefined" && (
                <motion.span
                  style={{
                    position: "absolute",
                  }}
                  className="RollingText__textContainer__animatingSpan"
                  key={textAsKey}
                  data-testid={`${testId}__text--${textAsKey}__animatingSpan`}
                  {...{
                    ...(motionProfileToUse !== "none"
                      ? {
                          variants,
                          initial: "enter",
                          animate: "center",
                          exit: "exit",
                          transition: {
                            duration:
                              base.motion.normal[motionProfileToUse].jsDuration,
                            ease: base.motion.normal[motionProfileToUse].jsEase,
                          },
                        }
                      : {}),
                  }}
                >
                  {text}
                </motion.span>
              )}
            </AnimatePresence>
          ),
        },
      )}
    </AnimateHeightBox>
  );
}

RollingText.displayName = "RollingText";
