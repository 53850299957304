import { Body, Box, Divider, Heading } from "@biom3/react";

export type SectionDividerProps = {
  variant: "default" | "divider";
  title?: string | JSX.Element;
  caption?: string | JSX.Element;
  linePosition: "bottom" | "top" | "none";
};

export function SectionDivider({
  title,
  caption,
  variant,
  linePosition = "bottom",
}: SectionDividerProps) {
  const withoutCopy = variant === "divider" || (!title && !caption);

  if (withoutCopy) {
    return (
      <Divider
        sx={{
          my: "base.spacing.x4",
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        mx: "auto",
        pt: {
          default: "0px",
          large: "base.spacing.x4",
        },
      }}
    >
      {linePosition === "top" ? (
        <Divider
          sx={{
            mb: "base.spacing.x4",
          }}
        />
      ) : null}
      <Box sx={{ mb: "base.spacing.x6" }}>
        {title && (
          <Heading
            // biome-ignore lint/a11y/useHeadingContent: <explanation>
            rc={<h2 />}
            weight="bold"
            size="medium"
          >
            {title}
          </Heading>
        )}
        {caption ? (
          <Body size="small" weight="regular" color="base.text.secondary">
            {caption}
          </Body>
        ) : null}
      </Box>
      {linePosition === "bottom" && (
        <Divider
          sx={{
            mt: "base.spacing.x4",
          }}
        />
      )}
    </Box>
  );
}

export default SectionDivider;
