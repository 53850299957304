import {
  type DependencyList,
  type EffectCallback,
  useEffect,
  useRef,
} from "react";

// useUpdateEffect is a React hook that runs an effect only on updates, not on the initial render.
// If we ever decide to use usehooks-ts, we can use its version of this, with the same name.
export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const firstRender = useRef(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    effect();
  }, deps);
};
