import { merge } from "ts-deepmerge";

import type { BiomeTheme, MakeResponsive } from "@/types";
import { isError } from "../../utils/textStyleHelpers";
import type { OnboardingPaginationSize } from "./types";

export const DEFAULT_PAGINATION_SIZE = "medium";

export const getContainerStyles = ({
  size,
  theme,
}: {
  size: OnboardingPaginationSize;
  theme: BiomeTheme;
}) => ({
  gap: size === "medium" ? theme.base.spacing.x2 : theme.base.spacing.x1,
});

export const getResponsiveContainerStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<OnboardingPaginationSize>;
  theme: BiomeTheme;
}) => {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getContainerStyles({
            size: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
};

export const pageButtonBaseStyles = {
  background: "base.color.translucent.emphasis.100",
  borderWidth: "base.border.size.100",
  borderColor: "base.color.translucent.standard.500",
  borderStyle: "solid",
  cursor: "pointer",
  transition: "width 200ms ease-in-out",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&[disabled]": {
    cursor: "default",
  },

  "&::before": {
    content: "''",
    background: "base.color.translucent.standard.1000",
    opacity: "0",
    transition: "opacity 200ms ease-in-out",
    transitionProperty: "opacity, width, height",
  },

  "&:hover:not([disabled])": {
    borderColor: "base.color.translucent.standard.1000",
  },

  "&:hover:not([disabled])::before, &.selected::before": {
    opacity: "1",
  },

  "&.selected": {
    borderColor: "base.color.translucent.standard.1000",
  },

  "&:hover:not([disabled]).selected": {
    borderWidth: "base.border.size.100",
  },
};

export const getSizeBasedPageButtonStyles = ({
  size,
  theme,
}: {
  size: OnboardingPaginationSize;
  theme: BiomeTheme;
}) => {
  const isMedium = size === "medium";
  return {
    w: isMedium ? theme.base.spacing.x5 : theme.base.spacing.x2,
    h: isMedium ? theme.base.spacing.x5 : theme.base.spacing.x2,
    borderRadius: isMedium ? theme.base.spacing.x3 : theme.base.spacing.x1,

    "&::before": {
      width: isMedium ? theme.base.spacing.x2 : theme.base.spacing.x1,
      height: isMedium ? theme.base.spacing.x2 : theme.base.spacing.x1,
      borderRadius: isMedium ? theme.base.spacing.x3 : theme.base.spacing.x1,
    },

    "&.selected": {
      width: isMedium ? theme.base.spacing.x12 : theme.base.spacing.x4,
      borderWidth: isMedium
        ? theme.base.border.size[200]
        : theme.base.border.size[100],
    },

    "&.selected::before": {
      width: isMedium ? theme.base.spacing.x10 : "10px",
      height: isMedium ? theme.base.spacing.x3 : "2px",
    },

    "&:hover.selected::before": {
      width: isMedium ? "34px" : "10px",
      height: isMedium ? "6px" : "2px",
    },
  };
};

export const getResponsivePageButtonStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<OnboardingPaginationSize>;
  theme: BiomeTheme;
}) => {
  const sizeAsArray = Array.isArray(size) ? [...size] : [size];
  sizeAsArray.shift();

  const sizeStyles = merge(
    ...sizeAsArray.map((responsiveSize, index) => {
      if (responsiveSize != null && !isError(responsiveSize)) {
        const mediaStyleRule = `@media screen and (min-width: ${theme.base.breakpointAsArray?.[index]}px)`;
        return {
          [mediaStyleRule]: getSizeBasedPageButtonStyles({
            size: responsiveSize,
            theme,
          }),
        };
      }
      return {};
    }),
  );

  return sizeStyles;
};
