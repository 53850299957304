import {
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { Stack } from "@/components/Stack";
import { DEFAULT_RATING_SIZE, RATING_SIZES } from "@/constants";
import {
  useEventListener,
  useGetCurrentSizeClass,
  useResizeObserver,
} from "@/hooks";

import { clamp, roundValueToPrecision } from "@/utils";
import { RatingStar } from "./RatingStar";
import {
  type RatingProps,
  isHovering,
  pickGapSpacingModifier,
  pickStarKind,
  pickStarSize,
} from "./shared";

export function Rating<RC extends ReactElement | undefined>(
  props: RatingProps<RC>,
) {
  const {
    sx = {},
    className,
    testId = "Rating",
    step = 1,
    size = DEFAULT_RATING_SIZE,
    currentRating,
    defaultRating = 1,
    maxStars = 5,
    onRatingChange,
    disabled = false,
    ...otherProps
  } = "currentRating" in props
    ? { ...props, defaultRating: undefined }
    : { ...props, currentRating: undefined };

  const [hoverRating, setHoverRating] = useState<number | null>(null);
  const [uncontrolledRating, setUncontrolledRating] = useState<number>(
    step === 0.5
      ? roundValueToPrecision(
          typeof currentRating === "number" ? currentRating : defaultRating,
          step,
        )
      : Math.round(
          typeof currentRating === "number" ? currentRating : defaultRating,
        ),
  );
  const ratingToDisplay = useMemo(() => {
    const rating = hoverRating ?? currentRating ?? uncontrolledRating;
    return step === 0.5
      ? roundValueToPrecision(rating, step)
      : Math.round(rating);
  }, [hoverRating, currentRating, uncontrolledRating, step]);
  const sizeClass = useGetCurrentSizeClass(
    size,
    DEFAULT_RATING_SIZE,
    RATING_SIZES,
  );
  const containerRef = useRef(null);
  const { width } = useResizeObserver(containerRef);
  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (disabled) return;
      const percentage = event.layerX / width;
      const ratingNumber = clamp(
        roundValueToPrecision(maxStars * percentage + step / 2, step),
        step,
        maxStars,
      );
      setHoverRating(ratingNumber);
    },
    [width, step, disabled, maxStars],
  );
  const onMouseLeave = useCallback(() => {
    setHoverRating(null);
  }, []);
  const onClick = useCallback(() => {
    setUncontrolledRating(ratingToDisplay);
    onRatingChange?.(ratingToDisplay);
  }, [ratingToDisplay, onRatingChange]);
  useEventListener("mousemove", onMouseMove, containerRef);
  useEventListener("mouseleave", onMouseLeave, containerRef);
  const mergedSx = useMemo(
    () =>
      merge(
        {
          "--starColor": "base.color.status.guidance.bright",
        },
        sx,
      ),
    [sx],
  );

  return (
    <Box
      {...otherProps}
      sx={mergedSx}
      testId={testId}
      className={`${className ?? ""} Rating Rating--${sizeClass} ${
        disabled ? "Rating--disabled" : ""
      }`}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        testId={`${testId}__innerContainer`}
        sx={{
          "--starSize": `${pickStarSize(sizeClass)}px`,
          gap: `base.spacing.x${pickGapSpacingModifier(sizeClass)}`,
          ...(disabled ? {} : { cursor: "pointer" }),
          pos: "relative",
        }}
        domRef={containerRef}
        onClick={onClick}
      >
        {Array.from(new Array(maxStars)).map((_, index) => {
          return (
            <RatingStar
              key={`${testId}__star${index + 1}`}
              mode={pickStarKind(index + 1, ratingToDisplay, step)}
              isHovering={isHovering(index + 1, hoverRating)}
              testId={`${testId}__star${index + 1}`}
            />
          );
        })}
      </Stack>
    </Box>
  );
}

Rating.displayName = "Rating";
