import { DEFAULT_TEXT_INPUT_SIZE } from "@/constants";
import { isChildSubcomponent } from "@/utils";
import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";
import { SmartClone } from "../SmartClone";
import { Stack } from "../Stack";
import { InputGroupRow } from "./InputGroupRow";
import {
  type InputGroupProps,
  SUPPORTED_INPUT_COMPONENTS_MAP,
  getSizePropForInput,
  isSupportedInputComponent,
} from "./shared";
import { baseContainerSx } from "./styles";

export function InputGroup<RC extends ReactElement | undefined = undefined>({
  size = DEFAULT_TEXT_INPUT_SIZE,
  textAlign,
  children,
  className,
  id,
  sx = {},
  testId = "InputGroup",
  validationStatus,
  ...props
}: InputGroupProps<RC>) {
  const mergedContainerSx = merge(baseContainerSx, sx);
  const flattendChildren = useMemo(() => flattenChildren(children), [children]);
  return (
    <Stack
      {...props}
      className={`${className ?? ""} InputGroup`}
      direction="column"
      sx={mergedContainerSx}
      testId={testId}
    >
      {Children.map(flattendChildren, (child, index) => {
        const isRow = isChildSubcomponent(child, InputGroupRow);
        const isSupportedInput = isSupportedInputComponent(child);
        const sizeProp = getSizePropForInput(child, size);
        if (isRow || isSupportedInput) {
          return (
            <SmartClone
              {...sizeProp}
              {...(index === 0 && isSupportedInput ? { id } : {})}
              sx={{ textAlign }}
              testId={`${testId}__${isRow ? "Row" : "inputComponent"}`}
              validationStatus={validationStatus}
              textAlign={textAlign}
              className="InputGroup__child"
            >
              {child}
            </SmartClone>
          );
        }

        // @TODO: should we support any component inside here?
        return null;
      })}
    </Stack>
  );
}

InputGroup.displayName = "InputGroup";
InputGroup.Row = InputGroupRow;
InputGroup.inputs = SUPPORTED_INPUT_COMPONENTS_MAP;
