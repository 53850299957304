import { Children, type ReactElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import type { BodyProps } from "@/types";
import { isChildSubcomponent } from "@/utils";

import { Icon } from "../Icon";
import { SmartClone } from "../SmartClone";
import { Body } from "../Text";

export type ToastMessageProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC>;

export function ToastMessage<RC extends ReactElement | undefined = undefined>({
  size = "medium",
  sx = {},
  children,
  ...props
}: ToastMessageProps<RC>) {
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  const mergedSx = merge(
    {
      verticalAlign: "middle",
      c: "base.color.text.body.inverse.primary",
    },
    sx,
  );
  return (
    <Body {...props} sx={mergedSx} size={size}>
      {Children.map(flattenedChildren, (child) => {
        if (isChildSubcomponent(child, Icon)) {
          return (
            <SmartClone
              sx={merge(
                {
                  fill: "base.color.text.body.inverse.primary",
                  mr: "base.spacing.x2",
                  w: "1em",
                },
                child.props.sx || {},
              )}
            >
              {child}
            </SmartClone>
          );
        }
        return child;
      })}
    </Body>
  );
}

ToastMessage.displayName = "Toast.Message";
