import {
  type MouseEvent,
  type ReactElement,
  useCallback,
  useMemo,
} from "react";

import { ButtCon } from "@/components/Clickable";
import type { ButtConProps, DistributiveOmit } from "@/types";

import { merge } from "ts-deepmerge";
import {
  type CardHeroButtonVariant,
  useGetCardHeroButtonVariant,
} from "./shared";
import { buttonAndButtConBaseSx } from "./styles";

export type CardHeroButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "variant"> & {
  variant?: CardHeroButtonVariant;
};

export function CardHeroButtCon<
  RC extends ReactElement | undefined = undefined,
>({
  variant = "tertiary",
  size = "small",
  className,
  sx = {},
  ...props
}: CardHeroButtConProps<RC>) {
  const { onClick, ...otherProps } =
    "onClick" in props ? props : { ...props, onClick: null };
  const handleOnClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onClick === "function") {
        onClick(ev);
      }
    },
    [onClick],
  );
  const variantToUse = useGetCardHeroButtonVariant(variant);
  const mergedSx = useMemo(() => merge(buttonAndButtConBaseSx, sx), [sx]);

  return (
    <ButtCon
      {...otherProps}
      {...(onClick ? { onClick: handleOnClick } : {})}
      size={size}
      variant={variantToUse}
      className={`${className ?? ""} CardHeroButtCon`}
      sx={mergedSx}
    />
  );
}

CardHeroButtCon.displayName = "CardHero.ButtCon";
CardHeroButtCon.SvgIcon = ButtCon.SvgIcon;
