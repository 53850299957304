import {
  type ComponentProps,
  type ReactElement,
  isValidElement,
  useId,
  useMemo,
} from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { Autocomplete } from "@/components/Autocomplete";
import { Box } from "@/components/Box";
import { Checkbox } from "@/components/Checkbox";
import { InputGroup } from "@/components/InputGroup";
import { Select } from "@/components/MenuItemRelatedComponents";
import { NumberStepper } from "@/components/NumberStepper";
import { Radio } from "@/components/Radio";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import { TextArea } from "@/components/TextArea";
import { TextInput } from "@/components/TextInput";
import { Toggle } from "@/components/Toggle";
import { DEFAULT_TEXT_INPUT_ALIGN } from "@/constants";
import { useGetSubcomponentChild } from "@/hooks";

import type { FormControlProps } from "@/types";
import { FormControlCaption } from "./FormControlCaption";
import { FormControlLabel } from "./FormControlLabel";
import { FormControlValidation } from "./FormControlValidation";

const SUPPORTED_INPUT_COMPONENTS = [
  TextArea,
  TextInput,
  InputGroup,
  Checkbox,
  Toggle,
  Radio,
  Select,
  Autocomplete,
  NumberStepper,
] as const;

const SUPPORTED_INPUT_COMPONENTS_MAP = {
  TextArea,
  TextInput,
  InputGroup,
  Checkbox,
  Toggle,
  Radio,
  Select,
  Autocomplete,
  NumberStepper,
} as const;

export function FormControl<RC extends ReactElement | undefined = undefined>({
  domRef,
  sx = {},
  id,
  testId,
  children,
  className,
  validationStatus,
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  ...domAttributes
}: FormControlProps<RC>) {
  const fallbackId = useId();
  const idToUse = id ?? fallbackId;
  const label = useGetSubcomponentChild(children, FormControlLabel);
  const caption = useGetSubcomponentChild(children, FormControlCaption);
  const validation = useGetSubcomponentChild(children, FormControlValidation);
  const flattendChildren = useMemo(() => flattenChildren(children), [children]);

  // Typescript struggles to type 'find' and infer prop types, so we need to cast here.
  const inputComponent = flattendChildren.find((child) =>
    SUPPORTED_INPUT_COMPONENTS.some(
      (inputEl) => isValidElement(child) && child.type === inputEl,
    ),
  ) as ReactElement<
    ComponentProps<(typeof SUPPORTED_INPUT_COMPONENTS)[number]>
  >;

  const shouldUseColumnBasedLayout =
    isValidElement(inputComponent) &&
    (inputComponent.type === TextArea ||
      inputComponent.type === TextInput ||
      inputComponent.type === Select ||
      inputComponent.type === Autocomplete ||
      inputComponent.type === NumberStepper ||
      inputComponent.type === InputGroup);

  const mergedInputSx = useMemo(
    () =>
      merge(
        {
          order: textAlign === "left" ? "-1" : "1",
          ...(textAlign === "left"
            ? { mr: "base.spacing.x2" }
            : { ml: "base.spacing.x2" }),
        },
        { ...inputComponent?.props?.sx },
      ),
    [inputComponent?.props?.sx, textAlign],
  );

  const mergedContainerSx = useMemo(
    () =>
      merge(
        {
          display: "flex",
          flexDirection: shouldUseColumnBasedLayout ? "column" : "row",
          gap: "base.spacing.x1",
        },
        sx,
      ),
    [shouldUseColumnBasedLayout, sx],
  );

  return (
    <Box
      {...domAttributes}
      domRef={domRef}
      testId={testId}
      sx={mergedContainerSx}
      className={`${className ?? ""} FormControl FormControl--${textAlign}Align`}
    >
      {shouldUseColumnBasedLayout ? (
        <>
          {label && (
            <SmartClone
              textAlign={label.props.textAlign || textAlign}
              htmlFor={idToUse}
            >
              {label}
            </SmartClone>
          )}
          {inputComponent && (
            <SmartClone
              textAlign={inputComponent.props.textAlign || textAlign}
              id={idToUse}
              validationStatus={
                inputComponent.props.validationStatus || validationStatus
              }
            >
              {inputComponent}
            </SmartClone>
          )}
          {caption && (
            <SmartClone
              textAlign={caption.props.textAlign || textAlign}
              htmlFor={idToUse}
            >
              {caption}
            </SmartClone>
          )}
          {validation && (
            <SmartClone
              htmlFor={idToUse}
              textAlign={validation.props.textAlign || textAlign}
              validationStatus={
                validation.props.validationStatus || validationStatus
              }
            >
              {validation}
            </SmartClone>
          )}
        </>
      ) : (
        <>
          <Stack
            gap="0px"
            justifyContent="center"
            className="FormControl__textContainer"
            sx={{ flex: 1 }}
          >
            {label && (
              <SmartClone
                textAlign={label.props.textAlign || textAlign}
                htmlFor={idToUse}
              >
                {label}
              </SmartClone>
            )}
            {caption && (
              <SmartClone
                htmlFor={idToUse}
                textAlign={caption.props.textAlign || textAlign}
              >
                {caption}
              </SmartClone>
            )}
            {validation && (
              <SmartClone
                htmlFor={idToUse}
                textAlign={validation.props.textAlign || textAlign}
                validationStatus={
                  validation.props.validationStatus || validationStatus
                }
              >
                {validation}
              </SmartClone>
            )}
          </Stack>

          {inputComponent && (
            <SmartClone
              id={idToUse}
              validationStatus={
                inputComponent.props.validationStatus || validationStatus
              }
              sx={mergedInputSx}
            >
              {inputComponent}
            </SmartClone>
          )}
        </>
      )}
    </Box>
  );
}

FormControl.displayName = "FormControl";

FormControl.Label = FormControlLabel;
FormControl.Validation = FormControlValidation;
FormControl.Caption = FormControlCaption;
FormControl.inputs = SUPPORTED_INPUT_COMPONENTS_MAP;
