import { ClassNames } from "@emotion/react";
import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Stack } from "@/components/Stack";
import { Body } from "@/components/Text";
import { useTheme } from "@/hooks";
import type { MotionProfile } from "@/types";
import { centerFlexChildren, getMotionProfileSx, hFlex } from "@/utils";

import { DEFAULT_MOTION_PROFILE } from "@/constants";
import { baseThumbSx } from "./styles";
import type { CommonThumbProps } from "./types";

type DefaultThumbProps = CommonThumbProps & { motionProfile?: MotionProfile };

export function DefaultThumb({
  index,
  selected,
  onClick,
  variant,
  motionProfile = DEFAULT_MOTION_PROFILE,
}: DefaultThumbProps) {
  const theme = useTheme();
  const mergedSx = useMemo(
    () =>
      merge(baseThumbSx, {
        ...hFlex,
        ...centerFlexChildren,
        c: selected
          ? "base.color.text.body.primary"
          : "base.color.text.body.secondary",
        ...(variant === "horizontal" ? { aspectRatio: "16/9" } : {}),
        ...getMotionProfileSx(motionProfile, theme),
        ...(motionProfile !== "none"
          ? {
              "& .innerContainer": {
                ...getMotionProfileSx(motionProfile, theme),
              },
            }
          : {}),
      }),
    [variant, motionProfile, selected, theme],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <Body
          className={cx("DefaultThumb", "CarouselThumbnailThumb", {
            "CarouselThumbnailThumb--active": selected,
          })}
          testId="CarouselThumbnailDefaultThumb"
          size={selected ? "large" : "medium"}
          weight={selected ? "bold" : "regular"}
          sx={mergedSx}
          rc={<button type="button" onClick={onClick} />}
        >
          <Stack
            rc={<span />}
            className="innerContainer"
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={variant === "vertical" ? "base.spacing.x4" : "unset"}
          >
            {index + 1}
          </Stack>
        </Body>
      )}
    </ClassNames>
  );
}

DefaultThumb.displayName = "DefaultThumb";
