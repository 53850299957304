import { jsx } from "@emotion/react";
import {
  type ElementType,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  isValidElement,
  useCallback,
} from "react";
import { merge } from "ts-deepmerge";

import { useConvertSxToEmotionStyles } from "@/hooks/useConvertSxToEmotionStyles";
import { useGetComponentCloneMode } from "@/hooks/useGetComponentCloneMode";
import type { StandardComponentWithProps } from "@/types";
import {
  childHasCssProp,
  childHasOnClickProp,
  childHasSxProp,
  cloneElementWithCssProp,
} from "@/utils/componentHelpers";

// @NOTE: Very tricky to safely type this component,
// as it can accept any number of children, of different dom element types
export type SmartCloneProps = StandardComponentWithProps<
  Element,
  {
    fallBackDomNode?: ElementType;
    onClick?: (ev: MouseEvent<unknown>) => void;
    children?: ReactNode;
    // @NOTE: allow various unknown props to be spread in to cloned component
  } & Record<string, unknown>
>;

// ------------------------------------------------------------------------------
// @NOTE: this component allows 3 different render modes:
// 1  - When there is a single react component child, simply clone it and pass
//      down all props as is
//
// 2  - When there is a single DOM component child, clone it, and modify props
//      so that they are suitable to be rendered onto a natural dom element
//
// 3  - When there are multiple children, or the child is a fragment - wrap
//      the children inside a span, and modify props so that they are suitable
//      to be rendered onto a natural dom element
//
export function SmartClone({
  testId,
  children,
  fallBackDomNode = "span",
  onClick,
  sx = {},
  domRef,
  rc,
  ...props
}: SmartCloneProps): ReactElement {
  const { shouldCloneAsComponent, shouldCloneAsDom } =
    useGetComponentCloneMode(children);
  const customStyles = useConvertSxToEmotionStyles(sx);
  const mergedOnClicks = useCallback(
    (ev: MouseEvent<unknown>) => {
      if (isValidElement(children) && childHasOnClickProp(children)) {
        children.props.onClick?.(ev);
      }
      onClick?.(ev);
    },
    [onClick, children],
  );

  // @NOTE: render mode 1
  return shouldCloneAsComponent && isValidElement(children)
    ? cloneElementWithCssProp(children, {
        ...(testId ? { testId } : {}),
        ...merge({}, children.props ?? {}, props),
        ...(domRef ? { domRef } : {}),
        ...(onClick || childHasOnClickProp(children)
          ? { onClick: mergedOnClicks }
          : {}),
        sx: merge(
          {},
          { ...(childHasSxProp(children) ? children.props.sx : {}) },
          sx,
        ),
        ...(rc ? { rc } : {}),
      })
    : shouldCloneAsDom && isValidElement(children)
      ? // @NOTE: render mode 2
        cloneElementWithCssProp(children, {
          ...(testId ? { "data-testid": testId } : {}),
          ...merge({}, children.props ?? {}, props),
          ...(domRef ? { ref: domRef } : {}),
          ...(onClick || childHasOnClickProp(children)
            ? { onClick: mergedOnClicks }
            : {}),
          ...(childHasCssProp(children)
            ? { css: [customStyles, children.props.css] }
            : { css: customStyles }),
        })
      : // @NOTE: render mode 3
        jsx(fallBackDomNode, {
          ...props,
          ...(testId ? { "data-testid": testId } : {}),
          ...(domRef ? { ref: domRef } : {}),
          ...(onClick ? { onClick } : {}),
          css: customStyles,
          children,
        });
}

SmartClone.displayName = "SmartClone";
