import { type ReactElement, useMemo } from "react";

import { AspectRatioImage } from "@/components/AspectRatioImage";
import { DEFAULT_IMAGE_SIZE_VARIANT } from "@/constants";
import { useGetRelativeImageSizeInLayout } from "@/hooks";
import type { FramedImageProps } from "@/types";
import {
  childHasHeightProp,
  childHasWidthProp,
  isImageResizerProps,
} from "@/utils";
import { merge } from "ts-deepmerge";
import { FramedContainer } from "../shared/FramedContainer";
import { innerMediaElementSx } from "../shared/styles";

export function FramedImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  circularFrame = false,
  emphasized = false,
  padded = false,
  sx = {},
  className,
  domRef,
  rc,
  use,
  testId = "FramedImage",
  objectFit = "cover",
  objectPosition = "center",
  size = DEFAULT_IMAGE_SIZE_VARIANT,
  motionProfile,
  ...props
}: FramedImageProps<RC, Use>) {
  // @NOTE: max size of any FramedContainer is 64px,
  // so 64 * 3 (triple density screens) = 192
  // Therefore, we really don't need to go above 256px
  const {
    responsiveSizes = [24, 32, 64, 128, 256],
    relativeImageSizeInLayout,
    ...otherImageProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        responsiveSizes: undefined,
        relativeImageSizeInLayout: undefined,
      };

  const defaultRelativeImageSizeInLayout = useGetRelativeImageSizeInLayout(
    size,
    "FramedImage",
  );

  const mergedContainerSx = useMemo(
    () =>
      merge(sx, {
        ...(childHasWidthProp(use) && childHasHeightProp(use)
          ? {
              width: `${use.props.width}px`,
              height: `${use.props.height}px`,
            }
          : {}),
      }),
    [sx, use],
  );

  const mergedImageSx = useMemo(
    () =>
      merge(innerMediaElementSx, {
        objectFit,
        objectPosition,
        borderRadius: circularFrame ? "50%" : "base.borderRadius.x2",
        w: padded ? "70%" : "100%",
        h: padded ? "70%" : "100%",
        minw: "unset",
      }),
    [objectFit, objectPosition, circularFrame, padded],
  );

  return (
    <FramedContainer
      circularFrame={circularFrame}
      emphasized={emphasized}
      padded={padded}
      size={size}
      sx={mergedContainerSx}
      rc={rc}
      testId={testId}
      domRef={domRef}
      motionProfile={motionProfile}
      className={`${className ?? ""} FramedImage`}
    >
      <AspectRatioImage
        // @NOTE: to be able to use NextImage with componnts which extends from FramedImage,
        // those components need a width and a height value to be specified
        {...(use
          ? {
              use,
              width: childHasWidthProp(use)
                ? use.props.width
                : Number.parseInt(defaultRelativeImageSizeInLayout),
              height: childHasHeightProp(use)
                ? use.props.height
                : Number.parseInt(defaultRelativeImageSizeInLayout),
            }
          : {
              ...otherImageProps,
              responsiveSizes: responsiveSizes,
              relativeImageSizeInLayout:
                relativeImageSizeInLayout ?? defaultRelativeImageSizeInLayout,
            })}
        className="FramedImage__image"
        testId={`${testId}__AspectRatioImage`}
        sx={mergedImageSx}
      />
    </FramedContainer>
  );
}

FramedImage.displayName = "FramedImage";
