import { css } from "@emotion/react";

export const DEFAULT_GLOBAL_SX = {
  "*, *::before, *::after": {
    boxSizing: "border-box",
  },
  "html, body": {
    height: "100%",
    margin: 0,
  },
  "pre, p, h1, h2, h3, h4, h5, h6": {
    margin: 0,
  },
  body: {
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    textRendering: "optimizeLegibility",
  },
};

const googleFontsImport = css`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
`;

export function getFontStyles(skipFontLoad: boolean) {
  return skipFontLoad
    ? []
    : [
        googleFontsImport,
        {
          "@font-face": {
            fontFamily: "Suisse-Intl",
            fontWeight: 400,
            fontStyle: "normal",
            fontDisplay: "swap",
            src: `url('https://biome.immutable.com/hosted-assets/fonts/suisseintl-regular.woff2')
                format('woff2')`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Suisse-Intl",
            fontWeight: 600,
            fontStyle: "normal",
            fontDisplay: "swap",
            src: `url('https://biome.immutable.com/hosted-assets/fonts/suisseintl-medium.woff2')
                format('woff2')`,
          },
        },
        {
          "@font-face": {
            fontFamily: "Suisse-Intl",
            fontWeight: 700,
            fontStyle: "normal",
            fontDisplay: "swap",
            src: `url('https://biome.immutable.com/hosted-assets/fonts/suisseintl-bold.woff2')
                format('woff2')`,
          },
        },
      ];
}
