import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import type { BodyProps } from "@/types";

import { baseDescriptionSx } from "./styles";

export type CardHeroDescriptionProps<
  RC extends ReactElement | undefined = undefined,
> = BodyProps<RC>;

export function CardHeroDescription<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  weight = "regular",
  sx = {},
  rc = <span />,
  className,
  ...props
}: CardHeroDescriptionProps<RC>) {
  return (
    <Body
      {...props}
      className={`${className ?? ""} CardHeroDescription`}
      sx={merge(baseDescriptionSx, sx)}
      size={size}
      weight={weight}
      rc={rc}
    />
  );
}

CardHeroDescription.displayName = "CardHero.Description";
