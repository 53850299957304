import type { ReactElement, ReactNode } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { DEFAULT_TOOLBAR_SIZE, TOOLBAR_SIZES } from "@/constants";
import { useGetSubcomponentChild, useTheme } from "@/hooks";
import type {
  BoxWithRCAndDomProps,
  MakeResponsive,
  ToolbarSize,
} from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";
import { vFlex } from "@/utils/sxChunks";

import { ToolbarCaption } from "./ToolbarCaption";
import { ToolbarLeftButtCon } from "./ToolbarLeftButtCon";
import { ToolbarRightSlot } from "./ToolbarRightSlot";
import { ToolbarTitle } from "./ToolbarTitle";
import {
  getContainerStyles,
  getResponsiveContainerStyles,
  toolbarContainerBaseSx,
} from "./styles";

export type ToolbarProps<RC extends ReactElement | undefined = undefined> =
  BoxWithRCAndDomProps<RC> & {
    size?: MakeResponsive<ToolbarSize>;
    children?: ReactNode;
  };

export function Toolbar<RC extends ReactElement | undefined = undefined>({
  sx = {},
  size = DEFAULT_TOOLBAR_SIZE,
  children,
  rc = <header />,
  testId = "Toolbar",
  className,
  ...props
}: ToolbarProps<RC>) {
  const title = useGetSubcomponentChild(children, ToolbarTitle);
  const caption = useGetSubcomponentChild(children, ToolbarCaption);
  const leftButtCon = useGetSubcomponentChild(children, ToolbarLeftButtCon);
  const rightSlot = useGetSubcomponentChild(children, ToolbarRightSlot);
  const themeProps = useTheme();
  const startingSize = getStartingSize(
    size,
    DEFAULT_TOOLBAR_SIZE,
    TOOLBAR_SIZES,
  );
  const allContainerStyles = merge(
    toolbarContainerBaseSx,
    getContainerStyles({ size: startingSize, themeProps }),
    getResponsiveContainerStyles({ size, themeProps }),
    sx,
  );

  return (
    <Box
      {...props}
      sx={allContainerStyles}
      rc={rc}
      testId={testId}
      className={`${className ?? ""} Toolbar`}
    >
      {leftButtCon && <SmartClone size={size}>{leftButtCon}</SmartClone>}
      <Box
        sx={{
          ...vFlex,
          flexGrow: 1,
        }}
        rc={<hgroup />}
      >
        {title && <SmartClone size={size}>{title}</SmartClone>}
        {caption && <SmartClone size={size}>{caption}</SmartClone>}
      </Box>

      {rightSlot && <SmartClone size={size}>{rightSlot}</SmartClone>}
    </Box>
  );
}

Toolbar.displayName = "Toolbar";
Toolbar.LeftButtCon = ToolbarLeftButtCon;
Toolbar.Title = ToolbarTitle;
Toolbar.Caption = ToolbarCaption;
Toolbar.RightSlot = ToolbarRightSlot;
