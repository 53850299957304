import { useCallback, useId, useMemo, useRef } from "react";
import flattenChildren from "react-keyed-flatten-children";

import { useBrowserEffect, useBrowserLayoutEffect } from "@/hooks";
import {
  CLOSE_TOAST,
  OPEN_TOAST,
  UPDATE_TOAST_PROPS,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { ToastComponentProps } from "@/types";

import { ToastButtCon } from "./ToastButtCon";
import { ToastButton } from "./ToastButton";
import { ToastMessage } from "./ToastMessage";
import {
  DEFAULT_TOAST_BUTTONS_POSITION,
  DEFAULT_TOAST_POSITION,
  DEFAULT_TOAST_VARIANT,
} from "./shared";

export function Toast({
  visible,
  onCloseToast,
  id: idProp,
  children,
  position = DEFAULT_TOAST_POSITION,
  hideDismissButton,
  variant = DEFAULT_TOAST_VARIANT,
  autoDismissDuration,
  buttonsPosition = DEFAULT_TOAST_BUTTONS_POSITION,
  testId = "Toast",
  sx,
}: ToastComponentProps) {
  const fallbackId = useId();
  const id = idProp ?? fallbackId;
  const { state: toastList, dispatchAction } = useOverlaysStore(
    (state) => state.toastList,
  );
  const timerRef = useRef<number>(0);

  const toastProps = useMemo(
    () => ({
      id,
      content: flattenChildren(children),
      onCloseToast,
      position,
      variant,
      hideDismissButton,
      buttonsPosition,
      testId,
      sx,
    }),
    [
      buttonsPosition,
      children,
      hideDismissButton,
      id,
      onCloseToast,
      position,
      sx,
      testId,
      variant,
    ],
  );

  // @NOTE: this useEffect allows Toast props to stay up to date,
  // as the application re-renders and things potentially change
  useBrowserEffect(() => {
    dispatchAction({
      type: UPDATE_TOAST_PROPS,
      payload: toastProps,
    });
  }, [toastProps]);

  const closeToast = useCallback(() => {
    dispatchAction({
      type: CLOSE_TOAST,
      payload: { id },
    });
  }, [dispatchAction, id]);

  const showToast = useCallback(() => {
    if (autoDismissDuration) {
      clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(
        () => onCloseToast?.(),
        autoDismissDuration,
      );
    }
    dispatchAction({
      type: OPEN_TOAST,
      payload: toastProps,
    });
  }, [autoDismissDuration, dispatchAction, toastProps, onCloseToast]);

  useBrowserLayoutEffect(() => {
    if (visible) {
      showToast();
    } else if (toastList.find((toast) => toast.id === id)) {
      closeToast();
    }
    // @NOTE: we want to trigger these changes ONLY when the visible prop changes
  }, [visible]);

  return null;
}

Toast.displayName = "Toast";
Toast.Button = ToastButton;
Toast.ButtCon = ToastButtCon;
Toast.Message = ToastMessage;
