import { ButtCon } from "@/components/Clickable";
import type { AllIconKeys, ButtConProps } from "@/types";
import type { ReactNode } from "react";

type CarouselSimpleControlButtConProps = Omit<
  ButtConProps<undefined>,
  "rc" | "icon" | "children"
> &
  ({ icon?: AllIconKeys } | { children?: ReactNode });

export function CarouselSimpleNextButtCon({
  testId = "CarouselSimpleNextButtCon",
  className,
  variant = "tertiary",
  size = "small",
  ...props
}: CarouselSimpleControlButtConProps) {
  const { icon = "ArrowForward", ...propsMinusIcon } =
    "icon" in props ? props : { icon: undefined, ...props };
  const { children, ...otherProps } =
    "children" in propsMinusIcon
      ? propsMinusIcon
      : { children: undefined, ...propsMinusIcon };
  return (
    <ButtCon
      {...otherProps}
      {...(icon ? { icon } : {})}
      variant={variant}
      size={size}
      testId={testId}
      className={`${className ?? ""} CarouselSimpleNextButtCon`}
    >
      {children as ReactNode}
    </ButtCon>
  );
}

export function CarouselSimplePreviousButtCon({
  testId = "CarouselSimplePreviousButtCon",
  className,
  variant = "tertiary",
  size = "small",
  ...props
}: CarouselSimpleControlButtConProps) {
  const { icon = "ArrowBackward", ...propsMinusIcon } =
    "icon" in props ? props : { icon: undefined, ...props };
  const { children, ...otherProps } =
    "children" in propsMinusIcon
      ? propsMinusIcon
      : { children: undefined, ...propsMinusIcon };
  return (
    <ButtCon
      {...otherProps}
      {...(icon ? { icon } : {})}
      variant={variant}
      size={size}
      testId={testId}
      className={`${className ?? ""} CarouselSimplePreviousButtCon`}
    >
      {children as ReactNode}
    </ButtCon>
  );
}

CarouselSimpleNextButtCon.displayName = "CarouselSimple.NextButtCon";
CarouselSimpleNextButtCon.SvgIcon = ButtCon.SvgIcon;

CarouselSimplePreviousButtCon.displayName = "CarouselSimple.PreviousButtCon";
CarouselSimplePreviousButtCon.SvgIcon = ButtCon.SvgIcon;
