import { type ReactElement, useContext } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import { DEFAULT_TABLE_SPACING, TABLE_SPACING_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type { BodyProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { TableContext } from "./context";
import type { TableChildParentNode } from "./shared";
import { baseTdSx, getCellSx, getResponsiveCellSx } from "./styles";

export type TableCellProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC> & {
    parentNode?: TableChildParentNode;
    colSpan?: number;
    columnNumber?: number;
  };

export function TableCell<RC extends ReactElement | undefined = undefined>({
  parentNode,
  ...props
}: TableCellProps<RC>) {
  const isInsideTableBody = parentNode === "tbody";
  const {
    children,
    size = "medium",
    sx = {},
    className,
    testId: testIdProp,
    colSpan = 1,
    columnNumber,
    weight = isInsideTableBody ? "regular" : "bold",
    rc = isInsideTableBody ? (
      <td colSpan={colSpan} />
    ) : (
      <th colSpan={colSpan} />
    ),
    ...otherProps
  } = props;
  const theme = useTheme();
  const {
    testId: testIdFromContext,
    horizontalSpacing,
    verticalSpacing,
    stickyHeaders,
  } = useContext(TableContext);
  const startingHorizontalSpacing = getStartingSize(
    horizontalSpacing,
    DEFAULT_TABLE_SPACING,
    TABLE_SPACING_SIZES,
  );
  const startingVerticalSpacing = getStartingSize(
    verticalSpacing,
    DEFAULT_TABLE_SPACING,
    TABLE_SPACING_SIZES,
  );
  const shouldApplyCellWidths =
    stickyHeaders && typeof columnNumber === "number" && columnNumber >= 0;
  const allSx = merge(
    baseTdSx,
    getCellSx({
      horizontalSpacing: startingHorizontalSpacing,
      verticalSpacing: startingVerticalSpacing,
      theme,
    }),
    getResponsiveCellSx({ horizontalSpacing, verticalSpacing, theme }),
    sx,
  );

  return (
    <Body
      {...otherProps}
      size={size}
      weight={weight}
      rc={rc}
      sx={allSx}
      className={`${className ?? ""} TableCell TableCell--${
        isInsideTableBody ? "td" : "th"
        // @TODO: deprecate the use of plain "td" and "th" classes, infavour of
        // the more specific "TableCell--td" and "TableCell--th" classes
      } ${isInsideTableBody ? "td" : "th"}`}
      data-has-hardcoded-widths={shouldApplyCellWidths}
      testId={
        testIdProp
          ? `${testIdProp}--${isInsideTableBody ? "td" : "th"}`
          : `${testIdFromContext}__${isInsideTableBody ? "td" : "th"}`
      }
    >
      {children}
    </Body>
  );
}

TableCell.displayName = "Table.Cell";
