import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from "react";

import { appConfig } from "@/constants";
import { EnvironmentNames, type MinimalCoinBalance } from "@/types";
import { notifyError } from "@/utils/monitoring";
import { useTranslation } from "react-i18next";

type CryptoFiatPrices = Record<string, { usd: number; usd24hChange: number }>; // symbol -> FiatPrice

type ConversionResponse = {
  data: Record<
    string,
    {
      usd: number;
      usd_24h_change: number | null; // null if no change!
    }
  >;
}; // coinGeckoId -> FiatPrice

type OverridesResponse = { data: Record<string, string> }; // symbol -> coinGeckoId

export type ChartData = {
  market_caps: [number, number][];
  prices: [number, number][];
  total_volumes: [number, number][];
};

type ChartDataResponse = { data: ChartData };

export type FiatEquivalentPrice = {
  currency: string;
  amount: string;
  amountBN: BigNumber;
  prefixSymbol: string;
  usd24HrChange: number;
};

interface Context {
  fiatPrices?: CryptoFiatPrices | undefined;
  getTotalFiatBalance: (
    coinBalances: MinimalCoinBalance[],
  ) => FiatEquivalentPrice | undefined;
  getFormattedPrice: (
    symbol: string | undefined,
    decimals: number | undefined,
    amount: string,
  ) => string;
  buildFiatEquivalentPrice: (
    tokenPrice: string,
    decimals: number,
    tokenSymbol: string,
    fiatPrices: CryptoFiatPrices | undefined,
    fiatCurrency?: string,
  ) => FiatEquivalentPrice | undefined;
  getFiatPriceForSymbol: (symbol: string) => number | undefined;
}

export const FiatPricingContext = createContext<Context>({
  fiatPrices: undefined,
  getFormattedPrice: () => "",
  buildFiatEquivalentPrice: () => undefined,
  getTotalFiatBalance: () => undefined,
  getFiatPriceForSymbol: () => undefined,
});

export const useFiatPricingContext = (): Context =>
  useContext(FiatPricingContext);

async function getCheckoutApiPrices(
  coinGeckoIds: string[],
  fiatSymbol = "usd",
) {
  if (coinGeckoIds.length === 0) return {};

  const idsParam = coinGeckoIds.join(",");
  const currenciesParam = fiatSymbol.toLowerCase();
  const include24hrChangeParam = "true"; // always fetch 24hr change

  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = `/v1/fiat/conversion?ids=${idsParam}&currencies=${currenciesParam}&include_24hr_change=${include24hrChangeParam}`;

  const { data } = (await axios.get(baseUrl + path)) as ConversionResponse;
  return data;
}

/**
 * To edit the mappings, edit this file:
 * https://github.com/immutable/checkout-api-configs/blob/main/configs/prod/v1/fiat/coins/overrides/index.json
 */
async function getCoinGeckoIdForSymbol() {
  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = "/v1/fiat/coins/overrides";
  const { data } = (await axios.get(baseUrl + path)) as OverridesResponse;
  return data;
}

export function useCoinGeckoIdForSymbol() {
  return useQuery({
    queryKey: ["coinGeckoIdForSymbol"],
    queryFn: () => getCoinGeckoIdForSymbol(),
  });
}

async function getChartData(id: string) {
  const baseUrl =
    appConfig.ENVIRONMENT === EnvironmentNames.PRODUCTION
      ? "https://checkout-api.immutable.com"
      : `https://checkout-api.${appConfig.ENVIRONMENT}.immutable.com`;
  const path = `/v1/fiat/chart-data?id=${id}`;
  const { data } = (await axios.get(baseUrl + path)) as ChartDataResponse;
  return data;
}

export function useChartData(id: string) {
  const symbol = id.toLowerCase();
  const { data: coinGeckoIdForSymbol } = useCoinGeckoIdForSymbol();
  const coinGeckoId = coinGeckoIdForSymbol?.[symbol];

  return useQuery({
    queryKey: ["chart-data", symbol],
    queryFn: () => (coinGeckoId ? getChartData(coinGeckoId) : undefined),
    enabled: !!coinGeckoId,
  });
}

function useCryptoFiatPrices(fiatSymbol: string) {
  const { data: coinGeckoIdForSymbol } = useCoinGeckoIdForSymbol();

  const { data } = useQuery({
    queryKey: [
      "checkoutApiPrices",
      fiatSymbol,
      Object.keys(coinGeckoIdForSymbol ?? {}).join("-"),
    ],
    refetchInterval: 1000 * 60 * 5, // refetch every 5 minutes
    queryFn: () =>
      getCheckoutApiPrices(
        Object.values(coinGeckoIdForSymbol ?? {}),
        fiatSymbol,
      ),
    select: (data) =>
      Object.entries(coinGeckoIdForSymbol ?? {}).reduce(
        (acc, [symbol, coinGeckoId]) => {
          if (data[coinGeckoId]) {
            acc[symbol] = {
              usd: data[coinGeckoId].usd,
              usd24hChange: data[coinGeckoId].usd_24h_change ?? 0,
            };
          }
          return acc;
        },
        {} as CryptoFiatPrices,
      ),
  });

  return { data };
}

export function FiatPricingProvider({
  children,
  fiatSymbol = "usd",
}: PropsWithChildren<{ fiatSymbol?: string }>) {
  const { t } = useTranslation();
  const { data } = useCryptoFiatPrices(fiatSymbol);

  const getFormattedPrice = useCallback(
    (
      symbol: string | undefined,
      decimals: number | undefined,
      amount: string,
    ) => {
      if (!symbol || !decimals) {
        return amount;
      }

      const fiatPrice = data ? data[symbol.toLocaleLowerCase()] : undefined;
      const usdValue = fiatPrice?.usd;

      if (usdValue) {
        const price = new BigNumber(amount)
          .multipliedBy(usdValue)
          .div(10 ** decimals, 10)
          .toFormat(2);
        if (price === "0.00") {
          return t("approximate_usd_less_than_0_01");
        }
        if (price && price !== "NaN") {
          return t("approximate_usd_value", { amount: price });
        }
      }

      const formattedAmount = new BigNumber(amount)
        .div(10 ** decimals, 10)
        .toFormat(2);
      if (formattedAmount && formattedAmount !== "NaN") {
        return `${symbol} ${formattedAmount}`;
      }
      return `${symbol} ${amount}`;
    },
    [data, t],
  );

  const getTotalFiatBalance = useCallback(
    (coinBalances: MinimalCoinBalance[]): FiatEquivalentPrice | undefined =>
      getTotalFiat(coinBalances, data),
    [data],
  );

  const getFiatPriceForSymbol = useCallback(
    (symbol: string): number | undefined => {
      const fiatPrice = data ? data[symbol.toLowerCase()] : undefined;
      return fiatPrice ? fiatPrice.usd : undefined;
    },
    [data],
  );

  return (
    <FiatPricingContext.Provider
      value={{
        fiatPrices: data,
        getFormattedPrice,
        buildFiatEquivalentPrice,
        getTotalFiatBalance,
        getFiatPriceForSymbol,
      }}
    >
      {children}
    </FiatPricingContext.Provider>
  );
}

export const buildFiatEquivalentPrice = (
  tokenPrice: string,
  tokenDecimals: number,
  tokenSymbol: string,
  fiatPrices: CryptoFiatPrices | undefined,
  fiatCurrency = "usd",
) => {
  if (!fiatPrices) {
    return;
  }

  if (!tokenSymbol) {
    const tokenSymbolError = new Error("Token Symbol does not exist");
    notifyError(tokenSymbolError, "appError");
    return;
  }

  const symb = tokenSymbol.toLowerCase();

  if (!(symb in fiatPrices)) {
    return;
  }

  const fiatPrice = fiatPrices[symb];

  const usdValue = fiatPrice?.usd;
  if (!usdValue) {
    return;
  }

  const fiatEquivalentRaw = new BigNumber(tokenPrice)
    .multipliedBy(usdValue)
    .dividedBy(new BigNumber(10).pow(tokenDecimals));

  let formattedAmount: string;
  if (
    fiatEquivalentRaw.isGreaterThan(0) &&
    fiatEquivalentRaw.isLessThan(0.01)
  ) {
    formattedAmount = "< $0.01";
  } else {
    formattedAmount = fiatEquivalentRaw.toFormat(2);
  }

  return {
    currency: `${
      fiatEquivalentRaw.isGreaterThan(0) ? "" : "~ "
    }${fiatCurrency.toUpperCase()}`,
    amount: formattedAmount !== "NaN" ? formattedAmount : "",
    amountBN: fiatEquivalentRaw,
    prefixSymbol: fiatCurrency === "usd" ? "$" : "",
    usd24HrChange: fiatPrice.usd24hChange,
  };
};

export const getTotalFiat = (
  coinBalances: MinimalCoinBalance[],
  fiatPrices: CryptoFiatPrices | undefined,
): FiatEquivalentPrice | undefined => {
  if (!fiatPrices) return undefined;
  return coinBalances.reduce(
    (acc, coinBalance) => {
      const fiatEquivalentPrice = buildFiatEquivalentPrice(
        coinBalance.balance,
        coinBalance.decimals,
        coinBalance.symbol,
        fiatPrices,
      );
      if (!fiatEquivalentPrice) return acc;

      const amountBN = fiatEquivalentPrice.amountBN.plus(acc.amountBN);

      const usd24HrChange = amountBN.isZero()
        ? acc.usd24HrChange
        : acc.amountBN
            .multipliedBy(acc.usd24HrChange)
            .plus(
              fiatEquivalentPrice.amountBN.multipliedBy(
                fiatEquivalentPrice.usd24HrChange,
              ),
            )
            .dividedBy(amountBN)
            .toNumber();

      return {
        amountBN,
        amount: amountBN.toFormat(2),
        currency: acc.currency,
        prefixSymbol: acc.prefixSymbol,
        usd24HrChange: usd24HrChange,
      };
    },
    {
      amount: "0",
      amountBN: BigNumber(0),
      currency: "usd",
      prefixSymbol: "$",
      usd24HrChange: 0,
    },
  );
};
