import { type ReactElement, useCallback, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { useTheme } from "@/hooks";
import {
  CLOSE_MODAL,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { ModalContentProps } from "@/types";

export function ModalContent<RC extends ReactElement | undefined = undefined>({
  children,
  id = "",
  sx = {},
  className,
  ...props
}: ModalContentProps<RC>) {
  const { dispatchAction } = useOverlaysStore((state) => state.modalList);
  const theme = useTheme();
  const closeModal = useCallback(
    () =>
      dispatchAction({
        type: CLOSE_MODAL,
        payload: { id },
      }),
    [dispatchAction, id],
  );

  const renderedChildren =
    typeof children === "function"
      ? children({
          closeModal,
        })
      : children;

  const mergedSx = useMemo(
    () =>
      merge(
        {
          maxw: "100dvw",
          maxh: "100dvh",
          minh: "100%",
          overflow: "auto",
        },
        theme.components?.Modal?.ModalContent?.sxOverride ?? {},
        sx,
      ),
    [sx, theme.components?.Modal?.ModalContent?.sxOverride],
  );

  return (
    <Box {...props} sx={mergedSx} className={`${className ?? ""} ModalContent`}>
      {renderedChildren}
    </Box>
  );
}

ModalContent.displayName = "Modal.Content";
