import type { DeeplyNestedSx } from "@/types";

export const innerMaskedMediaSx: DeeplyNestedSx = {
  pos: "absolute",
  top: "0",
  left: "0",
  w: "100%",
  h: "100%",
  maskImage: "linear-gradient(to bottom, red 0%, transparent 80%)",
  maskMode: "alpha",
  maskSize: "100% 100%",

  "&::after": {
    content: '""',
    pos: "absolute",
    top: "0",
    left: "0",
    w: "100%",
    h: "100%",
    bg: "url(https://biome.immutable.com/hosted-assets/images/heroBgBlockNoise.webp)",
    mixBlendMode: "soft-light",
  },
};

export const baseContainerSx = {
  pos: "relative",
  w: "100%",
  overflow: "hidden",
};

export const mediaAssetBaseSx = {
  w: "100%",
  h: "100%",
};

export const dimVariantOverlaySx = {
  "&::after": {
    content: '""',
    pos: "absolute",
    top: "0",
    left: "0",
    w: "100%",
    h: "100%",
    bg: "base.color.translucent.inverse.700",
  },
};
