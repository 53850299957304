import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { useGetCurrentSizeClass } from "@/hooks";
import { type DomPropsWithDomRef, isDualVariantIcon } from "@/types";
import { hFlex } from "@/utils";

import { BaseClickable } from "../BaseClickable";
import {
  DEFAULT_SIMPLE_ICON_BUTTON_SIZE,
  SIMPLE_ICON_BUTTON_SIZES,
  type SimpleIconButtonBaseProps,
} from "./shared";

export function SimpleIconButton<
  RC extends ReactElement | undefined = undefined,
>({
  icon,
  iconVariant,
  className,
  sx = {},
  iconSx = {},
  size = DEFAULT_SIMPLE_ICON_BUTTON_SIZE,
  testId = "SimpleIconButton",
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"button"> & SimpleIconButtonBaseProps
  : SimpleIconButtonBaseProps & { rc: RC }) {
  const sizeAsClass = useGetCurrentSizeClass(
    size,
    DEFAULT_SIMPLE_ICON_BUTTON_SIZE.toString(),
    SIMPLE_ICON_BUTTON_SIZES,
  );
  const mergedSx = useMemo(
    () =>
      merge(
        {
          ...hFlex,
          alignItems: "center",
          justifyContent: "center",
          w: `base.icon.size.${sizeAsClass}`,
          h: `base.icon.size.${sizeAsClass}`,
          fontSize: `base.icon.size.${sizeAsClass}`,
          fill: "base.color.text.body.secondary",
          transitionProperty: "fill",
          transitionDuration: "base.motion.normal.fast.cssDuration",
          transitionTimingFunction: "base.motion.normal.fast.cssEase",

          "&[disabled]": {
            cursor: "default",
          },

          "&:hover:not([disabled]), &:active:not([disabled])": {
            fill: "base.color.text.body.primary",
          },
        },
        sx,
      ),
    [sx, sizeAsClass],
  );
  const mergedIconSx = useMemo(
    () =>
      merge(
        {
          w: "0.6666em",
          fill: "inherit",
        },
        iconSx,
      ),
    [iconSx],
  );
  const iconProps = isDualVariantIcon(icon)
    ? { icon, variant: iconVariant }
    : { icon };
  return (
    <BaseClickable
      {...props}
      sx={mergedSx}
      testId={testId}
      className={`${
        className ?? ""
      } SimpleIconButton SimpleIconButton--${sizeAsClass}`}
    >
      <Icon
        {...iconProps}
        sx={mergedIconSx}
        className="SimpleIconButton__icon"
        testId={`${testId}__icon`}
      />
    </BaseClickable>
  );
}

SimpleIconButton.displayName = "SimpleIconButton";
