export function staticMiddleEllipsis({
  text,
  leftSideLength = 4,
  rightSideLength = 4,
}: {
  text: string;
  leftSideLength?: number;
  rightSideLength?: number;
}) {
  const isTooShort = leftSideLength + rightSideLength >= text.length;
  if (isTooShort) return text;

  const leftSide = text.substring(0, leftSideLength);
  const rightSide = text.substring(text.length - rightSideLength, text.length);
  return `${leftSide}...${rightSide}`;
}
