import { DEFAULT_MOTION_PROFILE } from "@/constants";
import type { MotionProfile } from "@/types";

import { useTheme } from "./useTheme";

export function useGetMotionProfile(motionProfile?: MotionProfile) {
  const { globalConfig } = useTheme();
  const motionProfileToUse =
    motionProfile ?? globalConfig?.motionProfile ?? DEFAULT_MOTION_PROFILE;
  return motionProfileToUse;
}
