import { type ReactNode, useContext } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components";
import { useTheme } from "@/hooks/useTheme";
import { ClientOnlyPortal } from "@/overlays/ClientOnlyPortal";
import { DrawerOverlay } from "@/overlays/DrawerOverlay";
import { ModalOverlay } from "@/overlays/ModalOverlay/ModalOverlay";
import { PopoverOverlay } from "@/overlays/PopoverOverlay";
import { ToastOverlay } from "@/overlays/ToastOverlay";
import type { StringWithoutHash } from "@/types";

import { BiomePortalIdContext } from "../BiomePortalIdProvider/BiomePortalIdProvider";
import { positionedOverlayContainerCss } from "../shared";
import { BiomeOverlaysProvider } from "./overlaysContext";

const MODAL_SELECTOR = "modal-container";
const DRAWER_SELECTOR = "drawer-container";
const POPOVER_SELECTOR = "popover-container";
const TOAST_SELECTOR = "toast-container";

export type MountedOverlayAndProviderProps<T> = {
  children: ReactNode;
  modalContainerId?: StringWithoutHash<T>;
  drawerContainerId?: StringWithoutHash<T>;
  popoverContainerId?: StringWithoutHash<T>;
  toastContainerId?: StringWithoutHash<T>;
};

export function MountedOverlaysAndProvider<T extends string>({
  children,
  modalContainerId,
  drawerContainerId,
  popoverContainerId,
  toastContainerId,
}: MountedOverlayAndProviderProps<T>) {
  const portalId = useContext(BiomePortalIdContext);
  const {
    base: { zLevel },
  } = useTheme();

  return (
    <BiomeOverlaysProvider>
      {children}

      {/* MODAL */}
      <ClientOnlyPortal
        selector={
          modalContainerId
            ? `#${modalContainerId}`
            : `#${MODAL_SELECTOR}${portalId}`
        }
      >
        <ModalOverlay hasExternalContainer={Boolean(modalContainerId)} />
      </ClientOnlyPortal>
      {!modalContainerId && <div id={`${MODAL_SELECTOR}${portalId}`} />}

      {/* BOTTOM SHEET */}
      <ClientOnlyPortal
        selector={
          drawerContainerId
            ? `#${drawerContainerId}`
            : `#${DRAWER_SELECTOR}${portalId}`
        }
      >
        <DrawerOverlay hasExternalContainer={Boolean(drawerContainerId)} />
      </ClientOnlyPortal>
      {!drawerContainerId && <div id={`${DRAWER_SELECTOR}${portalId}`} />}

      {/* POPOVER */}
      <ClientOnlyPortal
        selector={
          popoverContainerId
            ? `${popoverContainerId}`
            : `#${POPOVER_SELECTOR}${portalId}`
        }
      >
        <PopoverOverlay />
      </ClientOnlyPortal>
      {!popoverContainerId && (
        <Box
          id={`${POPOVER_SELECTOR}${portalId}`}
          sx={merge(positionedOverlayContainerCss, {
            zIndex: zLevel.popover,
          })}
        />
      )}

      {/* TOAST */}
      <ClientOnlyPortal
        selector={
          toastContainerId
            ? `#${toastContainerId}`
            : `#${TOAST_SELECTOR}${portalId}`
        }
      >
        <ToastOverlay />
      </ClientOnlyPortal>
      {!toastContainerId && <div id={`${TOAST_SELECTOR}${portalId}`} />}
    </BiomeOverlaysProvider>
  );
}
