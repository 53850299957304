import type { Colors, Gradients, Shadows } from "../../types";

export const onLightColors: Colors = {
  brand: {
    1: "#131313",
    2: "#FFFFFF",
    3: "#E0E0E0",
    4: "#868686",
    5: "#36E0F8",
    6: "#1F8290",
  },
  neutral: {
    1000: "#E7E7E7",
    900: "#ECECEC",
    800: "#F1F1F1",
    700: "#F6F6F6",
    600: "#FAFAFA",
    500: "#FFFFFF",
  },
  accent: {
    1: "#B026BD",
    2: "#1FD3F5",
    3: "#67C6E0",
    4: "#5AA0D9",
    5: "#8395E5",
    6: "#D8EFF4",
    7: "#F5BDD0",
    8: "#EC98CA",
  },
  translucent: {
    emphasis: {
      500: "#0D0D0D1F", // rgba(255, 255, 255, 0.12)
      400: "#0D0D0D1A", // rgba(255, 255, 255, 0.10)
      300: "#0D0D0D14", // rgba(255, 255, 255, 0.08)
      200: "#0D0D0D0F", // rgba(255, 255, 255, 0.06)
      100: "#0D0D0D0A", // rgba(255, 255, 255, 0.04)
    },
    standard: {
      1000: "#0D0D0DFF", // rgba(13, 13, 13, 1)
      900: "#0D0D0DE0", // rgba(13, 13, 13, 0.88)
      800: "#0D0D0DCC", // rgba(13, 13, 13, 0.80)
      700: "#0D0D0DA3", // rgba(13, 13, 13, 0.64)
      600: "#0D0D0D7A", // rgba(13, 13, 13, 0.48)
      500: "#0D0D0D3D", // rgba(13, 13, 13, 0.24)
      400: "#0D0D0D29", // rgba(13, 13, 13, 0.16)
      300: "#0D0D0D1F", // rgba(13, 13, 13, 0.12)
      200: "#0D0D0D1A", // rgba(13, 13, 13, 0.10)
      150: "#0D0D0D14", // rgba(13, 13, 13, 0.08)
      100: "#0D0D0D0A", // rgba(13, 13, 13, 0.04)
      50: "#0D0D0D05", // rgba(13, 13, 13, 0.02)
    },
    inverse: {
      1000: "#FFFFFFFF",
      900: "#FFFFFFE6",
      800: "#FFFFFFCC",
      700: "#FFFFFFA3",
      600: "#FFFFFF7A",
      500: "#FFFFFF3D",
      400: "#FFFFFF29",
      300: "#FFFFFF1F",
      200: "#FFFFFF14",
      150: "#FFFFFF0F",
      100: "#FFFFFF0A",
      50: "#FFFFFF05",
    },
  },
  status: {
    guidance: {
      bright: "#36E0F8",
      dim: "#9AEFFB",
    },
    success: {
      bright: "#148530",
      dim: "#9be182",
    },
    fatal: {
      bright: "#BB3049",
      dim: "#EA6385",
    },
    attention: {
      bright: "#FBFF6D",
      dim: "#FCFEBF",
    },
  },
  fixed: {
    black: {
      1000: "#0D0D0D",
      100: "#0D0D0D14",
    },
    white: {
      1000: "#FFFFFF",
      100: "#FFFFFF14",
    },
  },
  text: {
    highlight: "#36E0F866",
    heading: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      emphasis: "base.gradient.1",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "base.color.brand.3",
      },
    },
    body: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "base.color.brand.3",
      },
    },
    caption: {
      primary: "base.color.brand.1",
      secondary: "base.color.brand.4",
      inverse: {
        primary: "base.color.brand.2",
        secondary: "base.color.brand.3",
      },
    },
    link: {
      primary: "base.color.accent.1",
      secondary: "base.color.brand.1",
      inverse: {
        primary: "#f191fa",
        secondary: "base.color.brand.2",
      },
    },
    status: {
      success: {
        primary: "base.color.status.success.bright",
        secondary: "base.color.status.success.dim",
      },
      fatal: {
        primary: "base.color.status.fatal.bright",
        secondary: "base.color.status.fatal.dim",
      },
      guidance: {
        primary: "#206A74",
        secondary: "#22727E",
      },
      attention: {
        primary: "base.color.status.attention.bright",
        secondary: "base.color.status.attention.dim",
      },
    },
  },
  overlay: "base.color.translucent.standard.600",
} as const;

export const onLightShadows: Shadows = {
  100: "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 1px 4px 1px rgba(0, 0, 0, 0.32)",
  200: "0px 2px 6px 2px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.32)",
  300: "0px 4px 8px 4px rgba(0, 0, 0, 0.16), 0px 1px 4px rgba(0, 0, 0, 0.32)",
  400: "0px 8px 10px 4px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.32)",
  500: "0px 8px 12px 6px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.32)",
} as const;

export const onLightGradients: Gradients = {
  1: {
    spectrum: "linear-gradient(90.68deg, #F191FA 0%, #83E3F0 99.82%)",
    blendMode: "normal",
    svgDefs: `<linearGradient id="gradient-1-singleLayer" x1="0" y1="0" x2="24" y2="0.214702"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#F191FA" />
      <stop offset="1" stop-color="#83E3F0" />
    </linearGradient>`,
    svgDefIds: "gradient-1-singleLayer",
  },
  2: {
    spectrum:
      "radial-gradient(96.87% 65.57% at 54.34% 93.79%, #FCF5EE 0%, rgba(236, 190, 225, 0) 71.51%), radial-gradient(73.48% 242.97% at 26.54% 26.42%, #A3EEF8 0%, #A4DCF5 17.71%, #A6AEEC 38.02%, #ECBEE1 100%)",
    blendMode: "normal, normal",
    svgDefs: `<radialGradient id="gradient-2-bottomLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(4.7777 4.75595) rotate(44.9817) scale(18.6995 39.6574)">
      <stop stop-color="#A3EEF8" />
      <stop offset="0.177083" stop-color="#A4DCF5" />
      <stop offset="0.380208" stop-color="#A6AEEC" />
      <stop offset="1" stop-color="#ECBEE1" />
    </radialGradient>
    <radialGradient id="gradient-2-topLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(9.7806 16.8827) rotate(84.265) scale(9.68552 18.5286)">
      <stop stop-color="#FCF5EE" />
      <stop offset="0.715135" stop-color="#ECBEE1" stop-opacity="0" />
    </radialGradient>`,
    svgDefIds: "gradient-2-bottomLayer, gradient-2-topLayer",
  },
  3: {
    spectrum:
      "radial-gradient(100% 100% at 24.5% 100%, rgba(106, 160, 242, 0.9) 0%, rgba(198, 229, 247, 0) 100%), linear-gradient(81.66deg, rgba(124, 246, 251, 0.48) 9.38%, rgba(254, 253, 248, 0.7) 98.47%), radial-gradient(4009.6% 10453.55% at 0% 0%, #7EF8FE 0%, #7ABFEC 30.21%, #AEC3ED 45.54%, #DEF3F6 61.46%, #FAF9F3 100%)",
    blendMode: "normal, normal, normal",
    svgDefs: `<radialGradient id="gradient-3-bottomLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="rotate(60.6728) scale(36.75 1550.85)">
      <stop stop-color="#7EF8FE" />
      <stop offset="0.302083" stop-color="#7ABFEC" />
      <stop offset="0.455396" stop-color="#AEC3ED" />
      <stop offset="0.614583" stop-color="#DEF3F6" />
      <stop offset="1" stop-color="#FAF9F3" />
    </radialGradient>
    <linearGradient id="gradient-3-middleLayer" x1="-1.26123e-07" y1="4.8" x2="24" y2="2.16"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#7CF6FB" stop-opacity="0.48" />
      <stop offset="1" stop-color="#FEFDF8" stop-opacity="0.7" />
    </linearGradient>
    <radialGradient id="gradient-3-topLayer" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
      gradientTransform="translate(4.41 18) rotate(-52.9472) scale(22.5541)">
      <stop stop-color="#6AA0F2" />
      <stop offset="1" stop-color="#C6E5F7" stop-opacity="0" />
    </radialGradient>`,
    svgDefIds: `gradient-3-bottomLayer,
      gradient-3-middleLayer,
      gradient-3-topLayer`,
  },
} as const;
