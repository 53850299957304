import type { BiomeTheme, ButtonSize } from "@/types";
import {
  largeButtonStaticStyles,
  mediumButtonStaticStyles,
  smallButtonStaticStyles,
} from "./shared";

export const tertiaryButtonGenericStyles = {
  color: "base.color.text.body.primary",
  transitionDelay: "0.06s",
  background: "base.color.translucent.standard.200",
  backdropFilter: "base.frost.500",
  flexShrink: 0,

  "&::before": {
    position: "absolute",
    top: "50%",
    left: "50%",
    translate: "-50% -50%",
    boxShadow: "inset 0 0 0 0px transparent",
    transitionProperty: "box-shadow",
  },
};

export const tertiaryInverseButtonGenericStyles = {
  ...tertiaryButtonGenericStyles,
  color: "base.color.text.body.inverse.primary",
  background: "base.color.translucent.inverse.200",
  flexShrink: 0,

  "&::before": {
    ...tertiaryButtonGenericStyles["&::before"],
  },
};

export function getSmallTertiaryButtonUniqueStyles(
  theme: BiomeTheme,
  inverse?: boolean,
) {
  const hoverCutaway = `calc((${theme.base.border.size[100]} + ${theme.base.border.size[200]}) * 2)`;
  const pressCutaway = `calc((${theme.base.border.size[100]} + ${theme.base.border.size[200]}) * 2)`;

  return {
    ...smallButtonStaticStyles(theme),

    "&::before": {
      borderRadius: theme.base.borderRadius.x15,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[200]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${hoverCutaway})`,
        height: `calc(100% - ${hoverCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[100]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[200]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[200]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },
  };
}
export function getMediumTertiaryButtonUniqueStyles(
  theme: BiomeTheme,
  inverse?: boolean,
) {
  const hoverCutaway = `calc((${theme.base.border.size[200]} + ${theme.base.border.size[300]}) * 2)`;
  const pressCutaway = `calc((${theme.base.border.size[200]} + ${theme.base.border.size[300]}) * 2)`;

  return {
    ...mediumButtonStaticStyles(theme),

    "&::before": {
      borderRadius: theme.base.borderRadius.x20,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[300]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${hoverCutaway})`,
        height: `calc(100% - ${hoverCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[100]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[300]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[300]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },
  };
}
export function getLargeTertiaryButtonUniqueStyles(
  theme: BiomeTheme,
  inverse?: boolean,
) {
  const hoverCutaway = `calc((${theme.base.border.size[200]} + ${theme.base.border.size[400]}) * 2)`;
  const pressCutaway = `calc((${theme.base.border.size[200]} + ${theme.base.border.size[400]}) * 2)`;

  return {
    ...largeButtonStaticStyles(theme),

    "&::before": {
      borderRadius: theme.base.borderRadius.x25,
    },

    "&:hover:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[400]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${hoverCutaway})`,
        height: `calc(100% - ${hoverCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[200]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },

    "&:active:not([disabled])": {
      boxShadow: `inset 0 0 0 ${theme.base.border.size[400]} ${
        inverse
          ? theme.base.color.text.body.inverse.primary
          : theme.base.color.text.body.primary
      }`,

      "&::before": {
        content: '""',
        width: `calc(100% - ${pressCutaway})`,
        height: `calc(100% - ${pressCutaway})`,
        boxShadow: `inset 0 0 0 ${theme.base.border.size[400]} ${
          inverse
            ? theme.base.color.text.body.inverse.primary
            : theme.base.color.text.body.primary
        }`,
      },
    },
  };
}

export function getTertiaryButtonStyles(
  size: ButtonSize,
  themeProps: BiomeTheme,
  inverse?: boolean,
) {
  return size === "small"
    ? getSmallTertiaryButtonUniqueStyles(themeProps, inverse)
    : size === "medium"
      ? getMediumTertiaryButtonUniqueStyles(themeProps, inverse)
      : getLargeTertiaryButtonUniqueStyles(themeProps, inverse);
}
