import { keyframes } from "@emotion/react";

const gradientWipe = keyframes`100% { mask-position: left }`;

export const shimmerSx = {
  display: "block",
  width: "100%",
  height: "100%",
  bgi: "base.gradient.1",
  mask: "linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/400% 100%",
  brad: "base.borderRadius.x2",
  backgroundRepeat: "no-repeat",
  animation: `${gradientWipe} 1.3s infinite`,
  opacity: 0.32,
  minw: "10px",
  minh: "5px",
};
