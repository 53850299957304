import type { Interpolation } from "@emotion/react";
import { useMemo } from "react";

import type { BiomeTheme, DeeplyNestedSx } from "@/types";
import { convertSxToEmotionStyles } from "@/utils/sxToEmotionStyles";

import { useTheme } from "./useTheme";

/**
 * This function converts SxProps to Emotion styles using a given theme or the default theme.
 * @param {DeeplyNestedSx} sx - SxProps is a type definition for the theme-ui style object. It is an object
 * that contains CSS properties and values that can be applied to a component.
 * @param {BiomeTheme} [themeInput] - `themeInput` is an optional parameter of type `BiomeTheme` that
 * allows the user to pass in a custom theme object to be used instead of the default theme provided by
 * the `useTheme` hook. If `themeInput` is not provided, the default theme will be used.
 * @returns The `useConvertSxToEmotionStyles` function returns a memoized version of the
 * `convertSxToEmotionStyles` function with the provided `sx` and `themeInput` props. The `themeInput`
 * prop is optional and if provided, it will override the default theme provided by the `useTheme`
 * hook.
 */
export function useConvertSxToEmotionStyles(
  sx: DeeplyNestedSx,
  themeInput?: BiomeTheme,
) {
  let themeProps = useTheme();
  if (themeInput) themeProps = themeInput;
  return useMemo(
    () => convertSxToEmotionStyles(sx, themeProps) as Interpolation<BiomeTheme>,
    [sx, themeProps],
  );
}
