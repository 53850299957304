import { Children, type ReactElement, isValidElement, useMemo } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { merge } from "ts-deepmerge";

import { isChildSubcomponent } from "@/utils";

import { Divider } from "../Divider";
import { SmartClone } from "../SmartClone";
import { Stack, type StackProps } from "../Stack";
import type { ChevronSide } from "./shared";
import { expandedContentContainer } from "./style";

export type AccordionExpandedContentProps<
  RC extends ReactElement | undefined = undefined,
> = StackProps<RC> & { chevronSide?: ChevronSide };

export function AccordionExpandedContent<
  RC extends ReactElement | undefined = undefined,
>({
  children,
  className,
  sx = {},
  chevronSide = "left",
  ...props
}: AccordionExpandedContentProps<RC>) {
  const flattenedChildren = useMemo(
    () => flattenChildren(children),
    [children],
  );
  return (
    <Stack
      {...props}
      className={`${className ?? ""} AccordionExpandedContent`}
      sx={merge(
        expandedContentContainer,
        {
          ...(chevronSide === "left"
            ? { pl: "base.spacing.x15" }
            : { pr: "base.spacing.x15" }),
        },
        sx,
      )}
    >
      {Children.map(flattenedChildren, (child) => {
        if (isValidElement(child) && isChildSubcomponent(child, Divider)) {
          return <SmartClone size="xSmall">{child}</SmartClone>;
        }

        return child;
      })}
    </Stack>
  );
}

AccordionExpandedContent.displayName = "Accordion.ExpandedContent";
