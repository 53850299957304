import { useEffect, useState } from "react";

import { warnUser } from "../utils";

export function getInvalidPropWarningMsg(
  component: "Pagination" | "OnboardingPagination",
  propKind: "totalPages" | "currentPage" | "defaultPage",
  badProp: number,
) {
  return `<${component} /> Warning: 
  ${propKind} is invalid. It must be greater than${
    /currentPage|defaultPage/.test(propKind) ? "or equal to" : ""
  } ${
    /currentPage|defaultPage/.test(propKind) ? "1" : "0"
  }, but got ${badProp}`;
}

export function getInvalidCurrentPageWarningMsg(
  component: "Pagination" | "OnboardingPagination",
  currentPage: number,
  totalPages: number,
) {
  return `<${component} /> Warning: 
  currentPage is invalid. It must be less than or equal to ${totalPages}, but got ${currentPage}`;
}

export function useCheckForInvalidPaginationProps(
  component: "Pagination" | "OnboardingPagination",
  props: { totalPages: number; currentPage?: number; defaultPage: number },
) {
  const { totalPages, currentPage, defaultPage } = props;
  const [errorState, setErrorState] = useState(false);

  // @NOTE: handle various erroneous prop input states:
  useEffect(() => {
    if (currentPage !== undefined && currentPage < 1) {
      warnUser(getInvalidPropWarningMsg(component, "currentPage", currentPage));
      setErrorState(true);
      return;
    }
    if (currentPage !== undefined && currentPage > totalPages) {
      warnUser(
        getInvalidCurrentPageWarningMsg(component, currentPage, totalPages),
      );
      setErrorState(true);
      return;
    }
    if (totalPages < 1) {
      warnUser(getInvalidPropWarningMsg(component, "totalPages", totalPages));
      setErrorState(true);
      return;
    }
    if (defaultPage < 1) {
      warnUser(getInvalidPropWarningMsg(component, "defaultPage", defaultPage));
      setErrorState(true);
      return;
    }

    setErrorState(false);
  }, [component, currentPage, defaultPage, totalPages]);

  return errorState;
}
