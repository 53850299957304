import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedVideo } from "@/components/FramedThings";
import type { FramedVideoProps, MenuItemProps } from "@/types";
import { useGetBiomeImageSizeFromMenuItemSize } from "./shared";
import { baseLeftImageStyles } from "./styles";

export type MenuItemFramedVideoProps<
  RC extends ReactElement | undefined = undefined,
> = FramedVideoProps<RC> & Pick<MenuItemProps, "size">;

export function MenuItemFramedVideo<
  RC extends ReactElement | undefined = undefined,
>({
  sx = {},
  size = "small",
  className,
  ...props
}: MenuItemFramedVideoProps<RC>) {
  const mergedSx = useMemo(() => merge(baseLeftImageStyles, sx), [sx]);
  const sizeToUse = useGetBiomeImageSizeFromMenuItemSize(size);
  return (
    <FramedVideo
      {...props}
      sx={mergedSx}
      size={sizeToUse}
      className={`${className ?? ""} MenuItemFramedVideo`}
    />
  );
}

MenuItemFramedVideo.displayName = "MenuItem.FramedVideo";
