import axios, { AxiosError } from "axios";

import { appConfig } from "@/constants";
const baseUrl = appConfig.IMMUTABLE_BASE_URI;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function retryQuery(failureCount: number, error: any): boolean {
  // Don't retry client side errors
  if (
    error instanceof AxiosError &&
    error.response?.status &&
    error.response?.status.toString().startsWith("4")
  ) {
    return false;
  }

  return failureCount <= 3;
}

export const get = async <T>(url: string): Promise<T> => {
  const response = await fetch(`${appConfig.IMMUTABLE_BASE_URI}/${url}`);
  if (!response.ok) {
    throw new Error("Failed to fetch data");
  }
  return response.json();
};

// biome-ignore lint/suspicious/noExplicitAny:
export async function authorizedGet<T = any>(
  path: string,
  accessToken: string,
): Promise<T> {
  const resp = await axios.get<T>(baseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return resp.data;
}

// Ensures we have the latest access token when using SWR
export async function authorizedFetcherGet<T>(
  path: string,
  getAccessToken: () => Promise<string | undefined>,
): Promise<T> {
  const accessToken = await getAccessToken();
  if (!accessToken) {
    throw new Error("No access token");
  }
  const resp = await axios.get<T>(baseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return resp.data;
}

export async function authorizedDelete(
  path: string,
  accessToken: string,
  data?: unknown,
) {
  const resp = await axios.delete(baseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
    data,
  });

  return resp.data;
}

// biome-ignore lint/suspicious/noExplicitAny:
export async function authorizedPost<T = any>(
  path: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any,
  accessToken: string,
): Promise<T> {
  const resp = await axios.post<T>(baseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return resp.data;
}

export async function authorizedPatch(
  path: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any,
  accessToken: string,
) {
  const resp = await axios.patch(baseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return resp.data;
}
