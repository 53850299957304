import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { FramedImage } from "@/components/FramedThings";
import { useTheme } from "@/hooks/useTheme";
import type {
  ButtonSize,
  ButtonVariant,
  FixedSizeFramedImageProps,
  MakeResponsive,
} from "@/types";

import {
  getButtonIconOrLogoSizeStyles,
  getButtonIconVariantStyles,
  getResponsiveButtonIconOrLogoStyles,
} from "./styles";

export type ButtonFramedImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = FixedSizeFramedImageProps<RC, Use> & {
  /**
   * Controls the size of the Button
   * (this subcomponent's parent)
   *
   * NOTE: this prop is automatically passed down from the parent Button component.
   * You should rarely need to set this prop manually
   */
  size?: MakeResponsive<ButtonSize>;
  /**
   * Controls the variant of the Button
   * (this subcomponent's parent)
   *
   * NOTE: this prop is automatically passed down from the parent Button component.
   * You should rarely need to set this prop manually
   */
  variant?: MakeResponsive<ButtonVariant>;
};

export function ButtonFramedImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({
  rc = <span />,
  use,
  sx = {},
  size = "medium",
  variant = "primary",
  className,
  ...props
}: ButtonFramedImageProps<RC, Use>) {
  const themeProps = useTheme();
  const {
    defaultSize,
    defaultVariant,
    styles: responsiveStyles,
  } = getResponsiveButtonIconOrLogoStyles({
    size,
    variant,
    themeProps,
  });
  const allStyles = useMemo(
    () =>
      merge(
        { d: "inline-flex", flexShrink: 0 },
        getButtonIconOrLogoSizeStyles(defaultSize, themeProps),
        getButtonIconVariantStyles(defaultVariant, themeProps),
        responsiveStyles,
        sx,
      ),
    [defaultSize, defaultVariant, responsiveStyles, sx, themeProps],
  );
  return (
    <FramedImage
      {...props}
      sx={allStyles}
      rc={rc}
      use={use}
      className={`${className ?? ""} ButtonImage`}
    />
  );
}

ButtonFramedImage.displayName = "Button.FramedImage";
