import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import type { BodyProps, InputTextAlign } from "@/types";

type InputListLabelProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC> & { textAlign?: InputTextAlign };

export function InputListLabel<
  RC extends ReactElement | undefined = undefined,
>({
  size = "small",
  className,
  sx = {},
  textAlign,
  testId = "InputListLabel",
  ...props
}: InputListLabelProps<RC>) {
  return (
    <Body
      {...props}
      size={size}
      className={`${className ?? ""} InputListLabel`}
      testId={testId}
      sx={merge(
        {
          mb: "base.spacing.x2",
          py: "base.spacing.x1",
          textAlign: textAlign ?? "left",
        },
        sx,
      )}
    />
  );
}

InputListLabel.displayName = "InputList.Label";
