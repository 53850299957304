import type { OptionKey } from "@/types";
import { noOptionFoundErrorText } from "../Select/shared";

export function createMultiSelectErrorMessage(selectedOptions: OptionKey[]) {
  return selectedOptions.reduce(
    (accum, opt) => `${accum} 
${noOptionFoundErrorText(opt)}`,
    "",
  ) as string;
}
