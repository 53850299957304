import type { BodySize, BodyWeight } from "@biom3/design-tokens";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Stack } from "@/components/Stack";
import { Body, Heading, RollingText } from "@/components/Text";
import { useTheme } from "@/hooks";
import { useWindowSizeStore } from "@/providers";
import type { MakeResponsive, StandardComponentWithProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import {
  COUNTDOWN_TIMER_SIZES,
  type CountdownTimerSize,
  type CountdownTimerVariant,
  DEFAULT_COUNTDOWN_TIMER_SIZE,
  DEFAULT_COUNTDOWN_TIMER_VARIANT,
} from "./shared";
import {
  baseTileSx,
  getResponsiveTileSx,
  getTileSx,
  getTileVariantSx,
} from "./styles";

export type CountdownTileProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    digit: number;
    label: string;
    size?: MakeResponsive<CountdownTimerSize>;
    variant?: CountdownTimerVariant;
  }
>;

function pickLabelFontWeight(size: CountdownTimerSize) {
  switch (size) {
    case "xLarge":
      return "bold";

    case "small":
    case "medium":
    case "large":
    default:
      return "regular";
  }
}

function pickLabelFontSize(size: CountdownTimerSize) {
  switch (size) {
    case "large":
      return "xSmall";

    case "xLarge":
      return "small";

    case "small":
    case "medium":
    default:
      return "xxSmall";
  }
}

function pickDigitComponent(size: CountdownTimerSize) {
  switch (size) {
    case "small":
      return <Body size="xxSmall" weight="bold" />;
    case "large":
      return <Body size="large" weight="bold" />;
    case "xLarge":
      return <Heading size="medium" weight="bold" />;
    case "medium":
    default:
      return <Body size="small" weight="bold" />;
  }
}

function usePickDigitComponent(
  size: MakeResponsive<CountdownTimerSize>,
): ReactElement | undefined {
  const { state: width } = useWindowSizeStore((state) => state.width);
  const {
    base: { breakpointAsArray },
  } = useTheme();

  const picked = useMemo(() => {
    const defaultSize = pickDigitComponent("medium");
    if (width === null) return defaultSize;
    if (Array.isArray(size)) {
      const actualResponsiveSizes = [...size];
      actualResponsiveSizes.shift();
      return actualResponsiveSizes.reduce((accum, curr, index) => {
        let newAccum = accum;
        if (curr !== null && width >= (breakpointAsArray?.[index] ?? 0)) {
          newAccum = pickDigitComponent(curr as CountdownTimerSize);
        }
        return newAccum;
      }, defaultSize);
    }

    return /small|medium|large|xLarge/.test(size)
      ? pickDigitComponent(size)
      : undefined;
  }, [breakpointAsArray, size, width]);

  return picked;
}

export function CountdownTile({
  digit,
  label,
  size = DEFAULT_COUNTDOWN_TIMER_SIZE,
  variant = DEFAULT_COUNTDOWN_TIMER_VARIANT,
  sx = {},
  ...props
}: CountdownTileProps) {
  const theme = useTheme();
  const prettyDigit = useMemo(
    () => (digit < 10 ? `0${digit}` : digit),
    [digit],
  );
  const pickedDigitUseComponent = usePickDigitComponent(size);
  const startingSize = getStartingSize(
    size,
    DEFAULT_COUNTDOWN_TIMER_SIZE,
    COUNTDOWN_TIMER_SIZES,
  );
  const allSx = merge(
    baseTileSx,
    getTileVariantSx(variant),
    getTileSx({ size: startingSize, theme }),
    getResponsiveTileSx({ size, theme }),
    sx,
  );
  const labelTextSize = useMemo(() => {
    if (Array.isArray(size)) {
      return size.map((s) => {
        return pickLabelFontSize(s as CountdownTimerSize);
      });
    }
    return pickLabelFontSize(size);
  }, [size]);
  const labelFontWeight = useMemo(() => {
    if (Array.isArray(size)) {
      return size.map((s) => {
        return pickLabelFontWeight(s as CountdownTimerSize);
      });
    }
    return pickLabelFontWeight(size);
  }, [size]);

  return (
    <Stack
      {...props}
      alignItems="center"
      justifyContent="center"
      gap="0px"
      sx={allSx}
      className={`CountdownTile CountdownTile--${label}`}
      testId={`CountdownTimer__Tile--${label}`}
    >
      <RollingText
        text={prettyDigit.toString()}
        use={pickedDigitUseComponent}
        testId="CountdownTimer__tile__textRoller"
      />
      <Body
        size={labelTextSize as MakeResponsive<BodySize>}
        weight={labelFontWeight as MakeResponsive<BodyWeight>}
        testId="CountdownTimer__tile__label"
      >
        {label}
      </Body>
    </Stack>
  );
}

CountdownTile.displayName = "CountdownTile";
