import type { ReactElement } from "react";

import { Checkbox } from "@/components/Checkbox";
import { Radio } from "@/components/Radio";
import type { StackProps } from "@/components/Stack";
import { Toggle } from "@/components/Toggle";
import type { InputTextAlign, MakeResponsive } from "@/types";
import type { DividerSize } from "../Divider";

export const SUPPORTED_INPUT_COMPONENTS = [Toggle, Checkbox, Radio] as const;
export const SUPPORTED_INPUT_COMPONENTS_MAP = {
  Checkbox,
  Radio,
  Toggle,
} as const;

type BaseInputListProps = {
  id?: string;
  textAlign?: InputTextAlign;
  dividerSize?: MakeResponsive<DividerSize>;
  hideFormControlDividers?: boolean;
};
export type InputListProps<RC extends ReactElement | undefined = undefined> =
  Omit<StackProps<RC>, "direction"> & BaseInputListProps;
