import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { useTheme } from "@/hooks";
import type {
  ButtonSize,
  ButtonVariant,
  IconSubcomponentProps,
  MakeResponsive,
} from "@/types";
import { type ReactElement, useMemo } from "react";
import {
  getButtonIconOrLogoSizeStyles,
  getButtonIconVariantStyles,
  getResponsiveButtonIconOrLogoStyles,
} from "./styles";

export type ButtonIconProps<RC extends ReactElement | undefined = undefined> =
  IconSubcomponentProps<
    {
      size?: MakeResponsive<ButtonSize>;
      variant?: MakeResponsive<ButtonVariant>;
    },
    RC
  >;

export function ButtonIcon<RC extends ReactElement | undefined = undefined>({
  sx = {},
  variant = "primary",
  size = "medium",
  ...props
}: ButtonIconProps<RC>) {
  const { iconVariant, ...otherProps } =
    "iconVariant" in props ? props : { ...props, iconVariant: undefined };
  const themeProps = useTheme();
  const {
    defaultSize,
    defaultVariant,
    styles: responsiveStyles,
  } = getResponsiveButtonIconOrLogoStyles({
    size,
    variant,
    themeProps,
  });
  const allStyles = useMemo(
    () =>
      merge(
        { flexShrink: 0 },
        getButtonIconOrLogoSizeStyles(defaultSize, themeProps),
        getButtonIconVariantStyles(defaultVariant, themeProps),
        responsiveStyles,
        sx,
      ),
    [defaultSize, defaultVariant, responsiveStyles, sx, themeProps],
  );
  const allProps = iconVariant
    ? {
        ...otherProps,
        variant: iconVariant,
      }
    : otherProps;
  return <Icon {...allProps} sx={allStyles} />;
}

ButtonIcon.displayName = "Button.Icon";
