import type { Colors } from "@biom3/design-tokens";
import type { ReactElement } from "react";

import type {
  BodyProps,
  BoxProps,
  ButtConProps,
  ButtonProps,
  ButtonVariant,
  DistributiveOmit,
} from "@/types";

export const DEFAULT_BANNER_VARIANT = "standard";
export type BannerVariant =
  | keyof Colors["status"]
  | typeof DEFAULT_BANNER_VARIANT;

export type BannerProps = BoxProps & {
  variant?: BannerVariant;
  forceMultilineText?: boolean;
};
export type BannerTextProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC> & { variant?: BannerVariant };

type InvertableButtonVariants = Extract<ButtonVariant, "primary" | "tertiary">;

export type BannerRightButtConProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtConProps<RC>, "variant" | "size"> & {
  bannerVariant?: BannerVariant;
  variant?: InvertableButtonVariants;
};

export type BannerRightButtonProps<
  RC extends ReactElement | undefined = undefined,
> = DistributiveOmit<ButtonProps<RC>, "variant" | "size"> & {
  bannerVariant?: BannerVariant;
  variant?: InvertableButtonVariants;
};
