import type { ReactElement } from "react";

import type { ButtConProps } from "@/types";
import { ButtCon } from "../Clickable";

export type ToastButtConProps<RC extends ReactElement | undefined = undefined> =
  ButtConProps<RC>;

export function ToastButtCon<RC extends ReactElement | undefined = undefined>({
  size = "small",
  variant = "tertiary/inverse",
  ...props
}: ToastButtConProps<RC>) {
  return <ButtCon {...props} size={size} variant={variant} />;
}

ToastButtCon.displayName = "Toast.ButtCon";
ToastButtCon.SvgIcon = ButtCon.SvgIcon;
