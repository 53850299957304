import { ClassNames } from "@emotion/react";
import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { BaseClickable } from "@/components/Clickable";
import { SmartClone } from "@/components/SmartClone";
import {
  useGetSubcomponentChild,
  useSplitApartChildrenAndSubComponents,
} from "@/hooks";
import type { BaseClickableWithRCAndDomProps } from "@/types";

import { childHasSxProp } from "@/utils";
import { SpineMenuItemBadge } from "./SpineMenuItemBadge";
import { SpineMenuItemIcon } from "./SpineMenuItemIcon";
import { SpineMenuItemLabel } from "./SpineMenuItemLabel";
import { badgeSx, iconSx, spineMenuItemSx } from "./styles";

export type SpineMenuItemProps<
  RC extends ReactElement | undefined = undefined,
> = BaseClickableWithRCAndDomProps<RC> & {
  selected?: boolean;
};

export function SpineMenuItem<RC extends ReactElement | undefined = undefined>({
  sx = {},
  children,
  testId,
  selected,
  className,
  ...props
}: SpineMenuItemProps<RC>) {
  const label = useGetSubcomponentChild(children, SpineMenuItemLabel);
  const icon = useGetSubcomponentChild(children, SpineMenuItemIcon);
  const badge = useGetSubcomponentChild(children, SpineMenuItemBadge);
  const { otherChildren } = useSplitApartChildrenAndSubComponents(children, [
    SpineMenuItemLabel,
    SpineMenuItemIcon,
    SpineMenuItemBadge,
  ]);
  const mergedIconSx = useMemo(
    () =>
      childHasSxProp(icon) ? merge(iconSx, icon?.props?.sx ?? {}) : iconSx,
    [icon],
  );
  const mergedBadgeSx = useMemo(
    () =>
      childHasSxProp(badge) ? merge(badgeSx, badge?.props?.sx ?? {}) : badgeSx,
    [badge],
  );
  return (
    <ClassNames>
      {({ cx }) => (
        <BaseClickable
          {...props}
          testId={testId}
          sx={merge(spineMenuItemSx, sx)}
          className={cx(className, { selected })}
        >
          {icon && (
            <SmartClone testId={`${testId}__icon`} sx={mergedIconSx}>
              {icon}
            </SmartClone>
          )}
          {label && (
            <SmartClone testId={`${testId}__label`}>{label}</SmartClone>
          )}
          {badge && (
            <SmartClone testId={`${testId}__badge`} sx={mergedBadgeSx}>
              {badge}
            </SmartClone>
          )}
          {otherChildren}
        </BaseClickable>
      )}
    </ClassNames>
  );
}

SpineMenuItem.displayName = "SpineMenu.Item";
SpineMenuItem.Label = SpineMenuItemLabel;
SpineMenuItem.Icon = SpineMenuItemIcon;
SpineMenuItem.Badge = SpineMenuItemBadge;
