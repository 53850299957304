import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import type { LinkIconProps } from "@/types";

import { useMemo } from "react";
import { linkIconBaseSx } from "./styles";

export function LinkIcon({ sx = {}, className, ...props }: LinkIconProps) {
  const mergedSx = useMemo(() => merge(linkIconBaseSx, sx), [sx]);
  return (
    <Icon {...props} sx={mergedSx} className={`${className ?? ""} LinkIcon`} />
  );
}

LinkIcon.displayName = "Link.Icon";
