import { type ReactNode, useMemo } from "react";
import { isFragment } from "react-is";

import { isChildCustomReactComponent } from "@/utils/componentHelpers";

/**
 * This function determines whether to clone a React component or a DOM element based on the type of
 * children passed in.
 * @param {ReactNode} children - The `children` parameter is a ReactNode, which can be any valid React
 * child element, such as a React component, a string, a number, an array of React components, etc.
 * This function is used to determine whether the given `children` should be cloned as a React
 * component or as
 * @returns The function `useGetComponentCloneMode` returns an object with two properties:
 * `shouldCloneAsComponent` and `shouldCloneAsDom`. These properties indicate whether the `children`
 * passed to the function should be cloned as a React component or as a DOM element.
 */
export function useGetComponentCloneMode(children: ReactNode) {
  const cloneMode = useMemo(() => {
    const childIsReactComponent = isChildCustomReactComponent(children);
    const childIsReactFragment = isFragment(children);
    const childIsArray = Array.isArray(children);
    const childIsPrimitive = typeof children !== "object";
    const shouldCloneAsComponent =
      childIsReactComponent && !childIsReactFragment;
    const shouldCloneAsDom =
      children !== null &&
      !childIsReactComponent &&
      !childIsArray &&
      !childIsReactFragment &&
      !childIsPrimitive;
    return { shouldCloneAsDom, shouldCloneAsComponent };
  }, [children]);

  return cloneMode;
}
