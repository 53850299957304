import type { DeeplyNestedSx } from "@/types";

export const starContainerSx: DeeplyNestedSx = {
  pos: "relative",
  gap: "var(--gapSize)",
  pointerEvents: "none",
  scale: "1",
  transitionProperty: "scale",

  "&.RatingStar--hover": {
    scale: "1.3",
  },

  "&.RatingStar--empty": {
    ".RatingStar__foreground": {
      fill: "transparent",
    },
  },

  "&.RatingStar--full, &.RatingStar--half": {
    ".RatingStar__foreground": {
      fill: "var(--starColor)",
    },
  },
};

export const frontStarSx = {
  w: "var(--starSize)",
};

export const backStarSx = {
  w: "var(--starSize)",
  fill: "base.color.translucent.standard.200",
  pos: "absolute",
  top: "0",
  left: "0",
};
