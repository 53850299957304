import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import { DEFAULT_MENU_ITEM_SIZE } from "@/constants";
import type {
  BodyProps,
  MakeResponsive,
  MenuItemSize,
  MotionProfile,
} from "@/types";

import { useGetLabelTextSizeFromMenuItemSize } from "./shared";
import { labelSx } from "./styles";

export type MenuItemLabelProps<RC extends ReactElement | undefined> =
  BodyProps<RC> & {
    menuItemSize?: MakeResponsive<MenuItemSize>;
    motionProfile?: MotionProfile;
  };

export function MenuItemLabel<RC extends ReactElement | undefined = undefined>({
  size,
  menuItemSize = DEFAULT_MENU_ITEM_SIZE,
  children,
  testId = "MenuItemLabel",
  sx = {},
  className,
  ...props
}: MenuItemLabelProps<RC>) {
  const bodyTextSize = useGetLabelTextSizeFromMenuItemSize(menuItemSize, size);
  const mergedSx = useMemo(() => merge(labelSx, sx), [sx]);
  return (
    <Body
      {...props}
      sx={mergedSx}
      testId={testId}
      size={bodyTextSize}
      className={`${className ?? ""} MenuItemLabel`}
    >
      {children}
    </Body>
  );
}

MenuItemLabel.displayName = "MenuItem.Label";
