export const framedContainerSx = {
  w: "base.icon.size.300",
  h: "base.icon.size.300",
  borderRadius: "base.borderRadius.x6",
};

export const iconSx = {
  w: "base.icon.size.200",
};

export const framedComponentSx = {
  bg: "base.color.translucent.inverse[1000]",
};
