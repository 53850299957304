import { useEffect, useRef } from "react";

/**
 * The function returns the previous value of a given variable using a useRef hook in React.
 * @param {unknown} value - The value that we want to keep track of and get the previous value of. It
 * can be of any data type.
 * @returns The previous value of the input parameter `value` is being returned. This is achieved by
 * storing the current value of `value` in a `ref` object using the `useRef` hook, and updating it
 * whenever `value` changes using the `useEffect` hook. The previous value is then returned from the
 * `ref` object using its `current` property.
 */
export function usePrevious<T extends unknown | null>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>(null);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
