import type { ReactElement } from "react";

import type { AspectRatioBaseProps, FixedSizeCloudImageProps } from "@/types";
import { isImageResizerProps } from "@/utils";
import { AspectRatioImage } from "../AspectRatioImage";

export type ConfirmationDialogContentImage<
  Use extends ReactElement | undefined,
> = FixedSizeCloudImageProps<Use> & AspectRatioBaseProps;

export function ConfirmationDialogContentImage<
  Use extends ReactElement | undefined,
>({ use, ...props }: ConfirmationDialogContentImage<Use>) {
  const typedProps = isImageResizerProps(use, props)
    ? {
        ...props,
        responsiveSizes: props.responsiveSizes ?? [
          256, 370, 450, 512, 640, 720, 860, 1024, 1280, 1440,
        ],
        relativeImageSizeInLayout: "430px",
      }
    : { ...props, use };
  return <AspectRatioImage {...typedProps} />;
}

ConfirmationDialogContentImage.displayName = "ConfirmationDialog.Content.Image";
