import { TOLERATED_IMAGE_SIZES } from "@imtbl/image-resizer-utils";
import { type ReactElement, useMemo } from "react";

import type {
  BiomeTheme,
  BreakpointKeyOrString,
  ImageComponentKind,
  ImageResizerProps,
  ImageSizeVariant,
  LonghandSizesMediaQueryItem,
  MakeResponsive,
  MinOrMaxWidth,
  SizeMapping,
  SizesMediaQueryItem,
} from "@/types";
import { type Breakpoints, smartPickTokenValue } from "@biom3/design-tokens";

export function removeDuplicates(numbers: number[]): number[] {
  // Use a Set to store unique values
  const uniqueNumbers = new Set(numbers);
  // Convert the Set back to an array
  const resultArray = Array.from(uniqueNumbers);
  return resultArray;
}

export function getImageSizesArray(currentSize: number) {
  const index = TOLERATED_IMAGE_SIZES.indexOf(
    currentSize as (typeof TOLERATED_IMAGE_SIZES)[number],
  );

  let closestLargerSize = null;
  if (index === -1) {
    // If currentSize is not found, find the closest larger size
    const largerSizes = TOLERATED_IMAGE_SIZES.filter(
      (size) => size > currentSize,
    );
    if (largerSizes.length > 0) {
      closestLargerSize = largerSizes.reduce((prev, curr) =>
        Math.abs(curr - currentSize) < Math.abs(prev - currentSize)
          ? curr
          : prev,
      );
    }
  }

  const underSize = index > 0 ? TOLERATED_IMAGE_SIZES[index - 1] : null;
  const matchedSize =
    closestLargerSize !== null ? closestLargerSize : currentSize;
  const largeSize = TOLERATED_IMAGE_SIZES.find(
    (size) => size > currentSize,
  ) as number;
  const xLargeSize = TOLERATED_IMAGE_SIZES.find((size) => size > largeSize);

  const sizes = [underSize, matchedSize, largeSize, xLargeSize];
  const refinedSizes = sizes.filter((size) => size !== null) as number[];
  const sortedSizes = refinedSizes.sort((n1, n2) => n1 - n2);
  const dedupedSizes = removeDuplicates(sortedSizes);
  return dedupedSizes;
}

export function pickResponsiveSize(
  size: ImageSizeVariant | null,
  sizeMapping: SizeMapping,
) {
  switch (size) {
    case "xLarge":
      return getImageSizesArray(sizeMapping.xLarge);
    case "large":
      return getImageSizesArray(sizeMapping.large);
    case "small":
      return getImageSizesArray(sizeMapping.small);
    case "xSmall":
      return getImageSizesArray(sizeMapping.xSmall);
    case "xxSmall":
      return getImageSizesArray(sizeMapping.xxSmall);
    case "medium":
    default:
      return getImageSizesArray(sizeMapping.medium);
  }
}

export function pickSizeInLayout(
  size: ImageSizeVariant | null,
  sizeMapping: SizeMapping,
): string {
  switch (size) {
    case "xLarge":
      return `${sizeMapping.xLarge}px`;
    case "large":
      return `${sizeMapping.large}px`;
    case "small":
      return `${sizeMapping.small}px`;
    case "xSmall":
      return `${sizeMapping.xSmall}px`;
    case "xxSmall":
      return `${sizeMapping.xxSmall}px`;
    case "medium":
    default:
      return `${sizeMapping.medium}px`;
  }
}

type StringOrNumberSizeMapping = Record<ImageSizeVariant, number | string>;
export function getImageSizeMapping<AsNum extends boolean = false>(
  componentKind: ImageComponentKind,
  theme: BiomeTheme,
  asNumbers?: AsNum,
) {
  const { base } = theme;
  let sizeMapping: StringOrNumberSizeMapping;
  switch (componentKind) {
    case "SwapStack":
      sizeMapping = {
        xxSmall: asNumbers
          ? Number.parseInt(base.icon.size[200], 10)
          : base.icon.size[200],
        xSmall: asNumbers
          ? Number.parseInt(base.icon.size[200], 10)
          : base.icon.size[200],
        small: asNumbers
          ? Number.parseInt(base.icon.size[200], 10)
          : base.icon.size[200],
        medium: asNumbers
          ? Number.parseInt(base.icon.size[250], 10)
          : base.icon.size[250],
        large: asNumbers
          ? Number.parseInt(base.icon.size[400], 10)
          : base.icon.size[400],
        xLarge: asNumbers
          ? Number.parseInt(base.icon.size[500], 10)
          : base.icon.size[500],
      };
      break;

    case "FramedContainer":
    case "FramedImage":
      sizeMapping = {
        xxSmall: asNumbers
          ? Number.parseInt(base.icon.size[200], 10)
          : base.icon.size[200],
        xSmall: asNumbers
          ? Number.parseInt(base.icon.size[250], 10)
          : base.icon.size[250],
        small: asNumbers
          ? Number.parseInt(base.icon.size[300], 10)
          : base.icon.size[300],
        medium: asNumbers
          ? Number.parseInt(base.icon.size[400], 10)
          : base.icon.size[400],
        large: asNumbers
          ? Number.parseInt(base.icon.size[500], 10)
          : base.icon.size[500],
        xLarge: asNumbers
          ? Number.parseInt(base.icon.size[600], 10)
          : base.icon.size[600],
      };
      break;

    case "Avatar":
    case "FramedStack":
    default:
      sizeMapping = {
        xxSmall: asNumbers
          ? Number.parseInt(base.icon.size[300], 10)
          : base.icon.size[300],
        xSmall: asNumbers
          ? Number.parseInt(base.icon.size[300], 10)
          : base.icon.size[300],
        small: asNumbers
          ? Number.parseInt(base.icon.size[300], 10)
          : base.icon.size[300],
        medium: asNumbers
          ? Number.parseInt(base.icon.size[400], 10)
          : base.icon.size[400],
        large: asNumbers
          ? Number.parseInt(base.icon.size[500], 10)
          : base.icon.size[500],
        xLarge: asNumbers
          ? Number.parseInt(base.icon.size[600], 10)
          : base.icon.size[600],
      };
      break;
  }

  return sizeMapping as Record<
    ImageSizeVariant,
    AsNum extends true ? number : string
  >;
}

export function useGetFramedImageSizeFromSize(
  componentKind: ImageComponentKind,
  size: MakeResponsive<ImageSizeVariant>,
) {
  const chosenFramedSize = useMemo(() => {
    return Array.isArray(size)
      ? size.map((s) =>
          getFramedImageSizeBasedOnSizeVarient(
            componentKind,
            s as ImageSizeVariant,
          ),
        )
      : getFramedImageSizeBasedOnSizeVarient(componentKind, size);
  }, [size, componentKind]);
  return chosenFramedSize as MakeResponsive<ImageSizeVariant>;
}

export function getFramedImageSizeBasedOnSizeVarient(
  componentKind: ImageComponentKind,
  size: ImageSizeVariant,
): ImageSizeVariant {
  switch (componentKind) {
    case "FramedStack":
      return size;

    case "SwapStack":
      return (
        {
          xxSmall: "xxSmall",
          xSmall: "xxSmall",
          small: "xxSmall",
          medium: "xSmall",
          large: "medium",
          xLarge: "large",
        } as const
      )[size];

    case "Avatar":
    case "FramedImage":
    default:
      return size;
  }
}

export function isImageResizerProps(
  use?: ReactElement<unknown>,
  imageProps?: unknown,
): imageProps is ImageResizerProps {
  return !use;
}

function getMediaRule(rule: MinOrMaxWidth, breakpoint: string, width: string) {
  return width !== ""
    ? `(${
        rule === "minWidth" ? "min-width" : "max-width"
      }: ${breakpoint}) ${width}`
    : "";
}

function pickBreakpointValue(breakpoint: keyof Breakpoints, theme: BiomeTheme) {
  return `${
    smartPickTokenValue(theme, `base.breakpoint.${breakpoint}`) as number
  }px` as const;
}

function replaceDesignTokenValues(width: string, theme: BiomeTheme): string {
  const pickedValue = width.match(/^base./)
    ? (smartPickTokenValue(theme, width) as string)
    : width;
  return pickedValue;
}

function convertEntriesToLonghand(
  entries: SizesMediaQueryItem[],
): LonghandSizesMediaQueryItem[] {
  return entries.map((entry) => {
    if ("r" in entry) {
      return entry.r === "default"
        ? { rule: entry.r, width: entry.w }
        : { rule: entry.r, breakpoint: entry.b, width: entry.w };
    }
    return entry;
  });
}

const constructMq = (processedMqRules: string[], defaultWidth: string) => {
  return !processedMqRules.length && defaultWidth
    ? defaultWidth
    : `${processedMqRules.length ? processedMqRules.join(",\n") : ""}${
        defaultWidth && ", "
      } 
${defaultWidth}`;
};

type LonghandDefaultMediaQueryItem = { rule: "default"; width: string };

export function mediaQuerySizesHelper(entries: SizesMediaQueryItem[]) {
  return (theme: BiomeTheme) => {
    const longhandEntries = convertEntriesToLonghand(entries);
    const defaultRule = longhandEntries.find(
      (entry) => entry.rule === "default",
    ) as undefined | LonghandDefaultMediaQueryItem;
    const processedDefaultRule = defaultRule
      ? {
          rule: "default",
          width: replaceDesignTokenValues(defaultRule.width, theme),
        }
      : { width: "" };
    const processedMqRules = longhandEntries
      .filter(
        (entry) =>
          entry.rule !== "default" && entry.width !== "" && entry.width !== " ",
      )
      .map((entry) => {
        const typedEntry = entry as {
          rule: MinOrMaxWidth;
          breakpoint: BreakpointKeyOrString;
          width: string;
        };
        const breakpointValue = Object.keys(theme.base.breakpoint).includes(
          typedEntry.breakpoint,
        )
          ? pickBreakpointValue(
              typedEntry.breakpoint as keyof Breakpoints,
              theme,
            )
          : typedEntry.breakpoint;
        const widthValue = replaceDesignTokenValues(typedEntry.width, theme);
        return getMediaRule(typedEntry.rule, breakpointValue, widthValue);
      });

    return constructMq(processedMqRules, processedDefaultRule.width).trim();
  };
}
