import type { ReactElement } from "react";

import { Button } from "@/components/Clickable";
import type { ButtonProps } from "@/types";

export function ConfirmationDialogContentButton<
  RC extends ReactElement | undefined = undefined,
>({ rc, ...props }: ButtonProps<RC>) {
  return <Button {...props} rc={rc} />;
}

ConfirmationDialogContentButton.displayName =
  "ConfirmationDialog.Content.Button";
ConfirmationDialogContentButton.Icon = Button.Icon;
ConfirmationDialogContentButton.Logo = Button.Logo;
ConfirmationDialogContentButton.FramedImage = Button.FramedImage;
