import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import type { DomPropsWithDomRef, StandardComponentWithProps } from "@/types";
import {
  childHasClassNameProp,
  childHasSxProp,
  getSubcomponentChild,
  warnUser,
} from "@/utils";

import { CarouselSimple } from "../CarouselSimple";

import { CarouselThumbnail } from "../CarouselThumbnail";
import {
  backBgLayerSx,
  bgLayersSx,
  containerSx,
  frontLayerSx,
  midBgLayerSx,
} from "./styles";

export type PileProps = StandardComponentWithProps<
  HTMLDivElement,
  { children?: ReactElement; pileLayer?: ReactElement }
>;

export function Pile<RC extends ReactElement | undefined = undefined>({
  children,
  pileLayer,
  className,
  sx = {},
  ...props
}: RC extends undefined
  ? DomPropsWithDomRef<"div"> & PileProps
  : PileProps & { rc: RC }) {
  const childBgLayerSx = useMemo(
    () =>
      childHasSxProp(pileLayer)
        ? pileLayer.props.sx
        : childHasSxProp(children)
          ? children?.props.sx
          : {},
    [pileLayer, children],
  );
  const childClassName = childHasClassNameProp(children)
    ? children.props.className
    : "";
  const mergedBackLayerSx = useMemo(
    () => merge(backBgLayerSx, childBgLayerSx),
    [childBgLayerSx],
  );
  const mergedMidLayerSx = useMemo(
    () => merge(midBgLayerSx, childBgLayerSx),
    [childBgLayerSx],
  );
  const mergedFrontLayerSx = useMemo(
    () => merge(frontLayerSx, childBgLayerSx),
    [childBgLayerSx],
  );
  const mergedSx = useMemo(() => merge(containerSx, sx), [sx]);

  const illegalChildren = useMemo(() => {
    const carouselSimple = getSubcomponentChild(children, CarouselSimple);
    const carouselThumbnail = getSubcomponentChild(children, CarouselThumbnail);
    const renderPlainChildren =
      Boolean(carouselSimple) || Boolean(carouselThumbnail);
    if (renderPlainChildren) {
      warnUser(
        `You seem to be trying to use a highly interactive component (${
          carouselSimple ? "CarouselSimple" : "CarouselThumbnail"
        }) as a child of Pile. This is not supported and will not work as expected. Please try to use only minimally interactive components with Pile.`,
      );
    }
    return renderPlainChildren;
  }, [children]);

  // @NOTE: warn consumer when they're being a bit silly :rolling_on_the_floor_laughing:,
  // and then simply render whatever is passed in, without any additional layers
  return illegalChildren ? (
    <>{children}</>
  ) : (
    <Stack {...props} sx={mergedSx} className={`${className ?? ""} Pile`}>
      <Box className="Pile__bgLayers" sx={bgLayersSx}>
        <SmartClone
          className={`${childClassName} Pile__bgLayers__back`}
          sx={mergedBackLayerSx}
        >
          {pileLayer ?? children}
        </SmartClone>
        <SmartClone
          className={`${childClassName} Pile__bgLayers__mid`}
          sx={mergedMidLayerSx}
        >
          {pileLayer ?? children}
        </SmartClone>
      </Box>
      <SmartClone
        className={`${childClassName} Pile__frontLayer`}
        sx={mergedFrontLayerSx}
      >
        {children}
      </SmartClone>
    </Stack>
  );
}

Pile.displayName = "Pile";
