import type { BiomeTheme, MakeResponsive, MenuItemSize } from "@/types";
import { getResponsiveSx } from "@/utils/styleHelpers";

import { baseContainerStyles as menuItemBaseStyles } from "../MenuItem/styles";

export const containerSx = {
  transitionProperty: menuItemBaseStyles.transitionProperty,
  width: "100%",
  cursor: "pointer",

  "& .VerticalMenu.Box": {
    padding: "unset",
  },

  // @NOTE: apply incremental amounts of left
  // indentation to indicate nested heriarchy
  // @TODO: do we need to allow deeper than 3/4 levels?
  "& .VerticalMenu .MenuItem": {
    paddingLeft: "base.spacing.x5",
  },

  "& .VerticalMenu .ExpandableMenuItem .VerticalMenu .MenuItem": {
    paddingLeft: "base.spacing.x10",
  },

  "& .VerticalMenu .ExpandableMenuItem .VerticalMenu .ExpandableMenuItem .VerticalMenu .MenuItem":
    {
      paddingLeft: "base.spacing.x15",
    },

  "& .VerticalMenu .ExpandableMenuItem .VerticalMenu .ExpandableMenuItem .VerticalMenu .ExpandableMenuItem .VerticalMenu .MenuItem":
    {
      paddingLeft: "base.spacing.x20",
    },
};

export const rightChevronSx = {
  position: "absolute",
  right: "base.spacing.x3",
  w: "18px",
  stroke: "base.color.text.body.secondary",
  strokeWidth: 2.3,
  fill: "transparent",
};

export const nestedVerticalMenuSx = {
  overflow: "visible",
  p: "0",
  bg: "transparent",
  maxh: "unset",
  boxShadow: "unset",
  brad: "unset",
};

export const renderSizeBasedContainerStyles = ({
  size,
}: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) => {
  switch (size) {
    case "xSmall":
      return {
        borderRadius: "base.borderRadius.x4",
      };

    case "small":
    case "medium":
    default:
      return {
        borderRadius: "base.borderRadius.x6",
      };
  }
};

export const renderContainerResponsiveStyles = ({
  size,
  theme,
}: {
  size: MakeResponsive<MenuItemSize>;
  theme: BiomeTheme;
}) =>
  getResponsiveSx({
    size,
    theme,
    renderSxAtSize: renderSizeBasedContainerStyles,
  });
