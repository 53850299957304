import {
  type ChangeEventHandler,
  type ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { ButtCon } from "@/components/Clickable";
import { Stack } from "@/components/Stack";
import { Body } from "@/components/Text";
import { Tooltip } from "@/components/Tooltip";
import type {
  DomPropsWithDomRef,
  InputTextAlign,
  InputValidationStatus,
  MakeResponsive,
  StandardComponentWithProps,
} from "@/types";
import { noop } from "@/utils";

export const DEFAULT_NUMBER_STEPPER_SIZE = "medium" as const;
export const NUMBER_STEPPER_SIZES = ["medium", "large"] as const;
export type NumberStepperSize = (typeof NUMBER_STEPPER_SIZES)[number];

type NumericalString = `${number}`;

export type NumberStepperProps = StandardComponentWithProps<
  HTMLDivElement,
  {
    id?: string;
    name?: string;
    defaultValue?: NumericalString;
    value?: NumericalString;
    onChange?: (newValue: NumericalString) => void;
    step?: NumericalString;
    size?: MakeResponsive<NumberStepperSize>;
    min?: NumericalString;
    max?: NumericalString;
    minErrorText?: string;
    maxErrorText?: string;
    // @NOTE: these props are neccissary for FormControl to support this component
    // ATM they have no affect on how NumberStepper is rendered though.
    textAlign?: InputTextAlign;
    validationStatus?: InputValidationStatus;
  }
>;

export function NumberStepper<RC extends ReactElement | undefined = undefined>({
  value,
  defaultValue = "0",
  step = "1",
  size,
  onChange,
  min,
  max,
  name,
  id = name,
  minErrorText = "Minimum value reached",
  maxErrorText = "Maximum value reached",
  testId = "NumberStepper",
  textAlign,
  validationStatus,
  className,
  ...props
}: RC extends undefined
  ? Omit<DomPropsWithDomRef<"div">, "onChange"> & NumberStepperProps
  : NumberStepperProps & { rc: RC }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const isControlled = typeof value === "string";
  const [uncontrolledValue, setUncontrolledValue] = useState<string>(
    isControlled ? value : defaultValue,
  );
  const valueToUse = value ?? uncontrolledValue;
  const isAtMin = useMemo(() => valueToUse === min, [min, valueToUse]);
  const isAtMax = useMemo(() => valueToUse === max, [max, valueToUse]);
  const handleIncrement = useCallback(() => {
    if (isControlled) {
      inputRef.current?.stepUp();
      onChange?.((inputRef.current?.value ?? "") as NumericalString);
      // @NOTE: once the calc has been done, reset the input's value, so that it
      // only changes when the prop `value` changes
      if (inputRef.current) {
        inputRef.current.value = value;
      }
    } else {
      if (isAtMax) return;
      inputRef.current?.stepUp();
      setUncontrolledValue(inputRef.current?.value ?? "");
      onChange?.((inputRef.current?.value ?? "") as NumericalString);
    }
  }, [isAtMax, isControlled, onChange, value]);
  const handleDecrement = useCallback(() => {
    if (isControlled) {
      inputRef.current?.stepDown();
      onChange?.((inputRef.current?.value ?? "") as NumericalString);
      // @NOTE: once the calc has been done, reset the input's value, so that it
      // only changes when the prop `value` changes
      if (inputRef.current) {
        inputRef.current.value = value;
      }
    } else {
      if (isAtMin) return;
      inputRef.current?.stepDown();
      setUncontrolledValue(inputRef.current?.value ?? "");
      onChange?.((inputRef.current?.value ?? "") as NumericalString);
    }
  }, [isAtMin, isControlled, onChange, value]);

  const incrementButton = (
    <ButtCon
      size={size}
      icon="Add"
      variant="tertiary"
      onClick={handleIncrement}
      testId={`${testId}__incrementBtn`}
    />
  );
  const decrementButton = (
    <ButtCon
      size={size}
      icon="Minus"
      variant="tertiary"
      onClick={handleDecrement}
      testId={`${testId}__decrementBtn`}
    />
  );

  return (
    <Stack
      {...props}
      direction="row"
      alignItems="center"
      className={`${className ?? ""} NumberStepper`}
      testId={testId}
    >
      {isAtMin ? (
        // @TODO: perhaps a styled up Popover would be better than a tooltip here,
        // given that it should appear on click/tap, rather than hover?
        <Tooltip size="small">
          <Tooltip.Target>{decrementButton}</Tooltip.Target>
          <Tooltip.Content testId={`${testId}__tooltipContent`}>
            {minErrorText}
          </Tooltip.Content>
        </Tooltip>
      ) : (
        decrementButton
      )}
      <Body testId={`${testId}__value`}>{valueToUse}</Body>
      {/* 
        @NOTE: we use a simple number input here, because simple float arithmetic when using JS
        causes issues, eg: 0.1 * 0.2 = 0.020000000000000004 :facepalm:
      */}
      <input
        type="number"
        name={name}
        id={id}
        value={valueToUse.toString()}
        ref={inputRef}
        step={step}
        style={{ display: "none" }}
        onChange={
          (isControlled
            ? onChange
            : noop) as unknown as ChangeEventHandler<HTMLInputElement>
        }
        data-testid={`${testId}__input`}
      />
      {isAtMax ? (
        // @TODO: perhaps a styled up Popover would be better than a tooltip here,
        // given that it should appear on click/tap, rather than hover
        <Tooltip size="small">
          <Tooltip.Target>{incrementButton}</Tooltip.Target>
          <Tooltip.Content testId={`${testId}__tooltipContent`}>
            {maxErrorText}
          </Tooltip.Content>
        </Tooltip>
      ) : (
        incrementButton
      )}
    </Stack>
  );
}

NumberStepper.displayName = "NumberStepper";
