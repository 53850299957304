import type { TooltipContext, TooltipItem } from "@/types";
import { serializeChildren } from "@/utils";
import {
  type DispatchActionPayload,
  type FastContextDispatchAction,
  createFastContext,
} from "@/utils/createFastContext";

export const initialState: TooltipContext = { tooltipList: [] };

export const CLEAR_TOOLTIP = "clearTooltip";
export const STORE_TOOLTIP = "storeTooltip";
export const UPDATE_TOOLTIP_CONTENT = "updateTooltipContent";

type TooltipContextAction = Omit<FastContextDispatchAction, "payload"> & {
  payload: DispatchActionPayload;
};

const tooltipReducer = (
  state: TooltipContext,
  action: TooltipContextAction,
) => {
  switch (action.type) {
    case UPDATE_TOOLTIP_CONTENT: {
      let currentIndex = -1;
      state.tooltipList.forEach((tooltip, i) => {
        if (tooltip.id === action.payload.id) {
          currentIndex = i;
        }
      });
      if (currentIndex >= 0) {
        const newContentSerialised = serializeChildren(action.payload?.content);
        const tooltipItem = state.tooltipList[currentIndex];
        const oldContentSerialised = serializeChildren(tooltipItem?.content);
        const contentHasChanged = newContentSerialised !== oldContentSerialised;

        if (contentHasChanged && tooltipItem) {
          const updatedList = [...state.tooltipList];
          if (updatedList[currentIndex] && action.payload) {
            const payloadItem = action.payload as TooltipItem;
            updatedList[currentIndex] = {
              ...tooltipItem,
              content: payloadItem.content,
            };
            return { ...state, tooltipList: updatedList };
          }
        }
        return state;
      }
      return state;
    }

    case STORE_TOOLTIP: {
      const newTooltipList = [...state.tooltipList];
      const existingTooltip = state.tooltipList.find(
        (tooltip) => tooltip.id === action.payload.id,
      );
      let modifiedList = false;
      if (existingTooltip) {
        newTooltipList.forEach((tooltip, i) => {
          if (tooltip.id === action.payload.id) {
            const newTooltip = serializeChildren(action.payload);
            const oldTooltip = serializeChildren(newTooltipList[i]);
            if (newTooltip !== oldTooltip) {
              newTooltipList[i] = action.payload as TooltipItem;
              modifiedList = true;
            }
          }
        });
      } else {
        newTooltipList.push(action.payload as TooltipItem);
        modifiedList = true;
      }

      return modifiedList
        ? {
            ...state,
            tooltipList: newTooltipList,
          }
        : state;
    }

    case CLEAR_TOOLTIP:
      return {
        ...state,
        tooltipList: state.tooltipList.filter(
          (tooltip) => tooltip.id !== action.payload.id,
        ),
      };

    default:
      console.error("Unknown reducer action:", action);
      return state;
  }
};

export const { Provider: BiomeTooltipProvider, useStore: useTooltipStore } =
  createFastContext(initialState, tooltipReducer);

BiomeTooltipProvider.displayName = "BiomeTooltipProvider";
