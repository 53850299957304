import { type ReactElement, type Ref, useMemo } from "react";
import { merge } from "ts-deepmerge";

import type {
  InputTextAlign,
  InputValidationStatus,
  StandardInputComponentWithProps,
} from "@/types";

import { useForwardLocalDomRef, useTheme } from "@/hooks";
import { useHover } from "@/hooks/useHover";
import { cloneElementWithCssProp } from "@/utils";
import { inputResetStyles } from "../../utils/styleHelpers";
import { Box } from "../Box";
import { Icon } from "../Icon";
import {
  checkContainerSx,
  containerStyles,
  inputCssStyles,
  tickSx,
} from "./styles";

export type CheckboxProps = StandardInputComponentWithProps<
  "input",
  {
    validationStatus?: InputValidationStatus;
    textAlign?: InputTextAlign;
    inputRef?: Ref<HTMLInputElement>;
  }
>;

export function Checkbox<RC extends ReactElement | undefined = undefined>({
  sx = {},
  testId = "Checkbox",
  name,
  id = name,
  children,
  domRef = { current: null },
  className,
  onChange,
  checked,
  value,
  disabled,
  validationStatus,
  inputRef,
  rc,
  textAlign,
  ...inputHtmlAttrs
}: RC extends undefined ? CheckboxProps : CheckboxProps & { rc: RC }) {
  const localDomRef = useForwardLocalDomRef(domRef);
  const isHovering = useHover(localDomRef);
  const resetStyles = inputResetStyles(disabled);
  const theme = useTheme();
  const mergedContainerSx = useMemo(
    () =>
      merge(containerStyles, theme.components?.Checkbox?.sxOverride ?? {}, sx),
    [sx, theme.components?.Checkbox?.sxOverride],
  );

  const memoizedInputProps = useMemo(
    () => ({
      ref: inputRef,
      type: "checkbox",
      id,
      name,
      value,
      onChange,
      disabled,
      css: merge(resetStyles, inputCssStyles(theme)),
      className: "actualInput",
      "data-testid": `${testId}__input`,
      checked,
    }),
    [
      checked,
      disabled,
      id,
      inputRef,
      name,
      onChange,
      resetStyles,
      testId,
      theme,
      value,
    ],
  );

  const input = cloneElementWithCssProp(<input />, {
    ...inputHtmlAttrs,
    ...memoizedInputProps,
  });

  return (
    <Box
      rc={rc}
      domRef={localDomRef}
      testId={testId}
      className={`${className ?? ""} Checkbox`}
      sx={mergedContainerSx}
    >
      {input}
      <Box
        className="checkContainer"
        sx={checkContainerSx}
        testId={`${testId}__checkboxContainer`}
      >
        <Icon
          icon="Tick"
          className="checkIcon"
          variant={isHovering && !disabled ? "regular" : "bold"}
          sx={tickSx}
          testId={`${testId}__checkIcon`}
        />
      </Box>
    </Box>
  );
}

Checkbox.displayName = "Checkbox";
