import {
  type designTokens,
  onDarkBase,
  onLightBase,
} from "@biom3/design-tokens";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { BiomeThemeProvider } from "@/providers/theming/BiomeThemeProvider";
import type { DeeplyNestedSx } from "@/types";

import type { InputTheme } from "../shared";

export const ToggleableThemeContext = createContext<{
  toggleTheme: () => void;
  currentTheme: typeof designTokens;
}>({ toggleTheme: () => {}, currentTheme: { base: onLightBase } });

export type ToggleableThemeProviderProps = {
  children: ReactNode;
  lightTheme?: InputTheme;
  darkTheme?: InputTheme;
  autoPickTheme?: boolean;
  globalSx?: DeeplyNestedSx;
  skipFontLoad?: boolean;
};

function pickThemeBasedOnPreferColorScheme(
  lightTheme: InputTheme,
  darkTheme: InputTheme,
): InputTheme {
  return window?.matchMedia?.("(prefers-color-scheme: dark)").matches
    ? darkTheme
    : lightTheme;
}

export function ToggleableThemeProvider({
  children,
  lightTheme = { base: onLightBase },
  darkTheme = { base: onDarkBase },
  autoPickTheme = false,
  globalSx,
  skipFontLoad,
}: ToggleableThemeProviderProps) {
  const [currentTheme, setCurrentTheme] = useState(lightTheme);

  const toggleTheme = useCallback(() => {
    setCurrentTheme(currentTheme.base === onLightBase ? darkTheme : lightTheme);
  }, [currentTheme.base, darkTheme, lightTheme]);

  const onColorSchemeChange = useCallback(
    (event: { matches: boolean }) => {
      setCurrentTheme(event.matches ? darkTheme : lightTheme);
    },
    [lightTheme, darkTheme],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!autoPickTheme) return;
    // @NOTE: set the current color mode, based on the user's OS color mode:
    const autoPickedTheme = pickThemeBasedOnPreferColorScheme(
      lightTheme,
      darkTheme,
    );
    if (autoPickedTheme.base.colorMode !== currentTheme.base.colorMode) {
      setCurrentTheme(autoPickedTheme);
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", onColorSchemeChange);
  }, []);

  const contextValue = useMemo(() => {
    return { toggleTheme, currentTheme };
  }, [currentTheme, toggleTheme]);

  return (
    <ToggleableThemeContext.Provider value={contextValue}>
      <BiomeThemeProvider
        theme={currentTheme}
        globalSx={globalSx}
        skipFontLoad={skipFontLoad}
      >
        {children}
      </BiomeThemeProvider>
    </ToggleableThemeContext.Provider>
  );
}

export const useToggleableTheme = () => {
  const context = useContext(ToggleableThemeContext);
  if (context === undefined) {
    throw new Error(
      "useToggleableTheme must be used within ToggleableThemeProvider",
    );
  }
  return context;
};
