import type { ReactElement } from "react";

import { FramedImage } from "@/components/FramedThings";
import type { FixedSizeFramedImageProps } from "@/types";

export type TextInputFramedImageProps<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
> = FixedSizeFramedImageProps<RC, Use>;

export function TextInputFramedImage<
  RC extends ReactElement | undefined = undefined,
  Use extends ReactElement | undefined = undefined,
>({ ...props }: TextInputFramedImageProps<RC, Use>) {
  return <FramedImage {...props} size="small" />;
}

TextInputFramedImage.displayName = "TextInput.FramedImage";
