import { type ReactElement, useContext } from "react";
import { merge } from "ts-deepmerge";

import { Body } from "@/components/Text";
import { DEFAULT_TABLE_SPACING, TABLE_SPACING_SIZES } from "@/constants";
import { useTheme } from "@/hooks";
import type { BodyProps } from "@/types";
import { getStartingSize } from "@/utils/styleHelpers";

import { TableContext } from "./context";
import { baseCaptionSx, getCaptionSx, getResponsiveCaptionSx } from "./styles";

export type TableCaptionProps<RC extends ReactElement | undefined = undefined> =
  BodyProps<RC> & { captionSide?: "top" | "bottom" };

export function TableCaption<RC extends ReactElement | undefined = undefined>({
  children,
  rc = <div />,
  size = "small",
  weight,
  sx = {},
  captionSide = "top",
  className,
  testId: testIdProp,
  ...props
}: TableCaptionProps<RC>) {
  const theme = useTheme();
  const { verticalSpacing, testId } = useContext(TableContext);
  const startingVerticalSpacing = getStartingSize(
    verticalSpacing,
    DEFAULT_TABLE_SPACING,
    TABLE_SPACING_SIZES,
  );
  const allSx = merge(
    baseCaptionSx,
    {
      order: captionSide === "bottom" ? 1 : -1,
      marginBottom: captionSide === "top" ? "var(--captionGap)" : "unset",
      marginTop: captionSide === "bottom" ? "var(--captionGap)" : "unset",
    },
    getCaptionSx({ verticalSpacing: startingVerticalSpacing, theme }),
    getResponsiveCaptionSx({ verticalSpacing, theme }),
    sx,
  );
  return (
    <Body
      {...props}
      size={size}
      weight={weight}
      rc={rc}
      sx={allSx}
      testId={`${testIdProp ?? testId}__caption`}
      className={`${className ?? ""} TableCaption`}
    >
      {children}
    </Body>
  );
}

TableCaption.displayName = "Table.Caption";
