import {
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
  type ReactElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { merge } from "ts-deepmerge";

import type {
  DomPropsWithDomRef,
  InputValidationStatus,
  StandardComponentWithProps,
} from "@/types";
import {
  RE_DIGIT,
  focusToNextInput,
  focusToPrevInput,
} from "../../utils/inputHelpers";
import { Box } from "../Box";
import { TextInput } from "../TextInput";

type PasscodeInputDiscriminatedUnion<T> =
  | (T & {
      currentPasscode: string;
      onPasscodeChange: (value: string) => void;
    })
  | (T & {
      defaultPasscode?: string;
      onPasscodeChange?: (value: string) => void;
      currentPasscode?: never;
    });

export type PasscodeInputProps = PasscodeInputDiscriminatedUnion<
  StandardComponentWithProps<
    HTMLDivElement,
    {
      passcodeLength?: number;
      validationStatus?: InputValidationStatus;
    }
  >
>;

export function PasscodeInput<RC extends ReactElement | undefined = undefined>(
  props: RC extends undefined
    ? DomPropsWithDomRef<"div"> & PasscodeInputProps
    : PasscodeInputProps & { rc: RC },
) {
  const {
    testId = "PasscodeInput",
    domRef,
    sx = {},
    onPasscodeChange,
    defaultPasscode,
    currentPasscode,
    passcodeLength = 6,
    validationStatus,
    ...domAttrProps
  } = "currentPasscode" in props
    ? { ...props, defaultPasscode: undefined }
    : { ...props, currentPasscode: undefined };

  const [uncontrolledValue, setUncontrolledValue] = useState(
    defaultPasscode ?? "",
  );
  const digitValueAsArray = useMemo(() => {
    const valueToUse = currentPasscode ?? uncontrolledValue;
    const valueArray = valueToUse.split("");
    const items: Array<string> = [];

    for (let i = 0; i < passcodeLength; i += 1) {
      const char = valueArray[i];
      if (char && RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }
    return items;
  }, [currentPasscode, passcodeLength, uncontrolledValue]);

  const handleInputChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const valueToUse = currentPasscode ?? uncontrolledValue;
      const { target } = event;
      let targetValue = target.value.trim();
      const isTargetValueADigit = RE_DIGIT.test(targetValue);

      if (!isTargetValueADigit && targetValue !== "") {
        return;
      }

      const nextInputEl = target.parentElement?.nextElementSibling
        ?.firstElementChild as HTMLInputElement | null;

      // only delete digit if next input element has no value
      if (!isTargetValueADigit && nextInputEl && nextInputEl.value !== "") {
        return;
      }

      targetValue = isTargetValueADigit ? targetValue : " ";
      const targetValueLength = targetValue.length;

      if (targetValueLength === 1) {
        const newValue =
          valueToUse.substring(0, index) +
          targetValue +
          valueToUse.substring(index + 1);
        onPasscodeChange?.(newValue);
        setUncontrolledValue(newValue);
        if (!isTargetValueADigit) {
          return;
        }
        focusToNextInput(target);
      } else if (targetValueLength > 1) {
        onPasscodeChange?.(targetValue);
        setUncontrolledValue(targetValue);
        target.blur();
      }
    },
    [currentPasscode, uncontrolledValue, onPasscodeChange],
  );

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      const target = event.target as HTMLInputElement;

      // If user is hitting one of the arrow keys, early exit and focus to next or previous input:
      if (key === "ArrowRight" || key === "ArrowDown") {
        event.preventDefault();
        return focusToNextInput(target);
      }
      if (key === "ArrowLeft" || key === "ArrowUp") {
        event.preventDefault();
        return focusToPrevInput(target);
      }

      const { value: targetValue } = target;

      // keep the selection range position
      // if the same digit was typed
      target.setSelectionRange(0, targetValue.length);
      if (key !== "Backspace" || targetValue !== "") {
        return false;
      }

      focusToPrevInput(target);
      return false;
    },
    [],
  );

  const inputOnFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const { target } = event;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.parentElement?.previousElementSibling
      ?.firstElementChild as HTMLInputElement | null;
    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
    return false;
  }, []);

  return (
    <Box
      {...domAttrProps}
      testId={testId}
      domRef={domRef}
      sx={merge(
        {
          d: "flex",
          gap: "base.spacing.x3",
        },
        sx,
      )}
    >
      {digitValueAsArray.map((digit, index) => {
        return (
          <TextInput
            hideClearValueButton
            validationStatus={validationStatus}
            testId={`${testId}__TextInput--${index}`}
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            onKeyDown={handleInputKeyDown}
            onChange={handleInputChange(index)}
            onFocus={inputOnFocus}
            type="text"
            value={digit}
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern={`d${passcodeLength}`}
            maxLength={index === passcodeLength - 1 ? 1 : passcodeLength}
            sx={{
              minWidth: "50px",

              "& input": {
                textAlign: "center",
                padding: "0",
              },
            }}
          />
        );
      })}
    </Box>
  );
}

PasscodeInput.displayName = "PasscodeInput";
