import type { ReactElement } from "react";
import { merge } from "ts-deepmerge";

import { Icon } from "@/components/Icon";
import { type IconProps, isDualVariantIcon } from "@/types";

export type MenuItemIntentIconProps<RC extends ReactElement | undefined> =
  Partial<IconProps<RC>>;

export function MenuItemIntentIcon<RC extends ReactElement | undefined>({
  icon = "ChevronForward",
  variant,
  sx = {},
  ...props
}: MenuItemIntentIconProps<RC>) {
  const iconProps = isDualVariantIcon(icon) ? { icon, variant } : { icon };
  return (
    <Icon
      {...iconProps}
      {...props}
      sx={merge(
        {
          width: "base.icon.size.200",
          fill: "base.color.text.body.secondary",
          flexShrink: 0,
        },
        sx,
      )}
    />
  );
}

MenuItemIntentIcon.displayName = "MenuItem.IntentIcon";
