import { type ReactNode, useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useBrowserEffect } from "@/hooks/useBrowserEffect";
import { BiomeShadowRootContext } from "../../providers/BiomeShadowRootProvider/shadowRootContext";

export type ClientOnlyPortalProps = {
  selector: string;
  children?: ReactNode;
};

export function ClientOnlyPortal({
  children,
  selector,
}: ClientOnlyPortalProps) {
  const shadowRoot = useContext(BiomeShadowRootContext);
  const mountPointDomRef = useRef<HTMLElement | null>(null);
  const [portalMounted, setPortalMounted] = useState(false);
  useBrowserEffect(() => {
    // @TODO: wonder if we can modify this to try multiple times to
    // querySelector before giving up (e.g. if the element is not there yet)
    mountPointDomRef.current = shadowRoot
      ? shadowRoot.querySelector(selector)
      : document.querySelector(selector);
    if (mountPointDomRef.current) setPortalMounted(true);
    return () => setPortalMounted(false);
  }, [selector]);

  return portalMounted && mountPointDomRef.current
    ? createPortal(children, mountPointDomRef.current)
    : null;
}
