import { ClassNames } from "@emotion/react";
import {
  type MouseEventHandler,
  type ReactElement,
  type Ref,
  useMemo,
} from "react";
import { merge } from "ts-deepmerge";

import { Box } from "@/components/Box";
import { BaseClickable } from "@/components/Clickable";
import { SmartClone } from "@/components/SmartClone";
import { Stack } from "@/components/Stack";
import {
  useForwardLocalDomRef,
  useGetManySingleSubcomponentChildren,
  useGetMotionProfile,
  useGetSubcomponentChildren,
  useResizeObserver,
  useSplitApartChildrenAndSubComponents,
  useTheme,
} from "@/hooks";
import { useHoverCardVideoPlay } from "@/hooks/useHoverCardVideoPlay";
import {
  type GetHybridClickableRCandDomProps,
  type OnClickFunction,
  isBaseClickable,
  isBox,
} from "@/types";
import { checkChildrenForClickables, getMotionProfileSx } from "@/utils";

import { CardAssetImage } from "./CardAssetImage";
import { CardAssetVideo } from "./CardAssetVideo";
import { CardButtCon } from "./CardButtCon";
import { CardButton } from "./CardButton";
import { CardCaption } from "./CardCaption";
import { CardDescription } from "./CardDescription";
import { CardFramedImage } from "./CardFramedImage";
import { CardFramedLogo } from "./CardFramedLogo";
import { CardMenuItem } from "./CardMenuItem";
import { CardTitle } from "./CardTitle";
import { baseContainerSx, buttonsContainerSx, textContainerSx } from "./styles";

export type CardProps<
  RC extends ReactElement | undefined = undefined,
  OnClick extends OnClickFunction | undefined = undefined,
> = GetHybridClickableRCandDomProps<RC, OnClick> & {
  elevation?: 1 | 2 | 3 | 4 | 5;
  selected?: boolean;
};

export function Card<
  RC extends ReactElement | undefined,
  OnClick extends OnClickFunction | undefined,
>({
  className,
  testId = "Card",
  rc,
  sx = {},
  children,
  elevation = 1,
  selected,
  ...props
}: CardProps<RC, OnClick>) {
  const theme = useTheme();
  const motionProfile = useGetMotionProfile();
  const { onClick, ...propsMinusOnClick } =
    "onClick" in props ? props : { ...props, onClick: null };
  const { domRef, ...propsMinusOnClickAndDomRef } =
    "domRef" in propsMinusOnClick
      ? propsMinusOnClick
      : { ...propsMinusOnClick, domRef: null };
  const localDomRef = useForwardLocalDomRef(domRef as Ref<HTMLDivElement>);
  const baseClickableProps = {
    onClick,
    domRef: localDomRef,
    ...(rc ? { rc } : {}),
    ...propsMinusOnClickAndDomRef,
  };
  const {
    subcomponents: [
      menuItem,
      assetImage,
      assetVideo,
      title,
      caption,
      description,
      framedLogo,
      framedImage,
    ],
    otherChildren,
  } = useGetManySingleSubcomponentChildren(children, [
    CardMenuItem,
    CardAssetImage,
    CardAssetVideo,
    CardTitle,
    CardCaption,
    CardDescription,
    CardFramedLogo,
    CardFramedImage,
  ]);
  const buttons = useGetSubcomponentChildren(otherChildren, CardButton);
  const buttCons = useGetSubcomponentChildren(otherChildren, CardButtCon);
  const { otherChildren: nonSubcomponentChildren } =
    useSplitApartChildrenAndSubComponents(otherChildren, [
      CardButtCon,
      CardButton,
    ]);

  const hasButtons = useMemo(
    () =>
      Boolean(buttons?.length) ||
      Boolean(buttCons?.length) ||
      Boolean(menuItem) ||
      checkChildrenForClickables(otherChildren),
    [buttons, buttCons, menuItem, otherChildren],
  );
  const containsClickables = hasButtons || Boolean(menuItem);
  const assetMediaToUse = assetVideo ?? assetImage;
  const framedMediaToUse = framedLogo ?? framedImage;
  const localAssetMediaRef = useForwardLocalDomRef(
    (assetMediaToUse?.props.domRef as Ref<HTMLVideoElement | null>) ?? {
      current: null,
    },
  );
  const { height: assetMediaHeight } = useResizeObserver(localAssetMediaRef);
  const innerContents = useMemo(
    () => (
      <>
        {menuItem && menuItem}
        {assetMediaToUse && (
          <SmartClone domRef={localAssetMediaRef}>{assetMediaToUse}</SmartClone>
        )}

        <Box
          sx={textContainerSx}
          rc={<span />}
          className="textContainer"
          testId={`${testId}__textContainer`}
        >
          {framedMediaToUse}
          {(title ?? caption ?? description) ? (
            <Stack
              rc={<span />}
              sx={{
                pt: "base.spacing.x2",
                minh: "86px",
              }}
              gap="base.spacing.x1"
              className="innerTextContainer"
            >
              {caption}
              {title}
              {description}
            </Stack>
          ) : null}
        </Box>
        {hasButtons && (
          <Box
            rc={<span />}
            sx={buttonsContainerSx}
            className="buttonsContainer"
            testId={`${testId}__buttonsRow`}
          >
            {buttCons}
            {buttons}
          </Box>
        )}
        {nonSubcomponentChildren}
      </>
    ),
    [
      nonSubcomponentChildren,
      menuItem,
      assetMediaToUse,
      localAssetMediaRef,
      framedMediaToUse,
      title,
      caption,
      description,
      hasButtons,
      buttCons,
      buttons,
      testId,
    ],
  );
  const mergedContainerSx = useMemo(
    () =>
      merge(
        baseContainerSx,
        {
          "--cardAssetMediaHeight": `${assetMediaHeight ?? 0}px`,
          "&::before, &::after": {
            ...getMotionProfileSx(motionProfile, theme),
          },
          "& .AspectRatioImage, & .Video": {
            ...getMotionProfileSx(motionProfile, theme),
          },
        },
        getMotionProfileSx(motionProfile, theme),
        theme.components?.Card?.sxOverride ?? {},
        sx,
      ),
    [sx, theme, assetMediaHeight, motionProfile],
  );
  const baseClassNames = useMemo(
    () => [
      className,
      "Card",
      `Card--elevation${elevation}`,
      { "Card--selected": selected },
    ],
    [className, selected, elevation],
  );

  // @NOTE: setup the playOnCardHover functionality, if neccissary
  useHoverCardVideoPlay(localDomRef, assetVideo, localAssetMediaRef);

  return (
    <ClassNames>
      {({ cx }) =>
        isBaseClickable(onClick, rc, containsClickables, baseClickableProps) ? (
          <BaseClickable
            {...baseClickableProps}
            testId={testId}
            sx={mergedContainerSx}
            className={cx(
              ...baseClassNames,
              "Card--isClickable",
              "Card--BaseClickable",
            )}
          >
            {innerContents}
          </BaseClickable>
        ) : isBox(
            onClick,
            rc,
            containsClickables,
            propsMinusOnClickAndDomRef,
          ) ? (
          <Box
            {...propsMinusOnClickAndDomRef}
            onClick={onClick as MouseEventHandler<HTMLDivElement>}
            domRef={localDomRef}
            rc={rc}
            testId={testId}
            sx={mergedContainerSx}
            className={cx(...baseClassNames, "Card--Box", {
              "Card--isClickable": Boolean(onClick),
            })}
          >
            {innerContents}
          </Box>
        ) : null
      }
    </ClassNames>
  );
}

Card.displayName = "Card";
Card.Title = CardTitle;
Card.Caption = CardCaption;
Card.Description = CardDescription;
Card.FramedLogo = CardFramedLogo;
Card.FramedImage = CardFramedImage;
Card.AssetImage = CardAssetImage;
Card.MenuItem = CardMenuItem;
Card.Button = CardButton;
Card.ButtCon = CardButtCon;
Card.AssetVideo = CardAssetVideo;
