import type { ReactNode } from "react";

import type { StandardComponentWithProps } from "@/types";
import { SmartClone } from "../SmartClone";

export type TooltipTargetProps = StandardComponentWithProps<
  HTMLButtonElement,
  {
    children: ReactNode;
    id?: string;
  }
>;

export function TooltipTarget({ children, ...props }: TooltipTargetProps) {
  return <SmartClone {...props}>{children}</SmartClone>;
}

TooltipTarget.displayName = "Tooltip.Target";
