import debounce from "lodash.debounce";

import { useBrowserLayoutEffect } from "@/hooks/useBrowserLayoutEffect";
import { useEventListener } from "@/hooks/useEventListener";
import { DEFAULT_RESIZE_DEBOUNCE_TIME } from "./shared";
import { useWindowSizeStore } from "./windowSizeContext";

export type SetWindowSizeProps = { resizeDebounceDuration?: number };

export function SetWindowSize({
  resizeDebounceDuration = DEFAULT_RESIZE_DEBOUNCE_TIME,
}: SetWindowSizeProps) {
  const { setState } = useWindowSizeStore((state) => state);
  const debouncedOnResize = debounce(() => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  }, resizeDebounceDuration);
  useEventListener("resize", debouncedOnResize, {
    current: typeof window !== "undefined" ? window : null,
  });
  useBrowserLayoutEffect(
    () => setState({ width: window.innerWidth, height: window.innerHeight }),
    [],
  );
  return null;
}
