import { merge } from "ts-deepmerge";

import { DEFAULT_TEXT_INPUT_ALIGN } from "@/constants";
import type {
  BaseBodyProps,
  DomPropsWithDomRef,
  InputTextAlign,
} from "@/types";
import { Body } from "../Text/Body";

export type FormControlCaptionProps = Omit<
  BaseBodyProps & DomPropsWithDomRef<"span">,
  "rc"
> & { textAlign?: InputTextAlign };

export function FormControlCaption({
  size = "small",
  children,
  testId,
  sx = {},
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  className,
  ...props
}: FormControlCaptionProps) {
  return (
    <Body
      {...props}
      testId={testId}
      rc={<span />}
      size={size}
      sx={merge(
        {
          c: "base.color.text.body.secondary",
          textAlign,
          "& + .FormControlValidation": {
            mt: "base.spacing.x1",
          },
        },
        sx,
      )}
      className={`${className ?? ""} FormControlCaption`}
    >
      {children}
    </Body>
  );
}

FormControlCaption.displayName = "FormControlCaption";
