import { type ReactElement, useCallback, useMemo } from "react";
import { merge } from "ts-deepmerge";

import { useTheme } from "@/hooks";
import {
  CLOSE_DRAWER,
  useOverlaysStore,
} from "@/providers/BiomeOverlaysProvider";
import type { DrawerContentProps } from "@/types";
import { Box } from "../Box";

export function DrawerContent<RC extends ReactElement | undefined = undefined>({
  children,
  id = "",
  sx = {},
  className,
  ...props
}: DrawerContentProps<RC>) {
  const { dispatchAction } = useOverlaysStore((state) => state.modalList);
  const theme = useTheme();
  const closeDrawer = useCallback(
    () =>
      dispatchAction({
        type: CLOSE_DRAWER,
        payload: { id },
      }),
    [dispatchAction, id],
  );

  const renderedChildren =
    typeof children === "function"
      ? children({
          closeDrawer,
        })
      : children;

  const mergedSx = useMemo(
    () =>
      merge(
        {
          maxw: "100dvw",
          maxh: "100dvh",
          overflow: "auto",
        },
        theme.components?.Drawer?.DrawerContent?.sxOverride ?? {},
        sx,
      ),
    [sx, theme.components?.Drawer?.DrawerContent?.sxOverride],
  );

  return (
    <Box
      {...props}
      sx={mergedSx}
      className={`${className ?? ""} DrawerContent`}
    >
      {renderedChildren}
    </Box>
  );
}

DrawerContent.displayName = "Drawer.Content";
