import { type ReactElement, useMemo } from "react";
import { merge } from "ts-deepmerge";

import type { AspectRatioImageProps } from "@/types";
import { isImageResizerProps } from "@/utils";

import { AspectRatioImage } from "../AspectRatioImage";

export type CardAssetImageProps<
  Use extends ReactElement | undefined = undefined,
> = AspectRatioImageProps<Use>;

export function CardAssetImage<
  Use extends ReactElement | undefined = undefined,
>({ sx = {}, className, use, ...props }: CardAssetImageProps<Use>) {
  const {
    responsiveSizes = [128, 256, 450, 512, 720, 860, 1024],
    relativeImageSizeInLayout,
    ...otherImageProps
  } = isImageResizerProps(use, props)
    ? props
    : {
        ...props,
        relativeImageSizeInLayout: undefined,
        responsiveSizes: undefined,
      };
  const mergedSx = useMemo(
    () =>
      merge(
        {
          overflow: "hidden",
        },
        sx,
      ),
    [sx],
  );
  return (
    <AspectRatioImage
      {...otherImageProps}
      {...(use ? { use } : { responsiveSizes, relativeImageSizeInLayout })}
      className={`${className ?? ""} CardAssetImage`}
      sx={mergedSx}
    />
  );
}

CardAssetImage.displayName = "Card.AssetImage";
