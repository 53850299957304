import { useMemo } from "react";
import { merge } from "ts-deepmerge";

import { DEFAULT_TEXT_INPUT_ALIGN } from "@/constants";
import type {
  BaseBodyProps,
  DomPropsWithDomRef,
  InputTextAlign,
  InputValidationStatus,
} from "@/types";
import { DuoCon } from "../DuoCon";
import { Body } from "../Text";

export type FormControlValidationProps = Omit<
  BaseBodyProps & DomPropsWithDomRef<"span">,
  "rc"
> & {
  textAlign?: InputTextAlign | "center";
  validationStatus?: InputValidationStatus;
};

export function FormControlValidation({
  size = "small",
  children,
  validationStatus,
  testId,
  sx = {},
  textAlign = DEFAULT_TEXT_INPUT_ALIGN,
  className,
  ...props
}: FormControlValidationProps) {
  const iconSxProps = {
    marginTop: "-1px",
    order: textAlign === "right" ? 1 : 0,
    ...(textAlign === "right"
      ? { ml: "base.spacing.x1" }
      : { mr: "base.spacing.x1" }),
    width: "base.icon.size.250",
  };

  const duoConColorVariant = useMemo(() => {
    switch (validationStatus) {
      case "error":
        return "fatal";
      case "success":
        return "success";
      default:
        return "guidance";
    }
  }, [validationStatus]);

  const duoConIcon = useMemo(() => {
    switch (validationStatus) {
      case "error":
        return "Exclamation";
      case "success":
        return "Tick";
      default:
        return "Information";
    }
  }, [validationStatus]);

  const duoConOuterShape = useMemo(() => {
    switch (validationStatus) {
      case "error":
        return "triangle";
      case "success":
      default:
        return "circle";
    }
  }, [validationStatus]);

  const mergedSx = useMemo(
    () =>
      merge(
        {
          c: "base.color.brand.4",
          d: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent:
            textAlign === "center"
              ? "center"
              : textAlign === "right"
                ? "flex-end"
                : "flex-start",
        },
        sx,
      ),
    [sx, textAlign],
  );

  return (
    <Body
      {...props}
      rc={<span />}
      size={size}
      testId={testId}
      sx={mergedSx}
      className={`${className ?? ""} FormControlValidation`}
    >
      <DuoCon
        icon={duoConIcon}
        sx={iconSxProps}
        variant="bold"
        iconVariant="bold"
        outerShape={duoConOuterShape}
        colorVariant={duoConColorVariant}
      />
      {children}
    </Body>
  );
}

FormControlValidation.displayName = "FormControlValidation";
